<div fxLayout="row" fxLayoutAlign="center center" class="posting_p">
  <div fxFlex="86" fxFlex.xs="96">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="posting-list-card">
      <div fxFlex.gt-sm="35" fxFlex="100" [ngClass]="{'posting-details-card': cardWidth}">
        <app-opportunity-list-card class="" [loadMorePostings]="loadMorePostings" [opportunitiesLoaded]="opportunitiesLoaded" [opportunityFilterType]="opportunityFilterType"></app-opportunity-list-card>
      </div>
      <div fxFlex.gt-sm="64" fxFlex="100" [ngClass]="{'posting-details-card': cardWidth}">
        <router-outlet class="no-margin"></router-outlet>
      </div>
    </div>
  </div>
</div>
