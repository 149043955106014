<div fxLayout="row">
  <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
    <!-- <button mat-mini-fab (click)="prev()"> -->
    <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
    <!-- </button> -->
  </div>
  <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
    <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Work Experience</h1>
    <button type="button" mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
  </div>
</div>

<form name="candidateWorkExperienceForm" id="candidateWorkExperienceForm" [formGroup]="form" autocomplete="off">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayout="column" fxFlex="92" fxFlexOffset="5">
      <div fxFlex="row" fxLayoutAlign="center center" *ngIf="t.length == 0">
        <div fxFlex="column">
          <mat-label>Click 'Add' to get started adding your work experience, or click {{ edit ? '"SAVE"' : '"NEXT"' }} to continue!</mat-label>
        </div>
      </div>

      <div *ngFor="let cs of t.controls; let i = index;" [formGroup]="cs" fxLayout="column" fxLayoutGap="15px">
        <mat-divider fxFlex="100" [inset]="true" *ngIf="i > 0"></mat-divider>
        <div fxLayout="row">
          <div fxLayout="column" fxFlex="90">
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
              <div fxFlex="48" fxFlex.lt-md="100">
                <mat-form-field color="accent" appearance="outline">
                  <mat-label>Company Name</mat-label>
                  <input matInput name="company" type="text" formControlName="company_name">
                  <mat-error *ngIf="cs.controls.company_name.errors?.required && isNextClicked;" class="help-block small animated fadeInDown">
                    You did not enter your company name
                  </mat-error>
                  <mat-error *ngIf="cs.controls.company_name.errors?.maxlength;" class="help-block small animated fadeInDown">
                    This company name is too long (must be under 255 characters)
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="48" fxFlex.lt-md="100">
                <mat-form-field color="accent" appearance="outline">
                  <mat-label>Title</mat-label>
                  <input matInput name="title" minlength="3" maxlength="65" type="text" formControlName="title">
                  <mat-error *ngIf="cs.controls.title.errors?.required && isNextClicked;" class="help-block small animated fadeInDown">
                    You did not enter your title
                  </mat-error>
                  <mat-error *ngIf="cs.controls.title.errors?.maxlength;" class="help-block small animated fadeInDown">
                    This title name is too long (must be under 50 characters)
                  </mat-error>
                  <mat-error *ngIf="cs.controls.title.errors?.minlength;" class="help-block small animated fadeInDown">
                    This title name is too short (must be above 3 characters)
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
              <div fxFlex="48" fxFlex.lt-md="100">
                <mat-form-field color="accent" appearance="outline">
                  <mat-label>Start Date</mat-label>
                  <input matInput
                         [min]="minDate"
                         [max]="cs.controls.end_date.value ? cs.controls.end_date.value : maxDate"
                         [matDatepicker]="startDate" formControlName="start_date">
                  <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                  <mat-datepicker #startDate
                                  startView="multi-year" (monthSelected)="chosenMonthHandler($event,startDate,i,'start_date')"></mat-datepicker>
                  <mat-error>Start date is required!</mat-error>
                </mat-form-field>
              </div>
              <div class="padding-checkbox" fxFlex="10">
                <mat-checkbox (change)="disableExpiryDate(i,$event.checked)"
                              formControlName="present"
                              [checked]="cs.controls.present.value"
                              name="present">
                  Present
                </mat-checkbox>
              </div>

              <div fxLayout="column" fxFlex="36" fxFlex.lt-md="100">
                <mat-form-field color="accent" appearance="outline">
                  <mat-label>End Date</mat-label>
                  <input matInput
                         [min]="cs.controls.start_date.value ? cs.controls.start_date.value : minDate"
                         [max]="maxDate"
                         [matDatepicker]="endDate"
                         formControlName="end_date">
                  <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                  <mat-datepicker #endDate (monthSelected)="chosenMonthHandler($event,endDate,i,'end_date')"
                                  startView="multi-year"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="100">
                <mat-label>Description</mat-label>
                <div>
                  <quill-editor fxLayout="column" fxFlex="100"
                                [maxLength]="2000"
                                name="description"
                                class="quillBox"
                                formControlName="description"></quill-editor>
                </div>               
                <div>
                  <mat-error *ngIf="cs.controls.description.errors?.required && isNextClicked;"
                             class="help-block small animated fadeInDown">
                    Description is required!
                  </mat-error>
                </div>
                <div>
                  <mat-error *ngIf="cs.controls.description.errors?.maxlength;"
                             class="help-block small animated fadeInDown">
                    This Description is too long (must be under 2000 characters)
                  </mat-error>
                </div>
                <mat-hint fxLayoutAlign="end">{{getContentCount(cs.controls.description.value)}} / 2000</mat-hint>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="10" fxLayoutAlign="start center">
            <button mat-icon-button type="button" color="accent" matSuffix mat-icon-button aria-label="Clear" (click)="removeCertification(i)"><mat-icon>delete</mat-icon></button>
          </div>
        </div>
      </div>
      <div fxLayout=row fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start">
          <button mat-stroked-button color="primary" (click)="addWorkExperience()">ADD</button>
          <button type="button" mat-raised-button color="primary" (click)="next()" [disabled]="!form.valid">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
        </div>
      </div>
    </div>
  </div>
</form>
