import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { ShortlistService } from '../../Services/shortlist.service';
import { Shortlist } from '../../Models/Shortlist';
import * as _ from 'underscore';
import { TalentApiService } from '../../Services/talent-api.service';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ShortlistSearchPipe } from '../../Helpers/Pipes/shortlist-search.pipe';
import { PostinglistSearchPipe } from '../../Helpers/Pipes/postinglist-search.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../../Services/user-info.service';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
import { Subscription } from 'rxjs';
import { CreateShortlistDialogComponent } from '../dialogs/create-shortlist-dialog/create-shortlist-dialog.component';
import { PostingService } from '../../Services/posting.service';
import { SafeHtmlPipe } from '../../Helpers/Pipes/safe-html.pipe';

@Component({
  selector: 'app-shortlist-list-card',
  templateUrl: './shortlist-list-card.component.html',
  styleUrls: ['./shortlist-list-card.component.css'],

})
export class ShortListCardComponent implements OnInit {
  public ErrorMessage: string = "";
  public shortlistList: Shortlist[] = [];
  public ActiveShortList: Shortlist[] = [];
  public ArchivedShortList: Shortlist[] = [];
  @Input() shortListLoaded: EventEmitter<any>;
  showDetail = true;
  public selectedId: string = null;
  myEventSubscription: any;
  searchText: any;
  filterText: any;
  clearInfo: any;
  logoInfo: any;
  selectData: any;
  screenBreakPoint: any;
  public user: any;
  userType: number;
  shortlistSearchPipe = new ShortlistSearchPipe();
  postinglistSearchPipe = new PostinglistSearchPipe();
  @Output() searchedOutput = new EventEmitter();
  private subscription: Subscription;
  public sortField = "";
  public reverseSort = false;
  public sortBy = '';
  sortShowText = 'SORT';
  public logos: any[] = [];
  public hasCreateShortListPermission: boolean = false;
  public sortButtonSize = '100%';

  constructor(private _userInfoService: UserInfoService,
    private route: ActivatedRoute,
    public breakpointObserver: BreakpointObserver,
    public _dialog: MatDialog,
    private _loginService: LoginService,
    private _talentApiService: TalentApiService,
    private _shortlistService: ShortlistService,
    private _postingService: PostingService,
    private _router: Router,
    private sharedUtils: SharedUtilitiesService
  ) {

    this._shortlistService.backButtonShareData.subscribe((res: any) => {
      this.showDetail = res;
    });

    this._shortlistService.refreshShortlistData.subscribe((res: any) => {
      if (res.value) {
        this.refreshShortlistList(res.id);
        this.subscription = null;
      }
    });
  }

  ngOnInit(): void {
    this._userInfoService.checkMyPermission('Create Shortlist').subscribe((result: any) => {
      this.hasCreateShortListPermission = result;
      if(this.hasCreateShortListPermission){
        this.sortButtonSize = '48%';
      }
    });

    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);

    this.breakpointObserver
      .observe([
        Breakpoints.Tablet,
        Breakpoints.Handset
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.screenBreakPoint = true;
        } else {
          this.screenBreakPoint = false;
        }
      });

    // 'selects' the posting that is shown in detail view by using the router
    // this could probably be done via intercomponent communication
    this.route.url.subscribe(() => {
      if (this.subscription == null && this.route.firstChild) {
        this.subscription = this.route.firstChild.params.subscribe(params => {
          if (params.shortlistId && !isNaN(Number(params.shortlistId))) {
            this.selectedId = params.shortlistId;
          } else if (this.shortlistList.length) {
            this.selectedId = this.shortlistList[0].sid;
          }
        });
      }
    });

    this.route.queryParams.subscribe(params => {
      if(params['searchText']) {
        this.searchText = params['searchText'];
        this.modelChangeSearch(params['searchText']);
      }
    });


    this.shortListLoaded.subscribe(result => {
      this.shortlistList = result;
      this.ActiveShortList = result;
    });
  }

  clearSearch() {
    this.searchText = '';
    this.modelChangeSearch(this.searchText);
  }

  modelChangeSearch(data) {
    const param = { searchText: data }
    this.searchedOutput.emit({data: this.postinglistSearchPipe.transform(this.shortlistList, data), searchParams: param});
  }



  openShortlistModal(modell) {
    if (modell == 'employer') {
      let dialog = this._dialog.open(CreateShortlistDialogComponent, {
        height: 'auto',
        width: '100%',
        disableClose: true,
        panelClass: this.screenBreakPoint ? 'posting_popup' : ''
      });
      dialog.afterClosed().subscribe((result) => { });
    }
  }

  //Refresh shortlist when a  new shortlist added
  //shortlistId =0 if a shortlist deleted
  refreshShortlistList(shortlistId) {
    this._shortlistService.getShortlistList(this.user.id).subscribe(result => {
    if (result.length > 0) {        
      var that = this;
      that.shortlistList = result;
      this.ActiveShortList = result;
      this._router.navigate(['shortlist', shortlistId == 0 ? that.ActiveShortList[0].sid : shortlistId]);
    }
    else {
      this.shortlistList = result;
      this.ActiveShortList = result;
      this._router.navigate(['shortlist', 0]);
    }
    })

  }

  resetSortList() {
    if (this.ActiveShortList != null && this.shortlistList.length > 0) {       
      this._router.navigate(['shortlist', this.ActiveShortList[0].sid ]);
    }
  }

  toggleDetail() {
    this.showDetail = true;
    this._shortlistService.backButtonShareData.next(true);
  }

  setSort(sort) {
    if (sort == '') return;
     
    if (sort == this.sortField) {
      this.reverseSort = !this.reverseSort;
    } else {
      this.sortField = sort;
      this.reverseSort = (sort === "created_ts" ? true : false);// for date use descending default sort
    }
    switch (sort) {
      case "name":
        this.sortBy = "name";
        this.sortShowText = "Name";
        break;
      case "created_ts":
        this.sortBy = "created_ts";
        this.sortShowText = "Date";
        break;
      default:
        break;
    };
    //assign some time to sort the list
    setTimeout(x => {
      this.resetSortList();
    }, 1200);
    
     
  }
}
