<div fxLayout="column wrap" class="height-unset" fxLayoutAlign="center stretch" fxFill>
    <div fxLayout="row wrap" fxLayoutAlign="center stretch">
        <!-- <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
            <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
        </div> -->
        <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" *ngIf="!edit" fxLayoutAlign="space-between start">
            <h1>Upload Resume</h1>
            <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
        </div>
    </div>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
      </div>
    <div fxLayout="row">
        <div fxFlex="92" fxFlexOffset="5" fxLayoutAlign="center center" class="set-border">
            <form name="candidateInfoForm" id="candidateInfoForm" [formGroup]="form" autocomplete="off" novalidate>
                <div fxLayout="row" class="resume-image-padding" fxLayoutAlign="center center">
                    <app-logo [tenant]="tenant" [type]="'Candidate_Reg_Resume'"></app-logo>   
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="mytitle title comomn-padding" *ngIf="resumeName !== ''">{{resumeName}}</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button mat-stroked-button color="primary" (click)="fileInput.click()">
                        {{ resumeName !=='' ? 'UPDATE FILE' : 'UPLOAD FILE'}}
                        <input #fileInput type="file" accept=".pdf,.doc,.docx" onclick='this.value=null' (change)="onResumeUpload($event)" style="display:none;" />
                    </button>
                    <button fxFlexOffset="5" mat-raised-button color="primary" [disabled]="!resumeName" (click)="next()">
                        {{ !edit ? 'NEXT' : 'SAVE'}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
