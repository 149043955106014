import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
	selector: 'app-create-class',
	templateUrl: './create-class.component.html',
	styleUrls: ['./create-class.component.css']
})
export class CreateClassComponent implements OnInit {

	public cls = {
		startDate: new Date(),
		endDate: new Date(),
		name: '',
		ident: '',
		description: '',
		
	};
	public inprogress: boolean = false;
	public descriptionClass = 150;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;

	constructor(private _talentApiService: TalentApiService, private dialogRef: MatDialogRef<CreateClassComponent>) { }

	ngOnInit() {
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.inprogress = true;
		this._talentApiService.createClass(this.cls)
			.subscribe(
				(result:any) => {
					this._talentApiService.snackbarMessage("Class created successfully!");
					this.inprogress = false;
					if ($("#createClass")) {
						//let $:any; // for check
						this.dialogRef.close();
					}
				},
				error => {
					//$('#createClass').modal('show');
				}
			);
	}

}
