import { Component,Input, OnInit } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeUrl, SafeHtml, Title } from '@angular/platform-browser';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { TenantService } from '../../../../Services/tenant.service';
import {ThemePalette} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
@Component({
  selector: 'app-candidate-resume-view',
  templateUrl: './candidate-resume-view.component.html',
  styleUrls: ['./candidate-resume-view.component.css']
})
export class CandidateResumeViewComponent implements OnInit {
  @Input() resume: any;
  @Input() ownProfile: boolean;

  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  tenant: String;

  constructor(iconRegistry: MatIconRegistry,sanitizer: DomSanitizer,
    private _talentAPIService: TalentApiService, 
    private _tenantService: TenantService,
    public _dialog: MatDialog) {
      this.tenant = this._tenantService.tenant;
    }

  ngOnInit(): void {
    //this.resume = JSON.parse(this.resume);
  }

  isShare(event) {
    if(event.checked) {
      this.resume.share = 1;
    } else {
      this.resume.share = 0;
    }   
    //const candidateProfile = {
    //  resume: JSON.stringify(this.resume)
    //}
    const candidateProfile = {
      shareResume: this.resume.share
    }
    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }
  
	downloadDoc(referenceId) {
		let obj:any;
		obj = this.resume;
		if(obj.content && obj.name){
			let pdf = 'data:application/octet-stream;base64,'+obj.content;
			$('#'+referenceId).attr('href',pdf);
			$('#'+referenceId).attr('download',obj.name);
		}
  }
  
  openEdit() {
    let dialog = this._dialog.open(ProfileEditComponent,{
      height: '80%',
      width: '80%',
      disableClose: true,
      data: {
              subComponent: 'candidate-resume',
              seedData: this.resume
            }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        if (result == null) {
        } else {
          this.resume = JSON.parse(result);
        }
      });
  }

}
