
<div fxFlex="15"
     fxFlex.lg="18"
     fxFlex.md="40"
     fxFlex.sm="25"
     fxLayoutAlign="start start"
     fxLayoutAlign.xs="start start"    
     fxFlex.lt-md="40"
     fxLayout="row wrap"
     fxLayout.xs="column">
  <div fxFlex="100" fxFlex.xs="42" fxLayout="row">
    <app-company-logo class="company-logo"
                      [name]="detail.company.name"
                      [type]="'company'">
    </app-company-logo>
  </div>
  <div fxFlex="100" fxHide fxShow.xs fxFlex.xs="45" fxLayout="row" [ngClass.xs]="'paddingTop'">
    <img *ngIf="detail.skills.length > 0" [src]="detail.score_img|safeHtml" fxFlex="80" class="media-object" alt="" />
  </div>
</div>
<div fxFlex="50" fxFlex.md="55" fxFlex.sm="45" fxFlex.lt-md="55" fxFlexOffset="3" fxLayout="row wrap">
  <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.lt-md="100">
    <div class="address-margin">
      <mat-card-title class="padBottom5px">
        {{detail.title}}
      </mat-card-title>
      <div class="mat-body-1 padBottom5px">
        {{detail.company.name}}
      </div>
      <ng-container *ngIf="detail.locations.length == 1">
        <a class="mat-display-1" href="http://maps.google.com/{{'?q=' + (detail.locations[0].address1 ? detail.locations[0].address1+ ', ':'') + (detail.locations[0].city ? detail.locations[0].city+ ', ':'') + (detail.locations[0].state ? detail.locations[0].state+ ', ':'') + (detail.locations[0].zip ? detail.locations[0].zip:'') }}"
           target="_blank">{{ (detail.locations[0].city ? detail.locations[0].city:'') + (detail.locations[0].state ?  ', '+detail.locations[0].state:'') }}</a>
      </ng-container>
      <ng-container *ngIf="detail.locations.length > 1">
        <span class="mat-display-1">Multiple Locations</span>
      </ng-container>
      <mat-card-subtitle class="padding8px">
        <span *ngIf="detail.postingDay>1">
          Posted {{ detail.postingDay }} days ago
        </span>
        <span *ngIf="detail.postingDay==1">
          Posted 1 day ago
        </span>
        <span class="body-2" *ngIf="detail.postingDay==0">
          Today
        </span>
        <span fxShow.xs fxHide fxShow.sm *ngIf="!showHeaderBack">
          <button mat-icon-button
                  matTooltip="untag"
                  *ngIf="detail?.tag_id"
                  color="primary"
                  (click)="untagPosting(detail.tag_id)">
            <i class="fa fa-2x fa-star fa-fw"></i>
          </button>
          <button mat-icon-button
                  matTooltip="tag"
                  *ngIf="!detail?.tag_id"
                  (click)="tagPosting()">
            <i class="fa fa-2x fa-star-o"></i>
          </button>
        </span>
      </mat-card-subtitle>
    </div>
    <div fxLayoutAlign="start"
         fxFlexOrder.xs="0"
         fxLayout.xs="column wrap"
         fxLayout="row wrap"
         class="padding20px"
         fxLayoutGap="8px"
         *ngIf="(userType == 2 || showHeaderBack) && (userType != 4) ">
      <button mat-raised-button color="primary" [disabled]="!detail.canApply" (click)="applyPosting()" *ngIf="!detail.hasApplied">
        APPLY
      </button>
      <button mat-raised-button color="accent" *ngIf="detail.hasApplied" disabled>
        APPLIED
      </button>      
    </div>
  </div>
</div>
<div fxFlex="27"
     fxFlexAlign="start center"     
     fxHide.xs     
     class="padding20px"
     dir="rtl">
  <img *ngIf="detail.skills.length > 0 && userType != 4"  [src]="detail.score_img|safeHtml" class="media-object" [attr.width]="IsMobileDevice ? '80%' :'50%'" alt="" />
</div>
<div fxFlex="5"
     fxFlexAlign.xs="end center"    
     fxHide.sm
     fxHide.xs
     fxHide.lt-md    
     fxLayoutAlign="start start"
     *ngIf="!showHeaderBack && userType == 2">
  <button mat-icon-button
          matTooltip="untag"
          *ngIf="detail?.tag_id"
          color="primary"
          (click)="untagPosting(detail.tag_id)">
    <i class="fa fa-2x fa-star fa-fw"></i>
  </button>
  <button mat-icon-button
          matTooltip="tag"
          *ngIf="!detail?.tag_id"
          (click)="tagPosting()">
    <i class="fa fa-2x fa-star-o"></i>
  </button>
</div>


<button *ngIf="userType == 2"  mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" >
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item  (click)="sharePostingDialog()">
    <span>Share</span>
  </button>
  <button mat-menu-item (click)="copyLink()">
    <span>Copy Link</span>
  </button>
  <button mat-menu-item  (click)="contReportingDialog()">
    <span>Report Posting</span>
  </button>
</mat-menu>
