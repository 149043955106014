<mat-card fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" [ngClass.gt-sm]="'side-admin-list'">
    <mat-card-title fxLayout="column" class="no-margin padding-left">
        <h2 class="no-margin">{{ name }}</h2>
        <h5 class="no-margin">{{ email }}</h5>
    </mat-card-title>
    <mat-nav-list fxLayout="column" fxFlex="100" fxLayoutAlign="start stretch">
        <mat-divider inset></mat-divider>
        <mat-list-item [routerLinkActive]="['is-active']" [routerLink]="['/companyAdmin/aboutCompany']" class="hover-highlight">
            <mat-icon svgIcon="aboutCompany"></mat-icon>
            <div class="menu-font padding-left">About Company</div>
        </mat-list-item>
        <mat-accordion>
            <mat-expansion-panel [expanded]="isAccountManagement" class="mat-elevation-z0">
                <mat-expansion-panel-header matRipple class="no-padding" collapsedHeight="48px">
                    <mat-panel-title>
                        <mat-list class="no-padding">
                        <mat-list-item [disableRipple]="true">
                            <mat-icon svgIcon="companyMap"></mat-icon>
                            <div class="menu-font padding-left">Account Management</div>
                        </mat-list-item>
                        </mat-list>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list>
                        <a mat-list-item [routerLinkActive]="['is-active']" [routerLink]="['/companyAdmin/companyUsers']">My Users</a>
                        <a mat-list-item [routerLinkActive]="['is-active']" [routerLink]="['/companyAdmin/userGroups']">My User Groups</a>
                        <a mat-list-item [routerLinkActive]="['is-active']" [routerLink]="['/companyAdmin/InviteNewUser']">Add New User</a>
                </mat-nav-list>
                <mat-divider></mat-divider>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="isMembershipDetails" class="mat-elevation-z0">
            <mat-expansion-panel-header matRipple class="no-padding" collapsedHeight="48px">
                    <mat-panel-title>
                        <mat-list class="no-padding">
                        <mat-list-item [disableRipple]="true">
                            <mat-icon svgIcon="card"></mat-icon>
                            <div class="menu-font padding-left">Membership Details</div>
                        </mat-list-item>
                        </mat-list>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list>
                        <a mat-list-item [routerLinkActive]="['is-active']" [routerLink]="['/companyAdmin/accountUsage']">Account Usage</a>
                </mat-nav-list>
                <mat-divider></mat-divider>
            </mat-expansion-panel>    
        </mat-accordion>
    </mat-nav-list>   
</mat-card>       
