import { Component, OnInit, Input, EventEmitter, HostListener, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { OpportunityService } from '../../Services/opportunity.service';
import { Opportunity } from '../../Models/Opportunity';
import * as _ from 'underscore';
import { TalentApiService } from '../../Services/talent-api.service';
import { MatDialog } from '@angular/material/dialog';
import { OpportunitySearchFilterDialogComponent } from '../dialogs/opportunity-search-filter-dialog/opportunity-search-filter-dialog.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
/*import { PostinglistSearchPipe } from '../../Helpers/Pipes/postinglist-search.pipe';*/
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../../Services/user-info.service';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
import { Subject, Subscription } from 'rxjs';
import { CreatePostingDialogComponent } from '../dialogs/create-posting-dialog/create-posting-dialog.component';
import { PostingService } from '../../Services/posting.service';
import * as moment from 'moment';
import { SaveFilterDialogComponent } from '../dialogs/save-filter-dialog/save-filter-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-opportunity-list-card',
  templateUrl: './opportunity-list-card.component.html',
  styleUrls: ['./opportunity-list-card.component.css'],

})
export class OpportunityListCardComponent implements OnInit {
  public ErrorMessage: string = "";
  public opportunityList: Opportunity[] = [];
  public filteredOpportunity: Opportunity[] = [];
  @Input() opportunitiesLoaded: EventEmitter<any>;
  @Input() opportunityFilterType: EventEmitter<any>;
  showDetail = true;
  public selectedId: string = null;
  myEventSubscription: any;
  searchText: any = '';
  filterText: any;
  clearInfo: any;
  logoInfo: any;
  selectData: any;
  screenBreakPoint: any;
  public user: any;
  userType: number;
  private subscription: Subscription;
  public sortField = "";
  public sortOrder = 'DESC';
  public sortBy = 'postingdate';
  sortShowText = 'SORT';
  searchBoxSize = '100%';
  public mdSearchBoxSize;
  public parameter = {};
  public isPostingDownloading: boolean = false;
  public loadedJobCount = 0;
  public hasCreatePostingPermission: boolean = false;
  public saveSearchList;
  public postingFilterType = 'ACTIVE';
  private hasMoreItems: boolean = true;
  public smSearchBoxSize: any;

  @Input() loadMorePostings = new EventEmitter<{ hasMorePosting: boolean }>();
  userEnteredSearchText = new Subject<string>();
  @ViewChild('dvPostingContainer') private postingScrollContainer: ElementRef;

  constructor(private _userInfoService: UserInfoService,
    private route: ActivatedRoute,
    public breakpointObserver: BreakpointObserver,
    public _dialog: MatDialog,
    private _loginService: LoginService,
    private _talentApiService: TalentApiService,
    private _opportunityService: OpportunityService,
    private _postingService: PostingService,
    private _router: Router,
    private sharedUtils: SharedUtilitiesService,
    private scrollDispatcher: ScrollDispatcher,
    private changeDetector: ChangeDetectorRef
  ) {
    this._postingService.backButtonShareData.subscribe((res: any) => {
      this.showDetail = res;
    });

    this._postingService.refreshPostingData.subscribe((res: any) => {
      if (res.value) {
        if (res.isNewPosting != undefined) {
          this.refreshPostingList(res.id, res.isNewPosting);
        }
        else {
          this.refreshPostingList(res.id);
        }
        this.subscription = null;
      }
    });
  }

  @HostListener('scroll', ['$event'])
  onPostingScroll($event) {
    if (this.isPostingDownloading || this.hasMoreItems == false) return;
    this.getPostingList(false);
  }

  resetPaging() {
    if (this.postingScrollContainer != undefined && this.postingScrollContainer.nativeElement != undefined) {
      this.postingScrollContainer.nativeElement.scrollTop = 0;
    }
    this.loadedJobCount = 0;
    this.hasMoreItems = true;
    this.opportunityList = [];//reset list
  }

  ngOnInit(): void {
    this._userInfoService.checkMyPermission('Create Opportunity').subscribe((result: any) => {
      this.hasCreatePostingPermission = result;
      if (this.hasCreatePostingPermission) {
        this.searchBoxSize = '67%';
        this.smSearchBoxSize = '72%';
      }
    });
    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);
    if (this.userType == 3) {
      this.searchBoxSize = '67%';
      this.mdSearchBoxSize = this.searchBoxSize;
    }
    if (this.userType == 2) {
      this.searchBoxSize = '62%';
      this.mdSearchBoxSize = '50%';
    }
    this.breakpointObserver
      .observe([
        Breakpoints.Tablet,
        Breakpoints.Handset
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.screenBreakPoint = true;
        } else {
          this.screenBreakPoint = false;
        }
      });

    this.getMySearches();

    this.userEnteredSearchText.pipe(debounceTime(800), distinctUntilChanged()).subscribe(value => {
      this.modelChangeSearch(value);
    });

    // 'selects' the posting that is shown in detail view by using the router
    // this could probably be done via intercomponent communication
    this.route.url.subscribe(() => {
      if (this.subscription == null && this.route.firstChild) {
        this.subscription = this.route.firstChild.params.subscribe(params => {
          if (params.postingId && !isNaN(Number(params.postingId))) {
            this.selectedId = params.postingId;
          } else if (this.opportunityList.length) {
            this.selectedId = this.opportunityList[0].id;
          }
        });
      }
    });


    this.userEnteredSearchText.pipe(debounceTime(600), distinctUntilChanged()).subscribe(value => {
      this.modelChangeSearch(value);
    });

    // When the parent component finishes loading the opportunity list,
    // format dates and fetch all company logos.
    this.route.queryParams.subscribe(params => {

      if (params['searchParams']) {
        this.resetPaging();
        var obj = this.createSearchObj(params);
        this._opportunityService.getOpportunities(obj).subscribe((result: any) => {
          if (result['opportunityList'].length > 0) {
            this.postingFilterType = result['opportunityFilterType'];
            this.opportunityList = [];
            var that = this;
            result['opportunityList'].forEach(function (opp) {
              let a: Opportunity = {
                id: opp.id,
                title: opp.title,
                company: opp.company,
                streetaddress: opp.streetaddress,
                postiontype: opp.postiontype,
                postingdate: opp.postingdate,
                logo: opp.logo,
                alt: opp.alt,
                city: opp.city,
                state: opp.state,
                zip: opp.zip,
                name: opp.name,
                opportunity_type: opp.opportunity_type,
                overall_score: opp.overall_score,
                score_img: opp.score_img,
                skills: opp.skills,
                locations: opp.locations,
                status: opp.status,
                quantity: opp.quantity,
                TotalRecruited: opp.TotalRecruited,
                published: opp.published
              };
              that.opportunityList.push(a);
            });
              _.each(that.opportunityList, function (item) {
                item.postingdate = that.getPostingDay(item.postingdate);
              });
            this.filterOpportunitiesList();
            this._router.navigate(['postings', this.filteredOpportunity.length > 0 ? this.filteredOpportunity[0].id : 0], { queryParams: this.parameter });            
          } else {
            this.opportunityList = [];
            this.filterOpportunitiesList();
            this._router.navigate(['postings', 0], { queryParams: this.parameter });
          }
        },
          error => {
            this.ErrorMessage = "Unable to load Opportunity";
            console.log(this.ErrorMessage);
          }
        );
      } else {
        this.loadMorePostings.subscribe(res => {
          if (res != undefined && res.hasMorePosting == false) {
            this.hasMoreItems = false;
          }          
        });
        this.opportunityFilterType.subscribe(res => {
          this.postingFilterType = res;
        });
        this.opportunitiesLoaded.subscribe(result => {
          this.opportunityList = result;
          let that = this;
            _.each(this.opportunityList, function (item) {
              item.postingdate = that.getPostingDay(item.postingdate);
            });

          this.loadedJobCount = this.opportunityList.length;
          this.filterOpportunitiesList();

          if (this.filteredOpportunity.length === 0) {
            this.showDetail = false;
          } else {
            this._router.navigate(['postings', this.filteredOpportunity[0].id], { queryParams: this.parameter });
          }
        });
      }
    });

    if (this.screenBreakPoint) {
      this.manageScrollingForHandset();
    }

  }

  manageScrollingForHandset() {
    this.scrollDispatcher.scrolled().subscribe((x: CdkScrollable) => {
      if (!x) {
      }
      if (this.screenBreakPoint) {
        this.onPostingScroll(null);
      }
    });
  }

  getPostingDay(postingdate) {
    const diffInMs = Math.abs(<any>new Date() - <any>new Date(postingdate));
    const daysdiff = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    if (daysdiff == 0) {
      postingdate = 'Today';
    }
    if (daysdiff == 1) {
      postingdate = 'Yesterday';
    }
    if (daysdiff > 1) {
      postingdate = daysdiff + ' days Ago';
    }
    return postingdate;
  }

  createSearchObj(params) {
    if (params['searchParams']) {
      this.parameter = {
        searchParams: params['searchParams']
      }
      var obj = JSON.parse(params['searchParams']);
      this.selectData = JSON.parse(params['searchParams']);
      if (this.selectData.searchText) {
        this.searchText = this.selectData.searchText;
      }
      if (obj.o_work_setup) {
        const isAnyWorkSetup = _.where(obj.o_work_setup.value, { name: "Any", completed: true });
        if (isAnyWorkSetup.length > 0) {
          var workSetupData = _.pluck(obj.o_work_setup.value, 'id');
        } else {
          var workSetupData = _.where(obj.o_work_setup.value, { completed: true });
          var workSetupData = _.pluck(workSetupData, 'id');
        }
        obj.o_work_setup = workSetupData;
      }

      if (obj.o_match_bucket) {
        const isAnyMatchBucket = _.where(obj.o_match_bucket.value, { name: "Any", completed: true });
        if (isAnyMatchBucket.length > 0) {
          var matchBucketData = _.pluck(obj.o_match_bucket.value, 'id');
        } else {
          var matchBucketData = _.where(obj.o_match_bucket.value, { completed: true });
          var matchBucketData = _.pluck(matchBucketData, 'id');
        }
        obj.o_match_bucket = matchBucketData;
      }

      if (obj.o_commitment) {
        const isAnyCommitmentData = _.where(obj.o_commitment.value, { type: "Any", completed: true });
        if (isAnyCommitmentData.length > 0) {
          var commitmentData = _.pluck(obj.o_commitment.value, 'id');
        } else {
          var commitmentData = _.where(obj.o_commitment.value, { completed: true });
          var commitmentData = _.pluck(commitmentData, 'id');
        }
        obj.o_commitment = commitmentData;
      }

      if (obj.o_education) {
        var educationLevelData = _.where(obj.o_education.value, { completed: true });
        var educationLevelData = _.pluck(educationLevelData, 'id');
        obj.o_education = educationLevelData;
      }
    }

    if (obj == undefined) {
      obj = { pageSize: this.loadedJobCount, sortBy: this.sortBy, sortOrder: this.sortOrder, SEARCH: this.searchText, postingType: this.postingFilterType };
    }
    else {
      obj.pageSize = this.loadedJobCount;
      obj.sortBy = this.sortBy;
      obj.sortOrder = this.sortOrder;
      obj.SEARCH = this.searchText;
      obj.postingType = this.postingFilterType;
    }
    return obj;
  }
  clearSearch() {
    this.searchText = '';
    this.modelChangeSearch(this.searchText);
  }
  modelChangeSearch(data) {
    this.opportunityList = [];
    this.resetPaging();
    if (this.parameter['searchParams']) {
      var search = JSON.parse(this.parameter['searchParams']);
      search.searchText = data;
      this.parameter = {
        searchParams: JSON.stringify(search)
      }
    } else {
      this.parameter = {
        searchParams: JSON.stringify({ searchText: data })
      }
    }
    this.searchText = data;
    this.getPostingList(true);
  }

  getMySearches() {
    var obj = {
      search_type: 'posting'
    }
    this._talentApiService.getMySearches(obj).subscribe((result: any) => {
      if (result) {
        this.saveSearchList = result.result;
      }
    },
      error => {
        this.ErrorMessage = "Unable to load Opportunity";
        console.log(this.ErrorMessage);
      }
    );
  }

  openFilterDialog() {
    const dailog = this._dialog.open(OpportunitySearchFilterDialogComponent, {
      height: 'auto',
      width: '100%',
      data: this.selectData,
      panelClass: this.screenBreakPoint ? 'posting_popup' : ''
    }).afterClosed().toPromise()
      .then((result) => {
        if (result == null || result.data == undefined || result.selectedData == undefined) {
        }
        else {
          this.resetPaging();
          this.selectData = result.selectedData;
          this.parameter = {
            searchParams: JSON.stringify(this.selectData)
          }
          // this.filterOpportunitiesList();
          this.getMySearches();
          if (this.userType == 2) {
            this._router.navigate(['postings', this.filteredOpportunity.length > 0 ? this.filteredOpportunity[0].id : 0], { queryParams: this.parameter });
          }
        }
      });
  }

  openPostingModal(modell) {
    if (modell == 'employer') {
      let dialog = this._dialog.open(CreatePostingDialogComponent, {
        height: 'auto',
        width: '100%',
        disableClose: true,
        panelClass: this.screenBreakPoint ? 'posting_popup' : ''
      });
      dialog.afterClosed().subscribe((result) => {
        if (result != undefined && result.isNewPosting != undefined && result.isNewPosting == true) {
          //this.postingFilterType = 'DRAFT';
        }
      });
    }
  }

  getPostingList(isSearchApplied) {
    if (this.isPostingDownloading) return;
    this.isPostingDownloading = true;
    if (this.screenBreakPoint) {
      this.changeDetector.detectChanges();
    }
    var objFilterParam = this.createSearchObj(this.parameter);
    this._opportunityService.getOpportunities(objFilterParam).subscribe(result => {
      if (result['opportunityList'].length > 0) {
        var that = this;
          _.each(result['opportunityList'], function (item) {
            item.postingdate = that.getPostingDay(item.postingdate);
          });
        this.appendMorePostings(result['opportunityList']);
      }
      else {
        this.hasMoreItems = false;
      }
      this.filterOpportunitiesList();
      setTimeout(x => {
        this.isPostingDownloading = false;
        this.changeDetector.detectChanges();
      }, 1000);
      if (isSearchApplied) {
        this._router.navigate(['postings', this.filteredOpportunity.length > 0 ? this.filteredOpportunity[0].id : 0], { queryParams: this.parameter });
      }
    }, err => { this.isPostingDownloading = false; });
  }


  //Refresh posting list when a  new post added
  //postingId =0 if a post deleted
  refreshPostingList(postingId, isNewPosting = false) {
    this.resetPaging();
    if (isNewPosting) { this.postingFilterType = 'DRAFT'; }

    this._opportunityService.getOpportunities({ pageSize: this.loadedJobCount, postingType: isNewPosting ? 'DRAFT' : this.postingFilterType }).subscribe(result => {
      if (result['opportunityList'].length > 0) {
        var that = this;
        that.opportunityList = result['opportunityList'];
          _.each(that.opportunityList, function (item) {
            item.postingdate = that.getPostingDay(item.postingdate);
          });
        this.filterOpportunitiesList();
        this._router.navigate(['postings', postingId == 0 ? that.filteredOpportunity[0].id : postingId]);
      } else {
        this.filterOpportunitiesList();
        this._router.navigate(['postings', 0]);
      }
    })
  }

  appendMorePostings(newItems) {
    if (this.opportunityList != null && this.opportunityList.length != undefined) {
      for (var i = 0; i < newItems.length; i++) {
        if (this.opportunityList.find((o) => o.id === newItems[i].id) === undefined) {
          this.opportunityList.push(newItems[i]);
        }
      }
    }
    this.loadedJobCount = this.opportunityList.length;
  }

  checkIfNewPosting() {
    if (this.userType == 2) { return; }
    if (this.selectedId != null && parseInt(this.selectedId) > 0) {
      if (this.opportunityList.filter(x => x.id == this.selectedId && x.published == '2').length > 0) {
        this.postingFilterType = 'DRAFT';
      }
    }
  }


  filterOpportunitiesList() {
    this.filteredOpportunity = [];
    this.filteredOpportunity = this.opportunityList; // Apply more filters

  }

  toggleDetail() {
    this.showDetail = true;
    this._postingService.backButtonShareData.next(true);
  }

  changeFlter(value) {
    this.postingFilterType = value;
    this.searchText = '';
    this.resetPaging();
    this.getPostingList(true);
  }

  setSort(sort) {
    this.resetPaging();
    if (sort == this.sortField) {
      this.sortOrder = (this.sortOrder == 'ASC') ? 'DESC' : 'ASC';
    } else {
      this.sortField = sort;
      this.sortOrder = 'ASC';
    }

    switch (sort) {
      case "title":
        this.sortBy = "title";
        this.sortShowText = "Name";
        break;
      case "postingdate":
        this.sortBy = "postingdate";
        this.sortShowText = "Date";
        break;
      default:
        break;
    };
    this.getPostingList(true);
  }

  editSearch(data) {
    data['search_type'] = 'posting';
    const dailog = this._dialog.open(SaveFilterDialogComponent, {
      height: 'auto',
      width: '70vw',
      data: data,
      panelClass: this.screenBreakPoint ? 'posting_popup' : ''
    }).afterClosed().toPromise()
      .then((data) => {
        if (data.result == null) {
        }
        else {
          this.getMySearches();
          this._talentApiService.snackbarMessage(data.result.msg);
        }
      });
  }

  loadMatchingPost() {
    //location.href = '/postings/matched';
    this._router.navigateByUrl('/empty', { skipLocationChange: true }).then(() => {
      this._router.navigate(['/postings/matched']);
    });
  }

  applySearch(data) {    
    this.parameter = {
      searchParams: data.search_parameters
    }
    this.filterOpportunitiesList();
    this._router.navigate(['postings', this.filteredOpportunity.length > 0 ? this.filteredOpportunity[0].id : 0], { queryParams: this.parameter });
  }
}
