<mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="title-padding medium-24">My User Groups</h2>
    <div fxLayout="row" fxLayoutGap="20px">
        <button *ngIf="!largeScreen" mat-stroked-button (click)="back()">BACK</button>
        <button mat-raised-button color="primary" (click)="createGroup()">CREATE NEW GROUP</button>
    </div>  
</mat-card-title>
<mat-divider inset></mat-divider>
<mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="end start" class="padding-above">
        <mat-form-field fxFlex="25">
            <input matInput (keyup)="applyFilter($event)" #input placeholder="Search">
        </mat-form-field>
    </div>
    <table mat-table [dataSource]="dataSource" fxFlex="100" matSort>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="regular-18"> Group Name </mat-header-cell>
            <mat-cell *matCellDef="let row" class="medium-18"> {{ row.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="numUsers">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="regular-18"> Number of Users </mat-header-cell>
            <mat-cell *matCellDef="let row" class="regular-16"> {{ row.numUsers }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dateAdded">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="regular-18"> Date Added </mat-header-cell>
            <mat-cell *matCellDef="let row" class="regular-16"> {{ row.dateAdded }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accessRights">
            <mat-header-cell *matHeaderCellDef class="regular-18"> Access Rights </mat-header-cell>
            <mat-cell *matCellDef="let row" class="regular-16 clickable" (click)="viewEditRights(row)"> {{ row.accessRights }} </mat-cell>
        </ng-container>
        <!-- Header Column Labels -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
</mat-card-content>

