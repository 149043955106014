import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface EditData {
	subComponent: string;
	seedData: any;
  }

@Component({
	selector: 'profile-edit',
	templateUrl: './profile-edit.component.html',
	styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent {
	constructor(
		public dialogRef: MatDialogRef<ProfileEditComponent>,
		@Inject(MAT_DIALOG_DATA) public input: EditData)
		{
			dialogRef.backdropClick().subscribe(result => {
				dialogRef.disableClose = true; //disable default close operation
				dialogRef.close();
			});
		}

	onNoClick(): void {
		this.dialogRef.close();
	}
	
	close(): void {
		this.dialogRef.close();
	}
}