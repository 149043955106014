import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'searchTextPipePro',
	pure: false
})
export class SearchTextPipe implements PipeTransform {

	// transform(value: any, args?: any): any {
	// 	return null;
	// }
	transform(items: any[], filter: String): any {
		if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.user.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    }
}