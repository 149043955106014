<h1 mat-dialog-title *ngIf="currentSkill.id && currentSkill.reviewed == 'Y'" class="modal-title">Edit Skill</h1>
<h1 mat-dialog-title *ngIf="currentSkill.id && currentSkill.reviewed == 'N'" class="modal-title">Review Skill</h1>
<h1 mat-dialog-title *ngIf="!currentSkill.id" class="modal-title">Create Skill</h1>

<div mat-dialog-content fxLayout="column">
  <form [formGroup]="skillForm" fxFlex="100" fxLayout="column">
    <div fxFlex="100" fxLayout="row" >
      <mat-form-field color="accent" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-error *ngIf="skillForm.get('name').hasError('maxlength')">
          Name must be upto 80 characters
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxLayout="row"  fxLayoutGap="15px">
      <mat-form-field fxFlex="80" color="accent" appearance="outline">
        <mat-label>Merge with existing skill</mat-label>
        <input type="text"
               placeholder="Pick an existing skill"              
               matInput
               formControlName="mergeSkill"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let skill of skillFilteredOptions | async" (onSelectionChange)="setTargetSkill(skill)"  [value]="skill.name">
            {{skill.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start" >
        <button mat-raised-button FxlayoutAlign="end end" [disabled]="mergeTargetSkill===0" class="btn-merge-height" (click)="skillMerge()" color="primary">Merge</button>
      </div>
    </div>
    <div fxFlex="100" fxLayout="row">
      <mat-form-field color="accent" appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="description">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxLayout="row">  
      <mat-form-field color="accent" appearance="outline">
        <mat-label>Parent</mat-label>
        <mat-select formControlName="parent">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let skill of parents" [value]="skill.id">
            {{skill.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <div fxFlex="10">
    <button mat-raised-button color="warn" (click)="skillDelete()" *ngIf="currentSkill.id">Delete</button>
  </div>
  <div fxFlex="20">
    <button mat-raised-button class="pull-right" color="primary" (click)="skillSave()">Save</button>
    <button mat-raised-button class="pull-right" style="margin-right: 8%" (click)="dialogReturn()">Cancel</button>
  </div>

</div>
