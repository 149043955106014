<mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="title-padding medium-24">My Users</h2> 
    <div *ngIf="!largeScreen">
        <button mat-stroked-button (click)="back()">BACK</button>
    </div>
</mat-card-title>
<mat-divider inset></mat-divider>
<div fxLayout="row" fxLayoutAlign="end center">
    <mat-form-field fxFlex="25">
      <input matInput (keyup)="this.dataSource.filter = $event.target.value;" placeholder="Search">
    </mat-form-field>
</div>
<mat-divider inset></mat-divider>
<mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
    <mat-table [dataSource]="dataSource" matSort fxFlex="100">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef fxFlex="30" class="regular-18" mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let row" fxFlex="30" class="medium-18"> {{ row.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef fxFlex="35" class="regular-18"mat-sort-header> Email </mat-header-cell>
            <mat-cell *matCellDef="let row" fxFlex="35" class="regular-16"> {{ row.email }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="groups">
            <mat-header-cell *matHeaderCellDef fxFlex="20" class="regular-18" mat-sort-header> Groups </mat-header-cell>
            <mat-cell *matCellDef="let row let rowIndex = index" fxFlex="20" class="regular-16">
                <mat-select [value]="row.groups" multiple (selectionChange)="calculateDifference(row, $event)" (openedChange)="onClose(row, $event)"> <!-- row.id is the id of the row temp variable which is the user's id-->
                    <div class="mat-option">
                        <!-- LINK TO CREATE GROUP DIALOG -->
                        <a (click)="createNewUser()"><mat-icon>add</mat-icon>&nbsp;Create Group</a>
                    </div>
                    <mat-option *ngFor="let group of companyGroups" [value]="group.name">{{ group.name }}</mat-option>
                </mat-select>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef fxFlex="15" class="regular-18" mat-sort-header> Account Status </mat-header-cell>
            <mat-cell *matCellDef="let row" fxFlex="15" class="regular-16">
                <mat-select *ngIf="row.status != 'Invite Pending' && row.verified == 1" [(ngModel)]="row.status" (selectionChange)="updateAccountStatus(row)"> <!-- row.id is the id of the row temp variable which is the user's id-->
                    <mat-option value="Active">Active</mat-option>
                    <mat-option value="Inactive">Inactive</mat-option>
                </mat-select>
                <p *ngIf="row.status == 'Invite Pending'" class="color-warn">Invite Pending</p>
                <p *ngIf="row.status != 'Invite Pending' && row.verified == 0" class="color-warn">Unverified</p>
            </mat-cell>
        </ng-container>
        <!-- Header Column Labels -->
        <mat-header-row *matHeaderRowDef="this.columnHeaders"></mat-header-row>
        <mat-row *matRowDef="let row; columns: this.columnHeaders;"></mat-row>
    </mat-table>
    
</mat-card-content>
