import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrrides = <any>{
    'swipe': { velocity: 0.4, threshold: 20, direction: Hammer.DIRECTION_HORIZONTAL  }, //direction: Hammer.DIRECTION_ALL
    'pinch': { enable: false },
    'rotate': { enable: false },
    'pan': { enable: false }
  }
}
