import { Component, OnInit, Inject } from '@angular/core';
import { AdminApiService } from '../../Services/admin-api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { TalentApiService } from '../../Services/talent-api.service';
import { map, startWith } from 'rxjs/operators';
import { SkillNode } from '../../Models/SkillNode';



declare var $: any;
@Component({
  selector: 'app-admin-skills',
  templateUrl: './admin-skills.component.html',
  styleUrls: ['./admin-skills.component.css'],
})

export class AdminSkillsComponent implements OnInit {
  // public editing: any;
  nestedTreeControl: NestedTreeControl<SkillNode>;
  nestedDataSource: MatTreeNestedDataSource<SkillNode>;
  dataChange: BehaviorSubject<SkillNode[]> = new BehaviorSubject<SkillNode[]>([]);
  public treeData = [];
  public tree: any;
  public revSkills = [];
  public parents = [];
  public loading = false;
  public skilldesclength = 1000;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;


  constructor(public _dialog: MatDialog, private _talentAdminApiService: AdminApiService, private _talentApiService: TalentApiService) {
    this.nestedTreeControl = new NestedTreeControl<SkillNode>(node => this._getChildren(node));
    this.nestedDataSource = new MatTreeNestedDataSource<SkillNode>();

    this.dataChange.subscribe(data => this.nestedDataSource.data = data);
  }

  ngOnInit() {
    this.getSkillList();
    jQuery.noConflict();
  }

  getSkillList() {
    this.loading = true;
    let tempTree = [];
    let stree = [];
    this._talentAdminApiService.getSkillsTree()
      .subscribe(
        (result: any) => {
          stree = result;
          stree.forEach(function (ele) {
            tempTree.push(ele);
          });

          this.treeData = Object.assign(tempTree);
          this.nestedDataSource.data = this.treeData;
          this.elligibleParents(this.treeData);
          this.loading = false;

        },
        error => {
          this.loading = false;
          console.log("ERROR: Unable to get Skill List");
        }
      );
  }

  hasChild = (_: number, node: SkillNode) => !!node.children && node.children.length > 0;

  private _getChildren(node: SkillNode) {
    return observableOf(this.findNode(this.treeData, node.id).children)
  }
  refresh() {
    this.parents = [];
    this.getSkillList();
  }

  openEditReviewModal(id = false) {
    const dialogRef = this._dialog.open(SkillDialog, {
      height: 'auto',
      width: '66.66%',
      data: {
        "node": id ? this.findNode(this.treeData, id) : {},
        "parentChoices": this.parents,
        "newSkill": id ? false : true
      }
    });
    dialogRef.afterClosed().subscribe(result => {      
      if (result.del) {
        this.skillDelete(id);
      }
      if (result.refresh) {
        this.refresh()
      }
    });
  }

  skillDelete(id) {
    if (confirm("Are you sure you want to want to delete this Skill?")) {
      this._talentAdminApiService.deleteSkill(id)
        .subscribe(
          (result: any) => {
            this._talentApiService.snackbarMessage("Skill has been deleted.");

            this.refresh()
          },
          error => {
            this._talentApiService.snackbarMessage(error);

          }
        );
    }
  }

  findNode(data, id) {
    for (const node of data) {
      if (node.id == id.toString()) {
        return node;
      }
      if (this.findNode(node.children, id)) {
        return this.findNode(node.children, id);
      }
    }
  }
  elligibleParents(data) {
    for (const node of data) {
      if (node.reviewed == "Y") {
        this.parents.push(node);
      }
      this.elligibleParents(node.children);
    }
  }



}



export interface skillData {
  node: any,
  parentChoices: any,
  newSkill: boolean
}


@Component({
  // selector: 'get-answers-alert-dialog',	
  templateUrl: './admin-skills-dialog.component.html',
  styleUrls: ['./admin-skills.component.css']
})
export class SkillDialog implements OnInit {
  currentSkill: SkillNode;
  mergeSkillList: SkillNode[] = [];
  mergeTargetSkill: number= 0;

  skillForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.maxLength(80)]),
    description: new UntypedFormControl(''),
    parent: new UntypedFormControl(''),
    mergeSkill: new UntypedFormControl('')
  });
  skillFilteredOptions: Observable<SkillNode[]>;
  public parents = [];
  public newSkill: boolean;

  constructor(
    private _talentAdminApiService: AdminApiService, private _talentApiService: TalentApiService,
    public dialogRef: MatDialogRef<SkillDialog>, @Inject(MAT_DIALOG_DATA) public input: skillData) {
    this.parents = Object.assign(input.parentChoices);
    this.currentSkill = Object.assign(input.node);
    this.newSkill = input.newSkill;
  }

  ngOnInit() {
    this.skillForm.patchValue({
      name: this.currentSkill.name,
      description: this.currentSkill.description,
      parent: this.currentSkill.parent,
      mergeSkill: ['']
    });

    this.parents.forEach(element => this.mergeSkillList.push({
      name: element.name,
      id: element.id,
      description: "",
      parent: null,
      reviewed: null,
      children: null
    }));

    this.skillFilteredOptions = this.skillForm.get('mergeSkill')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

  }
  dialogReturn(deleteSkill = false, refresh = false) {
    this.dialogRef.close({ del: deleteSkill, refresh: refresh });
  }

  private _filter(value: string): SkillNode[] {
    const filterValue = value.toLowerCase();
    return this.mergeSkillList.filter(function (item) {
      return (item.name.toLowerCase().includes(filterValue))
    }).sort((item1, item2) => {
      if (item1.name > item2.name) {
        return 1;
      }
      return -1;      
    });
  }

  skillDelete() {
    this.dialogReturn(true, true);
  }

  skillSave() {
    this.currentSkill.name = this.skillForm.value.name;
    this.currentSkill.description = this.skillForm.value.description;
    this.currentSkill.parent = this.skillForm.value.parent;
    if (this.newSkill) {
      this.modalAddNew()
    }
    else if (this.currentSkill && this.currentSkill.name) {
      this._talentAdminApiService.editSkill(this.currentSkill)
        .subscribe(
          (result: any) => {
            this._talentApiService.snackbarMessage("Skill '" + this.currentSkill.name + "' has been saved.");

            this.dialogReturn(false, true);
          },
          error => {
            this._talentApiService.snackbarMessage(error);
          }
        );
    } else {
      this._talentApiService.snackbarMessage("Invalid auth role. Please check your parameters and try again.");
    }
  }

  modalAddNew() {
    this._talentAdminApiService.createSkill(this.currentSkill)
      .subscribe(
        (result: any) => {
          this._talentApiService.snackbarMessage("Skill '" + this.currentSkill.name + "' has been saved.");
          this.dialogReturn(false, true);
        },
        error => {
          this._talentApiService.snackbarMessage("Error: Please check your parameters and try again.");
        }
      );
  }

  checkValidSkill(skillValue) {
    let inputValue = skillValue.target.value;
    var skillFilter = this.mergeSkillList.filter(x => x.name == skillValue);
    if (skillFilter != null && skillFilter?.length > 0) {
    }   
  }

  setTargetSkill(obj) {   
    this.mergeTargetSkill = obj.id;
  }

  skillMerge() {    
    var sourceSkill = this.currentSkill.id;
    var targetSkill = this.mergeTargetSkill;

    this._talentAdminApiService.mergeSkill(sourceSkill, targetSkill)
      .subscribe(
        (result: any) => {
          if (result) {
            this._talentApiService.snackbarMessage("Skill merged successfully.");
            this.dialogReturn(false, true);
          } else {
            this._talentApiService.snackbarMessage("Unable to merge skill.");             
          }
        }
      );
  }
}
