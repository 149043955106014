<div fxLayout="row wrap" fxFlex="100" fxFlex.xs="100" [ngClass]="{'':!isMobileDevice, 'small-device-padding':isMobileDevice}">
  <!--<h1 mat-dialog-title fxFlex="50">
    Job Search Filter
  </h1>-->
  <div fxFlex="100" fxFlex.xs="100" fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
    <h1 mat-dialog-title fxFlex="50" [ngClass.xs]="'title-margin-clear'" fxFlex.xs="70">
      Job Search Filter
    </h1>
    <div fxFlex.xs="30" fxFlex="50" fxLayout="row wrap" fxLayoutAlign="end center">
      <button class="content-margin" fxHide.xs fxShow mat-stroked-button matTooltip="Clear All Filters" (click)="clearFilter()"> CLEAR </button>
      <button class="content-margin" fxHide.xs fxShow mat-raised-button color="primary" (click)="Filter()"> SEARCH </button>
      <button mat-icon-button fxLayoutAlign="end center" tabindex="-1" (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div fxFlex.xs="100" fxHide fxShow.xs fxLayoutAlign.xs="end end">    
      <button class="content-margin"  mat-stroked-button matTooltip="Clear All Filters" (click)="clearFilter()"> CLEAR </button>
      <button class="content-margin"  mat-raised-button color="primary" (click)="Filter()"> SEARCH </button>   
  </div>
  <hr class="margin-24" fxFlex="100">
  <mat-dialog-content fxFlex="100" fxFlex.xs="100" cdkScrollable fxLayoutAlign.xs="center start">
    <div fxLayout="row wrap" fxFlex.xs="100" fxLayoutAlign.xs="center center" fxLayoutAlign="start start" class="">
      <div fxLayout="row" fxFlex="100" class="saved-search-div">
        <div fxFlex="80" fxFlex.sm="70%" fxFlexOffset="20px">
          <h5 class="mat-small">Save this search for easy search experience next time.</h5>
        </div>
        <div fxFlex fxLayoutAlign="end center">
          <button (click)="saveSearch()" mat-button color="primary">
            <i class="fa fa-floppy-o"></i> SAVE SEARCH
          </button>
        </div>
      </div>
      <h4 class="remove-margin mat-h4" fxFlex="100">Match Bucket</h4>
      <div fxLayout.xs="row" fxFlex="100" fxLayoutAligmnet="start">
        <div fxLayout.xs="column" fxLayout="row wrap" fxFlex="100" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <div class="mat-chkbox-min-width box-padding padding-top-5" *ngFor="let el of matchBucket.value">
            <mat-checkbox (change)="ChangeMatchBucket(el)" [(ngModel)]="el.completed">
              {{el.name}}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <h4 class="remove-margin  mat-h4"
          fxFlex="100">
        Location
      </h4>
      <div fxLayout="row"  class="padding-top-5" fxFlex="100">
        <mat-form-field color="accent"
                        appearance="outline"
                        [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <mat-label>Locations</mat-label>
          <mat-chip-list #locationList aria-label="Location selection">
            <mat-chip *ngFor="let loc of locations"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="removeLocation(loc)">
              {{loc}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Add multiple prefered Locations"
                   #locationInput
                   [formControl]="locationCtrl"
                   [matAutocomplete]="autoAddress"
                   [matChipInputFor]="locationList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (matChipInputTokenEnd)="addLocation($event)"
                   (ngModelChange)="getLocation($event)">
          </mat-chip-list>
          <mat-autocomplete #autoAddress="matAutocomplete" (optionSelected)="selectedLocation($event)">
            <mat-option *ngFor="let item of searchResult" [value]="item">
              {{ item.formatted_address }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <h4 class="remove-margin mat-h4" fxFlex="100">Prefered Job Titles</h4>
      <div fxLayout="row" fxFlex="100" class="padding-top-5">
        <mat-form-field color="accent"
                        appearance="outline"
                        [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <mat-label>Titles</mat-label>
          <mat-chip-list #chipList aria-label="Title selection">
            <mat-chip *ngFor="let value of title" [selectable]="selectable" [removable]="removable"
                      (removed)="removeTitle(value)">
              {{value}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Add multiple prefered Job Titles" #titleInput [formControl]="titleCtrl"
                   [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (matChipInputTokenEnd)="addTitle($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTitle($event)">
            <ng-container *ngFor="let jtitle of filteredTitles | async">
              <mat-option *ngIf="!title.includes(jtitle)" [value]="jtitle">
                {{jtitle}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <h4 class="remove-margin mat-h4" fxFlex="100">Work Setup</h4>
      <div fxLayout="row" fxFlex="100">
        <div fxLayout.xs="column" fxLayout="row wrap" fxFlex="100" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <div class="mat-chkbox-min-width box-padding padding-top-5" *ngFor="let ws of workSetup.value">
            <mat-checkbox (change)="ChangeworkSetup(ws)" [(ngModel)]="ws.completed">
              {{ws.name}}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <h4 class="remove-margin mat-h4" fxFlex="100">Commitment</h4>
      <div fxLayout="row" fxFlex="100">
        <div fxLayout.xs="column" fxLayout="row wrap" fxFlex="100" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <div class="mat-chkbox-min-width box-padding padding-top-5" *ngFor="let cm of commitment.value">
            <mat-checkbox (change)="ChangeCommitment(cm)" [(ngModel)]="cm.completed">
              {{cm.type}}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <h4 class="remove-margin mat-h4" fxFlex="100">Education Level</h4>
      <div fxLayout="row" fxFlex="100">
        <div fxLayout.xs="column" fxLayout="row wrap" fxFlex="100" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <div class="mat-chkbox-min-width box-padding padding-top-5" *ngFor="let el of educationLevel.value">
            <mat-checkbox (change)="ChangeEducation(el)" [(ngModel)]="el.completed">
              {{el.name}}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <h4 class="remove-margin mat-h4" fxFlex="100">Salary</h4>
      <div fxLayout="row wrap" class="padding-top-5" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="0px" fxFlex="100">
        <mat-form-field color="accent" appearance="outline" fxFlex="grow" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <mat-label>Min salary:</mat-label>
          <input matInput type="number" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="minValue=$event" [ngModel]="minValue">
        </mat-form-field>
        <!--<mat-form-field color="accent" appearance="outline" fxFlex="grow" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
          <mat-label>Max salary:</mat-label>
          <input matInput type="number" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="maxValue=$event" [ngModel]="maxValue">
        </mat-form-field>-->
      </div>

    </div>
  </mat-dialog-content>
</div>
