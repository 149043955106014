<div id="candidate-profile-info" fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="profile-box" fxLayout="row" fxFlex="100" fxLayoutAlign.lt-md="start stretch" fxLayoutAlign.gt-sm="start center" fxLayoutGap="15px">
    <div fxShow fxHide.lt-md fxLayoutAlign="center start" fxFlex="15" fxFlex.md="20">
      <img matListAvatar
           *ngIf="ownProfile"
           (click)="openEdit('candidateInfo')"
           class="pointer"
           [ngClass.lt-md]="'small-profile-pic'"
           [ngClass.gt-xs]="'medium-profile-pic'"
           src="{{candidateInfo.candidateInfo?.profilePic}}"
           onError="this.src='../../assets/img/User-blue-icon.png';">
      <img matListAvatar
           *ngIf="!ownProfile"
           [ngClass.lt-md]="'small-profile-pic'"
           [ngClass.gt-xs]="'medium-profile-pic'"
           src="{{candidateInfo.candidateInfo?.profilePic}}"
           onError="this.src='../../assets/img/User-blue-icon.png';">
    </div>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex.lt-md="100" fxFlex.gt-sm="85" fxFlex.md="80">
      <div fxHide fxShow.lt-md fxLayout="row" fxFlex="100" fxLayoutAlign="center center" class="border-bottom">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div>
            <img matListAvatar *ngIf="ownProfile" (click)="openEdit('candidateInfo')"
                 [ngClass.lt-md]="'small-profile-pic'" [ngClass.gt-xs]="'medium-profile-pic'"
                 src="{{candidateInfo.candidateInfo?.profilePic}}"
                 onError="this.src='../../assets/img/User-blue-icon.png';">
            <img matListAvatar *ngIf="!ownProfile" [ngClass.lt-md]="'small-profile-pic'"
                 [ngClass.gt-xs]="'medium-profile-pic'" src="{{candidateInfo.candidateInfo?.profilePic}}"
                 onError="this.src='../../assets/img/User-blue-icon.png';">
          </div>
          <div fxLayout="row">
            <span class="mat-subheading-1 margin16px">{{candidateInfo.candidateInfo.firstName}} {{candidateInfo.candidateInfo.lastName}}</span>
            <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('candidateInfo')">edit</mat-icon>
          </div>
          <div fxLayout="row">
            <span class="mat-display-1">{{candidateInfo.sectorTitle.title ? candidateInfo.sectorTitle.title : '&nbsp;'}}</span>
            <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('sectorTitle')">edit</mat-icon>
          </div>
          <div fxLayout="row" *ngIf="!ownProfile && !showContactDetails && this.postingId>0">
            <button mat-button color="primary" (click)="requestContact(candidateInfo.id)">
              <mat-icon color="primary" inline="true">contacts</mat-icon>
              GET CONTACT DETAILS
            </button>
          </div>           
        </div> 
      </div>
      <div fxShow fxHide.lt-md fxLayout="column" fxLayoutAlign="space-between" class="border-bottom">
        <div fxLayout="row">
          <div fxFlex.gt-sm="45" fxLayout="column">
            <div fxLayout="row" class="padding-bottom">
              <span class="mat-subheading-2">{{candidateInfo.candidateInfo.firstName}} {{candidateInfo.candidateInfo.lastName}}</span>
              <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('candidateInfo')">edit</mat-icon>
            </div>
            <div fxLayout="row">
              <span class="mat-display-1">{{candidateInfo.sectorTitle.title ? candidateInfo.sectorTitle.title : '&nbsp;'}}</span>
              <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('sectorTitle')">edit</mat-icon>
            </div>
          </div>
          <div fxFlex.gt-sm="55" fxLayoutAlign="end start" *ngIf="!ownProfile && !showContactDetails && this.postingId>0">
            <button mat-button color="primary" (click)="requestContact(candidateInfo.id)">
              <mat-icon color="primary" inline="true">contacts</mat-icon>
              GET CONTACT DETAILS
            </button>
          </div>
          <div fxLayout="row" *ngIf="!ownProfile && showContactDetails" fxLayoutAlign="end" fxFlex.gt-sm="3"  class="padded">            
            <mat-divider fxHide.lt-md inset="true" vertical="true" [ngClass.lt-md]="'mat-divider-margin-left'" [ngClass.gt-sm]="'mat-divider-margin-left'"></mat-divider>
          </div>
          <div fxLayout="row" *ngIf="!ownProfile && showContactDetails" fxLayoutAlign="space-between" fxFlex.gt-sm="25" class="padded">
            <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <h4 [ngClass.lt-md]="'remove-margin'">PHONE NUMBER</h4>
              </div>
              <div fxLayout="row">
                <h3 [ngClass.lt-md]="'remove-margin'">{{candidateInfo.candidateInfo.phone}}</h3>
              </div>
            </div>
            <mat-divider fxHide.lt-md inset="true" vertical="true" [ngClass.lt-md]="'mat-divider-margin-left'" [ngClass.gt-sm]="'mat-divider-margin-left'"></mat-divider>
          </div>
          <div fxLayout="row" *ngIf="!ownProfile && showContactDetails" fxLayoutAlign="space-between" fxFlex.gt-sm="27" class="padded">
            <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <h4 [ngClass.lt-md]="'remove-margin'">EMAIL</h4>
              </div>
              <div fxLayout="row">
                <h3 [ngClass.lt-md]="'remove-margin'">{{candidateInfo.candidateInfo.email}}</h3>
              </div>
            </div>            
          </div>
        </div>
        <!--<div fxLayout="row">
          <h3>{{candidateInfo.sectorTitle.title ? candidateInfo.sectorTitle.title : '&nbsp;'}}</h3>
          <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('sectorTitle')">edit</mat-icon>
        </div>-->
      </div>
      <div fxLayout.gt-sm="row" fxLayout.lt-md="column">
        <div fxLayout="row" fxHide fxShow.lt-md   class="padded" *ngIf="!ownProfile && showContactDetails" [ngClass.lt-md]="'border-bottom'">
          <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between">
              <h4 [ngClass.lt-md]="'remove-margin'">PHONE NUMBER</h4>              
            </div>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="80">
                <h3 [ngClass.lt-md]="'remove-margin'">
                  {{ candidateInfo.candidateInfo.phone }}
                </h3>
              </div>
            </div>
          </div>
          <mat-divider fxHide.lt-md inset="true" vertical="true" [ngClass.lt-md]="'mat-divider-margin-left'" [ngClass.gt-sm]="'mat-divider-margin-left'"></mat-divider>
        </div>
        <div fxLayout="row" fxHide fxShow.lt-md  class="padded" *ngIf="!ownProfile && showContactDetails" [ngClass.lt-md]="'border-bottom'">
          <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between">
              <h4 [ngClass.lt-md]="'remove-margin'">EMAIL</h4>              
            </div>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="80">
                <h3 [ngClass.lt-md]="'remove-margin'">
                  <strong>{{ candidateInfo.candidateInfo.email }}</strong>
                </h3>
              </div>
            </div>
          </div>
          <mat-divider fxHide.lt-md inset="true" vertical="true" [ngClass.lt-md]="'mat-divider-margin-left'" [ngClass.gt-sm]="'mat-divider-margin-left'"></mat-divider>
        </div>
        <div fxLayout="row" fxFlex.gt-sm="23" class="padded" [ngClass.lt-md]="'border-bottom'">
          <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between">
              <span class="mat-body-1" [ngClass.lt-md]="'remove-margin'">LOCATION</span>
              <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('location')">edit</mat-icon>
            </div>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="80">
                <h3 [ngClass.lt-md]="'remove-margin'">
                  <span *ngIf="candidateInfo.location.city && candidateInfo.location.city != null">
                    {{ candidateInfo.location.city }},
                  </span>
                  <span *ngIf="candidateInfo.location.state && candidateInfo.location.state != null">
                    {{candidateInfo.location.state}}
                  </span>
                </h3>
              </div>
            </div>
          </div>
          <mat-divider fxHide.lt-md inset="true" vertical="true" [ngClass.lt-md]="'mat-divider-margin-left'" [ngClass.gt-sm]="'mat-divider-margin-left'"></mat-divider>
        </div>
        <div fxLayout="row" fxFlex.gt-sm="25" class="padded" [ngClass.lt-md]="'border-bottom'">
          <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between">
              <span class="mat-body-1"  [ngClass.lt-md]="'remove-margin'">AVAILABILITY</span>
              <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('availabilityComp')">edit</mat-icon>
            </div>
            <div fxLayout="row">
              <h3 [ngClass.lt-md]="'remove-margin'">{{candidateAvailabilities}}</h3>
            </div>
          </div>
          <mat-divider fxHide.lt-md inset="true" vertical="true" [ngClass.lt-md]="'mat-divider-margin-left'" [ngClass.gt-sm]="'mat-divider-margin-left'"></mat-divider>
        </div>
        <div fxLayout="row" fxFlex.gt-sm="25" class="padded" [ngClass.lt-md]="'border-bottom'">
          <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between">
              <span class="mat-body-1 margin16px">STATUS</span>
              <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('availabilityStatus')">edit</mat-icon>
            </div>
            <div fxLayout="row">
              <h3 [ngClass.lt-md]="'remove-margin'">{{candidateInfo.availabilityStatus.name}}</h3>
            </div>
          </div>
          <mat-divider fxHide.lt-md inset="true" vertical="true" [ngClass.lt-md]="'mat-divider-margin-left'" [ngClass.gt-sm]="'mat-divider-margin-left'"></mat-divider>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between" fxFlex.gt-sm="27" class="padded">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <span class="mat-body-1 margin16px"  *ngIf="ownProfile">EXPECTED SALARY</span>
            <span class="mat-body-1 margin16px"  *ngIf="!ownProfile">RESUME</span>
            <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit('availabilityComp')">edit</mat-icon>
          </div>
          <div fxLayout="row">
            <h3 [ngClass.lt-md]="'remove-margin'" *ngIf="ownProfile && (candidateInfo.availabilityComp.minComp !=null || candidateInfo.availabilityComp.maxComp !=null)"><span>${{candidateInfo.availabilityComp.minComp}}/Hr - ${{candidateInfo.availabilityComp.maxComp}}/Hr</span></h3>
            <div *ngIf="candidateInfo.resume && candidateInfo.resume!=null && !ownProfile">
              <a id='download_resume' (click)="downloadDoc('download_resume');"><button mat-stroked-button color="primary" [disabled]="!candidateInfo.resume.share"><mat-icon>file_download</mat-icon> Download</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
