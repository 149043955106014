import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../../Services/dashboard.service';
import { ColorHelper } from '@swimlane/ngx-charts';


@Component({
  selector: 'app-oldest-postings',
  templateUrl: './oldest-postings.component.html',
  styleUrls: ['./oldest-postings.component.css']
})
export class OldestPostingsComponent implements OnInit {
  public numPostings:number = 3;
  public postingData:any[] = [];
  public postingMaxDays: number;
  public oldestPostings:any;
  public legendData:string[];
  public view:any = "";
  public colors:ColorHelper;
  public colorScheme = { 
    domain: [
      'rgb(29, 104, 251)', 
      'rgb(51, 192, 252)', 
      'rgb(74, 255, 254)', 
      'rgb(175, 255, 255)', 
      'rgb(255, 252, 99)', 
      'rgb(255, 252, 99)'
    ] 
  };
  public activeEntries:any[] = [];


  constructor(private _dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getOldestPostings();
  }

  getOldestPostings() {
    this._dashboardService.getOldestPostings(this.numPostings)
    .subscribe(
    (result: any) => {
      this.legendData = result.map((d: any) => d.name);
      //this.colors = new ColorHelper(this.colorScheme, 'ordinal', this.legendData, null);
      this.postingData = result;
      this.postingMaxDays = Math.max.apply(Math, this.postingData.map(function(o) { return o.value; }));
    },
    error => {
      console.log('Could not load skills graph');
    }
  );
  }

  legendLabelActivate(item): void {
    this.activeEntries = [item];
  }

  legendLabelDeactivate(item): void {
    this.activeEntries = [];
  }

}
