import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.css']
})
export class CompanyAdminComponent implements OnInit {
  largeScreen:boolean = false;
  menu:boolean = false;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public _router: Router
  ) { }

  ngOnInit(): void {
    this.setMenu(this._router.url);

    // if the screen is large enough, the list and detail view will show at the same time
    // and the base company admin route will redirect
    this.breakpointObserver.observe(['(min-width: 959px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.largeScreen = true;
        if(this._router.url === '/companyAdmin') {
          let redirect = '/companyAdmin/aboutCompany';
          this._router.navigate([redirect]);
        }
      } else {
        this.largeScreen = false;
      }
    });

    // call set menu when the url changes
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setMenu(event.url);
    });
  }

 

  // if the url is the base url, then the detail view should not populate
  setMenu(url:string) {
    if(url === '/companyAdmin') {
      this.menu = true;
    }
    else {
      this.menu = false;
    }
  }

}
