import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; 
import { SharePostingDialogComponent } from '../dialogs/share-posting-dialog/share-posting-dialog.component';
import { ContentReportingComponent} from '../dialogs/content-reporting/content-reporting.component';
import { TalentApiService } from '../../Services/talent-api.service';

@Component({
  selector: 'app-candidate-posting-detail',
  templateUrl: './candidate-posting-detail.component.html',
  styleUrls: ['./candidate-posting-detail.component.css']
})
export class CandidatePostingDetailComponent implements OnInit {

  @Input() user: any;
  @Input() applicants: any = [];
  @Input() detail: any;
  @Input() userType: number;
  @Input() showHeaderBack: any;
  IsMobileDevice = false;  
  @Output() apply: EventEmitter<void> = new EventEmitter<void>();
  @Output() tag: EventEmitter<void> = new EventEmitter<void>();
  @Output() unTag: EventEmitter<number> = new EventEmitter<number>();

  constructor(public _dialog: MatDialog, public breakpointObserver: BreakpointObserver,
    public _talentApiService: TalentApiService) {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.IsMobileDevice = true;
        } else {
          this.IsMobileDevice = false;
        }
      });
  }

  ngOnInit(): void {
    if (this.showHeaderBack) {      
    }
  }

  tagPosting() {
    this.tag.emit();
  }

  untagPosting(id) {
    this.unTag.emit(id);
  }

  applyPosting() {
    this.apply.emit();
  }

  sharePostingDialog() {
    let dialog = this._dialog.open(SharePostingDialogComponent, {
      height: '300px',
      width: this.IsMobileDevice ? '95%' :'50%',
      disableClose: true
    });
    dialog.componentInstance.postingName = this.detail.title;
    dialog.componentInstance.opportunityId = this.detail.id;
    dialog.afterClosed().subscribe((result) => { });
  }

  contReportingDialog() {
    let dialog = this._dialog.open(ContentReportingComponent, {
      height: this.IsMobileDevice ? '400px' : '350px',
      width: this.IsMobileDevice ? '95%' :'45%',
      disableClose: true,
      panelClass: 'posting_popup',
      data : {
        opportunityId : this.detail.id
      }
    });
    dialog.afterClosed().subscribe((result) => { });
  } 

  copyLink() {
    var inputc = document.body.appendChild(document.createElement("input"));
    inputc.value = window.location.href;
    inputc.focus();
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode.removeChild(inputc);
    this._talentApiService.snackbarMessage('Link Copied.');
  }
}
