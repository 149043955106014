import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-submit-feedback',
	templateUrl: './submit-feedback.component.html',
	styleUrls: ['./submit-feedback.component.css']
})
export class SubmitFeedbackComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}
