<mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="title-padding medium-24">Account Usage</h2>
    <div *ngIf="!largeScreen">
        <button mat-stroked-button (click)="back()">BACK</button>
    </div>
</mat-card-title>
<mat-divider inset></mat-divider>
<mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
    <h4>Functions</h4>
    <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0 border-bottom">
            <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
                <mat-panel-title fxFlex="30" class="medium-18">
                    Postings
                </mat-panel-title>
                <mat-panel-description fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-progress-bar fxFlex="92" class="metrics-progress" mode="determinate" value="{{ progress['postingBar'] }}"></mat-progress-bar>
                    <span class="medium-18">{{ progress['posting'] }}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="!this.postingDataReady" fxLayout="row" fxLayoutAlign="center">
                <span class="medium-18"> No Record(s)! </span>
            </div>
            <app-metric-detail *ngIf="this.postingDataReady" [metricId]="this.metrics['Postings']" (metricCounter)="onCountAdvancedChange($event)"></app-metric-detail>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0 border-bottom">
            <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
                <mat-panel-title fxFlex="30" class="medium-18">
                    Shortlist
                </mat-panel-title>
                <mat-panel-description fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-progress-bar fxFlex="92" class="metrics-progress" mode="determinate" value="{{ progress['shortlistBar'] }}"></mat-progress-bar>
                    <span class="medium-18">{{ progress['shortlist'] }}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="!this.shortlistDataReady" fxLayout="row" fxLayoutAlign="center">
                <span class="medium-18">No Record(s)! </span>
            </div>
            <app-metric-detail *ngIf="this.shortlistDataReady" [metricId]="this.metrics['Shortlists']" (metricCounter)="onCountShortlistChange($event)"></app-metric-detail>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0 border-bottom">
            <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
                <mat-panel-title fxFlex="30" class="medium-18">
                    Number of Users
                </mat-panel-title>
                <mat-panel-description fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-progress-bar fxFlex="92" class="metrics-progress" mode="determinate" value="{{ progress['userlistBar'] }}"></mat-progress-bar>
                    <span class="medium-18">{{ progress['userlist'] }}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="!this.usersDataReady" fxLayout="row" fxLayoutAlign="center">
                <span class="medium-18">No Record(s)! </span>
            </div>
            <app-metric-detail *ngIf="this.usersDataReady" [metricId]="this.metrics['Users']"></app-metric-detail>
        </mat-expansion-panel>
        <!--
        <mat-expansion-panel class="mat-elevation-z0 border-bottom">
            <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
                <mat-panel-title fxFlex="30" class="medium-18">
                    Contacts View
                </mat-panel-title>
                <mat-panel-description>
                    <mat-progress-bar class="metrics-progress" mode="determinate" value="40"></mat-progress-bar>
                </mat-panel-description>
            </mat-expansion-panel-header>
        </mat-expansion-panel>
        -->
        <mat-expansion-panel class="mat-elevation-z0 border-bottom" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title class="medium-18">
                    Candidates Advanced
                </mat-panel-title>
                <mat-panel-description fxLayout="row" fxLayoutAlign="end center">
                    <span class="medium-18">{{this.candidatesAdvanced}}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0 border-bottom" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title class="medium-18">
                    Candidates Shortlisted
                </mat-panel-title>
                <mat-panel-description fxLayout="row" fxLayoutAlign="end center">
                    <span class="medium-18">{{this.candidatesShortlisted}}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card-content>