import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LoginService } from '../../Services/login.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserInfoService } from '../../Services/user-info.service';
import { TalentApiService } from '../../Services/talent-api.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { FeedbackRequestComponent } from '../dialogs/feedback-request/feedback-request.component';
import { FeedbackFilterPipe } from '../../Helpers/Pipes/feedback-filter.pipe';
import { FeedbackSearchPipe } from '../../Helpers/Pipes/feedback-search.pipe';
import { FeedbackOrderPipe } from '../../Helpers/Pipes/feedback-order.pipe';
import { TooltipPosition } from '@angular/material/tooltip';
import { SubmitFeedbackComponent } from '../dialogs/submit-feedback/submit-feedback.component';
import { DialogService } from '../../Services/dialog.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';


declare var $: any;
@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

	public userType: any;
	public searchText: any;
	myEventSubscription: any;
	public students = [];
	public student: any;
	public studentId: any;
	public jobId: any;
	public detail_id = null;
	public showDetail = false;
	public sortField = 'lastName';
	public sortText = "Name";
	public reverseSort = false;
	public filter: any;
	public filterText = "All Students"
	public classList: any;
	public selectedClass: any;
	public id_string: any;
	public sub: any;
	public form: any;
	public form2: any;
	//public internship_id: any; //has been replaced by feedback_id -ASHOK
	public feedback_id:any;
	public readOnly: boolean = false;
	public status: any;
	public pending: boolean = false;
	public questionsLength: any;
	public getParam: boolean = false;
	public fbCommentLength = 500;
	public fbResponseLength = 1000;
	public printArea: any;
	public mywindow: any;
	public commentsLength = 1000;
	public noRecords: boolean = false;

	isInstructor:boolean = false;
	isEmployer:boolean = false;
	isStudent:boolean = false;
	recipientCommentsLabel:any = null;
	reviewerCommentsLabel:any = null;
	submitFeedbackTooltip:any = null;
	recipientUserType:any = 0;
	reviewerUserType:any = 0;

	//spinner
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	public originalForm: any; // ### use for check form value change or not on navigation
	// reference service CanDeactivateGuardService 
	public activeStep = 0; // ### use for next/previous button at end of each page

	constructor(private _loginService: LoginService, private _route: ActivatedRoute, private _router: Router, private _http: HttpClient, private _userInfoService: UserInfoService, private _dialog: MatDialog, private location: Location, private _talentApiService: TalentApiService, public _dialogService:DialogService, private sharedUtils: SharedUtilitiesService) {

	}

	ngOnInit() {
		this.populate();
		//decide Instructor/Student/Employer
		this.setLabelSettings(); 
		this.sub = this._route.params.subscribe(params => {
			if (params.id_string) {
				this.showFeedback(params.id_string);
				this.getParam = true;
			}
			else {
				this.getParam = false;
			}
		});
	}

	ngOnDestroy() {
		if (!!this.myEventSubscription) this.myEventSubscription.unsubscribe();
	}

	setLabelSettings(){
		switch(parseInt(this._userInfoService.getUser().type))
		{
			case 2:
				this.isInstructor = true;
				break;
			case 3:
				this.isStudent = true;
				break;
			case 5:
				this.isEmployer = true;
				break;
		}
    if (this.isStudent)
      this.filterText = 'All Classes / Opportunites';
    else if (this.isInstructor)
      this.filterText = 'All Classes';
    else if (this.isEmployer)
      this.filterText = 'All Postings';
	}

	setReviewerReciverLabelText()
	{
		switch(parseInt(this.recipientUserType))
		{
			case 2:
				this.recipientCommentsLabel = 'Instructor Comments';
				this.submitFeedbackTooltip = 'Submit Feedback To School';
				break;
			case 3:
				this.recipientCommentsLabel = 'Student Comments';	
				this.submitFeedbackTooltip = 'Submit Feedback To Student';
				break;
			case 5:
				this.recipientCommentsLabel = 'Employer Comments';	
				this.submitFeedbackTooltip = 'Submit Feedback To Employer';
				break;
			default:
				break;
		}
		switch(parseInt(this.reviewerUserType))
		{
			case 2:
				this.reviewerCommentsLabel = 'Overall Instructor Comments';	
				break;
			case 3:
				this.reviewerCommentsLabel = 'Overall Student Comments';
				break;
			case 5:
				this.reviewerCommentsLabel = 'Overall Employer Comments';
				break;
			default:
				break;
		}
	}

	// form.js
	showFeedback(id_string) {
		this.getParam = false;
		let id = '';
		let opID = '';
		if(id_string.indexOf('_') !== -1)
		{
			id = id_string.substr(0, id_string.indexOf('_'));
			opID = id_string.substr(id_string.indexOf('_') + 1, 2000);
			this.detail_id = id + '_' + opID;
		}
		else
		{
			id = id_string;
			this.detail_id = id;

		}

		this.student = {};
		
		this.showDetail = true;
		this._talentApiService.getFeedback(id, opID)
			.subscribe(
				(result:any) => {
					this.noRecords = false;
					this.student = {
						name: result[0]['name'],
						title: result[0]['title'],
						company: result[0]['company']
					};
					this.form = JSON.parse(result[0]['form']);

					this.originalForm = JSON.parse(result[0]['form']);

					//set receiver and reviewer user type
					this.recipientUserType = result[0]['recipientUserType'];
					this.reviewerUserType = result[0]['reviewerUserType'];
					
					this.setReviewerReciverLabelText();

					this.feedback_id = result[0]['feedback_id'];
					this.status = result[0]['status'];
					//this.pending = (result[0]['status'] < 2 && this.readOnly);
					//NEVER MIX bool and string, it creates issue
					this.readOnly =  result[0]['readOnly'] == '1' ? true : false;
					this.pending = result[0]['status'] < 2 && this.readOnly;
					
					this.sharedUtils.getFile(id, 'student', 'profile-pic')
						.subscribe(
							(result:any) => {
								this.student.profilePic = JSON.parse(result[0]['profile-pic']);
							},
							error => {
								this.student.profilePic = null;
							}
						);

					if (typeof (this.form.reviewer) == 'undefined') {
						this.form.reviewer = {
							name: result[0]['reviewer_name'],
							id: result[0]['reviewer_id'],
							tele: result[0]['reviewer_tele'],
							email: result[0]['reviewer_email'],
						};
						this.originalForm.reviewer = {
							name: result[0]['reviewer_name'],
							id: result[0]['reviewer_id'],
							tele: result[0]['reviewer_tele'],
							email: result[0]['reviewer_email'],
						};
					}
					if (typeof (this.form.completed) == 'undefined') {
						this.form.completed = new Date();
						this.originalForm.completed = new Date();
					} else {
						this.form.completed = new Date(this.form.completed);
						this.originalForm.completed = new Date(this.form.completed);
					}

					this.questionsLength = this.form.questions.length;
					this.getParam = true;
				},
				error => {
					this.noRecords = true;
					this.form2 = {
						stars: [
							{
								name: 'Importance of mission: understood the organization’s mission and how it affects what we do',
								rating: 0,
								comment: "Comments required when rating is less than 3 or greater than 4"
							},

							{
								name: 'Reliability: was reliable in performing his/her duties',
								rating: 0,
								comment: " Comments required when rating is less than 3 or greater than 4"
							},

							{
								name: 'Work quality: showed commitment in producing high quality work ',
								rating: 0,
								comment: "Comments required when rating is less than 3 or greater than 4"
							},

							{
								name: 'Diversity: was sensitive to the diversity of his/her colleagues and/or the organization’s clients ',
								rating: 0,
								comment: "Comments required when rating is less than 3 or greater than 4"
							},
							{
								name: ' Capacity: performed work that benefited our clients and/or organization ',
								rating: 0,
								comment: "Comments required when rating is less than 3 or greater than 4"
							},
							{
								name: 'Professional growth: asked questions and addressed concerns ',
								rating: 0,
								comment: "Comments required when rating is less than 3 or greater than 4"
							},
							{
								name: 'Professional skills: developed professional skills ',
								rating: 0,
								comment: "Comments required when rating is less than 3 or greater than 4"
							},
							{
								name: ' Future Students/Interns: how likely would you have us recommend your internship to our students in the future?',
								rating: 0,
								comment: " Comments required when rating is less than 3 or greater than 4"
							},
							{
								name: 'Overall: Student was an asset to our organization ',
								rating: 0,
								comment: "Comments required when rating is less than 3 or greater than 4"
							}
						],
						questions: [
							{
								name: 'Please provide a brief description of what you consider to be the most important contribution made by the student to your organization.',
								content: "Enter Comments Here"
							},
							{
								name: 'Which professional skills did the student develop the most while working under you?',
								content: " Enter Comments Here "
							},
							{
								name: 'Please provide one area of growth on which the student should focus.',
								content: "Enter Comments Here "
							},
							{
								name: 'Please identify what you noticed to be your students’ greatest strength, strongest attribute or skill that stood out in his/her performance.',
								content: "Enter Comments Here "
							},
							{
								name: 'Please provide any overall feedback about the <Informatics Department>, curricula, experiential learning program or other topics.',
								content: "Enter Comments Here "
							}],
						checkboxes: [
							{
								question: 'Would you like another <Informatics> intern?',
								boxes: [{
									name: '',
									content: 0
								}],

								comments: ' If no to previous question, why not?',
							},
							{
								question: ' What additional skills do you wish <Informatics> students had, or had more of, that we could add to our curriculum?',
								boxes: [
									{
										name: 'Team Work',
										content: 0
									},
									{
										name: 'Writing',
										content: 0
									},
									{
										name: 'Presentation skills',
										content: 0
									},
									{
										name: 'Professional etiquette',
										content: 0
									},
									{
										name: 'Particular programming skills',
										content: 0
									},
									{
										name: 'Web technologies',
										content: 0
									},
									{
										name: 'Data analytics',
										content: 0
									},
									{
										name: 'How to do research',
										content: 0
									},
									{
										name: 'Particular database',
										content: 0
									},
									{
										name: 'Social media',
										content: 0
									},
									{
										name: 'Graphic design',
										content: 0
									},
									{
										name: 'Other',
										content: 0
									}],
								comments: ''
							}
						],
						comments: "testtesttest",
						rating: 5,
						reviewer: {
							name: "Ian Butler",
							id: "5",
							tele: "5188818130",
							email: "ian.butler@talentjumpstart.com"
						},
						completed: "1 April, 2016"
					};
					this.pending = false;
				}
			);
	}

	populate() {
		this.userType = this._userInfoService.getUser().type;
		this.getStudents();
		this.getClassList();
	}

	getStudents() {
		this._talentApiService.getFeedbackList(2)
			.subscribe(
				(result:any) => {
					if (result) {
						this.students = result;
						let that = this;
						//this.students.forEach(this.getPicFor);
						this.students.forEach(function (element, index, array) {
							element.lastName = element.name.substr(element.name.indexOf(" ") + 1, 8);
							that.sharedUtils.getFile(element.id, 'student', 'profile-pic')
								.subscribe(
									(result:any) => {
										if (!element.pending && !element.active) {
											element.pending = false;
											element.active = false;
										}
										if (true) {
											element.profilePic = JSON.parse(result[0]['profile-pic']);
										} else {
											//element.profilePic = null;
										}
									},
									error => {
										element.profilePic = null;
										console.log('Unable to get image for student profile');
									}
								);
						});
					}
				},
				error => {
					console.log('Unable to load student feedback list!!');
				}
			);
	}

	getPicFor(element, index, array) {
		//hack
		let that = this;
		element.lastName = element.name.substr(element.name.indexOf(" ") + 1, 8);
		that.sharedUtils.getFile(element.id, 'student', 'profile-pic')
			.subscribe(
				(result:any) => {
					if (!element.pending && !element.active) {
						element.pending = false;
						element.active = false;
					}
					if (true) {
						element.profilePic = JSON.parse(result[0]['profile-pic']);
					} else {
						//element.profilePic = null;
					}
				},
				error => {
					element.profilePic = null;
					console.log('Unable to get image for student profile');
				}
			);
	}

	setSort(sort) {
		if (sort == this.sortField) {
			this.reverseSort = !this.reverseSort;
		} else {
			this.sortField = sort;
			this.reverseSort = false;
		}
		switch (sort) {
			case "company":
				this.sortText = "Company";
				break;
			case "title":
				this.sortText = "Title";
				break;
			case "lastName":
				this.sortText = "Name";
				break;
			default:
				break;
		};
	}

	setFilter(value, cls) {
		switch (value) {
			case 0:
				this.filter = false;
        if (this.isStudent)
          this.filterText = 'All Classes / Opportunites';
        else if (this.isInstructor)
          this.filterText = 'All Classes';
        else if (this.isEmployer)
          this.filterText = 'All Postings';
				break;
			case 1:
				this.filter = { fb_status: '3' };
				this.filterText = 'Completed Feedback';
				break;
			case 2:
				this.filter = { fb_status: '1' };
				this.filterText = 'Pending Feedback';
				break;
			case 3:
				this.filter = { classID: cls.id };
				this.filterText = cls.courseid;
				break;
			default:
				this.filter = false;
          this.filterText = 'All Students';
				break;
		}
	}

	getClassList() {
		this._talentApiService.getMyClassList(0)
			.subscribe(
				(result:any) => {
					if (result) {
						this.classList = result;
						this.selectedClass = '';
					}
				},
				error => {
					console.log('Error Loading the getMyclassList');
				}
			);
	}

	select(feedback_id) {
		//this.detail_id = id;
		//this.id_string = id + '_' + opID;
		//this.detail_id = this.id_string;
		//$state.go('member.feedback.detail', {studentId:this.id_string});
		//This is kind of redirect. So in SPA it is not that beneficial
		//this._router.navigate(['feedback', feedback_id]);
		this.location.replaceState("/feedback/"+feedback_id);
		this.showFeedback(feedback_id);

	}

	printPage() {
		document.getElementById('printThisElement').style.display = "inline-table";
		this.printArea = document.getElementById('printThisElement').innerHTML;
		this.mywindow = window.open();
		this.mywindow.document.write('<html><head>');
		this.mywindow.document.write('</head><body>');
		this.mywindow.document.write(this.printArea);
		this.mywindow.document.write('</body></html>');
		this.mywindow.print();
		document.getElementById('printThisElement').style.display = "none";
		this.mywindow.close();
	}
	average(arry) {
		if (arry) {
			var total = 0;
			for (let i = 0; i < arry.length; i++) {
				total += arry[i].rating;
			}
			return total / arry.length;
		}
	}
	clean() {
		jQuery.noConflict();
		$('document').ready(function () {
			if ($("#submit-feedback-confirm")) {
				//let $:any; // for check
				$("#submit-feedback-confirm").modal();
			}
		});
		// var confirm = $mdDialog.confirm()
		// 	  .title('Have you completed Page 1, Page 2, and Page 3?')
		// 	  .ariaLabel('Check For Incomplete Forms')
		// 	  .ok('Submit Now')
		// 	  .cancel('Return to Form');
		// $mdDialog.show(confirm).then(function() {
		//   this.submit();
		// }, function() {
		//   //return to form
		// });
	}
	submit() {
		this.form.completed = new Date();
		this._talentApiService.editFeedback(this.form, this.feedback_id, 3)
			.subscribe(
				(result:any) => {
					if (result) {
						this._talentApiService.snackbarMessage("Feedback Submitted!");
						//this.populate($stateParams.studentId.substr(0, $stateParams.studentId.indexOf('_')), $stateParams.studentId.substr($stateParams.studentId.indexOf('_') + 1, 2000));
						
						//let path = this.location.path();
						//let feedback_id = path.substr(path.lastIndexOf('/') + 1);
						
						$("#submit-feedback-confirm").modal('hide');
						//this.showFeedback(feedback_id);
						//this.getParam = true;
						/*this.sub = this._route.params.subscribe(params => {
							$("#submit-feedback-confirm").modal('hide');
							
							if (params.id_string) {
								this.showFeedback(params.id_string);
								this.getParam = true;
							}
						});*/
					}
				},
				error => {
					console.log('error' + error);
				}
			);
	}
	save() {
		this._talentApiService.editFeedback(this.form, this.feedback_id, 2)
		.subscribe(
			(result:any) => {
				if (result) {
					this._talentApiService.snackbarMessage("Feedback Saved!");
					//this.populate($stateParams.studentId.substr(0, $stateParams.studentId.indexOf('_')), $stateParams.studentId.substr($stateParams.studentId.indexOf('_') + 1, 2000));
					this.sub = this._route.params.subscribe(params => {
						//$("#submit-feedback-confirm").modal('hide');
						if (params.id_string) {
							this.showFeedback(params.id_string);
							this.getParam = true;
						}
					});
				}
			},
			error => {
				console.log('error'+error);
			}
		);
	}
	closeDialog() {
		$("#submit-feedback-confirm").modal('hide');
	}
	/*isInstructor() {
		return parseInt(this._userInfoService.getUser().type) == 2;
	}
	isEmployer() {
		return parseInt(this._userInfoService.getUser().type) == 5;
	}
	isStudent(){
		return parseInt(this._userInfoService.getUser().type) == 3;
	}*/

	feedbackRequest(requestType) {
		/*this.sub = this._route.params.subscribe(params => {
			this.studentId = params.id_string.substr(0, params.id_string.indexOf('_'));
			this.jobId = params.id_string.substr(params.id_string.indexOf('_') + 1, 2000);
		});*/
		//requestType = 0 / 1, 0 = individual, 1 = group
		let feedbackRequestFor = parseInt(requestType);
		let dialog = this._dialog.open(FeedbackRequestComponent,
			{
				disableClose: false,
				height: 'auto',
				width: '66.66%',
				panelClass: "create_pipeline_popup"
			}
		);
		dialog.componentInstance.feedbackRequestFor = feedbackRequestFor;
		//dialog.componentInstance.studentId = this.studentId;
		//dialog.componentInstance.job = this.jobId;
		dialog.componentInstance.feedbackId = this.feedback_id;

		dialog.afterClosed()
			.subscribe((result) => {
			});
	}

	// ### use : add next & previous button to the bottpm of page

	public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.activeStep = tabChangeEvent.index;
    }

	incrementStep = function () {		
		this.activeStep = Math.min(this.activeStep + 1, 2);
	}

	decrementStep = function () {
		this.activeStep = Math.max(this.activeStep - 1, 0);
	}
}
