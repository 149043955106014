   <div fxLayout="row wrap" fxFlex="100">
        <h1 mat-dialog-title fxFlex="75">
            Shortlist candidate
        </h1>
        <div fxFlex="25" fxLayoutAlign="end start">
            <button mat-icon-button fxFlexOffset="10px" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>        
        <hr class="margin-24" fxFlex="100">
        <div fxLayout="row" fxFlex="100" *ngIf="isLoading" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
        <mat-dialog-content *ngIf="!isLoading" fxFlex="100" class="mat-typography scroll-content shortlist-content-part">
            <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="row wrap" fxLayoutAlign="start start" class="">     
                <h4  class="remove-margin" fxFlex="100">Select Shortlist(s) to shortlist candidate</h4>
                <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between">
                    <div fxLayout="column">
                        <mat-selection-list formControlName="selectedShortlist">
                            <mat-list-option [value]="shortlist.sid" *ngFor="let shortlist of shortlists">
                                {{shortlist.name}}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
            </form>
        </mat-dialog-content>
        
        <mat-dialog-actions *ngIf="!isLoading" fxLayout="row reverse" fxFlex="100" fxLayoutAlign="end" class="mat-padding">            
            <div  fxLayoutAlign="space-between">
                    <button mat-stroked-button (click)="close()">close</button>
                    <button mat-raised-button color="primary" fxFlexOffset="10px" (click)="submit()">Submit</button>
            </div>              
            
        </mat-dialog-actions>
    </div>
    
