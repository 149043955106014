<div class="full-page-login">
	<div fxFlex="100"
			 fxLayout="row"
			 fxLayoutAlign.xs="center stretch"
			 fxLayoutAlign="center center"
			 [ngClass.xs]="'padding-around'"
			 class="mat-typography">
		<mat-card fxFlex.gt-sm="65"  [ngClass.xs]="'minimum-height'"  fxFlex.lt-md="100">
			<form [formGroup]="loginForm" id="loginForm" name="loginForm" (ngSubmit)="onSubmit()">
				<div fxLayout="row" fxLayout.xs="column-reverse" class="pad-20"  fxLayoutGap="40px">
					<div fxLayout="column" fxFlex="70" fxFlex.xs="70">
						<!--fxFlex.lt-md="100"-->
						<div fxLayout="row-reverse" fxLayout.xs="column-reverse" fxLayoutAlign="space-between start">
							<h4 *ngIf="!registration">
								New to HipJobJuice?<a class="color-primary" color="primary" (click)="getRegisterForm()"><b> Sign Up</b></a>
							</h4>
							<span fxFlex></span>
							<h2 *ngIf="!registration">Login</h2>
						</div>
						<h2 *ngIf="registration">Register</h2>
						<mat-card-content>
							<div fxLayout="row">
								<mat-form-field color="accent" appearance="outline">
									<mat-label>Email Address</mat-label>
									<input matInput formControlName="email" placeholder="you@email.com">
									<mat-error *ngIf="loginForm.get('email').hasError('email')">
										<small>Please enter your email address</small>
									</mat-error>
								</mat-form-field>
							</div>
							<div fxLayout="row">
								<mat-form-field color="accent" appearance="outline">
									<mat-label>Password</mat-label>
									<input matInput type="password" *ngIf="!registration" formControlName="password" placeholder="password">
									<input matInput type="password" *ngIf="registration" pattern=".{8,200}" formControlName="password" placeholder="password">
									<mat-error *ngIf="loginForm.get('password').hasError('required') && !registration">
										<small>Please enter your password</small>
									</mat-error>
									<mat-error *ngIf="!loginForm.value.password.pattern && registration"> Password should be atleast 8 characters long </mat-error>
								</mat-form-field>
							</div>

							<div fxLayout="row">
								<a [ngClass]="{'disabled' : loginForm.value.email == '' || loginForm.get('email').hasError('email')}"
									 *ngIf="!registration"
									 fxFlex="20"
									 fxFlex.xs="40"
									 fxFlex.sm="40"
									 fxFlex.md="40"
									 (click)="resetPassword()">
									<small>Forgot Password?</small>
								</a>
								<small fxFlex="100" *ngIf="registration">
									By pressing register, you agree to our
									<a [routerLink]="[]" (click)="openDialog()">terms of service</a>.
								</small>
							</div>
						</mat-card-content>
						<mat-card-actions>
							<div *ngIf="registration" fxLayoutAlign="space-between start">
								<button mat-raised-button *ngIf="!isLoading" color="primary" type="submit" [disabled]="!loginForm.valid"
												form="loginForm" cdkFocusInitial>
									Register
								</button>
								<mat-spinner *ngIf="isLoading"></mat-spinner>
								<button mat-raised-button (click)="getLoginForm()">
									Already a
									Member?
								</button>
							</div>
							<div *ngIf="!registration" fxLayoutAlign="space-between start">
								<button mat-raised-button color="primary" [ngClass.xs]="'btn-full-width'" type="submit" [disabled]="!loginForm.valid"
												form="loginForm" cdkFocusInitial>
									LOGIN
								</button>
							</div>
						</mat-card-actions>
					</div>
					<div fxFlex="30"
							 fxFlex.xs="30"							 
							 fxLayoutAlign="start stretch"
							 fxLayoutAlign.xs="center stretch">
						<!--fxHide.lt-md="true"-->
						<app-logo [tenant]="tenant" fxFlex.xs="50" [type]="'login-page-image'"></app-logo>
					</div>
				</div>
			</form>
		</mat-card>
	</div>
</div>
