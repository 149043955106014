<div fxLayout="row" fxLayoutAlign="space-between start">
    <h3>Education</h3>
    <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit()">edit</mat-icon>
</div>

<div fxLayout="column" fxLayoutAlign="start stretch" class="candidate-education-list">
    <div *ngFor="let edu of candidateDegrees; let i=index">
        <mat-list *ngIf="!showingMore && i<3">
            <mat-list-item>
                <app-company-logo matListAvatar class="experience-logo" [name]="edu.school" [type]="'school'"></app-company-logo>
                <h4 mat-line class="line-height"><strong style="font-weight: 500;">{{ edu.course }}</strong></h4>
                <h4 mat-line class="gray-font line-height">{{edu.degreeType.name}}, {{ edu.school }} </h4>
                <h5 mat-line class="small-font gray-font line-height"> 
                    {{ edu.startDate | date: 'MMMM yyyy'}} - {{ edu.endDate | date: 'MMMM yyyy' }}
                </h5>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngFor="let edu of candidateDegrees; let i=index">
        <mat-list *ngIf="showingMore">
            <mat-list-item>
                <app-company-logo matListAvatar class="experience-logo" [name]="edu.school" [type]="'school'"></app-company-logo>
                <h4 mat-line class="line-height"><strong style="font-weight: 500;">{{ edu.course }}</strong></h4>
                <h4 mat-line class="gray-font line-height">{{edu.degreeType.name}}, {{ edu.school }} </h4>
                <h5 mat-line class="small-font gray-font line-height"> 
                    {{ edu.startDate | date: 'MMMM yyyy' }} - {{ edu.endDate | date: 'MMMM yyyy' }}
                </h5>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="candidateDegrees.length>3" fxLayout="row" fxLayoutAlign="center start">
        <button *ngIf="!showingMore" mat-stroked-button color="primary"
             fxFlex="33%" class="show-more-button" (click)="toggleShow()">Show More</button>
        <button *ngIf="showingMore" mat-stroked-button color="primary" 
             fxFlex="33%" class="show-more-button" (click)="toggleShow()">Show Less</button>
    </div>
   <div *ngIf="this.candidateDegrees.length==0" fxLayout="row" fxLayoutAlign="start start">
     <h4>{{this.education.highestDegree.name}}</h4>
   </div>
</div>

