import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  public tenant: String = null;

  constructor() { }

  setTenant(name) {
    this.tenant = name;
  }
}
