<div class="content-area">
  <div *ngIf="hasPostings===false">
    <h2 fxLayoutAlign="center center">No Postings!</h2>
  </div>

  <mat-accordion [multi]="viewAllPublicPostings">
    <mat-expansion-panel hideToggle cdkScrollable
      *ngFor="let n of opportunityList; let i=index"
      [expanded]="viewAllPublicPostings"
      (opened)="panelOpenState[n.id] = true"
      (closed)="panelOpenState[n.id] = false">
      <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight">
        <mat-panel-title>
          <div fxFlex="row">
            <div mat-line class="margin-bottom-6"><strong>{{n.title}}</strong></div>
            <div mat-line class="opportunity-font-subtitle mat-body-1">
              <ng-container>
                <span class="title18px">{{ n.company }}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              </ng-container>
              <ng-container *ngIf="n.locations.length > 1">
                <span class="title18px">Multiple Locations&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              </ng-container>
              <ng-container *ngIf="n.locations.length == 1">
                <span *ngIf="n.locations[0].city != null && n.locations[0].city.length>0">{{ n.locations[0].city +", "
                  }} </span>
                <span *ngIf="n.locations[0].state != null">{{ n.locations[0].state }}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              </ng-container>
              <span fxFlex class="mat-caption">
                Posted on {{n.postingdate | date:'d MMMM, y' }}
              </span>
            </div>
            <div *ngIf="!panelOpenState[n.id]" mat-line class="mat-caption margin-top-6">View Details</div>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div>
        <ng-template matExpansionPanelContent>
          <app-public-posting-details [postingId]="n.id"></app-public-posting-details>
        </ng-template>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</div>