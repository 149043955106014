import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoService } from '../../../Services/user-info.service';

@Component({
  selector: 'app-company-admin-list-card',
  templateUrl: './company-admin-list-card.component.html',
  styleUrls: ['./company-admin-list-card.component.css']
})
export class CompanyAdminListCardComponent implements OnInit {
  showDetail = true;
  name = "";
  email = "";
  route;
  isAccountManagement = false;
  isMembershipDetails = false;

  constructor(public _userInfoService: UserInfoService, private _router: Router) {


  }

  ngOnInit(): void {
    this.name = this._userInfoService.getUser().name;
    this.email = this._userInfoService.getUser().username;
    this.route = this._router.url;
    if(this.route == '/companyAdmin/companyUsers' || this.route == '/companyAdmin/userGroups' || this.route == '/companyAdmin/InviteNewUser') {
      this.isAccountManagement = true;
      this.isMembershipDetails = false;
    }
    if(this.route == '/companyAdmin/accountUsage') {
      this.isMembershipDetails = true;
      this.isAccountManagement = false;
    }
  }

  toggleDetail() {
    this.showDetail = true;
  }
}
