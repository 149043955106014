<div class="mat-modal-head mat-small" fxLayout=" wrap" fxLayoutGap="0 grid" fxLayoutAlign="start center">
  <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100" fxFlex.gt-sm="52" fxFlex.gt-md="63">
    <h2 mat-dialog-title>
      Candidate Profile Details
    </h2>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex.lt-md="59" fxFlex.md="24" fxFlex.gt-md="20">
    <button mat-raised-button color="primary"
            (click)="openInvite()" fxFlex.xs="Fill">
      INVITE TO APPLY
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="center" fxFlex.lt-md="38" fxFlex.md="18" fxFlex.gt-md="14">
    <button mat-stroked-button mat-button-base
            (click)="openShortlist()" fxFlex.xs="Fill">
      SHORTLIST
    </button>
  </div>
  <div fxLayout="column" fxFlex.gt-md="3" fxFlex.lt-md="3" fxFlex="3" fxLayoutAlign="start start">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  <mat-menu #menu="matMenu" xPosition="before">

    <button mat-menu-item (click)="contReportingDialog()">
      <span>Report Candidate</span>
    </button>
  </mat-menu>
  <button mat-icon-button (click)="close()" class="close_button">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="aplication-user-popup">
  <app-profile [candidateId]="input.candidateId"></app-profile>
</mat-dialog-content>
