<mat-card fxLayout="column" fxFlex="calc(50% - 20px)">
    <mat-card-title><h2>Posting Breakdown</h2></mat-card-title>
    <mat-card-content fxFlex="90%">
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start stretch">
            <div fxFlex="65%">
                <ngx-charts-pie-chart
                    *ngIf="postingsData.length"
                    [title]="'Postings by Industry'"
                    [results]="postingsData"
                    [animations]="true"
                    [tooltipDisabled]="false"
                    [tooltipText]="formatTooltip"
                    [legend]="true"
                    [legendTitle]="''"
                    [legendPosition]="'right'"
                    [tooltipText]="formatTooltip"
                    [view]="view"
                    [scheme]="colorScheme"
                    [activeEntries]="activeEntries">
                </ngx-charts-pie-chart>
            </div>
            <div fxFlex="20%">
                <!-- <ngx-charts-legend fxFlex="100%"
                    *ngIf="postingsData.length"
                    [data]="legendData"
                    [colors]="colors"
                    class="chart-legend"
                    (labelActivate)="legendLabelActivate($event)" 
                    (labelDeactivate)="legendLabelDeactivate($event)">
                </ngx-charts-legend> -->
            </div>
        </div>
    </mat-card-content>
</mat-card>
