<div fxLayout="row wrap" *ngIf="!isLoadingResult" fxLayoutGap="10px grid" fxLayoutAlign="start center" class="application-feed">  
  <div fxLayout="column"  fxFlex.lg="49" fxFlex.xl="49" fxFlex="100" *ngFor="let shortlistCandidate of shortlistCandidates"
       [ngClass]="{'applicationfeedinserted': breakpoint == 0, '': breakpoint != 0}">
    <app-student-small-card [candidateData]="shortlistCandidate"
                            [breakpoint]="breakpoint"
                            [isShortlistView]="isShortlistView"
                            [shortlistId]="shortlistId"
                            [shortlistName]="shortlistDetail.name"
                            (removeCandidate)="removeCandidate($event)"></app-student-small-card>
  </div>
</div>
<!-- Paginator -->
<div class="padded top-margin" *ngIf="shortlistCandidates.length > 0">
  <mat-paginator [pageSizeOptions]="[20,40,60,80,100]" [length]="totalLength" [pageSize]="pageSize"
    (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
</div>
<div *ngIf="!isLoadingResult && shortlistCandidates.length == 0" >
  <h3>No candidate(s) found!</h3>
  <div fxLayout="row" fxLayoutAlign="center">
    <button mat-raised-button color="primary" *ngIf="hasManageCandidatePermission" (click)="findCandidates()">
      Find Candidates
    </button>
  </div>
</div>
<div *ngIf="isLoadingResult" fxLayout="row" fxLayoutAlign="center">
  <mat-spinner></mat-spinner>
</div>
