import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../../Services/admin-api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TalentApiService } from '../../../Services/talent-api.service';


@Component({
  selector: 'app-tenant-logo-edit',
  templateUrl: './tenant-logo-edit.component.html',
  styleUrls: ['./tenant-logo-edit.component.css']
})
export class TenantLogoEditComponent implements OnInit {
  editing: any;
  public isReloadData = false;
  tenants: any;
  public logoFile = {
		mimetype: '',
		encoding: '',
		content: null,
		name: '',
		size: 0
	};
  public logoPreview: any;
  public noLogo = '../../../assets/img/nologo.png';
  public imageError: boolean = false;

  constructor(private _adminApiService: AdminApiService,
	private dialogRef: MatDialogRef<TenantLogoEditComponent>,
	private _talentApiService: TalentApiService) { }

  ngOnInit(): void {

    if(this.editing.image != '' && this.editing.image != undefined){
      this.logoPreview = this.editing.image; //"data:image/jpeg;base64,"+
    }
    else {
      this.logoPreview = this.noLogo;
    }
  }

  closeDialog() {
	this.dialogRef.close();
  }

  modalAddNew() {
		if (this.editing && this.editing.tenant_id && this.editing.type && this.editing.size && this.editing.image) {

		this._adminApiService.createTenantLogo(this.editing)
			.subscribe(
			(result: any) => {
        console.log(this.tenants);
        console.log(this.tenants[this.editing.tenant_id])
				this._talentApiService.snackbarMessage('Tenant logo for \'' + this.tenants.find( e => e.id == this.editing.tenant_id).name + '\' has been added.');
				this.isReloadData = true;
				this.dialogRef.close();

			},
			error => {
				this._talentApiService.snackbarMessage(error.msg);
			}
			);
		} else {
			this._talentApiService.snackbarMessage('Invalid Tenant. Please check your parameters and try again.');
		}
  }

  modalDelete() {
	if (confirm('Are you sure you want to want to delete this Tenant Logo?')) {
		this._adminApiService.deleteTenantLogo(this.editing.id)
		.subscribe(
		(result: any) => {
			this._talentApiService.snackbarMessage('Tenant logo for \'' + this.tenants.find( e => e.id == this.editing.tenant_id).name + '\' has been deleted.');
			this.isReloadData = true;
			this.dialogRef.close();
		},
		error => {
			this._talentApiService.snackbarMessage(error.msg);
		}
		);
	}
  }

  modalSave() {
  if (this.editing && this.editing.id && this.editing.tenant_id && this.editing.type && this.editing.size && this.editing.image) {
		this._adminApiService.editTenantLogo(this.editing)
		.subscribe(
			(result: any) => {
			this._talentApiService.snackbarMessage('Tenant logo for \'' + this.tenants.find( e => e.id == this.editing.tenant_id).name + '\' has been saved.');
			this.isReloadData = true;
			this.dialogRef.close();
			},
			error => {
			this._talentApiService.snackbarMessage(error.msg);
			}
		);
		} else {
			this._talentApiService.snackbarMessage('Invalid Tenant Logo. Please check your parameters and try again.');
		}
  }

  fileChangeEvent(event: any): void {
		let fileExtension = event.target.value.replace(/^.*\./, '');
		switch (fileExtension) {
			case 'png': case 'jpeg': case 'jpg':
				this.imageError = false;
				if (event.target.files && event.target.files[0]) {
					var reader = new FileReader();

					this.logoFile.size = event.target.files[0].size / 1024;
					if (this.logoFile.size > 2048) {
						this.logoFile = {
							mimetype: '',
							encoding: '',
							content: '',
							name: '',
							size: 0
						};
						this.logoPreview = this.noLogo;
						this.imageError = true;
						this._talentApiService.snackbarMessage("Only JPG, PNG or Gif images are allowed with file size of 2MB only.");
						break;
					}
					this.logoFile.mimetype = event.target.files[0].type;
					this.logoFile.name = event.target.files[0].name;
					this.logoFile.size = event.target.files[0].size / 1024;
					this.logoFile.encoding = 'base64';

					reader.onload = (event: ProgressEvent) => {
						this.logoFile.content = reader.result;
						//this.logoFile.content = this.logoFile.content.replace(/^data:image\/[a-z]+;base64,/, "");
						this.editing.image = this.logoFile.content;
						this.logoPreview = (<FileReader>event.target).result;
					}
					reader.readAsDataURL(event.target.files[0]);
					//clear the file object in order to trigger the change event if the same file was uploaded again
					event.target.value = null;
				}
				break;
			case 'svg':
				this.imageError = false;
				if (event.target.files && event.target.files[0]) {
					var reader = new FileReader();

					this.logoFile.size = event.target.files[0].size / 1024;
					if (this.logoFile.size > 2048) {
						this.logoFile = {
							mimetype: '',
							encoding: '',
							content: '',
							name: '',
							size: 0
						};
						this.logoPreview = this.noLogo;
						this.imageError = true;
						this._talentApiService.snackbarMessage("Only JPG, PNG or Gif images are allowed with file size of 2MB only.");
						break;
					}
					this.logoFile.mimetype = event.target.files[0].type;
					this.logoFile.name = event.target.files[0].name;

					reader.onload = (event: ProgressEvent) => {
						this.logoFile.content = reader.result;
						this.logoFile.content = this.logoFile.content.replace(/^data:image\/[a-z]+;base64,/, "");
						this.editing.image = this.logoFile.content;
						this.logoPreview = (<FileReader>event.target).result;
					}
					reader.readAsDataURL(event.target.files[0]);
					//clear the file object in order to trigger the change event if the same file was uploaded again
					event.target.value = null;
				}
				break;
			default:
				this.imageError = true;
				//cleaning the logoFile object
				this.logoFile = {
					mimetype: '',
					encoding: '',
					content: '',
					name: '',
					size: 0
				};
				this.logoPreview = this.noLogo;
				break;
		}
	}

}
