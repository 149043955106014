import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {  MatDialogRef  } from '@angular/material/dialog';
import { forEachChild } from 'typescript';
import { PostingService } from '../../../Services/posting.service';
import { TalentApiService } from '../../../Services/talent-api.service';
 

@Component({
  selector: 'app-share-posting-dialog',
  templateUrl: './share-posting-dialog.component.html',
  styleUrls: ['./share-posting-dialog.component.css']
})
export class SharePostingDialogComponent implements OnInit {
  emailForm: UntypedFormGroup;
  public postingName: any;
  public opportunityId: any;
  public emailList = [];
  public sendingEmail: boolean = false;
  share: UntypedFormBuilder = new UntypedFormBuilder();
  public removable: boolean = true;
  public selectable: boolean = true;
  constructor(public _dialogRef: MatDialogRef<SharePostingDialogComponent>,
    private _postingService: PostingService,
    private _talentApiService: TalentApiService  ) { }
  public separatorKeysCodes = [ENTER, COMMA];

  ngOnInit(): void {
    this.emailForm = this.share.group({
      emails: this.share.array([], [this.validateArrayNotEmpty]),
    });
  }
  closeDialog() {
    this._dialogRef.close();
  }
  private validateArrayNotEmpty(c: UntypedFormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }
  addEmails(event): void {    
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailList.push({ value: event.value, invalid: false });
      } else {
        this.emailList.push({ value: event.value, invalid: true });
         
      }
    }
    if (event.input) {
      event.input.value = '';
    }
  }
  removeEmail(data: any): void {
    console.log('email removed ' + data)
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
  }
  sharePost() {
    var emails = [];
    this.emailList.forEach(function (em) { if (!em.invalid) { emails.push(em.value) } });
    if (emails.length == 0) { this._talentApiService.snackbarMessage('No valid email found!'); return; }

    if (this.sendingEmail) return;

    this.sendingEmail = true;
    this._postingService.sharePosting(this.opportunityId, emails).subscribe((result: any) => {      
      if (result.length > 0 && result[0] == "success") {
        this._talentApiService.snackbarMessage('Posting shared successfully!');
        this._dialogRef.close();
      }
      else {
        this._talentApiService.snackbarMessage('Unable to share this posting!');
        this._dialogRef.close();
      }
    });
  }
   
  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
