<div fxLayout="row wrap" fxFlex="100">
	<h1 mat-dialog-title fxFlex="50">
	  Edit Profile
	</h1>
	<div fxFlex="50" fxLayoutAlign="end start">
	  <button mat-icon-button (click)="close()">
		<mat-icon>close</mat-icon>
	  </button>
	</div>
	<hr fxFlex="100">
	<div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="end start" mat-dialog-content [ngClass.xs]="'unset-margin-padding'">
		<app-candidate-info fxFlex="100" [candidateInfo]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-info'"></app-candidate-info>
		<app-candidate-sector fxFlex="100" [sectorTitle]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-sector'"></app-candidate-sector>
		<app-candidate-location fxFlex="100" [location]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-location'"></app-candidate-location>
		<app-candidate-availability-comp fxFlex="100" [availabilityComp]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-availabilityComp'"></app-candidate-availability-comp>
		<app-candidate-availability-status fxFlex="100" [availabilityStatus]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-availabilityStatus'"></app-candidate-availability-status>
		<app-candidate-resume fxFlex="100" [resume]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-resume'"></app-candidate-resume>
		<app-candidate-certifications fxFlex="100" [candidateCertifications]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-certifications'"></app-candidate-certifications>
		<app-candidate-work-environment fxFlex="100" [workEnvironment]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-work-environment'"></app-candidate-work-environment>
		<app-candidate-skills fxFlex="100" [candidateSkills]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-skills'"></app-candidate-skills>
		<app-candidate-link-contacts fxFlex="100" [linkContacts]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-link-contacts'"></app-candidate-link-contacts>
		<app-candidate-work-experience fxFlex="100" [workExperience]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-work-experience'"></app-candidate-work-experience>
		<app-candidate-education fxFlex="100" [education]="input.seedData" [edit]="true" *ngIf="input.subComponent === 'candidate-education'"></app-candidate-education>
	</div>
</div>