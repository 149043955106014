import { filter } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { IconRegistryService } from './Services/icon-registry.service';
import { TalentApiService } from './Services/talent-api.service';
import { ThemeService } from './Services/theme.service';
import { TenantService } from './Services/tenant.service';
import { Themes, TenantsList } from './Models/TenantsTheme';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TJS';
  userLoginType = 0;

  public snackBarConfig: MatSnackBarConfig = {
    duration: environment.SNACKBAR_TIMEOUT,
    horizontalPosition: "center",
    verticalPosition: "top"
  };



  constructor(public router: Router, private activatedRoute: ActivatedRoute,
    private MatIconRegistry: IconRegistryService, private _snackBar: MatSnackBar,
    private _talentApiService: TalentApiService,
    private _themeService: ThemeService,
    private _tenantService: TenantService) {
    _talentApiService.snackbarNotifiation.subscribe((message: string) => {
      _snackBar.open(message, "OK", this.snackBarConfig);
    });

    let baseUrl = window.location.origin;
    let tenantEntity = TenantsList[baseUrl] || TenantsList['default'];
    this._themeService.setTheme(tenantEntity.theme);
    this._tenantService.setTenant(tenantEntity.tenantName);

    router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {

        const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);       

        let currentUrlPath = event.url.slice(1).split(urlDelimitators)[0];
        if (currentUrlPath == 'admin') {
          this.userLoginType = 2;
        }
        else if (currentUrlPath == 'publicpostings') {
          this.userLoginType = -1;
        } else {
          this.userLoginType = 1;
        }
      });
  }
}
