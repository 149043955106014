import { Component, Input, OnInit, Output, EventEmitter,Optional} from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { BreakpointObserver } from '@angular/cdk/layout';

export const MMYYYY_Format = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-candidate-certifications',
  templateUrl: './candidate-certifications.component.html',
  styleUrls: ['./candidate-certifications.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MMYYYY_Format },
  ],
})
export class CandidateCertificationsComponent implements OnInit {
  @Input() candidateCertifications: any;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();  

  candidateProfile = {
    candidateCertifications: null
  }
  public form = this.fb.group({
    candidateCertifications: new UntypedFormArray([])
  });
  public addCourses = this.fb.group({
    coursesToAdd : new UntypedFormControl(false, [])
  });
  public isNextClicked = false;
  public isAddtionalCourse = false;
  public maxDate = new Date();
  public breakpoint:number; // used to classify the screen size/display width
  constructor(private fb: UntypedFormBuilder, private _talentAPIService: TalentApiService,private breakpointObserver: BreakpointObserver, @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    this.setMaxDateforCal();
    this.isAddtionalCourse = (this.edit?true:false);
    if(this.candidateCertifications && this.candidateCertifications.length > 0){
      for(let exp of this.candidateCertifications) {
        exp.dateAwarded = new Date(exp.dateAwarded);
        this.t.push(this.fb.group({
          name: [exp.name, [Validators.required, Validators.maxLength(100)]],
          authority: [exp.authority, [Validators.required, Validators.maxLength(100)]],
          dateAwarded: [exp.dateAwarded, [Validators.required]]
        }));
      }
    }
    this.form.patchValue(this.candidateCertifications);
  }
  get f() { return this.form.controls; }
  get t() { return this.f.candidateCertifications as UntypedFormArray; }

  // Hide Date selection from date picker
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, index) {
   
    const ctrlValue = _moment(this.form.value.candidateCertifications[index].dateAwarded);
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.year(normalizedMonth.year());
    const controlArray = <UntypedFormArray>this.form.get('candidateCertifications');
    controlArray.controls[index].get('dateAwarded').setValue(ctrlValue);
    datepicker.close();
  }


  setMaxDateforCal() {
    this.maxDate = new Date();       
    this.maxDate.setHours(23);
    this.maxDate.setMinutes(59);
    this.maxDate.setSeconds(59);
  } 

  courseChange() {
    if(this.addCourses.get('coursesToAdd').value == true) {
      this.isAddtionalCourse = true;
      if(this.t.controls.length == 0) {
        this.addCertification();
      }
      
    } else {
      this.isAddtionalCourse = false;
      this.t.clear();
      this.next();
    }
  }

  backToIsAdditional() {
    this.isAddtionalCourse = false;
  }

  addCertification() {
    this.t.push(this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      authority: ['', [Validators.required, Validators.maxLength(100)]],
      dateAwarded: [_moment(), [Validators.required]]
    }));
  }

  removeCertification(index) {
    if (index !== -1) {
      this.t.removeAt(index);
    }
  }

  

  next() {   

    this.isNextClicked = true;
    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return;
    } else {
      const candidateProfile = this.form.value;
      this._talentAPIService.updateProfile(candidateProfile)
        .subscribe(
        (result:any) => {
            this.saveProfile.emit(candidateProfile.candidateCertifications);
            var data = this.form.value;
            if(this.edit === true) {
              this.dialogRef.close(data);
            }
        },
        error => {
          this._talentAPIService.snackbarMessage('Something went wrong!');
        });
    }
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  prev() {
		this.prevEvent.emit();
	}

  skip() {
    this.saveProfile.emit(this.candidateCertifications);
  }
}
