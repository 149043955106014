<div fxLayout="row" fxLayoutAlign="space-between center" class="title-padding">
    <h2 class="medium-20">User Group access rights - {{ groupInfo.name }}</h2>
    <div *ngIf="!viewOnly" fxLayout="row" fxLayoutGap="10px">
        <button mat-icon-button color="warn" aria-label="Clear" (click)="confirmDelete()">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-stroked-button [mat-dialog-close]="true">CANCEL</button>
        <button mat-raised-button color="primary" (click)="save()">SAVE</button>
    </div>  
    <button *ngIf="viewOnly" mat-stroked-button [mat-dialog-close]="true">CLOSE</button>
</div>
<div fxLayout="column" fxLayoutGap="20px">
    <mat-divider></mat-divider>
    <app-user-group-permissions-component (dataChange)="onDataChange($event)" [viewOnly]="viewOnly" [groupId]="groupInfo.id"></app-user-group-permissions-component>
</div>
