import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'studentFilter'
})
export class StudentFilterPipe implements PipeTransform {

   public transform(value, keys: any, term:any) {

    if (!keys) return value;
    //Open,Archived,Active for status check
    let filter_status = '';
    let key_filter=null;
    if(keys.active){
      key_filter = 'active';
      filter_status = keys.active;
    }else if(keys.pending){
      key_filter = 'pending';
      filter_status = keys.pending;
    }
    
    return (value || []).filter((item) => key_filter.split(',').some(key => item.hasOwnProperty(key) && new RegExp(filter_status, 'gi').test(item[key])));
  
  }

}
