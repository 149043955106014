import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TalentApiService } from '../../Services/talent-api.service';
import { LoginService } from '../../Services/login.service';
import { UserInfoService } from '../../Services/user-info.service';
import { ProfileService } from '../../Services/profile.service';
import { ErrorStateMatcher, ThemePalette } from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    emailMatcher = new EmailErrorStateMatcher();
    public emailForm = new UntypedFormGroup({
		emailOld: new UntypedFormControl('', [
			Validators.required,
			Validators.email
		]),
		password: new UntypedFormControl('', [
			Validators.required,
        ]),
        emailNew: new UntypedFormControl('', [
            Validators.required,
            Validators.email
        ]),
        emailConfirm: new UntypedFormControl('', [
            Validators.required
        ])
    }, { validators: this.checkEmails });

    public passwordForm = new UntypedFormGroup({
		email: new UntypedFormControl('', [
			Validators.required,
			Validators.email
		]),
		passwordOld: new UntypedFormControl('', [
			Validators.required,
        ]),
        passwordNew: new UntypedFormControl('', [
            Validators.required
        ]),
        passwordConfirm: new UntypedFormControl('', [
            Validators.required
        ])
    });
    
    
    public themes = [
        'default',
        'projector-friendly'
    ];
    public selectedTheme = this.themes[0];
    public tab = 1;
    public cardView = true;
    public themeSwitch:any;
    public errorMessage:string = "";
    public successMessage:string = "";
    public allow_emails; 
    public user;
    public userId;
    public userType;
    color: ThemePalette = 'accent';
    constructor(private _talentApi: TalentApiService,
        private _profileService: ProfileService, 
        private _loginService: LoginService, 
        private _router: Router, 
        private _snackBar: MatSnackBar,
        private _userInfoService: UserInfoService) { }

    ngOnInit() {
		this.user = this._userInfoService.getUser();
		this.userId = this.user.id;
        this.getSubscriptionStatus();
        this.userType = parseInt(this.user.type);
    }

   	setTheme() {
        this.selectedTheme = this.themeSwitch ? this.themes[0] : this.themes[1];
    };
    checkTab(tab) {
        return this.tab === tab;
    };

    setTab(tab) {
        this.errorMessage = "";
        this.successMessage = "";
        this.tab = tab;
    };

    // retrieves form input for password change
    // @effects:    logs user out, password is changes on backend
    //              confirmation sent to user's email
    changePassword() {
        this.errorMessage = "";
        let params = {
			email: this.passwordForm.get('email').value,
            passwordOld: this.passwordForm.get('passwordOld').value,
            passwordNew: this.passwordForm.get('passwordNew').value
		};
        this._profileService.changePassword(params).subscribe((result:any) => 
            { 
                if(result !== true) {
                    this.errorMessage = result;
                } else {
                    let PasswordSnackBar = this._snackBar.open("Password successfully changed, please login again.", 'Ok', {
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                        panelClass: ['success-snackbar']
                    });
                    PasswordSnackBar.afterDismissed().subscribe(() => {
                        this._loginService.logout().subscribe();
                        this._talentApi.logoutEvent(true);
                    });
                }  			
  		    },  
            error => {
                console.log('error');
            });   
    }

    // retrieves form input from email change
    // @effects     email address is changes in database,
    //              confimations sent to new and old emails
    changeEmail() {
        this.errorMessage = "";
        let params = {
			emailOld: this.emailForm.get('emailOld').value,
            password: this.emailForm.get('password').value,
            emailNew: this.emailForm.get('emailNew').value
        };
        this._userInfoService.changeEmail(params).subscribe((result:any) => {
            // this.emailForm.reset();
            if(result !== true) {
                this.errorMessage = result;
                this.successMessage = '';
            }
            else {
                let EmailSnackBar = this._snackBar.open("Email successfully changed, please login again.", 'Ok', {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    panelClass: ['success-snackbar']
                });
                EmailSnackBar.afterDismissed().subscribe(() => {
                    this._loginService.logout().subscribe();
                    this._talentApi.logoutEvent(true);
                });
            }
        })
    }
    checkEmails(group: UntypedFormGroup) { 
        const Email = group.get('emailNew').value;
        const confirmEmail = group.get('emailConfirm').value;
        if(Email && confirmEmail) {
            return (Email === confirmEmail) ? null : { emailNotMatched: true } 
        } else {
            return null;
        }
    }
    getSubscriptionStatus() {
        this._userInfoService.getSubscriptionStatus(this.userId).subscribe((result:any) => 
            {
                if(result){
                    this.allow_emails = result.allow_emails;
                }
            },  
            error => {
                console.log('error');
            }
        ); 
    }
    isReceiveEmails(event) {
        if(event.checked) {
            this.allow_emails = 1;
        } else {
            this.allow_emails = 0;        
        }
        this._userInfoService.updateSubscriptionStatus(this.userId,this.allow_emails)
			.subscribe(
			(result:any) => {
                if(result.statusType) {
                    if(this.allow_emails == 0){
                        var snackbarMsg;
                        if(this.userType == 2) {
                            snackbarMsg = 'You will no longer receive emails from HipJobJuice except those required for contact from Postings to which you apply.';
                        } else if(this.userType == 3) {
                            snackbarMsg = 'You will no longer receive emails from HipJobJuice except those necessary for the hiring of candidates you advance.';
                        }
                        let subscriptionSnackBar = this._snackBar.open(snackbarMsg, 'Ok', {
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                            panelClass: ['success-snackbar']
                        });
                        subscriptionSnackBar.afterDismissed().subscribe(() => {
                            this._talentApi.snackbarMessage(result.msg);
                        });
                    } else {
                        this._talentApi.snackbarMessage(result.msg);
                    }
                }
			},
			error => {
                this._talentApi.snackbarMessage('Something went wrong!');
			}
        );
    }
}
export class EmailErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.parent.errors && control.parent.errors['emailNotMatched']
    }
  }
