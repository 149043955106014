import { Component, Input, OnInit } from '@angular/core';
import { th } from 'date-fns/locale';
import { CandidateInfo } from '../../../../Models/CandidateProfile';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeUrl, SafeHtml, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CandidateInfoComponent } from '../candidate-info/candidate-info.component';
import { CandidateSectorComponent } from '../candidate-sector/candidate-sector.component';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { CommonConfirmPopupComponent } from '../../../../Pages/dialogs/common-confirm-popup/common-confirm-popup.component';
import { OpportunityService } from '../../../../Services/opportunity.service';

@Component({
  selector: 'app-candidate-info-view',
  templateUrl: './candidate-info-view.component.html',
  styleUrls: ['./candidate-info-view.component.css']
})
export class CandidateInfoViewComponent implements OnInit {

  @Input() candidateInfo: any;
  @Input() jobCurrentStatus: any = '';
  @Input() ownProfile: boolean;
  public isResumeAvailable: boolean = false;
  candidateAvailabilities: any;
  @Input() postingId: any = 0;
  public showContactDetails: boolean = false;


  constructor(iconRegistry: MatIconRegistry,
    private _opportunitService: OpportunityService,
    sanitizer: DomSanitizer, public _dialog: MatDialog) {
    iconRegistry.addSvgIcon(
      'thumbs-up',
      sanitizer.bypassSecurityTrustResourceUrl('../../assets/imgtst/icons/svg/create-24px.svg'));
  }

  ngOnInit(): void {
    //this.candidateInfo.candidateInfo.profilePic = JSON.parse(this.candidateInfo.candidateInfo.profilePic);
    if (this.candidateInfo.candidateInfo.profilePic == '') {
      this.candidateInfo.candidateInfo.profilePic = '../../assets/img/User-blue-icon.png';
    }
    this.candidateInfo.resume = JSON.parse(this.candidateInfo.resume);
    this.candidateAvailabilities = this.candidateInfo.availabilityComp.availabilities.length > 0 ? this.candidateInfo.availabilityComp.availabilities.map(x => x.name).join(", ") : '';
        
    if (this.postingId > 0 && this.jobCurrentStatus != 'Invited') {
      this.requestAvailableFields();
    }
    else {
      this.postingId = 0;//stop any posting api call
    }
  }

  downloadDoc(referenceId) {
    if (!this.candidateInfo.resume.share) {
      return false;
    }
    let obj: any;
    obj = this.candidateInfo.resume;
    if (obj.content && obj.name) {
      let pdf = 'data:application/octet-stream;base64,' + obj.content;
      $('#' + referenceId).attr('href', pdf);
      $('#' + referenceId).attr('download', obj.name);
    }
  }

  openEdit(componenet_type) {
    if (componenet_type == 'candidateInfo') {
      let dialog = this._dialog.open(ProfileEditComponent, {
        height: 'auto',
        width: 'auto',
        disableClose: true,
        data: {
          subComponent: 'candidate-info',
          seedData: this.candidateInfo.candidateInfo
        }
      });
      dialog.afterClosed()
        .subscribe((result) => {
          //this won't work. we'll probably want to just refresh the page.
          if (result == null) {
          } else {
            console.log(result);
            this.candidateInfo.candidateInfo = result;
          }
        });
    }
    else if (componenet_type == 'sectorTitle') {
      console.log(this.candidateInfo.sectorTitle);
      let dialog = this._dialog.open(ProfileEditComponent, {
        height: 'auto',
        width: '80%',
        disableClose: true,
        data: {
          subComponent: 'candidate-sector',
          seedData: this.candidateInfo.sectorTitle
        }
      });

      dialog.afterClosed()
        .subscribe((result) => {
          if (result == null) {
          } else {
            console.log(result);
            this.candidateInfo.sectorTitle = result;
          }
        });
    }
    else if (componenet_type == 'location') {
      console.log(this.candidateInfo.location);
      let dialog = this._dialog.open(ProfileEditComponent, {
        height: 'auto',
        width: '80%',
        disableClose: true,
        data: {
          subComponent: 'candidate-location',
          seedData: this.candidateInfo.location
        }
      });

      dialog.afterClosed()
        .subscribe((result) => {
          if (result == null) {
          } else {
            console.log(result);
            this.candidateInfo.location = result;
          }
        });
    }
    else if (componenet_type == 'availabilityComp') {
      console.log(this.candidateInfo.availabilityComp);
      this.candidateInfo.availabilityComp.minComp = parseInt(this.candidateInfo.availabilityComp.minComp);
      this.candidateInfo.availabilityComp.maxComp = parseInt(this.candidateInfo.availabilityComp.maxComp);
      let dialog = this._dialog.open(ProfileEditComponent, {
        height: 'auto',
        width: '80%',
        disableClose: true,
        data: {
          subComponent: 'candidate-availabilityComp',
          seedData: this.candidateInfo.availabilityComp
        }
      });

      dialog.afterClosed()
        .subscribe((result) => {
          if (result == null) {
          } else {
            console.log(result);
            this.candidateInfo.availabilityComp = result;
            this.candidateAvailabilities = this.candidateInfo.availabilityComp.availabilities.length > 0 ? this.candidateInfo.availabilityComp.availabilities.map(x => x.name).join(", ") : '';
          }
        });
    }
    else if (componenet_type == 'availabilityStatus') {
      console.log(this.candidateInfo.availabilityStatus);
      let dialog = this._dialog.open(ProfileEditComponent, {
        height: 'auto',
        width: '80%',
        disableClose: true,
        data: {
          subComponent: 'candidate-availabilityStatus',
          seedData: this.candidateInfo.availabilityStatus
        }
      });

      dialog.afterClosed()
        .subscribe((result) => {
          if (result == null) {
          } else {
            console.log(result);
            this.candidateInfo.availabilityStatus = result;
          }
        });
    }
    else if (componenet_type == 'experience') {
      console.log(this.candidateInfo.availabilityStatus);
      let dialog = this._dialog.open(ProfileEditComponent, {
        height: 'auto',
        width: '80%',
        disableClose: true,
        data: {
          subComponent: 'candidate-work-experience',
          seedData: this.candidateInfo.workExperience
        }
      });

      dialog.afterClosed()
        .subscribe((result) => {
          if (result == null) {
          } else {
            console.log(result);
            if (result.length > 0) {
              result.sort((e1, e2) => {
                if (new Date(e1.start_date) > new Date(e2.start_date)) return -1;
                else if (new Date(e1.start_date) < new Date(e2.start_date)) return 1;
                else return 0;
              });
            }
            this.candidateInfo.workExperience = result;
          }
        });
    }
  }

  requestAvailableFields() {
    this._opportunitService.requestAvailableFields(this.postingId, this.candidateInfo.candidateInfo.candidateId)
      .subscribe((result: any) => {
        if (result != undefined && result.availableFields.length > 0) {          
          this.showContactDetails = true;
          this.candidateInfo.candidateInfo.phone = result.availableFields[0].telephone;
          this.candidateInfo.candidateInfo.email = result.availableFields[0].email;
        }
      });
  }

  requestContact(candId) {
    let dialog;
    dialog = this._dialog.open(CommonConfirmPopupComponent, {
      height: '205px',
      width: '40%',
      disableClose: false,
    });
    dialog.componentInstance.dialogTitle = this.candidateInfo.candidateInfo.firstName + " 's contact details will be shared with you on your registered email id.";
    dialog.componentInstance.confirm_message = 'As per HipJouice policy, your contact details will be shared with the candidate.';
    dialog.componentInstance.discardConfirm = true;
    dialog.componentInstance.cancelText = 'CANCEL';
    dialog.componentInstance.okText = 'OK';

    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this._opportunitService.insertContactCandidate(this.postingId, this.candidateInfo.candidateInfo.candidateId).subscribe(
            (result: any) => {
              if (result != undefined && result.statusType == 'success') {
                this.requestAvailableFields();
              }
            },
            error => {

            }
          );
        } else {

        }
      });

  }
}
