import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { User } from '../Models/User';
import { SharedUtilitiesService } from './shared-utilities.service';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'underscore';

@Injectable()
export class UserInfoService {

	public user: User = new User();

	constructor(private _http: HttpClient, private sharedUtils: SharedUtilitiesService) { }

	storeTokens(tokens) {
		localStorage.setItem("accessToken", tokens['accessToken']);
		localStorage.setItem("refreshToken", tokens['refreshToken']);
	}

	storeTempAccessToken(tokens, name) {
		this.setUsername(name);
		localStorage.setItem("accessToken", tokens['accessToken']);
	}
	
	removeTokens() {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
	}

	getAccessToken() {
		if (localStorage.getItem('accessToken') === null) {
			return '';
		}
		return localStorage.getItem('accessToken');
	}

	clearUser() {
		this.user = {
			id: '0',
			fk_tenant: '',
			first_name: '',
			last_name: '',
			name: '',
			username: '',
			type: '0',
			enabled: '',
			email_verified: false,
			school: 0,
			telephone: 0,
			company: ''
		};
	}

	logout() {
		this.user = {
			id: '0',
			fk_tenant: '',
			first_name: '',
			last_name: '',
			name: '',
			username: '',
			type: '0',
			enabled: '',
			email_verified: false,
			school: 0,
			telephone: 0,
			company: ''
		};
		this.removeTokens();
	}

	getRefreshToken() {
		return localStorage.getItem("refreshToken");
	}


	setUser(newUser) {
		if (newUser && newUser.id) {
			//Object.assign(this.user, newUser);
			this.user = {
				id: newUser.id,
				fk_tenant: newUser.fk_tenant,
				first_name: newUser.first_name,
				last_name: newUser.last_name,
				name: newUser.name,
				username: newUser.username,
				type: newUser.type,
				enabled: newUser.enabled,
				email_verified: (newUser.verified == 1) ? true : false,
				school: newUser.school,
				telephone: newUser.telephone,
				company: newUser.company_name
			}
			//angular.extend(user, newUser);
			//$log.info(user);
			return true;
		}
		return false;
	}

	setUsername(name){
		this.user.username = name;
	}

	setName(name) {
		this.user.name = name;
	}

	setType(type) {
		this.user.type = type || '';
	}

	getUser() {
		return this.user;
	}

	refreshToken() {
		let data = {"refreshToken" : this.getRefreshToken()};
		let headers = new HttpHeaders();
    	headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/refreshToken", data, { headers: headers }).pipe(
			map(response => {
				if (typeof (response !== 'undefined')) {
					return response['tokens'];
				}
				else {
					console.log('Could not refresh auth tokens');
				}
			}),
			catchError(error => observableThrowError (
			   { status: error.status, msg:error.error }
			)));
	}

	//clearUser: clearUser,
	isArray(value) {
        return Array.isArray ? Array.isArray(value) : value instanceof Array;
	}

	authenticateRoute(route:string) {
		let data = { route : route };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/authenticateRoute", data, { headers: headers });
	}

	changeEmail(params) {
		let password = CryptoJS.SHA256(params.password).toString();
		let data = { emailOld: params.emailOld, password: password, emailNew: params.emailNew };
		let headers = new HttpHeaders();
    	headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/changeEmail", data, { headers: headers }).pipe(
			map(response => {
				if (typeof (response !== 'undefined')) {
					if(response['statusType'] == 'success') {
						return response['status'];
					} else {
						return response['msg'];
					}
				}
				else {
					console.log('Could not refresh auth tokens');
				}}),
				catchError(error => observableThrowError (
					{ status: error.status }
				)));	
			}
 
	getMyProfilePic(company) {
		let data = {'company' : company};
		let headers = new HttpHeaders();
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getMyProfilePic", data, { headers: headers }).pipe(
			map(response => {
				return response['file'];
			}),
			catchError(error => observableThrowError(
				{status: error.status}
			)));
	}

	setDefaultLogo(userType, companyName) {
		var imgUrl = '';
		console.log("problem");
		var type = '';
		if (userType == 1) {
		  type = 'school'
		} else {
		  type = 'company'
		}
		this.sharedUtils.getOrganizationLogo(companyName, type).subscribe((result: any) => {
		  if (result != null) {
			imgUrl = result;
		  } 
		  else {
			imgUrl =  '../../../assets/img/nologo.png';
		  }
		});
		console.log(imgUrl !== null);
		return imgUrl;
	  }	
	  getSubscriptionStatus(userId) {
		let data = { userId: userId};
		let headers = new HttpHeaders();
    	headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/getSubscriptionStatus", data, { headers: headers }).pipe(
		map(response => {
			if (typeof (response !== 'undefined')) {
				return response['status'];
			}
			else {
				console.log('Could not get subscription status');
			}}),
			catchError(error => observableThrowError (
				{ status: error.status }
			)));
	}

	checkMyPermission(permission) {
		let data = { 'permission-name' : permission};
		let headers = new HttpHeaders();
    	headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/hasPermission", data, { headers: headers }).pipe(
		map(response => {
			if (typeof (response !== 'undefined')) {
				return response['hasPermission'];
			}
			else {
				console.log('Could not check permission');
			}}),
			catchError(error => observableThrowError (
				{ status: error.status }
			)));
	}

	updateSubscriptionStatus(userId,subscriptionStatus) {
		let data = { userId: userId,subscriptionStatus:subscriptionStatus};
		let headers = new HttpHeaders();
    	headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/updateSubscriptionStatus", data, { headers: headers }).pipe(
		map(response => {
			if (typeof (response !== 'undefined')) {
				return response;
			}
			else {
				console.log('Could not update subscription status');
			}}),
			catchError(error => observableThrowError (
				{ status: error.status }
			)));
	}
}

