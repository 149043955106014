<div fxLayout="row" fxLayoutAlign="space-between start">
    <h3>Work Experience</h3>
    <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit()">edit</mat-icon>
</div>

<div fxLayout="column" fxLayoutAlign="start stretch" class="candidate-education-list">
    <div *ngFor="let exp of experiences; let i=index">
        <mat-list *ngIf="!showingMore && i<3">
            <mat-list-item>
                <app-company-logo matListAvatar [name]="exp.company_name" [type]="'company'"></app-company-logo>
                <h4 mat-line class="line-height">{{ exp.company_name }}</h4>
                <h4 mat-line class="gray-font uppercase line-height"> {{ exp.title }} </h4>
                <h5 mat-line class="small-font gray-font line-height">
                  {{ exp.start_date | date: 'MMMM yyyy' }} - {{exp.present==true ? 'Present' : exp.end_date | date: 'MMMM yyyy'  }} 
                </h5>
                <p mat-line class="work-description small-font gray-font" [innerHTML]="exp.description"></p>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngFor="let exp of experiences; let i=index">
        <mat-list *ngIf="showingMore">
            <mat-list-item>
                <img class="experience-logo" matListAvatar [src]="logos[i].logo">
                <h4 mat-line class="line-height">{{ exp.company_name }}</h4>
                <h4 mat-line class="gray-font uppercase line-height"> {{ exp.title }} </h4>
                <h5 mat-line class="small-font gray-font line-height">
                  {{ exp.start_date | date: 'MMMM yyyy' }} - {{exp.present==true ? 'Present' : exp.end_date | date: 'MMMM yyyy'  }}
                </h5>
                <p mat-line class="work-description small-font gray-font" [innerHTML]="exp.description"></p>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="experiences.length>3" fxLayout="row" fxLayoutAlign="center start">
        <button *ngIf="!showingMore" mat-stroked-button color="primary"
             fxFlex="33%" class="show-more-button" (click)="toggleShow()">Show More</button>
        <button *ngIf="showingMore" mat-stroked-button color="primary" 
             fxFlex="33%" class="show-more-button" (click)="toggleShow()">Show Less</button>
    </div>
</div>
