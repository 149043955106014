import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs';
import {catchError, map} from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class SidenavService{

  private openedSubject:BehaviorSubject<any>;
  public navRoutesLoaded:EventEmitter<any> = new EventEmitter();

  constructor(private _http: HttpClient) { 
    this.openedSubject = new BehaviorSubject<any>(null);
  }

  private navRoutes:any[] = [];
  private actionsRoutes:any[] = [];

  // used by header and sidebar after getNavigationRoutes() is loaded
  getNavRoutes() {
    return this.navRoutes;
  }

  // used by header and sidebar after getNavigationRoutes() is loaded
  getActionsRoutes() {
    return this.actionsRoutes;
  }

  // gets sidebar status
  getOpened(): Observable<boolean> {
    return this.openedSubject.asObservable();
  }

  // opens/hides sidebar
  toggle() {
    this.openedSubject.next(null);
  }

  // gets data to populate the action routes and nav routes 
  // for the header and sidebar. This is called by getUserDetail
  //    @effects: emits true from navRoutesLoaded which is subscribed to 
  //              by app header and sidenav to load the links, and on
  //              emission, the components read from this service 
  getNavigationRoutes() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.actionsRoutes = [];
    this.navRoutes = [];
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getNavigationRoutes", { }, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
            let routes = response['navigationRoutes'];
            for(let route of routes) {
              if(route.actions == '1') {
                this.actionsRoutes.push({link: route.route, name: route.name});
              }
              if(route.nav == '1') {
                this.navRoutes.push({link: route.route, name: route.name});
              }
            }
            this.navRoutesLoaded.emit(true);
						return routes;
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
  }
}
