<form name="classSelector" id="classSelector" class="" #employerRegistration="ngForm" novalidate>
    <div fxLayout="row wrap" fxFlex="100">
        <h4 mat-dialog-title fxFlex="50">Logout?</h4>
        <div fxFlex="50" fxLayoutAlign="end start">
            <button mat-icon-button (click)="cancelLogout()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div mat-dialog-actions fxFlex="100" fxLayoutAlign="end center">
            <button mat-button (click)="cancelLogout()">
                Cancel
            </button>
            <button mat-button color="primary" (click)="confirmLogout()">
                Logout
            </button>
        </div>
    </div>
</form>