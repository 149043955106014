import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { AppheaderComponent } from './Core/appheader/appheader.component';
import { LoginComponent } from './Pages/login/login.component';
import { TalentApiService } from './Services/talent-api.service';
import { LoginService } from './Services/login.service';
import { AppRoutingModule } from './app-routing.module';
import { TermsConditionRegComponent } from './Pages/terms-condition-reg/terms-condition-reg.component';
import { RegisterService } from './Services/register.service';
import { AuthGuardService } from './Services/auth-guard.service';
import { ThemeService } from './Services/theme.service';
import { TenantService } from './Services/tenant.service';
import { EmployerRegistrationComponent } from './Pages/employer-registration/employer-registration.component';
import { MaterialModule } from './Core/modules/material.module';
import { EmployerRegisterService } from './Services/employer-register.service';
import { DashboardService } from './Services/dashboard.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ProfileComponent } from './Pages/profile/profile.component';
import { ProfileService } from './Services/profile.service';
import { UserInfoService } from './Services/user-info.service';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { StudentRegistrationComponent } from './Pages/student-registration/student-registration.component';
import { EmployerStudentComponent } from './Pages/employer-student/employer-student.component';
import { EmployerStudentService } from './Services/employer-student.service';
import {MatTreeModule} from '@angular/material/tree';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import { Overlay, CloseScrollStrategy } from '@angular/cdk/overlay';
import { MatBadgeModule } from '@angular/material/badge';
import { TagInputModule } from 'ngx-chips';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CustomFormsModule } from 'ng2-validation'
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { NgwWowModule } from 'ngx-wow';
import { SettingsComponent } from './Pages/settings/settings.component';
import { TimesheetComponent } from './Pages/timesheet/timesheet.component';
import { TimesheetService } from './Services/timesheet.service';
import { DataTablesModule } from 'angular-datatables';
import { PostingComponent } from './Pages/posting/posting.component';
import { TimesheetActionComponent } from './Pages/dialogs/timesheet-action/timesheet-action.component';
import { SearchTextPipe } from './Helpers/Pipes/search-text.pipe';
import { PostingService } from './Services/posting.service';
import { PostingSearchPipe } from './Helpers/Pipes/posting-search.pipe';
import { PostingFilterPipe } from './Helpers/Pipes/posting-filter.pipe';
import { PostingOrderPipe } from './Helpers/Pipes/posting-order.pipe';
import { PipelineFilterPipe } from './Helpers/Pipes/pipeline-filter.pipe';
import { PipelineSearchPipe } from './Helpers/Pipes/pipeline-search.pipe';
import { SafeHtmlPipe } from './Helpers/Pipes/safe-html.pipe';
import { FeedbackComponent } from './Pages/feedback/feedback.component';
import { StudentFilterPipe } from './Helpers/Pipes/student-filter.pipe';
import { FeedbackRequestComponent } from './Pages/dialogs/feedback-request/feedback-request.component';
import { FeedbackTemplateComponent } from './Pages/dialogs/feedback-template/feedback-template.component';
import { EmployerSetupProfileComponent } from './Pages/dialogs/employer-setup-profile/employer-setup-profile.component';
import { FeedbackFilterPipe } from './Helpers/Pipes/feedback-filter.pipe';
import { FeedbackSearchPipe } from './Helpers/Pipes/feedback-search.pipe';
import { FeedbackOrderPipe } from './Helpers/Pipes/feedback-order.pipe';
import { SubmitFeedbackComponent } from './Pages/dialogs/submit-feedback/submit-feedback.component';
import { CreateClassComponent } from './Pages/dialogs/create-class/create-class.component';
import { ResetPasswordComponent } from './Pages/dialogs/reset-password/reset-password.component';
import { AdminDashboardComponent } from './Admin/admin-dashboard/admin-dashboard.component';
import { AdminConfigComponent } from './Admin/admin-config/admin-config.component';
import { AdminHeaderComponent } from './Admin/admin-header/admin-header.component';
import { AdminApiService } from './Services/admin-api.service';
import { ConfigEditComponent } from './Admin/dialogs/config-edit/config-edit.component';
import { UsersComponent } from './Admin/users/users.component';
import { UserInfoComponent } from './Admin/dialogs/user-info/user-info.component';
import { AdminSkillsComponent,SkillDialog } from './Admin/admin-skills/admin-skills.component';
import { AdminLogoComponent,DefaultLogoDialog ,ReviewLogoDialog} from './Admin/admin-logo/admin-logo.component';
import { DialogService } from './Services/dialog.service';
import { CanDeactivateGuardService } from './Services/can-deactivate-guard.service';
import { NewFeedbackComponent } from './Pages/dialogs/new-feedback/new-feedback.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { TenantLogoComponent } from './Admin/tenant-logo/tenant-logo.component';
import { TenantComponent } from './Admin/tenant/tenant.component';
import { TenantEditComponent } from './Admin/dialogs/tenant-edit/tenant-edit.component';
import { TenantLogoEditComponent } from './Admin/dialogs/tenant-logo-edit/tenant-logo-edit.component';
import { LogoComponent } from './Pages/logo/logo.component';
import { NotificationsComponent } from './Pages/dashboard/notifications/notifications.component';
import { NewsFeedComponent } from './Pages/dashboard/news-feed/news-feed.component';
import { GraphingCardComponent } from './Pages/dashboard/graphing-card/graphing-card.component';
import { ProfileMetricsComponent } from './Pages/dashboard/profile-metrics/profile-metrics.component';
import { AdminNewsFeedComponent } from './Admin/admin-news-feed/admin-news-feed.component';
import { NewsFeedEditComponent } from './Admin/dialogs/news-feed-edit/news-feed-edit.component';
import { SearchStudentComponent } from './Pages/dialogs/search-student/search-student.component';

import { EmployerOverviewComponent } from './Pages/dashboard/employer-overview/employer-overview.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TopSkillsGraphComponent } from './Pages/dashboard/graphing-card/top-skills-graph/top-skills-graph.component';
import { PostingBreakdownGraphComponent } from './Pages/dashboard/graphing-card/posting-breakdown-graph/posting-breakdown-graph.component';
import { OldestPostingsComponent } from './Pages/dashboard/graphing-card/oldest-postings/oldest-postings.component';
import { StudentCardComponent } from './Pages/student-card/student-card.component';
import { SidenavComponent } from './Core/sidenav/sidenav.component';
import { ValidatePhoneDirective } from './Directives/validate-phone.directive';
import { TokenInterceptor } from './Services/token.interceptor';
import { OpportunityService } from './Services/opportunity.service';
import { OpportunitySearchFilterDialogComponent } from './Pages/dialogs/opportunity-search-filter-dialog/opportunity-search-filter-dialog.component';
import { OpportunityListCardComponent } from './Pages/opportunity-list-card/opportunity-list-card.component';
import { OpportunityDetailComponent } from './Pages/opportunity-detail/opportunity-detail.component';
import { AdminTenantPermissionsComponent } from './Admin/admin-tenant-permissions/admin-tenant-permissions.component';
import { CandidateInfoComponent } from './Shared/components/candidate/candidate-info/candidate-info.component';
import { CandidateResumeComponent } from './Shared/components/candidate/candidate-resume/candidate-resume.component';
import { CandidateWorkExperienceComponent } from './Shared/components/candidate/candidate-work-experience/candidate-work-experience.component';
import { CandidateInfoViewComponent } from './Shared/components/candidate/candidate-info-view/candidate-info-view.component';
import { CandidateAvailabilityStatusComponent } from './Shared/components/candidate/candidate-availability-status/candidate-availability-status.component';
import { WorkExperienceViewComponent } from './Shared/components/work-experience-view/work-experience-view.component';
import {  CandidateSectorComponent } from './Shared/components/candidate/candidate-sector/candidate-sector.component';
import { CandidateWorkEnvironmentComponent } from './Shared/components/candidate/candidate-work-environment/candidate-work-environment.component';
import { CandidateResumeViewComponent } from './Shared/components/candidate/candidate-resume-view/candidate-resume-view.component';
import { SkillsViewComponent } from './Shared/components/skills-view/skills-view.component';
import { CoursesAndCertificationsViewComponent } from './Shared/components/courses-and-certifications-view/courses-and-certifications-view.component';
import { AdditionalContactsViewComponent } from './Shared/components/additional-contacts-view/additional-contacts-view.component';
import { EducationViewComponent } from './Shared/components/education-view/education-view.component';
import { CandidateLocationComponent } from './Shared/components/candidate/candidate-location/candidate-location.component';
import { CandidateAvailabilityCompComponent } from './Shared/components/candidate/candidate-availability-comp/candidate-availability-comp.component';
import { CandidateEducationComponent } from './Shared/components/candidate/candidate-education/candidate-education.component';
import { CandidateCertificationsComponent } from './Shared/components/candidate/candidate-certifications/candidate-certifications.component';
import { CandidateSkillsComponent } from './Shared/components/candidate/candidate-skills/candidate-skills.component';
import { CandidateLinkContactsComponent } from './Shared/components/candidate/candidate-link-contacts/candidate-link-contacts.component';
import { WorkSetupViewComponent } from './Shared/components/work-setup-view/work-setup-view.component';
import { ProfileEditComponent } from './Pages/profile-edit/profile-edit.component';
import { CsvExportService } from './Services/csv-export.service'
import { ReportsComponent } from './Pages/reports/reports.component';
import { CreatePostingDialogComponent } from './Pages/dialogs/create-posting-dialog/create-posting-dialog.component';
import { CommonConfirmPopupComponent } from './Pages/dialogs/common-confirm-popup/common-confirm-popup.component';
import { PostinglistSearchPipe } from './Helpers/Pipes/postinglist-search.pipe';
import { ReportListComponent } from './Pages/report-list/report-list.component';
import { ReportDetailsComponent } from './Pages/report-details/report-details.component';
import { EmployerPostingDetailComponent } from './Pages/employer-posting-detail/employer-posting-detail.component';
import { OpportunityApplicationsComponent } from './Pages/opportunity-applications/opportunity-applications.component';
import { CandidateEngagedCardComponent } from './Pages/candidate-engaged-card/candidate-engaged-card.component';
import { ProfileViewDialogComponent } from './Pages/dialogs/profile-view-dialog/profile-view-dialog.component';
import { CandidateDetailComponent } from './Pages/dialogs/candidate-detail/candidate-detail.component';
import { InviteToOpportunitiesComponent } from './Pages/dialogs/invite-to-opportunities/invite-to-opportunities.component';
import { ShortlistComponent } from  './Pages/shortlist/shortlist.component';
import { ShortListCardComponent } from  './Pages/shortlist-list-card/shortlist-list-card.component';
import { CreateShortlistDialogComponent } from './Pages/dialogs/create-shortlist-dialog/create-shortlist-dialog.component';
import { ShortlistSearchPipe } from './Helpers/Pipes/shortlist-search.pipe';
import { ShortlistService } from './Services/shortlist.service';
import { ShortlistDetailComponent } from './Pages/shortlist-detail/shortlist-detail.component';
import { CandidateToShortlistComponent } from './Pages/dialogs/candidate-to-shortlist/candidate-to-shortlist.component';
import { ShortlistCandidatesComponent } from './Pages/shortlist-candidates/shortlist-candidates.component';
import { AdminEmailTemplateComponent } from './Admin/admin-email-template/admin-email-template.component';
import { EmailTemplateEditComponent } from './Admin/dialogs/email-template-edit/email-template-edit.component';
import { QuillModule } from 'ngx-quill';
import { StudentSmallCardComponent } from './Pages/student-small-card/student-small-card.component';
import { CandidatePostingDetailComponent } from './Pages/candidate-posting-detail/candidate-posting-detail.component';
import { PostingShortlistNotexistsComponent } from './Pages/dialogs/posting-shortlist-notexists/posting-shortlist-notexists.component';
import { CompanyLogoComponent } from './Pages/company-logo/company-logo.component';
import { SharePostingDialogComponent } from './Pages/dialogs/share-posting-dialog/share-posting-dialog.component';
import { CandidateEmailVerificationComponent } from './Shared/components/candidate/candidate-email-verification/candidate-email-verification.component';
import { VerifyEmailComponent } from './Pages/verify-email/verify-email.component';
import { CandidateDiversityQuestionsComponent } from './Shared/components/candidate/candidate-diversity-questions/candidate-diversity-questions.component';
import { HammerConfig } from './Helpers/HammerConfig';
import { SaveFilterDialogComponent } from './Pages/dialogs/save-filter-dialog/save-filter-dialog.component';
import { LogoutConfirmComponent } from './Pages/dialogs/logout-confirm/logout-confirm.component';
import { ErrorMessageComponent } from './Pages/dialogs/error-message/error-message.component';
import { UserRegistrationErrorComponent } from './Pages/dialogs/user-registration-error/user-registration-error.component';
import { ConfirmMessageComponent } from './Pages/dialogs/confirm-message/confirm-message.component';
import { NotificationListComponent } from './Pages/dialogs/notification-list/notification-list.component';
import { AdminMarketingEmailComponent, MarketingDialog } from './Admin/admin-marketing-email/admin-marketing-email.component';
import { CompanyUserGroupsComponent } from './Pages/company-admin/company-user-groups/company-user-groups.component';
import { SystemApisComponent } from './Admin/system-apis/system-apis.component';
import { SystemApiInfoComponent } from './Admin/dialogs/system-api-info/system-api-info.component';
import { CompanyAdminListCardComponent } from './Pages/company-admin/company-admin-list-card/company-admin-list-card.component';
import { CompanyAdminAboutComponent } from './Pages/company-admin/company-admin-about/company-admin-about.component';
import { CompanyAdminComponent } from './Pages/company-admin/company-admin/company-admin.component';
import { CompanyInfoService } from './Services/company-info.service';
import { CreateUserGroupComponent } from './Pages/company-admin/dialogs/create-user-group/create-user-group.component';
import { ViewGroupRightsComponent } from './Pages/company-admin/dialogs/view-group-rights/view-group-rights.component';
import { UserGroupPermissionsComponentComponent } from './Pages/company-admin/dialogs/user-group-permissions-component/user-group-permissions-component.component';
import { AccountUsageComponent } from './Pages/company-admin/account-usage/account-usage.component';
import { MetricDetailComponent } from './Pages/company-admin/metric-detail/metric-detail.component';
import { InviteNewUserComponent } from './Pages/company-admin/invite-new-user/invite-new-user.component';
import { CompanyAdminMyUsersComponent } from './Pages/company-admin/company-admin-my-users/company-admin-my-users.component';
import { ImportBulkOpportunitiesComponent } from './Admin/import-bulk-opportunities/import-bulk-opportunities.component';
import { AdminContentReportingComponent } from './Admin/admin-content-reporting/admin-content-reporting.component';
import { ContentReportingComponent } from './Pages/dialogs/content-reporting/content-reporting.component';
import { EmptyRouteComponent } from './Pages/empty-route/empty-route.component';
import { PublicPostingsComponent } from './Pages/public-postings/public-postings.component';
import { PublicPostingDetailsComponent } from './Pages/public-posting-details/public-posting-details.component';
import { SkillTreeControlComponent } from './Pages/controls/skill-tree-control/skill-tree-control.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PostingMatchCandidateComponent } from './Pages/posting-match-candidate/posting-match-candidate.component';
import { CandidateDiversityEditComponent } from './Shared/components/candidate/candidate-diversity-edit/candidate-diversity-edit.component';

const maskConfig: Partial<IConfig> = {
	validation: false,
  };

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
    return () => overlay.scrollStrategies.close();
}
@NgModule({
    declarations: [
        AppComponent,
        AppheaderComponent,
        LoginComponent,
        TermsConditionRegComponent,
        EmployerRegistrationComponent,
        ProfileComponent,
        StudentRegistrationComponent,
        EmployerStudentComponent,
        DashboardComponent,
        SettingsComponent,
        TimesheetComponent,
        PostingComponent,
        TimesheetActionComponent,
        SearchTextPipe,
        PostingSearchPipe,
        PostingFilterPipe,
        PostingOrderPipe,
        PipelineFilterPipe,
        PipelineSearchPipe,
        SafeHtmlPipe,
        FeedbackComponent,
        StudentFilterPipe,
        FeedbackRequestComponent,
        FeedbackTemplateComponent,
        EmployerSetupProfileComponent,
        FeedbackFilterPipe,
        FeedbackSearchPipe,
        FeedbackOrderPipe,
        SubmitFeedbackComponent,
        CreateClassComponent,
        ResetPasswordComponent,
        AdminDashboardComponent,
        AdminConfigComponent,
        AdminHeaderComponent,
        ConfigEditComponent,
        UsersComponent,
        UserInfoComponent,
        AdminSkillsComponent,
        AdminLogoComponent,
        ValidatePhoneDirective,
        NewFeedbackComponent,
        TenantLogoComponent,
        TenantComponent,
        TenantEditComponent,
        TenantLogoEditComponent,
        LogoComponent,
        DefaultLogoDialog,
        ReviewLogoDialog,
        SkillDialog,
        NotificationsComponent,
        NewsFeedComponent,
        GraphingCardComponent,
        ProfileMetricsComponent,
        AdminNewsFeedComponent,
        NewsFeedEditComponent,
        SearchStudentComponent,
        EmployerOverviewComponent,
        TopSkillsGraphComponent,
        PostingBreakdownGraphComponent,
        OldestPostingsComponent,
        StudentCardComponent,
        SidenavComponent,
        OpportunitySearchFilterDialogComponent,
        OpportunityListCardComponent,
        OpportunityDetailComponent,
        AdminTenantPermissionsComponent, CandidateInfoComponent, CandidateResumeComponent,
        CandidateWorkExperienceComponent,
        CandidateAvailabilityStatusComponent,
        CandidateSectorComponent,
        CandidateWorkEnvironmentComponent,
        CandidateInfoViewComponent,
        CandidateAvailabilityStatusComponent,
        WorkExperienceViewComponent,
        CandidateResumeViewComponent,
        SkillsViewComponent,
        CoursesAndCertificationsViewComponent,
        AdditionalContactsViewComponent,
        EducationViewComponent,
        CandidateLocationComponent,
        CandidateAvailabilityCompComponent,
        CandidateEducationComponent,
        CandidateCertificationsComponent,
        CandidateSkillsComponent,
        CandidateLinkContactsComponent,
        WorkSetupViewComponent,
        ProfileEditComponent,
        AdminTenantPermissionsComponent,
        ReportsComponent,
        OpportunityApplicationsComponent,
        CreatePostingDialogComponent,
        CommonConfirmPopupComponent,
        PostinglistSearchPipe,
        ReportListComponent,
        ReportDetailsComponent,
        EmployerPostingDetailComponent,
        CandidateEngagedCardComponent,
        CandidateDetailComponent,
        InviteToOpportunitiesComponent,
        ProfileViewDialogComponent,
        ShortlistComponent,
        ShortListCardComponent,
        CreateShortlistDialogComponent,
        ShortlistSearchPipe,
        ShortlistDetailComponent,
        CandidateToShortlistComponent,
        ShortlistCandidatesComponent,
        StudentSmallCardComponent,
        CandidatePostingDetailComponent,
        AdminEmailTemplateComponent,
        EmailTemplateEditComponent,
        PostingShortlistNotexistsComponent,
        CompanyLogoComponent,
        SharePostingDialogComponent,
        CandidateEmailVerificationComponent,
        VerifyEmailComponent,
        CandidateDiversityQuestionsComponent,
        SaveFilterDialogComponent,
        LogoutConfirmComponent,
        ErrorMessageComponent,
        UserRegistrationErrorComponent,
        ConfirmMessageComponent,
        NotificationListComponent,
        AdminMarketingEmailComponent,
        MarketingDialog,
        SystemApisComponent,
        SystemApiInfoComponent,
        CompanyAdminListCardComponent,
        CompanyAdminAboutComponent,
        CompanyAdminComponent,
        CompanyUserGroupsComponent,
        CreateUserGroupComponent,
        ViewGroupRightsComponent,
        UserGroupPermissionsComponentComponent,
        AccountUsageComponent,
        MetricDetailComponent,
        InviteNewUserComponent,
        CompanyAdminMyUsersComponent,
        ImportBulkOpportunitiesComponent,
        AdminContentReportingComponent,
        ContentReportingComponent,
        EmptyRouteComponent,
        PublicPostingsComponent, PublicPostingDetailsComponent, PostingMatchCandidateComponent, SkillTreeControlComponent, CandidateDiversityEditComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MaterialModule,
        MatBadgeModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        MatAutocompleteModule,
        MatSortModule,
        MatTableModule,
        TagInputModule,
        ImageCropperModule,
        NgxMaskModule.forRoot(maskConfig),
        CustomFormsModule,
        NgwWowModule,
        FlexLayoutModule,
        DataTablesModule,
        NgxChartsModule,
        MatGridListModule,
        NgxChartsModule,
        MatTreeModule,
        HammerModule,
        OverlayModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    ['clean']
                ]
            }
        })
    ],
    providers: [
        TalentApiService,
        LoginService,
        RegisterService,
        AuthGuardService,
        EmployerRegisterService,
        DashboardService,
        ProfileService,
        UserInfoService,
        EmployerStudentService,
        TimesheetService,
        PostingService,
        AdminApiService,
        DialogService,
        ThemeService,
        TenantService,
        CsvExportService,
        OpportunityService,
        ShortlistService,
        CompanyInfoService,
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        CanDeactivateGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] } // to override the scroll strategy of autocomplete to close the overlay on scroll
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
