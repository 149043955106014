<mat-card fxLayout="row wrap" fxFlexAlign="start" fxLayoutAlign="center start"
     [ngClass.lg]="'shortlist-container-lg'"
     [ngClass.xl]="'shortlist-container-xl'"
     [ngClass.md]="'shortlist-container-md'"
     [ngClass.lt-md]="'shortlist-container-lt-md'"
     class="shortlist-container bg-white" 
     fxShow.sm="{{!showDetail}}"
     fxShow.xs="{{!showDetail}}" fxFlex="100">
  <div class="head-box" fxLayout="row wrap">
    <div fxLayout="row wrap"
              fxLayoutAlign="space-between center"
              class="search-box"
              fxLayoutGap.gt-md="10px"
              fxFlex="100">
      <mat-form-field class="margin-field"
                      color="accent"
                      appearance="outline"
                      fxFlex.gt-md="100%"
                      fxFlex.md="100%"
                      fxFlex.sm="100"
                      fxFlex.xs="100">
        <input matInput aria-label="search" [(ngModel)]="searchText" (ngModelChange)="modelChangeSearch($event)" name="searchText" #searchPosting="ngModel" placeholder="Search by Name or Skills">
        <button mat-button type="button" *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
          <mat-icon>cancel</mat-icon>
        </button>
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>

    </div>
    <mat-card fxLayout="row wrap" fxLayoutAlign="end center" class="no-brdr top-padding-0" [ngClass]="{'top-padding-0' : screenBreakPoint}" fxFlex="100" fxLayoutGap="10px">

        <button mat-raised-button color="primary" fxFlex="nogrow"
        fxFlex.gt-md="48%" fxFlex.md="48%" fxFlex.sm="48%"
        fxFlex.xs="48%" *ngIf="(userType==3 || userType==4) && this.hasCreateShortListPermission" (click)="openShortlistModal('employer')">NEW SHORTLIST</button>
        <button fxFlex="{{sortButtonSize}}" name="btnSort" fxFlexAlign="end" mat-stroked-button [matMenuTriggerFor]="menu" color="primary">
          <i class="fa fa-1x fa-sort"></i> {{ sortShowText}}
        </button>
        <mat-menu #menu="matMenu" width="2">
          <button mat-menu-item name="btnSortByName" (click)="setSort('name')">Name </button>
          <button mat-menu-item name="btnSortByDate" (click)="setSort('created_ts')">Date</button>
        </mat-menu>
    </mat-card>
  </div>

  <div class="shortlist_list_scroll top-padding-10"
       [ngClass.xl]="'shortlist_list_scroll-xl'"
       [ngClass.lg]="'shortlist_list_scroll-lg'"
       [ngClass.md]="'shortlist_list_scroll-md'"
       [ngClass.xs]="'shortlist_list_scroll-xs'"
       fxLayout="column" fxFlex="100">

    <div  *ngIf="((shortlistList | shortlistSearch:searchText).length === 0)"
          fxLayoutAlign.xs="center center">
      <h2 fxLayoutAlign="center center">No Shortlist Match Criteria!</h2>
    </div>

    <mat-nav-list fxFill>
      <mat-list-item [routerLink]="['/shortlist', n.sid]"
                     [ngClass]="{'active':(n.sid==selectedId) }"
                     (click)="toggleDetail()"
                     class="card-caption fit-to-content"
                     *ngFor="let n of ActiveShortList | shortlistSearch:searchText | pipelineFilter:sortBy:reverseSort; let i=index ">

        <div fxLayout="row" fxFill fxLayoutAlign="space-around center">
          <div fxFlex="20">
            <app-company-logo fxFlex *ngIf="n.fk_display_image == null"
                              class="shortlist-object"
                              [name]="n.company_name"
                              [type]="'company'"></app-company-logo>
            <app-company-logo fxFlex *ngIf="n.fk_display_image != null"
                              class="shortlist-object"
                              [imageURL]="n.image"></app-company-logo>
          </div>
          <div fxFlex="70">
            <div mat-line class="mat-subheading-1"><h3>{{n.name}}</h3>
              <strong *ngIf="n.totalCandidate == 1"><mat-card-subtitle>{{n.totalCandidate}} Candidate</mat-card-subtitle></strong>
              <strong *ngIf="n.totalCandidate > 1"><mat-card-subtitle>{{n.totalCandidate}} Candidates</mat-card-subtitle></strong>
              <strong *ngIf="n.totalCandidate == 0"><mat-card-subtitle>No candidates</mat-card-subtitle></strong>
            </div>
            <div mat-line>
              <p  fxLayout.xs="column" fxLayout="row" fxLayoutAlign="space-between">
                <span fxFlex class="mat-caption" [ngClass]="{'opportunity-posted-by-tag':screenBreakPoint}">
                  POSTED ON : {{n.created_ts.toString() | date:'MM/dd/yyyy'}}
                </span>
              </p>
            </div>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      </mat-list-item>
    </mat-nav-list>
  </div>
</mat-card>

