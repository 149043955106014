import { Component, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { CandidateEducation } from '../../../../Models/CandidateProfile';
import { RegisterService } from '../../../../Services/register.service';
import { TalentApiService } from '../../../../Services/talent-api.service';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { BreakpointObserver } from '@angular/cdk/layout';


export const MMYYYY_Format = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-candidate-education',
  templateUrl: './candidate-education.component.html',
  styleUrls: ['./candidate-education.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MMYYYY_Format },
  ],
})
export class CandidateEducationComponent implements OnInit {
  @Input() education: CandidateEducation;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
    
  degreeTypes = [];
  schools = [];
  degrees = [];
  public isAfterDegreeType = false;
  public isNextToDegreeClicked = false;
  public maxDate = new Date();
  public minDate = new Date();
	public breakpoint:number; // used to classify the screen size/display width
  

  constructor(private fb: UntypedFormBuilder,
    private _registerService: RegisterService,
    private _talentAPIService: TalentApiService,
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { }

    public form = this.fb.group({
      highestDegree: [null, [Validators.required]],
      candidateDegrees: this.fb.group({
        tradeSchool: this.fb.array([]),
        associatesDegree: this.fb.array([]),
        bachelorsDegree: this.fb.array([]),
        mastersDegree: this.fb.array([]),
        doctorate: this.fb.array([])
       }),
    });

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    for(let edu_associatesDegree of this.education.candidateDegrees.associatesDegree) {
      edu_associatesDegree.startDate = new Date(edu_associatesDegree.startDate);
      edu_associatesDegree.endDate = new Date(edu_associatesDegree.endDate);
      this.t_associatesDegree.push(this.fb.group({
        school: [edu_associatesDegree.school, [Validators.required, Validators.maxLength(255)]],
        course: [edu_associatesDegree.course, [Validators.required, Validators.maxLength(255)]],
        degreeType: [edu_associatesDegree.degreeType, [Validators.required]],
        startDate: [edu_associatesDegree.startDate, [Validators.required]],
        endDate: [edu_associatesDegree.endDate, [Validators.required]]
      }));
      
    }

    for(let edu_tradeSchool of this.education.candidateDegrees.tradeSchool) {
      edu_tradeSchool.startDate = new Date(edu_tradeSchool.startDate);
      edu_tradeSchool.endDate = new Date(edu_tradeSchool.endDate);
      this.t_tradeSchool.push(this.fb.group({
        school: [edu_tradeSchool.school, [Validators.required, Validators.maxLength(255)]],
        course: [edu_tradeSchool.course, [Validators.required, Validators.maxLength(255)]],
        degreeType: [edu_tradeSchool.degreeType, [Validators.required]],
        startDate: [edu_tradeSchool.startDate, [Validators.required]],
        endDate: [edu_tradeSchool.endDate, [Validators.required]]
      }));
      
    }

    
    this.setMaxDateforCal();
    for(let edu_bachelorsDegree of this.education.candidateDegrees.bachelorsDegree) {
      edu_bachelorsDegree.startDate = new Date(edu_bachelorsDegree.startDate);
      edu_bachelorsDegree.endDate = new Date(edu_bachelorsDegree.endDate);
      this.t_bachelorsDegree.push(this.fb.group({
        school: [edu_bachelorsDegree.school, [Validators.required, Validators.maxLength(255)]],
        course: [edu_bachelorsDegree.course, [Validators.required, Validators.maxLength(255)]],
        degreeType: [edu_bachelorsDegree.degreeType, [Validators.required]],
        startDate: [edu_bachelorsDegree.startDate, [Validators.required]],
        endDate: [edu_bachelorsDegree.endDate, [Validators.required]]
      }));
    }

    for(let edu_mastersDegree of this.education.candidateDegrees.mastersDegree) {
      edu_mastersDegree.startDate = new Date(edu_mastersDegree.startDate);
      edu_mastersDegree.endDate = new Date(edu_mastersDegree.endDate);
      this.t_mastersDegree.push(this.fb.group({
        school: [edu_mastersDegree.school, [Validators.required, Validators.maxLength(255)]],
        course: [edu_mastersDegree.course, [Validators.required, Validators.maxLength(255)]],
        degreeType: [edu_mastersDegree.degreeType, [Validators.required]],
        startDate: [edu_mastersDegree.startDate, [Validators.required]],
        endDate: [edu_mastersDegree.endDate, [Validators.required]]
      }));
    }

    for(let edu_doctorate of this.education.candidateDegrees.doctorate) {
      edu_doctorate.startDate = new Date(edu_doctorate.startDate);
      edu_doctorate.endDate = new Date(edu_doctorate.endDate);
      this.t_doctorate.push(this.fb.group({
        school: [edu_doctorate.school, [Validators.required, Validators.maxLength(255)]],
        course: [edu_doctorate.course, [Validators.required, Validators.maxLength(255)]],
        degreeType: [edu_doctorate.degreeType, [Validators.required]],
        startDate: [edu_doctorate.startDate, [Validators.required]],
        endDate: [edu_doctorate.endDate, [Validators.required]]
      }));
    }

    
    this.form.patchValue(this.education);
    this.form.value;
    this.getDegreeTypes();
    this.getSchools();
    //this.getDegrees();
  }

  get f() { return this.form.controls; }
  get t_tradeSchool() { return this.f.candidateDegrees['controls'].tradeSchool as UntypedFormArray; }
  get t_associatesDegree() { return this.f.candidateDegrees['controls'].associatesDegree as UntypedFormArray; }
  get t_bachelorsDegree() { return this.f.candidateDegrees['controls'].bachelorsDegree as UntypedFormArray; }
  get t_mastersDegree() { return this.f.candidateDegrees['controls'].mastersDegree as UntypedFormArray; }
  get t_doctorate() { return this.f.candidateDegrees['controls'].doctorate as UntypedFormArray; }

  setMaxDateforCal() {
    this.maxDate = new Date();
    this.maxDate.setHours(23);
    this.maxDate.setMinutes(59);
    this.maxDate.setSeconds(59);
  }
  tradechosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, index, value) {
    var ctrlValue = _moment();
    if (value == 'startDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.tradeSchool[index].startDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    if (value == 'endDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.tradeSchool[index].endDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    const controlArray = <UntypedFormArray>this.form.get('candidateDegrees').get('tradeSchool')
    controlArray.controls[index].get(value).setValue(ctrlValue);
    datepicker.close();
  }
  associatechosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, index, value) {
    var ctrlValue = _moment();
    if (value == 'startDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.associatesDegree[index].startDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    if (value == 'endDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.associatesDegree[index].endDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    const controlArray = <UntypedFormArray>this.form.get('candidateDegrees').get('associatesDegree')
    controlArray.controls[index].get(value).setValue(ctrlValue);
    datepicker.close();
  }
  bachelorchosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, index, value) {
    var ctrlValue = _moment();
    if (value == 'startDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.bachelorsDegree[index].startDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    if (value == 'endDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.bachelorsDegree[index].endDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    const controlArray = <UntypedFormArray>this.form.get('candidateDegrees').get('bachelorsDegree');
    controlArray.controls[index].get(value).setValue(ctrlValue);
    datepicker.close();
  }
  masterschosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, index, value) {
    var ctrlValue = _moment();
    if (value == 'startDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.mastersDegree[index].startDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    if (value == 'endDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.mastersDegree[index].endDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    const controlArray = <UntypedFormArray>this.form.get('candidateDegrees').get('mastersDegree');
    controlArray.controls[index].get(value).setValue(ctrlValue);
    datepicker.close();
  }
  doctorchosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, index, value) {
    var ctrlValue = _moment();
    if (value == 'startDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.doctorate[index].startDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    if (value == 'endDate') {
      ctrlValue = _moment(this.form.value.candidateDegrees.doctorate[index].endDate);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    const controlArray = <UntypedFormArray>this.form.get('candidateDegrees').get('doctorate');
    controlArray.controls[index].get(value).setValue(ctrlValue);
    datepicker.close();
  }

  getDegreeTypes() {
    this._registerService.getDegreeTypes().subscribe(
      (result) => {
        if(result) {
          this.degreeTypes = result;
        }
      },
      (error) => {}
    );
  }

  getSchools() {
    this._registerService.getSchoolList().subscribe(
      (result) => {
        if(result) {
          this.schools = result;
        }
      },
      (error) => {}
    );
  }

  // getDegrees(school) {
  //   this._registerService.getDegrees(school).subscribe(
  //     (result) => {
  //       if(result) {
  //         this.degreeTypes = result;
  //       }
  //     },
  //     (error) => {}
  //   );
  // }

  nextFromDegree() {
    this.isNextToDegreeClicked = true;
    const formObj = this.form.value;
    const highestDegree = formObj.highestDegree;
    if (highestDegree) {
      switch (highestDegree.id) {
        case 1:
        case "1":
          this.removeDegreeOfTypes([3, 4, 5, 6, 7]);
          this.next();
          break;
        case 2:
        case "2":
          this.removeDegreeOfTypes([3, 4, 5, 6, 7]);
          this.next();
          break;
        case 3:
        case "3":
          this.removeDegreeOfTypes([4,5,6,7]);
          this.checkTradeSchool(this.form.value);
          this.isAfterDegreeType = true;
          break;
        case 4:
        case "4":
          //this.t.clear();
          this.removeDegreeOfTypes([3,5,6,7]);
          this.checkAssociatesDegree(this.form.value);
          this.isAfterDegreeType = true;
          break;
        case 5:
        case "5":
          //this.t.clear();
          this.removeDegreeOfTypes([3,4,6,7]);
          this.checkBachelorsDegree(this.form.value);
          this.isAfterDegreeType = true;
          break;
        case 6:
        case "6":
          //this.t.clear();
          this.removeDegreeOfTypes([3,4,7]);
          this.checkBachelorsDegree(this.form.value);
          this.checkMastersDegree(this.form.value);
          this.isAfterDegreeType = true;
          break;
        case 7:
        case "7":
          //this.t.clear();
          this.removeDegreeOfTypes([3,4]);
          this.checkBachelorsDegree(this.form.value);
          this.checkMastersDegree(this.form.value);
          this.checkDoctorateDegree(this.form.value);
          this.isAfterDegreeType = true;
          break;
        default:
          this.next();
          break;
      }
      console.log(this.form.value);
    }
    
  }

  removeDegree(degreeTypeId,index) {
    if (index !== -1) {
      switch (degreeTypeId) {
        case 3:
          case "3":
            this.t_tradeSchool.removeAt(index);
          break;
        case 4:
          case "4":
            this.t_associatesDegree.removeAt(index);
          break;
          case 5:
            case "5":
              this.t_bachelorsDegree.removeAt(index);
            break;
            case 6:
          case "6":
            this.t_mastersDegree.removeAt(index);
          break;
          case 7:
          case "7":
            this.t_doctorate.removeAt(index);
          break;
        default:
          break;
      }
    }
  }

  hasDeegreesOf(id) {
    switch (id) {
      case 3:
        case "3":
          let tradeSchool = this.form.value.candidateDegrees.tradeSchool;
          if (tradeSchool.length > 0) {
            return true;
          } else {
            return false;
          }
        break;
      case 4:
        case "4":
          let associatesDegree = this.form.value.candidateDegrees.associatesDegree;
          if (associatesDegree.length > 0) {
            return true;
          } else {
            return false;
          }
        break;
        case 5:
          case "5":
            let bachelorsDegree = this.form.value.candidateDegrees.bachelorsDegree;
            if (bachelorsDegree.length > 0) {
              return true;
            } else {
              return false;
            }
          break;
          case 6:
          case "6":
            let mastersDegree = this.form.value.candidateDegrees.mastersDegree;
            if (mastersDegree.length > 0) {
              return true;
            } else {
              return false;
            }
          break;
          case 7:
          case "7":
            let doctorate = this.form.value.candidateDegrees.doctorate;
            if (doctorate.length > 0) {
              return true;
            } else {
              return false;
            }
          break;
      default:
        break;
    }
  }

  pushDegreeObj(degreeTypeId,degreeControlsArray) {
    let index = this.degreeTypes.findIndex(element => element.id == degreeTypeId);
    degreeControlsArray.push(this.fb.group({
      school: ['', [Validators.required, Validators.maxLength(255)]],
      course: ['', [Validators.required, Validators.maxLength(255)]],
      degreeType: [{id: degreeTypeId, name: this.degreeTypes[index].name}, [Validators.required]],
      startDate: [_moment(), [Validators.required]],
      endDate: [_moment(), [Validators.required]]
    }));
  }

  addDegreeOfType(degreeTypeId) {
    switch (degreeTypeId) {
      case 3:
        case "3":
          this.pushDegreeObj(3,this.t_tradeSchool);
        break;
      case 4:
        case "4":
          this.pushDegreeObj(4,this.t_associatesDegree);
        break;
        case 5:
          case "5":
            this.pushDegreeObj(5,this.t_bachelorsDegree);
          break;
          case 6:
        case "6":
          this.pushDegreeObj(6,this.t_mastersDegree);
        break;
        case 7:
        case "7":
          this.pushDegreeObj(7,this.t_doctorate);
        break;
      default:
        break;
    }
    
    //this.form.updateValueAndValidity();
    //this.form.patchValue(this.education);
  }

  removeDegreeOfTypes(degreeTypeIds) {
    for(let degTypeId of degreeTypeIds) {
      switch (degTypeId) {
        case 3:
          case "3":
            this.t_tradeSchool.clear();
          break;
        case 4:
          case "4":
            this.t_associatesDegree.clear();
          break;
          case 5:
            case "5":
              this.t_bachelorsDegree.clear();
            break;
            case 6:
          case "6":
            this.t_mastersDegree.clear();
          break;
          case 7:
          case "7":
            this.t_doctorate.clear();
          break;
        default:
          break;
      }
     }

  }

  // add trade school to form array if not exists any
  checkTradeSchool(formObj) {
    let degree = formObj.candidateDegrees.tradeSchool;
    if (degree.length == 0) {
      this.addDegreeOfType(3);
    }
  }

  // add associates degree to form array if not exists any
  checkAssociatesDegree(formObj) {
    let degree = formObj.candidateDegrees.associatesDegree;
    if (degree.length == 0) {
      this.addDegreeOfType(4);
    }
  }

  checkBachelorsDegree(formObj) {
    let degree = formObj.candidateDegrees.bachelorsDegree;
    if (degree.length == 0) {
      this.addDegreeOfType(5);
    }
  }

  checkMastersDegree(formObj) {
    let degree = formObj.candidateDegrees.mastersDegree;
    if (degree.length == 0) {
      this.addDegreeOfType(6);
    }
  }

  checkDoctorateDegree(formObj) {
    let degree = formObj.candidateDegrees.doctorate;
    if (degree.length == 0) {
      this.addDegreeOfType(7);
    }
  }

  prevToDegree() {
    this.isAfterDegreeType = false;
    console.log(this.form.value);
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  next() {
    if(this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return false;
    }
    const candidateProfile = {
      education: this.form.value
    }

    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
          this.saveProfile.emit(this.form.value);
          if(this.edit === true) {
            this.dialogRef.close(this.form.value);
          }
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }

  skip() {
    this.saveProfile.emit(this.education);
  }

  prev() {
    this.prevEvent.emit();
  }

  querySearch(query, sourcelist) {
		if (typeof query === 'string') {
			if (query != undefined && query != '' && query != null) {
				let results: any;
				results = query ? sourcelist.filter(this.createFilterFor(query)) : [];
				return results;
			}
		}
		else {
			if (query != undefined && query.value != '' && query.value != null) {
        console.log('non string');
				let array = $.map(sourcelist, function (value, index) {
					return [value];
				});
				let results: any;
				results = query.value ? array.filter(this.createFilterFor(query.value)) : [];
				return results;
			}
		}
	}

	createFilterFor(query) {
		var lowercaseQuery = query.toLowerCase();
		return function filterFn(item) {
			return (item.name.toLowerCase().indexOf(lowercaseQuery) != -1);
		};
	}
}
