import { Component, Input, OnInit, Output,Inject, EventEmitter, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CandidateInfo } from '../../../../Models/CandidateProfile';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { TenantService } from '../../../../Services/tenant.service';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { UserInfoService } from '../../../../Services/user-info.service';

@Component({
  selector: 'app-candidate-info',
  templateUrl: './candidate-info.component.html',
  styleUrls: ['./candidate-info.component.css']
})
export class CandidateInfoComponent implements OnInit {

  @Input() candidateInfo: CandidateInfo;
  @Input() edit: boolean = false;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  candidateProfile = {
    candidateInfo: null
  }
  profilePicImage: any;

  namePattern = "[a-zA-Z ]*";
  public form = this.fb.group({
    firstName: ['', [Validators.required, Validators.pattern(this.namePattern), Validators.maxLength(255)]],
    lastName: ['', [Validators.required, Validators.pattern(this.namePattern), Validators.maxLength(255)]],
    phone: [''],
    profilePic: ['']
  });
  public imageChangedEvent: any = '';
  public imageError: boolean = false;
  tenant: String;

  constructor(private fb: UntypedFormBuilder, 
    private _talentAPIService: TalentApiService,
    private _tenantService: TenantService,
    private _userInfoService: UserInfoService,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { 
      this.tenant = this._tenantService.tenant;
  }

  ngOnInit(): void {
    this.form.patchValue(this.candidateInfo);
    if(!this.edit){
			if(this._talentAPIService.isResumeUpload) {
        this.getBasicInfoFromResume();
      }
    }
  }

  fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
		let fileExtension = this.imageChangedEvent.target.value.replace(/^.*\./, '');
		switch (fileExtension) {
			case 'png': case 'jpeg': case 'jpg':
				this.imageError = false;
				break;
      default:
        this.imageError = true;
        this.form.get('profilePic').setValue(null);
				break;
      }
	}
	imageCropped(event: any) {
		//this.croppedImage = image;
    this.form.get('profilePic').setValue(event.base64);
	}
	imageLoaded(event) {
		//this.user.photoUpload = event;
		// show cropper
	}
	loadImageFailed() {
		// show message
  }

  removeProfilePic() {
    this.form.get('profilePic').setValue(null);
    //this.imageChangedEvent.target.value = null;
    //this.profilePicImage = null;
  }
  
  next() {
    const candidateInfoObject = this.form.value;
    const candidateProfile = {
      candidateInfo: candidateInfoObject
    }
    this._userInfoService.setName(candidateProfile.candidateInfo.firstName+' '+candidateProfile.candidateInfo.lastName);
    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
        if (result) {
          this.saveProfile.emit(candidateInfoObject);
          if(this.edit === true) {
            this.dialogRef.close(candidateInfoObject);
          }
        }
        else {

        }
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }

  prev() {
		this.prevEvent.emit();
	}

  getBasicInfoFromResume() {
    this._talentAPIService.getBasicInfoFromResume()
		.subscribe(
		(result:any) => {
        var phoneValidator = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/; 
        var firstName = result.name.split(' ').slice(0, 1).join(' ');
        var lastName = result.name.split(' ').slice(-1).join(' ');
        if(firstName.match(this.namePattern)) {
          this.form.get('firstName').setValue(firstName);
        }
        if(lastName.match(this.namePattern)) {
          this.form.get('lastName').setValue(lastName);
        }
        if(phoneValidator.test(result.phone)){
          this.form.get('phone').setValue(result.phone);
        }
		},
		error => {
			this._talentAPIService.snackbarMessage('Something went wrong!');
		});
  }
}
