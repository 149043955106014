import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'underscore';
import { TalentApiService } from '../../../Services/talent-api.service';
import { CommonConfirmPopupComponent } from '../common-confirm-popup/common-confirm-popup.component';

@Component({
  selector: 'app-save-filter-dialog',
  templateUrl: './save-filter-dialog.component.html',
  styleUrls: ['./save-filter-dialog.component.css']
})
export class SaveFilterDialogComponent implements OnInit {
  isMobileDevice: any;
  salary:any = 0;
  location:any;
  education:any;
  commitment:any;
  title:any;
  workSetup:any;
  matchBucket:any;
  o_orgLocationlocation:any;
  educationLevelData:any;
  commitmentData:any;
  workSetupData:any;
  matchBucketData:any;
  name = new UntypedFormControl('', [Validators.required]);
  isEdit:boolean = false;
  searchText;

  searchType: string;

  candidateSearchParams = {
    text: "",
    educationLevel: [],
    availability: [],
    experience: 0,
    skills: [],
    location: []
  };

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  public breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<SaveFilterDialogComponent>,
    private _talentAPIService: TalentApiService,
    public _dialog: MatDialog ) {

      if (this.data != null) {
        console.log(data);
        if(data.id && data.id !='') {
          this.isEdit = true;
          var dataObject = JSON.parse(data.search_parameters);
          this.name.setValue(data.name);
        } else {
          var dataObject = JSON.parse(JSON.stringify(data.search_parameters));
        }
        this.searchType = data.search_type;

        switch(this.searchType) {
          case 'candidate':
            this.setCandidateFilterData(dataObject);
            break;
          case 'posting':
            this.setPostingFilterData(dataObject);
            break;
          case 'shortlist':
            this.setShortlistFilterData(dataObject);
            break;
          default:
            break;
        }
        
      }

    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileDevice = true;
        } else {
          this.isMobileDevice = false;
        }
      });
   }

  ngOnInit(): void {
  }

  setCandidateFilterData(dataObject){
    this.candidateSearchParams = {
        text: dataObject.text,
        educationLevel: dataObject.educationLevel,
        availability: dataObject.availability,
        experience: dataObject.experience,
        skills: dataObject.skills,
        location:dataObject.location
      };

      this.education = dataObject.educationLevel;
      this.commitment = dataObject.availability;

      const isAnyCommitmentData = _.where(dataObject.availability, { type: "Any", checked: true });
      if (isAnyCommitmentData.length > 0) {
        this.commitmentData = _.pluck(dataObject.availability, 'availability_type');
      } else {
        this.commitmentData = _.where(dataObject.availability, { checked: true });
        this.commitmentData = _.pluck(this.commitmentData, 'availability_type');
      }

      const isAnyEducationData = _.where(dataObject.educationLevel, { type: "Any", checked: true });
      if (isAnyEducationData.length > 0) {
        this.educationLevelData = _.pluck(dataObject.educationLevel, 'name');
      } else {
        this.educationLevelData = _.where(dataObject.educationLevel, { checked: true });
        this.educationLevelData = _.pluck(this.educationLevelData, 'name');
      }
  }

  setPostingFilterData(dataObject) {
    this.salary = dataObject.o_range_salary[0];
    this.location = dataObject.o_orgLocationlocation;
    this.o_orgLocationlocation = dataObject.o_location;
    this.education = dataObject.o_education;
    this.commitment = dataObject.o_commitment;
    this.title = dataObject.o_title;
    this.workSetup = dataObject.o_work_setup;
    this.matchBucket = dataObject.o_match_bucket;
    if(dataObject.searchText) {
      this.searchText = dataObject.searchText;
    }

    const isAnyWorkSetup = _.where(dataObject.o_work_setup.value, { name: "Any", completed: true });
    if (isAnyWorkSetup.length > 0) {
      this.workSetupData = _.pluck(dataObject.o_work_setup.value, 'name');
    } else {
      this.workSetupData = _.where(dataObject.o_work_setup.value, { completed: true });
      this.workSetupData = _.pluck(this.workSetupData, 'name');
    }
    const isAnyMatchBucket = _.where(dataObject.o_match_bucket.value, { name: "Any", completed: true });
    if (isAnyMatchBucket.length > 0) {
      this.matchBucketData = _.pluck(dataObject.o_match_bucket.value, 'name');
    } else {
      this.matchBucketData = _.where(dataObject.o_match_bucket.value, { completed: true });
      this.matchBucketData = _.pluck(this.matchBucketData, 'name');
    }
    const isAnyCommitmentData = _.where(dataObject.o_commitment.value, { type: "Any", completed: true });
    if (isAnyCommitmentData.length > 0) {
      this.commitmentData = _.pluck(dataObject.o_commitment.value, 'type');
    } else {
      this.commitmentData = _.where(dataObject.o_commitment.value, { completed: true });
      this.commitmentData = _.pluck(this.commitmentData, 'type');
    }
    this.educationLevelData = _.where(dataObject.o_education.value, { completed: true });
    this.educationLevelData = _.pluck(this.educationLevelData, 'name');
  }

  setShortlistFilterData(dataObject) {

  }

  close() {
    var data = {
      result : null,
      selectedData: this.getSelectedData()
    }
    this.dialogRef.close(data);
  }

  removeSkill(index) {
    this.candidateSearchParams.skills.splice(index,1);
  }

  removeCandidateSearchText() {
    this.candidateSearchParams.text = "";
  }

  removePostingSearchText() {
    this.searchText = "";
  }
  
  removeCandidateLocation(index) {
    this.candidateSearchParams.location.splice(index,1);
  }

  removeLocation(index) {
    this.location.splice(index, 1);
    this.o_orgLocationlocation.splice(index, 1);
  }

  removeSalary() {
    this.salary = 0;
  }

  removeEducation(eduData) {
    const index = this.educationLevelData.indexOf(eduData);
    this.educationLevelData.splice(index, 1);
    if(this.education.value) {
      this.education.value.filter(edu => edu.name == eduData)[0] = this.education.value.filter(edu => edu.name == eduData)[0].completed = false;
    } else {
      this.education.filter(edu => edu.name == eduData)[0] = this.education.filter(edu => edu.name == eduData)[0].checked = false;
    }
  }

  removeCommitment(commData) {
    const index = this.commitmentData.indexOf(commData);
    this.commitmentData.splice(index, 1);
    if(this.commitment.value) {
      this.commitment.value.filter(edu => edu.type == commData)[0] = this.commitment.value.filter(edu => edu.type == commData)[0].completed = false;
    } else {
      this.commitment.filter(edu => edu.availability_type == commData)[0] = this.commitment.filter(edu => edu.availability_type == commData)[0].checked = false;
    }
  }

  removeTitle(index) {
  this.title.splice(index,1);
  }

  removeWorkSetup(workData) {
    const index = this.workSetupData.indexOf(workData);
    this.workSetupData.splice(index, 1);
    this.workSetup.value.filter(edu => edu.name == workData)[0] = this.workSetup.value.filter(edu => edu.name == workData)[0].completed = false;
  }

  removeMatchBucket(matchData) {
    const index = this.matchBucketData.indexOf(matchData);
    this.matchBucketData.splice(index, 1);
    this.matchBucket.value.filter(edu => edu.name == matchData)[0] = this.matchBucket.value.filter(edu => edu.name == matchData)[0].completed = false;
  }

  validateForm() {
    switch(this.searchType) {
      case 'candidate':
        if(!this.candidateSearchParams.text && this.candidateSearchParams.skills.length == 0 && 
          this.education.filter(edu => edu.checked === true).length == 0 &&
          this.commitment.filter(el => el.checked === true).length == 0 &&
          this.candidateSearchParams.location.length == 0 &&
          !this.candidateSearchParams.experience)
        {
          this._talentAPIService.snackbarMessage("Nothing to save. Please cancel!");
          return false;
        }
        break;
      case 'posting':
        break;
      case 'shortlist':
        break;
      default:
        break;
      }
      return true;
  }

  getSelectedData() {
    let popupdata;
    switch(this.searchType) {
      case 'candidate':
        popupdata = {
          text: this.candidateSearchParams.text,
          skills: this.candidateSearchParams.skills,
          educationLevel: this.education,
          availability: this.commitment,
          experience: this.candidateSearchParams.experience,
          location: this.candidateSearchParams.location
        };
        break;
      case 'posting':
        popupdata = {
          o_location: this.o_orgLocationlocation,
          o_orgLocationlocation: this.location,
          o_title: this.title,
          o_work_setup: this.workSetup,
          o_commitment: this.commitment,
          o_education: this.education,
          o_range_salary: [this.salary],
          o_match_bucket: this.matchBucket,
          searchText: this.searchText
        };
        break;
      case 'shortlist':
        break;
      default:
        break;
    }
    return popupdata;
  }

  save() {
    let popupdata = {};
    if(this.name.valid && this.validateForm()) {
      
      popupdata = this.getSelectedData();
  
      var data = {
        search_type : this.searchType,
        name : this.name.value,
        search_parameters: JSON.stringify(popupdata)
      }
  
      if(this.isEdit) {
        data['id'] = this.data.id
      }
        this._talentAPIService.saveSearch(data).subscribe(result => {
          if (result) {
            var data = {
              selectedData: popupdata,
              result:result
            }
            this.dialogRef.close(data);
          }
        }, error => {
          this._talentAPIService.snackbarMessage("Something went wrong!");
        });
    }
    

  }

  delete() {
    let dialog;
		if (!dialog == null) {
		} else {
			dialog = this._dialog.open(CommonConfirmPopupComponent, {
				height: '175',
        width: this.isMobileDevice ? '88%' : '36.66%',
				disableClose: false,
			});
			dialog.componentInstance.post_type = 'Posting';
			dialog.componentInstance.confirm_message = 'Are you sure you want to delete this search criteria?';
			dialog.componentInstance.discardConfirm = true;
		}
    dialog.afterClosed()
			.subscribe((result) => {
				if (dialog.componentInstance.confirmDiscardPost) {	
          var data = {
            id : this.data.id,
          }				
					this._talentAPIService.deleteSearch(data).subscribe(result => {
            if (result) {
              var data = {
                selectedData: null,
                result:result
              }
              this.dialogRef.close(data);
            }
          })
				}
			});
  }
}
