<div fxLayout="column" FxlayoutAlign="space-between center">
  <div fxLayout="row" fxFlex="100">
    <mat-card fxFlex="100">
      <mat-card-title>Import Bulk Postings</mat-card-title>
      <mat-card-content>
        <p><em>Bulk Import posting managed here. </em></p>
        <div class="instructions_cl">
          <div class="pull-right addbutton">
          </div>
          <strong>Instructions:</strong>
          <ol>
            <li>Please upload csv file only.</li>
            <li>
              <a href="/assets/img/BulkOpportunityFormat.csv" target="_blank">
                Click Here 
              </a> to download template to get valid format of csv.
            </li>
            <li>All the postings will be imported as "DRAFT" status.</li>
          </ol>
          <div class="pull-right addbutton">
          </div>
          <strong>Formatting rules:</strong>
          <ol>
            <li>StartDate, ExpirationDate in mm/dd/yyyy format.</li>
            <li>Locations:</li>
            <ul>
              <li>Multiple locations should be separated by ‘|’. E.g. New York, NY, USA| Albany, NY, USA.</li>
            </ul>
            <li>JobDetails:</li>
            <ul>
              <li>Permitted values: <span *ngFor="let opp of opportunityTypes; let last=last"> '{{opp.type}}'<span *ngIf="!last">,</span></span>.</li>
              <li>Multiple value should be separated by ‘|’. E.g. <span *ngFor="let opp of opportunityTypes | slice:0:1; let i = index;">'{{opportunityTypes[i].type}}|{{opportunityTypes[i+1].type}}'</span>.</li>
            </ul>
            <li>WorkSetup:</li>
            <ul>
              <li>Permitted values: <span *ngFor="let work of workSetup; let last=last"> '{{work.name}}'<span *ngIf="!last">,</span></span>.</li>
              <li>Multiple value should be separated by ‘|’. E.g. <span *ngFor="let work of workSetup | slice:0:1; let i = index;">'{{workSetup[i].name}}|{{workSetup[i+1].name}}'</span>.</li>
            </ul>
            <li>RequiredSkills:</li>
             <ul>
               <li>Permitted values: Format: SkillName:Years E.g. <span *ngFor="let skill of skillsList | slice:0:1">{{skill.name}}:2</span>.</li>
               <li>Multiple value should be separated by ‘|’. E.g. <span *ngFor="let skill of skillsList | slice:0:1; let i = index;">'{{skillsList[i].name}}:1|{{skillsList[i+1].name}}:2'</span>.</li>
             </ul>
             <li>Education: </li>
             <ul>
               <li>Permitted values: <span *ngFor="let degree of degreeTypes; let last=last"> '{{degree.name}}'<span *ngIf="!last">,</span></span>.</li>
             </ul>
          </ol>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-card fxFlex="100" fxLayout="row warp">
    <form fxFlex="100" [formGroup]="bulkImportForm">
      <div fxLayout="row wrap" fxFlex="100">
        <div fxFlex="100" fxLayout="row">
          <div fxFlex="30" fxFlex.gt="50" fxFlex.lt-md="70" fxLayout="row">
            <mat-form-field appearance="fill">
              <mat-label>Select Employer</mat-label>
              <input type="text"
                     matInput
                     formControlName="empEmail"
                     required
                     (keyup)="checkEmp($event)"
                     [matAutocomplete]="autoGroup">
              <mat-autocomplete #autoGroup="matAutocomplete"
                                [displayWith]="getEmpName.bind(this)"
                                (optionSelected)="selectEmployer($event.option.value)">
                <mat-optgroup *ngFor="let emp of employerListOption | async" [label]="emp.companyName">
                  <mat-option *ngFor="let e of emp.employers" [value]="e.email">
                    {{e.name}} [{{e.email}}]
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
          </div>  
          <div fxFlex="14" fxFlex.lg="10" fxFlex.sm="20" fxFlex.lt-sm="30" fxFlex.gt-lg="8" fxLayoutAlign="end start" class="margin-right-1">
            <button mat-button mat-raised-button class="btn background-primary" [disabled]="bulkImportForm.invalid || !isValidEmp" color="primary" (click)="callback.click()">
              Select a File
              <input #callback type="file"
                     mode="data-url"
                     (click)="callback.value = null" 
                     (change)="fileChangeEvent($event)"
                     name="pic-upload"
                     id="pic-upload"
                     style="display:none;"
                     class="form-control"
                     accept=".csv" />
            </button>
          </div>         
          <p *ngIf="csvFileName!=''">{{csvFileName}}</p>
        </div>
        <div fxFlex="30" fxFlex.gt="50" fxFlex.lt-md="70" fxLayoutAlign="end start" fxLayoutGap="32px">
          <button mat-button mat-raised-button class="btn background-primary" [disabled]="csvFileName ==''" color="primary" (click)="fileUpload()">
            Upload
          </button>
          <!--<p class="help-block small animated fadeInDown mat-error ng-star-inserted" *ngIf="imageError">Only JPG, PNG or Gif images are allowed with file size of 2MB only.</p>-->
        </div>
        <div fxFlex="100" fxLayoutAlign="start start" *ngIf="isCSVInvalid" class="message-area">
          <mat-error>
            <ul class="liNumber">
              <li *ngFor="let err of validationMessage">{{err}}</li>
            </ul>
          </mat-error>
        </div>
        <div fxFlex="100" fxLayout="row wrap" *ngIf="csvSuccessMessage.length > 0" class="message-area">
            <div fxLayout="column" fxFlex="100" *ngFor="let msg of csvSuccessMessage">{{msg}}</div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
