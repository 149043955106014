import { Component, Input, OnInit, Output,Inject, EventEmitter, Optional} from '@angular/core';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { UntypedFormBuilder, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-candidate-sector',
  templateUrl: './candidate-sector.component.html',
  styleUrls: ['./candidate-sector.component.css']
})
export class CandidateSectorComponent implements OnInit {

  @Input() sectorTitle;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  public Candidatesector;
  public breakpoint:number; // used to classify the screen size/display width

  public form = this.fb.group({
    sector: ['', [Validators.required]],
    title: ['', [Validators.required, Validators.maxLength(100)]]
  });

  constructor(private fb: UntypedFormBuilder, 
    private _talentAPIService: TalentApiService,
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { 
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    this.getCandidateSector();
    this.form.patchValue(this.sectorTitle);
  }
  getCandidateSector() {
    this._talentAPIService.getCandidateSector()
			.subscribe(
			(result:any) => {
          this.Candidatesector = result;
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }
  sectorChange(event){
    this.sectorTitle.sector = event.value;
  }
  next() {
    const sectorTitleObj = this.form.value;
    const candidateProfile = {
      sectorTitle: sectorTitleObj
    }
    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
        if (result) {
          this.saveProfile.emit(sectorTitleObj);
          var data = sectorTitleObj;
          if(this.edit === true) {
            this.dialogRef.close(data);
          }
        }
        else {

        }
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }
  prev() {
		this.prevEvent.emit();
  }
  skip() {
		this.saveProfile.emit(this.sectorTitle);
	  }

}
