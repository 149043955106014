<div class="candidate-registration full-page-registration">
	<div fxLayout="row" fxLayoutAlign="center center">
		<div fxLayout="column" fxFlex="100">
			<div fxLayout="row" fxFlexOffset="30px" fxLayoutAlign="center center">
				<div fxLayout="column" fxFlex="80">
					<mat-card class="content-part reg-card">
                        <div fxLayout="row" fxFlex="grow" fxLayoutAlign="center stretch">
							<div fxLayout="column" fxFlex="100">
                                <div fxLayout="column wrap" fxLayoutAlign="center stretch">
                                    <div fxLayout="row">
                                        <div *ngIf="isChangeEmailView" fxFlex="100" fxLayoutAlign="center center">
                                            <form name="candidateChangeEmailForm" id="candidateChangeEmailForm" [formGroup]="form" autocomplete="off" novalidate>
                                                <div fxLayout="row" fxLayoutAlign="space-between">
                                                    <div fxLayout="column" fxFlex="100">
                                                        <h1>Change Email</h1>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="space-between">
                                                    <div fxLayout="column" fxFlex="100">
                                                        <div fxLayout="row wrap" fxLayoutAlign="space-between">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <mat-form-field color="accent" appearance="outline">
                                                                    <mat-label>Current Email</mat-label>
                                                                    <input matInput type="text" name="currentEmail" id="currentEmail" formControlName="currentEmail" readonly="readonly">
                                                                    <mat-error *ngIf="form.get('currentEmail').hasError('required')">
                                                                        Please enter Current Email
                                                                    </mat-error>
                                                                    <mat-error *ngIf="form.get('currentEmail').hasError('email')">
                                                                        Email is not valid
                                                                    </mat-error>
                                                                </mat-form-field>
                                                            </div>
                                                            <div fxLayout="column" fxFlex="100">
                                                                <mat-form-field color="accent" appearance="outline">
                                                                    <mat-label>New Email</mat-label>
                                                                    <input matInput type="text" name="newEmail" id="newEmail" formControlName="newEmail">
                                                                    <mat-error *ngIf="form.get('newEmail').hasError('required')">
                                                                        Please enter New Email
                                                                    </mat-error>
                                                                    <mat-error *ngIf="form.get('newEmail').hasError('email')">
                                                                        Email is not valid
                                                                    </mat-error>
                                                                </mat-form-field>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div fxLayout="row">
                                                    <button *ngIf="!isLoading" fxFlex="20" mat-raised-button color="primary" [disabled]="!form.valid" (click)="submitForm()">Submit</button>
                                                    <button *ngIf="!isLoading" fxFlex="20" fxFlexOffset="5" mat-raised-button (click)="back()">Cancel</button>
                                                    <mat-spinner *ngIf="isLoading"></mat-spinner>
                                                </div>
                                            </form>
                                        </div>
                                        <div *ngIf="!isChangeEmailView" fxFlex="100" fxLayoutAlign="center center">
                                            <form name="candidateEmailVerifyForm" id="candidateEmailVerifyForm" autocomplete="off" novalidate>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <app-logo fxLayoutAlign="center center" class="email-veryfication" [tenant]="'HJJ'" [type]="'candidate-email-verification'"></app-logo>   
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column">
                                                        <h2 fxFlexOffset="10">One Last Step! Verify Your Email Address</h2>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column" fxFlex="80" class="text-center">
                                                        <span>You have entered {{user.username}} as your email address for this account. Please verify this email address by clicking on the link below.</span>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column">
                                                        <button *ngIf="!isLoading" fxFlexOffset="20px" id="btnSendVerification" mat-raised-button color="primary" (click)="sendVerification()">
                                                            SEND VERIFICATION
                                                        </button>
                                                        <mat-spinner *ngIf="isLoading"></mat-spinner>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column">
                                                        <button fxFlexOffset="20px" mat-button color="primary" (click)="changeEmail()">
                                                            CHANGE EMAIL
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>

