import {throwError as observableThrowError,  Observable, from } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import 'rxjs';
import { UserInfoService } from './user-info.service';

@Injectable()
export class DashboardService {
	public rssFeed: any;
	public notificationsList: any;
	public newsSources: any;

	constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) {}

	getRssFeeds() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		return this._http.get("//rss2json.com/api.json", {
			params: {
				"rss_url": "http://createfeed.fivefilters.org/mergefeeds.php?url=https%3A%2F%2Fwww.recruiter.com%2Ffeed%2Fcareer.xml%0Awww.bestcollegesonline.com%2Ffeed%2F%0Awww.personalcareermanagement.com%2Fpress%2Ffeed%2F%0Awww.personalcareermanagement.com%2Fblog%2Ffeed%2F%0Ahttps%3A%2F%2Fwww.sciencemag.org%2Frss%2Fcareers-latest.xml&text_contains=job&career&Internship&interview&tips&strip_if_text=religion"
			}
		}).pipe(
			map(response => {
				{
					this.rssFeed = response['items'];
					this.rssFeed.forEach(function (item, index, rssFeed) {
						if (item.link.includes("sciencemag")) {
							item.image = item.thumbnail.substring(0, item.thumbnail.lastIndexOf("?"));
							item.text = item.description.concat(" ", ". . .");
						}
						else if (item.link.includes("recruiter")) {
							var n = item.description.lastIndexOf(">");
							item.image = item.description.substring(10, n - 35);
							item.text = item.description.substring(n + 1).concat(" ", ". . .");
						}
						else if (item.link.includes("personalcareermanagement"))
						{
							if (item.description.includes("src")) {
								item.image = item.description.substring(item.description.indexOf("src") + 5, item.description.indexOf("alt") - 2);
							} else {
								item.image = 'http://tjs2app/src/assets/imgtst/PCM.jpg';
							}

							item.text = item.description.replace(/<[^>]+>/gm, '');
							item.text = item.text.substring(0, 500).concat(" ", ". . .");
						}
						else {
							item.image = 'http://tjs2app/src/assets/imgtst/BCO.png';
							item.text = item.description.replace(/<[^>]+>/gm, '');
							item.text = item.text.substring(0, 500).concat(" ", ". . .");
						}
					});
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

  getNotifications(showAllNotification, pageNum, pageSize, RecordCount) {
    let headers = new HttpHeaders();
    let data: any;
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    data = { "accessToken": this._userInfoService.getAccessToken() };
    if (showAllNotification) {
      data['status_id_values'] = [1, 2];
      if (RecordCount === 0) {
        data['returnRecordCount'] = true;
      }
      else {
        data['returnRecordCount'] = false;
      }
      data['pageNum'] = pageNum;
      data['pageSize'] = pageSize;
    }
    else {
      data['status_id_values'] = [1];
      data['returnRecordCount'] = false;
    }

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getNotificationList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (response != 'undefined') {
            this.notificationsList = response['notificationList'];
            return response['notificationList'];
          }
          else {
            console.log('Couldnt get skills overview');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }


	updateNotificationStatus(notificationID, status){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		/*
		data = {
			TJS_USERNAME: sessionStorage.getItem("TJS_USERNAME"),
			TJS_PASSWORD: sessionStorage.getItem("TJS_PASSWORD"),
			id_status_list:
				[{
					"id": notificationID,
					"status":status
				}]
		};*/
		let data = { id_status_list:
				[{
					"id": notificationID,
					"status":status
				}] };

		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/editNotificationStatus", data, {headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['status'];
					}
					else {
						console.log('Couldnt get skills overview');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getNewsSources() {
		let headers = new HttpHeaders();
		let user = this._userInfoService.getUser();
		headers.append('Content-Type', 'application/json');
		let data = { 
			
			'user': user
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"core/getFeeds", data, {headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						this.notificationsList = response['newsSources'];
						return response['newsSources'];
					}
					else {
            console.log('Couldnt get news feeds');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
  

	getProfileMetrics() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		let user = this._userInfoService.getUser();
		let data = { 
			'user': user
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"candidate/getProfileMetrics", data, {headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['profileMetrics'];
					}
					else {
						console.log('Couldnt get skills overview');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getEmployerOverview() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		let user = this._userInfoService.getUser();
		let data = { 
			'user': user
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"employer/getEmployerOverview", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['getEmployerOverview'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getSkillsOverview(count:number) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		let user = this._userInfoService.getUser();
		let data = { 
			'user': user,
			'data': {
				'count': count
			}
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"reporting/getSkillsOverview", data, {headers: headers }).pipe(
			map(response => {
				{ 
					if(response != 'undefined') {
						return response['skillsOverview'];
					}
					else {
						console.log('Couldnt get skills overview');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getPostingsBreakdown(count:number) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		let user = this._userInfoService.getUser();
		let data = { 
			'user': user,
			'data': {
				'count': count
			}
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"reporting/getPostingsBreakdown", data, {headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['postingsBreakdown'];
					}
					else {
						console.log('Couldnt get skills overview');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getOldestPostings(count:number) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		let user = this._userInfoService.getUser();
		let data = { 
			'user': user,
			'data': {
				'count': count
			}
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"reporting/getOldestPostings", data, {headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['oldestPostings'];
					}
					else {
						console.log('Couldnt get oldest postings');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}


	
}
