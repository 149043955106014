import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../../Services/dashboard.service';
import { ColorHelper } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-posting-breakdown-graph',
  templateUrl: './posting-breakdown-graph.component.html',
  styleUrls: ['./posting-breakdown-graph.component.css']
})
export class PostingBreakdownGraphComponent implements OnInit {
  public numIndustries:number = 3;
  public postingsData:any[] = [];
  public legendData:string[];
  public view = "";
  public colors: ColorHelper;
  public colorScheme = { 
    domain: [
      'rgb(29, 104, 251)', 
      'rgb(51, 192, 252)', 
      'rgb(74, 255, 254)', 
      'rgb(175, 255, 255)', 
      'rgb(255, 252, 99)', 
      'rgb(255, 252, 99)'
    ] 
  };
  public activeEntries:any[] = [];


  constructor(private _dashboardService:DashboardService) { }

  ngOnInit(): void {
    this.getPostingsBreakdown();
  }

  getPostingsBreakdown() {
    this._dashboardService.getPostingsBreakdown(this.numIndustries)
    .subscribe(
    (result: any) => {
      this.legendData = result.map((d: any) => d.name);
      //this.colors = new ColorHelper(this.colorScheme, 'ordinal', this.legendData, null);
      this.postingsData = result;
    },
    error => {
      console.log('Could not load posting graph');
    });
  }

  formatTooltip(data) {
    return data['data']['label'];
  }

  legendLabelActivate(item): void {
    this.activeEntries = [item];
  }

 legendLabelDeactivate(item): void {
    this.activeEntries = [];
  }

}
