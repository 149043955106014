
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable,EventEmitter} from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';


@Injectable()
export class EmployerStudentService {

  constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }

  
	getSearchStudents(searchParams, page, step) {
		let data = { 'SEARCH': searchParams, start: page*step, step: step };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return {
							length: response['length'],
							candidates: response['candidateList']
						};
					}
					else {
						console.log('Couldnt get student profile data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	  	
  }

  getCandidateProfile(student_id){		
		
		let data = {  'id': student_id };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateProfile", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['candidateProfile'];
					}
					else {
						console.log('Couldnt get student profile data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
  }

  inviteToView(invitee, posting){
  		
		
  		let data = { "student-id": invitee.id, "opportunity-id": posting, "status": 1};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/createOpportunityStudent", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt get student profile data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
  }

  getAllOpportunityList(){
	let headers = new HttpHeaders();
	return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getAllOpportunityList", { headers: headers }).pipe(
		map(response => {
			{
				if (typeof (response !== 'undefined')) {
					return response['myOpportunities'];
				}
				else {
					console.log('Couldnt get postings data');
				}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status }
		)),);
}


	getPostingsForCandidate(candidateId){

	let data = { "candidate-id": candidateId};
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getOpportunitiesForCandidate", data, { headers: headers }).pipe(
	  map(response => {
		  {
			  if (typeof (response !== 'undefined')) {
				  return response['myOpportunities'];
			  }
			  else {
				  console.log('Couldnt get postings data');
			  }
		  };
	  }),
	  catchError(error => observableThrowError(
		  { status: error.status }
	  )),);
}
	
	logProfileView(candidate_id:string) {
		
		let headers = new HttpHeaders();
		let user = this._userInfoService.getUser();
		headers.append('Content-Type', 'application/json');
		let data = { 
			
			'user': user,
			'data': {
				'candidate': candidate_id,
			}
		};
		console.log(data);
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"core/logProfileView", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['status'];
					}
					else {
						console.log('Couldnt get degree');
					}
  
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	
getDegreeTypes() {
		let data = { };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getDegreeTypes", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['degreeTypes'];
					}
					else {
						console.log('Couldnt get degree types');
					}

				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
	getCandidateAvaibility() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateAvailabilityTypes", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['candidateAvailabilityTypes'];
					}
					else {
						console.log('Couldnt get degree');
					}
				  };
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
	getSkillList(){
		
	  
		let data = { };
	  let headers = new HttpHeaders();
	  headers.append('Content-Type', 'application/x-www-form-urlencoded');
	  return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getSkillList", data, { headers: headers }).pipe(
		  map(response => {
			  {
				  if (typeof (response !== 'undefined')) {
					  return response['skillList'];
				  }
				  else {
					  console.log('Couldnt get degree');
				  }
			  };
		  }),
		  catchError(error => observableThrowError(
			  { status: error.status }
		  )),);
}

  getEmployersActivity() {
    let data = { };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getEmployerActivities", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['employerActivities'];
          }
          else {
            console.log("Couldnt get employer's data");
          }
        };
      }),
      catchError(error => observableThrowError(
        {
          status: error.status
        }
      )));
  }
}
