import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { Login } from '../../Models/login';
import { LoginService } from '../../Services/login.service';
import { Router,ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml, Title } from "@angular/platform-browser";
import * as CryptoJS from 'crypto-js';
import { UserInfoService } from '../../Services/user-info.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ResetPasswordComponent } from '../dialogs/reset-password/reset-password.component';
import { TalentApiService } from '../../Services/talent-api.service';
import { RegisterService } from '../../Services/register.service';
import { TenantService } from '../../Services/tenant.service';
import { TermsConditionRegComponent } from '../terms-condition-reg/terms-condition-reg.component';
import { UserRegistrationErrorComponent } from '../dialogs/user-registration-error/user-registration-error.component';
import { ErrorMessageComponent } from '../dialogs/error-message/error-message.component';

@Component({
	selector: 'talent-login-form',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	public routeQueryParams;
	public registration:boolean = false;
	public loginForm = new UntypedFormGroup({
		email: new UntypedFormControl('', [
			Validators.required,
			Validators.email
		]),
		password: new UntypedFormControl('', [
			Validators.required,
		]),
	});
	public returnUrl: string = "";
	public loginErrorMessage:string = "";
	public sha:boolean = true;
	public resetRequestError: boolean = false;
	public resetRequestErrorMessage: string = '';
	public isResetRequestProceessing: boolean = false;
	public isFormSubmitted: boolean = false;
	public isRegFormSubmitted: boolean = false;
	public SuccessMessage: string = "";
	public ErrorMessage: string = "";
	public isInvalidUserPassword: boolean = false;
	public userRegistrationError: string = "";
	tenant: String;
	isLoading: boolean = false;

	constructor(private route: ActivatedRoute,
		private _loginService: LoginService, private _userInfoService: UserInfoService,
		private _router: Router, public _dialog: MatDialog, private _talentApiService: TalentApiService,
		private _registerService: RegisterService,
		private _tenantService: TenantService) { 
			this.tenant = this._tenantService.tenant;
			this.getLoginForm();
		}
	
	ngOnInit() {
		// if router parameter for dialog is true, then the dialog
		// for password reset is opened
		// ie. '/login?dialog=true'
		this.routeQueryParams = this.route.queryParams.subscribe(params => {
			if(params['dialog']) {
				this.openResetDialog();
			}
		})
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

    if (this.route.snapshot.queryParams['register'] != undefined
        && this.route.snapshot.queryParams['register'] != null) {
      this.getRegisterForm();
    }  

	}

	getRegisterForm() {		
		this.registration = true;
	}

	getLoginForm() {		
		this.registration = false;
	}


	onSubmit() {
		if(this.registration) {
			this.register();
		}
		else {
			this.login();
		}
	}

	login() {
		let user:Login = {
			Email: this.loginForm.get('email').value,
			Password: this.loginForm.get('password').value,
			RememberMe: false,
			ResetCode: ""
		};
		this.isFormSubmitted = true;
        this.isInvalidUserPassword = false;
        this.SuccessMessage = "";
        this.ErrorMessage = "";
        this.loginErrorMessage = "";
		this._loginService.Login(user).subscribe((result:any) => {
				if(typeof(result) !== 'undefined') {
					// on navigation to the dashboard, after the token pair is generated
					// the user detail will be null (canActivate), so getUserDetail will
        // be called during route authentication
        if (result['verified'] != undefined && result['verified'] == true) {
						this._router.navigateByUrl(this.returnUrl);
          }
          else if (result['enabled'] != undefined && result['enabled'] == false) {
          this.loginErrorMessage = result['msg'];
            this.loginAlert();
          }
					else {
						this._router.navigateByUrl('/email-verification');
          }
         
					// differentiate here for verified/unverified
				}
			},
			error => {
				this._userInfoService.logout();
				this.loginErrorMessage = "Username/Password combination does not exist";
				this.loginAlert();
			}
		);
	}

	register() {
		this.isLoading = true;
		let user:Login = {
			Email: this.loginForm.get('email').value,
			Password: this.loginForm.get('password').value,
			RememberMe: false,
			ResetCode: ""
		};
		this.isRegFormSubmitted = true;
		this.isInvalidUserPassword = false;
		this.ErrorMessage = "";
		this.userRegistrationError = "";
		this._registerService.Register(user).subscribe((result:any) => {
			this.isLoading = false;
			if (result) {
				this._router.navigate(['register']);
			}		
		},
		error => {
			this.isLoading = false;
			this.isInvalidUserPassword = true;
			this._userInfoService.logout();
			this.userRegistrationError = error.error.message;
			let errorType = 'userExists';
			if(error.error.msg === "User already invited by Administrator!") errorType = 'userInvited';
			this.regErrors(errorType);
			console.log(this.ErrorMessage);
		});
	}

	loginAlert() {
		let dialog = this._dialog.open(ErrorMessageComponent);
		dialog.componentInstance.errorTitle = "Login Failed"
		dialog.componentInstance.errorMessage = this.loginErrorMessage;
		dialog.componentInstance.errorClose = "Return to login";
	}

	resetPassword() {		
		let user:Login = {
			Email: this.loginForm.get('email').value,
			Password: "",
			RememberMe: false,
			ResetCode: ""
		};
		if(!this.loginForm.get('email').value) {
			this.isResetRequestProceessing= false;
			this.resetRequestError = true;
			this.resetRequestErrorMessage = "Please enter valid email address!";
			return false;
		}
		this.isResetRequestProceessing= true;
		this.resetRequestError = false;
		this.resetRequestErrorMessage = '';
		this._talentApiService.requestReset(user).subscribe(result=> {
		if(!result) {
			this.resetRequestError = true;
			this.isResetRequestProceessing = false;
			this.resetRequestErrorMessage = "Something went wrong when sending Forgot Password request!";		
      	} else {
			if(result['statusType'] == 'success') {
				this._talentApiService.snackbarMessage('Password Reset Code sent successfully!');
				this._router.navigate(['login'], {queryParams: {dialog: true}});
			} else {
				this._talentApiService.snackbarMessage(result['msg']);
			}
      	}
		},
		error =>{
			this.resetRequestError = true;
			this.isResetRequestProceessing = false;
			this.resetRequestErrorMessage = "Something went wrong when sending Forgot Password request!";
		});
	}

	openResetDialog() {
		let dialog = this._dialog.open(ResetPasswordComponent,{
			height: 'auto',
			width: '66.66%',
			panelClass:"posting_popup",
      disableClose: true,
      data: this.loginForm.get('email').value
		});
		this.isResetRequestProceessing = false;
		dialog.afterClosed().subscribe((result) => {
			this._router.navigate(['.'], { relativeTo: this.route });
			this.isFormSubmitted = false;
			this.isRegFormSubmitted = false;
		});
	}

	regErrors(errorType) {
		let dialog = this._dialog.open(UserRegistrationErrorComponent, {
			data: errorType
		});
		dialog.afterClosed().subscribe((result) => {
			this.isRegFormSubmitted = false;
			if(dialog.componentInstance.isResetRedirect){
				this.getLoginForm();
				this.resetPassword();
				this.openResetDialog();
			}  
		});
	}

	openDialog(): void {
		const dialogRef = this._dialog.open(TermsConditionRegComponent, {
			height: 'auto',
			panelClass:"posting_popup"
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.isFormSubmitted = false;
		});
	}

 

}
