import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuardService } from './Services/auth-guard.service';
import { LoginComponent } from './Pages/login/login.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { ProfileComponent } from './Pages/profile/profile.component';
import { StudentRegistrationComponent } from './Pages/student-registration/student-registration.component';
import { EmployerStudentComponent } from './Pages/employer-student/employer-student.component';
import { SettingsComponent } from './Pages/settings/settings.component';
import { TimesheetComponent } from './Pages/timesheet/timesheet.component';
import { PostingComponent } from './Pages/posting/posting.component';
import { FeedbackComponent } from './Pages/feedback/feedback.component';
import { EmployerSetupProfileComponent } from './Pages/dialogs/employer-setup-profile/employer-setup-profile.component';
import { CompanyAdminAboutComponent } from './Pages/company-admin/company-admin-about/company-admin-about.component';
import { AdminDashboardComponent } from './Admin/admin-dashboard/admin-dashboard.component';
import { AdminConfigComponent } from './Admin/admin-config/admin-config.component';
import { UsersComponent } from './Admin/users/users.component';
import { AdminSkillsComponent } from './Admin/admin-skills/admin-skills.component';
import { AdminLogoComponent } from './Admin/admin-logo/admin-logo.component';
import { AdminTenantPermissionsComponent } from './Admin/admin-tenant-permissions/admin-tenant-permissions.component';
import { TenantLogoComponent } from './Admin/tenant-logo/tenant-logo.component';
import { TenantComponent } from './Admin/tenant/tenant.component';
import { AdminNewsFeedComponent } from './Admin/admin-news-feed/admin-news-feed.component';
import { AdminContentReportingComponent } from './Admin/admin-content-reporting/admin-content-reporting.component';

import { CanDeactivateGuardService } from './Services/can-deactivate-guard.service';
import { EmployerRegistrationComponent } from './Pages/employer-registration/employer-registration.component';
import { ReportsComponent } from './Pages/reports/reports.component';
import { OpportunityDetailComponent } from './Pages/opportunity-detail/opportunity-detail.component';
import { ShortlistComponent } from './Pages/shortlist/shortlist.component';
import { ShortlistDetailComponent } from './Pages/shortlist-detail/shortlist-detail.component';
import { AdminEmailTemplateComponent } from './Admin/admin-email-template/admin-email-template.component';
import { VerifyEmailComponent } from './Pages/verify-email/verify-email.component';
import { CandidateEmailVerificationComponent } from './Shared/components/candidate/candidate-email-verification/candidate-email-verification.component';
import { AdminMarketingEmailComponent } from './Admin/admin-marketing-email/admin-marketing-email.component';
import { SystemApisComponent } from './Admin/system-apis/system-apis.component';
import { CompanyAdminComponent } from './Pages/company-admin/company-admin/company-admin.component';
import { CompanyUserGroupsComponent } from './Pages/company-admin/company-user-groups/company-user-groups.component';
import { AccountUsageComponent } from './Pages/company-admin/account-usage/account-usage.component';
import { InviteNewUserComponent } from './Pages/company-admin/invite-new-user/invite-new-user.component';
import { CompanyAdminMyUsersComponent } from './Pages/company-admin/company-admin-my-users/company-admin-my-users.component';
import { ImportBulkOpportunitiesComponent } from './Admin/import-bulk-opportunities/import-bulk-opportunities.component';
import { EmptyRouteComponent } from './Pages/empty-route/empty-route.component';
import { PublicPostingsComponent } from './Pages/public-postings/public-postings.component';
import { CandidateDiversityEditComponent } from './Shared/components/candidate/candidate-diversity-edit/candidate-diversity-edit.component';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'companyAdmin', component: CompanyAdminComponent, canActivate: [AuthGuardService],
    children: [
      // { path:'', redirectTo: 'aboutCompany', pathMatch: 'full' },
      { path: 'aboutCompany', component: CompanyAdminAboutComponent },
      { path: 'userGroups', component: CompanyUserGroupsComponent },
      { path: 'accountUsage', component: AccountUsageComponent },
      { path: 'InviteNewUser', component: InviteNewUserComponent },
      { path: 'companyUsers', component: CompanyAdminMyUsersComponent }
    ]
  },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'empty', component: EmptyRouteComponent, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'publicpostings/:companyName', component: PublicPostingsComponent },
  { path: 'profile/:id', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'register', component: StudentRegistrationComponent, canActivate: [AuthGuardService] },
  { path: 'diversitysurvey', component: CandidateDiversityEditComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent },
  { path: 'verify-email/:token', component: VerifyEmailComponent },
  { path: 'email-verification', component: CandidateEmailVerificationComponent },
  { path: 'employer-candidates', component: EmployerStudentComponent, canActivate: [AuthGuardService] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService] },
  { path: 'timesheet', component: TimesheetComponent, canActivate: [AuthGuardService] },
  { path: 'timesheet/:timeentryid', component: TimesheetComponent, canActivate: [AuthGuardService] },
  {
    path: 'postings', component: PostingComponent, canActivate: [AuthGuardService],
    children: [
      { path: ':postingId', component: OpportunityDetailComponent },
      { path: ':postingId/:landingTab', component: OpportunityDetailComponent }
    ]
  },
  { path: 'postings/:postingId/:studentId', component: PostingComponent, canActivate: [AuthGuardService] },
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuardService] },
  { path: 'feedback/:id_string', component: FeedbackComponent, canActivate: [AuthGuardService] },
  { path: 'setemployerpassword/:useridstr', component: LoginComponent },
  { path: 'employer_register', component: EmployerRegistrationComponent },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService]}, // need to secure route
  { path: 'reports/:reportId', component: ReportsComponent, canActivate: [AuthGuardService]}, // need to secure route
  {
    path: 'shortlist', component: ShortlistComponent, canActivate: [AuthGuardService],
    children: [
      { path: ':shortlistId', component: ShortlistDetailComponent }
    ]
  },
  // ======================== starts admin routes ======================
  { path: 'admin', component: AdminDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'admin/dashboard', component: AdminDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'admin/config', component: AdminConfigComponent, canActivate: [AuthGuardService] },
  { path: 'admin/users', component: UsersComponent, canActivate: [AuthGuardService] },
  { path: 'admin/skills', component: AdminSkillsComponent, canActivate: [AuthGuardService] },
  { path: 'admin/tenants', component: TenantComponent, canActivate: [AuthGuardService] },
  { path: 'admin/content-reporting', component: AdminContentReportingComponent, canActivate: [AuthGuardService] },
  { path: 'admin/tenantlogo', component: TenantLogoComponent, canActivate: [AuthGuardService] },
  { path: 'admin/newsfeed', component: AdminNewsFeedComponent, canActivate: [AuthGuardService] },
  { path: 'admin/logo', component: AdminLogoComponent, canActivate: [AuthGuardService] },
  { path: 'admin/tenantPermissions', component: AdminTenantPermissionsComponent, canActivate: [AuthGuardService] },
  { path: 'admin/emailTemplates', component: AdminEmailTemplateComponent, canActivate: [AuthGuardService] },
  { path: 'admin/marketingEmails', component: AdminMarketingEmailComponent, canActivate: [AuthGuardService] },
  { path: 'admin/system-apis', component: SystemApisComponent, canActivate: [AuthGuardService] },
  { path: 'admin/bulkImportPostings', component: ImportBulkOpportunitiesComponent, canActivate: [AuthGuardService] },
  // ========================== End Admin Routed =========================
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
