import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {EmployerStudentService} from '../../../Services/employer-student.service';
import { TalentApiService } from '../../../Services/talent-api.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatChipInput, MatChipInputEvent} from '@angular/material/chips';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { SaveFilterDialogComponent } from '../../dialogs/save-filter-dialog/save-filter-dialog.component';
@Component({
  selector: 'app-search-student',
  templateUrl: './search-student.component.html',
  styleUrls: ['./search-student.component.css']
})
export class SearchStudentComponent implements OnInit {
  public isStudentSearched: boolean = false;
  public allDegrees;
  public allSkills;
  public searchResult;
  public user = {
    text: "",
    skills:[],
    searchParams: {
			educationLevel: [],
			availability: [],
			experience: null,
      location:[]
		},
  };
  public TempFilter = {
    text: "",
    skills:[],
    searchParams: {
			educationLevel: [],
			availability: [],
			experience: null,
      location:[]
		},
  };
  skillCtrl = new UntypedFormControl('', [Validators.maxLength(80)]);
  locationCtrl = new UntypedFormControl();
  removable = true;
  filteredSkill: Observable<string[]>;
  @ViewChild('skillName') skillName: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;
  readonly separatorKeysCodes: number[] = [ENTER];
  isMobileDevice: any;
  constructor(private dialogRef: MatDialogRef<SearchStudentComponent>, 
    public _dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    private _talentAPIService: TalentApiService,
    private _employerStudentService:EmployerStudentService,@Inject(MAT_DIALOG_DATA) public data: any) {
      this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileDevice = true;
        } else {
          this.isMobileDevice = false;
        }
      });
  }

  ngOnInit(): void {
    this.getSkillList();
    this.user.skills = this.data.skills;
    this.user.searchParams.educationLevel = this.data.educationLevel;
    this.user.searchParams.availability = this.data.availability;
    this.user.searchParams.experience = this.data.experience;
    this.user.searchParams.location = this.data.location;
    this.user.text = this.data.text;
    this.TempFilter = jQuery.extend(true, {}, this.user);
  }
  cancel() {
		this.dialogRef.close();
  }
  submit(studentForm){
    this.isStudentSearched = true;
    if (!studentForm.valid) {
      this.isStudentSearched = false;
			return;
    } else {
      this.user = jQuery.extend(true, {}, this.TempFilter);
      this.dialogRef.close(this.user);
    }
  }
  getLocation(val: string) {
    if (val != undefined && val != '' && val != null) {
      this._talentAPIService.getLocation(val).subscribe(response => {
        this.searchResult = response
      })
    }
  }
  addLocation(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const index = this.TempFilter.searchParams.location.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        this.TempFilter.searchParams.location.push(value.trim());
      }
    }
    if (input) {
      input.value = '';
    }
    this.locationCtrl.setValue(null);
  }
  selectedLocation(event: MatAutocompleteSelectedEvent): void {
    const state = this.extractAddress(event.option.value.address_components, "administrative_area_level_1");
    const city = this.extractAddress(event.option.value.address_components, "locality");

    let locString: string = "";
    let locStringFlag = 0
    if (city != "" && state != "") {
      locString = city + ', ' + state;
      locStringFlag = 1
    } else if (city == "" && state != "") {
      locString = state;
      locStringFlag = 1
    } else if (city != "" && state == "") {
      locString = city;
      locStringFlag = 1
    }

    // check for city and state value flag and then insert city,state values into locations 
    if (locStringFlag == 1) {
      const index = this.TempFilter.searchParams.location.indexOf(locString);
      if (index < 0) {
        this.TempFilter.searchParams.location.push(locString)
      }
    }
    this.locationInput.nativeElement.value = '';
    this.locationCtrl.setValue(null);
  }
  extractAddress(array, attr) {
    if (array && array.length > 0) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].types.indexOf(attr) != -1) {
          return array[i].short_name;
        };
      };
    }
    return '';
  }
  removeLocation(location: string): void {
    const index = this.TempFilter.searchParams.location.indexOf(location);
    if (index >= 0) {
      this.TempFilter.searchParams.location.splice(index, 1);
    }
  }
  getSkillList(){
    this._employerStudentService.getSkillList().subscribe((result:any) => {
			if (result) {
        this.allSkills = result;
        this.filterSkills();
			}
			else {
				console.log('employer students not geeting');
			}
		});
  }
  addSkill(event){
    const value = event.value;
    const input = event.input;
    if(!value)    return;
    const alreadyInList = this.TempFilter.skills.filter(skill => skill.toLowerCase().indexOf(value) === 0);
    if(alreadyInList.length == 0){
      const checkInList = this.allSkills.filter(skill => skill.name.toLowerCase().indexOf(value) === 0);
      if(checkInList.length > 0){
        if ((checkInList[0].name || '').trim()) {
            this.TempFilter.skills.push(checkInList[0].name.trim());
          }
      } else {
        if ((value || '').trim()) {
          this.TempFilter.skills.push(value.trim());
        }
      }
    }
    //reset the input
    if (input) {
      input.value = '';
    }
  }
  selectSkill(event:MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    const alreadyInList = this.TempFilter.skills.indexOf(value);
    if(alreadyInList == -1){
      this.TempFilter.skills.push(event.option.viewValue);
    }
    this.skillName.nativeElement.value = '';
    this.skillCtrl.setValue(null);
  }
  removeSkill(value){
    const index: number = this.TempFilter.skills.indexOf(value);
			if (index !== -1) {
				this.TempFilter.skills.splice(index, 1);
			}
  }
  filterSkills() {
    this.filteredSkill = this.skillCtrl.valueChanges.pipe(
      startWith(null),
      map((skill: string | null) => skill ? this._filter(skill) : this.allSkills.slice())
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allSkills.filter(skill => skill.name.toLowerCase().indexOf(filterValue) === 0);
  }
  addSkillOnBlur(event: FocusEvent, skillList:MatChipInput) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.skillName.nativeElement, value : this.skillName.nativeElement.value,
        chipInput: skillList
      };
      this.addSkill(matChipEvent);
    }
  }
  searchByAvaibility(id, evt) {
		if (id === 0) {
			this.TempFilter.searchParams.availability.forEach(element => {
				element.checked = evt.checked;
			});
		} else {
			if (evt.checked) {
				let ifAllchecked = true;
				for (let i = 0; i < this.TempFilter.searchParams.availability.length ; i++) {
					if (this.TempFilter.searchParams.availability[i].id !== 0  && !this.TempFilter.searchParams.availability[i].checked) {
						ifAllchecked = false;
						break;
					}
				}

				this.TempFilter.searchParams.availability.find(element => element.id === 0).checked = ifAllchecked;
			} else {
				this.TempFilter.searchParams.availability.find(element => element.id === 0).checked = false;
			}
		}
  }
  searchByEducationLevel(id, evt) {
		if (id === 0) {
			this.TempFilter.searchParams.educationLevel.forEach(element => {
				element.checked = evt.checked;
			});
		} else {
			if (evt.checked) {
				let ifAllchecked = true;
				for (let i = 0; i < this.TempFilter.searchParams.educationLevel.length ; i++) {
					if (this.TempFilter.searchParams.educationLevel[i].id !== 0  && !this.TempFilter.searchParams.educationLevel[i].checked) {
						ifAllchecked = false;
						break;
					}
				}

				this.TempFilter.searchParams.educationLevel.find(element => element.id === 0).checked = ifAllchecked;
			} else {
				this.TempFilter.searchParams.educationLevel.find(element => element.id === 0).checked = false;
			}
    }
	}

  cancelFilter(){
    this.dialogRef.close(this.user);
  }

  saveSearch() {
    //const saveSearchData = jQuery.extend(true, {}, this.TempFilter);
    const saveSearchData = {
      text: this.TempFilter.text,
      skills: this.TempFilter.skills,
      educationLevel: this.TempFilter.searchParams.educationLevel,
      availability: this.TempFilter.searchParams.availability,
      experience: this.TempFilter.searchParams.experience,
      location: this.TempFilter.searchParams.location
    }

    if(saveSearchData.text || saveSearchData.skills.length > 0 ||  saveSearchData.educationLevel.length > 0 || saveSearchData.availability.length > 0 || saveSearchData.experience != undefined || saveSearchData.location.length > 0) {
      const dailog = this._dialog.open(SaveFilterDialogComponent, {
        height: 'auto',
        width: '70vw',
        data: {
          search_type: 'candidate',
          search_parameters : saveSearchData
        },
        panelClass: this.isMobileDevice ? 'posting_popup' : ''
      }).afterClosed().toPromise()
        .then((data) => {
          this.TempFilter.text = data.selectedData.text;
          this.TempFilter.skills = data.selectedData.skills;
          this.TempFilter.searchParams.educationLevel = data.selectedData.educationLevel;
          this.TempFilter.searchParams.availability = data.selectedData.availability;
          this.TempFilter.searchParams.experience = data.selectedData.experience;
          this.TempFilter.searchParams.location = data.selectedData.location;

          this._talentAPIService.snackbarMessage(data.result.msg);
      });
    } 
    
  }

}
