
import { catchError, map, startWith } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { FormGroup, UntypedFormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { EmployerRegistration } from '../../Models/EmployerRegistration';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml, Title } from "@angular/platform-browser";
import { EmployerRegisterService } from '../../Services/employer-register.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs'
import { LoginService } from '../../Services/login.service';
import * as CryptoJS from 'crypto-js';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Login } from '../../Models/login';
import { UserInfoService } from '../../Services/user-info.service';
import { TenantService } from '../../Services/tenant.service';
import { ErrorMessageComponent } from '../dialogs/error-message/error-message.component';

@Component({
  selector: 'talent-employer-registration-form',
  templateUrl: './employer-registration.component.html',
  styleUrls: ['./employer-registration.component.css']
})
export class EmployerRegistrationComponent implements OnInit {

  public user = {
    TJS_USERNAME: '',
    TJS_PASSWORD: '',
    firstName: '',
    lastName: '',
    phone: '',
    division: '',
    email: '',
    pass: '',
    verifiedpass: '',
    //    type: '',
    id: '',
    sector: [],
    company: {
      id: 0,
      name: '',
      city: [],
      street: [],
      zip: [],
      state: [],
      website: '',
      size: '',
      description: ''
    },
    location: { metro: 'USA', city: '', address: '', state: '', zip: '' },
    companyId: 0
  };
  // company: {
  // 	city: [],
  // 	street: [],
  // 	zip: [],
  // 	state: [],
  // },
  public activeStep = 0;
  public sectors = [];
  public companies = [];
  public companyList = [];
  public addr = {};
  public badLogin = true;
  public searchTextcity = '';
  public searchTextState = '';
  public searchTextZip = '';
  public searchTextStreet = '';
  //The NewCopmany and NewLocation is to allow user to enter new company information which doesn't exist in our database
  //public newCompany = { name: '', id: '', website: '' };
  public newCompany = { name: '', website: '', size: '', description: '' };
  public newLocation = { city: '', state: '', zip: '', street: '' };
  public submitting = false;
  public error = false;


  public isEmployerRegSubmitted: boolean = false;
  public EmployerRegistrationModal: EmployerRegistration = new EmployerRegistration();
  public SuccessMessage: string = "";
  public ErrorMessage: string = "";
  public companiesinfo: any;
  public companyCity: any;
  public skipSecondStep = false;

  public approximateLocation = {
    city: [],
    state: [],
    street: [],
    zip: []
  };
  public registerErrorMessage: string = "";
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  public states = ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
    'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
    'WY').split(' ').map(function (state) {
      return { abbrev: state };
    });
  tenant: String;

  constructor(private _employerRegistrationService: EmployerRegisterService,
    private _router: Router,
    private _loginService: LoginService,
    public _dialog: MatDialog,
    private renderer: Renderer2,
    private _userInfoService: UserInfoService,
    private _tenantService: TenantService) {
    this.isEmployerRegSubmitted = false;
    this.tenant = this._tenantService.tenant;
  }

  companyname: UntypedFormControl = new UntypedFormControl();
  companycity: UntypedFormControl = new UntypedFormControl();
  companystate: UntypedFormControl = new UntypedFormControl();
  companyzip: UntypedFormControl = new UntypedFormControl();
  companyaddress: UntypedFormControl = new UntypedFormControl();
  public companySize;
  public companySizeList;
  filteredCompany: Observable<string[]>;
  //filteredCompanyCity: Observable<string[]>;

  ngOnInit() {
    //call the setup method to initialize the user
    //this.setup();
    this.badLogin = false;
    this.getCompanySizeList();
    //this.getSectorsAndCompany();
    this.getSectorsList().subscribe(response => {
      //console.log('ngOnit after getSectors() ' + this.sectors);
    });
    this.getCompanyLists().subscribe(response => {
      //console.log('ngOnit after getCompany() ' + this.companyList);
    });
    //get Compnies info
    this.getCompaniesInfo().subscribe(response => {
      //console.log('ngOnit after getCompaniesinfo() ' + this.companiesinfo);
    });

    // this.filteredCompany = this.companyname.valueChanges
    // 	.pipe(
    // 	startWith(''),
    // 	map(val => this.filter(val))
    // 	);
    // this.filteredCompanyCity = this.companycity.valueChanges
    // 	.pipe(
    // 	startWith(''),
    // 	map(val => this.querySearch(val))
    // 	);
    this.isEmployerRegSubmitted = false;
  }

  getSectorsList() {
    let sec = [];
    return this._employerRegistrationService
      .getSectors().pipe(
        map(
          (result) => {
            let resultStr = JSON.stringify(result);
            let resultArray = JSON.parse(resultStr);
            resultArray.forEach(function (sect) {
              sect.name = sect.name.trim();
              sec.push(sect);
            });
            this.sectors = sec;
          }),
        catchError((error) => {
          console.log('error ' + error);
          throw error;
        }));
  }
  getCompanyLists() {
    let company = [];
    return this._employerRegistrationService
      .getCompanyList().pipe(
        map(
          (result) => {
            let resultStr = JSON.stringify(result);
            let resultArray = JSON.parse(resultStr);
            resultArray.forEach(function (comp) {
              company.push(comp.name);
            });
            this.companyList = resultArray;
          }),
        catchError((error) => {
          console.log('error ' + error);
          throw error;
        }));
  }

  getCompanySizeList() {
    this._employerRegistrationService.getCompanySizeList().subscribe((result: any) => {
      if (result) {
        this.companySizeList = result;
      }
      else {
        console.log('employer students not geeting');
      }
    });
  }

  getCompaniesInfo() {
    let compinfo = [];
    let location = {
      city: [],
      state: [],
      street: [],
      zip: []
    };
    return this._employerRegistrationService
      .getCompaniesInfo().pipe(
        map(
          (result) => {
            let resultStr = JSON.stringify(result);
            let resultArray = JSON.parse(resultStr);
            //console.log(result);
            this.companiesinfo = resultArray;
            // this.companiesinfo.forEach(function (compinfo) {
            // 	location.city.push(compinfo.city);
            // 	location.state.push(compinfo.state);
            // 	location.street.push(compinfo.street);
            // 	location.zip.push(compinfo.zip);
            // });
            // this.approximateLocation = location;
            // result.forEach(function (compinfo) {
            // 	compinfo.push(compinfo);
            // });
            // this.companiesinfo = compinfo;
          }),
        catchError((error) => {
          console.log('error ' + error);
          throw error;
        }));
  }

  createFilterFor(query) {
    if (query && query != '') {
      var lowercaseQuery = query.toString().toLowerCase();
      return function filterFn(item) {
        if (item && item != '') {
          var lowercaseitem = item.toString().toLowerCase();
          return (lowercaseitem.indexOf(lowercaseQuery) != -1);
        }
      };
    }
    return false;
  }

  onCompanyNameChange(companyName) {
    if (companyName == '') {
      //Clear the cities array to show all cities
      console.log('empty companyName');
    }
  }
  onCompanyCityChange(companyCity) {
    if (companyCity == '') {
      //Clear the cities array to show all cities
      console.log('empty companyCity');
    }
  }

  clearAddr() {
    this.addr = {
      cityList: [],
      stateList: [],
      zipList: [],
      cityToAdd: null,
      stateToAdd: null,
      zipToAdd: null
    };
  }

  setup() {

  }

  setCompany(value) {
    //console.log('Printing from addnewOption: '+this.user);
    this.user.company.name = '';
    this.user.company.website = '';
    this.user.company.description = '';
    this.user.sector = [];
    this.companySize = 0;

    this.newCompany.name = value;
    this.skipSecondStep = false;
    this.user.companyId = 0;
    this.user.company.id = 0;
    this.companyList.forEach(company => {
      if(value.toLowerCase() === company.name.toLowerCase()) this.user.company.id = company.id;
    });
    if (this.activeStep == 0 && this.user.company.id > 0) {
      this.skipSecondStep = true;
      this.user.companyId = this.user.company.id;
    }
  }
  setCompanyWebsite(value) {
    this.newCompany.website = value;
  }
  setCompanyDescription(value) {
    this.newCompany.description = value;
  }
  setcompanysector(value) {
    this.user.sector = value;
  }
  setCompanyCity(value) {
    this.newLocation.city = value;
    console.log('set City');
    //console.log('new location: ',this.newLocation);
  }
  setCompanyStreet(value) {
    this.newLocation.street = value;
    //console.log('new location: ',this.newLocation);
  }
  setCompanyZip(value) {
    this.newLocation.zip = value;
  }
  setCompanyState(value) {
    this.newLocation.state = value;
    //console.log('new location: ',this.newLocation);
  };
  setCompanyObject(value, steps) {
    this.user.company = JSON.parse(JSON.stringify(value)); //deep copy of object
    this.user.sector = value.sector;
    this.companySize = this.user.company.size;
    if (steps == 1 && this.user.company.id > 0) {
      this.skipSecondStep = true;
      this.user.companyId = this.user.company.id;
    }
  }
  //Registration begins here
  ngOnEmployerRegister(empRegForm) {

    this.submitting = true;
    var data: any;
    this.newLocation.street = this.companyaddress.value;
    this.newLocation.city = this.companycity.value;
    this.newLocation.state = this.companystate.value;
    this.newLocation.zip = this.companyzip.value;

    /*1*/
    if (this.user.company == null || (this.user.company.name == '')) {
      // if new company
      this.user.company = {
        id: 0,
        name: '',
        city: [],
        street: [],
        zip: [],
        state: [],
        website: '',
        size: '',
        description: ''
      };
      this.user.company.name = this.newCompany.name;
      this.user.company.website = this.newCompany.website;
      this.user.company.size = this.companySize;
      this.user.company.description = this.newCompany.description;
    }
    else {
      this.user.company.size = this.companySize;
    }

    if (this.user.location.city == null || this.user.location.city == '') {
      this.user.location.city = this.newLocation.city;
    };

    if (this.user.location.address == null || this.user.location.address == '') {
      this.user.location.address = this.newLocation.street;
    };

    if (this.user.location.state == null || this.user.location.state == '') {
      this.user.location.state = this.newLocation.state;
    };

    if (this.user.location.zip == null || this.user.location.zip == '') {
      this.user.location.zip = this.newLocation.zip;
    };

    this.user.TJS_USERNAME = this.user.email;
    this.user.TJS_PASSWORD = this.user.pass;


    /*Mine*/
    this.isEmployerRegSubmitted = true;
    this.SuccessMessage = "";
    this.ErrorMessage = "";
    if (!empRegForm.valid) {
      return;
    }
    let password = this.user.pass
    this.user.pass = CryptoJS.SHA256(this.user.pass).toString();
    this.user.verifiedpass = CryptoJS.SHA256(this.user.verifiedpass).toString();
    this.user.TJS_PASSWORD = CryptoJS.SHA256(this.user.TJS_PASSWORD).toString();
    this._employerRegistrationService.Register(this.user)
      .subscribe(
        (result: any) => {
          let params: Login = {
            Email: this.user.TJS_USERNAME,
            Password: password,
            RememberMe: false,
            ResetCode: ""
          }
          this._loginService.Login(params)
            .subscribe(
              (result: any) => {
                this._router.navigate(['email-verification']);

              },
              error => {
                this.ErrorMessage = "Something Went Wrong calling loginservice login!";
                console.log(this.ErrorMessage);
              }
            );
        },
        error => {
          this.registerErrorMessage = "Please check to make sure that you don\'t already have an account!";
          this.registerAlert();
          this.user.pass = password;
          this.user.verifiedpass = password;
          //this.ErrorMessage = "Something went wrong";
          //console.log(this.ErrorMessage);
        }
      );
  }

  registerAlert() {
    let dialog = this._dialog.open(ErrorMessageComponent);
    dialog.componentInstance.errorTitle = "Unable to register this employer"
    dialog.componentInstance.errorMessage = this.registerErrorMessage;
    dialog.componentInstance.errorClose = "Return to Registration Form";

    dialog.afterClosed()
      .subscribe((result) => {
        this.isEmployerRegSubmitted = false;
      });
  }

  validateStep() {
    if (this.skipSecondStep) {
      if (this.user.firstName == '' ||
        this.user.lastName == '' ||
        this.user.email == '' ||
        this.newCompany.name == '' ||
        this.user.pass == '' ||
        this.user.phone == '' ||
        this.user.verifiedpass == '') {
        return true;
      }     
    }
    return false;
  }

  setEmployerPassword() {
    this.submitting = true;
    this.error = false;

    //this.user.verifiedpass = this.user.pass;
    //this.user.resethash = CryptoJS.SHA256(this.user.reset).toString()

    // talentAPI.setEmployerInfo(this.user).then(function successCallback(response) {
    // 	this.submitting = false;

    // 	/* Set user credentials and remove old cookies */
    // 	UserInfo.setUserCredentials(this.user.email, this.user.passhash, false);
    // 	$cookies.remove('TJS_USERNAME');
    // 	$cookies.remove('TJS_PASSWORD');

    // 	$state.go('public.EmployerRegister');
    // 	$("#set-employer-password-modal").modal('hide');
    // }, function errorCallback(response) {
    // 	this.error = true;
    // 	this.submitting = false;
    // });
  }
  //For Company QuerySearch
  createFilterByName(query) {
    //let lowercaseQuery = query.toLowerCase();
    let lowercaseQuery = query.toLowerCase();
    return function filterFn(item) {
      return ((item.name.toLowerCase()).indexOf(lowercaseQuery) === 0);
    }
  }
  companySectorQuerySearch = (query, sourcelist): string => {
    let results = query.value ? sourcelist.filter(this.createFilterByName(query.value)) : [];
    //this.user.company = this.user.company[0];
    return results;
  }

  canDeactivate() {
    return true;
  }

	querySearch(query, sourcelist) {		
    if (query.value != undefined && sourcelist != undefined) {
      if (sourcelist.toString().indexOf(query.value) >= 0) {
        return [sourcelist];
      }
    }
    return [];
	}

  addNewOption = (companyName): string => {

    //if (typeof (this.user.company) !== undefined) {
    if (this.user.company.name != '' || this.approximateLocation.state[0]) {
      this.approximateLocation.state = this.user.company.state;
      this.approximateLocation.zip = this.user.company.zip;
      this.approximateLocation.street = this.user.company.street;
      this.approximateLocation.city = this.user.company.city;
    }
    this.EmployerRegistrationModal.CompanyName = companyName;
    return companyName ? companyName : companyName;
  }

  //For Company QuerySearch
  filter(val: string): string[] {
    if (val === null || val === '') {
      return null;
    }
    return this.companyList.filter(option =>
      option.indexOf(val) === 0);
  }

  setStep(value) {
    if (!this.skipSecondStep) {
      this.activeStep = value;
      $("mat-sidenav-content").animate({ scrollTop: 0 }, "fast");

    }
    else {
      this.registerCompanyUser();
    }
  }
  registerCompanyUser() {

    this.user.TJS_USERNAME = this.user.email;
    let password = this.user.pass
    this.user.pass = CryptoJS.SHA256(this.user.pass).toString();
    this.user.verifiedpass = CryptoJS.SHA256(this.user.verifiedpass).toString();
    this.user.TJS_PASSWORD = CryptoJS.SHA256(this.user.TJS_PASSWORD).toString();

    this._employerRegistrationService.RegisterCompanyUser(this.user)
      .subscribe(
        (result: any) => {
          let params: Login = {
            Email: this.user.TJS_USERNAME,
            Password: password,
            RememberMe: false,
            ResetCode: ""
          }
          this._loginService.Login(params)
            .subscribe(
              (result: any) => {
                this._router.navigate(['email-verification']);

              },
              error => {
                this.ErrorMessage = "Something Went Wrong calling loginservice login!";
                console.log(this.ErrorMessage);
              }
            );
        },
        error => {
          this.registerErrorMessage = "Please check to make sure that you don\'t already have an account!";
          this.registerAlert();
          this.user.pass = password;
          this.user.verifiedpass = password;
        }

      );
  }

  checkActiveStep(check) {
    return this.activeStep === check;
  };

}
