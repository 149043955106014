<div fxLayout="row">
    <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
        <!-- <button mat-mini-fab (click)="prev()"> -->
            <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
        <!-- </button> -->
    </div>
    <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" *ngIf="!edit" fxLayoutAlign="space-between start">
        <h1 class="title-bold">Tell us about your preferred work environment</h1>
        <button mat-button color="primary" (click)="skip()">SKIP</button>
    </div>
</div>

<form name="candidateWorkEnvironmentForm" id="candidateWorkEnvironmentForm" [formGroup]="form" autocomplete="off">
    <div fxLayout="row" [fxFlexOffset.gt-xs]="'5'" fxLayoutAlign="space-between" fxFlex="100">
        <div fxLayout="column" fxFlex="100">
            <div fxLayout="row wrap" class="border-bottom-silver padding-bottom-4"> 
                <div fxFlex="16" fxFlex.xs="41" class="wrap-text" fxLayoutAlign="space-between start">
                    <h4 class="remove-margin">Location</h4>
                </div>
                <div fxFlex="84" fxFlex.xs="59">
                    <mat-selection-list class="padding-top-unset" fxLayout="row wrap" [compareWith]="compareFunction" formControlName="location">
                        <mat-list-option [ngClass.xs]="'margin-bottom-7'" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" [value]="location" checkboxPosition="before" *ngFor="let location of CandidateLocation">
                        {{location.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <mat-error *ngIf="form.get('location').hasError('required') && isNextClicked" class="help-block small animated fadeInDown">
                        This field is required!
                    </mat-error>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="20px" class="border-bottom-silver padding-bottom-4 margin-top-4">
                <div fxFlex="16" fxFlex.xs="41" class="wrap-text" fxLayoutAlign="space-between start">
                    <h4 class="remove-margin">Schedule</h4>
                </div>
                <div fxFlex="84" fxFlex.xs="59">
                    <mat-selection-list (selectionChange) ="selectAll('currentWorkScheduleType')" #currentSchedule class="padding-top-unset" fxLayout="row wrap" [compareWith]="compareFunction" formControlName="currentWorkScheduleType">
                        <mat-list-option [ngClass.xs]="'margin-bottom-7'" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" [value]="schedule" checkboxPosition="before" *ngFor="let schedule of CandidateCurrentWorkSchedule">
                        {{schedule.name}}
                        </mat-list-option>
                        <!--<mat-checkbox class="margin-top-4" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" [checked]="isAllSchedule" (click)="CandidateCurrentWorkSchedule.length == form.get('currentWorkScheduleType').value.length ? currentSchedule.deselectAll() : currentSchedule.selectAll()">Open to all</mat-checkbox>-->
                    </mat-selection-list>
                    <mat-error *ngIf="form.get('currentWorkScheduleType').hasError('required') && isNextClicked" class="help-block small animated fadeInDown">
                        This field is required!
                    </mat-error>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="20px" class="border-bottom-silver padding-bottom-4 margin-top-4">
                <div fxFlex="16" fxFlex.xs="41" class="wrap-text" fxLayoutAlign="space-between start">
                    <h4 class="remove-margin">Work station</h4>
                </div>
                <div fxFlex="84" fxFlex.xs="59">
                    <mat-radio-group fxLayout="row wrap"
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group" formControlName="workStation">
                    <mat-radio-button [ngClass.xs]="'margin-bottom-7'" value="1" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100">
                        Setup at home
                    </mat-radio-button>
                    <mat-radio-button [ngClass.xs]="'margin-bottom-7'" value="0" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100">
                        No setup at home
                    </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="form.get('workStation').hasError('required') && isNextClicked" class="help-block small animated fadeInDown">
                        This field is required!
                    </mat-error>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="20px" class="border-bottom-silver padding-bottom-4 margin-top-4">
                <div fxFlex="16" fxFlex.xs="41" class="wrap-text" fxLayoutAlign="space-between start">
                    <h4 class="remove-margin">Work environment</h4>
                </div>
                <div fxFlex="84" fxFlex.xs="59">
                        <mat-selection-list (selectionChange) ="selectAll('workEnvironmentType')" #workEnvType fxLayout="row wrap" class="padding-top-unset" [compareWith]="compareFunction" formControlName="workEnvironmentType">
                            <mat-list-option [ngClass.xs]="'margin-bottom-7'" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" [value]="environment" checkboxPosition="before" *ngFor="let environment of CandidateworkEnvironmentType">
                            {{environment.name}}
                            </mat-list-option>
                            <!--<mat-checkbox class="margin-top-4" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" [checked]="isAllWorkEnvironment" (click)="CandidateworkEnvironmentType.length == form.get('workEnvironmentType').value.length ? workEnvType.deselectAll() : workEnvType.selectAll()">Open to all</mat-checkbox>-->
                        </mat-selection-list>
                        <mat-error *ngIf="form.get('workEnvironmentType').hasError('required') && isNextClicked" class="help-block small animated fadeInDown">
                            This field is required!
                        </mat-error>
                    <!-- </div> -->
                </div>      
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="20px" class="margin-top-4">
                <div fxFlex="16" fxFlex.xs="41" class="wrap-text" fxLayoutAlign="space-between start">
                    <h4 class="remove-margin">Preferred Industries</h4>
                </div>
                <div fxFlex="84" fxFlex.xs="59">
                    <mat-selection-list class="padding-top-unset" fxLayout="row wrap" [compareWith]="compareFunction" formControlName="interestedMarkets">
                        <mat-list-option [ngClass.xs]="'margin-bottom-7'" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100"  [value]="sector" checkboxPosition="before" *ngFor="let sector of Candidatesector">
                        {{sector.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <mat-error *ngIf="form.get('interestedMarkets').hasError('required') && isNextClicked" class="help-block small animated fadeInDown">
                    This field is required!
                    </mat-error>
                </div>
            </div>
            <div fxLayout="row" fxFlexOffset="20px">
                <div fxLayout="column" fxLayoutAlign="start">
                    <button type="button" mat-raised-button color="primary" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                </div>
            </div>
        </div>
    </div>
</form>
