<div class="candidate-info-containter">
    <div fxLayout="row">
        <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
            <!-- <button mat-mini-fab (click)="prev()"> -->
                <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
            <!-- </button> -->
        </div>
        <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
            <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Where are you currently located?</h1>
            <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="95" fxFlexOffset="5">
            <form [formGroup]="form" autocomplete="off" novalidate>
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="100">
                        <div fxLayout="row">
                            <div fxLayout="column" fxFlex.gt-md="60" fxFlex.md="100" fxFlex.lt-md="100">
                                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                                    <div fxLayout="column" fxFlex.gt-md="48" fxFlex.md="48" fxFlex.lt-md="100">
                                        <mat-form-field color="accent" appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input matInput type="text" name="streetaddress" id="streetaddress" formControlName="streetaddress">
                                            <mat-error *ngIf="form.get('streetaddress').hasError('required')">
                                                Please enter Address
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column" fxFlex.gt-md="48" fxFlex.md="48" fxFlex.lt-md="100">
                                        <mat-form-field color="accent" appearance="outline">
                                            <mat-label>Apartment</mat-label>
                                            <input matInput type="text" name="address2" id="address2" formControlName="address2">
                                            <mat-error *ngIf="form.get('address2').hasError('maxlength')" class="help-block small animated fadeInDown">
                                                Please enter up to 10 letters only
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between">
                                    <div fxLayout="column" fxFlex="100">
                                        <mat-form-field color="accent" appearance="outline" >
                                            <mat-label>City</mat-label>
                                            <input matInput type="text" name="city" id="city"
                                            formControlName="city" class="form-control" [matAutocomplete]="autocity">
                                            <mat-autocomplete #autocity="matAutocomplete">
                                                <mat-option *ngFor="let city of querySearch(form.get('city').value, cities)" [value]="city.name">
                                                    {{ city.name }}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="form.get('city').hasError('required')">
                                                Please enter City
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                                    <div fxLayout="column" fxFlex.gt-md="48" fxFlex.md="48" fxFlex.lt-md="100">
                                        <mat-form-field color="accent" appearance="outline" class="full-width">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="state" name="state" id="state">
                                                <mat-option *ngFor="let state of states" [value]="state.abbrev">{{state.abbrev}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="form.get('state').hasError('required')">
                                                Please select State
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column" fxFlex.gt-md="48" fxFlex.md="48" fxFlex.lt-md="100">
                                        <mat-form-field color="accent" appearance="outline">
                                            <mat-label>Zipcode</mat-label>
                                            <input matInput type="text" name="zip" id="zip" formControlName="zip" maxlength="5">
                                            <mat-error *ngIf="form.get('zip').hasError('required')">
                                                Please enter Zipcode
                                            </mat-error>
                                            <mat-error *ngIf="form.get('zip').hasError('minlength') || form.get('zip').hasError('maxlength') || form.get('zip').hasError('pattern')" class="help-block small animated fadeInDown">
                                                Invalid Zipcode
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <button fxFlexOffset="30px" mat-raised-button color="primary" [disabled]="!form.valid" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    
</div>
