<div id="candidate-resume">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h3>Resume</h3>
        <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit()">edit</mat-icon>
    </div>
    <div fxLayout="row">
        <span class="mat-body-1" >Do you want us to share this resume with the employers?</span>
        <mat-slide-toggle
            fxFlex
            fxFlexOffset="1"
            class="example-margin"
            [color]="color"
            [checked]="resume && resume?.share ? true : false"
            (change)="isShare($event)"
            [disabled]="!resume">
        </mat-slide-toggle>   
    </div>
    <div fxLayout="row" fxLayoutGap="20">
        <app-logo class="upload_resume_pic" [tenant]="tenant" [type]="'Candidate_Reg_Resume'"></app-logo>   
        <div fxLayout="column">
            <span class="mat-body-1 padBottom5px">{{resume ? resume.name : "No resume available"}}</span>
            <a *ngIf="resume" id='download_resume' (click)="downloadDoc('download_resume');"><button mat-stroked-button color="primary"><mat-icon>file_download</mat-icon> Download</button></a>
        </div>            
    </div>
</div>
