<div fxLayout="row" fxLayoutAlign="space-between start">
    <h3>Additional Contact Details</h3>
    <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit()">edit</mat-icon>
</div>

<mat-list fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
    <mat-list-item *ngIf="additionalContacts.contentLink" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="contact">
        <app-company-logo matListAvatar [imageURL]="'assets/img/default.PNG'"></app-company-logo>
        <a mat-line
           target="{{ additionalContacts.contentLink.indexOf(':') > 0 ? '_blank' : undefined }}"
           href="{{ additionalContacts.contentLink.indexOf(':') > 0 ? additionalContacts.contentLink : 'javascript:void(0)' }}"> {{ additionalContacts.contentLink }} </a>    
    </mat-list-item>
    <mat-list-item *ngFor="let contact of additionalContacts.preferredContacts; let i=index"
            fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px" class="contact">
        <app-company-logo matListAvatar [name]="contact.contactType.name" [type]="'company'"></app-company-logo>
        <a mat-line 
           target="{{ contact.contact.indexOf(':') > 0 ? '_blank' : undefined }}"
           href="{{ contact.contact.indexOf(':') >0 ? contact.contact :'javascript:void(0)' }}"> {{ contact.contact }} </a>    
    </mat-list-item>
</mat-list>
