<div class="candidate-registration full-page-registration">
	<div fxLayout="row" fxLayoutAlign="center center">
		<div fxLayout="column" fxFlex="100">
			<div fxLayout="row" fxFlexOffset="30px" fxLayoutAlign="center center">
				<div fxLayout="column" fxFlex="80">
					<mat-card class="content-part reg-card">
                        <div fxLayout="row" fxFlex="grow" fxLayoutAlign="center stretch">
							<div fxLayout="column" fxFlex="10" *ngIf="isLoading">
								<mat-spinner></mat-spinner>
							</div>
							<div fxLayout="column" fxFlex="100" *ngIf="isVerified && !isLoading">
                                <div fxLayout="column wrap" fxLayoutAlign="center stretch">
                                    <div fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center">
                                            <form name="candidateEmailVerifyForm" id="candidateEmailVerifyForm" formGroup="form" autocomplete="off" novalidate>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <app-logo fxLayoutAlign="center center" class="email-veryfication" [tenant]="'HJJ'" [type]="'candidate-email-verification'"></app-logo>   
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column">
                                                        <h2 fxFlexOffset="10">Verified!</h2>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column" fxFlex="80" class="text-center">
                                                        <span>Your account has been successfully verified!</span>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column" fxFlex="80" class="text-center">
                                                        <span>Log in using your verified account credentials to start using HipJobJuice today!</span>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center" *ngIf="userType==3  || userType==0">
                                                    <div fxLayout="column">
                                                        <button fxFlexOffset="20px" mat-raised-button color="primary" (click)="login()">
                                                            LOGIN
                                                        </button>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center" *ngIf="userType==2">
                                                    <div fxLayout="column">
                                                        <button fxFlexOffset="20px" mat-raised-button color="primary" (click)="redirecToMatch()">FIND MY MATCH</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="100" *ngIf="!isVerified && !isLoading">
                                <div fxLayout="column wrap" fxLayoutAlign="center stretch">
                                    <div fxLayout="row">
                                        <div fxFlex="100" fxLayoutAlign="center center">
                                            <form name="candidateEmailVerifyForm" id="candidateEmailVerifyForm" formGroup="form" autocomplete="off" novalidate>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <app-logo fxLayoutAlign="center center" class="email-veryfication" [tenant]="'HJJ'" [type]="'candidate-email-verification'"></app-logo>   
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column">
                                                        <h2 fxFlexOffset="10">Something went wrong!</h2>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column" fxFlex="80" class="text-center">
                                                        <span>Seems like the verification link has been expired!</span>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <div fxLayout="column">
                                                        <button fxFlexOffset="20px" mat-raised-button color="primary" (click)="login()">
                                                            GO TO LOGIN
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>


