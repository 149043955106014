<div class="container">
	<div class="text-center row">
		<div class="col-lg-12 padding"></div>
		<div class="col-md-6">

			<!-- Notifications -->
			<div class="row" style="margin-bottom:15px; margin-top:10px;">
				<div class="drop-shadow bg-light">
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="">Notifications</h4>
						</div>
						<div class="panel-body">
							<div class="text-left" *ngFor="let item of widgets?.notifications?.alerts">
								{{item.title}}: {{item.detail}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<!-- Graphing -->
			<div class="row" style="margin-bottom:15px; margin-top:10px;">
				<div class="drop-shadow bg-light">
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="">Stats</h4>
						</div>
						<div class="panel-body">
							<!-- <nvd3 options="home.options" data="home.data"></nvd3> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>