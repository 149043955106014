import { Component, OnInit } from '@angular/core';
import { UserInfoService } from '../../../Services/user-info.service';


@Component({
  selector: 'app-graphing-card',
  templateUrl: './graphing-card.component.html',
  styleUrls: ['./graphing-card.component.css']
})
export class GraphingCardComponent implements OnInit {

  public userType = this._userInfoService.getUser().type;

  constructor(private _userInfoService: UserInfoService) { }

  ngOnInit(): void {
  }

}
