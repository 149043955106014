import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postinglistSearch'
})
export class PostinglistSearchPipe implements PipeTransform {

  transform(value, args) {
		if (!value) {return null; }
		if (!args) {return value; }

		args = args.toLowerCase();

		return value.filter(function(item) {
			return JSON.stringify(item).toLowerCase().includes(args);
		});
  }
}
