<div fxLayout="row" fxLayoutAlign="space-between start">
    <h3>Courses and Certifications</h3>
    <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit()">edit</mat-icon>
</div>

<div fxLayout="column" fxLayoutAlign="start stretch" class="candidate-education-list">
    <div *ngFor="let cert of coursesAndCertifications; let i=index">
        <mat-list *ngIf="!showingMore && i<3">
            <mat-list-item>
                <app-company-logo matListAvatar class="experience-logo" [name]="cert.authority" [type]="'company'"></app-company-logo>
                <h4 mat-line class="line-height"><strong style="font-weight: 500;">{{ cert.name }}</strong></h4>
                <h4 mat-line class="gray-font uppercase line-height"> {{ cert.authority }} </h4>
                <h5 mat-line class="mat-body-2 gray-font line-height"> 
                    {{ cert.dateAwarded  | date: 'MMMM yyyy' }}
                </h5>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngFor="let cert of coursesAndCertifications; let i=index">
        <mat-list *ngIf="showingMore">
            <mat-list-item>
                <app-company-logo matListAvatar class="experience-logo" [name]="cert.authority" [type]="'company'"></app-company-logo>
                <h4 mat-line class="line-height"><strong style="font-weight: 500;">{{ cert.name }}</strong></h4>
                <h4 mat-line class="gray-font uppercase line-height"> {{ cert.authority }} </h4>
                <h5 mat-line class="mat-body-2 gray-font line-height"> 
                    {{ cert.dateAwarded  | date: 'MMMM yyyy' }}
                </h5>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="coursesAndCertifications.length>3" fxLayout="row" fxLayoutAlign="center start">
        <button *ngIf="!showingMore" mat-stroked-button color="primary"
             fxFlex="33%" class="show-more-button" (click)="toggleShow()">Show More</button>
        <button *ngIf="showingMore" mat-stroked-button color="primary" 
             fxFlex="33%" class="show-more-button" (click)="toggleShow()">Show Less</button>
    </div>
    <div *ngIf="this.coursesAndCertifications.length==0" fxLayout="row" fxLayoutAlign="start start">
        <h4>None</h4>
      </div>
</div>
