import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { OpportunityService } from '../../../Services/opportunity.service';
import { UntypedFormControl } from '@angular/forms';
import { Opportunity } from '../../../Models/Opportunity';
import { PostingFilterPipe } from '../../../Helpers/Pipes/posting-filter.pipe';
import * as moment from 'moment';
import { TalentApiService } from '../../../Services/talent-api.service';
import { DegreeType } from '../../../Models/degree-type';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'underscore';
import { UserInfoService } from '../../../Services/user-info.service';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { SaveFilterDialogComponent } from '../../dialogs/save-filter-dialog/save-filter-dialog.component';

@Component({
  selector: 'app-opportunity-search-filter-dialog',
  templateUrl: './opportunity-search-filter-dialog.component.html',
  styleUrls: ['./opportunity-search-filter-dialog.component.css'],
  providers: [PostingFilterPipe, CurrencyPipe]
})

export class OpportunitySearchFilterDialogComponent implements OnInit {
  isMinValueInit = true;
  isMaxValueInit = true;
  thumbLabel = true;
  minValue: number;
  maxValue: number;
  isMobileDevice: any;
  minColor: ThemePalette = 'accent';
  maxColor: ThemePalette = 'accent';
  public ErrorMessage: string = "";
  userType: number;
  user: any;

  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;


  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  title: string[] = [];
  titleCtrl = new UntypedFormControl();
  filteredTitles: Observable<string[]>;
  workSetup: any = {
    value: []
  };
  commitment: any = {
    value: []
  };
  educationLevel: any = {
    value: []
  };
  matchBucket: any = {
    value: []
  };
  workSetupData: number[] = [];
  commitmentData: number[] = [];
  educationLevelData: number[] = [];
  matchBucketData: number[] = [];
  allTitleList: string[] = [];
  opportunityList: Opportunity[] = [];
  clearInfo: any;
  logoInfo: any;
  locations: string[] = [];
  orgLocation: any[] = [];
  locationCtrl = new UntypedFormControl();
  searchResult = [];
  minConf = 0;
  maxConf = 410000;
  searchText;


  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _talentApiService: TalentApiService,
    private _userInfoService: UserInfoService,
    private _opportunitService: OpportunityService,
    private dialogRef: MatDialogRef<OpportunitySearchFilterDialogComponent>,
    private sharedUtils: SharedUtilitiesService,
    public _dialog: MatDialog,
    public breakpointObserver: BreakpointObserver  ) {
    this.filteredTitles = this.titleCtrl.valueChanges.pipe(
      startWith(null),
      map((title: string | null) => title ? this._filter(title) : this.allTitleList.slice()));

    if (this.data != null) {
      this.convertDataObject(data);
    }

    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileDevice = true;
        } else {
          this.isMobileDevice = false;
        }
      });

  }

  ngOnInit(): void {
    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);
    this.GetJobTitle();
    if (this.data == undefined || this.data == null) {
      this.GetWorkSetup();
      this.GetMatchBuckets();
      this.GetEducation();
      this.GetCommitment();
    }
  }

  convertDataObject(data) {
    let dataObject = JSON.parse(JSON.stringify(data));

    if(dataObject.o_orgLocationlocation) {
      this.locations = dataObject.o_orgLocationlocation;
    }

    if(dataObject.o_location) {
      this.orgLocation = dataObject.o_location;
    }

    if(dataObject.o_title) {
      this.title = dataObject.o_title;
    }

    if(dataObject.searchText) {
      this.searchText = dataObject.searchText;
    }

      if(dataObject.o_work_setup) {
        this.workSetup = dataObject.o_work_setup;
        const isAnyWorkSetup = _.where(dataObject.o_work_setup.value, { name: "Any", completed: true });
        if (isAnyWorkSetup.length > 0) {
          this.workSetupData = _.pluck(dataObject.o_work_setup.value, 'id');
        } else {
          this.workSetupData = _.where(dataObject.o_work_setup.value, { completed: true });
          this.workSetupData = _.pluck(this.workSetupData, 'id');
        }
      } else {
        this.GetWorkSetup();
      }

      if(dataObject.o_match_bucket) {
        this.matchBucket = dataObject.o_match_bucket;
        const isAnyMatchBucket = _.where(dataObject.o_match_bucket.value, { name: "Any", completed: true });
        if (isAnyMatchBucket.length > 0) {
          this.matchBucketData = _.pluck(dataObject.o_match_bucket.value, 'id');
        } else {
          this.matchBucketData = _.where(dataObject.o_match_bucket.value, { completed: true });
          this.matchBucketData = _.pluck(this.matchBucketData, 'id');
        }
      } else {
        this.GetMatchBuckets();
      }

      if(dataObject.o_commitment) {
        this.commitment = dataObject.o_commitment;
        const isAnyCommitmentData = _.where(dataObject.o_commitment.value, { type: "Any", completed: true });
        if (isAnyCommitmentData.length > 0) {
          this.commitmentData = _.pluck(dataObject.o_commitment.value, 'id');
        } else {
          this.commitmentData = _.where(dataObject.o_commitment.value, { completed: true });
          this.commitmentData = _.pluck(this.commitmentData, 'id');
        }
      } else {
        this.GetCommitment();
      }

      if(dataObject.o_education) {
        this.educationLevel = dataObject.o_education;
        this.educationLevelData = _.where(dataObject.o_education.value, { completed: true });
        this.educationLevelData = _.pluck(this.educationLevelData, 'id');
      } else {
        this.GetEducation();
      }

      if(dataObject.o_range_salary) {
        this.minValue = dataObject.o_range_salary[0];
        // this.maxValue = dataObject.o_range_salary[1];
        this.isMinValueInit = (this.minValue === this.sliderMin);
        // this.isMaxValueInit = (this.maxValue === this.sliderMax);
      } else {
        if (!this.minValue) {
          this.minValue = this.sliderMin;
          this.isMinValueInit = (this.minValue === this.sliderMin);
        }
      }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTitleList.filter(title => title.toLowerCase().indexOf(filterValue) === 0);
  }


  GetCommitment() {
    this._opportunitService.GetCommitmentData().subscribe((result: any) => {
      if (result.length > 0) {
        this.commitment.value = result;
        this.commitment.value.forEach(function (obj) {
          obj.completed = false;
        });
      }
    },
      error => {
        this.ErrorMessage = "Unable to fetch commitment";
        console.log(this.ErrorMessage);
      }
    );
  }

  GetEducation() {
    this._opportunitService.getDegreeTypes().subscribe((result: DegreeType[]) => {
      if (result.length > 0) {
        this.educationLevel.value = result;
        this.educationLevel.value.forEach(function (obj) {
          obj.completed = false;
        })
      }
    },
      error => {
        this.ErrorMessage = "Unable to fetch Education Date";
        console.log(this.ErrorMessage);
      }
    );
  }

  GetWorkSetup() {
    this._opportunitService.GetWorkSetupData().subscribe((result: any) => {
      if (result.length > 0) {
        this.workSetup.value = result;
        this.workSetup.value.forEach(function (obj) {
          obj.completed = false;
        })
        var tempAnyObj = {
          id: 0,
          name: "Any",
          completed: false
        }
        this.workSetup.value.push(tempAnyObj);
      }
    },
      error => {
        this.ErrorMessage = "Unable to fetch commitment";
        console.log(this.ErrorMessage);
      }
    );
  }

  GetMatchBuckets() {
    this._opportunitService.GetMatchBuckets().subscribe((result: any) => {
      if (result.length > 0) {
        this.matchBucket.value = result;
        this.matchBucket.value.forEach(function (obj) {
          obj.completed = false;
        })
        var tempAnyObj = {
          id: 0,
          name: "Any",
          completed: false
        }
        this.matchBucket.value.push(tempAnyObj);
      }
    },
    error => {
      this.ErrorMessage = "Unable to fetch commitment";
      console.log(this.ErrorMessage);
    });
  }


  GetJobTitle() {
    this._opportunitService.GetOpportunitiesJobTitle().subscribe((result: any) => {
      if (result.length > 0) {
        var that = this;
        result.forEach(function (obj) {
          that.allTitleList.push(obj.title)
        })
      }
    },
      error => {
        this.ErrorMessage = "Unable to fetch titles";
        console.log(this.ErrorMessage);
      }
    );
  }

  formatAmountMin(value: number) {
    if (value >= 400000) {
      return 'Min - $400k+';
    }
    if (value >= 1000) {
      value = Math.round(value / 1000);
      return 'Min - $' + value + 'k';
    }
    return 'Min - $' + value;
  }

  formatAmountMax(value: number) {
    if (value >= 400000) {
      return 'Max - $400k+';
    }
    if (value >= 1000) {
      value = Math.round(value / 1000);
      return 'Max - $' + value + 'k';
    }
    return 'Max - $' + value;
  }

  set sliderMax(m: number) {
    this.maxConf = parseInt(m.toString(), 10);
  }
  get sliderMax(): number {
    return this.maxConf;
  }

  set sliderMin(m: number) {
    this.minConf = parseInt(m.toString(), 10);
  }
  get sliderMin(): number {
    return this.minConf;
  }

  ChangeworkSetup(value) {
    var isAnySelect = _.where(this.workSetup.value, { name: "Any", completed: true })
    if (isAnySelect.length > 0) {
      this.workSetupData = _.pluck(this.workSetup.value, 'id');
    }
    else if (value.completed == false && value.name == "Any") {
      this.workSetupData = [];
      const tempworksetupData = _.where(this.workSetup.value, { completed: true });
      this.workSetupData = _.pluck(tempworksetupData, 'id');
    } else if (value.completed == true && value.name == "Any") {
      this.workSetupData = [];
      this.workSetupData = _.pluck(this.workSetup.value, 'id');
    } else if (value.name != "Any") {
      if (value.completed) {
        this.workSetupData.push(value.id)
      } else {
        if (value.completed == false) {
          const index = this.workSetupData.indexOf(value.id);
          if (index >= 0) {
            this.workSetupData.splice(index, 1);
          }
        }
      }
    }
    this.workSetupData = _.uniq(this.workSetupData);
  }

  ChangeCommitment(value) {
    var isAnySelect = _.where(this.commitment.value, { type: "Any", completed: true })
    if (isAnySelect.length > 0) {
      this.commitmentData = _.pluck(this.commitment.value, 'id');
    }
    else if (value.completed == false && value.type == "Any") {
      this.commitmentData = [];
      const tempCommitData = _.where(this.commitment.value, { completed: true });
      this.commitmentData = _.pluck(tempCommitData, 'id');
    } else if (value.completed == true && value.type == "Any") {
      this.commitmentData = [];
      this.commitmentData = _.pluck(this.commitment.value, 'id');
    } else if (value.type != "Any") {
      if (value.completed) {
        this.commitmentData.push(value.id)
      } else {
        if (value.completed == false) {
          const index = this.commitmentData.indexOf(value.id);
          if (index >= 0) {
            this.commitmentData.splice(index, 1);
          }
        }
      }
    }
    this.commitmentData = _.uniq(this.commitmentData);
  }

  ChangeEducation(value) {
    if (value.completed) {
      this.educationLevelData.push(value.id)
    } else {
      const index = this.educationLevelData.indexOf(value.id);
      if (index >= 0) {
        this.educationLevelData.splice(index, 1);
      }
    }
  }

  ChangeMatchBucket(value) {
    if (value.completed) {
      if(value.id == 0) {
        this.matchBucketData = [];
        this.matchBucket.value.forEach(el => {
          el.completed = true;
          this.matchBucketData.push(el.id);    
        });
      } else {
        this.matchBucketData.push(value.id)
        if(this.matchBucketData.length == 4) {
          let bucketAny = this.matchBucket.value.find(el => el.id == 0);
          bucketAny.completed = true;
          this.matchBucketData.push(0);
        }
      }
    } else {
      if(value.id == 0) {
        this.matchBucket.value.forEach(el => {
          el.completed = false;
        });
        this.matchBucketData = [];
      } else {
        this.removeMatchBucketData(value.id);
        
        if(this.matchBucketData.length <= 4) {
          let bucketAny = this.matchBucket.value.find(el => el.id == 0);
          bucketAny.completed = false;
          this.removeMatchBucketData(0);
        }
      }
    }
  }

  removeMatchBucketData(value) {
    const index = this.matchBucketData.indexOf(value);
    if (index >= 0) {
      this.matchBucketData.splice(index, 1);
    }
  }

  minValueInput(a): void {
    if(this.minValue >= this.maxValue) {
      this.minValue = this.maxValue - 1;
    }
  }

  // maxValueInput(a): void {
  //   if(this.maxValue <= this.minValue) {
  //     this.maxValue = this.minValue + 1;
  //   }
  // }

  removeTitle(title: string): void {
    const index = this.title.indexOf(title);

    if (index >= 0) {
      this.title.splice(index, 1);
    }
  }

  addTitle(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.title.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.titleCtrl.setValue(null);
  }

  selectedTitle(event: MatAutocompleteSelectedEvent): void {
    this.title.push(event.option.viewValue);
    this.titleInput.nativeElement.value = '';
    this.titleCtrl.setValue(null);
  }

  Filter() {
    const popupdata = {
      o_location: this.orgLocation,
      o_orgLocationlocation: this.locations,
      o_title: this.title,
      o_work_setup: this.workSetup,
      o_commitment: this.commitment,
      o_education: this.educationLevel,
      o_range_salary: [this.minValue],
      o_match_bucket: this.matchBucket,
      searchText: this.searchText
      // o_range_salary: [this.minValue,this.maxValue],
    }
    var data = {
      data: [],
      selectedData: popupdata
    }
    this.dialogRef.close(data);
  }

  saveSearch() {
    const popupdata = {
      o_location: this.orgLocation,
      o_orgLocationlocation: this.locations,
      o_title: this.title,
      o_work_setup: this.workSetup,
      o_commitment: this.commitment,
      o_education: this.educationLevel,
      o_range_salary: [this.minValue],
      o_match_bucket: this.matchBucket,
      searchText: this.searchText
      // o_range_salary: [this.minValue,this.maxValue],
    }

    var obj = {
      o_location: this.orgLocation,
      o_orgLocationlocation: this.locations,
      o_title: this.title,
      o_work_setup: this.workSetupData,
      o_match_bucket: this.matchBucketData,
      o_commitment: this.commitmentData,
      o_education: this.educationLevelData,
      o_range_salary: [this.minValue],
      searchText: this.searchText,
      // o_range_salary: [this.minValue,this.maxValue],
      TJS_USERNAME: "",
      TJS_PASSWORD: ""
    }

    if (obj.o_range_salary[0] === 0 && obj.o_location.length == 0 && obj.o_education.length == 0 && obj.o_commitment.length == 0 && obj.o_title.length == 0 && obj.o_work_setup.length == 0 && obj.o_match_bucket.length == 0 && obj.searchText == '') {
    } else {
      const dailog = this._dialog.open(SaveFilterDialogComponent, {
        height: 'auto',
        width: '70vw',
        data: {
          search_type: 'posting',
          search_parameters : popupdata
        },
        panelClass: this.isMobileDevice ? 'posting_popup' : ''
      }).afterClosed().toPromise()
        .then((data) => {
          if (data.result == null) {
          }
          else {
            this.convertDataObject(data.search_parameters);
            this._talentApiService.snackbarMessage(data.result.msg);
          }
      });
    }
  }

  close() {     
    var data = {
      data: null,
      selectedData: this.data
    }
    this.dialogRef.close(data);
  }

  clearFilter() {
    this.locations = []
    this.title = []
    this.workSetup.value.forEach(function (obj) { obj.completed = false; })
    this.commitment.value.forEach(function (obj) { obj.completed = false; });
    this.educationLevel.value.forEach(function (obj) { obj.completed = false; });
    this.matchBucket.value.forEach(function(obj) { obj.completed = false; });
    this.minValue = this.minConf;
    // this.maxValue = this.maxConf;

    this.commitmentData = []
    this.educationLevelData = []
    this.orgLocation = []
    this.workSetupData = []

  }

  


  addLocation(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const index = this.locations.indexOf(value);
    if (index < 0) {
      if ((value || '').trim()) {
        const obj = { 'city': '', 'state': '', 'other': value.trim() }
        this.orgLocation.push(obj)
        this.locations.push(value.trim());
      }
    }


    if (input) {
      input.value = '';
    }
    this.locationCtrl.setValue(null);
  }

  removeLocation(location: string): void {
    const index = this.locations.indexOf(location);
    if (index >= 0) {
      this.locations.splice(index, 1);
      this.orgLocation.splice(index, 1);
    }
  }

  getLocation(val: string) {
    if (val != undefined && val != '' && val != null) {
      this._talentApiService.getLocation(val).subscribe(response => {
        this.searchResult = response
      })
    }
  }

  selectedLocation(event: MatAutocompleteSelectedEvent): void {
    const state = this.extractAddress(event.option.value.address_components, "administrative_area_level_1");
    const city = this.extractAddress(event.option.value.address_components, "locality");
    const obj = { 'city': city.trim(), 'state': state.trim(), 'other': '' }

    let locString: string = "";
    let locStringFlag = 0
    if (city != "" && state != "") {
      locString = city + ', ' + state;
      locStringFlag = 1
    } else if (city == "" && state != "") {
      locString = state;
      locStringFlag = 1
    } else if (city != "" && state == "") {
      locString = city;
      locStringFlag = 1
    }

    // check for city and state value flag and then insert city,state values into locations 
    if (locStringFlag == 1) {
      // check for distinct value (insert if no record in the list)
      const index = this.locations.indexOf(locString);
      if (index < 0) {
        this.orgLocation.push(obj)
        this.locations.push(locString)
      }
    }
    this.locationInput.nativeElement.value = '';
    this.locationCtrl.setValue(null);
  }

  //parses the google API response - highly cooporative with nulls
  extractAddress(array, attr) {
    if (array && array.length > 0) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].types.indexOf(attr) != -1) {
          return array[i].short_name;
        };
      };
    }
    return '';
  }


}
