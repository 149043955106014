<div fxLayout="row" fxLayoutAlign="space-between center" class="title-padding">
    <h2 class="medium-20">Create New Group</h2>
    <div fxLayout="row" fxLayoutGap="10px">
        <button mat-stroked-button [mat-dialog-close]="true">CANCEL</button>
        <button mat-raised-button color="primary" (click)="save()">SAVE</button>
    </div>  
</div>
<form fxLayout="column" [formGroup]="newGroupForm" autocomplete="off" novalidate>
    <mat-divider></mat-divider>
    <mat-label class="medium-16 margin-above">Group Name</mat-label>
    <mat-form-field appearance="outline">
        <input matInput formControlName="group-name">
        <mat-error *ngIf="newGroupForm.get('group-name').hasError('required')">Group Name cannot be empty</mat-error>
        <mat-error *ngIf="newGroupForm.get('group-name').hasError('maxlength')">Group Name cannot exceed 255 characters</mat-error>
    </mat-form-field>
    <mat-label class="medium-1">Select Access Rights</mat-label>
    <app-user-group-permissions-component (dataChange)="onDataChange($event)" [viewOnly]="false" [groupId]="0"></app-user-group-permissions-component>
</form>