import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { Subject } from 'rxjs';
import { AdminApiService } from '../../Services/admin-api.service';
import { MatDialog } from '@angular/material/dialog';
import { NewsFeedEditComponent } from '../../Admin/dialogs/news-feed-edit/news-feed-edit.component';


@Component({
  selector: 'app-admin-news-feed',
  templateUrl: './admin-news-feed.component.html',
  styleUrls: ['./admin-news-feed.component.css']
})
export class AdminNewsFeedComponent implements OnInit {
  displayedColumns: string[] = ['id', 'fk_tenant_id', 'fk_user_type', 'feedUrl', 'priority', 'expiration', 'expires', 'active', 'created_by', 'created_ts', 'last_updated_by', 'last_updated_ts', 'edit'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  tenants:any;
  userTypes:any;
  newsSourcesList = {};


  constructor(private _adminApiService: AdminApiService,
    public _dialog: MatDialog) { }

  ngOnInit(): void {
    this.getTenants();
    this.getUserTypes();
	  this.getNewsSources();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTenants() {
		this._adminApiService.getTenantsList()
			.subscribe(
			(result: any) => {
        this.tenants = result;
			},
			error => {
				console.log('Could not load Tenants');
			}
		);
  }
  
  getUserTypes() {
		this._adminApiService.getUserTypes()
			.subscribe(
			(result: any) => {
        this.userTypes = result;
			},
			error => {
				console.log('Could not load User Types');
			}
		);
	}


  getNewsSources() {
		this._adminApiService.getNewsSources().subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      const self = this;
      result.map((element) => {
        self.newsSourcesList[element.id] = element;
      });
		},
			error => {
				console.log('Could not load News Sources');
			}
		);
	}

  // role edit popup
  openEditModal(id= '') {
    const dialog = this._dialog.open(NewsFeedEditComponent, {
      minWidth: "50%"
    });
    const editData = Object.assign({}, this.newsSourcesList[id]);
    const isReloadData = false;

    dialog.componentInstance.isReloadData = isReloadData;

    dialog.componentInstance.editing = editData;
    dialog.componentInstance.tenants = this.tenants;
    dialog.componentInstance.userTypes = this.userTypes;
    dialog.afterClosed().subscribe((result) => {
      if (dialog.componentInstance.isReloadData) {
      this.getNewsSources();
      }
    });
  }


}
