import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../Services/login.service';
import { DashboardService } from '../../../Services/dashboard.service';
import { UserInfoService } from '../../../Services/user-info.service';
import { ProfileMetrics } from '../../../Models/ProfileMetrics';


@Component({
  selector: 'app-profile-metrics',
  templateUrl: './profile-metrics.component.html',
  styleUrls: ['./profile-metrics.component.css']
})
export class ProfileMetricsComponent implements OnInit {
  public userType: number;
  public ErrorMessage: string = "";
  public metrics: ProfileMetrics = {
    profileViews: "",
    jobsApplied: "",
    inPipeline: "",
    profileScore: ""
  };
  myEventSubscription: any;

  constructor(private _loginService: LoginService, private _dashboardService: DashboardService,
    private _userInfoService: UserInfoService) { }

  ngOnInit(): void {
    this.userType = parseInt(this._userInfoService.getUser().type);
    this.getProfileMetrics();
  }

  getProfileMetrics() {
    this.ErrorMessage = "";
    this._dashboardService.getProfileMetrics().subscribe((result: any) => {
      if (result!=undefined && result.length > 0) {
        this.metrics = {
          profileViews: result[0].num_views,
          jobsApplied: result[0].jobs,
          inPipeline: result[0].pipeline,
          profileScore: result[0].candidate_score
        }
      } else {
        this.metrics = {
          profileViews: '0',
          jobsApplied: '0',
          inPipeline: '0',
          profileScore: '0'
        }
      }
    },
      error => {
        this.ErrorMessage = "Unable to load News feed";
        console.log(this.ErrorMessage);
      }
    );
  }

}
