import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TalentApiService } from '../../Services/talent-api.service';
import { ShortlistService } from '../../Services/shortlist.service';
import { CommonConfirmPopupComponent } from '../dialogs/common-confirm-popup/common-confirm-popup.component';
import { Router } from '@angular/router';
import { UserInfoService } from '../../Services/user-info.service';


@Component({
  selector: 'app-shortlist-candidates',
  templateUrl: './shortlist-candidates.component.html',
  styleUrls: ['./shortlist-candidates.component.css']
})
export class ShortlistCandidatesComponent implements OnInit {
  @Input() shortlistId: any;
  @Input() breakpoint: number;
  @Input() shortlistDetail: any;
  shortlistCandidates: any = [];
  public totalLength = 0; // Length of the candidate array
  public pageSize = 20; // Number of candidate fetched by API and displayed
  public pageIndex = 0; // Page number (starting candidate index = pageIndex * pageSize)
  public isLoadingResult: boolean = false;
  public isShortlistView: boolean = true;
  public hasManageCandidatePermission;
  @Output() onShortlistCandidateCount = new EventEmitter<any>();

  constructor(public _dialog: MatDialog,
    private _userInfoService: UserInfoService,
    private _talentApiService: TalentApiService,
    private _router: Router, 
    private _shortListService: ShortlistService) { }

  ngOnInit(): void {
    this._userInfoService.checkMyPermission('Manage Shortlist Candidates').subscribe((result: any) => {
      this.hasManageCandidatePermission = result;
    });
    this.getShortlistCandidates(0, this.pageSize);
  }

  findCandidates() {
    this._router.navigate(['employer-candidates']);
  }

  ///
  getShortlistCandidates(pageNumber, pageSize) {    
    this.isLoadingResult = true;
    this._shortListService.getShortListCandidates(this.shortlistId, pageNumber, pageSize).subscribe((result: any) => {            
      this.shortlistCandidates = result['shortlistCandidates'];
      this.totalLength = result['total_count'];
      this.onShortlistCandidateCount.emit(this.totalLength);
      this.isLoadingResult = false;

    });
  }

  // Gets the next group of shortlist-candidates from the API
  onPageChange(event) {    
    this.pageSize = event.pageSize;
    this.getShortlistCandidates(event.pageIndex, event.pageSize)
  }

  removeCandidate(candidateId) {
    let dialog;
    if (!dialog == null) {
    } else {
      dialog = this._dialog.open(CommonConfirmPopupComponent, {
        height: '175',
        width: '36.66%',
        disableClose: false,
      });
      dialog.componentInstance.post_type = 'Delete Shortlist-Candidate';
      dialog.componentInstance.confirm_message = 'Are you sure you want to delete this shortlisted candidate?';
      dialog.componentInstance.discardConfirm = true;
    }
    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {          
          this._shortListService.deleteShortlistCandidate(this.shortlistId, candidateId)
            .subscribe(
              (result: any) => {
                if (result) {                  
                  this._talentApiService.snackbarMessage('Candidate has been removed from this shortlist successfully!');
                  this.getShortlistCandidates(0, this.pageSize);
                  this._shortListService.refreshShortlistData.next({ id: this.shortlistId, value: true }); //Refresh shortlist list								

                } else {
                  this._talentApiService.snackbarMessage(result.msg);
                }
              },
              error => {
              }
            );
        }
      });
  }
}

