<div fxLayout="row wrap" fxLayoutAlign="space-between" [ngClass.gt-sm]="'emp-posting-header-section'">
  <div fxFlex="58" fxFlex.md="50" fxFlex.sm="100" fxFlex.lt-md="100">
    <h2>{{detail.title}}</h2>

    <div class="mat-body-1 margin10px">
      <ng-container *ngIf="detail.locations.length == 1">
        <a href="http://maps.google.com/{{'?q=' + (detail.locations[0].address1 ? detail.locations[0].address1+ ', ':'') + (detail.locations[0].city ? detail.locations[0].city+ ', ':'') + (detail.locations[0].state ? detail.locations[0].state+ ', ':'') + (detail.locations[0].zip ? detail.locations[0].zip:'') }}"
          target="_blank">{{ (detail.locations[0].city ? detail.locations[0].city+ ', ':'') + (detail.locations[0].state
          ? detail.locations[0].state.toUpperCase():'') }}</a>
      </ng-container>
      <ng-container *ngIf="detail.locations.length > 1">
        Multiple Locations
      </ng-container>
      &nbsp;
    </div>

    <div class="mat-body-1 margin10px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
      <span class="mat-body-1">
        Created by {{ detail.ownerName + ' ' + (detail.postingDay == 0? 'Today': detail.postingDay + (detail.postingDay
        == 1 ? ' day': ' days') + ' ago') }}
      </span>
    </div>
  </div>
  <div fxFlex="30" fxFlex.lt-md="100" class="status-info" fxLayoutAlign="space-between space-between" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="detail.isOwnerOrAdminRecruiter">
      <div class="mat-display-1 margin10px" fxFlex="60">
        Views
      </div>
      <div class="mat-display-3 count-padding-right">
        {{totalViewsLength}}
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="detail.isOwnerOrAdminRecruiter">
      <div class="mat-display-1 margin10px" fxFlex="60">
        Applications
      </div>
      <div class="mat-display-3 count-padding-right" *ngIf="applicants != null">
        {{totalApplicantLength}}
      </div>
      <div class="mat-display-3 count-padding-right" *ngIf="applicants == null">
        0
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="detail.isOwnerOrAdminRecruiter">
      <div class="mat-display-1 margin10px" fxFlex="40" fxFlex.md="50" fxFlex.sm="60" fxFlex.xs="39">
        Status
      </div>
      <div fxFlex.sm="15" fxShow.sm></div>
      <div fxFlex fxFlex.md="50" fxFlex.sm="25" fxFlex.xs="61">
        <div fxLayout="row" fxLayoutAlign="end start">
          <div [ngSwitch]="selectedStatus" fxFlex.xs="18%" fxFlex.sm="18%" fxFlex.gt-sm="18" fxLayoutAlign="end center">
            <mat-icon inline="true" *ngSwitchCase="1" class="active">brightness_1</mat-icon>
            <mat-icon inline="true" *ngSwitchCase="2" class="draft">brightness_1</mat-icon>
            <mat-icon inline="true" *ngSwitchCase="0" class="inactive">brightness_1</mat-icon>
          </div>
          <div fxFlex.xs="75%" fxFlex.sm="70%" fxFlex.gt-sm="70"
            [ngClass.gt-lg]="{'gt-lg-active-select': selectedStatus == 1,'gt-lg-draft-select': selectedStatus == 2,'gt-lg-inactive-select': selectedStatus == 0}"
            [ngClass.xs]="{'xs-active-select': selectedStatus == 1,'xs-draft-select': selectedStatus == 2,'xs-inactive-select': selectedStatus == 0}"
            [ngClass.sm]="{'sm-active-select': selectedStatus == 1,'sm-draft-select': selectedStatus == 2,'sm-inactive-select': selectedStatus == 0}"
            [ngClass.gt-sm]="{'gt-sm-active-select': selectedStatus == 1,'gt-sm-draft-select': selectedStatus == 2}"
            [ngClass]="{'active-select': selectedStatus == 1,'draft-select': selectedStatus == 2}">
            <mat-select [(value)]="selectedStatus" name="jobstatus" [ngClass.xs]="'border'"
              (selectionChange)="onChangeStatus($event.value)" class="status-select mat-display-3"
              [disabled]="!detail.isOwnerOrAdminRecruiter">
              <mat-option value="1" class="mat-display-3">ACTIVE</mat-option>
              <mat-option value="2" class="mat-display-3" *ngIf="currStatus==2">DRAFT</mat-option>
              <mat-option value="0" class="mat-display-3">INACTIVE</mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="sharePostingDialog()">
        <span>Share</span>
      </button>
      <button mat-menu-item (click)="copyLink()">
        <span>Copy Link</span>
      </button>
      <button mat-menu-item (click)="contReportingDialog()">
        <span>Report Posting</span>
      </button>
    </mat-menu>
</div>