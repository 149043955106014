import { Component, OnInit,Inject,Optional } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Login } from '../../../Models/login';
import { TalentApiService } from '../../../Services/talent-api.service';
import { LoginService } from '../../../Services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

	public resetForm = new UntypedFormGroup({
    email: new UntypedFormControl(this.data, [
			Validators.required,
			Validators.email
		]),
		resetCode: new UntypedFormControl('', [
			Validators.required
		]),
		passwordNew: new UntypedFormControl('', [
			Validators.required
		]),
		passwordConfirm: new UntypedFormControl('', [
			Validators.required
		])
	});

	public submitting = false;
	public error = false;
	public errorMessage = '';
	constructor(private _talentApiService: TalentApiService,
		public _dialog: MatDialog,
		private _snackBar: MatSnackBar,
    private _loginService: LoginService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router) { }

	ngOnInit() {
	}

	reset() {
		const user:Login = {
			Email: this.resetForm.get('email').value,
			Password: this.resetForm.get('passwordNew').value,
			RememberMe: false,
			ResetCode: this.resetForm.get('resetCode').value
		};
		this.submitting = true;
		this.error = false;

		this._loginService.Login(user).subscribe((result:any) => {
			let passwordSnackBar = this._snackBar.open("Password successfully reset, please login again.", 'Ok', {
				horizontalPosition: 'center',
				verticalPosition: 'top',
				panelClass: ['success-snackbar']
			});
			passwordSnackBar.afterDismissed().subscribe(() => {
				this._loginService.logout().subscribe((result:any) => {
						 this.closeDialog();
						 this.submitting = false;
					})
                });
			},
			error => {
				this.errorMessage = 'Email or reset code is incorrect';
				console.log(this.errorMessage);
				this.error = true;
				this.submitting = false;
			}
		);
	}

	closeDialog() {
		this._dialog.closeAll();
	}

	resetPassword() {
		const user:Login = {
			Email: this.resetForm.get('email').value,
			Password: '',
			RememberMe: false,
			ResetCode: ''
		};
		if(!this.resetForm.get('email').value) {
			return false;
		}
		this._talentApiService.requestReset(user).subscribe(result => {
      if (result) {
		this._talentApiService.snackbarMessage('Password Reset Code sent successfully!');
      }
		}),
		error => {
			this.errorMessage = 'Email or reset code is incorrect';
			console.log(this.errorMessage);
			this.error = true;
			this.submitting = false;
		};
	}
}
