import { Component, OnInit ,Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AdminApiService } from '../../Services/admin-api.service';
import { MatDialog ,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import { BehaviorSubject, of as observableOf } from 'rxjs';
import { TalentApiService } from '../../Services/talent-api.service';
import { EmployerStudentService } from '../../Services/employer-student.service';

@Component({
  selector: 'app-admin-marketing-email',
  templateUrl: './admin-marketing-email.component.html',
  styleUrls: ['./admin-marketing-email.component.css']
})


export class AdminMarketingEmailComponent implements OnInit {
  public dummyChoices: ['a', 'b', 'c'];
  constructor(public _dialog: MatDialog) { 
	}

  ngOnInit(): void {

  }

  sendNew(){
    const dialogRef = this._dialog.open(MarketingDialog, {
      height: 'auto',
			width: '66.66%',
    });
  }

}


//-----------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------------





@Component({
  templateUrl: './admin-marketing-email-dialog.component.html',
})
export class MarketingDialog implements OnInit {
  public opportunities = [];
  public emailList = [];
  public isLoading: boolean = false;
  emailForm: UntypedFormGroup;
  fb: UntypedFormBuilder = new UntypedFormBuilder();
  public separatorKeysCodes = [ENTER, COMMA];

  constructor(
    private _employerStudentService: EmployerStudentService,
    public _dialog: MatDialog,
    private _talentApiService: TalentApiService,
    private _adminApiService: AdminApiService,
    private _dialogRef:MatDialogRef<MarketingDialog>)
    {}

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      emails: this.fb.array([], [this.validateArrayNotEmpty]),
      message: [''],
      selectedOpportunities: ['', [Validators.required]]
    });
    this.getOpportunitiesForCandidate();
  }

  getOpportunitiesForCandidate() {
    this.isLoading = true;
    this._employerStudentService.getAllOpportunityList()
		.subscribe((result: any) => {
		if (result) {
      this.opportunities = result;
      this.isLoading = false;
		} else {
			console.log('could not get the opportunities for candidate');
		}
		});
  }

  private validateArrayNotEmpty(c: UntypedFormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  addEmails(event): void {
    console.log(event.value)
    if (event.value) {
      if (this.validateEmail(event.value) && this.emailList.length < 10) {
        this.emailList.push({ value: event.value, invalid: false });
      } else {
        this.emailList.push({ value: event.value, invalid: true });
         
      }
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeEmail(data: any): void {
    console.log('email removed ' + data)
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  submit(){
    var emails = [];
    this.emailList.forEach(function (em) { if (!em.invalid) { emails.push(em.value) } });
    this._adminApiService.sendPostingListEmail(emails, this.emailForm.get('message').value, this.emailForm.get('selectedOpportunities').value)
    .subscribe((result: any) =>{
      if (emails.length == 0) {
        this._talentApiService.snackbarMessage('No valid email found!'); 
        return;
      }
      else {
        this._talentApiService.snackbarMessage('Posting shared successfully!');
        this.close();
      }
    });
  }

  close(){
    this._dialogRef.close();
  }


}

