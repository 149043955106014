<div id="studentDetail" [ngClass]="{'col-xs-5': showChoices == 1, 'col-xs-8': showChoices > 0}" class="student-detail-header mat-dialog-container mat-dialog-fullscreen">
    <form name="newFeedbackForm" id="newFeedbackForm" class="popup_class_new feedback_popup_" #newFeedbackForm="ngForm" novalidate>
		<mat-toolbar mat-toolbar class="mat-default-theme">
			<div class="mat-toolbar-tools background-primary background-white">
                <h1 mat-dialog-title class="calendar-md-title" *ngIf="showPages < 3">Create New Feedback</h1>
                <h1 mat-dialog-title class="calendar-md-title" *ngIf="showPages == 3">Request Feedback for {{feedbackEmailTemplate.name}}</h1>
                <div class="close_back_button">
                    <button mat-icon-button class="close_bt" (click)="cancel()"  matTooltip="Close"> <i class="fa fa-times" aria-hidden="true"></i> </button>

                </div>
            </div>
		</mat-toolbar>
		<mat-dialog-content class="bgpopup_in">
            <mat-card *ngIf="showPages==1">

                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field color="accent" appearance="outline" class="md-block">
                            <mat-select placeholder="What would you like the feedback to cover?" [(ngModel)]="fbModel.fbCoverOption" #fbcoveroption="ngModel" name="fbCoverOption" id="fbCoverOption"
                            class="form-control" required (ngModelChange)="onChangeFeedbackCoverOption()">
                                <mat-option *ngFor="let fcOpt of feedbackCoverOptions" [value]="fcOpt.val">
                                    {{ fcOpt.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="fbcoveroption.hasError('required');" class="help-block small animated fadeInDown">
                                You did not select the option!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="isListDecided">
                    <div class="col-lg-12">
                        <mat-form-field color="accent" appearance="outline" class="md-block">
                            <mat-select placeholder="{{labelNameOfList}}" [(ngModel)]="fbModel.fbCoverObject" #fbcoverobject="ngModel" name="fbcoverobject" id="fbcoverobject"
                            class="form-control" required (selectionChange)="onChangefbCoverObject()">
                                <mat-option *ngFor="let fcobjOpt of listOfClassesorPostings" [value]="fcobjOpt">
                                    {{ fcobjOpt.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="fbcoverobject.hasError('required');" class="help-block small animated fadeInDown">
                                You did not select the option!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="isStudentListRequired">
                    <div class="col-lg-12">
                        <mat-form-field color="accent" appearance="outline" class="md-block">
                            <mat-select placeholder="Select Student" [(ngModel)]="fbModel.fbStudent" #fbstudent="ngModel" name="fbStudent" id="fbStudent" class="form-control" required (selectionChange)="onChangeStudent()">
                                <mat-option *ngFor="let slt of studentList" [value]="slt">
                                    {{ slt.student_name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="fbstudent.hasError('required');" class="help-block small animated fadeInDown">
                                Please select the student!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="isOpportunityListRequired">
                    <div class="col-lg-12">
                        <mat-form-field color="accent" appearance="outline" class="md-block">
                            <mat-select placeholder="Select Posting" [(ngModel)]="fbModel.fbOpportunity" #fbopportunity="ngModel" name="fbOpportunity" id="fbOpportunity" class="form-control" required (selectionChange)="onChangeOpportunity()">
                                <mat-option *ngFor="let opp of opportunityList" [value]="opp">
                                    {{ opp.title }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="fbopportunity.hasError('required');" class="help-block small animated fadeInDown">
                                Please select the posting!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-select placeholder="Select Feedback Template" [(ngModel)]="selectedTemplateName" #templateName="ngModel" name="templateName"
                                class="form-control" required>
                                <mat-option *ngFor="let template of templates;let last=last;" [value]="template.name">
                                        {{template.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="templateName.hasError('required');" class="help-block small animated fadeInDown">
                                You did not select the feedback template!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card>

            <mat-tab-group *ngIf="showPages == 2"  (selectChange)="setShowChoices($event)" >
                <mat-tab class="tab-weight" label="Skills">
                    <mat-card>
                            <em>Enter Skills that your student should be rated on, Separated by Comma.</em>
                        <tag-input [(ngModel)]="selectedTemplate[0].stars" name="templateSkills"  [allowDupes]="false"
                        [placeholder]="'Add More Skills'" [displayBy]="'name'"
                        [identifyBy]="'name'" [secondaryPlaceholder]="'Enter Skills Here'" class="mat-form-field" [separatorKeyCodes]="keys">
                        </tag-input>
                    </mat-card>
                </mat-tab>
                <mat-tab class="tab-weight" label="Questions" >
                    <mat-card>
                        <form>
                            <mat-form-field color="accent" appearance="outline" class="md-block" *ngFor="let question of selectedTemplate[0].questions;let i=index">
                                <input placeholder="Enter a Question"  matInput name="questionName{{i}}" i type="text" [(ngModel)]="question.name" #questionName="ngModel"  >
                            </mat-form-field>
                        </form>
                        <div class="pull-right">
                            <i class="fa fa-plus fa-lg fa-border" aria-hidden="true" (click)="addOptions(showChoices)" matTooltip="Add another field.">
                            </i>

                            <i class="fa fa-minus fa-lg fa-border" aria-hidden="true" (click)="removeOption(showChoices)" matTooltip="Delete the last option.">
                            </i>
                        </div>
                    </mat-card>
                </mat-tab>

                <mat-tab class="tab-weight" label="Check Boxes" >
                    <mat-card>
                        <form>
                            <div class="col-lg-12" *ngFor="let checkBox of selectedTemplate[0].checkboxes;let i=index">
                                <mat-form-field color="accent" appearance="outline" class="md-block col-lg-8" flex-gt-xs>
                                    <input placeholder="Type your Question"  matInput name="checkBoxQuestion{{i}}"  type="text" [(ngModel)]="checkBox.question"  spellcheck="true" >
                                </mat-form-field>
                                <div class="col-lg-5">
                                    <div *ngFor="let option of checkBox.boxes;let inneri=index">
                                        <mat-checkbox [disabled]="true"  class="col-lg-1" style="margin-top: 24px" >
                                        </mat-checkbox>
                                        <mat-form-field color="accent" appearance="outline" class="md-block col-lg-11">
                                            <div class="col-lg-8 strip-margins no-padding">
                                                <input type="text" placeholder="Option" matInput spellcheck="true" [(ngModel)]="option.name" name="optionName{{inneri}}{{i}}" >
                                            </div>
                                            <div class="col-lg-3 strip-margins" style="padding-top:5px;">
                                                <i class="fa fa-times-circle-o col-lg-2" aria-hidden="true" (click)="removeOption(showChoices+1, option, checkBox)" matTooltip="Delete The Option.">

                                                </i>
                                            </div>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-lg-8 strip-margins" style="margin-left:17px;">
                                    <i class="fa fa-plus-square-o fa-lg" aria-hidden="true" (click)="addOptions(showChoices+1,i)" matTooltip="Add more option.">
                                    </i>
                                </div>

                            </div>
                        </form>
                        <div class="pull-right" style="margin-top:5px;">
                            <i class="fa fa-plus fa-lg fa-border" aria-hidden="true" (click)="addOptions(showChoices)" matTooltip="Add another field.">
                            </i>
                            <i class="fa fa-minus fa-lg fa-border" aria-hidden="true" (click)="removeOption(showChoices)" matTooltip=">Delete the last option.">
                            </i>
                        </div>
                    </mat-card>
                </mat-tab>


                <mat-tab class="tab-weight" label="Options" >
                    <mat-card>
                        <form>
                            <div class="row" >
                                <div class="col-md-6" *ngIf= "showSaveTemplate">
                                    <mat-slide-toggle  aria-label="Switch No Ink" [(ngModel)]="saveAsTemplate" name="saveAsTemplate" id="saveAsTemplate">
                                    <span class="heading">Save this as a template?</span>
                                    </mat-slide-toggle>
                                    <mat-form-field color="accent" appearance="outline" class="md-block" style="margin-top:10px;"  *ngIf = "saveAsTemplate">
                                        <input type="text" placeholder="Template Name" matInput [(ngModel)]="formName" name="formName" >
                                    </mat-form-field>

                                </div>
                                <div class="col-md-6">
                                        <div layout-align="end start">
                                                <mat-form-field color="accent" appearance="outline" layout-align="end">
                                                    <input matInput  [matDatepicker]= "startDatepicker"  name="dueDate"  [(ngModel)]="templatesDueDate" required placeholder="Due date"  [min]="minDate" >
                                                    <mat-datepicker-toggle matSuffix [for]="startDatepicker"  style="margin-left:3vw;"> </mat-datepicker-toggle>
                                                    <mat-datepicker #startDatepicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                </div>





                            </div>

                        </form>
                    </mat-card>
                </mat-tab>
            </mat-tab-group>

            <mat-card class="md-padding panel-body" *ngIf="showPages == 3">
				<div class="row">
					<div class="col-lg-6">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<input matInput name="feedbackEmailTo" placeholder="To" id="fbfeedbackEmailToto" type="text" [(ngModel)]="feedbackEmailTemplate.to" #fbToModel="ngModel" minlength="3"
							 maxlength="65" required>
							<mat-error *ngIf="fbToModel.hasError('required');" class="help-block small animated fadeInDown">
								Email is required for feedback request.
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-lg-6">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<input matInput name="feedbackEmailSubj" placeholder="Subject" id="feedbackEmailSubj" type="text" [(ngModel)]="feedbackEmailTemplate.subject" #fbSubjectModel="ngModel"
							 minlength="3" maxlength="65" required>
							<mat-error *ngIf="fbSubjectModel.hasError('required');" class="help-block small animated fadeInDown">
								Subject is required for feedback request.
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 add_sk">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<textarea matInput name="feedbackEmailMessage" id="feedbackEmailMessage" [(ngModel)]="feedbackEmailTemplate.message" #fbMessageModel="ngModel" rows="8" md-select-on-focus
							 class="text_areat" matAutosizeMinRows="0" placeholder="Body" matAutosizeMaxRows="10" matTextareaAutosize md-select-on-focus required></textarea>
						</mat-form-field>
						<mat-error *ngIf="fbMessageModel.hasError('required');" class="help-block small animated fadeInDown">
							Message body is required for feedback request.
						</mat-error>
						<div class="clearfix"></div>
					</div>
				</div>
			</mat-card>
		</mat-dialog-content>

		<mat-dialog-actions layout="row" class="footer_popUp posting_pop">
            <div class="col-lg-12">
                <button *ngIf="showPages == 1" mat-raised-button (click)="goNext()" class="pull-right" color="primary" [disabled]="isCreatingFeedback || !newFeedbackForm.valid || templatesDueDate== ''">Next</button>

                <button *ngIf="showPages == 2" mat-raised-button (click)="goBack()" class="pull-left" color="primary" [disabled]="isCreatingFeedback">Prev</button>

                <button *ngIf="showPages == 2" mat-raised-button (click)="createFeedback()" class="pull-right" color="primary" [disabled]="isCreatingFeedback || !newFeedbackForm.valid || !templatesDueDate">Submit</button>
                <button *ngIf="showPages == 3" mat-raised-button (click)="requestFeedback()" class="pull-right" color="primary" [disabled]="isRequestingFeedback || !newFeedbackForm.valid || !templatesDueDate">Submit</button>
                <mat-progress-spinner style="position: absolute !important;
							top: 0;bottom: 0;margin: auto;right: 0;left: 0;" *ngIf="isCreatingFeedback || isRequestingFeedback"
								class="tjs-spinner login"
								[color]="color"
								[mode]="mode"
								[value]="value"
								[diameter]="20">
							</mat-progress-spinner>
            </div>
		</mat-dialog-actions>
    </form>
</div>
