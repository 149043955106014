import { Component, OnInit } from '@angular/core';
//import { Router } from '@angular/router';
import { UserInfoService } from '../../../Services/user-info.service';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
  selector: 'app-new-feedback',
  templateUrl: './new-feedback.component.html',
  styleUrls: ['./new-feedback.component.css']
})
export class NewFeedbackComponent implements OnInit {
  public userType: any;
  public cls = {
		startDate: new Date(),
		endDate: new Date(),
		name: '',
		ident: '',
		description: '',
	};

  public fbModel = {
	requestOption: '',
	fbCoverOption: 0,
	fbCoverObject: null, //either internship or class or organization
	fbStudent: null,
	fbOpportunity: null
  };
  public showChoices = 1;
  public showPages = 1;
  public fbCoverObject: any;
  public selectedTemplate = [];
  public templatesDueDate: any = null;
  public isCreatingFeedback = false;
  public isRequestingFeedback = false;

  requestOptions = [{'name': 'Requesting'}, {'name': 'Reviewing'}];
  feedbackCoverOptions = [];
  public inprogress = false;
	public descriptionClass = 150;
  public templates = [];
  public formName = '';
  public saveAsTemplate = false;
  public showSaveTemplate = false;
  public selectedTemplateName = '';
  public chips = ['Unknown', 'OhYa'];
  public keys = [188];

  public createdForm = {
		stars: [],
		questions: [{
			name:'',
			content:''
		}],
		checkboxes: [{
			question:'',
			boxes: [{
				name:'',
				content: 0
			}]
		}]
  };
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
  isListDecided = false;
  isStudentListRequired = false;
  isOpportunityListRequired = false;
  labelNameOfList = ''; //dynamic label
  listOfClassesorPostings = []; //general list for classes or Internships or Postings but that will be dynamically changed
  studentList = [];
  opportunityList = [];
  feedbackTemplates: any;
  option: any;
  index: any;
  item: any;
  cBox: any;
  tmp = {};
  feedbackEmailTemplate: any = {
		"to":'',
		"name": '',
		"message":'',
		"subject":''
  };
  studentId: any = 0;
  opportunityId: any = 0;
  classId: any = 0;

  reviewer: any = null;
  opportunityStudentId: any = null;
  classInternshipId: any = null;
  studentOrganizationId: any = null;
  feedbackCreatedId: any = null;

  constructor(private _talentApiService: TalentApiService, private _userInfoService: UserInfoService, private dialogRef: MatDialogRef<NewFeedbackComponent>) { }

	ngOnInit() {
		this.getTemplates();

		//set the options of Feedback to cover based on the user login
		switch (this.userType) {
			case 3: //For student
			case '3':
				this.studentId = this._userInfoService.getUser().id;
			this.feedbackCoverOptions = [
				{'name': 'Internship or Posting', 'val': 1},
				{'name': 'Class', 'val': 2},
				//{'name':'Organization','val': 3} //commented as the organization is not in the picture. Once available remove this comment
				];
			break;
			case 5: //For employer
			case '5':
			this.feedbackCoverOptions = [{'name': 'Posting', 'val': 4}];
			break;
			case 2: //For schoool user (Instructor)
			case '2':
			this.feedbackCoverOptions = [{'name': 'Class', 'val': 5}];
			break;
			default:
			break;
		}

	}

  getTemplates() {
	this.selectedTemplate = [];

	this._talentApiService.getMyFeedbackTemplate().subscribe((result: any) => {
		this.showChoices = 1;
		this.feedbackTemplates = result;
		var self = this;
		this.feedbackTemplates.forEach(function(form, index, array) {

			self.templates.push(JSON.parse(form['form']));
			self.templates[index].name = form.name;
			self.templates[index].id = form.id;

		});
		this.templates.push({name:'Custom Template', id: 0});
	}, error => {
		console.log('Error Loading getMyStudentInternships');
	});
  }

  onChangeFeedbackCoverOption() {
		this.isListDecided = false;
		this.labelNameOfList = '';
		this.listOfClassesorPostings = [];
		switch (this.fbModel.fbCoverOption) {
			case 1: //student requests a feedback from employer on any Posting(or Internship)
			this.labelNameOfList = 'Select Internship or Posting';
			break;
			case 2: //student requests a feedback from supervisor on any class
			this.labelNameOfList = 'Select Class-Internship';
			break;
			case 3: //student requests a feedback from organization
			this.labelNameOfList = 'Select Organization';
			break;
			case 4: //employer requests a feedback from student on any postings(for which he is the owner)
			this.labelNameOfList = 'Select Posting';
			break;
			case 5: //school user(instructor) requests a feedback from student on any class
			this.labelNameOfList = 'Select Class';
			break;
			default:
			break;
		}
		this._talentApiService.getListOfFeedbackCreateOptions(this.fbModel.fbCoverOption).subscribe(
			(result: any) => {
			if (result) {
				this.listOfClassesorPostings = result;
				this.isListDecided = true;
			} else {
              console.log("Something went wrong while fetching Feedback create options");
            }
			},
			error => {
				console.log('Some error occurred while fetching Feedback create options');
			}
		);

  }

  onChangefbCoverObject() {

	this.setFeedbackInfo();
	this.isStudentListRequired = false;
	//check whether student list will be required or not
	switch (this.userType) {
		case 3: //For student
		case '3':
			break;
		case 5: //For employer
		case '5':
		case 2: //For schoool user (Instructor)
		case '2':
			this.getStudentList();
			break;
		default:
			break;
	}


		// this.isStudentListRequired = false;
		// this.studentList = [];
		// switch(this.userType)
		// {
		//     case 5: //For employer
		//     case '5':
		//       break;
		//     case 2: //For schoool user (Instructor)
		//     case '2':
		//       break;
		//     default:
		//       break;
		// }
		// this._talentApiService.getListOfFeedbackCreateOptions(this.fbModel.fbCoverOption).subscribe(
		//     (result:any) => {
		//       if(result)
		//       {
		//           this.listOfClassesorPostings = result;
		//           this.isListDecided = true;
		//       }
		//       else
		//         console.log("Something went wrong while fetching Feedback create options");
		//     },
		//     error => {
		//         console.log("Some error occurred while fetching Feedback create options");
		//     }
		//   );

  }

  getStudentList() {
		this.isStudentListRequired = true;
		//get student list based on either opportunity id or class id
		this._talentApiService.getStudentListForFeedback(this.opportunityId, this.classId).subscribe(
			(result: any) => {
			if (result) {
				this.studentList = result;
			} else {
              console.log("Something went wrong while fetching Feedback create options");
            }
			},
			error => {
				console.log('Some error occurred while fetching Feedback create options');
			}
		);

		//on select of student get opporutityStudentId or classInternshipId
  }

  onChangeStudent() {
		this.isOpportunityListRequired = false;

		//update the reviewer because school user and employer user wants to request the feedback from student
		this.reviewer = this.fbModel.fbStudent.user_id;

		//if school user, it's required to get opportunity list for which the instructor wants to request feedback
		if (this.userType == '1' || this.userType == 1) {
			this.isOpportunityListRequired = true;
			this._talentApiService.getOpportunityListForFeedback(this.classId, this.fbModel.fbStudent.student_id).subscribe(
			(result: any) => {
			if (result) {
				this.opportunityList = result;
			} else {
              console.log("Something went wrong while fetching Opportunity list");
            }
			},
			error => {
				console.log('Some error occurred while fetching Opportunity list');
			}
		);
		} else if (this.userType == '3' || this.userType == 3) {
			this._talentApiService.getOpportunityStudent(this.opportunityId, this.fbModel.fbStudent.student_id).subscribe(
			(result: any) => {
				if (result) {
					this.opportunityStudentId = result.id;
				} else {
					this._talentApiService.snackbarMessage('Something went wrong while getting opportunity student');
                }
			},
			error => {
				this._talentApiService.snackbarMessage('Some error occurred while getting opportunity student');
			}
			);
		}
  }

  onChangeOpportunity() {
		//get class internship id based on class-id, student-id and opportunity-id
		this._talentApiService.getClassInternship(this.classId, this.fbModel.fbStudent.student_id, this.fbModel.fbOpportunity.id).subscribe(
			(result: any) => {
			if (result) {
				this.classInternshipId = result.id;
			} else {
				this._talentApiService.snackbarMessage('Something went wrong while getting class internship');
            }
			},
			error => {
				this._talentApiService.snackbarMessage('Some error occurred while getting class internship');
			}
		);
  }

	cancel() {
		this.dialogRef.close();
	}


  showSelectedTemplate() {
	this.selectedTemplate = [];
	if (this.selectedTemplateName == 'Custom Template') {
			//vm.selectedTemplate = [];
			this.selectedTemplate.push(this.createdForm);
			this.showSaveTemplate = true;
		} else {
			var self = this;
			this.templates.forEach(function(item, index, array) {
				if (item.name == self.selectedTemplateName) {
					//self.tmp = Object.assign({},item);
					self.tmp = JSON.parse(JSON.stringify(item));
					//angular.copy(item, this.tmp);
					self.selectedTemplate.push(item);
				}
			});

		}
		this.showChoices = 2;
  }

  goBack() {
		this.showPages--;
  }

  goNext() {
		if (this.showPages == 1) {
			this.showSelectedTemplate();
		}
		this.showPages++;
  }

  setShowChoices(event) {
		console.log(event.index);
		console.log(this.showChoices);
		if (event.index == 1) { // QUESTIONS
			this.showChoices = 2;
		} else if (event.index == 2) { //CHECK BOXES
			this.showChoices = 3;
		} else if (event.index == 3) {//OPTIONS
			this.checkTemplates();
		}
  }

  checkTemplates() {
		var tempSelectedTemplate = JSON.stringify(this.selectedTemplate[0]);
		var tempAssignedTemplate = JSON.stringify(this.tmp);
		if (tempSelectedTemplate != tempAssignedTemplate) {
			this.showSaveTemplate = true;
		} else {
			this.showSaveTemplate = false;
		}
		this.showChoices = 4;
  }

  addOptions(option, indx) {
	this.option = option;
	this.index = indx;
	switch (this.option) {
		// this has to start from case 2 as our option 1 is to just show the template selector dialog
		case 2:
			this.selectedTemplate[0].questions.push({name: '', content: ''});
			break;
		case 3:
			this.selectedTemplate[0].checkboxes.push({question: '', boxes: [{name:'', content: 0}]});
			break;
		case 4:
			this.selectedTemplate[0].checkboxes[this.index].boxes.push({name:'', content: 0});
			break;
		default:
			console.log('wrong input');
	}
  }

  removeOption(num, itm, chkBox) {
	this.option = num;
	this.item = itm;
	this.cBox = chkBox;
	switch (this.option) {
		case 2:
			var lastItem = this.selectedTemplate[0].questions.length - 1;
			this.selectedTemplate[0].questions.splice(lastItem);
			break;
		case 3:
			var lastItem = this.selectedTemplate[0].checkboxes.length - 1;
			this.selectedTemplate[0].checkboxes.splice(lastItem);
			break;
		case 4:
			var lastIndex = this.cBox.boxes.indexOf(this.item);
			this.cBox.boxes.splice(lastIndex, 1);
			break;
		default:
			console.log('something wrong');
	}

  }

  setFeedbackInfo() {
		this.opportunityStudentId = null;
		this.classInternshipId = null;
		this.studentOrganizationId = null;

		this.opportunityId = null;
		this.classId = null;

		this.reviewer = null;
		/* decide the feedback base info */
		switch (this.fbModel.fbCoverOption) {
			case 1: //student requests a feedback from employer on any Posting(or Internship)
			//set opportunity-student id
			this.opportunityStudentId = this.fbModel.fbCoverObject.id;
			this.reviewer = this.fbModel.fbCoverObject.reviewer;
			break;
			case 2: //student requests a feedback from supervisor on any class
			//set the class internship id
			this.classInternshipId = this.fbModel.fbCoverObject.id;
			this.reviewer = this.fbModel.fbCoverObject.reviewer;
			break;
			case 3: //student requests a feedback from organization
			break;
			case 4: //employer requests a feedback from student on any postings(for which he is the owner)
			this.opportunityId = this.fbModel.fbCoverObject.id;
			break;
			case 5: //school user(instructor) requests a feedback from student on any class
			this.classId = this.fbModel.fbCoverObject.id;
			break;
			default:
			break;
		}
  }

  createFeedback() {

		//set the data object
		let feedbackData = {
			'form' : this.selectedTemplate[0],
			'due_date' : this.templatesDueDate,
			'reviewer': this.reviewer,
			'opportunity_student_id': this.opportunityStudentId,
			'class_internship_id': this.classInternshipId,
			'student_organization_id': this.studentOrganizationId
		};
		this.isCreatingFeedback = true;
		this._talentApiService.createNewFeedback(feedbackData).subscribe((result: any) => {
			this.feedbackCreatedId = result.feedback_id;
			if (this.saveAsTemplate) {
				this._talentApiService.createFeedbackTemplate(this.selectedTemplate[0], this.formName).subscribe((result: any) => {
					//this.dialogRef.close();
					//after finishing the create feedback call, show email request form to send email to the reviewer
					this._talentApiService.snackbarMessage('Template saved and feedback created');
					this.isCreatingFeedback = false;
					this.showRequestFeedback();
				}
				, function errorCallback() {
					//this.dialogRef.close();
					this.isCreatingFeedback = false;
					this._talentApiService.snackbarMessage('Something went wrong when saving template!');
				});
			} else {
				this.isCreatingFeedback = false;
				this._talentApiService.snackbarMessage('Feedback created successfully.');
				this.showRequestFeedback();
				//this.dialogRef.close();
			}
		}, error => {
			this.isCreatingFeedback = false;
			this._talentApiService.snackbarMessage('Something went wrong. Please check form and try again.');
		});
  }

  showRequestFeedback() {
		this._talentApiService.getFeedbackEmailTemplateByFeedback(this.feedbackCreatedId)
			.subscribe(
				(result: any) => {
					this.feedbackEmailTemplate = result;
					this.showPages = 3;
				},
				error => {
					console.log('error getting Feedback Email Template' + error);
				}
			);

  }

  requestFeedback() {
		this.isRequestingFeedback = true;
		this._talentApiService.sendNewFeedback(this.feedbackCreatedId, this.feedbackEmailTemplate)
			.subscribe(
				(result: any) => {
					this._talentApiService.snackbarMessage('Feedback requested successfully');
					this.isRequestingFeedback = false;
					this.dialogRef.close();
				},
				error => {
					this.isRequestingFeedback = false;
					this._talentApiService.snackbarMessage('Something went wrong when requesting feedback!');
				}
			);
		console.log('Request feedback called');
  }

}
