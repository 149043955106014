<div fxLayout="row wrap" fxFlex="100" class="padding-around">
  <div fxFlex="100">
    <div fxFlex="90" fxFlex.xs="80" fxLayout="row">
      <h1 mat-dialog-title>
        New Notifications&nbsp;&nbsp;&nbsp;<span matBadge="{{newCount}}"> </span>
      </h1>
    </div>
    <div fxFlex="10" fxFlex.xs="20" fxLayoutAlign="center start">
      <button mat-icon-button (click)="cancel()" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider fxFlex="100" [inset]="true"></mat-divider>
  <div fxLayout="row wrap" fxFlex="100">  
      <app-notifications fxFlex="100"
                         [showAllNotification]="viewAllNotification">
      </app-notifications> 
  </div>
</div>
