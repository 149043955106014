<h1 mat-dialog-title>
    <ng-container *ngIf="data.type=='create'">Create a new email template</ng-container>
    <ng-container *ngIf="data.type=='delete'">Delete email template</ng-container>
    <ng-container *ngIf="data.type=='edit'">Edit email template</ng-container>
</h1>
<div mat-dialog-content>
    <ng-container *ngIf="data.type=='delete'">Are you sure you want to delete this email template?</ng-container>
    <ng-container *ngIf="data.type=='create' || data.type=='edit'">
        <form [formGroup]='form'>
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div fxLayout="column" fxFlex.md="48" fxFlex.gt-md="48" fxFlex.lt-md="100">
                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>Template Name</mat-label>
                        <input matInput type="text" name="template_name" id="template_name" formControlName="template_name">
                        <mat-error *ngIf="form.get('template_name').hasError('required')">
                            Please enter a name for the email template
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>Subject</mat-label>
                        <input matInput type="text" name="subject" id="subject" formControlName="subject">
                        <mat-error *ngIf="form.get('subject').hasError('required')">
                            Please enter a subject
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex.md="48" fxFlex.gt-md="48" fxFlex.lt-md="100">
                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>Sender</mat-label>
                        <input matInput type="text" name="sender" id="sender" formControlName="sender">
                        <mat-error *ngIf="form.get('sender').hasError('required')">
                            Please enter an email address for the sender
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>Sender Password</mat-label>
                        <input matInput type="text" name="sender_password" id="sender_password" formControlName="sender_password">
                        <mat-error *ngIf="form.get('sender_password').hasError('required')">
                            Please enter a password for the sender
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-checkbox name="html" id="html" formControlName="html">HTML Template?</mat-checkbox>
                <mat-form-field color="accent">
                    <mat-label>Body</mat-label>
                    <textarea matInput name="body" id="body" formControlName="body"></textarea>
                </mat-form-field>
            </div>
        </form>
    </ng-container>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
    <button *ngIf="data.type=='delete'" mat-button (click)="deleteEmailTemplate()" matDialogClose>Yes</button>
    <button *ngIf="data.type=='create'" mat-button (click)="createEmailTemplate()" [disabled]="!form.valid" matDialogClose>Create</button>
    <button *ngIf="data.type=='edit'" mat-button (click)="editEmailTemplate()" [disabled]="!form.valid" matDialogClose>Submit</button>
    <button mat-button matDialogClose>Cancel</button>
</div>