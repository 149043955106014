<div class="candidate-info-containter">
  <div fxLayout="row">
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
      <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
    </div>
    <div fxLayout="column" fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90">      
        <div fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="column" fxFlex="90" fxFlex.lt-md="80">
            <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Diversity and Inclusion Survey</h1>
          </div>
          <div fxLayout="column" fxFlex="5" fxFlex.lt-md="20">
            <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <div>
            <h4>
              Providing this information is voluntary, and your applications will not be affected if you respond or decline to respond.
              All information will be kept confidential and will be
              used only in connection with HipJobJuice's site administration.
            </h4>
          </div>
        </div><br />
        <div fxLayout="row">
          <strong>Gender</strong>
        </div>
        <div fxLayout="row">
          <mat-radio-group fxLayout="column wrap" fxLayoutAlign="space-between">
            <mat-radio-button *ngFor="let g of genderList;"
                              class="topPadding"
                              [checked] ="(diversityQuestion.gender===g.id ? true :false)"
                              (change)="genderRadioChange($event)"
                              value="{{g.id}}">{{g.name}}</mat-radio-button>
          </mat-radio-group>
        </div><br />
        <div fxLayout="row" class="topPadding">
          <strong>Ethnicity</strong>
        </div>
        <div fxLayout="row">
          <mat-radio-group fxLayout="column wrap" fxLayoutAlign="space-between">
            <mat-radio-button *ngFor="let e of ethnicityList;"
                              [checked] ="(diversityQuestion.ethnicity===e.id ? true :false)"
                              class="topPadding" (change)="ethnicityRadioChange($event)" value="{{e.id}}">{{e.name}}</mat-radio-button>
          </mat-radio-group>
        </div><br />
        <div fxLayout="row" class="topPadding">
          <strong>Military Status</strong>
        </div>
        <div fxLayout="row">
          <mat-radio-group fxLayout="column wrap" fxLayoutAlign="space-between">
            <mat-radio-button *ngFor="let e of militaryStatusList;" [checked] ="(diversityQuestion.military_status===e.id ? true :false)" class="topPadding" (change)="militaryRadioChange($event)" value="{{e.id}}">{{e.name}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxLayout="row wrap" class="topPadding" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="15px">
          <button fxFlex="no-grow" type="button" mat-raised-button color="primary"
                  [disabled]="(this.diversityQuestion.ethnicity===0 && this.diversityQuestion.gender===0 && this.diversityQuestion.military_status===0)"
                  (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
          <button fxFlex="no-grow" *ngIf="edit" type="button" mat-stroked-button color="primary" (click)="cancel()">CANCEL</button>
        </div>
    </div>
  </div>
</div>
