import { Component, OnInit,Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { AdminApiService } from '../../Services/admin-api.service';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog ,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TalentApiService } from '../../Services/talent-api.service';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
declare var $: any;

@Component({
	selector: 'app-admin-logo',
	templateUrl: './admin-logo.component.html',
	styleUrls: ['./admin-logo.component.css']
})
export class AdminLogoComponent implements OnInit {

	// Init variables
	public editing = false;
	public loading = false;
	public submitting = false;
	public isLoadingDefaultLogo = false;
	public fieldList = {};
	public isClearbitLogoAvailable = false;
	public isLogoAvailable = false;
	public isClearbitLoading = false;
	public imageError = false;
	public noLogo = '../../../assets/img/nologo.png';
	public imageChangedEvent: any;

	public logoFile = {
		mimetype: '',
		encoding: '',
		content: null,
		name: '',
		size: 0
	};
	public logoPreview: any;
	public organization = {
		id: '',
		name: '',
		type: '',
		fkLogo: ''
	};
	public logoId: any;
	public defaultLogoType: any;
	public clearInfo: any;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;

	// DataTable Setup
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	orgLogo: any;
	list = {};

	constructor(private _adminApiService: AdminApiService, private _router: Router, public _dialog: MatDialog, private _talentApiService: TalentApiService) { }

	ngOnInit() {
		this.getOrgsorgLogo();
		jQuery.noConflict();
	}

	getOrgsorgLogo() {
		$('#admincompanylogos').DataTable().destroy();

		this.dtOptions = {
			pagingType: 'full_numbers',
			pageLength: 10,
			scrollX: false,
			order: [[1, 'asc']],
			columnDefs: [
				{
					"targets": [ 3 ],
					"searchable": false,
					"orderable": false
				}
			]
		};

		this._adminApiService.urlOrganizationsList()
			.subscribe(
				(result:any) => {
					this.orgLogo = result["getOrganizationList"];
          this.dtTrigger.next({});
					var that = this;
					this.orgLogo.map((element, i) => {
						that.list[element.id] = element;
					});
				},
				error => {
					console.log('Could not load Orgs orgLogo');
				}
			);
	}

	openDefaultLogoModal(type) {
		const dialogRef = this._dialog.open(DefaultLogoDialog, {
			height: 'auto',
			width: '25%',
			data: type
		});
	}
	openReviewModal(orgLogo) {
		const dialogRef = this._dialog.open(ReviewLogoDialog, {
			height: 'auto',
			width: '25%',
			data: orgLogo
		});
	}

	fileChangeEvent(event: any): void {
		let fileExtension = event.target.value.replace(/^.*\./, '');
		switch (fileExtension) {
			case 'png': case 'jpeg': case 'jpg':
				this.imageError = false;
				if (event.target.files && event.target.files[0]) {
					var reader = new FileReader();

					this.logoFile.size = event.target.files[0].size / 1024;
					if (this.logoFile.size > 2048) {
						this.logoFile = {
							mimetype: '',
							encoding: '',
							content: '',
							name: '',
							size: 0
						};
						this.logoPreview = this.noLogo;
						this.imageError = true;
						this._talentApiService.snackbarMessage("Only JPG, PNG or Gif images are allowed with file size of 2MB only.");
						break;
					}
					this.logoFile.mimetype = event.target.files[0].type;
					this.logoFile.name = event.target.files[0].name;
					this.logoFile.size = event.target.files[0].size / 1024;
					this.logoFile.encoding = 'base64';

					reader.onload = (event: ProgressEvent) => {
						this.logoFile.content = reader.result;
						//this.logoFile.content = this.logoFile.content.replace(/^data:image\/[a-z]+;base64,/, "");
						this.logoPreview = (<FileReader>event.target).result;
					}
					reader.readAsDataURL(event.target.files[0]);
					//clear the file object in order to trigger the change event if the same file was uploaded again
					event.target.value = null;
				}
				break;
			default:
				this.imageError = true;
				//cleaning the logoFile object
				this.logoFile = {
					mimetype: '',
					encoding: '',
					content: '',
					name: '',
					size: 0
				};
				this.logoPreview = this.noLogo;
				break;
		}
	}

	
	
}
export interface orgLogo{
	type: string,
	id: string,
	name: string,
	organization_type: string,
	fk_logo: string
	 
  }
  @Component({
	templateUrl: './admin-review-logo.component.html',
	styleUrls: ['./admin-logo.component.css']
  })
  export class ReviewLogoDialog {
	// Init variables
	public editing = false;
	public loading = false;
	public submitting = false;
	public isLoadingDefaultLogo = false;
	public fieldList = {};
	public isClearbitLogoAvailable = false;
	public isLogoAvailable = false;
	public isClearbitLoading = false;
	public imageError = false;
	public noLogo = '../../../assets/img/nologo.png';
	public imageChangedEvent: any;

	public logoFile = {
		mimetype: '',
		encoding: '',
		content: null,
		name: '',
		size: 0
	};
	public logoPreview: any;
	public organization = {
		id: '',
		name: '',
		type: '',
		fkLogo: ''
	};
	public logoId: any;
	public defaultLogoType: any;
	public clearInfo: any;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;

	constructor(
		private _talentApiService: TalentApiService, private _adminApiService: AdminApiService,
		public dialogRef: MatDialogRef<DefaultLogoDialog>,	@Inject(MAT_DIALOG_DATA) public orgLogo: orgLogo, private sharedUtils: SharedUtilitiesService
	  ) 
	  {
		this.logoPreview = this.noLogo;
		this.isClearbitLoading = true;
		this.isClearbitLogoAvailable = false;
		this.isLogoAvailable = false;
		this.imageError = false;

		this.organization.id = orgLogo.id;
		this.organization.name = orgLogo.name;
		this.organization.type = orgLogo.organization_type;
		this.organization.fkLogo = orgLogo.fk_logo;

		let that = this;
		this.sharedUtils.getOrganizationLogo(this.organization.name, this.organization.type)
			.subscribe(
				(result:any) => {
					if(result) {
						that.logoPreview = result;
					} else {
						this.logoPreview ='../../../assets/img/nologo.png';
					}
					this.isClearbitLoading = false;
				},
				error => {
					this.isClearbitLoading = false;
					console.log('Error getting logo from Clearbit.'+error);
				}
			);

	  }  

fileChangeEvent(event: any): void {
	let fileExtension = event.target.value.replace(/^.*\./, '');
	switch (fileExtension) {
		case 'png': case 'jpeg': case 'jpg':
			this.imageError = false;
			if (event.target.files && event.target.files[0]) {
				var reader = new FileReader();

				this.logoFile.size = event.target.files[0].size / 1024;
				if (this.logoFile.size > 2048) {
					this.logoFile = {
						mimetype: '',
						encoding: '',
						content: '',
						name: '',
						size: 0
					};
					this.logoPreview = this.noLogo;
					this.imageError = true;
					this._talentApiService.snackbarMessage("Only JPG, PNG or Gif images are allowed with file size of 2MB only.");
					break;
				}
				this.logoFile.mimetype = event.target.files[0].type;
				this.logoFile.name = event.target.files[0].name;
				this.logoFile.size = event.target.files[0].size / 1024;
				this.logoFile.encoding = 'base64';

				reader.onload = (event: ProgressEvent) => {
					this.logoFile.content = reader.result;
					//this.logoFile.content = this.logoFile.content.replace(/^data:image\/[a-z]+;base64,/, "");
					this.logoPreview = (<FileReader>event.target).result;
				}
				reader.readAsDataURL(event.target.files[0]);
				//clear the file object in order to trigger the change event if the same file was uploaded again
				event.target.value = null;
			}
			break;
		default:
			this.imageError = true;
			//cleaning the logoFile object
			this.logoFile = {
				mimetype: '',
				encoding: '',
				content: '',
				name: '',
				size: 0
			};
			this.logoPreview = this.noLogo;
			break;
	}
}
submit() {
	this.submitting = true;
	var fileId = this.organization.id;
	var altText = this.organization.name;
	var orgType = this.organization.type;
	this._adminApiService.uploadLogo(this.logoFile, this.logoFile.size, this.logoFile.mimetype, fileId, orgType, altText, this.organization.fkLogo)
		.subscribe(
			(result:any) => {
				$('#upload-modal').modal('hide');
				this._talentApiService.snackbarMessage("Logo for " + orgType + ": " + altText + " uploaded Successfully!");
				this.submitting = false;
				//cleaning the logoFile object
				this.logoFile = {
					mimetype: '',
					encoding: '',
					content: '',
					name: '',
					size: 0
				};					
			},
			error => {
				this._talentApiService.snackbarMessage("Error occurred while submitting logo.");
				this.submitting = false;
			}
		);
		this.dialogRef.close(true);
}

}  
  
export interface logoData{
	type: number,
	 }
@Component({
	// selector: 'get-answers-alert-dialog',	
	templateUrl: './admin-default-logo.component.html',
	styleUrls: ['./admin-logo.component.css']
  })
  export class DefaultLogoDialog {
	public submitting = false;
	public isLoadingDefaultLogo = false;
	public isLogoAvailable = false;
	public imageError = false;
	public noLogo = '../../../assets/img/nologo.png';
	public logoFile = {
		mimetype: '',
		encoding: '',
		content: null,
		name: '',
		size: 0
	};
	public logoPreview: any;
	public logoId: any;
	public defaultLogoType: any;
	
	constructor(
		private _adminApiService: AdminApiService, private _talentApiService: TalentApiService,
		public dialogRef: MatDialogRef<DefaultLogoDialog>,	@Inject(MAT_DIALOG_DATA) public input: logoData
	  ) 
	  {
		  dialogRef.backdropClick().subscribe(result => {
			dialogRef.disableClose = true; //disable default close operation
			dialogRef.close(false);
		  });
	
		this.isLoadingDefaultLogo = true;
		this.logoFile = {
			mimetype: '',
			encoding: '',
			content: '',
			name: '',
			size: 0
		};
		this.logoId = input;
		this.logoPreview = this.noLogo;
		this.imageError = false;
		this.defaultLogoType = (input.type == 1)? 'School' : 'Company';
		this._adminApiService.getLogo(input.toString())
		.subscribe(
			(result:any) => {
				this.isLoadingDefaultLogo = false;
				if(result != ''){
					this.logoPreview = result[0]['logo_image'];
					this.isLogoAvailable = true;
				}
			},
			error => {
				console.log('Logo could not be found from the DB.',error);
			}
		);
		
	}
	
	fileChangeEvent(event: any): void {
		let fileExtension = event.target.value.replace(/^.*\./, '');
		switch (fileExtension) {
			case 'png': case 'jpeg': case 'jpg':
				this.imageError = false;
				if (event.target.files && event.target.files[0]) {
					var reader = new FileReader();

					this.logoFile.size = event.target.files[0].size / 1024;
					if (this.logoFile.size > 2048) {
						this.logoFile = {
							mimetype: '',
							encoding: '',
							content: '',
							name: '',
							size: 0
						};
						this.logoPreview = this.noLogo;
						this.imageError = true;
						this._talentApiService.snackbarMessage("Only JPG, PNG or Gif images are allowed with file size of 2MB only.");
						break;
					}
					this.logoFile.mimetype = event.target.files[0].type;
					this.logoFile.name = event.target.files[0].name;
					this.logoFile.size = event.target.files[0].size / 1024;
					this.logoFile.encoding = 'base64';

					reader.onload = (event: ProgressEvent) => {
						this.logoFile.content = reader.result;
						//this.logoFile.content = this.logoFile.content.replace(/^data:image\/[a-z]+;base64,/, "");
						this.logoPreview = (<FileReader>event.target).result;
					}
					reader.readAsDataURL(event.target.files[0]);
					//clear the file object in order to trigger the change event if the same file was uploaded again
					event.target.value = null;
				}
				break;
			default:
				this.imageError = true;
				//cleaning the logoFile object
				this.logoFile = {
					mimetype: '',
					encoding: '',
					content: '',
					name: '',
					size: 0
				};
				this.logoPreview = this.noLogo;
				break;
		}
	}
	submitDefaultLogo(){
		this.submitting = true;
		this._adminApiService.setDefaultLogo(this.logoFile, this.logoId)
		.subscribe(
			(result:any) => {
				this.logoFile = {
					mimetype: '',
					encoding: '',
					content: '',
					name: '',
					size: 0
				};
				this._talentApiService.snackbarMessage('Default Logo for '+ this.defaultLogoType +' uploaded Successfully!');
				this.submitting = false;
				this.close();
			},
			error => {
				//this.simpleAlertBar.showError("Error occurred while submitting logo.");
				this._talentApiService.snackbarMessage('Error occurred while submitting logo.');
				this.submitting = false;
			}
		);
	}
	close(): void {
	  this.dialogRef.close(true);
	}
  }
