<div class="full-page-registration">
	<div fxLayout="row" fxLayoutAlign="center center">
		<div fxLayout="column" fxFlex="100">
			<div fxLayout="row" fxFlexOffset="30px" fxLayoutAlign="center center">
				<div fxLayout="column" fxFlex="80">
					<mat-card class="mat-typography custom-height-step"
										[ngClass.lt-md]="'custom-height'"
										fxFlex="80"
										fxFlex.gt-sm="80" fxFlex.lt-sm="100">
						<form name="empRegForm"
									class="emp-reg-form"
									id="empRegForm"
									#empRegForm="ngForm"
									novalidate>
							<div [hidden]="!checkActiveStep(0)">
								<div fxLayout="row" fxLayoutAlign="space-between start">
									<div fxLayout="column" fxFlex="100">
										<mat-card-header class="title">
											<h2>Register to access our diverse talent pool</h2>
										</mat-card-header>
									</div>
								</div>
								<div fxLayout="row" class="common-padding">
									<div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
										<mat-card-content>
											<div fxLayout="row">
												<mat-form-field [ngClass]="{'has-error':!companyname.valid && isEmployerRegSubmitted==true}" appearance="outline">
													<mat-label>Company Name</mat-label>
													<input matInput type="text" tabindex="8"
																 [value]="this.companyname.value"
																 placeholder="Company Name"
																 required name="companyname"
																 id="companyname"
																 [matAutocomplete]="autoComp"
																 [formControl]="companyname"
																 (input)="setCompany($event.target.value)">
													<mat-autocomplete #autoComp="matAutocomplete" [displayWith]="addNewOption">
														<mat-option *ngFor="let comp of companySectorQuerySearch(companyname,companyList)"
																				(onSelectionChange)="setCompanyObject(comp,1)"
																				[value]="comp.name"> {{ comp.name }} </mat-option>
													</mat-autocomplete>
													<mat-error *ngIf="companyname.hasError('required')"> You must have a company name. </mat-error>
												</mat-form-field>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100" fxLayoutGap.gt-sm="15px">
													<div fxLayout.lt-md="row wrap" fxLayout="row" fxLayoutGap.gt-sm="30px">
														<div fxFlex="50" fxFlex.lt-md="100">
															<mat-form-field [ngClass]="{'has-error':!employerfirstname.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>First Name</mat-label>
																<input matInput type="text" tabindex="1" placeholder="First Name" required [(ngModel)]="user.firstName" #employerfirstname="ngModel" name="employerfirstname"
																		id="employerfirstname" pattern="[a-zA-Z ]*">
																<mat-error *ngIf="employerfirstname.hasError('required')"> You did not enter First Name </mat-error>
																<mat-error *ngIf="!employerfirstname.pattern && !employerfirstname.hasError('required')"> Please enter valid first name </mat-error>
															</mat-form-field>
														</div>
														<div fxFlex="50" fxFlex.lt-md="100">
															<mat-form-field [ngClass]="{'has-error':!employerlastname.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Last Name</mat-label>
																<input matInput type="text"  tabindex="2" placeholder="Last Name" required [(ngModel)]="user.lastName" #employerlastname="ngModel" name="employerlastname"
																				id="employerlastname" pattern="[a-zA-Z ]*">
																<mat-error *ngIf="employerlastname.hasError('required')"> You did not enter Last Name </mat-error>
																<mat-error *ngIf="!employerlastname.pattern && !employerlastname.hasError('required')"> Please enter valid last name </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100">
													<div fxLayout="row">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error':!employerphone.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Phone</mat-label>
																<input matInput type="text" tabindex="3" placeholder="Phone #" required [(ngModel)]="user.phone" #employerphone="ngModel"
																name="employerphone" id="employerphone" mask="(000) 000-0000" [showMaskTyped]="true" appValidatePhone>			
																<mat-error *ngIf="employerphone.hasError('required')"> You did not enter your phone #. </mat-error>
																<mat-error *ngIf="employerphone.hasError('isPhoneValid')"> Please enter valid phone number </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100">
													<div fxLayout="row">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error': !employeremail.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Email/ Username</mat-label>
																<input matInput type="email" tabindex="4" placeholder="Email/ Username" required [(ngModel)]="user.email" #employeremail="ngModel" name="employeremail"
																				id="employeremail" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" minlength="10" maxlength="100">
																<mat-error  *ngIf="!employeremail.pattern && !employeremail.valid && !employeremail.hasError('required')"> Your email must be between 10 and 100 characters long and look like an e-mail address. </mat-error>
																<mat-error *ngIf="employeremail.hasError('required')"> You did not enter your email address </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100">
													<div fxLayout="row">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error': !employerpassword.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Password</mat-label>
																<input matInput type="password"  tabindex="5" placeholder="Password" maxlength="250" required [(ngModel)]="user.pass" #employerpassword="ngModel" name="employerpassword"
																				id="employerpassword" pattern=".{8,200}">
																<mat-error *ngIf="employerpassword.hasError('required')"> You did not enter your password. </mat-error>
																<mat-error *ngIf="!employerpassword.pattern && !employerpassword.hasError('required')"> Password should be atleast 8 characters long </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100">
													<div fxLayout="row">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error': !employerverifypassword.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Verify Password</mat-label>
																<input matInput type="password" tabindex="6" placeholder="Verify Password" required [(ngModel)]="user.verifiedpass" #employerverifypassword="ngModel"
																				name="employerverifypassword" id="employerverifypassword" [pattern]=employerpassword.value>
																<mat-error *ngIf="employerverifypassword.hasError('required')"> Please verify password. </mat-error>
																<mat-error *ngIf="!employerverifypassword.valid && !employerverifypassword.hasError('required')"> Password must match </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
										</mat-card-content>
										<mat-card-actions>
											<div fxLayout="row">
												<button tabindex="7" type="button" [disabled]="validateStep()" mat-raised-button color="primary" (click)="setStep(1)">
													{{skipSecondStep==true?'Done':'Next'}}
												</button>	
											</div>
										</mat-card-actions>
									</div>
									<div fxFlex="40" fxFlexOffset="10" fxLayout="column" fxLayoutAlign="start stretch" fxHide.lt-md="true">
										<app-logo class="pad-20" [tenant]="tenant" [type]="'employer-registration'"></app-logo>
									</div>
								</div>
							</div>
							<div [hidden]="!checkActiveStep(1)">
								<div fxLayout="row" fxLayoutAlign="space-between start">
									<div fxLayout="column" fxFlex.lt-md="7" fxFlex.xs="9" fxFlex="4" (click)="setStep(0)" id="back" style="cursor:pointer">
										<mat-icon>keyboard_backspace</mat-icon>
									</div>
									<div fxLayout="column" fxFlex="96">
										<h2> Tell us more about your organization</h2>
									</div>
								</div>
								<div fxLayout="row wrap" class="common-padding">
									<div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
										<mat-card-content>
											<div *ngIf="isEmployerRegSubmitted" fxLayout="row" fxLayoutAlign="center">
												<mat-spinner></mat-spinner>
											</div>
										<div *ngIf="!isEmployerRegSubmitted">
											<!--<div fxLayout="row">
												<div fxLayout="column" fxFlex="100">
													<div fxLayout="row">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error':!companyname.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Company Name</mat-label>
																<input matInput type="text" tabindex="8" [value]="this.companyname.value" placeholder="Company Name" required name="companyname" id="companyname"
																				[matAutocomplete]="autoComp" [formControl]="companyname" (input)="setCompany($event.target.value)">
																<mat-autocomplete #autoComp="matAutocomplete" [displayWith]="addNewOption">
																	<mat-option *ngFor="let comp of companySectorQuerySearch(companyname,companiesinfo)" (onSelectionChange)="setCompanyObject(comp,2)" [value]="comp.name"> {{ comp.name }} </mat-option>
																</mat-autocomplete>
																<mat-error *ngIf="companyname.hasError('required')"> You must have a company name. </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>-->
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100">
													<div fxLayout="row">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error': !companyaddress.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Address</mat-label>
																<input matInput type="text"  tabindex="9" placeholder="Address" required name="companyaddress" id="companyaddress" [matAutocomplete]="autostreet"
																				[formControl]="companyaddress" (input)="setCompanyStreet($event.target.value)">
																<mat-autocomplete #autostreet="matAutocomplete">
																	<mat-option *ngFor="let street of querySearch(companyaddress, approximateLocation.street)" [value]="street"> {{ street }} </mat-option>
																</mat-autocomplete>
																<mat-error *ngIf="companyaddress.hasError('required')"> You must have an address. </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100" fxLayoutGap.gt-sm="15px">
													<div fxLayout.lt-md="row wrap" fxLayout="row" fxLayoutGap.gt-sm="30px">
														<div fxFlex="50" fxFlex.lt-md="100" fxFlex.gt-xs="45" fxFlex.lg="50">
															<mat-form-field [ngClass]="{'has-error': !companycity.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>City</mat-label>
																<input matInput type="text" tabindex="10"  placeholder="City" required name="companycity" id="companycity" pattern="[a-zA-Z ]*"
																				[matAutocomplete]="autocity" [formControl]="companycity" (input)="setCompanyCity($event.target.value)">
																<mat-autocomplete #autocity="matAutocomplete">
																	<mat-option *ngFor="let city of querySearch(companycity, approximateLocation.city)" [value]="city"> {{ city }} </mat-option>
																</mat-autocomplete>
																<mat-error *ngIf="companycity.hasError('required')"> You must have a City Name. </mat-error>
																<mat-error *ngIf="companycity.errors?.pattern && !companycity.hasError('required')"> Please enter valid city </mat-error>
															</mat-form-field>
														</div>
														<div fxFlex="50" fxFlex.lt-md="100" fxFlex.gt-xs="45" fxFlex.lg="50">
															<mat-form-field [ngClass]="{'has-error': !companystate.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>State</mat-label>
																<mat-select placeholder="State" [formControl]="companystate" tabindex="11" name="companystate" id="companystate" required>
																<mat-option *ngFor="let state of states" [value]="state.abbrev">{{state.abbrev}}</mat-option>
															</mat-select>
																<mat-error *ngIf="companystate.hasError('required')"> You must have a state. </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100" fxLayoutGap.gt-sm="15px">
													<div fxLayout.lt-md="row wrap" fxLayout="row" fxLayoutGap.gt-sm="30px">
														<div fxFlex="50" fxFlex.lt-md="100" fxFlex.gt-xs="45" fxFlex.lg="50">
															<mat-form-field [ngClass]="{'has-error': !companyzip.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Zip</mat-label>
																<input matInput type="text"  tabindex="12" placeholder="Zip" required name="companyzip" maxlength="5" minlength="5" id="companyzip" pattern="^\d{5}(?:[-\s]\d{4})?$"
																				[matAutocomplete]="autozip" [formControl]="companyzip" (input)="setCompanyZip($event.target.value)">
																<mat-autocomplete #autozip="matAutocomplete">
																	<mat-option *ngFor="let zip of querySearch(companyzip, approximateLocation.zip)" [value]="zip"> {{ zip }} </mat-option>
																</mat-autocomplete>
																<mat-error *ngIf="companyzip.hasError('required')"> You must have a Zip Code. </mat-error>
																<mat-error *ngIf="companyzip.errors?.pattern && !companyzip.hasError('required')"> Please enter valid zip code </mat-error>
															</mat-form-field>
														</div>
														<div fxFlex="50" fxFlex.lt-md="100" fxFlex.gt-xs="45" fxFlex.lg="50">
															<mat-form-field [ngClass]="{'has-error': !companycountry.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Country</mat-label>
																<input matInput type="text"  tabindex="13" placeholder="Country" required [(ngModel)]="user.location.metro" #companycountry="ngModel" name="companycountry"
																				id="companycountry" pattern="[a-zA-Z ]*">
																<mat-error *ngIf="companycountry.hasError('required')"> You did not enter Country </mat-error>
																<mat-error *ngIf="!companycountry.pattern && !companycountry.hasError('required')"> Please enter valid country </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row">
												<div fxLayout="column" fxFlex="100" fxLayoutGap.gt-sm="15px">
													<div fxLayout.lt-md="row wrap" fxLayout="row" fxLayoutGap.gt-sm="30px">
														<div fxFlex="50" fxFlex.lt-md="100" fxFlex.gt-xs="45" fxFlex.lg="50">
															<mat-form-field [ngClass]="{'has-error':!companysize.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Size of the Organization</mat-label>
																<mat-select tabindex="14" placeholder="Size of the Organization" [(ngModel)]="companySize" #companysize="ngModel" required name="companysize" id="companysize">
																	<ng-container *ngFor="let size of companySizeList">
																		<mat-option [value]="size.id">
																			{{size.name}}
																		</mat-option>
																	</ng-container>
																</mat-select>
																<mat-error *ngIf="companysize.hasError('required')"> You must have a Size of the Organization. </mat-error>
															</mat-form-field>
														</div>
														<div fxFlex="50" fxFlex.lt-md="100" fxFlex.gt-xs="45" fxFlex.lg="50">
															<mat-form-field  appearance="outline">
																<mat-label>Industry/ Sector</mat-label>
																	<mat-select tabindex="15" placeholder="Industry/ Sector" [(ngModel)]="user.sector" #companysector="ngModel" required name="companysector" id="companysector" multiple>
																		<ng-container *ngFor="let sector of sectors">
																			<mat-option [value]="sector.id">
																				{{sector.name}}
																			</mat-option>
																		</ng-container>
																	</mat-select>
																<mat-error *ngIf="companysector.hasError('required')">You must have a Sector</mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div>
												<div fxLayout="column" fxFlex="100" fxLayoutGap.gt-sm="15px">
													<div fxLayout.lt-md="row wrap" fxLayout="row" fxLayoutGap.gt-sm="30px">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error':!companywebsite.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Company Website</mat-label>
																<input matInput type="text" tabindex="16" placeholder="Company Website" [(ngModel)]="user.company.website" (input)="setCompanyWebsite($event.target.value)" #companywebsite="ngModel" required name="companywebsite" id="companywebsite">
																<mat-error *ngIf="companywebsite.hasError('required')"> You must have a company website. </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
											<div>
												<div fxLayout="column" fxFlex="100" fxLayoutGap.gt-sm="15px">
													<div fxLayout.lt-md="row wrap" fxLayout="row" fxLayoutGap.gt-sm="30px">
														<div fxFlex="100">
															<mat-form-field [ngClass]="{'has-error':!companyDescription.valid && isEmployerRegSubmitted==true}" appearance="outline">
																<mat-label>Company Description</mat-label>
																<!-- rows="5" -->
																<textarea matInput tabindex="16" placeholder="Company Description" [(ngModel)]="user.company.description" (input)="setCompanyDescription($event.target.value)" #companyDescription="ngModel" required name="companyDescription" id="companyDescription">
																</textarea>
																<mat-error *ngIf="companyDescription.hasError('required')"> You must have a company description. </mat-error>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>		
										</div>
										</mat-card-content>
										<mat-card-actions>
											<div fxLayout="row">
												<button tabindex="17" mat-raised-button color="primary" [disabled]="!empRegForm.valid || isEmployerRegSubmitted" (click)="ngOnEmployerRegister(empRegForm)">
													Done
												</button>	
											</div>
										</mat-card-actions>
									</div>
									<div fxFlex="40" fxFlexOffset="10" fxLayout="column" fxLayoutAlign="start stretch" fxHide.lt-md="true">
										<app-logo [tenant]="tenant" [type]="'employer-registration'"></app-logo>			
									</div>
								</div>
							</div>
						</form>
					</mat-card>
				</div>
			</div>
			<div fxLayout="row" fxFlexOffset="30px"></div>
		</div>
	</div>
</div>
