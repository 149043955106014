<h1 mat-dialog-title>Update Default Logo for {{defaultLogoType}}</h1>
<div mat-dialog-content>
	<form  name="defaultLogo" novalidate>
		<div class="modal-body">
			<div class="">
				<div class="form-group">
					<div>
						<button mat-button mat-raised-button color="primary" (click)="callback.click()">
							Upload New Logo
							<input #callback type="file" 
								(change)="fileChangeEvent($event)" 
								mode="data-url" name="pic-upload-{{defaultLogoType}}" 
								id="pic-upload-{{defaultLogoType}}" 
								style="display:none;"
								class="form-control" 
								accept="image/x-png,image/gif,image/jpeg" />
						</button>
						<p class="help-block small animated fadeInDown mat-error ng-star-inserted" *ngIf="imageError">Only JPG, PNG or Gif images are allowed with file size of 2MB only.</p>
					</div>
					<div *ngIf="isLoadingDefaultLogo">
						<div>
							<mat-progress-spinner style="position: absolute !important; top: 0;bottom: 0;margin: auto;right: 0;left: 0;" 
								class="tjs-spinner skills" 
								[color]="color" 
								[mode]="mode" 
								[value]="value"
								[diameter]="30">
							</mat-progress-spinner>
						</div>
						<div>Loading...</div>
					</div>
					<div [hidden]="isLoadingDefaultLogo" class="img-box padding-top-15">
						<img [src]="logoPreview|safeHtml" />
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
<div mat-dialog-actions>
	<button mat-button mat-raised-button (click)="close()">Cancel</button>
	<button mat-button mat-raised-button color="primary" [disabled]="!logoFile.content || imageError" *ngIf="!submitting" (click)="submitDefaultLogo()">Submit</button>
	<div *ngIf="submitting">
		<mat-progress-spinner style="position: absolute !important;	top: 0;bottom: 0;margin: auto;right: 0;left: 0;" 
			class="tjs-spinner skills" 
			[color]="color" 
			[mode]="mode" 
			[value]="value"
			[diameter]="20">
		</mat-progress-spinner>
	</div>
</div>
