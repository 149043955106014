import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-student-card',
  templateUrl: './student-card.component.html',
  styleUrls: ['./student-card.component.css']
})
export class StudentCardComponent implements OnInit {
  @Input() data: any;
  @Input() breakpoint: number;
  @Output() viewProfile: EventEmitter<number> = new EventEmitter<number>();
  @Output() viewShortlist : EventEmitter<number> = new EventEmitter<number>();
  public skillWrapper;
  public listOfSkillWrappers;
  public inputSkillChips:  Array<any> = [];
  public numRemoved = 0;
  constructor() { }

  ngOnInit() {
    if (this.data.skills != null) {

      var sortByTextSkillList = this.sortByTextLength(this.data.skills);
      for (var i = 0; i < sortByTextSkillList.length;i++) {
        if (sortByTextSkillList[i] != '') {
          this.inputSkillChips.push(sortByTextSkillList[i]);
        } 
      }
      var thisCandidateSkillList = document.getElementById('skillList');
      this.listOfSkillWrappers = document.getElementsByClassName('mat-chip-list-wrapper');
      for(var i=0; i<this.listOfSkillWrappers.length; i++) {
        if(this.listOfSkillWrappers[i].parentElement == thisCandidateSkillList) {
          this.skillWrapper = this.listOfSkillWrappers[i];
        }
      }
    }
  }

  sortByTextLength(skill_list: any) {
    return skill_list.sort(function (s1, s2) { return s1.length - s2.length; });//Sort by length of skill
  }

  ngAfterViewInit() {
    this.updateChipList();
  }

  updateChipList() {
    setTimeout( () => {     
      if(this.skillWrapper && this.skillWrapper.scrollHeight > 40) {
        if(this.numRemoved != 0) {
          this.inputSkillChips.pop();
        }
        this.inputSkillChips.pop();
        this.inputSkillChips.push("+" + ++this.numRemoved);
      }
    }, 10);
  }


  public checkOverflow(element) {
    if (element.offsetHeight > document.getElementById('skillListContainer').offsetHeight) {
      return element.offsetHeight;
    } 
    else {
      return document.getElementById('skillListContainer').scrollHeight;
    }
  }

  public getStudentAllDetails(id: number): void {
    this.viewProfile.emit(id);
  }

  public getShortlistDetails(id: number) : void {
    this.viewShortlist.emit(id);
  }

}
