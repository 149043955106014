<div fxLayour="row" fxLayoutAlign="center start">
    <mat-card class="candidates-card top-margin" 
              [ngClass]="{'zero-card-width': breakpoint==0, 'one-card-width': breakpoint==1, 
              'two-card-width': breakpoint==2, 'three-card-width': breakpoint==3, 'four-card-width': breakpoint==4}">
      <mat-card-header class="padded">
        <!-- Search Criteria Here -->
        <form name="searchForm" id="searchForm" fxLayout="column" fxFlex="100%" fxLayoutGap="15px" #searchForm="ngForm">
          <!-- Row 1 of Search -->
          <div [fxLayout]="breakpoint>1 ? 'row' : 'column'" [fxLayoutAlign]="breakpoint>1 ? 'space-between start' : 'space-between stretch'">
            <div [fxLayout]="breakpoint>1 ? 'row wrap' : 'row wrap'" fxLayoutAlign="center start" [fxFlex]="breakpoint>1 ? '75.75%' : '100%'">
              <mat-form-field class="candidateSearchtext" color="accent" appearance="outline" [fxFlex]="breakpoint>1 ? '78%' : '100%'">
                <mat-label>Search Candidates</mat-label>
                <input matInput [(ngModel)]="searchParams.text" id="searchStudent" name="searchStudent" #searchStudent="ngModel">
                <button matPrefix mat-icon-button (click)="search()"><mat-icon>search</mat-icon></button>
                <button mat-icon-button *ngIf="searchParams.text" matSuffix aria-label="Clear" (click)="clearSearchText()">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-form-field>
              <div [fxFlex]="breakpoint>1 ? '22%' : '100%'">
                <div fxLayout="column" fxFlex="100">
                  <button class="filter-buttons btn_savedSearch" fxFlexFill fxFlexOffset.gt-sm="4px" fxFlexAlign="end" mat-stroked-button [matMenuTriggerFor]="savedSearches" color="primary">
                    SAVED SEARCH <i class="fa fa-angle-down"></i>
                  </button>
                  <mat-menu fxLayout="row" #savedSearches="matMenu" class="savedSearchMenuCandidates" xPosition="before" yPosition="below">
                    <div fxLayout="column" fxFlex="100">
                      <div fxLayout="row wrap" fxFlex="100">
                        <h5 fxFlexOffset="15px">Saved Searches</h5>
                        <div fxFlex="100" *ngIf="!saveSearchList || saveSearchList.length == 0" fxLayoutAlign="space-between center">
                          <mat-label mat-menu-item fxFlex="100">No searches saved yet!</mat-label>
                        </div>
                        <div class="border-bottom" fxLayout="row wrap" fxLayoutAlign="space-between center"  fxFlex="100" *ngFor="let search of saveSearchList;last as last">
                          <mat-label mat-menu-item fxFlex="90" fxFlex.lt-md="80" (click)="applySearch(search)" class="remove-margin">{{search.name}}</mat-label>
                          <button mat-icon-button fxFlex="8" fxFlex.lt-md="16" (click)="editSearch(search)" class="margin-top-2 cursor-pointer"><mat-icon>edit</mat-icon></button>
                        </div>
                      </div>
                    </div>
                  </mat-menu>
                </div>
                  
              </div>
            </div>
            <div fxLayout="row" [fxFlex]="breakpoint>1 ? '22.25%' : '100%'" fxLayoutAlign="space-between center">
              <div fxLayout="column" fxFlex="50">
                <button mat-flat-button class="filter-buttons" fxFlexFill fxFlexOffset="5px" color="primary" (click)="openAdvancedFilterDialog()"> FILTER</button>
              </div>
              <div fxLayout="column" fxFlex="50">
                <button mat-button class="filter-buttons" fxFlexFill fxFlexOffset="5px" color="primary" (click)="clearFilters()" matTooltip="Clear All Filters">CLEAR ALL</button>
              </div>
            </div>
              <!-- <button mat-stroked-button fxFlex="49" color="primary" class="filter-buttons top-alignment" (click)="search()">
                Search
              </button> -->
          </div>
        </form> 
      </mat-card-header>
      <mat-divider></mat-divider>
      
      <!-- Candidate Results Here -->
      <mat-card-content class="padded top-margin">
        <div *ngIf="isLoadingResult" fxLayout="row" fxLayoutAlign="center" >
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="employerStudents.length===0 && !isLoadingResult" fxLayout="row" fxLayoutAlign="center">
          <label> No candidates found. </label>
        </div>
        <div *ngIf="!isLoadingResult" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px grid"> 
          <app-student-card [breakpoint]="breakpoint" [data]="stud" (viewProfile)="viewProfile($event)" (viewShortlist)="viewShortlist($event)" *ngFor="let stud of employerStudents"></app-student-card>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      
      <!-- Paginator -->
      <mat-card-footer class="padded top-margin">
        <mat-paginator [pageSizeOptions]="[20,40,60,80,100]" [length]="totalLength" [pageSize]="20" (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
      </mat-card-footer>
    </mat-card>
</div>
