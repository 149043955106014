import { ViewChild, Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css']
})
export class ReportDetailsComponent implements OnInit {
  searchText: '';
  hasRecords: boolean = true;
  @Input() reportdetail: any;
  @Input() displayedColumns: any;

  @ViewChild(MatSort) sort: MatSort;
  @Output('cancelText') cancelClick: EventEmitter<any> = new EventEmitter();
  @Output('searchReport') searchReport: EventEmitter<any> = new EventEmitter();

  public reportData: MatTableDataSource<any>;
  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.reportdetail != null) {
      this.reportData = new MatTableDataSource(this.reportdetail);
      this.reportData.sort = this.sort;
      this.hasRecords = (this.reportdetail.length == 0 ? false : true);
    }
  }

  applyFilter(event: Event) {
    const result = (event.target as HTMLInputElement).value;
    this.reportData.filter = result.trim().toLowerCase();
    this.searchReport.emit(this.reportData);
  }

  clearSearchText() {
    this.searchText = '';
    this.cancelClick.emit();
  }

  isDate(d) {
    return d instanceof Date;
  }

}
