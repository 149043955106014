import { Component, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CandidateAvailabilityComp } from '../../../../Models/CandidateProfile';
import { RegisterService } from '../../../../Services/register.service';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { ThemePalette } from '@angular/material/core';
import { MatSliderChange } from '@angular/material/slider';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-candidate-availability-comp',
  templateUrl: './candidate-availability-comp.component.html',
  styleUrls: ['./candidate-availability-comp.component.css']
})
export class CandidateAvailabilityCompComponent implements OnInit {

  @Input() availabilityComp: CandidateAvailabilityComp;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  candidateAvailabilityTypes = [];
  minConf = 0;
  maxConf = 100;
  isMinValueInit = true;
  isMaxValueInit = true;
  thumbLabel = true;
  minValue: number;
  maxValue: number;
  minColor: ThemePalette = 'accent';
  maxColor: ThemePalette = 'accent';
  compareFunction = (o1: any, o2: any)=> o1.id===o2.id;

  public form = this.fb.group({
    availabilities: [''],
    compType: [''],
    minComp: ['', [Validators.required]],
    maxComp: ['', [Validators.required]],
  });
  public breakpoint:number; // used to classify the screen size/display width
  constructor(private fb: UntypedFormBuilder,
    private _registerService: RegisterService,
    private _talentAPIService: TalentApiService,
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    if(!this.availabilityComp.minComp || !this.availabilityComp.maxComp) {
      this.availabilityComp.minComp = 1;
      this.availabilityComp.maxComp = 100;
    } 
    this.form.patchValue(this.availabilityComp);
    this.getCanidateAvailabilityStatuses();
    this.minValue = this.sliderMin;
    this.maxValue = this.sliderMax;
  }

  formatAmountMin(value: number) {
    if (value >= 1000) {
      value = Math.round(value / 1000);
      return 'Min - $' + value + 'k/Hr';
    }
    return 'Min - $' + value + '/Hr';
  }

  formatAmountMax(value: number) {
    if (value >= 1000) {
      value = Math.round(value / 1000);
      return 'Max - $' + value + 'k/Hr';
    }
    return 'Max - $' + value + '/Hr';
  }

  set sliderMax(m: number) {
    this.maxConf = parseInt(m.toString(), 10);
  }
  get sliderMax(): number {
    return this.maxConf;
  }

  set sliderMin(m: number) {
    this.minConf = parseInt(m.toString(), 10);
  }
  get sliderMin(): number {
    return this.minConf;
  }

  minValueInput(a): void {
    let maxValue = this.form.get('maxComp').value; 
    if(this.form.get('minComp').value >= maxValue) {
      this.form.get('minComp').setValue(Math.max(maxValue - 1, 0));
    }
  }

  maxValueInput(a): void {
    let minValue = this.form.get('minComp').value; 
    if(this.form.get('maxComp').value <= minValue) {
      this.form.get('maxComp').setValue(Math.max(minValue + 1, 0));
    }
  }

  getCanidateAvailabilityStatuses() {
    this._registerService.getCandidateAvaibility().subscribe(
      (result) => {
        if(result) {
          result.forEach(el => {
            this.candidateAvailabilityTypes.push({ id: el.id, name: el.availability_type});
          });
        }
      },
      (error) => {}
    );
  }

  next() {
    const candidateAvailabilityObject = this.form.value;
    const candidateProfile = {
      availabilityComp: this.form.value
    }
    // the comp type is not implemented
    if(candidateProfile.availabilityComp.compType == 0) {
      candidateProfile.availabilityComp.compType = 1;
    }
    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
        if (result) {
          this.saveProfile.emit(this.form.value);
          var data = candidateAvailabilityObject;
          if(this.edit === true) {
            this.dialogRef.close(data);
          }
        }
        else {

        }
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }

  skip() {
    this.saveProfile.emit(this.availabilityComp);
  }

  prev() {
    this.prevEvent.emit();
  }
}
