import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../Pages/profile-edit/profile-edit.component';

import { CandidateWorkExperience } from '../../../Models/CandidateProfile';
import { TalentApiService } from '../../../Services/talent-api.service';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';
import { UserInfoService } from '../../../Services/user-info.service';

@Component({
  selector: 'app-work-experience-view',
  templateUrl: './work-experience-view.component.html',
  styleUrls: ['./work-experience-view.component.css']
})
export class WorkExperienceViewComponent implements OnInit {

  @Input() ownProfile: boolean;
  @Input() experiences: any;
  @Output() saveExperience = new EventEmitter<any>();

  public showingMore = false;
  public dateOptions = {
    month: 'long',
    year: 'numeric'
  }
  public logos:any[] = [];
  public user: any;
  userType: number;

  constructor(private _talentApiService:TalentApiService,
    public _dialog: MatDialog,
    private sharedUtils: SharedUtilitiesService,private _userInfoService: UserInfoService,) { }


  ngOnInit(): void {
    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);
    this.experiences.forEach((el) => {
      el.start_date = el.start_date != null ? new Date(el.start_date) : null;
      el.end_date = el.end_date != null ? new Date(el.end_date) : null;
    });
  }

  toggleShow() {
    if(this.showingMore == true) {
      this.showingMore = false;
    }
    else {
      this.showingMore = true;
    }
  }

  openEdit() {
    let dialog = this._dialog.open(ProfileEditComponent,{
      height: 'auto',
      width: '80%',
      disableClose: true,
      data: {
              subComponent: 'candidate-work-experience',
              seedData: this.experiences
            }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        if (result == null) {
        } else {
          console.log(result);
          
          this.experiences = result;
        
           this.experiences.sort((e1) => { 
            if (e1.present == "1") return -1;
            else if (e1.present == "0") return 1;
            else return 0;
           });
          this.saveExperience.emit(this.experiences);
        }
      });   
  }
}
