import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { CandidateDetailComponent } from '../../Pages/dialogs/candidate-detail/candidate-detail.component';
import { OpportunityDetailComponent } from '../../Pages/opportunity-detail/opportunity-detail.component';
import { PostingService } from '../../Services/posting.service';
import { AdminApiService } from '../../Services/admin-api.service';
import { TalentApiService } from '../../Services/talent-api.service';

export interface ContentReport {
  id: number;
  reportedby: string;
  mainReason: string;
  subReason: string;
  candidateId: number;
  candidateName: string;
  opportunityId: number;
  opportunityTitle: string;
  reportedDate: Date;
  actionTaken: string;
  opportunityDisabled: string;
  userDisabled: string;
}

const ELEMENT_DATA: ContentReport[] = [];

@Component({
  selector: 'app-admin-content-reporting',
  templateUrl: './admin-content-reporting.component.html',
  styleUrls: ['./admin-content-reporting.component.css']
})
export class AdminContentReportingComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'reportedby', 'reportedDate', 'mainReason', 'candidateId', 'opportunityId', 'reviewed', 'actionTaken', 'disablebutton'];
  dataSource = new MatTableDataSource<ContentReport>(ELEMENT_DATA);
  selectedValue: string;
  actionOptions: string[] = ['None', 'Removed'];
  isLoading = false;

  constructor(public _dialog: MatDialog,
    private postingdetails: PostingService,
    private adminApiService: AdminApiService,
    public _talentApiService: TalentApiService,) { }

  ngOnInit(): void {
    this.getContentReports();
  }

  getContentReports() {
    this.isLoading = true;
    this.adminApiService.getContentReports().subscribe((result: any) => {
        if(result && result.length > 0) {
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        }
    },
    (error) => {
      console.log("Error retriving content report data")
      this.isLoading = false;
    });
  }
  previewCandidateProfile(candidateId) {
    console.log(candidateId);
    let dialog = this._dialog.open(CandidateDetailComponent, {
      height: 'auto',
      width: '100%',
      disableClose: true,
      data: {
        candidateId: candidateId
      }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        console.log(result);
      });
  }

  previewPosting(postingId) {
    this.postingdetails.getPostingDetail(postingId).subscribe((result: any) => {
      console.log(result);

      if (result) {     

        const dialog = this._dialog.open(OpportunityDetailComponent, {
          height: '90vh',
          width: '90vh',
          data: result
        });
        dialog.afterClosed().subscribe((result) => { });

      }
    });

  }

  takeAction(element) {
    let data = {contentReportId: element.id, actionTaken: element.actionTaken};
    this.isLoading = true;
    this.adminApiService.contentReportAction(data).subscribe((result: any) => {
      this._talentApiService.snackbarMessage("Content report action taken successfully");
      this.getContentReports();
    },
    (error) => {
      element.actionTaken = null;
      this._talentApiService.snackbarMessage("Something went wrong while taking action!");
      this.isLoading = false;
    });
  }

  disable(element) {
    element.actionTaken = 'Removed';
    this.takeAction(element);
  }

}
