import { Component, OnInit } from '@angular/core';
import { CandidateProfile, CandidateProfileObj } from '../../../../Models/CandidateProfile';
import { SharedUtilitiesService } from '../../../../Services/shared-utilities.service';
import { UserInfoService } from '../../../../Services/user-info.service';



@Component({
  selector: 'app-candidate-diversity-edit',
  templateUrl: './candidate-diversity-edit.component.html',
  styleUrls: ['./candidate-diversity-edit.component.css']
})
export class CandidateDiversityEditComponent implements OnInit {
	userId: string = null;
	candidateProfile: CandidateProfile = new CandidateProfileObj();
	isProfileLoaded = false;
  constructor(private _userInfoService: UserInfoService,
		private sharedUtils: SharedUtilitiesService) { }

  ngOnInit(): void {
    this.userId = this._userInfoService.getUser().id;
		this.getCandidateProfile();
  }
  getCandidateProfile() {
		this.sharedUtils.getMyProfile("", this.userId)
			.subscribe(
			(result: any) => {
				if (result) {
					this.candidateProfile = result;
					this.isProfileLoaded = true;
				}
			},
			error => {
				this.isProfileLoaded = true;
			}
			);
	}

}
