import {catchError, map} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeUrl, SafeHtml, Title } from '@angular/platform-browser';
import { ProfileService } from '../../Services/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserInfoService } from '../../Services/user-info.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthGuardService } from '../../Services/auth-guard.service';
import { LoginService } from '../../Services/login.service';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
import { TalentApiService } from '../../Services/talent-api.service';
// import { FileUploaderModule } from "ng4-file-upload";
import { RegisterService } from '../../Services/register.service';
import { EmployerStudentService } from '../../Services/employer-student.service'
import {MatIconRegistry} from '@angular/material/icon';
import { CandidateProfile, CandidateProfileObj } from '../../Models/CandidateProfile';

// export interface Tile {
// 	color: string;
// 	cols: number;
// 	rows: number;
// 	text: string;
//   }

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @Input() candidateId: any = 0;
  @Input() postingId: any = 0;
  @Input() jobCurrentStatus: any = '';
	public edit: boolean = false;
	public student: any = {};
	public newPic = '';
	public years = [
		2010,
		2011,
		2012,
		2013,
		2014,
		2015,
		2016,
		2017,
		2018,
		2019,
		2020,
		2021,
		2022,
		2023,
		2024
	];
	public d = new Date();
	public lastYear = this.d.getFullYear() - 1;
	public selectedItem = null;
	public searchSkill = null;
	public requireMatch = false;
	public selectedSkillList = [];
	public internshipHistoryRetrieved: boolean = false;
	public mySkills = [];
	public Stdskills = [];
	public tmpSkills = [];
	public searchSkills = [];
	public foundSkills = [];
	public filteredResult = [];

	public skills: any = {};
	public degreePrograms: any;
	public schoolInfo: any = {};
	public tjsInternship: any = {};
	public studCurrentExp = [];
	public studExp = [];
	//public studPastExp = [];
	public studPastExp = {};
	public studOptId = [];
	public studentAwards = [];
	public studentOrgs = [];
	public studentHobby = [];
	//public editEnabled: boolean = false;

	public ErrorMessage: string = '';
	public studentDetails: any;
	public getUser: any;
	public userId;
	public ownProfile:boolean = false;
	public isFormSubmitted: boolean = false;
	public isLoggedIn: boolean = false;
	public selectedCourse: string = '';

	//editing
	public isStudentProfSubmitted = false;
	public persist: any;
	public schoolList: any;
	public objectiveLength = 140;
	public majorlength = 100;
	public bioLength = 5000;
	public editSchools: boolean = false;
	public sourcelistnames = [];
	public data: any;
	public filteredSkillResult: any;
	// public mask = ['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	// private gpaMask =[/[0-4]/,'.', /\d/, /\d/];
	//public linkedInUrl = ['https://www.linkedin.com/', '', '\d/'];
	public expBeforeEdit: any;
	public majorSearchText: any;
	public imageChangedEvent: any = '';
	public imageError: boolean = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	myEventSubscription:any;
	resumeName:'';
	transcriptName='';
	uploadTranscript:any;
	uploadResume:any;
	noExperience = false;
	isFileChange:boolean = false;
	comps: any;
	workStatusBuckets: any;
	degreeTypes: any;

	// for student Courses

	public studentCourses = [];
	public courseList: any;
	isEducationLevelCollege = false;

	public sub: any;
	//public candidateId: any = 0;
	public isDataAvailable:boolean = false;

	candidateProfile: CandidateProfile = new CandidateProfileObj();

	// default blank object
	constructor(private _profileService: ProfileService, private _route: ActivatedRoute, private _router: Router, private _userInfoService: UserInfoService, private _http: HttpClient, private _authGuardService: AuthGuardService, private _loginService: LoginService, private _sanitizer: DomSanitizer,
		private _talentAPIService: TalentApiService, private _employerStudentService: EmployerStudentService,
		private _studentRegistrationService: RegisterService, iconRegistry: MatIconRegistry,sanitizer: DomSanitizer, private sharedUtils: SharedUtilitiesService) {

		this.isFormSubmitted = false;
		this.student = {
			res: {
				exp: [],
				awards: ''
			}
		};
		this.studPastExp = {
			contact: {
				name: '',
				tele: ''
			}
		};

		iconRegistry.addSvgIcon(
			'thumbs-up',
			sanitizer.bypassSecurityTrustResourceUrl('assets/imgtst/icons/svg/create-24px.svg'));
	}

	studentmajor: UntypedFormControl = new UntypedFormControl();
	stdskills: UntypedFormControl = new UntypedFormControl();

	ngOnInit() {
		this.studPastExp = [];
		this.userId = this._userInfoService.getUser().id;
		this.isDataAvailable = false;
		this.getProfileData();
	}

	saveExperience(data) {
		this.candidateProfile.workExperience = data;
	}
	
	getProfileData() {
		if(!this.candidateId) {
			this.sub = this._route.params.subscribe(params => {
				this.candidateId = +params['id']; // (+) converts string 'id' to a number
				// In a real app: dispatch action to load the details here.
			});
		}

		if(this._userInfoService.getUser().type === "2") { //bad logic !! needs to be switched to proper user check.
			this.ownProfile = true;
		} else {
			this._employerStudentService.logProfileView(this.candidateId).subscribe();
		}

		this.sharedUtils.getMyProfile(this.candidateId, this.userId)
			.subscribe(
			(result:any) => {
				if (result) {
					this.student = result;
					this.candidateProfile = result;
					this.isDataAvailable = true;
				}
			},
			error => {
				this._talentAPIService.snackbarMessage(error.error.msg);
				this._router.navigate(['/dashboard']);
			}
			);
	}
}
