<div fxLayout="column">
  <div>    
    <h4>API List</h4>
    <p><em>API level Permission</em></p>
    <div class="instructions_cl">
      <strong>Instructions:</strong>
      <ol>
        <li>xxx</li>
        <li>xxx</li>
        <li>xxx</li>
        <li>xxx</li>
      </ol>
    </div>
  </div>
  <div fxLayout="row" fxFlex="100">
    <!--Search box-->
    <mat-form-field appearance="outline" fxFlex="90">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
    </mat-form-field>
    <div fxFlex="10" fxLayoutAlign="center center">
      <button  mat-button mat-raised-button (click)="edit(null,true)">Add New API</button>
    </div>
  </div>
  <div fxFlex="100" fxLayot="row" class="table-content table-container">   
      <table mat-table [dataSource]="dataSource" fxFlex="100%" matSort>
        <ng-container matColumnDef="Id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.Id }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> API Name </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="last_updated_by">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Updated By </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.last_updated_by }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="last_updated_ts">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Updated On </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.last_updated_ts | date }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="view">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Edit </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-raised-button (click)="edit(row,false)">Edit</button>
          </mat-cell>
        </ng-container>
        Header Column Labels
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        Row shown when there is no matching data.
        <mat-row *matNoDataRow>
          <mat-cell colspan="9">No data matching the filter "{{input.value}}"</mat-cell>
        </mat-row>
      </table>
  </div>
  <div fxFlex="100" fxLayot="row">
    <!--[length]="totalRecords"
  [pageSize]="pageSize"
  (page)="handlePageEvent($event)"-->
    <mat-paginator fxFlex="100"
                   [pageSizeOptions]="[10,25,50,100]"></mat-paginator>
  </div>
</div>
