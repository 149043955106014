<mat-card class="student-card student-card-small" [ngClass]="{'student-card-semi-regular': breakpoint == 0, 'student-card-regular': breakpoint != 0}">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxFlex.gt-md="25" fxFlex.md="25" fxFlex.sm="18" fxFlex.xs="35" [ngClass]="{'matcardcandidatelogo': breakpoint == 1, 'logo': breakpoint != 1}">
        <img *ngIf="candidateData.profile_pic == null || candidateData.profile_pic == ''" 
          class="matcardcandidateimage card-round-image" src="assets/img/User-blue-icon.png">
        <img *ngIf="candidateData.profile_pic != null && candidateData.profile_pic != ''" 
          class="matcardcandidateimage card-round-image" src="{{candidateData.profile_pic}}">
      </div>
      <div fxFlex>
        <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="flex-start">
          <div fxFlex>
            <div fxLayout="row wrap">
              <div fxLayout="column" class="padBottom8px" fxFlex (click)="getCandidateDetails()">
                <strong>
                  <span >
                    {{candidateData.name}}
                  </span>
                </strong>
              </div>
              <div *ngIf="isPostingView" fxFlex="35" fxFlex.gt-lg="30" fxFlex.xs="100" fxLayoutAlign="end">
                <span class="status">{{candidateData.status}}</span>
              </div>
              <div fxFlex="10" *ngIf="isShortlistView" fxLayoutAlign="end">
                <mat-icon class="remove-btn" *ngIf="hasManageCandidatePermission" (click)="callRemoveCandidate()">close</mat-icon>
              </div>
            </div>
            <strong class="me-designation" (click)="getCandidateDetails()">
              <mat-card-subtitle>
                {{candidateData.title}}
              </mat-card-subtitle>
            </strong>
            <mat-card-subtitle fxLayout="row"  fxLayout.xs="column" fxLayoutAlign="space-between" (click)="getCandidateDetails()">
              <div *ngIf="candidateData.candidate_state != null && candidateData.candidate_city != null" fxLayout="row"
                class="mat-small" fxFlex="100" fxLayoutAlign="start center">
                <div  fxFlex="9" class="location-icon">
                  <mat-icon>room</mat-icon>
                </div>               
                <div fxFlex>
                  <mat-label>{{candidateData.candidate_city | titlecase }}, {{candidateData.candidate_state | uppercase}}</mat-label>
                </div>
              </div>
            </mat-card-subtitle>
            <div fxLayout="row" class="btn-container set-fixed-height" id="skillListContainer" (click)="getCandidateDetails()">
                <mat-chip-list cdkDropList cdkDropListOrientation="horizontal" id="skillList">
                    <mat-chip color="accent" *ngFor="let skill of inputSkillChips" [removable]="true" (destroyed)="updateChipList()" cdkDrag>
                      {{skill}}
                    </mat-chip>
                </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" *ngIf="isShortlistView" fxLayoutAlign="center center" >
        <div fxLayout="column" fxFlex="100">
            <button class="btn-invite" mat-stroked-button color="primary" (click)="openInviteToApply(candidateData.candidateId)">INVITE TO APPLY</button>
        </div>
    </div>
  </mat-card>
