import { Component, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { CandidateWorkExperience } from '../../../../Models/CandidateProfile';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import * as _moment from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { BreakpointObserver } from '@angular/cdk/layout';



export const MMYYYY_Format = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-candidate-work-experience',
  templateUrl: './candidate-work-experience.component.html',
  styleUrls: ['./candidate-work-experience.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MMYYYY_Format },
  ]
})
export class CandidateWorkExperienceComponent implements OnInit {
  @Input() workExperience;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  candidateProfile = {
    workExperience: null
  }
  public maxDate = new Date();
  public minDate = new Date(0);
  public isNextClicked = false;
  public form = this.fb.group({
    workExperience: new UntypedFormArray([])
  });
  public breakpoint:number; // used to classify the screen size/display width
  constructor(private fb: UntypedFormBuilder,
    private _talentAPIService: TalentApiService,
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef: MatDialogRef<ProfileEditComponent>) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    for (let exp of this.workExperience) {
      exp.start_date = new Date(exp.start_date);
      if (exp.present != true) {
        exp.end_date = new Date(exp.end_date);
      }
      if (exp.present == "1") {
        exp.present = true;
      } else {
        exp.present = false;
      }


      this.addWorkExperience();
    }
    this.form.get('workExperience').patchValue(this.workExperience);
    for (let cntrol of this.t.controls) {
      if (cntrol.get('present').value == true) {
        cntrol.get('end_date').setValue(new Date(this.maxDate));
        cntrol.get('end_date').disable();
      }
    }
    this.setMaxDateforCal();
  }

  get f() { return this.form.controls; }
  get t() { return this.f.workExperience as UntypedFormArray; }

  setMaxDateforCal() {
    this.maxDate = new Date();
    this.maxDate.setHours(23);
    this.maxDate.setMinutes(59);
    this.maxDate.setSeconds(59);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, index, value) {
    var ctrlValue = _moment();
    if (value == 'start_date') {
      ctrlValue = _moment(this.form.value.workExperience[index].start_date);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    if (value == 'end_date') {
      ctrlValue = _moment(this.form.value.workExperience[index].end_date);
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    const controlArray = <UntypedFormArray>this.form.get('workExperience');
    controlArray.controls[index].get(value).setValue(ctrlValue);

    datepicker.close();
  }

  addWorkExperience() {
    this.t.push(this.fb.group({
      company_name: ['', [Validators.required, Validators.maxLength(255)]],
      title: ['', [Validators.required, Validators.maxLength(50)]],
      start_date: [_moment(), [Validators.required]],
      end_date: [_moment(), []],
      present: [false, []],
      description: ['', [Validators.required, Validators.maxLength(2000)]],
    }));
  }

  removeCertification(index) {
    if (index !== -1) {
      this.t.removeAt(index);
    }
  }

  skip() {
    this.saveProfile.emit({});
  }

  next() {
    this.isNextClicked = true;
    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return;
    } else {
      const candidateProfile = this.form.value;
      this._talentAPIService.updateProfile(candidateProfile)
        .subscribe(
          (result: any) => {
            this.saveProfile.emit(this.form.value.workExperience);
            if (this.edit === true) {
              this.dialogRef.close(this.form.value.workExperience);
            }
          },
          error => {
            this._talentAPIService.snackbarMessage('Something went wrong!');
          });
    }
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  prev() {
    this.prevEvent.emit();
  }

  getContentCount(htmlCont) {
    if (htmlCont == '') {
      return 0;
    }
    var span = document.createElement('span');
    span.innerHTML = htmlCont;
    return span.textContent.length || span.innerText.length;
  }

  disableExpiryDate(index, checkedStatus) {

    if (this.t.controls.length > 0) {
      checkedStatus === false ? this.t.controls[index].get('end_date').enable() : this.t.controls[index].get('end_date').disable();

      if (checkedStatus == true) {
        this.t.controls[index].get('end_date').setValue(_moment(this.maxDate));
        this.t.controls[index].updateValueAndValidity();
      }
    }
  }
}
