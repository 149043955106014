import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OpportunityService } from '../../Services/opportunity.service';
import { ProfileViewDialogComponent } from '../dialogs/profile-view-dialog/profile-view-dialog.component';

@Component({
  selector: 'app-posting-match-candidate',
  templateUrl: './posting-match-candidate.component.html',
  styleUrls: ['./posting-match-candidate.component.css']
})
export class PostingMatchCandidateComponent implements OnInit {

  @Input() postingId: any;
  @Input() breakpoint: number;
  @Input() postingDetail: any;
  candidates: any = [];

  public isLoadingResult: boolean = false;
  public selectedSort: any = '0';
  public sortBy = 'date';
  public sortDirection = 'desc';


  constructor(@Optional() private dialogRef: MatDialogRef<ProfileViewDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public _dialog: MatDialog,
    private _opportunityService: OpportunityService, private _router: Router) {

  }

  ngOnInit(): void {
    this.getMatchedCandidatesForOpportunity();
  }

  public getCandidateDetails(id: number, has_applied: number, has_invited: any): void {    
    const dialog = this._dialog.open(ProfileViewDialogComponent, {
      height: 'auto',
      disableClose: false,
      data: {
        id: id,
        postingId: (has_applied === 1) ? this.postingId : 0,
        invite: (has_invited == '1') ? 0 : this.postingId, // set invite as posting id
        title: this.postingDetail.title,
        sector: this.postingDetail.company.sector,
        postedDate: this.postingDetail.postingdate
      },
    });
    const sub = dialog.afterClosed().subscribe((data) => {
      this.getMatchedCandidatesForOpportunity();
    });

  }

  getMatchedCandidatesForOpportunity() {    
    this.isLoadingResult = true;
    this._opportunityService.getMatchedCandidatesForOpportunity(this.postingId).subscribe((result: any) => {
      this.candidates = result['matchedCandidates'];
      this.isLoadingResult = false;
    });
  }  

}
