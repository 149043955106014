import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { Router, NavigationEnd } from '@angular/router';
import { RegisterService } from '../../Services/register.service';
import { TenantService } from '../../Services/tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoComponent } from '../../Pages/logo/logo.component';

@Component({
	selector: 'app-admin-header',
	templateUrl: './admin-header.component.html',
	styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

	public isLoggedIn: boolean = false;
	public widgets: any;
	tenant: String;
	constructor(private _loginService: LoginService, private _registerService: RegisterService, 
		public _router: Router, public _dialog: MatDialog,
		private _tenantService: TenantService) {
			this.tenant = this._tenantService.tenant;
		}

	ngOnInit() {

	}

 
}
