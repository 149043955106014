<div class="internship-modal posting_create">
	<form name="createClass" id="createClass" #createClass="ngForm" novalidate>
		<mat-toolbar mat-toolbar color="primary" class="background_ch internship-modal mat-default-theme">
			<mat-toolbar-row>
				<h3 mat-dialog-title class="calendar-md-title">Create New Class</h3>
				<span mat-flex></span>
				<button mat-icon-button id="closepostingmodal" class="mat-icon-button cancel_b pull-right" (click)="cancel()">
					<i class="fa fa-times" aria-hidden="true" tooltip="Close  Create Class."></i>
				</button>
			</mat-toolbar-row>
		</mat-toolbar>
		<mat-dialog-content class="popup_sc">
			<mat-card class="md-padding panel-body">
				<div class="row">
					<div class="col-lg-6">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<input matInput name="classname" placeholder="Class Name" id="classname" type="text" [(ngModel)]="cls.name" #classname="ngModel"
							 required>
							<mat-error *ngIf="classname.hasError('required')" class="help-block small animated fadeInDown">
								You did not enter your class name
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-lg-6">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<input matInput name="courseid" placeholder="Course Id" id="courseid" name="courseid" type="text" [(ngModel)]="cls.ident"
							 #courseid="ngModel" required [minlength]="1" [maxlength]="8" pattern="^$|^[A-Za-z0-9]+">
							<mat-error *ngIf="courseid.hasError('required')" class="help-block small animated fadeInDown">
								You did not enter course id.
							</mat-error>
							<mat-error *ngIf="courseid.hasError('pattern')" class="help-block small animated fadeInDown">
								Course Id can be Alpha Numeric only.
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<mat-form-field color="accent" appearance="outline" class="full-width">
							<input name="startDate" placeholder="Start Date" matInput [matDatepicker]="startDate" type="text" [(ngModel)]="cls.startDate"
							 #startdate="ngModel" required>
							<mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
							<mat-datepicker #startDate></mat-datepicker>
							<mat-error *ngIf="startdate.hasError('required'); else startdatesecond;" class="help-block small animated fadeInDown">
								Start date is required!
							</mat-error>
							<ng-template #startdatesecond>
								<mat-error *ngIf="startdate.invalid" class="help-block small animated fadeInDown">
									The entered value is not a valid date!
								</mat-error>
							</ng-template>
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field color="accent" appearance="outline" class="full-width">
							<input name="endDate" placeholder="End Date" matInput [min]="cls.startDate" [matDatepicker]="endDate" type="text" [(ngModel)]="cls.endDate"
							 #enddate="ngModel" required>
							<mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
							<mat-datepicker #endDate></mat-datepicker>
							<mat-error *ngIf="enddate.hasError('required'); else enddatesecond;" class="help-block small animated fadeInDown">
								End date is required!
							</mat-error>
							<ng-template #enddatesecond>
								<mat-error *ngIf="enddate.invalid" class="help-block small animated fadeInDown">
									The entered value is not a valid date!
								</mat-error>
							</ng-template>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<!-- <label>Description</label> -->
							<textarea class="text_areat" matAutosizeMinRows="0" matAutosizeMaxRows="4" matTextareaAutosize matInput placeholder="Description"
							 [(ngModel)]="cls.description" name="description" id="description" maxlength="150" #description="ngModel" required></textarea>

							<mat-error *ngIf="description.hasError('required');" class="help-block small animated fadeInDown">
								You did not enter description
							</mat-error>
							<small class="form-text text-muted pull-right">{{cls.description?.length}}/{{descriptionClass}}</small>
						</mat-form-field>
					</div>
				</div>
			</mat-card>
		</mat-dialog-content>

		<mat-dialog-actions class="submit-bb">
			<div *ngIf="!inprogress">
				<button mat-button (click)="submit()" class="pull-right" [ngClass]="{'bgwhite': createClass.valid}" [disabled]="inprogress || !createClass.valid">
					Submit
					<mat-progress-spinner style="position: absolute !important;
							top: 0;bottom: 0;margin: auto;right: 0;left: 0;" *ngIf="inprogress" class="tjs-spinner pipe-create" [color]="color"
					 [mode]="mode" [value]="value" [diameter]="20">
					</mat-progress-spinner>
				</button>
			</div>
		</mat-dialog-actions>
	</form>
</div>