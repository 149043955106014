<div class="work-setup-view">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h3>Work Setup</h3>
        <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit()">edit</mat-icon>
    </div>
    
    <div fxLayout="row wrap" class="border-bottom-silver padding-bottom-4">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="25" class="wrap-text mat-body-2 min-height" fxLayoutAlign="space-between start">
            <b>Location</b>
        </div>
        <div fxFlex.lt-md="100" fxFlex>
            <mat-chip-list>
                <mat-chip class="work-chip" *ngFor="let location of workEnvironment.location">
                    <p mat-line class="gray-text wrap-text chip-text">{{ location.name }}</p>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div fxLayout="row wrap" class="border-bottom-silver padding-bottom-4 margin-top-4">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="25" class="wrap-text mat-body-2 min-height" fxLayoutAlign="space-between start">
            <b>Schedule</b>
        </div>
        <div fxFlex.lt-md="100" fxFlex>
            <mat-chip-list>
                <mat-chip class="work-chip" *ngFor="let workSchedule of workEnvironment.currentWorkScheduleType">
                    <p mat-line class="gray-text wrap-text chip-text">{{ workSchedule.name }} </p>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div fxLayout="row wrap" class="border-bottom-silver padding-bottom-4 margin-top-4">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="25" class="wrap-text mat-body-2 min-height" fxLayoutAlign="space-between start">
            <b>Work station setup at home?</b>
        </div>
        <div fxFlex.lt-md="100" fxFlex>
            <mat-chip-list>
                <mat-chip class="work-chip" *ngIf="workEnvironment.workStation">
                    <p mat-line *ngIf="workEnvironment.workStation == 1" class="gray-text wrap-text chip-text"> Yes </p>
                    <p mat-line *ngIf="workEnvironment.workStation == 0" class="gray-text wrap-text chip-text"> No </p>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div fxLayout="row wrap" class="border-bottom-silver padding-bottom-4 margin-top-4">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="25" class="wrap-text mat-body-2 min-height" fxLayoutAlign="space-between start">
            <b>Preferred work environment</b>
        </div>
        <div fxFlex.lt-md="100" fxFlex>
            <mat-chip-list>
                <mat-chip class="work-chip" *ngFor="let workEnvironmentType of workEnvironment.workEnvironmentType">
                    <p mat-line class="gray-text wrap-text chip-text">{{ workEnvironmentType.name }} </p>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div fxLayout="row wrap" class="margin-top-4">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="25" class="wrap-text mat-body-2 min-height" fxLayoutAlign="space-between start">
            <b>Industries/markets most interested in</b>
        </div>
        <div fxFlex.lt-md="100" fxFlex>
            <mat-chip-list>
                <mat-chip class="work-chip" *ngFor="let markets of workEnvironment.interestedMarkets">
                    <p mat-line class="gray-text wrap-text chip-text line-height">{{ markets.name.trim() }} </p>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
</div>
