
import {catchError,  map } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, Pipe, ElementRef, Input, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, FormArray, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { StudentRegistration } from '../../Models/StudentRegistration';
import { CandidateProfile, CandidateProfileObj } from '../../Models/CandidateProfile';
import { CandidateInfoComponent } from '../../Shared/components/candidate/candidate-info/candidate-info.component';
import { CandidateResumeComponent } from '../../Shared/components/candidate/candidate-resume/candidate-resume.component';
import { CandidateLocationComponent } from '../../Shared/components/candidate/candidate-location/candidate-location.component';
import { CandidateAvailabilityCompComponent } from '../../Shared/components/candidate/candidate-availability-comp/candidate-availability-comp.component';
import { CandidateEducationComponent } from '../../Shared/components/candidate/candidate-education/candidate-education.component';
import { CandidateCertificationsComponent } from '../../Shared/components/candidate/candidate-certifications/candidate-certifications.component';
import { CandidateSkillsComponent } from '../../Shared/components/candidate/candidate-skills/candidate-skills.component';
import { CandidateLinkContactsComponent } from '../../Shared/components/candidate/candidate-link-contacts/candidate-link-contacts.component';
import { ProfileEditComponent } from '../../Pages/profile-edit/profile-edit.component';
import { LoginService } from '../../Services/login.service';
import { RegisterService } from '../../Services/register.service';
import { TalentApiService } from '../../Services/talent-api.service';
import { UserInfoService } from '../../Services/user-info.service';
import { Subject } from 'rxjs';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';

@Component({
	selector: 'app-student-registration',
	templateUrl: './student-registration.component.html',
	styleUrls: ['./student-registration.component.css']
})
export class StudentRegistrationComponent implements OnInit {

	public step: number = 1;
	stepsFinished: number = 0;
	public isLoggedIn = false;
	isProfileLoaded = false;
	userId: string = null;
	// default blank object
	candidateProfile: CandidateProfile = new CandidateProfileObj();

	constructor(private _studentRegistrationService: RegisterService,
		private _router: Router, private _loginService: LoginService,
		private _formBuilder: UntypedFormBuilder,
		private _talentAPIservice: TalentApiService, private _userInfoService: UserInfoService,
		private sharedUtils: SharedUtilitiesService) {
	}

	ngOnInit() {
		this.isLoggedIn = this._loginService.checkIfLoggedIn();
		//if user is not logged in don't allow to access registration page
		if(!this.isLoggedIn) {
			let redirect = '/';
			this._router.navigate([redirect]);
			return;
		}
		this.userId = this._userInfoService.getUser().id;
		this.getCandidateProfile();
	}

	getCandidateProfile() {
		this.sharedUtils.getMyProfile("", this.userId)
			.subscribe(
			(result: any) => {
				if (result) {
					this.candidateProfile = result;
					this.isProfileLoaded = true;
				}
			},
			error => {
				this.isProfileLoaded = true;
			}
			);
	}

	next(data, objName) {
		this.candidateProfile[objName] = data;
		this.step++;
		this.stepsFinished++;
	}

	prev(data) {
		this.step--;
	}

}
