import { Component, Input, OnInit, Output, EventEmitter, Optional, Inject, SimpleChanges } from '@angular/core';
import { UserInfoService } from '../../Services/user-info.service';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
import { PostingService } from '../../Services/posting.service';
import { TalentApiService } from '../../Services/talent-api.service';
import { PostingFilterPipe } from '../../Helpers/Pipes/posting-filter.pipe';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { CreatePostingDialogComponent } from '../dialogs/create-posting-dialog/create-posting-dialog.component';



@Component({
  selector: 'app-opportunity-detail',
  templateUrl: './opportunity-detail.component.html',
  styleUrls: ['./opportunity-detail.component.css'],
  providers: [PostingFilterPipe]

})

export class OpportunityDetailComponent implements OnInit {
  public showDetail: any = true;
  public user: any;
  showApplicants: any = false;
  applicants: any = [];
  public detail: any = null;
  userType: number;
  isMobileDevice: any;
  showHeaderBack = false;
  public postingId = null;
  public selectedTab = null;
  currTabIndex = -1;
  public isLoading = true;
  public clearInfo;
  public breakpoint: number;
  public resultDisplay: boolean = true;
  public hasEditPostingPermission: boolean = false;

  constructor(@Optional() private dialogRef: MatDialogRef<OpportunityDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public breakpointObserver: BreakpointObserver,
    private _userInfoService: UserInfoService,
    private _postingService: PostingService,
    private _talentApiService: TalentApiService,
    public _dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute,
    private sharedUtils: SharedUtilitiesService) {
    this._postingService.backButtonShareData.subscribe((res: any) => {
      this.showDetail = res;
    });
    if (this.data != null) {
      this.showHeaderBack = true;
      this.showApplicants = false;
      this.detail = data;
      this.isLoading = false;

    }
  }

  ngOnInit() {
    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileDevice = true;
        } else {
          this.isMobileDevice = false;
        }
      });



    this.breakpointObserver.observe(['(max-width: 767px)',
      '(max-width: 991px)']).subscribe(result => {
        if (result.breakpoints['(max-width: 767px)']) {
          this.breakpoint = 0;
        }
        else if (result.breakpoints['(max-width: 991px)']) {
          this.breakpoint = 1;
        }
        else {
          this.breakpoint = 2
        }
        if (this.breakpoint != 2) { this.toggleDetail(); }
      });

    if (this.data != null) {
      this.showDetail = true;
    }

    this.route.params.subscribe(params => {
      // posting specific route
      if (params.postingId && !isNaN(Number(params.postingId))) {
        this.postingId = params.postingId;
        this.selectedTab = params.landingTab;
        this.showDetailForService(this.postingId);
        if (this.selectedTab != null && this.userType != 2) {
          this.setTablIndex();
        }
      }
    });

    // will check the user's permissions and conditionally display the edit posting button
    this._userInfoService.checkMyPermission('Edit Opportunity').subscribe((result: any) => {
      this.hasEditPostingPermission = result;
    });
    this.refreshPostingData();
  }


  showDetailForService(id) {
    this.isLoading = true;
    this.detail = null;
    this._postingService.getPostingDetail(id).subscribe((result: any) => {
      if (result.error == 2) {
        this.isLoading = false;
        //this._talentApiService.snackbarMessage('Oops! "' + result.title + '" Oppontunity already deleted!');
        this.resultDisplay = false;
        return false;
      }
      this.detail = result;
      const diffInMs = Math.abs(<any>new Date() - <any>new Date(result.postingdate));
      this.detail.postingDay = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      if (this.detail.company.website != '' && this.detail.company.website.substring(0, 3) != 'http') {
        this.detail.company.website = 'http://' + this.detail.company.website;
      }

      this.isLoading = false;
      this.resultDisplay = true;
    },
      () => {
        console.log("This Oppontunity has already deleted");
        //this._talentApiService.snackbarMessage('Oops! This Oppontunity is already deleted!');
        //this._router.navigate(['postings']);
        this.isLoading = false;
        this.resultDisplay = false;
      });

    if (this.userType == 2) { // candidate type user
      this._postingService.viewCandidateOpportunityCount(id.toString(), this.user.id.toString()).subscribe((result: any) => {

      });
    }
  }

  backposting() {
    this.showHeaderBack = false;
    this.data = null;
    this.dialogRef.close();
  }


  getIndexOfObjWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(attr) && array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  toggleDetail() {
    this.showDetail = false;
    this._postingService.backButtonShareData.next(false);
  }
  getApplicantStatus() {
    if (this.applicants) {
      return this.applicants[this.getIndexOfObjWithAttr(this.applicants, 'email address', this.user.email)].status;
    } else {
      return false;
    }
  }

  // getApplicants() {
  // 	if (this.detail != null) {
  // 		this._talentApiService.getApplicants(this.detail.id).subscribe((result: any) => {
  // 			this.applicants = result;
  // 		});
  // 	}
  // }

  ngOnChanges(changes: SimpleChanges) {
    //this.getApplicants();
  }

  apply() {
    this._talentApiService.isOpportunityValid(this.detail.id).subscribe((result: any) => {
      if (result.error === 2) {
        this._talentApiService.snackbarMessage('Opps! You can not apply because of Hiring Limit has been reached for this opportunity');
        return false;
      }
      if (this.applicants.length == 0) {

        this._talentApiService.apply(this.detail.id).subscribe(() => {
          this.detail.hasApplied = true;
          this._talentApiService.snackbarMessage('Applied successfully!');
        }, () => {
          console.log('Something went wrong');
          this._talentApiService.snackbarMessage('Something Went Wrong!');
        });

      } else {
        this._talentApiService.updateApplicantStatus(this.applicants[0], this.detail.id, 2).subscribe(() => {
          this._talentApiService.snackbarMessage('Applied successfully!');
        }, () => {
          console.log('Something went wrong');
          this._talentApiService.snackbarMessage('Something Went Wrong!');
        });
      }
    }, () => {
      this._talentApiService.snackbarMessage('Opps! This Opportunity is no longer available!');
    });
  }

  tag() {
    this._postingService.tagPosting(this.detail.id).subscribe((result: any) => {

      this.detail.tag_id = result;
      this._talentApiService.snackbarMessage('Posting Tagged successfully!');
    }, () => {
      this._talentApiService.snackbarMessage('Something Went Wrong!');
    });
  }

  unTag(tagId) {
    this._postingService.unTagPosting(tagId, this.detail.id).subscribe(() => {
      this.detail.tag_id = null;
      this._talentApiService.snackbarMessage('Posting Un-tagged successfully!');
    }, () => {
      this._talentApiService.snackbarMessage('Something Went Wrong!');
    });
  }

  changeTabs(tab) {
    if (tab.index == 3) {
      this.showApplicants = true;

    }
  }

  setTablIndex() {
    switch (this.selectedTab.toUpperCase()) {
      case "ENGAGED":
        this.currTabIndex = 4;
        break;
      case "APPLICATIONS":
        this.showApplicants = true;
        this.currTabIndex = 3;
        break;
      case "COMPANYINFO":
        this.currTabIndex = 2;
        break;
      case "LOGISTICS":
        this.currTabIndex = 1;
        break;
      case "JOBSUMMARY":
        this.currTabIndex = 0;
        break;
      default:
    }
  }

  onSwipe(evt) {
    if (!this.isMobileDevice) return;
    const horizontalDirection = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';

    if (horizontalDirection == 'left') {
      if (this.currTabIndex < 5) {
        if (this.currTabIndex == -1) {
          this.currTabIndex = 1;
        }
        else {
          this.currTabIndex++;
        }
      }
    }
    else if (horizontalDirection == 'right') {
      if (this.currTabIndex > 0) {
        this.currTabIndex--;
      }
    }
  }

  setApplicants(value) {
    this.showApplicants = value;
  }

  // notify current component to update posting data
  refreshPostingData() {
    this._postingService.refreshPostingData.subscribe((res: any) => {
      if (res != undefined && res.value && res.id > 0) {
        this.showDetailForService(this.detail.id); // Fetch posting details again, after editing
      }
      else if (res != undefined && res.value && res.id == 0) {
        this._router.navigate(['postings']);// if posting deleted
      }
    })
  }

  OpenEditPopup() {
    let dialog = this._dialog.open(CreatePostingDialogComponent, {
      height: 'auto',
      width: '100%',
      disableClose: true,
      data: this.detail,
      panelClass: this.isMobileDevice ? 'posting_popup' : ''
    });
    dialog.afterClosed().subscribe((result) => {
    });
  }

  getWorkSetup() {
    if (this.isMobileDevice && this.detail.remote != null) {
      return this.detail.remote.toString().replaceAll(',', '\n') + '\n\n';
    }
    else if (this.detail.remote != null) {
      return this.detail.remote.toString().replaceAll(',', ',  ')
    }
    return this.detail.remote;
  }
}
