<mat-card fxLayout="column" [ngClass.gt-xs]="'medium-card'">
  <mat-card-title fxFlex="10">
    <h2 [ngSwitch]="userType">      
        <span *ngSwitchCase="2">News Feed</span>
        <span *ngSwitchCase="3">Market Insights</span>
        <span *ngSwitchDefault>News Feed</span>      
    </h2>
  </mat-card-title>
  <mat-divider fxFlex="0" [inset]="true"></mat-divider>
  <mat-card-content fxFlex="90">
    <div *ngIf="articlesList.length == 0" fxLayout="column" fxLayoutAlign="space-around center" fxFlex>
      <mat-divider fxFlex="20%" [inset]="true"></mat-divider>
      <div fxFlex="80%">
        <h2 style="color:gray">No News to Display</h2>
      </div>
    </div>
    <div class="display-block" *ngIf="articlesList.length != 0" fxFill [style.overflow]="'auto'">
      <mat-selection-list fxFlex="auto" style="padding-top:0px;" [multiple]="false">
        <div *ngFor="let a of articlesList; let i=index">
          <mat-list-item (click)="open(a)"  class="mat-list-item-word-wrap" style="cursor: pointer;">
            <app-company-logo matListAvatar [name]="a.company" [type]="'company'"></app-company-logo>
            <div mat-line> <span class="mat-display-3">{{ a.source }}</span></div>
            <div mat-line><span class="mat-body-2">{{ a.title }}</span></div>
            <div mat-line><span class="mat-body-2">{{ a.date }}</span></div>
          </mat-list-item>
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </mat-selection-list>
    </div>
  </mat-card-content>
</mat-card>
