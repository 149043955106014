<div *ngIf="!isDataAvailable" fxLayout="row" fxLayoutAlign="center center" >
	<mat-spinner></mat-spinner>
</div>
<div *ngIf="isDataAvailable" class="mat-typography" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding" fxLayout="row" fxLayoutAlign="center center">
	<div [ngClass.gt-md]="'max-width'" fxLayout="column" fxFlex.lt-lg="100%" fxFlex.lt-sm="grow">
		<mat-card class="profile-card">
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="column" fxLayoutAlign="start stretch" class="border-bottom" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding">
					<app-candidate-info-view *ngIf="isDataAvailable"
																	 [ownProfile]="ownProfile"
																	 [postingId]="this.postingId"
																	 [jobCurrentStatus]="this.jobCurrentStatus"
						[candidateInfo]="candidateProfile">
					</app-candidate-info-view>
				</div>
				<div fxLayout="column" fxLayoutAlign="start stretch" class="border-bottom" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding">
					<div *ngIf="ownProfile">
						<app-candidate-resume-view *ngIf="isDataAvailable" [ownProfile]="ownProfile"
						[resume]="candidateProfile.resume">
						</app-candidate-resume-view>
					</div>
					<div *ngIf="!ownProfile">
						<app-skills-view *ngIf="isDataAvailable" [ownProfile]="ownProfile"
						[skills]="candidateProfile.skills"></app-skills-view>
					</div>
				</div>
				<div fxLayout.gt-xs="row" fxLayout.lt-sm="column" class="border-bottom">
					<div fxFlex.gt-xs="50" fxLayout="column" fxLayoutAlign="start stretch" [ngClass.gt-sm]="'large-padding border-right'"
							 [ngClass.sm]="'border-right'" [ngClass.lt-sm]="'small-padding border-bottom'">
						<app-education-view *ngIf="isDataAvailable" [ownProfile]="ownProfile"
						[education]="candidateProfile.education"></app-education-view>
					</div>
					<div fxFlex.gt-xs="50" fxLayout="column" fxLayoutAlign="start stretch" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding">
						<app-courses-and-certifications-view *ngIf="isDataAvailable" [ownProfile]="ownProfile" 
							[coursesAndCertifications]="candidateProfile.candidateCertifications">
						</app-courses-and-certifications-view>
					</div>
				</div>
				<div fxLayout="column" fxLayoutAlign="start stretch" class="border-bottom" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding">
					<app-work-experience-view *ngIf="isDataAvailable" [ownProfile]="ownProfile"
					[experiences]="candidateProfile.workExperience" (saveExperience)="saveExperience($event)"></app-work-experience-view>
				</div>
				<div *ngIf="ownProfile" fxLayout="column" fxLayoutAlign="start stretch" class="border-bottom" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding">
					<app-skills-view *ngIf="isDataAvailable" [ownProfile]="ownProfile"
					[skills]="candidateProfile.skills"></app-skills-view>
				</div>
				<div fxLayout="column" fxLayoutAlign="start stretch" class="border-bottom" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding">
					<app-additional-contacts-view *ngIf="isDataAvailable" [ownProfile]="ownProfile"
					[additionalContacts]="candidateProfile.linkContacts"></app-additional-contacts-view>
				</div>
				<div fxLayout="column" fxLayoutAlign="start stretch" class="border-bottom" ngClass.gt-sm="large-padding" ngClass.lt-md="small-padding">
					<app-work-setup-view *ngIf="isDataAvailable" [ownProfile]="ownProfile"
					[workEnvironment]="candidateProfile.workEnvironment"></app-work-setup-view>
				</div>
			</div>
		</mat-card>
	</div>
</div>
