import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TalentApiService } from '../../../Services/talent-api.service';
import { ENTER } from '@angular/cdk/keycodes';
import * as _ from 'underscore';
import { CommonConfirmPopupComponent } from '../common-confirm-popup/common-confirm-popup.component';
import { ShortlistService } from '../../../Services/shortlist.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { UserInfoService } from '../../../Services/user-info.service';
import { SkillNode } from '../../../Models/SkillNode';

@Component({
  selector: 'app-create-shortlist-dialog',
  templateUrl: './create-shortlist-dialog.component.html',
  styleUrls: ['./create-shortlist-dialog.component.css']
})


export class CreateShortlistDialogComponent implements OnInit {
  shortListForm: UntypedFormGroup;
  shortlistId = 0;
  skillsList = [];
  shortlistImages = [];
  selectedImage = 0;
  selectedSkills = [];
  isMobileDevice: any = false;
  allSkills: any[] = [];
  skillCtrl = new UntypedFormControl();
  filteredSkill: Observable<string[]>;
  @ViewChild('skillName') skillName: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  readonly separatorKeysCodes: number[] = [ENTER];
  public hasDeleteShortlistPermission: boolean = false;


  constructor(public breakpointObserver: BreakpointObserver, public _dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateShortlistDialogComponent>,
    private _userInfoService: UserInfoService,
    private _router: Router, private _talentApiService: TalentApiService,
    private _shortListService: ShortlistService,
    private _builder: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public editData: any) {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileDevice = true;
        }
      });
  }

  ngOnInit(): void {
    this.buildShortListForm();
    this.getSkillsList();
    this.getShortlistImages();

    // will check the user's permissions and conditionally display the edit posting button
    this._userInfoService.checkMyPermission('Delete Shortlist').subscribe((result: any) => {
      this.hasDeleteShortlistPermission = result;
    });

  }

  addChildSkill(skills) {
    for (var i = 0; i < skills.length; i++) {
      this.allSkills.push(skills[i]);
      if (skills[i].children.length > 0) {
        this.addChildSkill(skills[i].children);
      }
    }
  }


  getSkillsList() {
    this._talentApiService.getSkills()
      .subscribe(
        (result: any) => {
          this.addChildSkill(result);
          this.filterSkills();          
          this.editFillForm();
        },
        error => {
          this._talentApiService.snackbarMessage('Unable to load skills list');
        }
      );
  }


  addSkillOnBlur(event: FocusEvent, skillList: MatChipInput) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {
        input: this.skillName.nativeElement, value: this.skillName.nativeElement.value,
        chipInput: skillList
      };
      this.addSkill(matChipEvent);
    }
  }

  addSkill(event) {
    const value = event.value;
    const input = event.input;
    if (!value) return;
    const alreadyInList = this.selectedSkills.filter(skill => skill.toLowerCase().indexOf(value) === 0);
    if (alreadyInList.length == 0) {
      const checkInList = this.allSkills.filter(skill => skill.name.toLowerCase().indexOf(value) === 0);
      if (checkInList.length > 0) {
        if ((checkInList[0].name || '').trim()) {
          this.selectedSkills.push(checkInList[0].name.trim());
          this.shortListForm.patchValue({ skills: this.selectedSkills });

        }
      }
    }
    //reset the input
    if (input) {
      input.value = '';
    }
    this.shortListForm.markAsDirty();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allSkills.filter(skill => skill.name.toLowerCase().indexOf(filterValue) === 0);
  }

  selectSkill(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    const alreadyInList = this.selectedSkills.indexOf(value);
    if (alreadyInList == -1) {
      this.selectedSkills.push(event.option.viewValue);
      this.shortListForm.patchValue({ skills: this.selectedSkills });
    }
    this.skillName.nativeElement.value = '';
    this.skillCtrl.setValue(null);
  }

  removeSkill(value) {
    const index: number = this.selectedSkills.indexOf(value);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
      this.shortListForm.patchValue({ skills: this.selectedSkills });
    }
    this.shortListForm.markAsDirty();
  }

  filterSkills() {
    this.filteredSkill = this.skillCtrl.valueChanges.pipe(
      startWith(null),
      map((skill: string | null) => skill ? this._filter(skill) : this.allSkills.slice())
    );
  }

  getShortlistImages() {
    this._shortListService.getShortlistImage()
      .subscribe(
        (result: any) => {
          this.shortlistImages = result['shortlistImage'];
        },
        error => {
          this._talentApiService.snackbarMessage('Unable to load shortlist images');
        }
      );
  }

  getContentCount(htmlCont) {
    if (htmlCont == '') {
      return 0;
    }
    var span = document.createElement('span');
    span.innerHTML = htmlCont;
    return span.textContent.length || span.innerText.length;
  }

  selectSector(id) {
    this.selectedImage = id;
    this.shortListForm.patchValue({ fk_display_image: parseInt(id) });
    this.shortListForm.markAsDirty();
  }

  editFillForm() {
    this.shortlistId = parseInt(this.editData.sid);
    this.selectedImage = this.editData.fk_display_image;
    this.getSelectedSkillList(this.editData.skills);
    this.shortListForm.patchValue({
      id: parseInt(this.editData.sid),
      name: this.editData.name,
      fk_display_image: parseInt(this.editData.fk_display_image),
      description: this.editData.description,
      skills: this.selectedSkills
    });
  }

  getSelectedSkillList(arr) {
    this.selectedSkills = [];
    var data = [];
    arr.forEach(function (value) {
      data.push(value.name.toString());
    });
    this.selectedSkills = data;

  }

  buildShortListForm() {
    this.shortListForm = this._builder.group({
      id: 0,
      name: ['', Validators.required],
      fk_display_image: [0],
      description: ['', Validators.maxLength(255)],
      skills: [],
    });
  }

  get f() { return this.shortListForm.controls; }



  confirmDiscard() {

    if (!this.shortListForm.dirty) {
      this.dialogRef.close();
      return;
    }

    let dialog;
    var confirmMessage = 'Are you sure you want to discard this Posting?'
    if (this.shortlistId > 0) {
      confirmMessage = 'Are you sure you want to discard your edits?'
    }

    if (!dialog == null) {
    } else {
      dialog = this._dialog.open(CommonConfirmPopupComponent, {
        height: '175px',
        width: this.isMobileDevice ? '88%' : '36.66%',
        disableClose: false,
      });
      dialog.componentInstance.post_type = 'Shortlist';
      dialog.componentInstance.confirm_message = confirmMessage;
      dialog.componentInstance.discardConfirm = true;
    }
    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this.dialogRef.close();
        } else {

        }
      });
  }

  deleteShortlist() {
    let dialog;
    if (!dialog == null) {
    } else {
      dialog = this._dialog.open(CommonConfirmPopupComponent, {
        height: '175',
        width: this.isMobileDevice ? '88%' : '36.66%',
        disableClose: false,
      });
      dialog.componentInstance.post_type = 'Shortlist';
      dialog.componentInstance.confirm_message = 'Are you sure you want to delete this shortlist?';
      dialog.componentInstance.discardConfirm = true;
    }
    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this._shortListService.deleteShortlist(this.shortlistId)
            .subscribe(
              (result: any) => {
                if (result) {
                  this._shortListService.refreshShortlistData.next({ id: 0, value: true }); //Refresh shortlist list								
                  this._talentApiService.snackbarMessage('Shortlist has been deleted successfully!');
                  this.dialogRef.close();
                  this._router.navigate(['shortlist']);
                } else {
                  this._talentApiService.snackbarMessage(result.msg);
                }
              },
              error => {
              }
            );
        } else {

        }
      });
  }


  submit() {
    if (this.shortListForm.invalid) {
      return;
    }
    this._shortListService.postShortlist(this.shortListForm.value)
      .subscribe(
        (result: any) => {
          if (result) {
            this.shortListForm.value.id = result;
            this._shortListService.refreshShortlistData.next({ id: this.shortListForm.value.id, value: true }); //Refresh shortlist list						
            this._talentApiService.snackbarMessage('Shortlist submitted successfully!');
            //this.dialogRef.close();
            this._dialog.closeAll();
            //this._router.navigate(['shortlist', this.shortListForm.value.id]);
          } else {
            this._talentApiService.snackbarMessage(result.msg);
          }
        },
        error => {
        }
      );
  }
}
