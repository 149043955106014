<div class="candidate-info-containter" [ngClass.md]="'darker-right-side'" [ngClass.gt-md]="'darker-right-side'">
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="98" fxFlexOffset="2">
            <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="!edit">
                <div fxLayout="column" fxFlex="100">
                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
                            <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
                        </div>
                        <div fxLayout="column" fxFlex="70" *ngIf="!edit">
                            <h1 class="title-bold">Create Profile</h1>
                        </div>
                        <div fxLayout="column" fxFlex="70" *ngIf="edit">
                            <h1>Edit Profile</h1>
                        </div>
                        <div fxLayout="column" fxFlex="20"></div>
                    </div>
                </div>
            </div>
            
            <form name="candidateInfoForm" id="candidateInfoForm" [formGroup]="form" autocomplete="off" novalidate>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <div fxLayout="column" fxFlex.md="50" fxFlex.gt-md="50" fxFlex.lt-md="100">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between">
                            <div fxLayout="column" fxFlex.md="48" fxFlex.gt-md="48" fxFlex.lt-md="100">
                                <mat-form-field color="accent" appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input matInput type="text" name="firstName" id="firstName" formControlName="firstName">
                                    <mat-error *ngIf="form.get('firstName').hasError('required') || form.get('firstName').hasError('pattern')">
                                        Please enter a valid First Name
                                    </mat-error>
                                    <mat-error *ngIf="form.get('firstName').hasError('maxlength')">
                                        First name must be under 255 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxFlex.md="48" fxFlex.gt-md="48" fxFlex.lt-md="100">
                                <mat-form-field color="accent" appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput type="text" name="lastName" id="lastName" formControlName="lastName">
                                    <mat-error *ngIf="form.get('lastName').hasError('required') || form.get('lastName').hasError('pattern')">
                                        Please enter a valid Last Name
                                    </mat-error>
                                    <mat-error *ngIf="form.get('lastName').hasError('maxlength')">
                                        Last name must be under 255 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div fxLayout="column" fxFlex="100">
                                <mat-form-field color="accent" appearance="outline">
                                    <mat-label>Phone Number</mat-label>
                                    <input matInput type="text" name="phone" id="phone" appValidatePhone formControlName="phone"
                                    [showMaskTyped]="true" mask="(000) 000-0000">
                                    <mat-error *ngIf="form.get('phone').hasError('isPhoneValid')">
                                        Please enter valid phone number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <h4 class="remove-margin">Add Profile Picture</h4>
                        <div fxLayout="row wrap" fxFlexOffset="20px" fxLayoutAlign="space-between">
                            <div fxLayout="column" fxFlex="grow" fxFlex.gt-sm="50">
                                <mat-card class="">
                                    <div class="image-cropper-height" fxLayoutAlign="center center">
                                        <img class="round" [src]="form.get('profilePic').value" onError="this.src='../../assets/img/User-blue-icon.png';" />
                                    </div>
                                    <div *ngIf="imageError">
                                      <p class="help-block small animated fadeInDown mat-error ng-star-inserted" >
                                        Please upload valid image, (i.e png, jpeg, jpg)
                                      </p>
                                    </div>
                                    <mat-card-actions fxLayoutAlign="center center">
                                        <button class="set-max-width" type="button" mat-button mat-stroked-button color="primary" (click)="profilepic.click()">
                                            {{form.get('profilePic').value ? 'Update' : 'Upload' }}
                                            <input #profilepic type="file"
                                                   (change)="fileChangeEvent($event)"
                                                   mode="data-url"
                                                   name="pic-upload"
                                                   id="pic-upload"
                                                   style="display:none;"
                                                   accept="image/x-png,image/png,image/gif,image/jpeg,image/PNG,image/GIF,image/JPEG" />
                                        </button>
                                        
                                        <button *ngIf="form.get('profilePic').value"
                                                mat-button color="primary"
                                                (click)="removeProfilePic()"
                                                matTooltip="Remove Profile Pic">Remove</button>
                                    </mat-card-actions>
                                </mat-card>
                                <div class="paddingBottom">
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="grow" fxFlex.gt-sm="50" class="image-cropper-height" [hidden]="form.get('profilePic').value == null">
                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [resizeToWidth]="128"
                                        format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.md="46" fxFlex.gt-md="46" fxHide.lt-md="true">
                        <app-logo [tenant]="tenant" [type]="'candidate-reg-profile'"></app-logo>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxLayout="column" class="next-button-padding">
                        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
