<div id="add-modal" *ngIf="actionType=='add'" class="timesheet-popup " role="form">
  <form name="addForm" id="addForm" #addForm="ngForm" class="popup_class_new"  novalidate ng-cloak>
    <div class="modal-content">
    <mat-toolbar mat-toolbar class="mat-default-theme">
      <div class="mat-toolbar-tools">
        <h1 mat-dialog-title class="calendar-md-title" style="color:black;font-weight:700">Add New Time Entry</h1>
      
        <button mat-icon-button class="close_bt" (click)="closeDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </mat-toolbar>
    <mat-dialog-content>
    <div class="modal-body">
      <div class="row">
          <div class="col-md-6">
            <mat-form-field color="accent" appearance="outline">
              <mat-select placeholder="Select Posting" [(ngModel)]="adding.selopportunity" #selopportunity="ngModel" name="selopportunity" id="selopportunity" class="form-control" required>
                <mat-option *ngFor="let opportunity of opportunityList" [value]="opportunity.id">
                  {{ opportunity.title }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selopportunity.hasError('required')" class="help-block small animated fadeInDown">
                  Posting is required!
              </mat-error>

            </mat-form-field>
          </div>
         
      </div>

      <div class="row">

          <div class="col-md-6">
              <mat-form-field color="accent" appearance="outline">
              
                <input matInput #startDate="ngModel" [matDatepicker]="startDatepicker"         [max]="currdate" name="startDate" placeholder="Date" [(ngModel)]="adding.date" required>
  
                <mat-datepicker-toggle matSuffix [for]="startDatepicker"> </mat-datepicker-toggle>
                <mat-datepicker #startDatepicker></mat-datepicker>
  
                 <mat-error *ngIf="startDate.hasError('matDatepickerParse')">Please Select Valid Date.</mat-error>
  
                <mat-error *ngIf="!startDate.hasError('matDatepickerParse') && startDate.hasError('required')">Date is required!</mat-error>
         
              </mat-form-field>
            </div>

          <div class="col-sm-6">
              <mat-form-field color="accent" appearance="outline" class="md-block">
                  <!-- <label>Posting Title</label> -->
                  <input placeholder="Time (Hrs)" matInput name="add_entered_time" id="add_entered_time" type="number" [(ngModel)]="adding.time_entered" #add_entered_time="ngModel" max="24" required>

                  <mat-error *ngIf="add_entered_time.hasError('required')">Time is required</mat-error>

                  <mat-error *ngIf="!add_entered_time.hasError('required') && add_entered_time.hasError('max')">Can not be more than 24 hours</mat-error>
                 
                </mat-form-field>
          </div>
      </div><!-- row -->       
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 pull-right">
                <button mat-raised-button color="primary" class="md-raised  save-bt add_btt "  [disabled]="!addForm.valid" (click)="addNew()" >Add</button>
                <button mat-raised-button color="warn" class="Cancel-btt" (click)="closeDialog()">Cancel</button>
            </div>
        </div>
    </div>

  </mat-dialog-content>
  </div>
  </form>
</div>

<!-- // delete timesheet -->

<div *ngIf="actionType=='delete'" id="deleting-modal" class="timesheet-popup mat-dialog-container" role="form">
    <form name="deletingForm" id="deletingForm" #deletingForm="ngForm" class="popup_class_new"  novalidate ng-cloak>
      <div class="modal-content">
        <mat-toolbar mat-toolbar class="mat-default-theme">
          <div class="mat-toolbar-tools">
            <h1 mat-dialog-title class="calendar-md-title" style="color:black;font-weight:700">Confirm Action</h1>
            
            <button mat-icon-button class="mat-icon-button close_bt" (click)="closeDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </mat-toolbar>
        <mat-dialog-content>
          <div class="modal-body">
            <div class="row">
                <div class="col-sm-12">
                        <div><b>Posting Title: </b>{{deletingData.title}}</div>
                        <div><b>Date: </b>
                           {{deletingData.date | date}}
                        </div>
                        <div><b>Time Entered:</b> {{deletingData.time_entered}}</div>
                        <div>Are you sure you want to remove this timeentry?</div>
                  </div>
              </div>

              <div class="modal-footer">
                  <div class="row">
                      <div class="col-md-12">
                         
                          <button mat-raised-button color="warn" class="save-bt" (click)="removeTimeEntry('student')">Yes</button>

                          <button mat-raised-button color="primary" class="md-raised Cancel-btt" (click)="closeDialog()" >No</button>

                      </div>
                  </div>
              </div>
          </div>
      </mat-dialog-content>
    </div>
   </form>
</div>


<!-- // edit timesheet -->

<div id="editing-modal" *ngIf="actionType=='edit'" class="timesheet-popup" role="form">
    <form name="editingForm" id="editingForm" #editingForm="ngForm" class="popup_class_new" novalidate ng-cloak>
      <div class="modal-content">
      <mat-toolbar mat-toolbar class="mat-default-theme">
        <div class="mat-toolbar-tools">
          <h1 mat-dialog-title class="calendar-md-title" style="color:black;font-weight:700">Update Time Entry</h1>
           
          <button mat-icon-button class="close_bt" (click)="closeDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>
      <mat-dialog-content>
      <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div><b>Posting:</b> {{editing.title}}</div>
            </div>
           
        </div>

        <div class="row">
            <div class="col-md-6">&nbsp;</div>

        </div>
  
        <div class="row">

            <div class="col-md-6">
                <mat-form-field color="accent" appearance="outline">
                
                  <input matInput #editstartDate="ngModel" [matDatepicker]="editstartDatepicker" [max]="currdate" name="editstartDate" placeholder="Date" [(ngModel)]="editing.date" required>
    
                  <mat-datepicker-toggle matSuffix [for]="editstartDatepicker"> </mat-datepicker-toggle>
                  <mat-datepicker #editstartDatepicker></mat-datepicker>
    
                   <mat-error *ngIf="editstartDate.hasError('matDatepickerParse')">Please Select Valid Date.</mat-error>
    
                  <mat-error *ngIf="!editstartDate.hasError('matDatepickerParse') && editstartDate.hasError('required')">Date is required!</mat-error>
           
                </mat-form-field>
              </div>

            <div class="col-sm-6">
                <mat-form-field color="accent" appearance="outline" class="md-block">
                    <!-- <label>Posting Title</label> -->
                    <input placeholder="Time (Hrs)" matInput name="entered_time" id="entered_time" type="number" [(ngModel)]="editing.time_entered" #entered_time="ngModel" max="24" required>
  
                    <mat-error *ngIf="entered_time.hasError('required')">Time is required</mat-error>
  
                    <mat-error *ngIf="!entered_time.hasError('required') && entered_time.hasError('max')">Can not be more than 24 hours</mat-error>
                   
                  </mat-form-field>
            </div>
        </div><!-- row -->       
      </div>
      <div class="modal-footer">
          <div class="row">
              <div class="col-md-12 pull-right">
                  <button mat-raised-button color="primary" class="md-raised save-bt"  [disabled]="!editingForm.valid" (click)="modalSave()" >Save</button>
                  <button mat-raised-button color="warn" class="Cancel-btt" (click)="closeDialog()">Cancel</button>
              </div>
          </div>
      </div>
  
    </mat-dialog-content>
    </div>
  </form>
</div>


<!-- // Employer side dialogs -->

<div *ngIf="actionType=='updateEmp'" id="deleting-modal" class="timesheet-popup mat-dialog-container" role="form">
    <form name="deletingForm" id="deletingForm" class="popup_class_new" #deletingForm="ngForm"  novalidate ng-cloak>
      <div class="modal-content">
        <mat-toolbar mat-toolbar class="mat-default-theme">
          <div class="mat-toolbar-tools">
            <h1 mat-dialog-title class="calendar-md-title" style="color:black;font-weight:700">Confirm Action</h1>
            
            <button mat-icon-button class="close_bt" (click)="closeDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </mat-toolbar>
        <mat-dialog-content>
          <div class="modal-body">
            <div class="row">
                <div class="col-sm-12 pddin">
                    <div><b>Student Name:</b> {{statusupdate.name}}</div>
                    <div><b>Posting Title: </b>{{statusupdate.title}}</div>
                    <div><b>Date: </b>
                        {{statusupdate.date | date}}
                    </div>
                    <div><b>Time Entered:</b> {{statusupdate.time_entered}}</div>
                    <div *ngIf="statusupdate.status == 1">Are you sure you want to Approve this timeentry?</div>
                    <div *ngIf="statusupdate.status == -1">Are you sure you want to Disapprove this timeentry?</div>
                  </div>
              </div>

              <div class="modal-footer ft_padding">
                  <div class="row">
                      <div class="col-md-12">
                         
                          <button mat-raised-button color="warn" class=" Cancel-btt" (click)="updateStatus()">Yes</button>

                          <button mat-raised-button color="primary" class="md-raised save-bt" (click)="closeDialog()" >Cancel</button>

                      </div>
                  </div>
              </div>
          </div>
      </mat-dialog-content>
    </div>
   </form>
</div>

<div *ngIf="actionType=='deleteEmp'" id="deleting-modal" class="timesheet-popup mat-dialog-container" role="form">
    <form name="deletingForm" id="deletingForm" class="popup_class_new" #deletingForm="ngForm"  novalidate ng-cloak>
      <div class="modal-content">
        <mat-toolbar mat-toolbar class="mat-default-theme">
          <div class="mat-toolbar-tools">
            <h1 mat-dialog-title class="calendar-md-title" style="color:black;font-weight:700">Confirm Action</h1>
            
            <button mat-icon-button class="close_bt" (click)="closeDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </mat-toolbar>
        <mat-dialog-content>
          <div class="modal-body">
            <div class="row">
                <div class="col-sm-12 pddin">
                    <div><b>Student Name:</b> {{deletingData.name}}</div>
                    <div><b>Posting Title: </b>{{deletingData.title}}</div>
                    <div><b>Date: </b>
                       {{deletingData.date | date}}
                    </div>
                    <div><b>Time Entered:</b> {{deletingData.time_entered}}</div>
                    <div>Are you sure you want to remove this timeentry?</div>
                  </div>
              </div>

              <div class="modal-footer ft_padding">
                  <div class="row">
                      <div class="col-md-12">
                         
                          <button mat-raised-button color="warn" class="Cancel-btt" (click)="removeTimeEntry('supervisor')">Yes</button>

                          <button mat-raised-button color="primary"  class="md-raised save-bt" (click)="closeDialog()" >No</button>

                      </div>
                  </div>
              </div>
          </div>
      </mat-dialog-content>
    </div>
   </form>
</div>