<!-- If feedback request is for single student -->
<div *ngIf="feedbackRequestFor == 0" class="posting_create open_report">
	<form name="fbrequest" id="fbrequest" class="" #fbrequest="ngForm" novalidate>
		<mat-toolbar mat-toolbar color="primary" class=" background_ch internship-modal mat-default-theme">
			<h3 mat-dialog-title class="calendar-md-title" style="display:block;
				float:left; width: 100%;">Request Feedback for {{sfbfeedbackEmailTemplate.name}}</h3>
			<button id="closepostingmodal" class="mat-icon-button cancel_b pull-right" (click)="closeDialog()">
				<i class="fa fa-times" aria-hidden="true" tooltip="Close  Create Pipeline."></i>
			</button>

		</mat-toolbar>
		<mat-dialog-content class="popup_sc">
			<mat-card class="md-padding panel-body">
				<div class="row">
					<div class="col-lg-6">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<input matInput name="fbto" placeholder="To" id="fbto" type="text" [(ngModel)]="sfbfeedbackEmailTemplate.to" #fbToModel="ngModel" minlength="3"
							 maxlength="65" required>
							<mat-error *ngIf="fbToModel.hasError('required');" class="help-block small animated fadeInDown">
								Email is required for feedback request.
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-lg-6">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<input matInput name="fbsubject" placeholder="Subject" id="fbsubject" type="text" [(ngModel)]="sfbfeedbackEmailTemplate.subject" #fbSubjectModel="ngModel"
							 minlength="3" maxlength="65" required>
							<mat-error *ngIf="fbSubjectModel.hasError('required');" class="help-block small animated fadeInDown">
								Subject is required for feedback request.
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 add_sk">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<textarea matInput name="fbmessage" id="fbmessage" [(ngModel)]="sfbfeedbackEmailTemplate.message" #fbMessageModel="ngModel" rows="8" md-select-on-focus
							 class="text_areat" matAutosizeMinRows="0" placeholder="Body" matAutosizeMaxRows="10" matTextareaAutosize md-select-on-focus></textarea>
						</mat-form-field>
						<mat-error *ngIf="fbMessageModel.hasError('required');" class="help-block small animated fadeInDown">
							Message body is required for feedback request.
						</mat-error>
						<div class="clearfix"></div>
					</div>
				</div>
			</mat-card>
		</mat-dialog-content>

		<mat-dialog-actions class="footer_popUp posting_pop mat-dialog-actions" style="    border-top: 1px solid rgba(0,0,0,0.12);">
			<div class="col-md-12">
				<div class="alert alert-danger reanimate pipeline-modal-error" role="alert" *ngIf="error">There was an error submitting feedback request.</div>
				<button mat-raised-button (click)="closeDialog()" class="pull-left" style="position: relative;">
					Cancel
				</button>
				<button mat-raised-button color="primary" (click)="sendEmailIndividual()" class="pull-right" style="position: relative;" [disabled]="submitting || !fbrequest.valid">
					<span *ngIf="!submitting">Submit</span>
					<mat-progress-spinner style="position: absolute !important; top: 0;bottom: 0;margin: auto;right: 0;left: 0;" *ngIf="submitting"
					 class="tjs-spinner pipe-create" [color]="color" [mode]="mode" [value]="value" [diameter]="20">
					</mat-progress-spinner>
					<span *ngIf="submitting">Please wait...</span>
				</button>
			</div>
		</mat-dialog-actions>
	</form>
</div>

<!-- If feedback request is for multiple students -->
<div *ngIf="feedbackRequestFor == 1" class="posting_create open_report">
	<form name="fbrequestmulti" id="fbrequestmulti" class="" #fbrequestmulti="ngForm" novalidate>
		<mat-toolbar mat-toolbar color="primary" class="background_ch internship-modal mat-default-theme">
			<mat-toolbar-row>
				<h3 mat-dialog-title class="calendar-md-title" style="display:block;
				float:left; width: 100%;">Select a Class to Send Feedback</h3>
				<span mat-flex></span>
				<button id="closepostingmodal" class="mat-icon-button cancel_b pull-right" (click)="closeDialog()">
					<i class="fa fa-times" aria-hidden="true" tooltip="Close  Create Pipeline."></i>
				</button>
			</mat-toolbar-row>
		</mat-toolbar>

		<mat-dialog-content class="popup_sc">
			<mat-card class="md-padding panel-body">
				<div class="row">
					<div class="col-lg-6">
						<label>Select Class</label>
						<mat-select name="selectedCourse" [(ngModel)]="selectedClass" required>
							<mat-option *ngFor="let class of classList" [value]="class.name" [disabled]="class.regStatus > 0">
								{{class.name}}
								<span *ngIf="class.regStatus==1"> - (Pending acceptance from instructor)</span>
							</mat-option>
						</mat-select>
					</div>
					<div class="col-lg-6">
						<mat-form-field color="accent" appearance="outline" class="md-block">
							<input matInput name="signature" placeholder="Signature" id="signature" type="text" [(ngModel)]="signature">
						</mat-form-field>
					</div>
				</div>
			</mat-card>
		</mat-dialog-content>
		<mat-dialog-actions class="footer_popUp posting_pop mat-dialog-actions" style="border-top: 1px solid rgba(0,0,0,0.12);">
			<div class="col-lg-12 no-padding strip-margins">
				<div class="alert alert-danger reanimate pipeline-modal-error" role="alert" *ngIf="createError">An error occured. Please try again.</div>
			</div>
			<div class="col-md-6">
				<button mat-button mat-raised-button (click)="closeDialog()" class="pull-left">
					Cancel
				</button>
			</div>
			<div class="col-md-6">
				<button mat-button mat-raised-button color="primary" (click)="sendEmailMultiple()" class="pull-right" [disabled]="submitting || !fbrequestmulti.valid">
					<span *ngIf="!submitting">Submit</span>
					<mat-progress-spinner style="position: absolute !important; top: 0;bottom: 0;margin: auto;right: 0;left: 0;" *ngIf="submitting"
					 class="tjs-spinner pipe-create" [color]="color" [mode]="mode" [value]="value" [diameter]="20">
					</mat-progress-spinner>
					<span *ngIf="submitting">Please wait...</span>
				</button>
			</div>
		</mat-dialog-actions>
	</form>
</div>