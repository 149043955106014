<div class="candidate-registration full-page-registration">
	<div fxLayout="row" fxLayoutAlign="center center">
		<div fxLayout="column" fxFlex="100">
			<div fxLayout="row" fxFlexOffset="30px" fxLayoutAlign="center center">
				<div fxLayout="column" fxFlex="80">
					<mat-card class="content-part reg-card">
						<div fxLayout="row" fxFlex="grow" fxLayoutAlign="center stretch">
                            <div fxLayout="column" fxFlex="10" *ngIf="!isProfileLoaded">
								<mat-spinner></mat-spinner>
							</div>
							<div fxLayout="column" fxFlex="100" *ngIf="isProfileLoaded">
								<app-candidate-diversity-questions [edit]="true" [diversityQuestion]="candidateProfile.diversityQuestion"></app-candidate-diversity-questions>
							</div>
						</div>
					</mat-card>
				</div>
			</div>
			<div fxLayout="row" fxFlexOffset="30px"></div>
		</div>
	</div>
</div>
