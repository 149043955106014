<div fxLayout="column" FxlayoutAlign="space-between center">
	<mat-card fxFlex="25">
		<mat-card-title>Admin Skills Area</mat-card-title>
		<mat-card-content>
			
			
			<strong>Instructions:</strong>
			<ol>
				<li>Add a skill by clicking the "<strong>Add New Skill</strong>" button below.</li>
				<li>Skill may be edited by clicking on the "Edit" button in the table row.</li>
				<li>From the edit skill form you may "Delete" the skill and any references to it.</li>
				<li>Skills which are flagged as 'Review' will not be accessible to users (students). They will be accessible after getting reviewed.
				</li>
				<li>If any parent skill is deleted then its direct children skills will be set to root nodes and flagged for review whereas the rest of the hierarchy/tree (if any) under chilren nodes will remain as it is.</li>
			</ol>
		</mat-card-content>
		<mat-card-footer>
			<div fxLayout="row">
				<div fxFlex="50" fxFlexOffset="5">
					<p>
						<em>Students can enter new skills while registration / profile edit. These skills are flagged for
							<strong>review</strong>.
						</em>
					</p>
				</div>
				<div fxFlex>
					<button mat-raised-button color="accent" (click)="openEditReviewModal()">Add New Skill</button>
				</div>
			</div>
			
		</mat-card-footer>
	</mat-card>
	<div fxFlex="10" *ngIf="loading">
		<div style="text-align: center;">
			<mat-progress-spinner class="tjs-spinner skills" color="primary" [mode]="mode" [value]="value" [diameter]="60"></mat-progress-spinner>
		</div>
	</div>
	<mat-tree fxFlex [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="tree">
		<!-- This is the tree node template for leaf nodes -->
		<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
			<li class="mat-tree-node">
			<!-- use a disabled button to provide padding for tree leaf -->
			<button mat-icon-button disabled></button>
			{{node.name}}
			<button mat-raised-button (click)="openEditReviewModal(node.id)" color="accent" class="dialog-button" *ngIf="node.reviewed == 'Y'">Edit</button>
			<button mat-raised-button (click)="openEditReviewModal(node.id)" color="primary" class="dialog-button" *ngIf="node.reviewed == 'N'">Review</button>
			</li>
		</mat-tree-node>
		
		<!-- This is the tree node template for expandable nodes -->
		<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
		<li>
			<div class="mat-tree-node">
			<button mat-icon-button matTreeNodeToggle>
				<mat-icon class="mat-icon-rtl-mirror">
				{{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
				</mat-icon>
			</button>
			{{node.name}}
			<button mat-raised-button (click)="openEditReviewModal(node.id)" color="accent" class="dialog-button" *ngIf="node.reviewed == 'Y'">Edit</button>
			<button mat-raised-button (click)="openEditReviewModal(node.id)" color="primary" class="dialog-button" *ngIf="node.reviewed == 'N'">Review</button>
			</div>
			<ul [class.hide-node]="!nestedTreeControl.isExpanded(node)">
			<ng-container matTreeNodeOutlet></ng-container>
			</ul>
		</li>
		</mat-nested-tree-node>
	</mat-tree>
</div>