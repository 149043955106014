<!-- phase 1 -->
<form [formGroup]="form" autocomplete="off" novalidate>
<div class="candidate-info-containter" *ngIf="!isAfterDegreeType">
    <div fxLayout="row wrap" fxLayoutAlign="center stretch">
        <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
            <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
        </div>
        <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
            <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">What is the highest degree you have attained?</h1>
        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="95" fxFlexOffset="5">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="100">
                    <mat-radio-group
                    class="example-radio-group"
                    formControlName="highestDegree">
                        <mat-radio-button [checked]="form.get('highestDegree').value?.id == degreeType.id" class="example-radio-button" *ngFor="let degreeType of degreeTypes" [value]="degreeType">
                            {{degreeType.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="form.get('highestDegree').hasError('required') && isNextToDegreeClicked">
                        Please select your degree
                    </mat-error>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column">
                    <button fxFlexOffset="30px" mat-raised-button color="primary" (click)="nextFromDegree()">NEXT</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- phase 2 -->
<div class="candidate-info-containter" *ngIf="isAfterDegreeType">
    <div fxLayout="row wrap" fxLayoutAlign="center stretch">
        <div class="back" fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
            <mat-icon (click)="prevToDegree()">keyboard_backspace</mat-icon>
        </div>
        <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
            <h1 *ngIf="hasDeegreesOf(3)" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''" class="title-bold">Tell us more about your Trade School</h1>
            <button *ngIf="hasDeegreesOf(3)" mat-stroked-button color="primary" (click)="addDegreeOfType(3)">ADD</button>
            <h1 *ngIf="hasDeegreesOf(4)" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''" class="title-bold">Tell us more about your Associates Degree</h1>
            <button *ngIf="hasDeegreesOf(4)" mat-stroked-button color="primary" (click)="addDegreeOfType(4)">ADD</button>
            <h1 *ngIf="hasDeegreesOf(5)" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''" class="title-bold">Tell us more about your Bachelor's Degree</h1>
            <button *ngIf="hasDeegreesOf(5)" mat-stroked-button color="primary" (click)="addDegreeOfType(5)">ADD</button>
        </div>
    </div>
    <div *ngFor="let cs of t_tradeSchool.controls; let i = index; let last = last; let first = first" [formGroup]="cs">
        <div fxLayout="row wrap" fxFlexOffset="5" fxLayoutAlign="space-between stretch" fxFlex="95">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="95">
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>School Name</mat-label>
                    <input matInput type="text" name="school" id="school"
                    formControlName="school" [matAutocomplete]="autoschool">
                    <mat-autocomplete #autoschool="matAutocomplete">
                        <mat-option *ngFor="let school of querySearch(cs.controls.school.value, schools)" [value]="school.name">
                            {{ school.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.school.errors?.required">
                        Please enter School Name
                    </mat-error>
                    <mat-error *ngIf="cs.controls.school.errors?.maxlength">
                        This school name is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>Course of Study</mat-label>
                    <input matInput type="text" name="course" id="course"
                    formControlName="course" [matAutocomplete]="autocourse">

                    <mat-autocomplete #autocourse="matAutocomplete">
                        <mat-option *ngFor="let course of querySearch(cs.controls.course.value, degrees)" [value]="course.name">
                            {{ course.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.course.errors?.required">
                        Please enter Course of Study
                    </mat-error>
                    <mat-error *ngIf="cs.controls.course.errors?.maxlength">
                        This Course of Study is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input matInput 
                        [max]="cs.controls.endDate.value ? cs.controls.endDate.value : maxDate"
                        [matDatepicker]="startDate"  formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate startView="multi-year" (monthSelected)="tradechosenMonthHandler($event,startDate,i,'startDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.startDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter Start Date!
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>End Date</mat-label>
                    <input matInput 
                        [min]="cs.controls.startDate.value ? cs.controls.startDate.value : minDate"  
                        [max]="maxDate"  
                        [matDatepicker]="endDate" formControlName="endDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate startView="multi-year" (monthSelected)="tradechosenMonthHandler($event,endDate,i,'endDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.endDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter End Date!
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="3" fxFlex.lt-md="100" fxLayoutAlign="end">
                <button *ngIf="!(first && last)" type="button" matSuffix mat-icon-button color="accent" aria-label="Clear" (click)="removeDegree(cs.controls.degreeType.value.id,i)"><mat-icon>delete</mat-icon>{{cs.controls.degreeType.id}}</button>
            </div>
            <div fxFlex="100" *ngIf="!last">
                <hr>
            </div>
        </div>
    </div>



    <div *ngFor="let cs of t_associatesDegree.controls; let i = index; let last = last; let first = first" [formGroup]="cs">
        <div fxLayout="row wrap" fxFlexOffset="5" fxLayoutAlign="space-between stretch" fxFlex="95">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="95">
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>School Name</mat-label>
                    <input matInput type="text" name="school" id="school"
                    formControlName="school" [matAutocomplete]="autoschool">
                    <mat-autocomplete #autoschool="matAutocomplete">
                        <mat-option *ngFor="let school of querySearch(cs.controls.school.value, schools)" [value]="school.name">
                            {{ school.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.school.errors?.required">
                        Please enter School Name
                    </mat-error>
                    <mat-error *ngIf="cs.controls.school.errors?.maxlength">
                        This school name is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>Course of Study</mat-label>
                    <input matInput type="text" name="course" id="course"
                    formControlName="course" [matAutocomplete]="autocourse">

                    <mat-autocomplete #autocourse="matAutocomplete">
                        <mat-option *ngFor="let course of querySearch(cs.controls.course.value, degrees)" [value]="course.name">
                            {{ course.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.course.errors?.required">
                        Please enter Course of Study
                    </mat-error>
                    <mat-error *ngIf="cs.controls.course.errors?.maxlength">
                        This Course of Study is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input matInput 
                        [max]="cs.controls.endDate.value ? cs.controls.endDate.value : maxDate"
                        [matDatepicker]="startDate"  formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate startView="multi-year" (monthSelected)="associatechosenMonthHandler($event,startDate,i,'startDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.startDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter Start Date!
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>End Date</mat-label>
                    <input matInput 
                        [min]="cs.controls.startDate.value ? cs.controls.startDate.value : minDate"  
                        [max]="maxDate"  
                        [matDatepicker]="endDate" formControlName="endDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate startView="multi-year" (monthSelected)="associatechosenMonthHandler($event,endDate,i,'endDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.endDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter End Date!
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="3" fxFlex.lt-md="100" fxLayoutAlign="end">
                <button *ngIf="!(first && last)" type="button" matSuffix mat-icon-button color="accent" aria-label="Clear" (click)="removeDegree(cs.controls.degreeType.value.id,i)"><mat-icon>delete</mat-icon>{{cs.controls.degreeType.id}}</button>
            </div>
            <div fxFlex="100" *ngIf="!last">
                <hr>
            </div>
        </div>
    </div>
    <div *ngFor="let cs of t_bachelorsDegree.controls; let i = index; let last = last; let first = first" [formGroup]="cs">
        <div fxLayout="row wrap" fxFlexOffset="5" fxLayoutAlign="space-between stretch" fxFlex="95">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="95">
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>School Name</mat-label>
                    <input matInput type="text" name="school" id="school"
                            formControlName="school" [matAutocomplete]="autoschool">

                    <mat-autocomplete #autoschool="matAutocomplete">
                        <mat-option *ngFor="let school of querySearch(cs.controls.school.value, schools)" [value]="school.name">
                            {{ school.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.school.errors?.required">
                        Please enter School Name
                    </mat-error>
                    <mat-error *ngIf="cs.controls.school.errors?.maxlength">
                        This school name is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>Course of Study</mat-label>
                    <input matInput type="text" name="course" id="course"
                    formControlName="course" [matAutocomplete]="autocourse">

                    <mat-autocomplete #autocourse="matAutocomplete">
                        <mat-option *ngFor="let course of querySearch(cs.controls.course.value, degrees)" [value]="course.name">
                            {{ course.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.course.errors?.required">
                        Please enter Course of Study
                    </mat-error>
                    <mat-error *ngIf="cs.controls.course.errors?.maxlength">
                        This Course of Study is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                  <mat-label>Start Date</mat-label>
                  <input matInput
                         [max]="cs.controls.endDate.value ? cs.controls.endDate.value : maxDate"
                         [matDatepicker]="startDate" formControlName="startDate">
                  <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                  <mat-datepicker #startDate startView="multi-year" (monthSelected)="bachelorchosenMonthHandler($event,startDate,i,'startDate')"></mat-datepicker>
                  <mat-error *ngIf="cs.controls.startDate.errors?.required" class="help-block small animated fadeInDown">
                    Please enter Start Date!
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                  <mat-label>End Date</mat-label>
                  <input matInput
                         [min]="cs.controls.startDate.value ? cs.controls.startDate.value : minDate"
                         [max]="maxDate"
                         [matDatepicker]="endDate" formControlName="endDate">
                  <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                  <mat-datepicker #endDate startView="multi-year" (monthSelected)="bachelorchosenMonthHandler($event,endDate,i,'endDate')"></mat-datepicker>
                  <mat-error *ngIf="cs.controls.endDate.errors?.required" class="help-block small animated fadeInDown">
                    Please enter End Date!
                  </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="3" fxFlex.lt-md="100" fxLayoutAlign="end">
                <button *ngIf="!(first && last)" type="button" matSuffix mat-icon-button color="accent" aria-label="Clear" (click)="removeDegree(cs.controls.degreeType.value.id,i)"><mat-icon>delete</mat-icon></button>
            </div>
            <div fxFlex="100" *ngIf="!last">
                <hr class="divider">
            </div>
        </div>
    </div>
    <div *ngFor="let cs of t_mastersDegree.controls; let i = index; let last = last; let first = first" [formGroup]="cs">
        <div fxLayout="row wrap" fxFlexOffset="5" fxLayoutAlign="space-between stretch" fxFlex="95">
            <div *ngIf="first" fxFlex="100" fxLayoutAlign="space-between start">
                <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Tell us more about your Master's Degree</h1>
                <button mat-stroked-button color="primary" (click)="addDegreeOfType(6)">ADD</button>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" fxFlex="95">
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>School Name</mat-label>
                    <input matInput type="text" name="school" id="school"
                    formControlName="school" [matAutocomplete]="autoschool">

                    <mat-autocomplete #autoschool="matAutocomplete">
                        <mat-option *ngFor="let school of querySearch(cs.controls.school.value, schools)" [value]="school.name">
                            {{ school.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.school.errors?.required">
                        Please enter School Name
                    </mat-error>
                    <mat-error *ngIf="cs.controls.school.errors?.maxlength">
                        This school name is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>Course of Study</mat-label>
                    <input matInput type="text" name="course" id="course"
                    formControlName="course" [matAutocomplete]="autocourse">

                    <mat-autocomplete #autocourse="matAutocomplete">
                        <mat-option *ngFor="let course of querySearch(cs.controls.course.value, degrees)" [value]="course.name">
                            {{ course.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.course.errors?.required">
                        Please enter Course of Study
                    </mat-error>
                    <mat-error *ngIf="cs.controls.course.errors?.maxlength">
                        This school name is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input matInput 
                        [max]="cs.controls.endDate.value ? cs.controls.endDate.value : maxDate"
                        [matDatepicker]="startDate"  formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate startView="multi-year" (monthSelected)="masterschosenMonthHandler($event,startDate,i,'startDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.startDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter Start Date!
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>End Date</mat-label>
                    <input matInput 
                        [min]="cs.controls.startDate.value ? cs.controls.startDate.value : minDate"  
                        [max]="maxDate"  
                        [matDatepicker]="endDate" formControlName="endDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate startView="multi-year" (monthSelected)="masterschosenMonthHandler($event,endDate,i,'endDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.endDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter End Date!
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="3" fxFlex.lt-md="100" fxLayoutAlign="end">
                <button *ngIf="!(first && last)" type="button" matSuffix mat-icon-button color="accent" aria-label="Clear" (click)="removeDegree(cs.controls.degreeType.value.id,i)"><mat-icon>delete</mat-icon></button>
            </div>
            <div fxFlex="100" *ngIf="!last">
                <hr class="divider">
            </div>
        </div>
    </div>
    <div *ngFor="let cs of t_doctorate.controls; let i = index; let last = last; let first = first" [formGroup]="cs">
        <div fxLayout="row wrap" fxFlexOffset="5" fxLayoutAlign="space-between stretch" fxFlex="95">
            <div *ngIf="first" fxFlex="100" fxLayoutAlign="space-between start">
                <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Tell us more about your Doctorate Degree</h1>
                <button mat-stroked-button color="primary" (click)="addDegreeOfType(7)">ADD</button>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="95">
                <mat-form-field fxFlex="49" fxFlex.lt-md="100" color="accent" appearance="outline">
                    <mat-label>School Name</mat-label>
                    <input matInput type="text" name="school" id="school"
                    formControlName="school" [matAutocomplete]="autoschool">

                    <mat-autocomplete #autoschool="matAutocomplete">
                        <mat-option *ngFor="let school of querySearch(cs.controls.school.value, schools)" [value]="school.name">
                            {{ school.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.school.errors?.required">
                        Please enter School Name
                    </mat-error>
                    <mat-error *ngIf="cs.controls.school.errors?.maxlength">
                        This school name is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline" fxFlex="49" fxFlex.lt-md="100">
                    <mat-label>Course of Study</mat-label>
                    <input matInput type="text" name="course" id="course"
                    formControlName="course" [matAutocomplete]="autocourse">

                    <mat-autocomplete #autocourse="matAutocomplete">
                        <mat-option *ngFor="let course of querySearch(cs.controls.course.value, degrees)" [value]="course.name">
                            {{ course.name }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="cs.controls.course.errors?.required">
                        Please enter Course of Study
                    </mat-error>
                    <mat-error *ngIf="cs.controls.course.errors?.maxlength">
                        This Course of Study is too long (must be less than 255 characters) 
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline" fxFlex="49" fxFlex.lt-md="100">
                    <mat-label>Start Date</mat-label>
                    <input matInput 
                        [max]="cs.controls.endDate.value ? cs.controls.endDate.value : maxDate"
                        [matDatepicker]="startDate"  formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate startView="multi-year" (monthSelected)="doctorchosenMonthHandler($event,startDate,i,'startDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.startDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter Start Date!
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline" fxFlex="49" fxFlex.lt-md="100">
                    <mat-label>End Date</mat-label>
                    <input matInput 
                        [min]="cs.controls.startDate.value ? cs.controls.startDate.value : minDate"  
                        [max]="maxDate"  
                        [matDatepicker]="endDate" formControlName="endDate">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate startView="multi-year" (monthSelected)="doctorchosenMonthHandler($event,endDate,i,'endDate')"></mat-datepicker>
                    <mat-error *ngIf="cs.controls.endDate.errors?.required" class="help-block small animated fadeInDown">
                        Please enter End Date!
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="3" fxFlex.lt-md="100" fxLayoutAlign="end">
                <button *ngIf="!(first && last)" type="button" matSuffix mat-icon-button color="accent" aria-label="Clear" (click)="removeDegree(cs.controls.degreeType.value.id,i)"><mat-icon>delete</mat-icon></button>
            </div>
            <div fxFlex="100" *ngIf="!last">
                <hr class="divider">
            </div>
        </div>
    </div>
    <div fxLayout="row">
        <button fxFlexOffset="5" mat-raised-button color="primary" [disabled]="form.errors" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
    </div>
</div>  
</form>

