<div fxLayout="column" fxLayoutAlign="start center">
    <mat-card>
        <mat-card-title>Settings</mat-card-title>
        <mat-card-content>
            <div fxLayout="row" fxLayoutGap="20px">
                <!-- Menu -->
                <mat-card>
                    <mat-action-list>
                        <button mat-list-item (click)="setTab(0)">Change Email</button>
                        <button mat-list-item (click)="setTab(1)">Change Password</button>
                        <button mat-list-item (click)="setTab(2)">Communication Preferences</button>
                    </mat-action-list>
                </mat-card>
                <!-- Email address change form -->
                <mat-card *ngIf="checkTab(0)">
                    <mat-card-title class="padBottom8px">Change Primary Email Address</mat-card-title>
                    <mat-card-content>
                        <p *ngIf="errorMessage!=''" class="error-message">{{ errorMessage }}</p>
                        <form [formGroup]="emailForm" id="emailForm" (ngSubmit)="changeEmail()">
                            <mat-form-field color="accent" appearance="outline">
                                <mat-label>Old Email Address</mat-label>
                                <input matInput formControlName="emailOld" placeholder="you@email.com">
                                <mat-error *ngIf="emailForm.get('emailOld').hasError('email')">
                                    <small>Please enter your current valid email address</small>
                                </mat-error>
                                <mat-error *ngIf="emailForm.get('emailOld').hasError('required')">
                                    <small>Please enter your email address</small>
                                </mat-error>
                            </mat-form-field>        
                            <mat-form-field color="accent" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput type="password" formControlName="password" placeholder="password">
                                <mat-error *ngIf="emailForm.get('password').hasError('required')">
                                    <small>Please enter your password</small>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent" appearance="outline">
                                <mat-label>New Email Address</mat-label>
                                <input matInput formControlName="emailNew" placeholder="new@email.com" #emailNew>
                                <mat-error *ngIf="emailForm.get('emailNew').hasError('email')">
                                    <small>Please enter your new valid email address</small>
                                </mat-error>
                                <mat-error *ngIf="emailForm.get('emailNew').hasError('required')">
                                    <small>Please enter your email address</small>
                                </mat-error>
                            </mat-form-field> 
                            <mat-form-field color="accent" appearance="outline">
                                <mat-label>Confirm New Email Address</mat-label>
                                <input matInput formControlName="emailConfirm" placeholder="new@email.com"  [errorStateMatcher]="emailMatcher">
                                <mat-error *ngIf="emailNew.value && emailForm.hasError('emailNotMatched')">
                                    <small>Emails do not match</small>
                                </mat-error>
                            </mat-form-field> 
                        </form>    
                        <p *ngIf="successMessage!=''">{{ successMessage }}</p>
                    </mat-card-content>
                    <mat-card-actions fxLayoutAlign="end center">
                        <button mat-raised-button color="primary" type="submit" [disabled]="!emailForm.valid || emailForm.hasError('emailNotMatched')" form="emailForm" cdkFocusInitial>Submit</button>
                    </mat-card-actions>
                </mat-card>
                <!-- Password Change Form -->
                <mat-card *ngIf="checkTab(1)">
                    <mat-card-title class="padBottom8px">Change Password</mat-card-title>
                    <mat-card-content>
                        <p *ngIf="errorMessage!=''" class="error-message">{{ errorMessage }}</p>
                        <form [formGroup]="passwordForm" id="passwordForm" (ngSubmit)="changePassword()">
                            <mat-form-field color="accent" appearance="outline">
                                <mat-label>Email Address</mat-label>
                                <input matInput formControlName="email" placeholder="you@email.com">
                                <mat-error *ngIf="passwordForm.get('email').hasError('email')">
                                    <small>Please enter valid email address</small>
                                </mat-error>
                                <mat-error *ngIf="passwordForm.get('email').hasError('required')">
                                    <small>Please enter your email address</small>
                                </mat-error>
                            </mat-form-field>        
                            <mat-form-field color="accent" appearance="outline">
                                <mat-label>Old Password</mat-label>
                                <input matInput type="password" formControlName="passwordOld" placeholder="Old Password">
                                <mat-error *ngIf="passwordForm.get('passwordOld').hasError('required')">
                                    <small>Please enter your password</small>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent" appearance="outline">
                              <mat-label>New Password</mat-label>
                              <input matInput type="password" formControlName="passwordNew"  pattern=".{8,200}" placeholder="New Password" #passwordNew>
                              <mat-error *ngIf="passwordForm.get('passwordNew').hasError('required')">
                                <small>Please enter your new password</small>
                              </mat-error>                             
                              <mat-error *ngIf="!passwordForm.value.passwordNew.pattern && !passwordForm.get('passwordNew').hasError('required')"><small>New password should be at least 8 characters</small></mat-error>
                            </mat-form-field> 
                            <mat-form-field color="accent" appearance="outline">
                                <mat-label>Confirm New Password</mat-label>
                                <input matInput type="password" formControlName="passwordConfirm" placeholder="New Password" [pattern]="passwordNew.value">
                                <mat-error *ngIf="passwordForm.get('passwordConfirm').hasError('pattern')">
                                    <small>Passwords do not match</small>
                                </mat-error>
                                <mat-error *ngIf="passwordForm.get('passwordConfirm').hasError('required')">
                                    <small>Please enter your confirm new password</small>
                                </mat-error>
                            </mat-form-field> 
                        </form>    
                    </mat-card-content>
                    <mat-card-actions fxLayoutAlign="end center">
                        <button mat-raised-button color="primary" type="submit" [disabled]="!passwordForm.valid" form="passwordForm" cdkFocusInitial>Submit</button>
                    </mat-card-actions>
                </mat-card>
                <!-- Communication Preferences Form -->
                <mat-card class="communication-card" [ngClass.lg]="'card-width-lg'" [ngClass.xl]="'card-width-xl'" [ngClass.md]="'card-width-md'"  [ngClass.sm]="'card-width-sm'" *ngIf="checkTab(2)">
                    <mat-card-title class="padBottom8px">Communication Preferences</mat-card-title>
                    <mat-card-content>
                        <mat-label>Receive Emails</mat-label>
                        <mat-slide-toggle
                        [color]="color"
                        [checked]="allow_emails==1 ? true : false"
                        (change)="isReceiveEmails($event)">
                        </mat-slide-toggle>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-card-content>
    </mat-card>    
</div>
