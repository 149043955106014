import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user-registration-error',
  templateUrl: './user-registration-error.component.html',
  styleUrls: ['./user-registration-error.component.css']
})
export class UserRegistrationErrorComponent implements OnInit {
  public isResetRedirect:boolean = false;
  public errorMessage;

  constructor(public _dialogRef: MatDialogRef<UserRegistrationErrorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public errorType: any,
    public _router: Router) { }

  ngOnInit(): void {
  }

  goToResetPassword(){
		this.isResetRedirect = true;
		this._dialogRef.close();
	}

  goToEmployerRegister() {
    this._router.navigate(['/employer_register']);
    this.closeDialog();
  }

  closeDialog() {
    this._dialogRef.close();
  }

}
