import {throwError as observableThrowError,  Observable, EMPTY } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from '../Services/user-info.service';
import { SidenavService } from '../Services/sidenav.service';
import { Login } from '../Models/login';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class LoginService {
	public authToken: string = 'userloggedin';
	public IsLoggedIn = new EventEmitter();
	public ErrorMessage: string = '';
	constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService,
			private _sidenavService: SidenavService) { }

	// Logs the user in with username and password credentials, 
	// stores resultant tokens in local storage for session use
	Login(params:Login) {
		if (!params.Email || !params.Password) {
			this._router.navigate(['/']);
		}
		let data = { 
			'TJS_USERNAME': params.Email, 
			'TJS_PASSWORD': CryptoJS.SHA256(params.Password).toString(), 
			'reset': ""
		};
		if(params.ResetCode != '') {
			data.reset = CryptoJS.SHA256(params.ResetCode).toString();
		}
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/getTokenPair", data, { headers: headers }).pipe(
		map(response => {
			{
				if (typeof (response) !== 'undefined') {
					//differentie the flow based on verified/unverified
					if(response['verified']) {
						this._userInfoService.storeTokens(response['tokens']);
						this.IsLoggedIn.emit(true); 
          }
          else if (response['enabled'] == false) {

          }
					else {
						this._userInfoService.storeTempAccessToken(response['tokens'], params.Email);
					}
					return response;
				}
			};
		}),   
		catchError(error => observableThrowError(
			{ status: error.status }
		)),);
	}

	// Gets the details of logged in user, this is authenticated by access
	// token, so must be called by an already signed in user
	getUserDetail() {
		let data = { };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/getUserDetail", data, { headers: headers }).pipe(
		map(response => {
			{
				if (typeof (response) !== 'undefined') {
					response = response['userDetail'];
					this._userInfoService.setUser(response['user']);
					this._sidenavService.getNavigationRoutes().subscribe();
					return this._userInfoService.getUser();
				}
			};
		}),   
		catchError(error => observableThrowError(
			{ status: error.status }
		)),);
	}

	// checks if the user has access token 
	checkIfLoggedIn() {
		var isLoggedIn = this._userInfoService.getAccessToken();
		if (isLoggedIn && isLoggedIn !== 'undefined') {
			return true;
		}
		else {
			return false;
		}
	}

	// removes all of users access tokens from the database
	logout() {
		if(this._userInfoService.getAccessToken() === '') {
			this._userInfoService.clearUser();
			this.IsLoggedIn.emit(false);
			this._router.navigate(['/']);
			return EMPTY;
		}
		let data = { accessToken : this._userInfoService.getAccessToken() };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/logout", data, { headers: headers }).pipe(
		map(response => {
			{
				if(response) {
					this._userInfoService.logout();
					this.IsLoggedIn.emit(false);
					this._router.navigate(['/login']);
					return response['status'];
				}
				console.log("Logout Failed");
			};
		}),   
		catchError(error => observableThrowError(
			{ status: error.status }
		)),);
	}

}
