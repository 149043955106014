<div fxLayout="row wrap" fxFlex="100">
    <h1 mat-dialog-title fxFlex="75">
        Marketing Email
    </h1>
    <div fxFlex="25" fxLayoutAlign="end start">
        <button mat-icon-button fxFlexOffset="10px" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div fxLayout="row" fxFlex="100" *ngIf="isLoading" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>  
    <mat-dialog-content *ngIf="!isLoading" fxFlex="100" class="mat-typography scroll-content invite-to-opp-content" [formGroup]="emailForm">
        <div fxLayout="column">
            <mat-form-field color="primary"  class="form-item" appearance="fill" fxFlex="100" >
                <mat-chip-list #chipList formArrayName="emails">
                  <mat-chip [color]="(em.invalid)?'warn':''" selected *ngFor="let em of emailList; let i=index"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="removeEmail(em)"
                            required name="chips">
                    {{em.value}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="enter recipient email(s) (10 max)"
                         [matChipInputFor]="chipList"
                         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                         [matChipInputAddOnBlur]="true"
                         (matChipInputTokenEnd)="addEmails($event) " />
                </mat-chip-list>
            </mat-form-field>
            <div fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label>Message (optional):</mat-label>
                    <textarea matInput formControlName="message"></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <h4>Select Postings to Send as a Marketing Email</h4>
                <mat-selection-list formControlName="selectedOpportunities">
                    <mat-list-option [value]="opp.id" *ngFor="let opp of opportunities" [disabled]="opp.OpportunityCandidateId">
                        {{opp.title}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!isLoading" fxLayout="row reverse" fxFlex="100" fxLayoutAlign="end" class="mat-padding">            
        <div  fxLayoutAlign="space-between">
                <button mat-stroked-button (click)="close()">Cancel</button>
                <button mat-raised-button color="primary" fxFlexOffset="10px" (click)="submit()">Send</button>
        </div>  
    </mat-dialog-actions>
</div>