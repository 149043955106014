<mat-card fxLayout="column" ng-cloak>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div> <!--Instructions-->
            <h4>Email Templates</h4>
            <p><em>Tenants' email templates can be managed from here. </em></p>
        </div>
        <button mat-button color="primary" mat-raised-button (click)="createTemplate()">Add New</button>
    </div>
    <div> <!-- Searching Element -->
        <mat-form-field color="accent" appearance="outline">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </mat-form-field>
    </div>
    <div fxLayout="column"> <!-- Table -->
        <table mat-table [dataSource]="dataSource" fxFlex="100%" matSort>
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.id }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="template_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Template Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.template_name }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sender">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Sender </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.sender }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sender_password">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Sender Password </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.sender_password }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="subject">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Subject </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.subject }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="body">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Body </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.body }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="html">
                <mat-header-cell *matHeaderCellDef mat-sort-header> HTML? </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.html }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_updated_by">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated By </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.last_updated_by }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_updated_ts">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.last_updated_ts }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Edit">
                <mat-header-cell *matHeaderCellDef mat-header-sort>Edit</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-icon class="pointer" (click)="editTemplate(row)">edit</mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Delete">
                <mat-header-cell *matHeaderCellDef mat-header-sort>Delete</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-icon class="pointer" (click)="deleteTemplate(row)">delete</mat-icon>
                </mat-cell>
            </ng-container>
            <!-- Header Column Labels -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <!-- Row shown when there is no matching data. -->
            <mat-row *matNoDataRow>
                <mat-cell colspan="11">No data matching the filter "{{input.value}}"</mat-cell>
            </mat-row>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,50,100]" showFirstLastButtons></mat-paginator>
    </div>
</mat-card>
    
