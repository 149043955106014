import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { reduceTicks } from '@swimlane/ngx-charts';
import { BehaviorSubject, of as observableOf, Subject } from 'rxjs';
import { publish } from 'rxjs-compat/operator/publish';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forEachChild } from 'typescript';
import { skillData } from '../../../Admin/admin-skills/admin-skills.component';
import { SkillNode } from '../../../Models/SkillNode';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
  selector: 'skill-tree-control',
  templateUrl: 'skill-tree-control.component.html',
  styleUrls: ['skill-tree-control.component.css']

})
export class SkillTreeControlComponent implements OnInit {
  treeControl: NestedTreeControl<SkillNode>;
  isOverlayOpen = false;
  overlayHeight = 150;
  isLoading = true;
  noSkills = false;
  skillsList: SkillNode[] = [];
  isMobileDevice = false;
  searchText: string = '';
  resetScroll = true;
  skillDataSource: MatTreeNestedDataSource<SkillNode>;
  public treeData = [];
  public foundFirst = false;
  userEnteredSearchText = new Subject<string>();
  dataChange: BehaviorSubject<SkillNode[]> = new BehaviorSubject<SkillNode[]>([]);
  @ViewChild('tree') tree;

  constructor(private _talentApiService: TalentApiService,
    private changeDetector: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SkillTreeControlComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data:any) {

    this.isMobileDevice = data.isMobileDevice;
    var skillData = data.skillData;
    this.treeControl = new NestedTreeControl<SkillNode>(node => this._getChildren(node));
    this.skillDataSource = new MatTreeNestedDataSource<SkillNode>();
    if (skillData.controls.length > 0) {      
      for (let sk of skillData.controls) {     
        const node: SkillNode = new SkillNode();
        node.name = sk.value.name;
        node.id = sk.value.id;
        node.exp = sk.value.expYears;
        this.skillsList.push(node);
       
      }     
    }
  }
  hasChild = (_: number, node: SkillNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.dataChange.subscribe(data => this.skillDataSource.data = data);
    this.getSkillsList();
    this.userEnteredSearchText.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.filter(value);
    });
  }

  getSkills() {
    return this.skillsList;
  }

  private _getChildren(node: SkillNode) {
    if (node != undefined) {
      return observableOf(this.findNode(this.treeData, node.id).children)
    }
    return null;
  }

  public filter(filterText: string) {
    document.getElementById('matTree').scrollTop = 0;
    const data = this.skillDataSource.data.slice();
    this.dataChange.next(data);
    this.resetScroll = true;
    this.foundFirst = false;
    this.treeControl.collapseAll();
    if (filterText !== '') {
      var that = this;
      data.forEach(node => {
        if (this.treeSearch(filterText.toLowerCase(), node) === true) {
          this.treeControl.expand(node);
        }
      });
    }
  }

  scroll(el: HTMLElement) {
    if (this.resetScroll) {
      setTimeout(() => {
        document.getElementById('matTree').scrollTop = el.offsetTop - document.getElementById('matTree').offsetTop;
      }, 100);
    }
  }

  findPosition(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop;
    }
  }

  close() {
    this.dialogRef.close();
  }

  treeSearch(searchText, node): boolean {
    var found = false;
    if (node != null && node != undefined) {
      if (!this.foundFirst && node.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
        this.foundFirst = true;
        this.scroll(document.getElementById('nd' + node.id));
      }

      if (node.children.length > 0) {
        for (var i = 0; i < node.children.length; i++) {
          if (node.children[i].name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            if (!this.foundFirst) {
              this.foundFirst = true;
              this.scroll(document.getElementById('nd' + node.children[i].id));
            }
            found = true;
          }
          if (this.treeSearch(searchText, node.children[i])) {
            this.treeControl.expand(node.children[i]);
            //this.scroll(document.getElementById('nd' + node.children[i].id));
            found = true;
          }
        }
      }
    }
    return found;
  }

  addSkill(event, isChecked): void {    
    const value = (typeof (event) == 'object') ? event.name : event;
    event.isChecked = isChecked;
    if (value === '') return;
    const inSelectedList = this.skillsList.filter(skill => skill.name.indexOf(value) === 0);
    if (!isChecked && inSelectedList.length > 0) {
      this.removeSkill(event);
      return;
    }
    if (inSelectedList.length == 0 && isChecked) {
      this.skillsList.push(event);
    }
    
  }

  clearSearch() {
    this.searchText = '';
    this.filter('');
  }

  done() {   
    this.dialogRef.close(this.skillsList);
  }

  findNode(data, id) {
    for (const node of data) {
      if (node.id == id.toString()) {
        return node;
      }
      if (this.findNode(node.children, id)) {
        return this.findNode(node.children, id);
      }
    }
  }
  removeSkill(skill) {
    if (skill.name != '') {      
      var index = this.skillsList.findIndex(x => x.id == skill.id);
      this.skillsList.splice(index, 1);
      this.buildTree(this.treeData);
      this.filter('');
      this.changeDetector.detectChanges();
    }
  }

  getSkillStatus(skillNode): boolean {  

    if (skillNode.children != null && skillNode.children.length > 0) {
      var index = 1;
      for (var i = 0; i < skillNode.children.length; i++) {
        skillNode.children[i].isChecked = this.getSkillStatus(skillNode.children[i]);
        index++;
      }
    }
    if (this.skillsList != null) {
      const inSelectedList = this.skillsList.filter(skill => skill.name.indexOf(skillNode.name) === 0);
      if (inSelectedList.length > 0) {
        return true;
      }
    }
    return false;
  }

  buildFileTree(obj: any[], level: string): SkillNode[] {
    return obj.filter(o =>
      (<string>o.level).startsWith(level + '.')
      && (o.level.match(/\./g) || []).length === (level.match(/\./g) || []).length + 1
    )
      .map(o => {
        const node = new SkillNode();
        node.name = o.name;
        node.level = o.level;
        const children = obj.filter(so => (<string>so.level).startsWith(level + '.'));
        if (children && children.length > 0) {
          node.children = this.buildFileTree(children, o.level);
        }
        return node;
      });
  }

  buildTree(stree) {
    let tempTree = [];
    var that = this;
    stree.forEach(function (ele) {
      ele.isChecked = that.getSkillStatus(ele);
      tempTree.push(ele);
    });
    this.treeData = Object.assign(tempTree);
    this.skillDataSource.data = Object.assign(tempTree);
  }

  getSkillsList() {
    let stree = [];
    this._talentApiService.getSkills()
      .subscribe(
        (result: any) => {
          this.isLoading = false;
          stree = result;
          var that = this;
          that.buildTree(stree);
        },
        error => {
          console.log('Unable to load skills list');
        }
      );
  }
  
  expandAll()
  {
    this.expandNode(this.treeData);
  }

  expandNode(nodedata)
  {
    var that = this;
    nodedata.forEach(node => {
      if(node.children.length > 0){
        this.treeControl.expand(node);
        for (let index = 0; index < node.children.length; index++) {
          if(node.children[index].children.length > 0){
            this.treeControl.expand(node.children[index]);
            this.expandNode(node.children);
          }
        }
      }
    });
  }
}


