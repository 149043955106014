<form name="classSelector" id="classSelector" class="" #employerRegistration="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
            <h4 mat-dialog-title> {{ errorTitle }}</h4>
            <div mat-dialog-content class="mat-block">
                <label>{{errorMessage}} </label>
            </div>
            <div mat-dialog-actions align="end">
                <button type="button" mat-button (click)="close()">
                    {{ errorClose }}
                </button>
            </div>
        </div>
    </div>
</form>
