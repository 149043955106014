import { Component, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { TenantService } from '../../../../Services/tenant.service';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-candidate-resume',
  templateUrl: './candidate-resume.component.html',
  styleUrls: ['./candidate-resume.component.css']
})
export class CandidateResumeComponent implements OnInit {
  @Input() resume;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();

  public form;
  public resumeName = '';
  tenant: String;
  isLoading:boolean = false;
  constructor(private fb: UntypedFormBuilder, 
	private _talentAPIService: TalentApiService, 
	private _tenantService: TenantService,
	@Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { 
		this.tenant = this._tenantService.tenant;
	}

  ngOnInit(): void {
	  if(typeof this.resume === 'string') {
		this.resume = JSON.parse(this.resume);
	  }
	
    this.form = this.fb.group({
      resume: [this.resume]
	});
	
	if(this.resume && this.resume.name) {
		this.resumeName = this.resume.name;
	}
	
  }

  next() {
    const candidate = this.form.value;
    const candidateProfile = {
      resume: (typeof this.resume === 'string') ? this.resume : JSON.stringify(this.resume) //to handle an object or the string form retrieved from API
    }
    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
				if (result) {
					this.saveProfile.emit(this.resume);
					var data = this.resume;
					if(this.edit === true) {
						this.dialogRef.close(data);
					  }
				}
				else {

				}
			},
			error => {
				this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }

  onResumeUpload(event: any) {   
    var sizeInKB = (event.target.files[0].size / 1024).toFixed(0);
    var fileName = event.target.files[0].name;
    var fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (!(fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "docx") ||
          Number(sizeInKB) > Number(environment.CV_UPLOAD_MAXSIZE_KB)) {

        this._talentAPIService.snackbarMessage('File must be in pdf or doc format and less than '
        + (environment.CV_UPLOAD_MAXSIZE_KB / 1024).toFixed(0) + ' MB.');
      return;
    }    
	const file: File = event.target.files[0];
	this.UploadResume(file);
	this.setResumeFile(event.target);    
	}

  setResumeFile(inputValue: any): void {
		var file: File = inputValue.files[0];
		var myReader: FileReader = new FileReader();
		var content;
		var mimefileResume = {
			mimetype: null,
			encoding: null,
			content: null,
			name: null,
      size: null,
      share: 1
		};
		myReader.onload = (e) => {
      content = myReader.result;
			mimefileResume = this.parse(content);
			mimefileResume.name = file.name;
			mimefileResume.size = file.size;
			//this.user.resume.file = JSON.stringify(mimefileResume);
			this.resume = JSON.stringify(mimefileResume);
			//this.user.resume.file = mimefileResume;
		}
		myReader.readAsDataURL(file);
  }
  
  parse(c) {
		var f = c.split('data:')[1];
		var splited = f.split(';');
		var mimetype = splited[0];
		var secondSplit = splited[1].split(',');
		var encoding = secondSplit[0]
		var content = secondSplit[1];
		return {
			mimetype: mimetype,
			encoding: encoding,
			content: content,
			name: '',
			size: '',
			share: 1 //share to employers by default
		};
	}

	// prev() {
	// 	this.prevEvent.emit();
	// }

	skip() {
		const candidateProfile = {
			resume: ""
		  }
		this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
				if (result) {
					this.saveProfile.emit(this.resume);
					var data = this.resume;
					if(this.edit === true) {
						this.dialogRef.close(data);
					  }
				}
			},
			error => {
				this._talentAPIService.snackbarMessage('Something went wrong!');
			});
	  }

	UploadResume(file) {
	this._talentAPIService.snackbarMessage('Your Resume Is Uploading Please Wait');
	this.isLoading = true;
	this._talentAPIService.UploadResume(file)
	.subscribe(
	(result:any) => {
		if(result['statusType'] == 'success') {
			this._talentAPIService.isResumeUpload = result['ResumeData'].isUploaded;
			this._talentAPIService.snackbarMessage('Your Resume Is Uploaded');
			this.isLoading = false;
			this.resumeName = file.name;
		}
		else {
			this._talentAPIService.isResumeUpload = result['ResumeData'].isUploaded;
			this._talentAPIService.snackbarMessage('Something Went Wrong When Uploading The Resume');
			this.isLoading = false;
			this.resumeName = file.name;
		}
	},
	error => {
		this._talentAPIService.snackbarMessage('Something went wrong!');
		this.resumeName = file.name;   //we have to allow user to click next even if Upload Resume failed, so file assign here
	});
	}
}
