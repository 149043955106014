<mat-card fxLayout="column" fxFlex="100">
    <mat-card-title><h2>Oldest Open Postings</h2></mat-card-title>
    <mat-card-content fxFlex="90%">
      <div fxFlex="100%" fxLayout="column" *ngIf="postingData.length" fxLayoutAlign="start stretch">
        <div flex="65%">
          <ngx-charts-gauge 
                            [title]="'Oldest open postings'"
                            [results]="postingData"
                            [max]="postingMaxDays"
                            [animations]="true"
                            [tooltipDisabled]="false"
                            [units]="'Days old'"
                            [view]="''"
                            [legend]="true"
                            [legendTitle]="''"
                            [legendPosition]="'below'"
                            [scheme]="colorScheme"
                            [showAxis]="false"
                            [showText]="false">
          </ngx-charts-gauge>
        </div>
        <div fxFlex="20%">
          <!-- <ngx-charts-legend fxFlex="100%"
                             *ngIf="postingData.length"
                             [data]="legendData"
                             [colors]="colors"
                             class="chart-legend"
                             (labelActivate)="legendLabelActivate($event)"
                             (labelDeactivate)="legendLabelDeactivate($event)">
          </ngx-charts-legend> -->
        </div>
      </div>
      <div fxFlex="100%" *ngIf="postingData.length==0" fxLayout="column" fxLayoutAlign="center center">
        <h2 style="color:grey;">No Open Postings</h2>
      </div>
    </mat-card-content>  
</mat-card>


