import { Component, Input, OnInit, Output, EventEmitter, Optional, Inject, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileViewDialogComponent } from '../../Pages/dialogs/profile-view-dialog/profile-view-dialog.component';
import { OpportunityService } from '../../Services/opportunity.service';

@Component({
  selector: 'app-candidate-engaged-card',
  templateUrl: './candidate-engaged-card.component.html',
  styleUrls: ['./candidate-engaged-card.component.css']
})
export class CandidateEngagedCardComponent implements OnInit {
  @Input() postingId: any;
  @Input() breakpoint: number;
  @Input() postingDetail: any;
  candidates: any = [];
  public totalLength = 0; // Length of the candidate array
  public pageSize = 20; // Number of candidate fetched by API and displayed
  public pageIndex = 0; // Page number (starting candidate index = pageIndex * pageSize)
  public isLoadingResult: boolean = false;
  public selectedSort: any = '0';
  public sortBy = 'date';
  public sortDirection = 'desc';


  constructor(@Optional() private dialogRef: MatDialogRef<ProfileViewDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public _dialog: MatDialog,
    private _opportunityService: OpportunityService, private _router: Router) {

  }

  ngOnInit(): void {
    this.getEngagedCandidates(0, this.pageSize, this.sortBy, this.sortDirection);
  }

  public getCandidateDetails(id: number): void {
    const dialog = this._dialog.open(ProfileViewDialogComponent, {
      height: 'auto',
      disableClose: false,
      //custom_popup  custom_popup_candidate
      data: { id: id, postingId: this.postingId, title: this.postingDetail.title, sector: this.postingDetail.company.sector, postedDate: this.postingDetail.postingdate },
    });
    const sub = dialog.afterClosed().subscribe((data) => {
      this.getEngagedCandidates(0, this.pageSize, this.sortBy, this.sortDirection);
    });

  }

  getEngagedCandidates(pageNumber, pageSize, sortBy, sortDirection) {
    this.isLoadingResult = true;
    this._opportunityService.getEngagedCandidatesForOpportunity(this.postingId, pageNumber, pageSize, sortBy, sortDirection).subscribe((result: any) => {
      this.candidates = result['studentsForOpportunity'];
      this.totalLength = result['total_count'];
      this.isLoadingResult = false;
    });
  }

  // Gets the next group of candidates from the API
  onPageChange(event) {
    this.pageSize = event.pageSize;
    this.getEngagedCandidates(event.pageIndex, event.pageSize, this.sortBy, this.sortDirection);
  }

  onChangeSort(val) {
    if (val == '0') {
      this.sortBy = 'date';
      this.sortDirection = 'desc';
    }
    else if (val == '1') {
      this.sortBy = 'date';
      this.sortDirection = 'asc';
    }
    else if (val == '2') {
      this.sortBy = 'status';
      this.sortDirection = 'desc';
    }
    else {
      this.sortBy = 'status';
      this.sortDirection = 'asc';
    }
    this.getEngagedCandidates(0, this.pageSize, this.sortBy, this.sortDirection);
  }
}
