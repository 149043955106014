<div id="editing-modal" class="timesheet-popup admin_popup" role="form">
    <form name="editingForm" id="editingForm" #editingForm="ngForm" class="" novalidate ng-cloak>
        <div class="modal-content">
            <div class="modal-header">

                <div>
                    <h1 *ngIf="!editing.id" mat-dialog-title>Add Config Variable</h1>

                    <h1 *ngIf="editing.id" mat-dialog-title>Edit Config Variable</h1>

                    <button class="mat-icon-button close_bt" (click)="closeDialog()">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                </div>

            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field color="accent" appearance="outline">
                            <input placeholder="Name (SUGGESTED_VARIABLE_FORMAT)"  matInput name="editname" id="editname" [(ngModel)]="editing.name" #editname="ngModel" minlength="3" maxlength="255" required (change)="editing.name=editing.name.trim()"/>

                            <mat-error *ngIf="editname.hasError('required')">You did not enter name.</mat-error>

                            <mat-error *ngIf="!editname.hasError('required') && editname.hasError('maxlength')">Name must be between 3 and 255 characters long.</mat-error>

                        </mat-form-field>

                        <small>* 255 characters maximum and must be a unique name.</small>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12">
                        <mat-form-field color="accent" appearance="outline">
                            <input placeholder="Value" matInput name="editvalue" id="editvalue" [(ngModel)]="editing.value" #editvalue="ngModel" maxlength="1000"
                                required>

                            <mat-error *ngIf="editvalue.hasError('required')">You did not enter value.</mat-error>

                            <mat-error *ngIf="!editvalue.hasError('required') && editvalue.hasError('maxlength')">Name must be between 1 and 1000 characters long.</mat-error>

                        </mat-form-field>
                        <small>* 1000 characters maximum.</small>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
                        <!-- // Edit  -->
                        <div>
                            <button mat-button mat-raised-button color="warn" *ngIf="editing.id" (click)="modalDelete()" class="Cancel-btt  pull-left">Delete</button>
                        </div>

                        <div>
                            <button mat-button mat-raised-button color="primary" *ngIf="editing.id" (click)="modalSave()" class="save-bt popup_done_btn "
                                [disabled]="!editingForm.valid">Save</button>
                        </div>
                        <!-- // common -->
                        <!-- <div data-element-identifier="admin.config.modal.add-edit-variable.cancel-button">
                      <button mat-button mat-raised-button (click)="modalCancel()" >Cancel</button>
                  </div> -->
                        <!-- // add -->
                        <div>
                            <button mat-button mat-raised-button *ngIf="!editing.id" [disabled]="!editingForm.valid" (click)="modalAddNew()" class="save-bt add_new ">Add New</button>
                        </div>
                    </div>
                </div>
            </div>
    </form>
</div>