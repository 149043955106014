import { Component, Input, OnInit, Output, EventEmitter, Optional} from '@angular/core';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { UntypedFormBuilder, Validators} from '@angular/forms';
import { CandidateWorkEnvironment } from '../../../../Models/CandidateProfile';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-candidate-work-environment',
  templateUrl: './candidate-work-environment.component.html',
  styleUrls: ['./candidate-work-environment.component.css']
})
export class CandidateWorkEnvironmentComponent implements OnInit {

  @Input() workEnvironment: CandidateWorkEnvironment;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  candidateProfile = {
    workEnvironment: null
  }
  public form = this.fb.group({
    location: ['', [Validators.required]],
    currentWorkScheduleType: ['', [Validators.required]],
    workStation: ['', [Validators.required]],
    workEnvironmentType: ['', [Validators.required]],
    interestedMarkets: ['', [Validators.required]]
  });
  public CandidateCurrentWorkSchedule;
  public CandidateworkEnvironmentType;
  public Candidatesector;
  public isNextClicked = false;
  public CandidateLocation;
  public isAllWorkEnvironment;
  public isAllSchedule;
  compareFunction = (o1: any, o2: any)=> o1.id===o2.id;

  constructor(private fb: UntypedFormBuilder, 
    private _talentAPIService: TalentApiService, 
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { }

  ngOnInit(): void {
    //console.log(this.workEnvironment);
    this.form.patchValue(this.workEnvironment);
    //console.log(this.form.value);
    this.getCandidateWorkScheduleType();
    this.getCandidateWorkEnvironmentType();
    this.getCandidateSector();
    this.getCandidateLocation();
  }
  getCandidateWorkScheduleType() {
    this._talentAPIService.getCandidateWorkScheduleType()
			.subscribe(
			(result:any) => {
          this.CandidateCurrentWorkSchedule = result;
          this.selectAll('currentWorkScheduleType');
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
      });
  }
  getCandidateWorkEnvironmentType() {
    this._talentAPIService.getCandidateWorkEnvironmentType()
			.subscribe(
			(result:any) => {
          this.CandidateworkEnvironmentType = result;
          this.selectAll('workEnvironmentType');
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }
  getCandidateLocation() {
    this._talentAPIService.getCandidateLocation()
			.subscribe(
			(result:any) => {
          this.CandidateLocation = result;
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }
  getCandidateSector() {
    this._talentAPIService.getCandidateSector()
			.subscribe(
			(result:any) => {
          this.Candidatesector = result;
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }

  selectAll(controllName) {
    switch (controllName) {
      case 'workEnvironmentType':
        if(this.form.get(controllName).value && this.form.get(controllName).value.length == this.CandidateworkEnvironmentType.length) {
          this.isAllWorkEnvironment = true;
        } else {
          this.isAllWorkEnvironment = false;
        }
      break;
      case 'currentWorkScheduleType':
        if(this.form.get(controllName).value && this.form.get(controllName).value.length == this.CandidateCurrentWorkSchedule.length) {
          this.isAllSchedule = true;
        } else {
          this.isAllSchedule = false;
        }
      break;
    }
  }
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ', '

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}
  next() {
    this.isNextClicked = true;
    console.log(this.form.value);
    if (this.form.invalid) {
      return;
    } else {
      const candidateWorkEnvironmentObject = this.form.value;
      const candidateProfile = {
        workEnvironment: this.form.value
      }
    
      this._talentAPIService.updateProfile(candidateProfile)
        .subscribe(
        (result:any) => {
            this.saveProfile.emit(this.form.value);
            var data = this.form.value;
            if(this.edit === true) {
              this.dialogRef.close(data);
            }
        },
        error => {
          this._talentAPIService.snackbarMessage('Something went wrong!');
        });
    }
  }

  prev() {
		this.prevEvent.emit();
  }
  skip() {
		//this.form.patchValue(this.workEnvironment);
		this.saveProfile.emit(this.workEnvironment);
	}
}
