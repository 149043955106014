import { Component, EventEmitter, OnInit, Optional, Inject, Output } from '@angular/core';
import { ProfileService } from '../../../Services/profile.service';
import { OpportunityService } from '../../../Services/opportunity.service';
import { UserInfoService } from '../../../Services/user-info.service';
import { TalentApiService } from '../../../Services/talent-api.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InviteToOpportunitiesComponent } from '../invite-to-opportunities/invite-to-opportunities.component';
import { EmployerStudentService } from '../../../Services/employer-student.service';


@Component({
	selector: 'app-profile-view-dialog',
	templateUrl: './profile-view-dialog.component.html',
	styleUrls: ['./profile-view-dialog.component.css']
})
export class ProfileViewDialogComponent implements OnInit {
	public postingId: any = 0;
  public shortlistId: any = 0;
  public invite: number = undefined;
  public candidateId: any = 0;  
	public student: any = {};
	public userId;
	public hasAdvanceCandidatePermission: boolean = false;


	constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
		private _profileService: ProfileService,
		public _dialog: MatDialog,
		public dialogRef: MatDialogRef<ProfileViewDialogComponent>,
		private _talentApiService: TalentApiService,
		private _opportunityService: OpportunityService,
    private _userInfoService: UserInfoService, private _employerStudentService: EmployerStudentService,) {
    if (this.data != undefined) {       
			this.candidateId = this.data.id;
			if (data.postingId != undefined) {
				this.postingId = this.data.postingId;
			}
			if (data.shortlistId != undefined) {
				this.shortlistId = this.data.shortlistId;
      }
      if (data.invite != undefined) {
        this.invite = this.data.invite;
      }
		}
		dialogRef.backdropClick().subscribe(result => {
			dialogRef.disableClose = true; //disable default close operation
			dialogRef.close();
		});

	}

	ngOnInit() {		
		this.userId = this._userInfoService.getUser().id;
		if (this.postingId > 0) {
			this.getProfileData();
		}
		// will check the user's permissions and conditionally display the edit posting button
		this._userInfoService.checkMyPermission('Advance Candidate').subscribe((result: any) => {
			this.hasAdvanceCandidatePermission = result;
		});
	}

	getProfileData() {
		this._profileService.getCandidateOpportunityStatus(this.candidateId, this.userId, this.postingId)
			.subscribe(
				(result: any) => {
          if (result) {           
						this.student = result;
					}
				},
				error => {
					this._talentApiService.snackbarMessage('Unable to get profile data.');
				}
			);
	}

	updateOpportunityCandidateStatus(event, operation: string): void {
		let statusIdPass;
		if (operation == '') {
			statusIdPass = parseInt(this.student.fk_opportunity_student_status_id) + 1;
		}
		else {
			statusIdPass = 5; // remove status
		}
		this._opportunityService.updateOpportunityCandidateStatus(this.candidateId.toString(), this.postingId.toString(), statusIdPass.toString())
			.subscribe(
				(response: any) => {
					if (response.status) {
						this._talentApiService.snackbarMessage(response.message);
						this.dialogRef.close('');
					}
				},
				error => {
					this._talentApiService.snackbarMessage('unable to update opportunity candidate status.');
				}
			);

	}

	close(): void {
		this.dialogRef.close();
  }

  inviteSinglePosting() {
    this._employerStudentService.inviteToView({ id: this.candidateId }, this.invite)
      .subscribe(
        (result: any) => {
          this.close();
          this._talentApiService.snackbarMessage('Invited successfully!');          
        },
        error => {        
          this._talentApiService.snackbarMessage('unable to invit.');
          this.close();
          console.log(error);
        });
  }

	openInviteToApply() {
		let dialog = this._dialog.open(InviteToOpportunitiesComponent,{
			height: '95vh',
			width: '100%',
			disableClose: true,
			data: {
				candidateId: this.candidateId
				}
			});
			dialog.afterClosed()
			.subscribe((result) => {
			  console.log(result);
			});
		}
	}


