<div fxLayout="row wrap" fxFlex="100">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="end start">     
    <button mat-icon-button (click)="close()" color="primary">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <mat-dialog-content *ngIf="IsShortListNotExists">
      <div fxLayoutAlign="center center" class="subtitle2"><strong>You don't have any Shortlists yet.</strong></div>
      <div fxLayoutAlign="center center"><strong>Get started by creating one!</strong></div>
 
    </mat-dialog-content>
    <mat-dialog-content *ngIf="IsPostingNotExists" >
      <div fxLayoutAlign="center center"><strong>You have No Active Postings.</strong></div>
      <div fxLayoutAlign="center center"><strong>Create a New Posting to Invite Candidates to Apply!</strong></div>
    </mat-dialog-content> 
  </div>
  <div fxLayout="row">&nbsp;</div>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">    
    <button *ngIf="IsPostingNotExists" (click)="createPosting()" mat-raised-button color="accent">
      + NEW POSTING
    </button>
    <button *ngIf="IsShortListNotExists" (click)="createShortList()" mat-raised-button color="accent">
      NEW SHORTLIST
    </button>
  </div>
</div>



