<div fxLayout="row wrap" fxFlex="100" fxFlex.xs="100" [ngClass.xs]="'small-device'">
  <div fxFlex="100" fxFlex.xs="100" fxLayoutAlign="start center" class="padding-border" fxLayoutAlign.xs="start start">
    <h1 mat-dialog-title fxFlex="50" fxFlex.xs="90">
      {{title}}
    </h1>
    <div fxFlex="50" fxFlex.xs="10" fxLayout="row wrap" fxLayoutAlign="end center">
      <button mat-icon-button fxLayoutAlign="end center" tabindex="-1"
              (click)="closeReportingDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr fxFlex="100">
  <div fxFlex="100" class="scroll">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
      </div>
      <mat-list role="list" fxLayout="row wrap" *ngIf="!showDetails">
        <mat-list-item fxFlex="100" *ngFor="let data of reportingMainReasons" (click)="getDetails(data)" class="mat-list-item-word-wrap">
          <span matLine fxFlex="96" fxFlex.xs="94" fxLayoutAlign="start start">{{data.reason}}</span>
          <mat-icon fxFlex="4" fxFlex.xs="6" fxLayoutAlign="end center">chevron_right </mat-icon>
          <mat-divider [inset]="true"></mat-divider>
        </mat-list-item>
      </mat-list>
      <div fxLayout="row">
        <div *ngIf="showDetails" fxFlex="95" fxLayout="column wrap">
          <div fxLayout="row wrap" class="radio" *ngIf="showDetails">
            <mat-radio-group fxFlex="100" fxLayout="column" fxLayoutGap="16px" fxFlex="100" formControlName="reason"  class="padding-border-content">
              <mat-radio-button fxFlex="100" *ngFor="let data of subReasons" [checked]="form.get('reason').value == data.id" [value]="data.id">
                {{data.reason}}
              </mat-radio-button>
            </mat-radio-group>
            <mat-error fxFlex fxFlexOffset="2" *ngIf="form.get('reason').hasError('required') && isSubmitClicked">
              Please select the reason
            </mat-error>
          </div>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center" *ngIf="!isLoading">
            <button mat-raised-button color="accent" (click)="resetList()">  BACK  </button>
            <button mat-raised-button color="primary" (click)="submitReport()">  SUBMIT  </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


