<div *ngIf="isLoadingResult" fxLayout="row" fxLayoutAlign="center">
    <mat-spinner></mat-spinner>
</div>
<mat-tab-group *ngIf="!isLoadingResult">
    <mat-tab *ngFor="let tab of permissionsData; let k = index" [label]="tab.tabName">
        <table mat-table [dataSource]="tab.dataSource" fxFlex="100" class="min-table-width outlined">
            <ng-container *ngFor="let permission of tab.permissions; let i = index"
            [matColumnDef]="permission">
                <mat-header-cell *matHeaderCellDef class="regular-14"  fxLayout="row" fxLayoutAlign="center center"> {{ permission }}</mat-header-cell>
                <mat-cell *matCellDef="let row; let j = index" [ngClass]="{'regular-14': i != 0}">
                    <ng-container *ngIf="isString(row[i])">
                        {{ row[i] }}
                    </ng-container>
                    <div *ngIf="!isString(row[i])" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                        <mat-checkbox (change)="valueChanged($event.checked,i,j,k)"
                            [indeterminate]="indeterminate(i,j,k)"
                            [disabled]="viewOnly || tab.disabled[j][i]" 
                            [checked]="row[i]"
                            fx="row" fxLayoutAlign="start center">
                        </mat-checkbox> 
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="tab.permissions"></mat-header-row>
            <mat-row *matRowDef="let row; columns: tab.permissions;" class="thin-columns clickable header-row"></mat-row>
        </table>
    </mat-tab>
</mat-tab-group>