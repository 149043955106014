import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'feedbackFilter'
})
export class FeedbackFilterPipe implements PipeTransform {

	public transform(value, keys: any, term: any) {
    if (!keys) return value;
		//Open,Archived,Active for status check
		let filter_status = '';
		let key_filter = null;
		if (keys.fb_status) {
			key_filter = 'fb_status';
			filter_status = keys.fb_status;
		} else if (keys.fk_owner) {
			key_filter = 'fk_owner';
			filter_status = keys.fk_owner;
		} else if (keys.tagged) {
			key_filter = 'tagged';
			filter_status = keys.tagged;
		} else if (keys.super) {
			key_filter = 'super';
			filter_status = keys.super;
		} else if (keys.classID) {
      key_filter = 'classID';
      filter_status = keys.classID;
    }
		if(key_filter == null) return;
		return (value || []).filter((item) => key_filter.split(',').some(key => item.hasOwnProperty(key) && new RegExp(filter_status, 'gi').test(item[key])));

	}

}
