import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyInfoService } from '../../../Services/company-info.service';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
  selector: 'app-invite-new-user',
  templateUrl: './invite-new-user.component.html',
  styleUrls: ['./invite-new-user.component.css']
})
export class InviteNewUserComponent implements OnInit {

  emailForm: UntypedFormGroup;
  companyGroups: any = [];
  showSuccess: any = 0;
  public emailList = [];
  public previouslyInvitedEmail = [];
  public userExistsEmail = [];
  public separatorKeysCodes = [ENTER, COMMA];
  public sendingEmail = false;
  
  
  inviteEmail: UntypedFormBuilder = new UntypedFormBuilder();
  largeScreen: boolean = false;
  public removable: boolean = true;
  public selectable: boolean = true;
  
  constructor(private _companyInfoService: CompanyInfoService,
    private _router: Router,
    private _talentApiService: TalentApiService,
    public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.emailForm = this.inviteEmail.group({
      emails: this.inviteEmail.array([], [this.validateArrayNotEmpty]),
      usergroup: [null, Validators.required],
    });
    this.getGroups();
    this.breakpointObserver.observe(['(min-width: 959px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.largeScreen = true;
      } else {
        this.largeScreen = false;
      }
    });
  }

  private getGroups() {
    this._companyInfoService.getCompanyGroups()
      .subscribe((result) => {
        if (result != null) {
          result.splice(0, 0, { name: '+ Add new user group', id: -1 });
          this.companyGroups = result;
        }
      });
  }

  private validateArrayNotEmpty(c: UntypedFormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  getEmails(excludeInvited) {
    var emails = [];
    var that = this;
    this.emailList.forEach(function (em) {
      if (!em.invalid) {
        if (excludeInvited && (that.previouslyInvitedEmail.length > 0 || that.userExistsEmail.length > 0)) {
          var isExists = false;
          for (var i = 0; i < that.previouslyInvitedEmail.length; i++) {
            if (that.previouslyInvitedEmail[i] == em.value) {
              isExists = true;
            }
          }
          for (var i = 0; i < that.userExistsEmail.length; i++) {
            if (that.userExistsEmail[i] == em.value) {
              isExists = true;
            }
          }
          if (!isExists) {
            emails.push(em.value)
          }
        }
        else {
          emails.push(em.value)
        }
      }
    });
    return emails;
  }

  addEmails(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailList.push({ value: event.value, invalid: false });
        this.emailForm.controls['emails'].setErrors(null);
      } else {
        this.emailList.push({ value: event.value, invalid: true });
      }
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeEmail(data: any): void {    
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
  }
  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onChangeUserGroup(val) {
    if (val != undefined && val < 0) {
      this._router.navigate(['/companyAdmin/userGroups']);
    }
  }

  Invite() {
    if (this.emailForm.invalid || this.sendingEmail==true) {
      return;
    }
    var ems = this.getEmails(false);
    if (ems.length == 0) { this._talentApiService.snackbarMessage('No valid email found!'); return; }
    this.sendingEmail = true;
    this._companyInfoService.inviteUser(ems, this.emailForm.controls['usergroup'].value.toString())
      .subscribe((result) => {
        this.sendingEmail = false;
        this.userExistsEmail = result['userExists']
        this.previouslyInvitedEmail = result['userInvited'];
        const failedLength = this.userExistsEmail.length + this.previouslyInvitedEmail.length;
        if (failedLength == ems.length) {
          if(this.userExistsEmail.length > 0) {
            this._talentApiService.snackbarMessage('The selected email(s) have already have an account or have been invited!');
          }
          else {
            this._talentApiService.snackbarMessage('The selected email(s) have already been invited!');
          }
          this.previouslyInvitedEmail = [];
          this.userExistsEmail = [];
        }
        else {
          this.showSuccess = 1;
        }
      }, error => { this.sendingEmail = false;});
  }

  backToInvite() {
    this.emailForm = this.inviteEmail.group({
      emails: this.inviteEmail.array([], [this.validateArrayNotEmpty]),
      usergroup: [null, Validators.required],
    });
    this.emailList = [];
    this.showSuccess = 0;
  }
  
  back() {
    this._router.navigate(['/companyAdmin']);
  }

}
