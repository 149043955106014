import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../../Services/dashboard.service';
import { ColorHelper } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-top-skills-graph',
  templateUrl: './top-skills-graph.component.html',
  styleUrls: ['./top-skills-graph.component.css']
})
export class TopSkillsGraphComponent implements OnInit {
  public numSkills:number = 3;
  public skillData:any[] = [];
  public topSkills:any;
  public legendData:string[];
  public view:any = "";
  public colors:ColorHelper;
  public colorScheme = { 
    domain: [
      'rgb(29, 104, 251)', 
      'rgb(51, 192, 252)', 
      'rgb(74, 255, 254)', 
      'rgb(175, 255, 255)', 
      'rgb(255, 252, 99)', 
      'rgb(255, 252, 99)'
    ] 
  };

  constructor(private _dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getSkillsOverview();    
  }

  getSkillsOverview() {
    this._dashboardService.getSkillsOverview(this.numSkills)
    .subscribe(
    (result: any) => {
      this.legendData = ["Demand","Availability"];
      //this.colors = new ColorHelper(this.colorScheme, 'ordinal', this.legendData, null);
      this.topSkills = result;
      this.topSkills.forEach(skill => {
        this.skillData.push({
          'name': skill.name,
          'series': [
            {
              'name': "Demand",
              'value': skill.value
            },
            {
              'name': "Availability",
              'value': skill.available
            }
          ]
        });
      });
    },
    error => {
      console.log('Could not load skills graph');
    }
  );
  }

}
