import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable, from } from 'rxjs';
import { UserInfoService } from './user-info.service';




@Injectable()
export class CompanyInfoService {

  constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }

  getCompanyInfo() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getCompanyInfo", {}, {headers: headers }).pipe(
			map(response => {
				{
					return response['companyInfo'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}


	updateCompanyInfo(companyData) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/updateCompanyInfo", companyData, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	uploadLogo(logo) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/uploadLogo", logo, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getGroups() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getGroups", { }, {headers: headers }).pipe(
			map(response => {
				{
					return response['groups'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getUserGroups() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getUserGroups", { }, {headers: headers }).pipe(
			map(response => {
				{
					return response['userGroups'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	createGroup(group, groupPermissions) {
		let headers = new HttpHeaders();
		let data = {
			'group-name': group,
			'group-permissions': groupPermissions
		}
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/createGroup", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	editGroupPermissions(group, groupPermissions) {
		let headers = new HttpHeaders();
		let data = {
			'group-id': group,
			'group-permissions': groupPermissions
		}
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/updateGroupPermissions", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getGlobalPermissions() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getGlobalPermissions", { }, {headers: headers }).pipe(
			map(response => {
				{
					return response['globalPermissions'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getGroupPermissions(groupId) {
		let headers = new HttpHeaders();
		let data = { 'group-id': groupId }; 
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getGroupPermissions", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['groupPermissions'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	
	getCompanyUserData(){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getCompanyUserData", {headers: headers }).pipe(
			map(response => {
				{
					return response['userData'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	createUserGroup(userId, groupId){
		let headers = new HttpHeaders();
		let data = {
			'user-id' : userId,
			'group-id' : groupId
		};
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/createUserGroup", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getCompanyMetrics(){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getCompanyMetrics", {}, {headers: headers }).pipe(
			map(response => {
				{
					return response['metrics'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getSubscriptionInfo(){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getSubscriptionInfo", {}, {headers: headers }).pipe(
			map(response => {
				{
					return response['subscriptionDetailsAll'][0];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getSubscriptionMetricDetail(metricId) {
		let headers = new HttpHeaders();
		let data = { 'metric-id': metricId }; 
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/getSubscriptionMetricDetail", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['subscriptionMetricDetail'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	deleteUserGroup(userId, groupId){
		let headers = new HttpHeaders();
		let data = {
			'user-id' : userId,
			'group-id' : groupId
		};
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/deleteUserGroup", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	deleteGroup(groupId){
		let headers = new HttpHeaders();
		let data = {
			'group-id' : groupId
		};
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/deleteGroup", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

  getCompanyGroups() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "company-permissions/getGroups", {}, { headers: headers }).pipe(
        map(response => {
          {
            return response['groups'];
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }
  inviteUser(emails, groupids) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let data = { 'emails': emails, 'groupids': groupids };
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "company-permissions/inviteUser", data, { headers: headers }).pipe(
        map(response => {
          {
            return response['status'];
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }

	setCompanyUserActive(userId){
		let headers = new HttpHeaders();
		let data = {
			'user-id' : userId
		};
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"employer/setCompanyUserActive", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	setCompanyUserInactive(userId){
		let headers = new HttpHeaders();
		let data = {
			'user-id' : userId
		};
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"employer/setCompanyUserInactive", data, {headers: headers }).pipe(
			map(response => {
				{
          return { response
        };
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	updateInvitedUserGroup(userEmail, selectedGroups) {
		let headers = new HttpHeaders();
		let data = {
			'user-email' : userEmail,
			'group-ids' : selectedGroups
		};
		headers.append('Content-Type', 'application/json');
		console.log(data);
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"company-permissions/updateInvitedUserGroups", data, {headers: headers }).pipe(
			map(response => {
				{
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
}
