
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';


@Injectable()
export class ShortlistService {
	backButtonShareData = new Subject();
    refreshShortlistData = new Subject();// Refresh shortlist data if shortlist edited or deleted
	IsLoggedIn = new EventEmitter();
	constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }

	postShortlist(shortlist) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		let data = { shortlist: shortlist };
		let apiName = '';
		if (shortlist.id > 0) {
			apiName = 'shortlist/editShortlist';
		} else {
			apiName = 'shortlist/createShortlist';
		}
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + apiName, data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== undefined) {
						return response['status'];
					}
					console.log("post shortlist failed");
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));

	}

	getShortlistList(userid) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = { "user_id": userid };		
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "shortlist/getShortLists", data, { headers: headers }).pipe(
			map(response => {
				{					
					if (typeof (response) !== undefined) {
						return response['shortlist'];
					}
					console.log("could not get shortlist list")
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));
	}

	getShortlistDetail(id) {
		let data = { "shortlist-id": id };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "shortlist/getShortlistDetail", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['shortlistDetail'];
					}
					else {
						console.log('Couldnt get shortlist details');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));

	}

	getShortlistImage() {		
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "shortlist/getShortlistImage", {}, { headers: headers }).pipe(
			map(response => {
				{					
					if (typeof (response) !== undefined) {
						return response;
					}
					console.log("could not get shortlist list")
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));
	}

	deleteShortlist(id) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = { id: id };		
		
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 'shortlist/deleteShortlist', data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== undefined) {
						return response['status'];
					}
					console.log("post shortlist failed");
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));

	}

	updateArchive(id, value){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = { id: id, value : value };		
		
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 'shortlist/updateArchive', data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== undefined) {
						return response['status'];
					}
					console.log("unable to update archive value");
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));

	}

	postCandidateShortList(candidateshortlist){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = { candidateshortlist: candidateshortlist };
		
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 'shortlist/createCandidateShortlist', data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== undefined) {
						return response['status'];
					}
					console.log("post candidate-shortlist failed");
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));

	}

	getCandidateShortList(candidateId){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = { candidateId: candidateId };			
		
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 'shortlist/getCandidateShortlist', data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== undefined) {
						return response;
					}
					console.log("get candidate-shortlist failed");
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));

	}

	getShortListCandidates(shortlistId, pageNumber, pageSize){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = { shortlistId: shortlistId, "pagenumber" : pageNumber, "pagesize": pageSize  };
		
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 'shortlist/getShortlistCandidates', data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== undefined) {						
						return response;
					}
					console.log("get shortlist-candidate failed");
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));
	}

	deleteShortlistCandidate(shortlistId, candidateId){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = { shortlistId: shortlistId, candidateId : candidateId   };
		
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 'shortlist/deleteShortlistCandidate', data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== undefined) {
						return response;
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));

	}
	

}
