import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../../Services/admin-api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TalentApiService } from '../../../Services/talent-api.service';


@Component({
  selector: 'app-news-feed-edit',
  templateUrl: './news-feed-edit.component.html',
  styleUrls: ['./news-feed-edit.component.css']
})
export class NewsFeedEditComponent implements OnInit {
  editing: any;
  public isReloadData = false;
  tenants:any;
  userTypes:any;

  constructor(private _adminApiService: AdminApiService,
	private dialogRef: MatDialogRef<NewsFeedEditComponent>,
	private _talentApiService: TalentApiService) { }

  ngOnInit(): void {
    if(this.editing.expiration) {
      this.editing.expiration = new Date(this.editing.expiration);
    }
  }

  closeDialog() {
	  this.dialogRef.close();
  }

  modalAddNew() {
	if(this.editing.expiration == undefined) {
		this.editing.expiration = 0;
	}
    this._adminApiService.createNewsSource(this.editing.fk_user_type, this.editing.feedUrl,
       this.editing.priority, this.editing.expiration, this.editing.fk_tenant_id,
       this.editing.active, this.editing.expires).subscribe((result: any) => {
				this._talentApiService.snackbarMessage('News Source has been added.');
				this.isReloadData = true;
				this.dialogRef.close();
			},
			error => {
				this._talentApiService.snackbarMessage('Error adding news source');
			});
  }

  modalDelete() {
	if (confirm('Are you sure you want to want to delete this News Source?')) {
		this._adminApiService.deleteNewsSource(this.editing.id).subscribe((result: any) => {
			this._talentApiService.snackbarMessage('News Source has been deleted.');
			this.isReloadData = true;
			this.dialogRef.close();
		},
		error => {
			this._talentApiService.snackbarMessage(error.msg);
		}
		);
	}
  }

  modalSave() {
	if(this.editing.expiration == undefined) {
		this.editing.expiration = 0;
	}
	this._adminApiService.updateNewsSource(this.editing.id, this.editing.fk_user_type, this.editing.feedUrl,
    this.editing.priority, this.editing.expiration, this.editing.fk_tenant_id,
    this.editing.active, this.editing.expires).subscribe((result: any) => {
			this._talentApiService.snackbarMessage('News Source has been saved.');
			this.isReloadData = true;
			this.dialogRef.close();
			},
			error => {
				this._talentApiService.snackbarMessage(error.msg);
			}
		);
  }

}
