import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { EmployerStudentService } from '../../../Services/employer-student.service';
import { TalentApiService } from '../../../Services/talent-api.service';

export interface DialogData {
	candidateId: string;
}

@Component({
  selector: 'app-invite-to-opportunities',
  templateUrl: './invite-to-opportunities.component.html',
  styleUrls: ['./invite-to-opportunities.component.css']
})
export class InviteToOpportunitiesComponent implements OnInit {

  public opportunities = [];
  public isLoading: boolean = false;
  public isNextClicked: boolean = false;
  public isSubmitting: boolean = false;
  public form = this.fb.group({
    selectedOpportunities: ['', [Validators.required]]
  });

  constructor(
    private fb: UntypedFormBuilder,
    private _employerStudentService: EmployerStudentService,
    public _dialog: MatDialog,
    private _talentApiService: TalentApiService,
    private dialogRef:MatDialogRef<InviteToOpportunitiesComponent>, @Inject(MAT_DIALOG_DATA) public input: DialogData
    ) {
    dialogRef.backdropClick().subscribe(result => {
      dialogRef.disableClose = true; //disable default close operation
      dialogRef.close();
    });
  }

  ngOnInit(): void {
    this.getOpportunitiesForCandidate();
  }

  getOpportunitiesForCandidate() {
    this.isLoading = true;
    this._employerStudentService.getPostingsForCandidate(this.input.candidateId)
		.subscribe((result: any) => {
		if (result) {
      this.opportunities = result;
      this.isLoading = false;
		} else {
			console.log('could not get the opportunities for candidate');
		}
		});
  }

  onNoClick(): void {
		this.dialogRef.close();
	}
	
	close(): void {
		this.dialogRef.close();
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  submit():void {
    this.isNextClicked = true;
    this.isSubmitting = true;
    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      this.isSubmitting = false;
      return;
    } else {
      const formVal = this.form.value;
      this._employerStudentService.inviteToView({id: this.input.candidateId}, formVal.selectedOpportunities)
      .subscribe(
      (result:any) => {
            this.close();
            this._talentApiService.snackbarMessage('Invited successfully!');
            this.isSubmitting = false;
      },
      error => {
        this.isSubmitting = false;
            // this._talentApiService.snackbarMessage('Something went wrong!');
      });
    }
  }
}
