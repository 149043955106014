import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TalentApiService } from '../../Services/talent-api.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  public isVerified = false;
  public isLoading = false;
  public userType: number = 0;
  constructor(private route: ActivatedRoute, private router: Router, private _talentApiService: TalentApiService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.token && params.token !== '') {
        this.isLoading = true;
        this._talentApiService.verifyEmail(params.token).subscribe(
          (result: any) => {            
            if (result[0].id != undefined && parseInt(result[0].id) > 0) {
              this.isVerified = true;
              this.userType = result[0].userType;
              this.isLoading = false;
            }
          },
          error => {
            this.isVerified = false;
            this.isLoading = false;
          }
        );
      }
    });

  }

  login() {
    this.router.navigateByUrl('/login');
  }

  redirecToMatch() {
    location.href = 'postings/matched';
  }
}
