import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { any } from 'codelyzer/util/function';
import { AdminApiService } from '../../../Services/admin-api.service';
import { TalentApiService } from '../../../Services/talent-api.service'


@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})

export class UserInfoComponent implements OnInit {
  editing:any;
  modalInEditMode = false;
  public isReloadData: boolean = false;
  tempSaveEditing:any;
  public userGroups:any;
  // public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];


  constructor(private _adminApiService:AdminApiService,private dialogRef:MatDialogRef<UserInfoComponent>,private _talentApiService: TalentApiService ) {  

  }

  ngOnInit() {

  }

  closeDialog(){
    this.dialogRef.close();
  }

  modalEnableDisable(status){
    let confirm_msg = (status ? "Are you sure you want to want to enable this user? Once enabled, they will be able to login as normal." : "Are you sure you want to want to disable this user? They will no longer be able to login.");
    if(confirm(confirm_msg)) {
      this._adminApiService.modalEnableDisable(this.editing.id,status)
        .subscribe(
          (result:any) => {
            this._talentApiService.snackbarMessage("User '#" + this.editing.id + " <" + this.editing.email + ">' has been "+(status ? "Enabled" : "Disabled")+".");
            this.isReloadData=true;
            this.dialogRef.close();
          },
          error => {
            this._talentApiService.snackbarMessage(error.msg);
          }
        );
      }
  }

  modalCancelEdit(){
    this.editing = this.tempSaveEditing;
    this.tempSaveEditing = {};
    this.modalInEditMode = false;
  }


  modalEdit(){
    this._adminApiService.getUserTypes().subscribe((result:any) => {
      this.userGroups = result;
      this.tempSaveEditing  = Object.assign({}, this.editing); 
      this.modalInEditMode = true;
    });
  }
  modalCancel(){
    this.isReloadData=true;
    this.dialogRef.close();
  }

  displayValid(inputRef: any): boolean {
		let errors: any = inputRef.errors;
		return errors && errors.pattern;
  }
  
  modalSave(){
    if (this.editing && this.editing.id && this.editing.firstName && this.editing.lastName && this.editing.email && this.editing.fk_userGroup) {

      this._adminApiService.editUser(this.editing)
        .subscribe(
          (result:any) => {           
            this._talentApiService.snackbarMessage("User '" + this.editing.firstName + " " + this.editing.lName + "' has been saved.");
            this.isReloadData=true;
            this.dialogRef.close();   
          },
          error => {
            this._talentApiService.snackbarMessage(error.msg);
          }
        );
      } else {
        console.log(this.editing);
        this._talentApiService.snackbarMessage("Invalid user. Please check your parameters and try again.");
      }
  
  }
 
}
