import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	Route,
	Resolve
} from '@angular/router';
import { LoginService } from './login.service';
import { UserInfoService } from './user-info.service';
import { TalentApiService } from './talent-api.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';



@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(private _loginService: LoginService, private _router: Router, 
		private _userInfoService: UserInfoService, private _talentAPIservice: TalentApiService) { }

	// canActivate checks whether the current user has permission to access a specific frontend route
	// This is called by angular to activate routes guarded in the app router
	// 		@params: route and state passed in by angular
	// 		@effects: if the user details are undefined (i.e.) on initial login, the app will
	// 			 	  hold on getUserDetail
	// 		@retuns: a boolean observable which emits the return value of isAuthorized
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> {
		let userObject = this._userInfoService.getUser();
		let isUserDefined:boolean = !(!userObject.type || userObject.type == '0' || !userObject.id || userObject.id == '0');
		return this._userInfoService.authenticateRoute(state.root.firstChild.routeConfig.path).pipe(
				switchMap(result => {
					let auth = !!result['status'];
					if (!auth){ // route not available for logged in user
						this.loginRedirect();
						return of(false);
					}
					if(!isUserDefined){
						return this._loginService.getUserDetail(); //
					}
					return of(auth); // permission granted (observable of true)
				}),
				catchError((error) => { // if isAuthorized errors,
					this._router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
					//this.loginRedirect(); // then permission is denied
					return of(false);
				}),
			// this map takes the observable returned above
			// either userInfo or auth status, and returns 
			map((result) =>{ 
				if (typeof result === "boolean"){
					return result;
				} else { //if result is not of type boolean, then it is of type userDetail
					// and user must be authorized (see switchMap logic)
					return true;
				}
			}));
	}

	// redirects to dashboard when a route is accessed without permission
	loginRedirect() {
		this._router.navigate(['/dashboard']);
	}

}
