import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { UserInfoService } from './user-info.service';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../Services/login.service';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import { TalentApiService } from './talent-api.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private isRefreshing = false; // flag/semaphore for protecting refreshToken resource
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	// These urls are exceptions to 401s calling refresh tokens
	private loggedOutUrls = [
		'/', '/login', '/employer_register'
	];

	constructor(public _userInfoService: UserInfoService, private _router: Router,
			public _loginService: LoginService,
			public _talentApiService: TalentApiService) { }

	// intercepts the frontend's request, sends the request,
	// and chooses how to use the response
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		

		if (this._userInfoService.getAccessToken()) {// append the access token to the request headers
			request = this.addToken(request, this._userInfoService.getAccessToken());
			
		}

		// check google api maps request url to not include Authorization and Content-Type headers 
		 const exclude = request.url.includes("https://maps.googleapis.com/maps/api/geocode");
		 if(exclude){
		 	if (request.headers.has('Content-Type')) {
		 		request = request.clone({ headers: request.headers.delete('Content-Type') });
		 	}
			if(request.headers.has('Authorization')){
				request = request.clone({ headers: request.headers.delete('Authorization') });
			}
		 }
		// sends request, return response if error free
		return next.handle(request).pipe(catchError(error => {
			// On access token authentication and login authentication errors
			if (error instanceof HttpErrorResponse && error.status === 401) {
				if(this.loggedOutUrls.includes(this._router.url)) {
					return throwError(error);
				}
				if(error.error.hasPermission !== undefined && error.error.hasPermission === false) {
					this._talentApiService.snackbarMessage('Unauthorized: Contact your system administrator');
					this._router.navigate(['/dashboard']);
					return throwError(error);
				}
				return this.handle401Error(request, next);
			} 
			// On refresh token authentication errors
			if (error instanceof HttpErrorResponse && error.status === 403) {
				this._loginService.logout().subscribe();
				this.isRefreshing = false;
				return throwError(error);
			} 
			else {
				return throwError(error);
			} 
		}));
	}

	// appends the token bearer to the request
	// this is used in backend middleware 
	// authentication
	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				'Authorization': `Bearer ${token}`
			}
		});
	}


	// Only one call will refresh the tokens, the subsequent calls
	// will wait for the token refresh
	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			return this._userInfoService.refreshToken().pipe(
				switchMap((tokens: any) => {
					this.isRefreshing = false; // semaphore blocking trailing calls
					this._userInfoService.storeTokens(tokens);
					// handle requests that have been waiting (requests n=2,3,4...)
					this.refreshTokenSubject.next(this._userInfoService.getAccessToken());
					// handle first failed request (n=1)
					return next.handle(this.addToken(request, this._userInfoService.getAccessToken())); 
				}));
		} 
		else {
			return this.refreshTokenSubject.pipe( 
				filter(token => token != null),
				take(1),
				switchMap(jwt => {
					return next.handle(this.addToken(request, jwt));
				}));
		}
	}
}