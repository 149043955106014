import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { ConfigurationModel } from "../Helpers/tjsConfigurationHelper";
import { UserInfoService } from "./user-info.service";
import { throwError as observableThrowError, Observable } from "rxjs";
import { DegreeType } from "../Models/degree-type";

@Injectable()
export class OpportunityService {
  getPublicPostings(companyName) {
    return this._http
      .get(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getPublicPostings?company=" +
          companyName
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              this.opportunityList = response["postings"];
              return response;
            }
            console.log("could not get opportunity list");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }
  getPublicPostingDetails(postingId) {
    return this._http
      .get(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getPublicPostingDetail?posting=" +
          postingId
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              return response["opportunityDetail"];
            }
            console.log("could not get posting details");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  public opportunityList: any;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _userInfoService: UserInfoService
  ) {}

  getOpportunities(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getOpportunityList",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              this.opportunityList = response["opportunityList"];
              return response;
            }
            console.log("could not get opportunity list");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  filterWiseGetOpportunities(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getOpportunityList",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              this.opportunityList = response["opportunityList"];
              return response["opportunityList"];
            }
            console.log("could not get opportunity list");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  GetOpportunitiesJobTitle() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getOpportunityTitle",
        {},
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              this.opportunityList = response["opportunityTitle"];
              return response["opportunityTitle"];
            }
            console.log("could not get opportunity titles");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  GetCommitmentData() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getOpportunityType",
        {},
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              this.opportunityList = response["opportunityType"];
              return response["opportunityType"];
            }
            console.log("could not get opportunity types");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  GetWorkSetupData() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getWorkSetup",
        {},
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              return response["workSetup"];
            }
            console.log("could not get opportunity types");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  GetMatchBuckets() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getMatchBuckets",
        {},
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof response !== undefined) {
              return response["matchBuckets"];
            }
            console.log("could not get match buckets");
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  getDegreeTypes(): Observable<DegreeType[]> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    return this._http
      .post<DegreeType[]>(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getOpportunityDegree",
        {},
        { headers: headers }
      )
      .pipe(
        map((response) => {
          if (typeof response !== undefined) {
            this.opportunityList = response["opportunityDegree"];
            return response["opportunityDegree"];
          }
          console.log("could not get opportunity degree");
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  deletePosting(id) {
    let data = { "opportunity-id": id };
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/deleteOpportunity",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof (response !== "undefined")) {
              return response["status"];
            } else {
              console.log("Couldnt get data");
            }
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  updateOpportunityCandidateStatus(
    candidate_id: string,
    opportunity_id: string,
    status_id: string
  ) {
    let headers = new HttpHeaders();
    let data = {
      candidate_id: candidate_id,
      opportunity_id: opportunity_id,
      status_id: status_id,
    };
    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/updateOpportunityCandidateStatus",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof (response !== "undefined")) {
              return response;
            } else {
              console.log("Could not change candidate status.");
            }
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  getMatchedCandidatesForOpportunity(opportunity_Id) {
    let headers = new HttpHeaders();
    let data = {
      opportunity_id: opportunity_Id,
    };
    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getMatchedCandidatesForOpportunity",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof (response !== "undefined")) {
              return response;
            } else {
              console.log("Could not get matched candidate data .");
            }
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }
  getEngagedCandidatesForOpportunity(
    opportunity_Id,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection
  ) {
    let headers = new HttpHeaders();
    let data = {
      "opportunity-id": opportunity_Id,
      pagenumber: pageNumber,
      pagesize: pageSize,
      sortby: sortBy,
      sortdirection: sortDirection,
    };
    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/getEngagedStudentsForOpportunity",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof (response !== "undefined")) {
              return response;
            } else {
              console.log("Could not get engaged candidate data .");
            }
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  requestAvailableFields(opportunityId, candidateId) {
    let headers = new HttpHeaders();
    let data = { candidate_id: candidateId, opportunity_id: opportunityId };

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/requestAvailableFields",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof (response !== "undefined")) {
              return response;
            } else {
              console.log("Could not insert record.");
            }
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }

  insertContactCandidate(opportunityId, candidateId) {
    let headers = new HttpHeaders();
    let data = { candidate_id: candidateId, opportunity_id: opportunityId };

    return this._http
      .post(
        ConfigurationModel.Configuration.BASE_API_URL +
          "opportunity/contactCandidate",
        data,
        { headers: headers }
      )
      .pipe(
        map((response) => {
          {
            if (typeof (response !== "undefined")) {
              return response;
            } else {
              console.log("Could not insert record.");
            }
          }
        }),
        catchError((error) => observableThrowError({ status: error.status }))
      );
  }
}
