<div fxLayout="row wrap" *ngIf="!isLoadingResult" fxLayoutGap="10px grid" fxLayoutAlign="start center" class="application-feed">  
  <div fxLayout="column"
       fxFlex="49"
       fxFlex.sm="100"
       fxFlex.md="100"
       fxFlex.lt-md="100"
       *ngFor="let applicant of applicants"
       [ngClass]="{'applicationfeedinserted': breakpoint == 0, '': breakpoint != 0}"
       (click)="getCandidateDetails(applicant.id)">
      <app-student-small-card [candidateData]="applicant"
                              [breakpoint]="breakpoint"
                              [isPostingView]="isPostingView"></app-student-small-card>
  </div>
</div>
<!-- Paginator -->
<div fxLayout="row" *ngIf="applicants.length > 0" fxLayoutAlign="end">
  <mat-paginator [pageSizeOptions]="[20,40,60,80,100]" [length]="totalLength" [pageSize]="20"
    (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
</div>
<div fxLayout="row" *ngIf="!isLoadingResult && applicants.length == 0" >
  <h3>No Applications found!</h3>
</div>
<div *ngIf="isLoadingResult" fxLayout="row" fxLayoutAlign="center" >
  <mat-spinner></mat-spinner>
</div>
