
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable,EventEmitter} from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';

@Injectable()
export class TimesheetService {

  constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }

  timesheetData = new EventEmitter<any>();
  getStudentTimesheets(){
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getStudentTimesheets", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['studentTimesheets'];
					}
					else {
						console.log('Couldnt get student profile data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	  	
  }

	getStudentOpportunities(){
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getStudentOpportunities", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['studentOpportunities'];
					}
					else {
						console.log('Couldnt get student profile data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
		
	}

	 formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
	
		return [year, month, day].join('-');
	}

	updateStudentTimesheet(timeentry_id, date, opportunity_id, entered_time){
		date = this.formatDate(date);
		let data = { timeentry_id:timeentry_id, date:date, opportunity_id:opportunity_id, entered_time:entered_time };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/updateTimeEntry", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['status'];
					}
					else {
						console.log('Couldnt get save data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	deleteTimeEntry(timeentry_id){
		let data = { timeentry_id:timeentry_id };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/deleteTimeEntry", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['status'];
					}
					else {
						console.log('Couldnt delete Time Entry');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);	
	}

	getTimesheet(oppId, stdId, postingType){
		let data = { opportunity_id: oppId, student_id: stdId, postingType};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getTimesheet", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['timesheet'];
					}
					else {
						console.log('Couldnt get Time Entry');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);	
	}

	getOpportunityList(postingType = "open"){
		let data = { postingType };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getOpportunityListings", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['opportunityListings'];
					}
					else {
						console.log('Couldnt get Time Entry');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);		
	}

	getOpportunityStudentList(opportunityId){
		let data = { opportunityId:opportunityId };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getOpportunityStudentList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['opportunityStudentList'];
					}
					else {
						console.log('Couldnt get Time Entry');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);		
	}

	updateTimesheetStatus(timesheetId, status){
		let data = { timesheetId: timesheetId, status: status};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/updateTimeEntryStatus", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt get Time Entry');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);		
	}

}
