import { Component, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CandidateLocation } from '../../../../Models/CandidateProfile';
import { RegisterService } from '../../../../Services/register.service';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { BreakpointObserver } from '@angular/cdk/layout';


@Component({
  selector: 'app-candidate-location',
  templateUrl: './candidate-location.component.html',
  styleUrls: ['./candidate-location.component.css']
})
export class CandidateLocationComponent implements OnInit {

  @Input() location: CandidateLocation;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  public states = ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
		'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
		'WY').split(' ').map(function (state) {
			return { abbrev: state };
    });
    
  cities = [];
  zipPattern = "[0-9]*";
  public form = this.fb.group({
    streetaddress: ['', [Validators.required, Validators.maxLength(255)]],
    address2: ['', [Validators.maxLength(10)]],
    city: ['', [Validators.required, Validators.maxLength(255)]],
    state: ['', [Validators.required]],
    zip: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(this.zipPattern)]]
  });
	public breakpoint:number; // used to classify the screen size/display width
  constructor(private fb: UntypedFormBuilder,
    private _registerService: RegisterService,
    private _talentAPIService: TalentApiService,
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    this.form.patchValue(this.location);
    this.getCities();
  }

  getCities() {
    this._registerService.getMetroList().subscribe(
      (result) => {
        if(result) {
          // result.forEach(element => {
          //   this.cities.push(element.name);
          // });
          this.cities = result;
        }
      },
      (error) => {}
    );
  }

  querySearch(query, sourcelist) {
		if (typeof query === 'string') {
			if (query != undefined && query != '' && query != null) {
				let results: any;
				results = query ? sourcelist.filter(this.createFilterFor(query)) : [];
				return results;
			}
		}
		else {
			if (query != undefined && query.value != '' && query.value != null) {
        console.log('non string');
				let array = $.map(sourcelist, function (value, index) {
					return [value];
				});
				let results: any;
				results = query.value ? array.filter(this.createFilterFor(query.value)) : [];
				return results;
			}
		}
	}

	createFilterFor(query) {
		var lowercaseQuery = query.toLowerCase();
		return function filterFn(item) {
			return (item.name.toLowerCase().indexOf(lowercaseQuery) != -1);
		};
	}

  next() {
    const candidatelocationObject = this.form.value;
    const candidateProfile = {
      location: this.form.value
    }

    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
        if (result) {
          this.saveProfile.emit(this.form.value);
          var data = candidatelocationObject;
          if(this.edit === true) {
            this.dialogRef.close(data);
          }
        }
        else {

        }
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }

  skip() {
    this.saveProfile.emit(this.location);
  }

  prev() {
    this.prevEvent.emit();
  }

}
