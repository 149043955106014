<div fxLayout="row wrap" fxFlex="100" [ngClass.xs]="'box-padding'" [ngClass.sm]="'box-padding'">
  <h1 mat-dialog-title class="margin-top-5" fxFlex="50">
    {{shortlistId === 0 ? 'New' : 'Edit'}} Shortlist
  </h1>
  <div fxFlex="50" fxLayoutAlign="end start">
    <button *ngIf="shortlistId > 0 && this.hasDeleteShortlistPermission" mat-icon-button color="warn" tabindex="-1" (click)="deleteShortlist()">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button (click)="confirmDiscard()" id="btnClose" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>  
  <hr class="margin-24" fxFlex="100">
  <mat-dialog-content fxFlex="100" class="mat-typography scroll-content" [ngClass.sm]="'box-padding'" [ngClass.xs]="'box-padding'" cdkScrollable>
    <form [formGroup]="shortListForm" (ngSubmit)="submit()" fxLayout="row wrap" fxLayoutAlign="start start" class="">     
      <h4 class="remove-margin" fxFlex="100">Name</h4>
      <div fxLayout="row"  fxFlex="100">
        <mat-form-field color="accent" appearance="outline" class="form-item" fxFlex="100">
          <!--<mat-label>Name</mat-label>-->
          <input matInput formControlName="name" name="name" minlength="3" maxlength="255" tabindex="0"
             required placeholder="Name *">     
          <mat-error *ngIf="f.name.hasError('required')">
            You did not enter name
					</mat-error>
          <mat-error *ngIf="f.name.hasError('maxlength')">
            Name can be maximum of 255 letter
          </mat-error>
          <mat-error *ngIf="f.name.hasError('minlength')">
            Name can be minimum of 3 letter
          </mat-error>        
        </mat-form-field>        
      </div>    
      <!-- {{shortlistImages[0].image}} -->
      <h4 class="remove-margin mb-15" fxFlex="100">Select image that best describes this list</h4>      
      <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="15px grid" >
        <div fxLayout="column" fxFlex.gt-md="14" fxFlex.xs="48" fxFlex *ngFor="let shortlistImage of shortlistImages" >
          <span fxFlex class="sortlist-title">{{shortlistImage.name}}</span>          
          <img [ngClass]="(shortlistImage.id==selectedImage)?'sortlist-svg focus':'sortlist-svg'"
               [src]="shortlistImage.image|safeHtml"
               alt="{{shortlistImage.name}} image"
               (click)="selectSector(shortlistImage.id)" />
          
        </div>        
      </div>
      <h4 class="remove-margin" fxFlex="100">Description</h4>
      <div fxLayout="row wrap" fxFlex="100">
        <mat-form-field color="accent" appearance="outline" class="form-item" fxFlex="100">          
          <textarea matInput 
            cdkTextareaAutosize            
            formControlName="description" 
            name="description" 
            placeholder="Description" 
            >
          </textarea>
          <mat-error *ngIf="f.description.hasError('maxlength')" class="help-block small animated fadeInDown">
            The description must be less than 255 characters
          </mat-error>
          <mat-hint align="end">{{getContentCount(f.description.value)}} / 255</mat-hint>
        </mat-form-field>
      </div>      
      <!-- <h4 class="remove-margin" fxFlex="100">Link Skills</h4>
      <div fxLayout="row" fxFlex="100" class="sortlist-select-box">
        <mat-form-field appearance="fill">          
          <mat-select class="sortlistmatSelect"  [formControl]="f.skills" [(ngModel)]="selectedSkillList"   multiple required>
            <mat-option  *ngFor="let skill of skillsList" [value]="skill.id">{{skill.name}}</mat-option>            
          </mat-select>
          <mat-error *ngIf="f.skills.hasError('required') || (f.submitted && f.skills.invalid)">
            Please choose skill(s)
          </mat-error>
        </mat-form-field>        
      </div> -->
            
      <h4 class="remove-margin" fxFlex="100">Link Skills</h4>
      <div fxLayout="row" fxFlex="100" >
        <mat-form-field color="accent" appearance="outline" class="example-chip-list">
          <mat-chip-list #skilllistmat aria-label="Skill selection" [formControl]="f.skills">
              <mat-chip *ngFor="let skill of selectedSkills" [selectable]="selectable"
                      [removable]="removable">
              {{skill}}
              <mat-icon (click)="removeSkill(skill)" matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input placeholder="Search For Skills..."
                      [matChipInputFor]="skilllistmat"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="false"
                      [matAutocomplete]="auto"
                      (matChipInputTokenEnd)="addSkill($event)"
                      name="skillSearch"
                      id="skillSearch"
                      (blur)="addSkillOnBlur($event, skilllistmat)"
                      #skillName
                      [formControl]="skillCtrl"
                      #trigger="matAutocompleteTrigger"
                      (keydown.enter)="trigger.closePanel()">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkill($event)">
              <ng-container *ngFor="let skills of filteredSkill | async">
                  <mat-option [value]="skills.name" *ngIf="skills.name !=''">
                    {{skills.name}}
                  </mat-option>
              </ng-container>
            </mat-autocomplete>
            <!-- <mat-error *ngIf="f.skills.hasError('required') || (f.submitted && f.skills.invalid)">
              Please choose skill(s)
            </mat-error> -->
          </mat-form-field>        
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end" class="mat-padding">    
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="shortListForm.invalid">
      {{shortlistId == 0 ? 'Create' : 'Submit'}}
    </button>
  </mat-dialog-actions>
</div>
