<div *ngIf="postingId != 0" class="mat-modal-head" fxLayout=" wrap" fxLayoutGap="0 grid" fxLayoutAlign="start center">
  <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100" fxFlex.gt-sm="55" fxFlex.gt-md="66">
    <h2 mat-dialog-title>
      {{data.title}}
    </h2>
    <h4>
      {{data.sector}}
    </h4>
    <p>{{student.current_status | uppercase }} ON {{student.status_updated_at | date:'MM/dd/yyyy'}}</p>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="66" fxFlex.gt-sm="29" fxFlex.gt-md="21">
    <button *ngIf="student.fk_opportunity_student_status_id < 4 && student.fk_opportunity_student_status_id > 1 && this.hasAdvanceCandidatePermission" mat-raised-button color="primary"
            (click)="updateOpportunityCandidateStatus($event, '')">
      {{
student.candidate_opportunity_status |
            uppercase
      }}
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="34" fxFlex.gt-sm="16" fxFlex.gt-md="13">
    <button mat-stroked-button mat-button-base
            (click)="updateOpportunityCandidateStatus($event, 'remove')">
      REMOVE
    </button>
  </div>
  <button mat-icon-button (click)="close()" class="close_button">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="shortlistId != 0" class="mat-modal-head" fxLayout=" wrap" fxLayoutGap="0 grid" fxLayoutAlign="start center">
  <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100" fxFlex.gt-sm="55" fxFlex.gt-md="66">
    <h2 mat-dialog-title>
      {{data.name}}
    </h2>
    <p>
      SHORTLISTED ON :
      {{data.created_ts | date:'MM/dd/yyyy'}}
    </p>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="66" fxFlex.gt-sm="29" fxFlex.gt-md="21">

  </div>
  <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="34" fxFlex.gt-sm="16" fxFlex.gt-md="13">
    <button mat-raised-button color="primary"
            (click)="openInviteToApply()">
      Invite to Apply
    </button>
  </div>
  <button mat-icon-button (click)="close()" class="close_button">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="invite != undefined" class="mat-modal-head" fxLayout=" wrap" fxLayoutGap="0 grid" fxLayoutAlign="start center">
  <div fxLayout="column"  fxLayoutAlign="start center" fxFlex="34" fxFlex.gt-sm="16" fxFlex.gt-md="13">
    <button *ngIf="invite>0" mat-raised-button color="primary"
            (click)="inviteSinglePosting()">
      INVITE TO APPLY
    </button>
    <span *ngIf="invite==0"><strong>Invited To Apply</strong></span>
  </div>
  <button mat-icon-button (click)="close()" tabindex="-1" class="close_button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="aplication-user-popup">
  <app-profile [candidateId]="data.id" [jobCurrentStatus]="student.current_status" [postingId]="this.postingId"></app-profile>
</mat-dialog-content>
