import { Component, Input, OnInit, Output, EventEmitter, Optional, Inject, SimpleChanges } from '@angular/core';
import { PostingService } from '../../Services/posting.service';
import * as _ from 'underscore';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TalentApiService } from '../../Services/talent-api.service';
import { CommonConfirmPopupComponent } from '../dialogs/common-confirm-popup/common-confirm-popup.component';
import { OpportunityType } from '../../Models/opportunity-type';
import { OpportunityStatus } from '../../Models/Opportunity-status';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { SharePostingDialogComponent } from '../dialogs/share-posting-dialog/share-posting-dialog.component';
import { ContentReportingComponent } from '../dialogs/content-reporting/content-reporting.component';


@Component({
  selector: 'app-employer-posting-detail',
  templateUrl: './employer-posting-detail.component.html',
  styleUrls: ['./employer-posting-detail.component.css'],

})

export class EmployerPostingDetailComponent implements OnInit {
  @Input() user: any;
  @Input() applicants: any = [];
  @Input() detail: any;
  @Input() userType: number;
  @Input() showHeaderBack: any;
  @Input() breakpoint: number;

  public selectedStatus: any;
  public totalApplicantLength = 0; // Length of the candidate array
  public totalViewsLength = 0; // Length of the Views count	
  public statusTypeList: any = [];
  opportunityStatus: OpportunityStatus[] = [];
  public currStatus: any;
  IsMobileDevice = false;

  @Output() apply: EventEmitter<void> = new EventEmitter<void>();
  @Output() tag: EventEmitter<void> = new EventEmitter<void>();
  @Output() unTag: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private _postingService: PostingService,
    private _location: Location,
    private _talentApiService: TalentApiService,
    public breakpointObserver: BreakpointObserver,
    public _dialog: MatDialog) {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.IsMobileDevice = true;
        } else {
          this.IsMobileDevice = false;
        }
      });
  }

  getPostingStatus() {
    this.opportunityStatus = [
      { status: 0, name: 'Inactive' },
      { status: 1, name: 'Active' },
      { status: 2, name: 'Draft' }
    ];
  }

  ngOnInit() {
    this.bindOpportunityStatus();
    this.setInitialDetails(this.detail);
    this.getPostingStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInitialDetails(changes.detail.currentValue);
  }

  setInitialDetails(detail) {
    this.selectedStatus = detail.published != null ? detail.published.toString() : 1;
    this.currStatus = this.selectedStatus;
    this.totalApplicantLength = detail.applicants_count;
    this.totalViewsLength = detail.view_count;
  }

  onChangeStatus(newValue) {
    let CurrValue = this.selectedStatus;
    let dialog;
    if (!dialog == null) {
    } else {
      dialog = this._dialog.open(CommonConfirmPopupComponent, {
        height: this.IsMobileDevice ? '194px' : '185px',
        width: this.IsMobileDevice ? '80.66%' : '36.66%',
        disableClose: false,
      });
      dialog.componentInstance.post_type = 'Posting';
      dialog.componentInstance.confirm_message = 'Are you sure you want to update the posting: \'' + this.detail.title + '\' from ' + this.opportunityStatus[this.currStatus].name + ' to ' + this.opportunityStatus[newValue].name + '?';
      dialog.componentInstance.discardConfirm = true;
    }
    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this._postingService.updateOpportunityStatus(this.detail.id.toString(), newValue.toString()).subscribe(() => {
            this._talentApiService.snackbarMessage('Opportunity status has been updated successfully!');
            this._location.go('/postings');
            window.location.reload();
          }, () => {
            this._talentApiService.snackbarMessage('Something Went Wrong!');
          });

        } else {
          this.selectedStatus = this.currStatus;
        }
      });
  }

  bindOpportunityStatus() {
    this._postingService.getOpportunityStatus().subscribe((response) => {
      this.statusTypeList = response['opportunityStatusTypeList'];
    }, () => {
      this._talentApiService.snackbarMessage('Something Went Wrong!');
    });

  }

  sharePostingDialog() {
    let dialog = this._dialog.open(SharePostingDialogComponent, {
      height: '300px',
      width: this.IsMobileDevice ? '95%' : '50%',
      disableClose: true
    });
    dialog.componentInstance.postingName = this.detail.title;
    dialog.componentInstance.opportunityId = this.detail.id;
    dialog.afterClosed().subscribe((result) => { });
  }

  contReportingDialog() {
    let dialog = this._dialog.open(ContentReportingComponent, {
      height: this.IsMobileDevice ? '400px' : '350px',
      width: this.IsMobileDevice ? '95%' : '45%',
      disableClose: true,
      panelClass: 'posting_popup',
      data: {
        opportunityId: this.detail.id
      }
    });
    dialog.afterClosed().subscribe((result) => { });
  }

  copyLink() {
    var inputc = document.body.appendChild(document.createElement("input"));
    inputc.value = window.location.href;
    inputc.focus();
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode.removeChild(inputc);
    this._talentApiService.snackbarMessage('Link Copied.');
  }

}
