<div fxLayout="row wrap" fxFlex="100" [ngClass.xs]="'small-device-padding'">
  <h1 mat-dialog-title class="margin-top-5" fxFlex="50">
    {{postingId === 0 ? 'New' : 'Edit'}} Posting
  </h1>
  <div fxFlex="50" fxLayoutAlign="end start">
    <button *ngIf="postingId > 0 && this.hasDeletePostingPermission" mat-icon-button color="warn" tabindex="-1" (click)="deletePosting()">
      <mat-icon>delete</mat-icon>
    </button>
    <button  mat-icon-button (click)="confirmDiscard()" id="btnClose" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <hr class="margin-24" fxFlex="100">
  <mat-dialog-content fxFlex="100" class="mat-typography scroll-content" [ngClass.xs]="'box-padding'" cdkScrollable>
    <form [formGroup]="postingForm" (ngSubmit)="submit()" fxLayout="row wrap" fxLayoutAlign="start start" class="">
      <h4 class="padBottom12px" fxFlex="100">Job Details</h4>
      <!--REMOVE AND AUTOMATICALLY SET COMPANY TO USER'S COMPANY-->
      <div fxLayout="row" fxFlex="100">
        <mat-form-field color="accent"
                        appearance="outline"
                        [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}"
                        fxFlex="100">
          <mat-label>Company Name</mat-label>
          <input matInput name="autocompleteFieldcompany" tabindex="-1"
                 [formControl]="companyControl"
                 [matAutocomplete]="autoschool"
                 minlength="3"
                 maxlength="65"
                 (blur)="checkCompanyObject(companyControl)"
                 required>
          <mat-autocomplete #autoschool="matAutocomplete">
            <mat-option *ngFor="let company of companyQuerySearch(companyControl.value)" (onSelectionChange)="CompanyObject(company)" [value]="company.name">
              {{ company.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="companyControl.hasError('required')"> You must have a company name. </mat-error>
          <mat-error *ngIf="companyControl.hasError('maxlength')">This company name is too long (65 character maximum)</mat-error>
          <mat-error *ngIf="companyControl.hasError('minlength')">This company name is too short (3 character maximum)</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.xs="column wrap" fxLayoutGap="15px" fxFlex="100">
        <mat-form-field color="accent"
                        appearance="outline"
                        [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}"
                        fxFlex="66">
          <mat-label>Job Title</mat-label>
          <input matInput formControlName="title" name="postingTitle" minlength="3" tabindex="0"
                 maxlength="65" required>
          <mat-error *ngIf="f.title.hasError('required')"> You must have a job title. </mat-error>
          <mat-error *ngIf="f.title.hasError('maxlength')">Job title is too long (65 character maximum)</mat-error>
          <mat-error *ngIf="f.title.hasError('minlength')">Job title is too short (3 character maximum)</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline"
                        [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="34">
          <mat-label>Number of Positions</mat-label>
          <input matInput type="number" name="noofpos" [max]="99999999" formControlName="quantity"
                 required>
          <mat-error *ngIf="f.quantity.hasError('required')"> Please enter number of positions. </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column wrap" fxFlex="100">
        <mat-form-field color="accent" appearance="outline" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="50">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startDate" name="startdate" [min]="minPostingDate" formControlName="startDate"
                 required>
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate color="accent"></mat-datepicker>
          <mat-error *ngIf="f.startDate.hasError('required')"> Please select start date. </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="50">
          <mat-label>Expiration Date</mat-label>
          <input matInput [matDatepicker]="endDate" name="enddate" [min]="postingForm.value.startDate" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate color="accent"></mat-datepicker>
          <mat-hint *ngIf="postingForm.value.endDate!=null && postingForm.value.startDate>postingForm.value.endDate">Expiration date must be greater than start date</mat-hint>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex="100">
        <mat-form-field color="accent" appearance="outline" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="100">
          <mat-label>Locations</mat-label>
          <mat-chip-list #locationList aria-label="Location selection" name="locations">
            <mat-chip *ngFor="let loc of locations"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="removeLocation(loc)">
              {{loc}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Add multiple prefered location"
                   #locationInput
                   formControlName="locationCtrl"
                   [matAutocomplete]="autoAddress"
                   name="locationCtrl"
                   [matChipInputFor]="locationList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (ngModelChange)="getLocation($event)">
          </mat-chip-list>
          <mat-autocomplete #autoAddress="matAutocomplete"
                            (optionSelected)="selectedLocation($event)">
            <mat-option *ngFor="let item of locationSearchResult" [value]="item">
              {{ item.formatted_address }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <h4 class="remove-margin" fxFlex="100">Job Details</h4>
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start" class="padding-24">
        <div class="mat-chkbox-min-width box-padding" *ngFor="let cm of opportunityTypes">
          <mat-checkbox [checked]="cm.completed" (change)="opportunityValue(cm, $event.checked)"
                        name="opportunity">
            {{cm.type}}
          </mat-checkbox>
        </div>
      </div>
      <h4 class="remove-margin" fxFlex="100">Work Setup</h4>
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start" class="padding-24">
        <div class="mat-chkbox-min-width box-padding" *ngFor="let ws of workSetup">
          <mat-checkbox [checked]="ws.completed" (change)="workSetupValue(ws, $event.checked)"
                        name="workSetup">
            {{ws.name}}
          </mat-checkbox>
        </div>
      </div>
      <h4 class="remove-margin" fxFlex="100">Compensation</h4>
      <div fxLayout="row" fxLayoutGap="15px" fxFlex="100" fxLayout.xs="column wrap">
        <mat-form-field color="accent" appearance="outline" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="50">
          <mat-label>Min Hourly Rate</mat-label>
          <input matInput name="paylow" placeholder="Pay Low" (blur)="compensationValidation('Low')" id="paylow" type="number" [min]="0"
                 formControlName="compensationlow" [max]="99999999" required>
          <mat-hint *ngIf="compensationPayLowValidationStatus">Min hourly rate should be less than Max hourly rate</mat-hint>
          <mat-error *ngIf="f.compensationlow.hasError('required')">You must have Min Hourly Rate.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="50">
          <mat-label>Max Hourly Rate</mat-label>
          <input matInput
                 name="payhigh"
                 (blur)="compensationValidation('High')"
                 placeholder="Pay High"
                 type="number"
                 formControlName="compensationhigh"
                 required />
          <mat-hint *ngIf="compensationPayHighValidationStatus">Max hourly rate should be greater than Min hourly rate</mat-hint>
          <mat-error *ngIf="f.compensationhigh.hasError('required')"> You must have Max Hourly Rate. </mat-error>
        </mat-form-field>
      </div>
      <!--REPLACE WITH SKILLS SELECTOR FROM REGISTRATION (shared/candidate/candidate-skills)-->
      <h4 class="remove-margin" fxFlex="100">Required Skills</h4>
      <div fxLayout="row"
           fxLayoutAlign="space-between"
           fxFlex="100"
           [ngClass]="'form-item'"
           fxFlex.lt-md="100"
           fxFlex.md="95"
           fxFlex.gt-md="95">

        <!--<skill-tree-control [isMobileDevice]="isMobileDevice"
                      fxLayout="row"
                      [showSkillTree]="false"
                      [postingForm]="postingForm"
                      [_builder]="_builder"
                      fxFlex="100"></skill-tree-control>-->
        <button mat-stroked-button color="primary" (click)="openSkillDialog()">All Skills</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" *ngIf="t.controls.length > 0">
        <div fxLayout="column" fxFlex="100" fxLayoutGap="5px">
          <div fxLayout="row" fxLayoutGap="30px">
            <h4 class="remove-margin" fxFlex="60">Skills</h4>
            <h4 class="remove-margin" fxFlex="40">Years</h4>
          </div>
          <div *ngFor="let cs of t.controls; let expIndex = index;" fxLayout="row" [formGroup]="cs" fxLayoutGap="5px" fxFlex="100">
            <div fxFlex="60" fxFlex.xs="60" fxLayout="column">
              <mat-form-field color="accent" appearance="outline" fxFlex="70" > 
                <mat-label>Skill Name</mat-label>
                <input formControlName="name" matInput>
                <mat-error *ngIf="cs.controls.name.hasError('required')" class="help-block small animated fadeInDown">
                  <span fxShow fxHide.xs>This field is required!</span><span fxShow.xs fxHide>Required!</span>
                </mat-error>
                <mat-error *ngIf="cs.controls.name.hasError('maxlength')">This skill is too long (100 character maximum)</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="30" fxFlex.xs="28" fxLayout="column">
              <mat-form-field color="accent" appearance="outline" fxFlex="30">
                <mat-label>Experience years</mat-label>
                <mat-select formControlName="expYears" id="skexp{{expIndex}}" [aria-label]="cs.controls.name.value">
                  <mat-option *ngFor="let item of [].constructor(41);let i=index;" [value]="i.toString()">{{i}}</mat-option>
                </mat-select>
                <mat-error *ngIf="cs.controls.expYears.hasError('required')" class="help-block small animated fadeInDown">
                  <span fxShow fxHide.xs>This field is required!</span><span fxShow.xs fxHide>Required!</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="10" fxFlex.xs="12" fxLayoutAlign="start center">
              <button mat-button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="removeSkillWithYear(expIndex)">
                <mat-icon color="accent">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <h4 class="remove-margin" fxFlex="100">Education</h4>
      <div fxLayout="row wrap" fxFlex="100">
        <mat-form-field color="accent" appearance="outline" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="100">
          <mat-label>Minimum Qualifications</mat-label>
          <mat-select formControlName="degree_type" name="dType" required>
            <mat-option *ngFor="let dType of degreeTypes" [value]="dType.id">
              {{dType.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.degree_type.hasError('required')"> Please select minimum qualifications.</mat-error>
        </mat-form-field>
      </div>
      <h4 class="remove-margin" fxFlex="100">Job Summary</h4>
      <div fxLayout="row wrap" fxFlex="100">
        <quill-editor class="quillBox" placeholder="Enter job summary *"  formControlName="description"></quill-editor>
        <mat-error *ngIf="isFormSubmitted && f.description.hasError('required')"> Please enter job summary. </mat-error>
      </div>
      <h4 class="remove-margin margin-top-20" fxFlex="100">Benefits</h4>
      <div fxLayout="row wrap" fxFlex="100">
        <mat-form-field color="accent" appearance="outline" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" fxFlex="100">
          <mat-label>Benefit</mat-label>
          <textarea matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="3"
                    formControlName="benefits"
                    name="benefits"
                    placeholder="Benefits"
                    required>
          </textarea>
          <mat-error *ngIf="f.benefits.hasError('required')"> Please enter benefits. </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end" class="mat-padding">
    <button mat-stroked-button color="primary" type="button" [disabled]="postingForm.invalid" (click)="preview()">PREVIEW</button>
    <button mat-raised-button color="primary" (click)="submit()">{{postingId==0 ? "SAVE AS DRAFT" : "SUBMIT"}}</button>
  </mat-dialog-actions>
</div>
