import { Component, OnInit, Inject,Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ShortlistService } from '../../../Services/shortlist.service';
import { TalentApiService } from '../../../Services/talent-api.service';
import { UserInfoService } from '../../../Services/user-info.service';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface DialogData {
  candidateId: string;
}

@Component({
  selector: 'app-candidate-to-shortlist',
  templateUrl: './candidate-to-shortlist.component.html',
  styleUrls: ['./candidate-to-shortlist.component.css']
})
export class CandidateToShortlistComponent implements OnInit {  
  public user: any;
  public shortlists = [];
  public isLoading: boolean = false;
  public isNextClicked: boolean = false;
  public previousShortlist;
  public form = this.fb.group({
    candidateId : 0, 
    selectedShortlist: []    
  });  

  constructor(
    private fb: UntypedFormBuilder,
    private _shortlistService: ShortlistService,
    private _userInfoService: UserInfoService,
    public _dialog: MatDialog,
    private _talentApiService: TalentApiService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CandidateToShortlistComponent>,
     @Inject(MAT_DIALOG_DATA) public input: DialogData
  ) {    
    dialogRef.backdropClick().subscribe(result => {
      dialogRef.disableClose = true; //disable default close operation
      dialogRef.close();
    });    
    this.user = this._userInfoService.getUser();			
  }

  ngOnInit(): void {    
    this.user = this._userInfoService.getUser();
    this.getShortlists();
    this.form.patchValue({candidateId: parseInt(this.input.candidateId)});
    
  }

  getShortlists() {    
    this.isLoading = true;
    this._shortlistService.getShortlistList(this.user.id)
      .subscribe((result: any) => {        
        if (result) {          
          this.shortlists = result;          
          this.isLoading = false;
          this.getCandidateShortlists();
        } else {
          console.log('could not get the shortlist for candidate');
        }
      });
  }

  getCandidateShortlists(){    
    this.isLoading = true;
    this._shortlistService.getCandidateShortList(this.input.candidateId)
      .subscribe(
      (result:any) => {          
        this.isLoading = false;      
        this.previousShortlist = result['candidateShortlist'];    
        this.form.patchValue({selectedShortlist: result['candidateShortlist']});			
      },
      error => {
            this.isLoading = false;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  submit(): void {    
    this.isNextClicked = true;
    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return;
    } else {
      //const formVal = this.form.value;      
      var removedShortlists = $(this.previousShortlist).not(this.form.value.selectedShortlist).get();  
      var addShortlists = $(this.form.value.selectedShortlist).not(this.previousShortlist).get();
      var snackbarMessage = "";
      if(addShortlists.length > 0) {
        snackbarMessage = "Added candidate to "+ addShortlists.length + " shortlist(s)";
      }
      if(removedShortlists.length > 0) {
        snackbarMessage +="\nRemoved candidate from "+ removedShortlists.length +" shortlist(s)";
      }
      this._shortlistService.postCandidateShortList(this.form.value)
      .subscribe(
      (result:any) => {
            this.close();
            if(JSON.stringify(this.previousShortlist) != JSON.stringify(this.form.value.selectedShortlist)) {
              if(removedShortlists.length > 0 && addShortlists.length > 0) {
                this._snackBar.open(snackbarMessage, 'Ok', {
                  duration: 2000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  panelClass: ['success-snackbar']
                });
              } else {
                this._talentApiService.snackbarMessage(snackbarMessage);
              }
            }
      },
      error => {
            // this._talentApiService.snackbarMessage('Something went wrong!');
      });
    }
  }
}
