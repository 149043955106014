import { Component,  OnInit,  Optional, Inject, SimpleChanges, Input } from '@angular/core';
import { UserInfoService } from '../../Services/user-info.service';
import { ShortlistService } from '../../Services/shortlist.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TalentApiService } from '../../Services/talent-api.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { CreateShortlistDialogComponent } from '../dialogs/create-shortlist-dialog/create-shortlist-dialog.component';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
import { CommonConfirmPopupComponent } from '../../Pages/dialogs/common-confirm-popup/common-confirm-popup.component';



@Component({
	selector: 'app-shortlist-detail',
	templateUrl: './shortlist-detail.component.html',
	styleUrls: ['./shortlist-detail.component.css'],

})

export class ShortlistDetailComponent implements OnInit {
	public showDetail: any = true;
	public user: any;
	public detail: any = null;
	userType: number;
	isMobileDevice: any;
	showHeaderBack = false;
	public shortlistId = null;
	public isLoading = false;
	public clearInfo;
	public breakpoint: number;
	public logos:any[] = [];
  @Input() totalCount: number = 0;
  public resultDisplay: boolean = true;
  public hasEditShortlistPermission: boolean = false;

	constructor(@Optional() private dialogRef: MatDialogRef<ShortlistDetailComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
		public breakpointObserver: BreakpointObserver,
		private _userInfoService: UserInfoService,		
		private _shortlistService: ShortlistService,
		public _dialog: MatDialog,
		private _talentApiService: TalentApiService,
		private _router: Router,
		private route: ActivatedRoute,
		private sharedUtils: SharedUtilitiesService) {		
		this._shortlistService.backButtonShareData.subscribe((res: any) => {
			this.showDetail = res;
		});
		if (this.data != null) {			
			this.showHeaderBack = true;
			this.detail = data;
		}
	}

	ngOnInit() {
		this.user = this._userInfoService.getUser();
		this.userType = parseInt(this.user.type);
		this.breakpointObserver
			.observe([
				Breakpoints.Handset
			])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
          this.isMobileDevice = true;
				} else {
          this.isMobileDevice = false;
				}
			});

		this.breakpointObserver.observe(['(max-width: 767px)',
			'(max-width: 991px)']).subscribe(result => {
				if (result.breakpoints['(max-width: 767px)']) {
					this.breakpoint = 0;
				}
				else if (result.breakpoints['(max-width: 991px)']) {
					this.breakpoint = 1;
				}
				else {
          this.breakpoint = 2;          
        }
        if (this.breakpoint != 2) { this.toggleDetail(); }
			});

		this.route.params.subscribe(params => {
			if (params.shortlistId && !isNaN(Number(params.shortlistId))) {
				this.shortlistId = params.shortlistId;
				this.showDetailForService(this.shortlistId);
			}
		});

		this._userInfoService.checkMyPermission('Edit Shortlist').subscribe((result: any) => {
			this.hasEditShortlistPermission = result;
		  });
	}

	showDetailForService(id) {
		this.isLoading = true;
		this.detail = null;		
		this._shortlistService.getShortlistDetail(id).subscribe((result: any) => {			
			if (result.error == 2) {
				this.isLoading = false;				
        this.resultDisplay = false;//this._talentApiService.snackbarMessage('Oops! "' + result.title + '" Shortlist already deleted!');
				return false;
			}
			this.resultDisplay = true; // to show th detail back
			this.detail = result[0];			
			this.isLoading = false;
			//this.showDetail = true;
		},
			() => {
        this.isLoading = false;
        this.resultDisplay = false;
        return false;
				//console.log("This Shortlist has already deleted");				
				//this._talentApiService.snackbarMessage('Oops! This Shortlist already deleted!');
				//this._router.navigate(['shortlist']);
			});


	}

	backshortlist() {
		this.showHeaderBack = false;
		this.data = null;
		this.dialogRef.close();
	}


	toggleDetail() {
		this.showDetail = false;
		this._shortlistService.backButtonShareData.next(false);
	}

	OpenEditPopup() {
		let dialog = this._dialog.open(CreateShortlistDialogComponent, {
			height: 'auto',
			width: '100%',
			disableClose: true,
      data: this.detail,
      panelClass: this.isMobileDevice ? 'posting_popup' : ''
		});
    dialog.afterClosed().subscribe((result) => {      
			this._shortlistService.refreshShortlistData.subscribe((res: any) => {
        if (res.value) {
          this._router.navigateByUrl('shortlist').then(() => {
            this._router.navigate(['shortlist', this.detail.sid]);
          });
				}
			})
		});
	}

	changeCandidateCount(data){		
		this.totalCount = data;
	}

}
