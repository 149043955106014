
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';

@Injectable()
export class EmployerRegisterService {
	public authToken: string = 'userloggedin';
	public token: string;
	public userId;
	public userType;
	public IsLoggedIn = new EventEmitter();
	RedirectUrl: string;

	constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }

	Register(data) {
		//let data: any;
		if (typeof (data.email) !== undefined || typeof (data.pass) !== undefined) {
			this._userInfoService.setType(3);
			let userinfo = this._userInfoService.getUser();
			// data.pass = userinfo.pass;
			// data.verifiedpass = userinfo.pass;
			data.type = userinfo.type;
		}
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let user: any;

		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/createEmployer", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['status'];
					}
					else {
						console.log("could not create employer");
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
  }

  RegisterCompanyUser(data) {
    
    if (typeof (data.email) !== undefined || typeof (data.pass) !== undefined) {
      this._userInfoService.setType(3);
      let userinfo = this._userInfoService.getUser();    
      data.type = userinfo.type;
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let user: any;

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/createCompanyUser", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response) !== 'undefined') {
            return response['status'];
          }
          else {
            console.log("could not create employer");
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }




	getCompanyList() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		let data = {};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getCompanyList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['companyList'];
					}
					else {
						console.log("could not get company list");
					}

				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getSectors() {
		let headers = new HttpHeaders();
		this.userId = this._userInfoService.getUser().id;
		this.userId = this._userInfoService.getUser().type;
		//var userType = sessionStorage.getItem("userType");
		//var userId = sessionStorage.getItem("userId");
		if (this.userId == '' || this.userType == '') {
			return;
		}
		let data = {
			'userType': this.userType,
			'userId': this.userId
		};

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getSectorList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['sectorList'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getCompaniesInfo() {
		let headers = new HttpHeaders();
		this.userId = this._userInfoService.getUser().id;
		this.userId = this._userInfoService.getUser().type;	
		if (this.userId == '' || this.userType == '') {
			return;
		}
		let data = {};

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getCompaniesInfo", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['companiesInfo'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	getCompanySizeList(){
	  let data = { };
	  let headers = new HttpHeaders();
	  headers.append('Content-Type', 'application/x-www-form-urlencoded');
	  return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getCompanySizeList", data, { headers: headers }).pipe(
		  map(response => {
			  {
				  if (typeof (response !== 'undefined')) {
					  return response['companySizeList'];
				  }
				  else {
					  console.log('Couldnt get company size');
				  }
			  };
		  }),
		  catchError(error => observableThrowError(
			  { status: error.status }
		  )),);
}
}
