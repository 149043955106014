import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import { UserInfoService } from './user-info.service';
import {throwError as observableThrowError,  Observable, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private _http: HttpClient) {
  }
  
  getReport()
	{
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/myPostsReport", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['myPosts'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);

  }

  getPostingsArchiveReport() {
    let data = {};
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "reporting/getOpportunityHistoryReport", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response) !== 'undefined') {
            return response['opportunityHistoryReport'];
          }
          return response;
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));

  }
  getCandidateAdvanced() {
    let data = {};
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "reporting/getCandidateAdvancedReport", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response) !== 'undefined') {
            return response['candidateAdvancedReport'];
          }
          return response;
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));

  }

  getContentReportReasons(reason) {
    let data = { reasonId: reason };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "content-reporting/getReasons", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['reasons'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
  }

  createContentReport(reason, opportunityId, candidateId) {
    let data = { reasonId: reason, opportunityId: opportunityId, candidateId: candidateId };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "content-reporting/createNewReport", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['reasons'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
  }
}


