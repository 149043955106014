import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { UserInfoService } from '../../Services/user-info.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	myEventSubscription:any;
	public userType = this._userInfoService.getUser().type;

	constructor(private _loginService: LoginService, private _userInfoService: UserInfoService ) { }

	ngOnInit() {
		
	}

	ngOnDestroy() {
		if (!!this.myEventSubscription) this.myEventSubscription.unsubscribe();
	}
}
