import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportService } from '../../Services/report.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  public reportData: [];
  public displayedColumns: string[];
  reportId: any;
  reportList: any[];
  filterData: any;
  selectedReport: any;

  constructor(
    private _reportService: ReportService,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    this.reportData = null;
    this.getReportList();
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.reportId) {
        this.reportId = params.reportId;
        if (this.reportId == "TotalApplicationReceived") {
          this.getReport(this.reportId);
        }
        else if (this.reportId == "PostingsArchiveReport") {
          this.getPostingsArchiveReport(this.reportId);
        }
        else if (this.reportId == "Advanced") {
          this.getCandidateAdvanced(this.reportId);
        }
        else {
          this.reportData = [];
        }
      } else {
        this._router.navigate(['reports', this.reportList[0].reportId]);//Redirect to first report
      }
      if (this.reportId != '') {
        this.selectedReport = this.reportList.filter(x => x.reportId == this.reportId)[0];
      }
      else {
        this.selectedReport = this.reportList[0];
      }
    });
  }
  getReportList() {
    this.reportList = [{ 'reportId': 'TotalApplicationReceived', 'name': 'Total Application Received' },
    { 'reportId': 'PostingsArchiveReport', 'name': 'Postings Archive Report' },
    { 'reportId': 'Advanced', 'name': 'Candidate Advanced' }];
  }

  getCandidateAdvanced(repId) {
    this._reportService.getCandidateAdvanced().subscribe((result: any) => {
      if (result) {
        const report = result.map(obj => ({
          'Posting ': obj.PostingName,
          'Candidate Name': obj.Candidate,
          'Status': obj.Status,
          'Applied Date': new Date(Date.parse(obj.created_ts)),
          'Last Updated': new Date(Date.parse(obj.last_updated_ts))
        }));
        this.reportData = report;
        this.displayedColumns = Object.keys(report[0]);
      } else {
        console.log('User is not initialized');
      }
    });
  }

  getPostingsArchiveReport(repId) {
    this._reportService.getPostingsArchiveReport().subscribe((result: any) => {
      if (result) {
        const report = result.map(obj => ({
          'ID': obj.fk_opportunity,
          'Name': obj.title,
          'Start Date': new Date(Date.parse(obj.postingdate)),
          'End Date': obj.enddate ? new Date(Date.parse(obj.enddate)) : 'No Expiration',
          'Total Position': obj.quantity,
          'Pay Low': obj.compensationlow,
          'Pay High': obj.compensationhigh,
          'Skills': obj.Skills,
          'Location': obj.Locations,
          'Work-Setup': obj.work_setup,
          'Type': obj.opportunity_type,
          'Last Updated': formatDate(Date.parse(obj.last_updated_ts), 'yyyy-MM-dd hh:mm:ss', 'en_US')//, 'Total Applicants': obj.TotalCandidates
        }));
        this.reportData = report;
        this.displayedColumns = Object.keys(report[0]);
      } else {
        console.log('User is not initialized');
      }
    });
  }

  getReport(repId) {

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

    this._reportService.getReport().subscribe((result: any) => {
      if (result) {
        const report = result.map(obj => ({
          'Posting ID': obj.id,
          'Posting Start Date': new Date(Date.parse(obj.postingdate)),
          'Posting End Date': obj.enddate ? new Date(Date.parse(obj.enddate)) : 'No Expiration',
          'Posting Name': obj.title,
          'Total Applicants': obj.TotalCandidates
        }));
        this.reportData = report;
        this.displayedColumns = Object.keys(report[0]);
      } else {
        console.log('User is not initialized');
      }
    });
  }

  cancelClickUpdateReport() {
    if (this.reportId) {
      this.getReport(this.reportId);
    }
    else {
      this.getReport(this.reportList[0].reportId);
    }
  }

  searchReportData(data) {
    this.filterData = data.filteredData;
  }
}
