import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.css']
})
export class ConfirmMessageComponent implements OnInit {
  public confirm:boolean = false;

  public confirmTitle:string = "Confirm this action";
  public confirmMessage:string = "Are you sure you want to submit?"

  constructor(public _dialogRef: MatDialogRef<ConfirmMessageComponent>) { }

  ngOnInit(): void {
  }

  confirmButton() {
    this.confirm = true;
  }

  closeDialog() {
    this._dialogRef.close();
  }

}
