
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class ProfileService {

	public userDetails: any;
	constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }

	getMyProfile(candidateId, userId) {
		let data = { 'id': candidateId,'user-id': userId };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateProfile", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['candidateProfile'];
					}
					else {
						console.log('Couldnt get student profile data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getClearbitCompanyinfo(companyName) {
		let name = companyName;
		return this._http.get('//autocomplete.clearbit.com/v1/companies/suggest?query=:' + name).pipe(
		map(response => {
			{
				if (typeof (response !== 'undefined')) {
					//console.log(response);
					return response;
				}
				else {
					console.log('Couldnt get clearbit company info');
				}
			};
		}),
		catchError(error => observableThrowError(error)),);
	}

	// change password from within site
	changePassword(user) {
		let old_pass = CryptoJS.SHA256(user.passwordOld).toString();    
		let pw = CryptoJS.SHA256(user.passwordNew).toString();    
		let data = { 'email': user.email, 'old_password':old_pass,'pw': pw };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/editAccount", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						if(response['statusType'] == 'success') {
							return response['status'];
						} else {
							return response['msg'];
						}
					}
					else {
						console.log('Could not update account');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getCandidateOpportunityStatus(candidateId, userId, opportunityId) {		
		let data = { 'id': candidateId,'user-id': userId, 'opportunity-id' : opportunityId  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateOpportunityStatus", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['candidateOpportunityStatus'];
					}
					else {
						console.log('Couldnt get student profile data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}



}
