<div fxLayout="row">
    <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
        <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
    </div>
    <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
        <h1 class="title title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Where are you in your job search process?</h1>
        <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
    </div>
</div>
<form name="candidateAvalForm" id="candidateAvalForm" [formGroup]="form" autocomplete="off">
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex="95" fxFlexOffset="5">
            <div fxLayout="row">
                    <mat-radio-group
                    fxLayout="column"
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group" formControlName="availabilityStatus">
                        <mat-radio-button [fxFlexOffset]="i>0 ? '20px' : ''" fxLayoutAlign="center center" (change)="statusChange($event)" *ngFor="let status of candidateAvailabilityStatus; let i = index" [value]="status" class="example-radio-button" [checked]="availabilityStatus && availabilityStatus.name == status.name">
                            {{status.name}}
                        </mat-radio-button>
                    </mat-radio-group>
            </div>
            <div fxLayout="row" fxFlexOffset="20px">
                <div fxLayout="column" fxLayoutAlign="start">
                    <button [disabled]="!form.valid" mat-raised-button color="primary" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                </div>
            </div>        
        </div>
    </div>
</form>
