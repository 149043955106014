<div fxLayout="row" fxFlex="100" fxLayoutAlign="center stretch" class="mat-typography padded">
	<div [ngClass.gt-md]="'max-dashboard'" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" fxFlex.lt-lg="100%" fxFlex.lt-sm="grow">
		<app-profile-metrics *ngIf="userType=='2'" fxFlex fxLayout="row"></app-profile-metrics>
		<app-employer-overview *ngIf="userType=='3'" fxFlex fxLayout="row"></app-employer-overview>	
		<div fxLayout="row" fxLayout.lt-sm="column-reverse" fxLayoutAlign="start stretch" fxLayoutGap="20px">
			<app-graphing-card fxFlex.gt-xs="calc(50% - 20px)" fxLayout="row" fxLayoutAlign="start stretch"></app-graphing-card>
			<div fxLayout="column" fxLayoutGap="20px" fxFlex.gt-xs="calc(50% - 20px)" fxLayoutAlign="start stretch">
				<app-notifications></app-notifications>
				<app-news-feed></app-news-feed>
			</div>
		</div>	
	</div>
</div>
