<div fxLayout="row wrap" fxFlex="100">
    <h1 mat-dialog-title fxFlex="75">
        Invite to Apply
    </h1>
    <div fxFlex="25" fxLayoutAlign="end start">
        <button mat-icon-button fxFlexOffset="10px" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <hr class="margin-24" fxFlex="100">
    <div fxLayout="row" fxFlex="100" *ngIf="isLoading" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <mat-dialog-content *ngIf="!isLoading" fxFlex="100" class="mat-typography scroll-content invite-to-opp-content" [formGroup]="form">
        <div fxLayout="column" fxFlex="100">
            <h4>Select Postings to Invite this Candidate to Apply</h4>
            <div fxLayout="column">
                <mat-selection-list formControlName="selectedOpportunities">
                    <mat-list-option [value]="opp.id" *ngFor="let opp of opportunities" [disabled]="opp.OpportunityCandidateId">
                        {{opp.title}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <mat-error *ngIf="form.get('selectedOpportunities').hasError('required') && isNextClicked" class="help-block small animated fadeInDown">
            No opportunities selected yet!
            </mat-error>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!isLoading" fxLayout="row reverse" fxFlex="100" fxLayoutAlign="end" class="mat-padding">            
        <div  fxLayoutAlign="space-between">
                <button mat-stroked-button (click)="close()">close</button>
                <button mat-raised-button color="primary" fxFlexOffset="10px" [disabled]="isSubmitting" (click)="submit()">Invite</button>
        </div>  
    </mat-dialog-actions>
</div>