import { Component, OnInit } from '@angular/core';
import { TalentApiService } from '../../../Services/talent-api.service';
import { UserInfoService } from '../../../Services/user-info.service';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'app-feedback-request',
	templateUrl: './feedback-request.component.html',
	styleUrls: ['./feedback-request.component.css']
})
export class FeedbackRequestComponent implements OnInit {

	public feedbackRequestFor: any;
	public student: any;
	//public studentId: any;
	//public job: any;
	//above two lines replaced by following lines
	public feedbackId: any;

	public sfbTo = '';
	public valid: boolean = false;

	public sfbfeedbackEmailTemplate: any;
	//public sfbmessage: any;
	//public sfbname: any;
	//public sfbto: any;
	//public sfbsubject: any;
	
	
	public createError: boolean = false;
	public submitting: boolean = false;
	public signature: any;
	public mailings: any;
	public emailStatus: any;
	public classList:any;
	public selectedClass: any;
	public classInformation: any;
	public subject: any;
	public message: any;

	//spinner
	color = 'primary';
	mode = 'indeterminate';
	value = 50;


	constructor(private _talentApiService: TalentApiService, private _userInfoService: UserInfoService, public _dialogRef: MatDialogRef<FeedbackRequestComponent>, private _router: Router) { }

	ngOnInit() {
		if(this.feedbackRequestFor === 0){
			this.populateIndividual();
		}
		else if(this.feedbackRequestFor === 1){
			this.populateMultiple();
		}
	}

	submitFeedbackRequest() {
	}

	populateIndividual() {
		//this._talentApiService.getFeedbackEmailTemplate(this.studentId, this.job, this._userInfoService.getUser().name)
		this._talentApiService.getFeedbackEmailTemplateByFeedback(this.feedbackId)
			.subscribe(
				(result:any) => {
					this.sfbfeedbackEmailTemplate = result;
					//this.sfbmessage = this.sfbfeedbackEmailTemplate.message;
					//this.sfbname = this.sfbfeedbackEmailTemplate.name;
					//this.sfbto = this.sfbfeedbackEmailTemplate.to;
					//this.sfbsubject = this.sfbfeedbackEmailTemplate.subject;
				},
				error => {
					console.log('error getting Feedback Email Template' + error);
				}
			);
	}
	populateMultiple(){
		this.getClassList();
	}
	getClassList() {
		this._talentApiService.getMyClassList(0)
		.subscribe(
			(result:any) => {
				this.classList = result;
		   		this.selectedClass = '';
			},
			error => {
				console.log("Error Loading the getMyclassList");
			}
		);
   };

	sendEmailIndividual() {
		this.createError = false;
		this.submitting = true;
		/*this.signature = '';
		this.mailings = [{
			"id": this.studentId,
			"opportunity_id": this.job,
			"to": this.sfbto
		}];*/

		//this._talentApiService.sendFeedback(this.sfbsubject, this.sfbmessage, this.mailings, this.signature)
		this._talentApiService.sendNewFeedback(this.feedbackId, this.sfbfeedbackEmailTemplate)
		.subscribe(
			(result:any) => {
				this.emailStatus = 'send';
				this.submitting = false;
				this._dialogRef.close();
				this._talentApiService.snackbarMessage('Email sent successfully!');
                this._router.navigate(['feedback']);
                // $state.transitionTo($state.current, $stateParams, { reload: true, inherit: false, notify: true });
			},
			error => {
				console.log("Couldn't send Email"+ error);
				this.submitting = false;
				this.createError = true;
			}
		);
	}

	sendEmailMultiple(){
		this.createError = false;
		this.submitting = true;
		this._talentApiService.getMyClasses(0, 2)
		.subscribe(
			(result:any) => {
				let that = this;
				this.classInformation = result;
				this.subject = '';
				this.message = '';
				this.mailings =[];
				this.classInformation.forEach(function (item, index, array){
					that.mailings.push({"id":item.id,"opportunity-id":item.opportunity_id});
				});
				this._talentApiService.sendFeedback(this.subject, this.message, this.mailings, this.signature)
				.subscribe(
					(result:any) => {
						this.submitting = true;
						//close dialog
						this.closeDialog();
						this._talentApiService.snackbarMessage('Email sent successfully!');
						// this.sending = false;
						//$state.transitionTo($state.current, $stateParams, { reload: true, inherit: false, notify: true });
					},
					error => {
						this._talentApiService.snackbarMessage('Couldn\'t send Email.');
						this.submitting = false;
						this.createError = true;
						//this.sending = false;
					}
				);
			},
			error => {
				this._talentApiService.snackbarMessage('Can\'t Send Feedback due to inproper class information.');
			}
		);
	}

	closeDialog(){
		this._dialogRef.close();
	}
}
