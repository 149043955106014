import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-common-confirm-popup',
  templateUrl: './common-confirm-popup.component.html',
  styleUrls: ['./common-confirm-popup.component.css']
})
export class CommonConfirmPopupComponent implements OnInit {


  public confirmDiscardPost: boolean = false;
  public discardConfirm: boolean = false;
  public submitPostingConfirm: boolean = false;
  public confirmSubmitPost: boolean = false;
  public confirm_message;
  public okText: any = 'Yes';
  public cancelText: any = 'No';
  public dialogTitle: any = 'Confirm Action';


  constructor(public _dialogRef: MatDialogRef<CommonConfirmPopupComponent>) { }

  ngOnInit(): void {
  }
  closeDialog() {
    this._dialogRef.close();
  }

  confimrDiscard() {
    this.confirmDiscardPost = true;
    this._dialogRef.close();
  }
  //confimrSubmitPost() {
  //  this.confirmSubmitPost = true;
  //}
}
