<form name="classSelector" id="classSelector" #postdiscardconfirm="ngForm" novalidate ng-cloak>
    <h1 mat-dialog-title> {{confirmTitle}} </h1>
    <mat-dialog-content>
            <p>{{confirmMessage}}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-raised-button class="save-bt" (click)="closeDialog()">Cancel</button>
        <button mat-button mat-raised-button color="warn" class="md-raised Cancel-btt" (click)="confirmButton()">Yes</button>
    </mat-dialog-actions>
</form>
