<div fxLayout="row" fxLayoutAlign="space-between start" style="padding: 15px;">
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Search by keyword</mat-label>
    <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchText">
    <button matPrefix mat-icon-button><mat-icon>search</mat-icon></button>
    <button mat-icon-button *ngIf="searchText != '' && searchText != null"
            matSuffix aria-label="Clear"
            (click)="clearSearchText()">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-form-field>
</div>
<mat-divider *ngIf="hasRecords==true"></mat-divider>
<div class="table-content" *ngIf="hasRecords==true">
  <mat-card-content class="table-container" fxLayout="row">
    <table mat-table fxFlex="100%" [dataSource]="reportData" matSort>
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="isDate(element[column])">
            {{element[column] | date:'longDate'}}
          </ng-container>
          <ng-container *ngIf="!isDate(element[column])">
            {{element[column]}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
  </mat-card-content>
</div>
<div *ngIf="reportdetail==null && hasRecords==true" fxLayout="row" fxLayoutAlign="center center">
  <mat-spinner></mat-spinner>
</div>
<div fxLayoutAlign="center center" fxLayout="row" *ngIf="hasRecords==false">
  <h2>No Record(s)!</h2>
</div>
