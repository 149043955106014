<div fxLayout="row" fxLayoutAlign="space-between start">
    <h3>Skills</h3>
    <mat-icon *ngIf="ownProfile" class="edit" (click)="openEdit()">edit</mat-icon>
</div>

<mat-chip-list>
    <mat-chip *ngFor="let skill of skills" class="skill-chip">
        <p class="skill-text"> {{ skill.name }} </p>
        <p class="years-text"> {{ skill.expYears }} </p>
    </mat-chip>
</mat-chip-list>
