import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginService } from '../../Services/login.service';

import { UserInfoService } from '../../Services/user-info.service';
import { TimesheetService } from '../../Services/timesheet.service';
import { Subject } from 'rxjs';

import { TimesheetActionComponent } from '../../Pages/dialogs/timesheet-action/timesheet-action.component';

import { MatDialog } from '@angular/material/dialog';
import { TalentApiService } from '../../Services/talent-api.service';
import { ActivatedRoute,Router } from '@angular/router';






@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {
  public userType:any=0;
  public opportunityTimesheetList:any;
  actionType='';
  timesheetId='';

  public opportunitiesListType:any = [
    {
      display : "All",
      value : "all"
    },
    {
      display : "Open",
      value : "open"
    },
    {
      display : "Closed",
      value : "close"
    }
  ];
  public opportunitiesList:any;
  public view:any={selopportunityType: "all",selopportunity:'',selstudent:''};
  public studentList:any;
  public currdate = new Date();

  dtOptions: DataTables.Settings = {};
  dtOptionsEmp: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();
  dtTriggerEmp: Subject<any> = new Subject();
  getParam :any;
  private sub: any;
  myEventSubscription:any;

  disableStatus=false;

  constructor(private route: ActivatedRoute,private _http: HttpClient,private _talentApiService:TalentApiService, private _loginService: LoginService, private _userInfoService: UserInfoService,private _timesheetService: TimesheetService,public _dialog: MatDialog ,private _router: Router) { 
    
	}


  ngOnInit() {

  // use when add or edit and call datatable after it
    this._timesheetService.timesheetData.subscribe(
      (data: any) => {
          if(data=='employee_action'){

            if (this.getParam && this.getParam.timeentryid) {
              this._router.navigate(['/timesheet']);
              return false;
            }else{
              this.getOpportunityTimesheetList();
              this.getOpportunityList(this.view.selopportunityType);
            }

           
          }else{
            this.getStudentTimesheets();
          }
          
      });

      this.userType = parseInt(this._userInfoService.getUser().type);
      this.sub = this.route.params.subscribe(params => {
        this.getParam = params;
        if(params.timeentryid && params.timeentryid!==''){
          
          var timeEntryString = params.timeentryid.split('_');
          this.showTimeEntry(timeEntryString[0], timeEntryString[1]);
        }
      });
      if(this.getParam && this.getParam.timeentryid){
        
      }else{
          if(this.userType === 2) {
            this.dtOptions = {
              pagingType: 'full_numbers',
              pageLength: 10,
              scrollX:false,
    info:true,
    order: [],
    columnDefs: [
      {
        "targets": [ 3 ],
        "searchable": false,
        "orderable": false
      },
      {
        "targets": [ 4 ],
        "searchable": false,
        "orderable": false
      }
    ]
            };
            this.getStudentTimesheets();
          }else if(this.userType === 3){
            this.dtOptionsEmp = {
              pagingType: 'full_numbers',
              pageLength: 10,
              scrollX:false,
    info:true,
    language: {
      searchPlaceholder: 'Search Timesheet'
    },
    columnDefs: [
      {
        "targets": [ 5 ],
        // "searchable": false,
        "orderable": false
      },
      {
        "targets": [ 6 ],
        "searchable": false,
        "orderable": false
      },
      {
        "targets": [ 3 ],
      }
    ]
            };
            this.getOpportunityTimesheetList();
            this.getOpportunityList(this.view.selopportunityType);
          }
      }
  }

  ngOnDestroy() {
    if (!!this.myEventSubscription) this.myEventSubscription.unsubscribe();
  }

  showTimeEntry(opId, stdId){
    this._timesheetService.getTimesheet(opId, stdId, this.view.selopportunityType)
    .subscribe(
      (result:any) => {
          this.opportunityTimesheetList = result;
          if(this.userType === 3){
            this.dtTriggerEmp.next({});
              this.getOpportunityList(this.view.selopportunityType);
          }else{
            this.dtTrigger.next({});
          }
      },
      error => {
          console.log('Could not load getCompaniesInfo');
      }
    );
  }
  // get student timesheet on load
  // and after edit/delete/add timesheet
  getStudentTimesheets(){
	  let tempTimeSheet;
    if (this.getParam && this.getParam.timeentryid) {
      this._router.navigate(['/timesheet']);
      //return false;
    }else{
      $('#studentdatatables').DataTable().destroy();
      this._timesheetService.getStudentTimesheets()
        .subscribe(
          (result:any) => {
				tempTimeSheet = result;
				tempTimeSheet.forEach(function(ele) {
					ele = Object.assign(ele, {datetime: new Date(ele.date).getTime()})
					//ele.append({datetime: Math.round(new Date(ele.date).getTime()/1000)});
				});
              //this.opportunityTimesheetList = result;
              this.opportunityTimesheetList = tempTimeSheet;
            this.dtTrigger.next({});
          },
          error => {
              console.log('Could not load StudentTimesheets');
          }
        );
     }
      
  }

 
  // open add popup
  addNewModal(){
    let dialog = this._dialog.open(TimesheetActionComponent,{
		  height: 'auto',
     // width: '50%',
      panelClass:"posting_popup"
		});
    dialog.componentInstance.actionType = 'add';
    this._timesheetService.getStudentOpportunities()
    .subscribe(
      (result:any) => {
        let adding:any={date:'',time_entered:'',selopportunity:''};    
        console.log('ss');
                  
        dialog.componentInstance.opportunityList = result;
        adding.date = this.currdate;      
        
      },
      error => {
          console.log('Could not Student Opportunities');
      }
    );
   

		dialog.afterClosed()
			.subscribe((result) => {
			});

	
  }

  // open delete popup
  delete(tid){
    let dialog = this._dialog.open(TimesheetActionComponent,{
		  height: 'auto',
      //width: '50%',
      panelClass:"posting_popup"
		});
    dialog.componentInstance.actionType = 'delete';
    let deleting = Object.assign({}, this.opportunityTimesheetList[tid]);
    dialog.componentInstance.deletingData = deleting;

    dialog.afterClosed()
     .subscribe((result) => {
    });
  }

  // open edit popup 
  edit(tid){
    let dialog = this._dialog.open(TimesheetActionComponent,{
		  height: 'auto',
      panelClass:"posting_popup"
		});
    dialog.componentInstance.actionType = 'edit';
    let editing = Object.assign({}, this.opportunityTimesheetList[tid]);
    editing.date = new Date(editing.date);
    dialog.componentInstance.editing = editing;

    dialog.afterClosed()
     .subscribe((result) => {
    });
  }
//  ====================== End user type==3 =======================
//  ======================== Start user type == 5 ==================

// fetch when load page
// get all timehseet data
getOpportunityTimesheetList(){
	let tempTimeSheet;
  $('#supdatatables').DataTable().destroy();
  this._timesheetService.getTimesheet(null,null,this.view.selopportunityType)
  .subscribe(
    (result:any) => {
        //this.opportunityTimesheetList = result;
		tempTimeSheet = result;
		tempTimeSheet.forEach(function(ele) {
			ele = Object.assign(ele, {datetime: new Date(ele.date).getTime()})
			//ele.append({datetime: Math.round(new Date(ele.date).getTime()/1000)});
		});
		this.opportunityTimesheetList = tempTimeSheet;
      this.dtTriggerEmp.next({});
    },
    error => {
        console.log('Could not load getCompaniesInfo');
    }
  );
}

// fetch when load page
// get all posting in dropdown
getOpportunityList(postingType){
  console.log('postingType: ', postingType);
  this.disableStatus = false;
  this.view.selopportunity = '';
  this.view.selstudent = '';
  this.studentList = [];
  this._timesheetService.getOpportunityList(postingType)
  .subscribe(
    (result:any) => {
        this.opportunitiesList = result;
    },
    error => {
        this.opportunitiesList = [];
        console.log('Could not load getCompaniesInfo');
    }
  );
}

// get student according to selection of posting
opportunityStudentList(oppId){
  this.disableStatus = false;
  this.view.selstudent = '';
  this._timesheetService.getOpportunityStudentList(oppId)
  .subscribe(
    (result:any) => {
        this.studentList = result;
        
    },
    error => {
      console.log('Student list is not available, please check your parameters.');
    }
  );
}

changeStudent(){
  this.disableStatus = false;
}

// apply filter- search timesheet
searchStudentOpportunityTimesheet(opId, stdId,cancetButton=0){
  if(cancetButton==1){
    this.disableStatus = true;
    this.studentList = null;
    this.view.selopportunityType = "all";
  }
  if (opId === '' && stdId === '' && this.getParam && this.getParam.timeentryid) {
    $('#supdatatables').DataTable().destroy();
    this._router.navigate(['/timesheet']);
    return false;
  }
 
  
  $('#supdatatables').DataTable().destroy();
  this._timesheetService.getTimesheet(opId, stdId, this.view.selopportunityType)
  .subscribe(
    (result:any) => {
      
        this.opportunityTimesheetList = result;
      this.dtTriggerEmp.next({});
    },
    error => {
      console.log('Student opportunity timesheet is not available, please check your parameters.');
    }
  );
}

confirmUpdateStatus(tid,status){
  let dialog = this._dialog.open(TimesheetActionComponent,{
    height: 'auto',
    //width: '50%',
    panelClass:"posting_popup"
  });
  dialog.componentInstance.actionType = 'updateEmp';
  let statusupdate = Object.assign({}, this.opportunityTimesheetList[tid]);
  statusupdate.status = status;
  dialog.componentInstance.statusupdate = statusupdate;

  dialog.afterClosed()
   .subscribe((result) => {
  });
}

deleteSupervisor(tid){
  let dialog = this._dialog.open(TimesheetActionComponent,{
    height: 'auto',
    //width: '50%',
    panelClass:"posting_popup"
  });
  dialog.componentInstance.actionType = 'deleteEmp';
  let deletingData = Object.assign({}, this.opportunityTimesheetList[tid]);
  dialog.componentInstance.deletingData = deletingData;

  dialog.afterClosed()
   .subscribe((result) => {
  });
}

}
