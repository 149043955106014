import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../../Services/admin-api.service';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
  selector: 'app-config-edit',
  templateUrl: './config-edit.component.html',
  styleUrls: ['./config-edit.component.css']
})
export class ConfigEditComponent implements OnInit {
  editing:any;
  public isReloadData: boolean = false;

  constructor(private dialogRef:MatDialogRef<ConfigEditComponent>,private _adminApiService:AdminApiService, private _talentApiService: TalentApiService) { }

  ngOnInit() {
  }

  closeDialog(){
    this.dialogRef.close();
  }
  
  modalAddNew(){
      if (this.editing && this.editing.name && this.editing.value) {

        this._adminApiService.createConfigVariable(this.editing)
          .subscribe(
            (result:any) => {
              this._talentApiService.snackbarMessage("Variable '" + this.editing.name + "' has been created.");
              this.isReloadData=true;
              this.dialogRef.close();

            },
            error => {
              this._talentApiService.snackbarMessage("Something Went Wrong. Please Try Again!");
            }
          );
        } else {
           this._talentApiService.snackbarMessage("Invalid config variable. Please check your parameters and try again.");
        }
  }

  modalDelete(){
    if (confirm("Are you sure you want to want to delete this config variable?")) {
      this._adminApiService.deleteConfigVariable(this.editing.id)
      .subscribe(
        (result:any) => {
          this._talentApiService.snackbarMessage("Variable '" + this.editing.name + "' has been deleted.");
          this.isReloadData=true;
          this.dialogRef.close();
        },
        error => {
          this._talentApiService.snackbarMessage("Something Went Wrong. Please Try Again!");
        }
      );
    }
  }

  modalSave(){
    if (this.editing && this.editing.id && this.editing.name && this.editing.value) {

      this._adminApiService.editConfigVariable(this.editing)
        .subscribe(
          (result:any) => {
            this._talentApiService.snackbarMessage("Variable '" + this.editing.name + "' has been saved.");
            this.isReloadData=true;
            this.dialogRef.close();

          },
          error => {
            this._talentApiService.snackbarMessage("Something Went Wrong. Please Try Again!");
          }
        );
      } else {
        this._talentApiService.snackbarMessage("Invalid config variable. Please check your parameters and try again.");
      }
  }

}
