import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { UserInfoService } from '../../Services/user-info.service';
import * as _ from 'underscore';
import { ShortlistService } from '../../Services/shortlist.service';


@Component({
  selector: 'app-shortlist',
  templateUrl: './shortlist.component.html',
  styleUrls: ['./shortlist.component.css'],
})
export class ShortlistComponent implements OnInit {
  detail: any;
  user: any;
  cardWidth: any;
  resultDisplay: boolean = false;
  userType: number;

  public shortlistListLoaded: EventEmitter<any> = new EventEmitter<any>();


  constructor(private _userInfoService: UserInfoService,
    public breakpointObserver: BreakpointObserver,
    private _router: Router, private route: ActivatedRoute,
    private _shortlistService: ShortlistService
  ) {
  }

  ngOnInit() {
    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);
    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.cardWidth = true;
        } else {
          this.cardWidth = false;
        }
      });

    this.resultDisplay = true;

    this._shortlistService.getShortlistList(this.user.id).subscribe((result: any) => {      
      if (result.length) {
        this.shortlistListLoaded.emit(result); // <- signals the shortlist component to format results
        if (this.route.firstChild == null) {
          this._router.navigate(['shortlist', result[0].sid]);
        }
      }
      else {
        this._router.navigate(['shortlist', 0]);
      }

    });
  }

  searchedResult(searchData) {
    let parameter = searchData.searchParams;
    if (!searchData.data || searchData.data.length == 0) {
      this.resultDisplay = false;
      this._router.navigate(['shortlist', 0], { queryParams: parameter });
    }
    else {
      this.resultDisplay = true;
      this._router.navigate(['shortlist', searchData.data[0].sid], { queryParams: parameter });
    }
  }
}
