<mat-card fxLayout="column" fxFlexAlign="start" fxLayoutAlign="start center "
          [ngClass.lg]="'opportunity-list-lg'" [ngClass.xl]="'opportunity-list-xl'"
          [ngClass.md]="'opportunity-list-md'" [ngClass.lt-md]="'opportunity-list-lt-md'"
          class="opportunity-list bg-white" fxShow.sm="{{!showDetail}}" fxShow.xs="{{!showDetail}}" fxFlex="100">
  <div class="head-box" fxLayout="row wrap" cdkScrollable>
    <mat-card fxLayout="row wrap" fxLayoutAlign="space-between center" class="search-box no-brdr" fxLayoutGap.gt-md="10px" fxFlex="100">
      <mat-form-field class="margin-field"
                      [ngClass]="{'posting-search-text':userType == 2 && !screenBreakPoint}"
                      color="accent"
                      [ngClass.sm]="{'posting-search-text':userType == 2}"
                      appearance="outline" fxFlex.gt-md="{{searchBoxSize}}"
                      fxFlex.md="{{mdSearchBoxSize}}"
                      fxFlex.sm="72"
                      fxFlex.xs="100">
        <input matInput aria-label="search"
               [(ngModel)]="searchText"
               (ngModelChange)="this.userEnteredSearchText.next($event)"
               name="searchText"
               #searchPosting="ngModel"
               placeholder="Search by Name, Location, or Skills">
        <button mat-button type="button" *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
          <mat-icon>cancel</mat-icon>
        </button>
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
      <button *ngIf="userType == 2" class="margin-bottom-1" [ngClass.sm]="'btn_savedSearch'" [ngClass]="{'btn_savedSearch':!screenBreakPoint}" fxFlex.gt-md="38%" fxFlex.md="50%" fxFlex.sm="28%" fxFlex.xs="100" fxFlexAlign="end" mat-stroked-button [matMenuTriggerFor]="savedSearches" color="primary">
        SAVED SEARCHES <i class="fa fa-angle-down"></i>
      </button>
      <mat-menu #savedSearches="matMenu" class="savedSearchMenuPosting" xPosition="before" yPosition="below">
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row wrap" fxFlex="100">
            <h5 fxFlexOffset="15px">Saved Searches</h5>
            <div fxFlex="100" *ngIf="!saveSearchList || saveSearchList.length == 0" fxLayoutAlign="space-between center">
              <mat-label mat-menu-item fxFlex="100">No searches saved yet!</mat-label>
            </div>
            <div class="border-bottom" fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="100" *ngFor="let search of saveSearchList;last as last">
              <mat-label mat-menu-item fxFlex="90" fxFlex.lt-md="80" (click)="applySearch(search)">{{search.name}}</mat-label>
              <button mat-icon-button fxFlex="8" fxFlex.lt-md="16" (click)="editSearch(search)" class="margin-top-2 cursor-pointer"><mat-icon>edit</mat-icon></button>
            </div>
          </div>
        </div>
      </mat-menu>
     <button mat-raised-button color="primary"  fxFlex.gt-md="30%" fxFlex.md="30%" fxFlex.sm="27%" fxFlex.xs="100" *ngIf="(userType==3 || userType==4) && this.hasCreatePostingPermission" (click)="openPostingModal('employer')">New Posting</button>
    </mat-card>
    <mat-card fxLayout="row wrap" fxLayoutAlign="space-between center" class="top-padding-0 no-brdr" fxFlex="100" fxLayoutGap="10px">
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
        <mat-form-field *ngIf="this.userType != 2">
          <mat-select [(value)]="postingFilterType" name="postingStatusList" (selectionChange)="changeFlter($event.value)" class="filter-select">
            <mat-option value="ACTIVE">ACTIVE POSTINGS</mat-option>
            <mat-option value="DRAFT">DRAFT POSTINGS</mat-option>
            <mat-option value="FILLED">FILLED POSTINGS</mat-option>
            <mat-option value="CLOSED">CLOSED POSTINGS</mat-option>
            <mat-option value="INACTIVE">INACTIVE POSTINGS</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" *ngIf="userType != 3" fxFlex="40" fxFlex.xs="50" fxlayoutalign="end center" (click)="loadMatchingPost()">{{screenBreakPoint? "MY MATCH":"FIND MY MATCH"}}</button>
        <button mat-stroked-button color="primary" *ngIf="userType != 3" fxFlex="30" fxFlex.xs="50" (click)="openFilterDialog()">FILTER</button>
        <button fxFlex="30" fxFlexAlign="end" mat-stroked-button fxFlex.xs="50" [matMenuTriggerFor]="menu" color="primary">
          <i class="fa fa-1x fa-sort"></i> {{ sortShowText}}
        </button>
        <mat-menu #menu="matMenu" width="2" id="mnSort">
          <button mat-menu-item id="btnSortByName" (click)="setSort('title')">Name </button>
          <button mat-menu-item id="btnSortByDate" (click)="setSort('postingdate')">Date</button>
        </mat-menu>
      </div>
    </mat-card>
  </div>
  <div class="opportunity_list_scroll top-padding-10" (scroll)="onPostingScroll($event)"
       [ngClass.xl]="'opportunity_list_scroll-xl'"
       [ngClass.lg]="'opportunity_list_scroll-lg'" id="dvPostingContainer"
       [ngClass.md]="'opportunity_list_scroll-md'" #dvPostingContainer
       fxLayout="column" fxFlex="100" fxFlexFill cdkScrollable>
    <div *ngIf="((filteredOpportunity).length === 0)">
      <h2 fxLayoutAlign="center center">No Postings Match Criteria!</h2>
    </div>
    <mat-nav-list fxLayout="row wrap">
      <mat-list-item [routerLink]="['/postings', n.id]" [queryParams]="parameter"
                     [ngClass]="{'active':(n.id==selectedId) }"
                     (click)="toggleDetail()"
                     class="card-caption fit-to-content"
                     *ngFor="let n of filteredOpportunity;let i=index">
        <div fxLayout="row" fxFill fxLayoutAlign="space-evenly center">
          <div fxFlex="23" dir="rtl" style="max-height:100px !important; max-width:100px !important;">
            <app-company-logo fxFlexAlign="baseline" [name]="n.company" [type]="'company'"></app-company-logo>
          </div>
          <div fxFlex="72">
            <div mat-line class="margin-bottom-6 margin-top-2"><h3>{{n.title}}</h3></div>
            <div mat-line class="margin-bottom-6 "  *ngIf="userType == 2"><span>{{n.company}}</span></div>
            <div mat-line class="mat-body-2 margin-top10" [ngClass]="{'opportunity-font-subtitle': userType == 2, 'opportunity-font-subtitle-margin5': userType != 2}" *ngIf="n.locations?.length == 1">
              <span *ngIf="n.locations[0].city != null && n.locations[0].city.length>0">{{ n.locations[0].city +", " }} </span>
              <span *ngIf="n.locations[0].state != null">{{ n.locations[0].state }}</span>
            </div>
            <div mat-line class="mat-body-2 margin-top10" [ngClass]="{'opportunity-font-subtitle': userType == 2, 'opportunity-font-subtitle-margin5': userType != 2}" *ngIf="n.locations?.length > 1">
              <span>Multiple Locations</span>
            </div>
            <div mat-line class="margin-bottom-10 margin-top10" *ngIf="userType != 2"></div>
            <div mat-line>
              <p fxLayout.xs="column" fxLayout="row" fxLayoutAlign="space-between">
                <span *ngIf="userType != 3" fxFlex="50" fxFlex.xs="100" class="mat-caption" [ngClass]="{'opportunity-posted-by-tag':screenBreakPoint}">
                  {{n.postingdate }}
                </span>
                <span *ngIf="userType == 2 && n.skills.length > 0" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end" class="mat-caption" [ngClass]="{'opportunity-posted-by-tag':screenBreakPoint}">
                  <img [src]="n.score_img|safeHtml" />
                </span>

                <span *ngIf="userType==3 || userType==4" fxFlex class="mat-caption" [ngClass]="{'opportunity-posted-by-tag':screenBreakPoint}">
                  {{n.postingdate}}
                </span>
                <!-- <span dir="ltr" class="mat-caption" *ngIf="n.opportunity_type.length == 0">POSITION : Any </span>
              <span dir="ltr" class="mat-caption" *ngIf="n.opportunity_type && n.opportunity_type.length > 1">POSITION : Multiple </span>
              <span dir="ltr" class="mat-caption" *ngIf="n.opportunity_type && n.opportunity_type.length == 1">POSITION : {{n.opportunity_type[0]}} </span> -->
              </p>
            </div>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      </mat-list-item>
      <mat-list-item >
      <div fxLayout="row"  style="min-height:300px;" fxFlex="100">
        <div fxFlex="100" fxLayoutAlign="center center"><mat-spinner *ngIf="isPostingDownloading"></mat-spinner></div>
      </div>
    </mat-list-item>
    </mat-nav-list> 
  </div>
</mat-card>

