<mat-card class="mat-typography">
	<mat-card-title fxLayoutAlign="space-between center">
		<h2>Please Choose a New Password</h2>
		<button mat-raised-button (click)="closeDialog()">×</button>
	</mat-card-title>
	<mat-card-content>
		<p>An Email has been sent to the email address below containing the Password Reset Code. &nbsp;
			<a color="primary" fxFlex="15"  (click)="resetPassword()"> <strong><u>Resend Code</u></strong></a>
		</p>
		<p class="error-message">{{ errorMessage }}</p>
		<form [formGroup]="resetForm" id="resetForm" (ngSubmit)="reset()">
			<mat-form-field color="accent" appearance="outline">
				<mat-label>Email Address</mat-label>
				<input matInput formControlName="email" placeholder="you@email.com">
				<mat-error *ngIf="resetForm.get('email').hasError('email')">
					<small>Please enter your email address</small>
				</mat-error>
			</mat-form-field>  
			<mat-form-field color="accent" appearance="outline">
				<mat-label>Reset Code</mat-label>
				<input matInput formControlName="resetCode" placeholder="Reset Code">
				<mat-error *ngIf="resetForm.get('resetCode').hasError('required')">
					<small>Please enter your reset code</small>
				</mat-error>
			</mat-form-field>  
			<mat-form-field color="accent" appearance="outline">
				<mat-label>New Password</mat-label>
				<input matInput type="password" formControlName="passwordNew" pattern=".{8,200}" placeholder="New Password" #passwordNew>
				<mat-error *ngIf="resetForm.get('passwordNew').hasError('required')">
					<small>Please enter your new password</small>
				</mat-error>
				<mat-error *ngIf="!resetForm.value.passwordNew.pattern"><small>New password should be at least 8 characters</small></mat-error>
			</mat-form-field> 
			<mat-form-field color="accent" appearance="outline">
				<mat-label>Confirm New Password</mat-label>
				<input matInput type="password" formControlName="passwordConfirm" placeholder="New Password" [pattern]="passwordNew.value">
				<mat-error *ngIf="resetForm.get('passwordConfirm').hasError('pattern')">
					<small>Passwords do not match</small>
				</mat-error>
				<mat-error *ngIf="resetForm.get('passwordConfirm').hasError('required')">
					<small>Please enter your confirm new password</small>
				</mat-error>

			</mat-form-field> 
		</form>
	</mat-card-content>	
	<mat-card-actions fxLayoutAlign="end center">
		<button mat-raised-button color="primary" type="submit" [disabled]="!resetForm.valid" form="resetForm" cdkFocusInitial>Submit</button>
	</mat-card-actions>
</mat-card>
