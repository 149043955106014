import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InviteToOpportunitiesComponent } from '../invite-to-opportunities/invite-to-opportunities.component';
import { CandidateToShortlistComponent } from '../candidate-to-shortlist/candidate-to-shortlist.component';
import { PostingShortlistNotexistsComponent } from '../posting-shortlist-notexists/posting-shortlist-notexists.component';
import { EmployerStudentService } from '../../../Services/employer-student.service';
import { UserInfoService } from '../../../Services/user-info.service';
import { ContentReportingComponent } from '../content-reporting/content-reporting.component';

export interface ProfileData {
	candidateId: string;
  }

@Component({
  selector: 'app-candidate-detail',
  templateUrl: './candidate-detail.component.html',
  styleUrls: ['./candidate-detail.component.css']
})
export class CandidateDetailComponent implements OnInit {

  IsShortListNotExists: any = false;
  IsPostingNotExists: any = false;
  userType: any;

  IsMobileDevice = false;
  constructor(
    public _dialog: MatDialog,
    private dialogRef: MatDialogRef<CandidateDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public input: ProfileData,
    private _employerStudentService: EmployerStudentService,
    private _userInfoService: UserInfoService,
    public breakpointObserver: BreakpointObserver) {
    dialogRef.backdropClick().subscribe(result => {
      dialogRef.disableClose = true; //disable default close operation
      dialogRef.close();
    });

    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.IsMobileDevice = true;
        } else {
          this.IsMobileDevice = false;
        }
      });
  }

  ngOnInit(): void {
    this.getEmployerActivities();
    this.userType = this._userInfoService.getUser().type;
  }
  getEmployerActivities() {
    this._employerStudentService.getEmployersActivity().subscribe((result: any) => {
      if (result != null && result.length>0) {       
        if (result[0].active_postings == 0) {
          this.IsPostingNotExists = true;
        }
        if (result[0].shortlist == 0) {
          this.IsShortListNotExists = true;
        }
      }
    });
  }

  onNoClick(): void {
		this.dialogRef.close();
	}
	
	close(): void {
		this.dialogRef.close();
  }

  isShortListOrPostingExists(IsPosting) {
    let dialog = this._dialog.open(PostingShortlistNotexistsComponent, {
      height: '200px',
      width: (IsPosting?'460px':'380px'),
      disableClose: true,
      data: {
        IsShortListNotExists: !IsPosting ? this.IsShortListNotExists : false,
        IsPostingNotExists: IsPosting ? this.IsPostingNotExists : false
      }
    });
      dialog.afterClosed()
        .subscribe((result) => {
        
      });
  }
  
  openInvite() {
    if (this.IsPostingNotExists) {
      this.isShortListOrPostingExists(true);
      return;
    }
    let dialog = this._dialog.open(InviteToOpportunitiesComponent,{
      height: '95vh',
      width: '100%',
      disableClose: true,
      data: {
          candidateId: this.input.candidateId
          }
      });
      dialog.afterClosed()
      .subscribe((result) => {
        console.log(result);
      });
  }
  openShortlist() {
    if (this.IsShortListNotExists) {
      this.isShortListOrPostingExists(false);
      return;
    }
    const dialog = this._dialog.open(CandidateToShortlistComponent, {
      height: '95vh',
      width: '100%',
      disableClose: false,
      data: {
        candidateId: this.input.candidateId
      }
    });
    dialog.afterClosed()
      .subscribe((result) => {
      });
  }
  contReportingDialog() {
    let dialog = this._dialog.open(ContentReportingComponent, {
      height: this.IsMobileDevice ? '400px' : '350px',
      width: this.IsMobileDevice ? '95%' :'45%',
      disableClose: false,
      data: {
        candidateId: this.input.candidateId
      },
      panelClass: 'posting_popup'
    });

  }
}
