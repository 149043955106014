<h1 matDialogTitle fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container *ngIf="!editing.id">Add News Source</ng-container>
    <ng-container *ngIf="editing.id">Edit News Source</ng-container>            
    <button class="mat-icon-button close_bt" (click)="closeDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</h1>
<div matDialogContent>
    <form name="editingForm" id="editingForm" #editingForm="ngForm">
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex.md="48" fxFlex.gt-md="48" fxFlex.lt-md="100">
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>News Source URL</mat-label>
                    <input [(ngModel)]="editing.feedUrl" matInput name="editUrl" id="editUrl" required #editUrl="ngModel">
                    <mat-error *ngIf="editUrl.hasError('required')">You did not enter a URL</mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>User Type </mat-label>
                    <mat-select [(ngModel)]="editing.fk_user_type" name="editUsertype" id="editUsertype" required #edituserType="ngModel">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let data of userTypes; let i = index;" value="{{data.id}}">{{data.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="edituserType.hasError('required')">You did not select a user type</mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>Active</mat-label>
                    <mat-select [(ngModel)]="editing.active" name="editActive" id="editActive" required #editActive="ngModel">
                        <mat-option value="0">Not active</mat-option>
                        <mat-option value="1">Active</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editActive.hasError('required')">You did not select an active status</mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>Expiration date</mat-label>
                    <input matInput [(ngModel)]="editing.expiration" [value]="editing.expiration" [matDatepicker]="expirationPicker" name="editExpiration" id="editExpiration" #editExpiration="ngModel">
                    <mat-datepicker-toggle matSuffix [for]="expirationPicker"></mat-datepicker-toggle>
                    <mat-datepicker #expirationPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex.md="48" fxFlex.gt-md="48" fxFlex.lt-md="100">
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>Tenant </mat-label>
                    <mat-select [(ngModel)]="editing.fk_tenant_id" name="editTenant" id="editTenant" required #editTenant="ngModel">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let data of tenants; let i = index;" value="{{data.id}}">{{data.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editTenant.hasError('required')">You did not select tenant name</mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>Priority</mat-label>
                    <mat-select [(ngModel)]="editing.priority" matInput name="editPriority" id="editPriority" required #editPriority="ngModel">
                        <mat-option value="1">1 (Highest)</mat-option>
                        <mat-option value="2">2</mat-option>
                        <mat-option value="3">3</mat-option>
                        <mat-option value="4">4</mat-option>
                        <mat-option value="5">5 (Lowest)</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editPriority.hasError('required')">You did not select the priority level</mat-error>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>Expires</mat-label>
                    <mat-select [(ngModel)]="editing.expires" name="editExpires" id="editExpires" required #editExpires="ngModel">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editActive.hasError('required')">You did not select an expiration status</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
    <!-- // Edit  -->
    <button mat-button mat-raised-button color="warn" *ngIf="editing.id" (click)="modalDelete()">Delete</button>
    <button  mat-button mat-raised-button color="primary"  *ngIf="editing.id" (click)="modalSave()" [disabled]="!editingForm.valid" >Save</button>
    <button  mat-button mat-raised-button *ngIf="!editing.id" [disabled]="!editingForm.valid" (click)="modalAddNew()">Add New</button>
</div>
