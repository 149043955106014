import {interval as observableInterval } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CreateClassComponent } from '../../Pages/dialogs/create-class/create-class.component';
import { NewFeedbackComponent } from '../../Pages/dialogs/new-feedback/new-feedback.component';
import { LoginComponent } from '../../Pages/login/login.component';
import { EmployerRegistrationComponent } from '../../Pages/employer-registration/employer-registration.component';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
import { SidenavService } from '../../Services/sidenav.service';
import { LoginService } from '../../Services/login.service';
import { UserInfoService } from '../../Services/user-info.service';
import { TalentApiService } from '../../Services/talent-api.service';
import { ThemeService } from '../../Services/theme.service';
import { LogoutConfirmComponent } from '../../Pages/dialogs/logout-confirm/logout-confirm.component';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
	@ViewChild('toggleSidenav', { static: true }) toggleSidenav: ElementRef;
	public hasCompanyAdminPermission:boolean = false;
	public actionsRoutes:any[] = [];
	public navRoutes:any[] = [];
	public isLoggedIn: boolean = false;
	public theme = false;
	public profilePic = '';
	public userName = null;
	public userType = null;
	public userId = null;
	public ErrorMessage: string = '';
	public timerSubscription : any;
	public logoLink: string = '/';
	public sidenavOpened = false;
	public isNotificationIconShow = false;
	public opened;
	private firstLoad:boolean;
  
	constructor(private _themeService: ThemeService, 
		private _loginService: LoginService, 
		public _router: Router, 
		private _userInfoService: UserInfoService, 
		private _talentAPIservice: TalentApiService,
    	public _dialog: MatDialog,
		private _sidenavService: SidenavService,
		private sharedUtils: SharedUtilitiesService) { }


	ngOnInit() {
		this.firstLoad = true;
		this.logoLink = '/dashboard';
		this._sidenavService.navRoutesLoaded.subscribe((result) => {
			this.initialize();
			this._userInfoService.checkMyPermission('ADMIN').subscribe((result: any) => {
				this.hasCompanyAdminPermission = result;
			});
		});


		this._talentAPIservice.isProfPicChanged.subscribe((result:any) => {
			if (result) {
				this.getProfilePicIfChanged(this._userInfoService.getUser().company);
				this.userName = this._userInfoService.getUser().name;
			}
		});

		this._sidenavService.getOpened().subscribe(opened => {
			if(this.firstLoad) {
				this.firstLoad = false;
			}
			else {
				this.toggleSidenav.nativeElement.click();
			}
		});
	}

	initialize() {
		this.navRoutes = this._sidenavService.getNavRoutes();
		this.actionsRoutes = this._sidenavService.getActionsRoutes();
		this.userName = this._userInfoService.getUser().name;
    	this.userType = this._userInfoService.getUser().type;
		this.userId = this._userInfoService.getUser().id;
    	this.getProfilePicIfChanged(this._userInfoService.getUser().company);
	}


  logout(): void {
  
		let dialog = this._dialog.open(LogoutConfirmComponent, { disableClose: true });
		dialog.afterClosed()
			.subscribe((result) => {        
				if(dialog.componentInstance.logoutConfirm){
					this._loginService.logout().subscribe();
					this.userType = undefined;
					this.isLoggedIn = false;
					this.profilePic = null;
					if (this.timerSubscription){
						this.timerSubscription.unsubscribe();
					}
					this.userName = null;
				}
				else{
					this.isLoggedIn = true;
				}
			});
	}

	ngOnDestroy(): void {
		if (this.timerSubscription) {
		  	this.timerSubscription.unsubscribe();
		}
	}

	openCreateClassModal() {
		let dialog = this._dialog.open(CreateClassComponent,{
			height: 'auto',
			width: '33.33%',
			panelClass:"posting_popup",
			disableClose: true
		});
		dialog.afterClosed().subscribe((result) => { });
	}

	openCreateFeedbackModal(userType) {
		let dialog = this._dialog.open(NewFeedbackComponent,{
			height: 'auto',
			panelClass:"custom_popup",
			disableClose: true
		});
		dialog.componentInstance.userType = this.userType;
		dialog.afterClosed().subscribe((result) => { });
	}

	/*--------------------------------------------------|
	| This function needs to check for refresh token	|
	| since core/getMyProfilePic is a protected route	|
	| and would otherwise block unregistered user from	|
	| logging in for the first time.					|
	|--------------------------------------------------*/
	getProfilePicIfChanged(company) {
		if (this._userInfoService.getRefreshToken() !== null && this._userInfoService.getRefreshToken().length > 20) {
			this._userInfoService.getMyProfilePic(company).subscribe(
				(result: any) => {
					this.profilePic = result;
				},
				error =>{
					this.profilePic = null;
					this.ErrorMessage = 'Can not get user profile image'+ error;
				});
		}
	}

	openLoginDialog() {
		let dialog = this._dialog.open(LoginComponent);
	}

	openEmpRegDialog() {
		let dialog = this._dialog.open(EmployerRegistrationComponent,{
			maxHeight: '90vh',
			width: '66.66%',
			// panelClass:"posting_popup",
			// disableClose: true
		});	
	}
  navigateTo(link) {
    this._router.navigateByUrl('/empty', { skipLocationChange: true }).then(() => {
      this._router.navigate([link]);
    });
  }
	swapTheme(e){
		switch (this.theme){
			case true:
				this._themeService.setAltTheme();
				break;
			case false:
				this._themeService.setDefaultTheme();
				break;
		}
	}
}

