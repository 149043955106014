<div  class="container admin_user_page proflie-page" ng-cloak>
<div class="row">
  <div class="col-lg-12 drop-shadow bg-light">

      <h4>Config Variables</h4>

      <p><em>Config variables are options or values used throughout the system that are configurable from the admin area.</em></p>

      <div class="instructions_cl">

              <div class="pull-right addbutton">
                  <button mat-button color="primary" mat-raised-button (click)="openEditModal()">Add New</button>
                      
              </div>

              <strong>Instructions:</strong>

              <ol>
                  <li>Add a config variable by clicking the "Add New" button on the right.</li>
                  <li>
                      Each variable must have a unique name. We suggest all caps with words separated by underscores.
                      <ul>
                          <li><code>Ex: DETAILED_VARIABLE_NAME_MAKING_ITS_PURPOSE_OBVIOUS</code></li>
                      </ul>
                  </li>
                  <li>The value can be anything in string form including but not limited to numbers, paragraphs of text and or JSON.</li>
              </ol>

        
      </div>

      <div> 
          <table datatable id="studentdatatables" width="100%" class="table table-striped table-hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                  <tr>
                      <th >ID</th>
                      <th>Name</th>
                      <th width="20%">Value</th>
                      <th width="20%">Last Update</th>
                      <th width="15%" class="text-center">Updated On</th>
                      <th width="20%" class="text-center">Edit</th>

                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let list of configs; let i = index;">
                      <td >{{list.id}}</td>
                      <td>{{list.name}}</td>
                      <td>{{list.value}}</td>
                      <td>{{list.last_updated_by}}</td>
                      <td class="date"> {{list.last_updated_ts | date}}</td>
                      <td class="text-center"><button mat-button mat-raised-button 
                        (click)="openEditModal(list.id)" class="pull-right">Edit</button></td>
                      
                  </tr>
              </tbody>
          </table>
      </div>

  </div> 
</div>
</div>
