
<div id="editing-modal" class="timesheet-popup admin_popup" role="form">
  <form name="editingForm" id="editingForm" #editingForm="ngForm" class="" novalidate ng-cloak>
    <div class="modal-content">
        <div class="modal-header">
      <div>
          <h1 *ngIf="!modalInEditMode" mat-dialog-title> User: {{editing.firstName}} {{editing.lastName}}</h1>

          <h1 *ngIf="modalInEditMode" mat-dialog-title>Editing: #{{editing.id}} - {{editing.firstName}} {{editing.lastName}}</h1>            
          
          <button class="mat-icon-button close_bt" (click)="closeDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
    
    </div>
   
    <div class="modal-body">
            
        <!-- //  ================== Starts Edit mode =======================================         -->
        <div class="row" *ngIf="modalInEditMode">            
            <div class="col-sm-6">
                <mat-form-field color="accent" appearance="outline">
                    <input placeholder="First Name" matInput name="editfirstName" id="editfirstName"  [(ngModel)]="editing.firstName" #editfirstName="ngModel" maxlength="255" required (change)="editing.firstName=editing.firstName.trim()">             
                <mat-error *ngIf="editfirstName.hasError('required')">You did not enter First Name</mat-error>
                <mat-error *ngIf="!editfirstName.hasError('required') && editfirstName.hasError('maxlength')">First Name must be between 1 and 255 characters long.</mat-error>              
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field color="accent" appearance="outline">
                    <input placeholder="Last Name" matInput name="editlastName" id="editlastName"  [(ngModel)]="editing.lastName" #editlastName="ngModel" maxlength="255" required (change)="editing.lastName=editing.lastName.trim()">            
                    <mat-error *ngIf="editlastName.hasError('required')">You did not enter Last Name</mat-error>
                    <mat-error *ngIf="!editlastName.hasError('required') && editlastName.hasError('maxlength')">Last Name must be between 1 and 255 characters long.</mat-error>                
                </mat-form-field>
            </div>

            <div class="col-xs-12">
                <mat-form-field color="accent" appearance="outline">
                    <input placeholder="Email (Username)" matInput required type="email" id="email_address" name="email_address" [(ngModel)]="editing.email" #email_address="ngModel" required minlength="10" maxlength="100" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" (change)="editing.email=editing.email.trim()"/>         
                    <mat-error *ngIf="!email_address.pattern && !email_address.valid && !email_address.hasError('required')" class="help-block small animated fadeInDown">
                        Your email must be between 10 and 100 characters long and look like an e-mail address.
                    </mat-error>
                    <mat-error *ngIf="email_address.hasError('required')" class="help-block small animated fadeInDown">
                        You did not enter your email address
                    </mat-error>         
                </mat-form-field>
            </div>

            <div class="col-xs-12">
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>User Group</mat-label>
                    <mat-select matInput #userGroup="ngModel" required name="userGroup" [(ngModel)]="editing.fk_userGroup">   
                        <mat-option *ngFor="let group of userGroups" value="{{group.id}}">{{group.name}}</mat-option>      
                    </mat-select>
                    <mat-error *ngIf="userGroup.hasError('required')"> Please select a group for this user </mat-error>
                </mat-form-field>
            </div>

            <div class="col-xs-12">
                <label class="mytitle" for="#phone">Contact Phone</label>
                <mat-form-field color="accent" appearance="outline">
                    <!-- <input matInput name="phone" placeholder="(888) 888-8888" [textMask]="{mask: mask}" type="text" phone-input [(ngModel)]="editing.phone" #phone="ngModel" pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"  (change)="editing.phone=editing.phone.trim()"> -->
                    <input matInput name="phone" mask="(000) 000-0000" type="text" phone-input [(ngModel)]="editing.phone" #phone="ngModel" (change)="editing.phone=editing.phone.trim()">
                    <mat-error *ngIf="displayValid(phone)" class="help-block small animated fadeInDown">
                        Please enter valid phone number
                    </mat-error>               
                </mat-form-field>
            </div>
        </div>      
        <!-- //  ================== Ends Edit mode =======================================         -->


        <div [hidden]="modalInEditMode" class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <label>User Name</label>
                    <p>{{editing.firstName}} {{editing.lastName}}</p>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="form-group">
                    <label>Email (Login Username)</label>
                    <p>{{editing.email}}</p>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="form-group">
                    <label>Phone</label>
                    <p>{{editing.phone}}</p>
                </div>
            </div>
            <hr />
        </div> 
    </div>


    <div class="modal-footer" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
        <div *ngIf="modalInEditMode && editing.enabled == '1'">
            <button *ngIf="modalInEditMode && editing.enabled == '1'"  mat-button mat-raised-button color="warn" (click)="modalEnableDisable(false)" class="md-raised md-warn pull-left">Disable User</button>
        </div>
        <div *ngIf="modalInEditMode && editing.enabled == '0'">
            <button  mat-button mat-raised-button *ngIf="modalInEditMode && editing.enabled == '0'" (click)="modalEnableDisable(true)" class="md-raised Enable_user_btn md-warn pull-left">Enable User</button>
        </div>

        <div *ngIf="modalInEditMode" style="display: inline-block;">
            <button mat-button mat-raised-button  class="save_btn"  *ngIf="modalInEditMode" (click)="modalSave()" >Save</button>
        </div>
        <div *ngIf="modalInEditMode" style="display: inline-block;">
            <button *ngIf="modalInEditMode" (click)="modalCancelEdit()" mat-button mat-raised-button color="primary" color="warn">Cancel</button>
        </div>

        <div *ngIf="!modalInEditMode">
            <button *ngIf="!modalInEditMode" (click)="modalEdit()" mat-button mat-raised-button class="pull-left edit_user_btn">Edit User</button>
        </div>
        <div *ngIf="!modalInEditMode">
            <button *ngIf="!modalInEditMode" class="popup_done_btn" (click)="modalCancel()" mat-button mat-raised-button>Done</button>
        </div>
    </div>

  
  </div>
</form>
</div>