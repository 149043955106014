import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { CandidateDiversity, CandidateProfile } from '../../../../Models/CandidateProfile';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-candidate-diversity-questions',
  templateUrl: './candidate-diversity-questions.component.html',
  styleUrls: ['./candidate-diversity-questions.component.css']
})
export class CandidateDiversityQuestionsComponent implements OnInit {
    
  @Input() diversityQuestion: CandidateDiversity;
  @Input() edit: boolean = false;
  @Input() candidateProfileLoaded: EventEmitter<CandidateProfile>;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  genderList: any;
  ethnicityList: any;
  militaryStatusList:any;
  initialQuestion:CandidateDiversity;
  public breakpoint:number; // used to classify the screen size/display width



  constructor(private _talentAPIService: TalentApiService, private breakpointObserver: BreakpointObserver, public fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    this.getGenderList();
    this.getEthnicityList();
    this.getMilitaryStatusList();
    if(this.edit) {
      this.initialQuestion = Object.assign({}, this.diversityQuestion);
    }
  }
  getGenderList() {
    this._talentAPIService.getGenderList()
      .subscribe(
        (result: any) => {
          this.genderList = result;         
        },
        error => {
          this._talentAPIService.snackbarMessage('Something went wrong!');
        });
  }
  getEthnicityList() {
    this._talentAPIService.getEthnicityList()
      .subscribe(
        (result: any) => {
          this.ethnicityList = result;           
        },
        error => {
          this._talentAPIService.snackbarMessage('Something went wrong!');
        });
  }
  getMilitaryStatusList() {
    this._talentAPIService.getMilitaryStatusList()
      .subscribe(
        (result: any) => {
          this.militaryStatusList = result;
        },
        error => {
          this._talentAPIService.snackbarMessage('Something went wrong!');
        });
  }

  skip() {
    this.saveProfile.emit(this.diversityQuestion);
  }

  prev() {
    this.prevEvent.emit();
  }

  cancel() {
    this.diversityQuestion = Object.assign({}, this.initialQuestion);
  }
  next() {
    const diversityQuestion = this.diversityQuestion;
    const candidateProfile = {
      diversityQuestion: diversityQuestion
    }
    this._talentAPIService.updateProfile(candidateProfile)
      .subscribe(
        (result: any) => {
          if (result) {
            if(!this.edit) {
              this.saveProfile.emit(this.diversityQuestion);
              console.log(diversityQuestion);         
            } else {
              this._talentAPIService.snackbarMessage('Diversity and Inclusion Survey Updated');
            }
          }
          else {
            this._talentAPIService.snackbarMessage('Something went wrong!');
          }
        },
        error => {
          this._talentAPIService.snackbarMessage('Something went wrong!');
        });

  }


  ethnicityRadioChange(evt) {
    this.diversityQuestion.ethnicity = evt.value; console.log(this.diversityQuestion);
  }
  genderRadioChange(evt) {
    this.diversityQuestion.gender = evt.value;
  }
  militaryRadioChange(evt) {
    this.diversityQuestion.military_status = evt.value;
  }
}
