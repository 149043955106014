import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver } from '@angular/cdk/layout';

import { CandidateSearch } from '../../Models/CandidateSearch'
import { UserInfoService } from '../../Services/user-info.service';
import { EmployerStudentService } from '../../Services/employer-student.service';
import { TalentApiService } from '../../Services/talent-api.service';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';
import { SearchStudentComponent } from '../../Pages/dialogs/search-student/search-student.component';
import { CandidateDetailComponent } from '../dialogs/candidate-detail/candidate-detail.component';
import { CandidateToShortlistComponent } from '../dialogs/candidate-to-shortlist/candidate-to-shortlist.component';
import { fromEvent } from 'rxjs';
import { debounceTime,map,distinctUntilChanged,filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveFilterDialogComponent } from '../dialogs/save-filter-dialog/save-filter-dialog.component';

@Component({
  selector: 'app-employer-student',
  templateUrl: './employer-student.component.html',
  styleUrls: ['./employer-student.component.css'],
})
export class EmployerStudentComponent implements OnInit {
	@ViewChild('searchStudent', { static: true,read: ElementRef }) searchStudent: ElementRef;
	public ErrorMessage: string = "";
	saveSearchList = [];
	public employerStudents = []; // List returned by API, current page of queried candidates
	public totalLength = 0; // Length of the candidate array
	public pageSize= 20; // Number of candidate fetched by API and displayed
	public pageIndex = 0; // Page number (starting candidate index = pageIndex * pageSize)
	
	public searchParams:CandidateSearch = {
		text: '',
		educationLevel: [],
		availability: [],
		experience: null,
		skills: [],
		location: []
	};

	public routeQueryParams;
	public breakpoint:number; // used to classify the screen size/display width
	public isLoadingResult:boolean = false;

	constructor(private _employerStudentService: EmployerStudentService,
		private _talentAPIService: TalentApiService,
		public _dialog: MatDialog,
		private _userInfoService: UserInfoService,
		private breakpointObserver: BreakpointObserver,
		private _router: Router,
		private _route: ActivatedRoute,
		private sharedUtils: SharedUtilitiesService) {}

	ngOnInit() {
		// May Layout breakpoint observer, sets this.breakpoint equal
		// to the number of cards that can fit on one page, zero meaning the
		// card must be scaled down
		this.routeQueryParams = this._route.queryParams.subscribe(params => {
			if(params['text'] && params['text'] !='') {
				this.searchParams.text = params['text'];
			}
			if(params['educationLevel']) {
				this.searchParams.educationLevel = JSON.parse(params['educationLevel']);
			} else {
				this.getDegreeTypes();
			}
			if(params['availability']) {
				this.searchParams.availability = JSON.parse(params['availability']);
			} else {
				this.getCandidateAvaibility();
			}
			if(params['experience'] && params['experience'] != null) {
				this.searchParams.experience = params['experience'];
			}
			if(params['skills']) {
				this.searchParams.skills = JSON.parse(params['skills']);
			}

			if(params['location']) {
				this.searchParams.location = JSON.parse(params['location']);
			}
		});
		this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
			fromEvent(this.searchStudent.nativeElement, 'keyup').pipe(
				// get value
				map((event: any) => {
					return event.target.value;
				})
				// if character length greater then 3
				, filter(res => res.length > 3)
				
				// Time in milliseconds between key events
				, debounceTime(2000)
				
				// If previous query is diffent from current   
				, distinctUntilChanged()
				
				// subscription for response
				).subscribe((text: string) => {
					if(this.breakpoint < 1) {
						this.search();
					}
				});
		// initial candidateSearch with no search parameters
		this.executeSearch(0, this.pageSize, false);
		this.getMySearches();
	}

	// Retrieves pageSize number of candidates starting at 
	// pageNumber*pageSize index, such that all candidates match
	// the search criteria
	executeSearch(pageNumber, pageSize, requiredNavigation) {
		this.isLoadingResult = true;
		this._employerStudentService.getSearchStudents(this.searchParams, pageNumber, pageSize).subscribe((result:any) => {
			if (result) {
				this.employerStudents = result['candidates'];
				this.totalLength = result['length']
				this.setImages();
				this.isLoadingResult = false;
				if(requiredNavigation) {
					var location, text, educationLevel, availability, skills;
					if(this.searchParams.text != '') {
						text = this.searchParams.text;
					}
					if(this.searchParams.educationLevel && this.searchParams.educationLevel.filter(el => el.checked === true).length > 0) {
						educationLevel =  JSON.stringify(this.searchParams.educationLevel);
					}
					if(this.searchParams.availability && this.searchParams.availability.filter(el => el.checked === true).length > 0) {
						availability =  JSON.stringify(this.searchParams.availability);
					}
					if(this.searchParams.skills.length > 0) {
						skills = JSON.stringify(this.searchParams.skills);
					}
					if(this.searchParams.location.length > 0) {
						location = JSON.stringify(this.searchParams.location);
					}
					var parameter = {
						text: text,
						educationLevel: educationLevel,
						availability: availability,
						experience: this.searchParams.experience,
						skills: skills,
						location: location
					}
					this._router.navigate(['employer-candidates'], { queryParams: parameter });
				}
			} else {
				console.log('User is not initialized');
			}
		});
	}  

	// Gets the next group of candidates from the API
	onPageChange(event) {
		this.pageSize = event.pageSize;
		this.executeSearch(event.pageIndex, event.pageSize, true)
	}
	// Gets the first group of candidates from the API
	// with the given search parameters
	search() {
		this.executeSearch(0, this.pageSize, true);
	}

	// Opens a dialog to view the student profile
	viewProfile(id) {
			  let dialog = this._dialog.open(CandidateDetailComponent,{
				height: 'auto',
				width: '100%',
				disableClose: true,
				data: {
						candidateId: id
					  }
			  });
			  dialog.afterClosed()
				.subscribe((result) => {
					console.log(result);
				});

		//window.open(`/profile/${id}`,'_self');
	}

	// Display the images for each candidate on load
	setImages(){
		var self = this;
		this.employerStudents.map((element, i) => {
			self.sharedUtils.getFile(element.id, 'student', 'profile-pic')
				.subscribe((result: any) => {
					if (result) {
						if (!element.pending && !element.active) {
							element.pending = false;
							element.active = false;
						}
						if (result[0]['profile-pic'] && result[0]['profile-pic'] !=null && result[0]['profile-pic'] !='Array') {
							element.profilePic = result[0]['profile-pic'];
						} else {
							element.profilePic = '';
						}
					} else {
						element.profilePic = '';
						console.log("Image cant load");
					}
				},
				error => {
					element.profilePic = '';
				});
	  	});
  	}

	clearFilters() {
	 	 // check if any params available
		if (this.searchParams.text ||
			this.searchParams.availability.filter(el => el.checked === true).length > 0 ||
			this.searchParams.educationLevel.filter(el => el.checked === true).length > 0 ||
			this.searchParams.skills.length > 0 ||
			this.searchParams.location.length > 0 ||
			this.searchParams.experience) {
			this.searchParams.text = null;
			this.searchParams.availability.forEach(el => { el.checked = false; });
			this.searchParams.educationLevel.forEach(el => { el.checked = false; });
			this.searchParams.experience = null;
			this.searchParams.skills = [];
			this.searchParams.location = [];
			this.search();
		}
  }


	openAdvancedFilterDialog() {
		const dialog = this._dialog.open(SearchStudentComponent, {
			height: 'auto',
			disableClose: false,
			panelClass: 'custom_popup',
			data: {
				text: this.searchParams.text,
				skills: this.searchParams.skills,
				educationLevel: this.searchParams.educationLevel,
				availability: this.searchParams.availability,
				experience: this.searchParams.experience,
				location: this.searchParams.location,
			}
		});
		dialog.afterClosed()
			.subscribe((result) => {
				if(result) {
					this.searchParams.text = result.text;
					this.searchParams.skills = result.skills;
					this.searchParams.educationLevel = result.searchParams.educationLevel;
					this.searchParams.availability = result.searchParams.availability;
					this.searchParams.experience = result.searchParams.experience;
					this.searchParams.location = result.searchParams.location;
					this.search();
					this.getMySearches();
				}
			});
	}

	getDegreeTypes() {
		this._employerStudentService.getDegreeTypes()
		.subscribe((result: any) => {
		if (result) {
			result.push({
				name: 'Any',
				id: 0
			});
			this.searchParams.educationLevel = result;
		} else {
			console.log('User is not initialized');
		}
		});
	}

	getCandidateAvaibility() {
		this._employerStudentService.getCandidateAvaibility()
		.subscribe((result: any) => {
		if (result) {
			result.push({
				availability_type: 'Any',
				id: 0
			});
			this.searchParams.availability = result;
		} else {
			console.log('User is not initialized');
		}
		});
	}

	clearSearchText() {
		this.searchParams.text = null;
		this.search();
	}

	viewShortlist(id){		
		const dialog = this._dialog.open(CandidateToShortlistComponent, {
			height: '95vh',
			width: '100%',
			disableClose: false,
			data: {
				candidateId: id
			  }						
		});
		dialog.afterClosed()
			.subscribe((result) => {
				
			});
	}

	getMySearches(){
		var obj = {
		  search_type : 'candidate'
		}
		this._talentAPIService.getMySearches(obj).subscribe((result: any) => {
		  if(result) {
			this.saveSearchList = result.result;
		  }
		},
		  error => {
			console.log("Unable to load Opportunity");
		  }
		);
	}

	applySearch(data) {
		var search_parameters = JSON.parse(data.search_parameters);
		this.searchParams.text = search_parameters.text;
		this.searchParams.skills = search_parameters.skills;
		this.searchParams.educationLevel = search_parameters.educationLevel;
		this.searchParams.availability = search_parameters.availability;
		this.searchParams.experience = search_parameters.experience;
		this.searchParams.location = search_parameters.location;
		this.search();
	}

	editSearch(data) {
		data['search_type'] = 'candidate';
		const dailog = this._dialog.open(SaveFilterDialogComponent, {
		  height: 'auto',
		  width: '70vw',
		  data: data,
		  panelClass: this.breakpoint < 3 ? 'posting_popup' : ''
		}).afterClosed().toPromise()
		  .then((data) => {
			if (data.result == null) {
			}
			else {
			  this.getMySearches();
			  this._talentAPIService.snackbarMessage(data.result.msg);
			}
		});
	}
}
