import { Component, Input, OnInit, Optional } from '@angular/core';

import { CandidateCertification } from '../../../Models/CandidateProfile'
import { TalentApiService } from '../../../Services/talent-api.service';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';
import { ProfileEditComponent } from '../../../Pages/profile-edit/profile-edit.component';
import { MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-courses-and-certifications-view',
  templateUrl: './courses-and-certifications-view.component.html',
  styleUrls: ['./courses-and-certifications-view.component.css']
})
export class CoursesAndCertificationsViewComponent implements OnInit {
  @Input() ownProfile: boolean;
  @Input() coursesAndCertifications: any;

  public showingMore = false;
  public dateOptions = {
    month: 'long',
    year: 'numeric'
  }
  public logos:any[] = []

  constructor(private _talentApiService:TalentApiService, public _dialog: MatDialog,
    private sharedUtils: SharedUtilitiesService) { }

  ngOnInit(): void {
    this.setDatesAwarded();
  }

  toggleShow() {
    if(this.showingMore == true) {
      this.showingMore = false;
    }
    else {
      this.showingMore = true;
    }
  }

  openEdit() {
    let dialog = this._dialog.open(ProfileEditComponent,{
      height: 'auto',
      width: '80%',
      disableClose: true,
      data: {
              subComponent: 'candidate-certifications',
              seedData: this.coursesAndCertifications
            }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        //this won't work. we'll probably want to just refresh the page.
        if (result == null) {
        } else {
          //console.log(result.candidateCertifications);
          this.coursesAndCertifications = result.candidateCertifications;
          this.setDatesAwarded();
        }
      });
  }

  setDatesAwarded() {
    for (let cert of this.coursesAndCertifications) {
      cert.dateAwarded = cert.dateAwarded != null ? new Date(cert.dateAwarded) : null;
    }
  }

}
