<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="mat-typography">
	<!--color="primary" -->
	<a [routerLink]="'/dashboard'">
	  <app-logo [tenant]="tenant" [type]="'header'" class="app-header-logo"></app-logo>
	</a>
	<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="toolbar-height" fxHide.lt-lg>
	  	<div fxLayout="row" fxLayoutAlign="start end" style="height:64px">
				<nav mat-tab-nav-bar color="primary">
					<!--backgroundColor="primary" -->
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/dashboard']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Home
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/config']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Config
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/users']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Users
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/tenantPermissions']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Tenant Permissions
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/skills']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						skills
					</a>
					<a mat-tab-link
						routerLinkActive
						#RouterLinkActive="routerLinkActive"
						[active]="RouterLinkActive.isActive"
						[routerLink]="['admin/content-reporting']"
						[ngClass]="{focus: RouterLinkActive.isActive}">
						Content Reporting 
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/logo']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Logo
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/tenants']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Tenants
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/tenantlogo']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Tenant Logo
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/newsfeed']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						News Feeds
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/emailTemplates']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Email Templates
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/bulkImportPostings']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Import Postings
					</a>
					<a mat-tab-link
						 routerLinkActive
						 #RouterLinkActive="routerLinkActive"
						 [active]="RouterLinkActive.isActive"
						 [routerLink]="['/admin/marketingEmails']"
						 [ngClass]="{focus: RouterLinkActive.isActive}">
						Marketing
					</a>
				</nav>
		</div>
	</div>
</mat-toolbar>
