import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from '../../../dialogs/common-confirm-popup/common-confirm-popup.component';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { CompanyInfoService } from '../../../../Services/company-info.service';

@Component({
  selector: 'app-view-group-rights',
  templateUrl: './view-group-rights.component.html',
  styleUrls: ['./view-group-rights.component.css']
})
export class ViewGroupRightsComponent implements OnInit {
  viewOnly = false;
  groupPermissions: any;
  
  constructor(
    public _companyInfoService: CompanyInfoService,
    public _talentApiService: TalentApiService,
    public _dialog: MatDialog,
    private dialogRef: MatDialogRef<ViewGroupRightsComponent>,
    @Inject(MAT_DIALOG_DATA) public groupInfo: any
  ) { }

  ngOnInit(): void {
    if(this.groupInfo.dateAdded === 'Default') {
      this.viewOnly = true;
    }
  }

  save() {
    this._companyInfoService.editGroupPermissions(this.groupInfo.id, this.groupPermissions).subscribe((result:any) => {
      this._talentApiService.snackbarMessage('Permissions for employer group ' + this.groupInfo.name + ' have been changed');
      this.dialogRef.close();
    },
    error => {
      this._talentApiService.snackbarMessage("Failed to edit user group");
    })
  }

  confirmDelete() {
    let dialog;
    dialog = this._dialog.open(CommonConfirmPopupComponent, {
      height: '175',
      width: '36.66%',
      disableClose: false,
    });
    dialog.componentInstance.confirm_message = 'This group is associated with '+ this.groupInfo.numUsers +' user(s). Are you sure you want to delete this group?';
    dialog.afterClosed().subscribe((result) =>{
      if (dialog.componentInstance.confirmDiscardPost){
        this.deleteGroup();
      }
    });
  }

  deleteGroup() {
    this._companyInfoService.deleteGroup(this.groupInfo.id).subscribe((result:any) => {
      this._talentApiService.snackbarMessage('Employer group ' + this.groupInfo.name + ' has been deleted');
      this.dialogRef.close();
    },
    error => {
      this._talentApiService.snackbarMessage("Failed to delete user group");
    })
  }

  onDataChange(event) {
    this.groupPermissions = event;
  }

}
