<mat-card fxLayout="column" fxFlex="100">
    <mat-card-title><h2>Skills</h2></mat-card-title>
    <mat-card-content fxFlex="90%">
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start stretch">
            <div fxFlex="75%">
                <ngx-charts-bar-horizontal-2d
                    *ngIf="skillData.length"
                    [title]="'Skills In Demand and Available'"  
                    [results]="skillData"
                    [animations]="true"
                    [xAxis]="true"
                    [yAxis]="true"
                    [maxYAxisTickLength]="10"
                    [barPadding]="1"
                    [tooltipDisabled]="false"
                    [legend]="true"
                    [legendTitle]="''"
                    [legendPosition]="'bottom'"
                    [view]="view"
                    [scheme]="colorScheme">
                </ngx-charts-bar-horizontal-2d>
            </div>
        </div>        
    </mat-card-content>
</mat-card>



