// also used in students list ( school user )

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postingOrder',
  pure: false
})
export class PostingOrderPipe implements PipeTransform {


  public transform(value, keys: any, rev: any) {
   
    if (!keys) return value;
    let arry = value;
    let ky = keys.toLowerCase();

    return arry.sort(function (a, b) {
       var x = a[ky];
       var y = b[ky];

       if (typeof x == "string") {
          x = ("" + x).toLowerCase();
       }
       if (typeof y == "string") {
          y = ("" + y).toLowerCase();
       }

       if(rev){
          return ((x > y) ? -1 : ((x < y) ? 1 : 0));
       }
       else{
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
       }
    });
   }

}
