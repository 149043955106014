import { Component, EventEmitter, OnInit } from '@angular/core';
import { PostingService } from '../../Services/posting.service';
import { TalentApiService } from '../../Services/talent-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { UserInfoService } from '../../Services/user-info.service';
import { OpportunityService } from '../../Services/opportunity.service';
import * as _ from 'underscore';
import { Opportunity } from '../../Models/Opportunity';
import { Observer, Subscription } from 'rxjs';

@Component({
  selector: 'app-posting',
  templateUrl: './posting.component.html',
  styleUrls: ['./posting.component.css'],
})
export class PostingComponent implements OnInit {
  detail: any;
  sectors = [];
  user: any;
  inviteEmail: any;
  editable: any;
  selectedSkill: any[];
  selectedSkillsToggle: any[];
  postQuip: any;
  showApplicants: boolean;
  cardWidth: any;
  resultDisplay: boolean = false;
  userType: number;
  public loadMorePostings: EventEmitter<any> = new EventEmitter<any>();
  public opportunityList: Opportunity[] = [];
  public opportunitiesLoaded: EventEmitter<any> = new EventEmitter<any>();
  public opportunityFilterType: EventEmitter<any> = new EventEmitter<any>();
  private subscription: Subscription;

  constructor(private _userInfoService: UserInfoService,
    public breakpointObserver: BreakpointObserver,
    private _talentApiService: TalentApiService,
    private _router: Router, private route: ActivatedRoute,
    private _postingService: PostingService,
    private _opportunityService: OpportunityService) {
  }

  ngOnInit() {
    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.cardWidth = true;
        } else {
          this.cardWidth = false;
        }
      });

    this.resultDisplay = true;    
    // Route paramatrization logic:
    // if the router parametrization is null or posting specific, the page will load the full opportunity list
    // if the router parameter is 'applied' or 'active', then the parametrized opportunity list will be loaded
    // and the child router will redirect to the first opportunity in the returned list. 
    let isRoutePostingSpecified: boolean = this.route.firstChild && !isNaN(Number(this.route.firstChild.snapshot.params.postingId));
    if (this.route.firstChild == null || isRoutePostingSpecified) {
      let data = {
        postingId: this.route.firstChild ? this.route.firstChild.snapshot.params.postingId : null
      }
      this._opportunityService.getOpportunities(data).subscribe((result: any) => {       
        if (result['opportunityList'].length) {
          this.opportunityFilterType.emit(result['opportunityFilterType']);
          this.opportunitiesLoaded.emit(result['opportunityList']); // <- signals the opportunity list component to format results
          if (this.route.firstChild == null) {
            if (result['opportunityList'].filter(x => x.published == '1').length > 0) {
              //Searching for first active posting
              let activePosting = result['opportunityList'].filter(x => x.status !== 'Closed' && x.published == '1' && parseInt(x.quantity) > parseInt(x.TotalRecruited))[0];
              if (activePosting) {
                this.resultDisplay = true;
                this._router.navigate(['postings', activePosting.id]);
              }
              else {
                this.resultDisplay = false;
                this._router.navigate(['postings', 0]);
              }
            }
            else {
              this._router.navigate(['postings', 0]);
            }
          }
        }
        else {
          this._router.navigate(['postings', 0]);
        }
      });
    }
    else {
      this.route.firstChild.params.subscribe(params => {
        // only jobs applied for by user
        if (params.postingId && (params.postingId == 'applied' || params.postingId == 'tagged'
           || params.postingId == 'matched') && this.userType == 2) {
          this._opportunityService.getOpportunities({ searchType: params.postingId }).subscribe((result: any) => {
            if (result['opportunityList'].length) {
              this.loadMorePostings.emit({ hasMorePosting: false });
              this.opportunitiesLoaded.emit(result['opportunityList']);
              this._router.navigate(['postings', result['opportunityList'][0].id]);
            }
          });
        }
      });
    }
  }
}
