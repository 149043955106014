<div fxLayout="row">
    <div fxLayout="column" fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
        <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
    </div>
    <div fxLayout="column" fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90">
        <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">What are your top talents/skills?</h1> 
    </div>
</div>
<form name="candidateSkillForm" id="candidateSkillForm" [formGroup]="form" autocomplete="off">
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex="100">
            <div fxLayout="row"
                fxLayoutAlign="space-between" 
                [ngClass]="'form-item'">
                <button mat-stroked-button color="primary" (click)="openSkillDialog()">All Skills</button>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100" [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}" *ngIf="t.controls.length > 0">
                <div fxLayout="column" fxFlex="100" fxLayoutGap="5px">
                  <div fxLayout="row" fxLayoutGap="30px">
                    <h4 class="remove-margin" fxFlex="60">Skills</h4>
                    <h4 class="remove-margin" fxFlex="40">Years</h4>
                  </div>
                  <div *ngFor="let cs of t.controls; let expIndex = index;" fxLayout="row" [formGroup]="cs" fxLayoutGap="5px" fxFlex="100">
                    <div fxFlex="60" fxFlex.lt-md="55" fxLayout="column">
                      <mat-form-field color="accent" appearance="outline" fxFlex="70">
                        <mat-label>Skill Name</mat-label>
                        <input formControlName="name" matInput>
                        <mat-error *ngIf="cs.controls.name.hasError('required')" class="help-block small animated fadeInDown">
                          <span fxShow fxHide.xs>This field is required!</span><span fxShow.xs fxHide>Required!</span>
                        </mat-error>
                        <mat-error *ngIf="cs.controls.name.hasError('maxlength')">This skill is too long (100 character maximum)</mat-error>
                      </mat-form-field>
                    </div>
                    <div fxFlex="30" fxFlex.lt-md="25" fxLayout="column">
                      <mat-form-field color="accent" appearance="outline" fxFlex="30">
                        <mat-label>Experience years</mat-label>
                        <mat-select formControlName="expYears" id="skexp{{expIndex}}" [aria-label]="cs.controls.name.value">
                          <mat-option *ngFor="let item of [].constructor(41);let i=index;" [value]="i.toString()">{{i}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="cs.controls.expYears.hasError('required')" class="help-block small animated fadeInDown">
                          <span fxShow fxHide.xs>This field is required!</span><span fxShow.xs fxHide>Required!</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxFlex="10" fxLayoutAlign="center start">
                      <button mat-button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="removeSkillWithYear(expIndex)">
                        <mat-icon color="accent">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
            </div>
            <div fxLayout="row" fxFlexOffset="20px">
                <div fxLayout="column" fxLayoutAlign="start">
                    <button type="button" mat-raised-button color="primary" [disabled]="!form.valid || t.length == 0" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                </div>
            </div>
        </div>
    </div>
</form>
