import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminApiService } from '../../Services/admin-api.service';
import { SystemApiInfoComponent } from '../dialogs/system-api-info/system-api-info.component';

@Component({
  selector: 'app-system-apis',
  templateUrl: './system-apis.component.html',
  styleUrls: ['./system-apis.component.css']
})
export class SystemApisComponent implements OnInit {

  dataSource: MatTableDataSource<any> = null;
  displayedColumns: string[] = ['Id', 'name', 'last_updated_by', 'last_updated_ts', 'view'];
  totalRecords = 0;
  pageSize = 10;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _adminApiService: AdminApiService,
    private _router: Router,
    public _dialog: MatDialog,
    private route: ActivatedRoute) {


  }

  ngOnInit(): void {
    this.getAPIList()
  }
  

  getAPIList() {
    this._adminApiService.getAPIList()
      .subscribe((result: any) => {
        this.dataSource = new MatTableDataSource(result);
        this.totalRecords = this.dataSource.data.length;
        this.dataSource.paginator = this.paginator; 
      },
        error => {
          console.log('Could not load Config Variables');
        }
      );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  edit(record,isAddNew) {
    let dialog = this._dialog.open(SystemApiInfoComponent, {
      height: '430px',
      width: '55%',
      disableClose: true,
      data: record
    });
    dialog.afterClosed().subscribe((result) => {
      this.getAPIList();
    });
  }
  handlePageEvent(event: PageEvent) {
    this.dataSource.paginator.pageSize = event.pageSize;
    this.dataSource.paginator.pageIndex = event.pageIndex;
  }

}
