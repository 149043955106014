<div  class="container admin_user_page proflie-page" ng-cloak>
    <div class="row">
      <div class="col-md-12  drop-shadow bg-light">
    
          <h4>Tenants</h4>
          <p><em>Tenants can be managed from here. </em></p>
  
          <div class="instructions_cl">
  
              <div class="pull-right addbutton">
                  <button mat-button color="primary" mat-raised-button (click)="openEditModal()">Add New</button>      
              </div>
  
           <strong>Instructions:</strong>
           <ol>
            <li>Add a tenant by clicking the "Add New" button on the right.</li>
            <li>Tenant may be edited by clicking on the "Edit" button in the table row.</li>
            <li>From the edit tenant form you may "Delete" the tenant.</li>
        </ol>    
        </div>
        <div class="col-md-12 table-responsive">
            <mat-form-field color="accent" appearance="outline">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
            </mat-form-field>

            <div>
                <mat-table class="mat-elevation-z8" [dataSource]="dataSource" fxFlex="100%" matSort>
              
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
                  </ng-container>
              
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Tenant Name </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
                  </ng-container>
              
                  <ng-container matColumnDef="created_by">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Created By </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.created_by }} </mat-cell>
                  </ng-container>
              
                  <ng-container matColumnDef="created_ts">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Created On </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.created_ts | date}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="last_updated_by">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Updated By </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.last_updated_by}} </mat-cell>
                  </ng-container>
              
                  <ng-container matColumnDef="last_updated_ts">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Updated On </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.last_updated_ts | date}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
                    <mat-cell *matCellDef="let row"><button mat-button mat-raised-button 
                        (click)="openEditModal(row.id)" class="pull-right">Edit</button></mat-cell>
                  </ng-container>
              
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              
                  <!-- Row shown when there is no matching data. -->
                  <mat-row *matNoDataRow>
                    <mat-cell colspan="7">No data matching the filter "{{input.value}}"</mat-cell>
                  </mat-row>
                </mat-table>
              
                <mat-paginator [pageSizeOptions]="[10,25,50,100]"></mat-paginator>
            </div>
        </div>
      </div> 
    </div>
  </div>
    