// import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { error } from 'selenium-webdriver';
import { forEachChild } from 'typescript';
import { environment } from '../../../environments/environment';
import { DegreeType } from '../../Models/degree-type';
import { CompanyEmployer, Employer } from '../../Models/EmployerRegistration';
import { OpportunityImport } from '../../Models/Opportunity';
import { OpportunityType } from '../../Models/opportunity-type';
import { AdminApiService } from '../../Services/admin-api.service';
import { OpportunityService } from '../../Services/opportunity.service';
import { TalentApiService } from '../../Services/talent-api.service';

export const _filter = (opt: Employer[], value: string): Employer[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(function (item) {
    return (item.name.toLowerCase().includes(filterValue)
      || item.email.toLowerCase().includes(filterValue))
  });
};


@Component({
  selector: 'app-import-bulk-opportunities',
  templateUrl: './import-bulk-opportunities.component.html',
  styleUrls: ['./import-bulk-opportunities.component.css']
})
export class ImportBulkOpportunitiesComponent implements OnInit {
  public bulkImportForm: UntypedFormGroup;
  public skillsList = [];
  public opportunityList: OpportunityImport[] = [];
  public degreeTypes: DegreeType[];
  public workSetup: any;
  public opportunityTypes: OpportunityType[];
  isCSVInvalid: boolean = false;
  isValidEmp:boolean = false;
  validationMessage: string[] = [];
  csvFileName = '';
  headersRow: any;
  selectedEmployer: number = 0;
  csvSuccessMessage: string[] = [];
  csvErrorMessage: string = '';
  employerListOption: Observable<CompanyEmployer[]>;
  employerList: CompanyEmployer[] = [];
  public file;
  public postingColumns = ["Title", "Positions", "StartDate", "ExpirationDate", "Locations"];
  public postingColumnIndex = {
    Title: 0,
    Positions: 1,
    StartDate: 2,
    ExpirationDate: 3,
    Locations: 4,
    JobDetails: 5,
    WorkSetup: 6,
    Compensation_min: 7,
    Compensation_max: 8,
    RequiredSkills: 9,
    Education: 10,
    JobSummary: 11,
    Benefits: 12
  };

  constructor(private _adminApiService: AdminApiService,
    private _router: Router,
    private _builder: UntypedFormBuilder,
    private _opportunitService: OpportunityService,
    private _talentApiService: TalentApiService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.getEmployerList();
    this.getSkillsList();
    this.getOpportunityTypes();
    this.getDegreeTypes();
    this.getWorkSetup();
    
  }
  buildForm() {
    this.bulkImportForm = this._builder.group({
      empEmail: ['', Validators.required]
    });
  }

  getDegreeTypes() {
    this._opportunitService.getDegreeTypes().subscribe((result: DegreeType[]) => {
      if (result.length > 0) {
        this.degreeTypes = result;
      }
    },
      error => {
        console.log(error);
      }
    );
  }

  getWorkSetup() {
    this._opportunitService.GetWorkSetupData().subscribe((result: any) => {
      if (result.length > 0) {
        this.workSetup = result;
      }
    });
  }
  getOpportunityTypes() {
    this._opportunitService.GetCommitmentData().subscribe((result: OpportunityType[]) => {
      if (result.length > 0) {
        this.opportunityTypes = result;
      }
    },
      error => {
        console.log(error);
      }
    );
  }

  getSkillsList() {
    this._talentApiService.getSkills()
      .subscribe((result: any) => {
        this.skillsList = result;
      },
        error => {
          console.log('Unable to load skills list');
        }
      );
  }
  getEmployerList() {
    this._adminApiService.getEmployerList().subscribe((result: CompanyEmployer[]) => {
      if (result != null) {
        this.employerList = result;
        this.employerListOption = this.bulkImportForm.get('empEmail')!.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterGroup(value))
          );
      }
    });
  }

  isValidCSVFile(sizeInKB, fileName) {   
    var fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (!(fileExtension.toLowerCase() === "csv")) {
      this._talentApiService.snackbarMessage('File must be in csv format');
      return false;
    }
    if (Number(sizeInKB) > Number(environment.POSTING_CSV_MAXSIZE_KB)) {
      this._talentApiService.snackbarMessage('File must be less than '
        + (environment.POSTING_CSV_MAXSIZE_KB / 1024).toFixed(0) + ' MB.');
      return false;
    }
    return true;
  }

  fileChangeEvent ($event:any):void {
    this.resetValidation();
    this.file = $event.target;
    this.csvFileName = this.file.files[0].name;
  }

  fileUpload() {
    let csvReader = new FileReader();
    if(!this.file.files[0]) {
      return;
    }
    csvReader.readAsText(this.file.files[0]);
    var sizeInKB = (this.file.files[0].size / 1024).toFixed(0);
    
    if (!this.isValidCSVFile(sizeInKB, this.csvFileName)) {
      this.resetValidation();
      return;
    }     
    csvReader.onload = () => {
      var that = this;
      let csvData = csvReader.result;
      let csvPostingArray = (<string>csvData).split(/\r\n/);
      that.readCSV(csvPostingArray);
    };
  }
  resetValidation() {
    this.isCSVInvalid = false;
    this.csvSuccessMessage = [];
    this.validationMessage = [];
    this.csvFileName = '';
    this.opportunityList= [];
  }

  readCSV(csvPostingArray) {
    this.headersRow = this.getHeaders(csvPostingArray);
    var allowedHeader = ["JOBTITLE", "POSITIONS", "STARTDATE", "EXPIRATIONDATE", "LOCATIONS", "JOBDETAILS", "WORKSETUP", "COMPENSATION_MIN", "COMPENSATION_MAX", "REQUIREDSKILLS", "EDUCATION", "JOBSUMMARY", "BENEFITS"];
    for (var i = 0; i < this.headersRow.length; i++) {
      if (allowedHeader.filter(x => x == this.headersRow[i].toUpperCase()).length == 0) {
        this.validationMessage.push("Invalid csv headers found. Please re-upload.");
        this.isCSVInvalid = true;
        this.csvFileName = '';
        break;
      }
    }
    if (!this.isCSVInvalid) {
      this.opportunityList = this.getPostingFromCSVFile(csvPostingArray, this.headersRow.length);
    }    
    if (this.opportunityList && this.opportunityList.length > 0) {
      this.submitCSV();
    }
  }

  isValidCSV(rowIndex, curRec) {
    if (curRec.length < 13) return true; // skip blank row    
    var result = true;
    var errorMsg = '';
    if (curRec[this.postingColumnIndex.Title] == '') {
      errorMsg = 'Posting Title cannot be blank on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Title].lenght < 3) {
      errorMsg = 'Posting Title cannot be less than 3 chars on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Title].length > 255) {
      errorMsg = 'Length of posting title cannot be greater than 255 chars on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Positions] == '') {
      errorMsg = 'Total Positions cannot be blank on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (isNaN(curRec[this.postingColumnIndex.Positions])) {
      errorMsg = 'Total Positions must be number on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Positions] <= 0) {
      errorMsg = 'Total Positions must be greater than 0 on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.StartDate] == '') {
      errorMsg = 'Start Date cannot be blank on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (!this.isValidSkill(curRec[this.postingColumnIndex.RequiredSkills])) {
      errorMsg = 'Incorrect required skills / experience years between 0 and 40 on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (!this.isValidWorkSetup(curRec[this.postingColumnIndex.WorkSetup])) {
      errorMsg = 'Incorrect work setup on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (!this.isValidOpportunityType(curRec[this.postingColumnIndex.JobDetails])) {
      errorMsg = 'Incorrect Job Details on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (!this.isValidDegreeType(curRec[this.postingColumnIndex.Education])) {
      errorMsg = 'Incorrect Education type on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (!this.isValidDate(curRec[this.postingColumnIndex.StartDate])) {
      errorMsg = 'Incorrect Start Date on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.ExpirationDate] != ''
      && !this.isValidDate(curRec[this.postingColumnIndex.ExpirationDate])) {
      errorMsg = 'Incorrect Expiration Date on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Locations] != ''
      && !this.isValidLocation(curRec[this.postingColumnIndex.Locations])) {
      errorMsg = 'Incorrect Location on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }

    if (curRec[this.postingColumnIndex.Compensation_min] == '') {
      errorMsg = 'Compensation_min value required on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Compensation_max] == '') {
      errorMsg = 'Compensation_max value required on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Education] == '') {
      errorMsg = 'Education cannot be empty on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.JobSummary] == '') {
      errorMsg = 'Job Summary cannot be empty on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Benefits] == '') {
      errorMsg = 'Benefits cannot be empty on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Compensation_min] != '' && isNaN(curRec[this.postingColumnIndex.Compensation_min])) {
      errorMsg = 'Compensation_min value must be a number on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (curRec[this.postingColumnIndex.Compensation_max] != '' && isNaN(curRec[this.postingColumnIndex.Compensation_max])) {
      errorMsg = 'Compensation_max value must be a number on row ' + rowIndex;
      this.validationMessage.push(errorMsg);
      errorMsg = '';
      result = false;
    }
    if (this.isValidDate(curRec[this.postingColumnIndex.StartDate])) {
      var startDate = Date.parse(curRec[this.postingColumnIndex.StartDate]);     
      var currentDate = Date.parse(new Date().toLocaleDateString());
      if (startDate < currentDate) {
        errorMsg = 'Start Date should be higer than or equal to current Date on row ' + rowIndex;
        this.validationMessage.push(errorMsg);
        errorMsg = '';
        result = false;
      }
    }
    if (this.isValidDate(curRec[this.postingColumnIndex.ExpirationDate])) {
      var startDate = Date.parse(curRec[this.postingColumnIndex.StartDate]);
      var expirationDate = Date.parse(curRec[this.postingColumnIndex.ExpirationDate]);
      if (expirationDate < startDate) {
        errorMsg = 'Expiration Date should be higer than or equal to Start Date on row ' + rowIndex;
        this.validationMessage.push(errorMsg);
        errorMsg = '';
        result = false;
      }      
    }
    if (curRec[this.postingColumnIndex.Compensation_max] != ''
      && curRec[this.postingColumnIndex.Compensation_min] != '') {
      var max = parseInt(curRec[this.postingColumnIndex.Compensation_max]);
      var min = parseInt(curRec[this.postingColumnIndex.Compensation_min]);
      if (max < min) {
        errorMsg = 'Compensation_max value must be greater than Compensation_min on row ' + rowIndex;
        this.validationMessage.push(errorMsg);
        errorMsg = '';
        result = false;
      }
    }
    return result;
  }
  isValidDate(val: any) {
    var timestamp = Date.parse(val);
    if (isNaN(timestamp) == false) {
      var d = new Date(timestamp);
      return true;
    }
    return false;
  }

  processRow(strRaw) {
    let strRawNew = '';
    let flag = 0
    for (let ch of strRaw) {
      if (ch === '"' && flag === 0) {
        flag = 1
      }
      else if (ch === '"' && flag == 1) flag = 0
      if (ch === ',' && flag === 0) {
        ch = '<->'; // new splitter
      }
      if (ch !== '"') { strRawNew += ch }
    }

    // Split the string using new delimiter <->
    // and store the values in a properties array
    return strRawNew.split("<->")
  }
  selectEmployer(evt) {
    this.isValidEmp = true;
    this.bulkImportForm.value.empEmail = evt; //this.selectedEmployer = evt.id;
    this.resetValidation();
  }

  getEmpName(empId: string) {
    for (var i = 0; i < this.employerList.length; i++) {
      var emp = this.employerList[i].employers.filter(x => x.email === empId);
      if (emp != null && emp?.length > 0) {
        return emp[0].name;
      }
    }
  }

  checkEmp(empValue) {
    let inputValue = empValue.target.value;
    for (var i = 0; i < this.employerList.length; i++) {
      var emp = this.filterEmployerList(this.employerList[i].employers,inputValue);
      if (emp != null && emp?.length > 0) {
        this.isValidEmp = true;
        break;
      } else {
        this.isValidEmp = false;
      }
    }
  }
  filterEmployerList(array, string) {
    return array.filter(o =>
    Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
}


  getPostingFromCSVFile(csvRecordsArray: string[], headerLength: any): OpportunityImport[] {
    this.isCSVInvalid = false;
    for (let row = 1; row < csvRecordsArray.length; row++) {
      let curruntRecord = this.processRow(<string>csvRecordsArray[row]);
      this.isValidCSV(row, curruntRecord);

      if (this.validationMessage.length >= 10) {
        this.isCSVInvalid = true;
        this.csvFileName = '';
        this.validationMessage = [];
        this.validationMessage.push('Too many errors, please correct CSV file.');
        return;
      }
    }
    if (this.validationMessage.length > 0) {
      this.isCSVInvalid = true;
      this.csvFileName = '';
      return
    }

    for (let row = 1; row < csvRecordsArray.length; row++) {
      try {
        let curRec = this.processRow(<string>csvRecordsArray[row]);
        curRec[this.postingColumnIndex.ExpirationDate] = curRec[this.postingColumnIndex.ExpirationDate] == '' ? null:curRec[this.postingColumnIndex.ExpirationDate];
        let opportunity: OpportunityImport = {
          id: '0',
          title: curRec[this.postingColumnIndex.Title],
          startDate: new Date(curRec[this.postingColumnIndex.StartDate]),
          postingdate: (new Date()),
          opportunity_types: this.getOpportunityTypesId(curRec[this.postingColumnIndex.JobDetails]),
          skills: this.getSkillIds(curRec[this.postingColumnIndex.RequiredSkills]),
          locations: this.getLocations(curRec[this.postingColumnIndex.Locations]),
          workSetup: this.getWorkSetupId(curRec[this.postingColumnIndex.WorkSetup]),
          degree_type: this.getDegreeTypesId(curRec[this.postingColumnIndex.Education]),
          quantity: curRec[this.postingColumnIndex.Positions],
          compensationhigh: curRec[this.postingColumnIndex.Compensation_max],
          compensationlow: curRec[this.postingColumnIndex.Compensation_min],
          endDate: new Date(curRec[this.postingColumnIndex.ExpirationDate]),
          benefits: curRec[this.postingColumnIndex.Benefits],
          description : curRec[this.postingColumnIndex.JobSummary],
        };
        this.opportunityList.push(opportunity);
      } catch (err) {
        console.log('Error on row number ' + row + ' :' + err);
      }
    }
    return this.opportunityList;
  }

  submitCSV() {
    var data = { employerEmail: this.bulkImportForm.value.empEmail, postings: this.opportunityList }
    console.log(this.opportunityList);

    this._adminApiService.importBulkOpportunities(data).subscribe((result) => {
      if (result != null) {
        this.csvSuccessMessage = result['msgs'];
        this.opportunityList = [];
        this.csvFileName = '';
      }
    });
  }

  getOpportunityTypesId(ot: string) {
    var otIds = [];
    var opportunityTypeArray = ot.split('|');
    for (var i = 0; i < opportunityTypeArray.length; i++) {
      otIds.push(parseInt(this.opportunityTypes.filter(x => x.type == opportunityTypeArray[i])[0].id,10));
    }
    return otIds;
  }

  getDegreeTypesId(dt: string) {
    return parseInt(this.degreeTypes.filter(x => x.name == dt)[0].id, 10);
  }
  getWorkSetupId(ws) {
    var wsIds = [];
    var workSetupArray = ws.split('|');
    for (var i = 0; i < workSetupArray.length; i++) {
      wsIds.push(this.workSetup.filter(x => x.name == workSetupArray[i])[0].id);
    }
    return wsIds;
  }

  getHeaders(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }  

  private _filterGroup(value: string): CompanyEmployer[] {
    if (value) {
      return this.employerList
        .map(emp => ({ companyName: emp.companyName, employers: _filter(emp.employers, value) }))
        .filter(emp => emp.employers.length > 0);
    }
    return this.employerList;
  }

  getLocations(loc) {
    var locs = [];
    if (loc == '') return locs;
    var locArray = loc.split('|');
    for (var i = 0; i < locArray.length; i++) {
      var l = locArray[i].split(',');
      if (l.length > 2) {
        locs.push({ address1: '', city: l[0], state: l[1], zip: '' });
      }
    }
    return locs;
  }

  isValidLocation(loc) {
    if (loc == '') return true;
    var locArray = loc.split('|');
    for (var i = 0; i < locArray.length; i++) {
      if (locArray[i].split(',').length != 3) {
        return false; break;
      }
    }
    return true;
  }

  isValidOpportunityType(ot) {
    if (ot == '') return true;
    var opportunityTypeArray = ot.split('|');
    for (var i = 0; i < opportunityTypeArray.length; i++) {
      if (this.opportunityTypes.filter(x => x.type == opportunityTypeArray[i]).length == 0) {
        return false; break;
      }
    }
    return true;
  }

  isValidWorkSetup(ws) {
    if (ws == '') return true;
    var workSetupArray = ws.split('|');
    for (var i = 0; i < workSetupArray.length; i++) {
      if (this.workSetup.filter(x => x.name == workSetupArray[i]).length == 0) {
        return false; break;
      }
    }
    return true;
  }
  isValidDegreeType(dg) {
    if (dg == '') return true;
      if (this.degreeTypes.filter(x => x.name == dg).length == 0) {
        return false; 
      }
    return true;
  }
  getSkillIds(skills) {
    var wsIds = [];
    var skilllArray = skills.split('|');
    for (var i = 0; i < skilllArray.length; i++) {
      if (skilllArray[i].indexOf(':') <= 0) { continue; }
      var skillAndExp = skilllArray[i].split(':');
      wsIds.push({ name: skillAndExp[0], expYears: skillAndExp[1] });
    }
    return wsIds;
  }
  isValidSkill(skills) {
    if (skills == '') return true;
    var skilllArray = skills.split('|');
    for (var i = 0; i < skilllArray.length; i++) {
      if (skilllArray[i].indexOf(':') <= 0) { return false; }
      var skillAndExp = skilllArray[i].split(':');
      if (this.skillsList.filter(x => x.name == skillAndExp[0]).length == 0) {
        return false; break;
      }
      if (isNaN(skillAndExp[1])) return false;
      if( skillAndExp[1] < 0 || skillAndExp[1] > 40) {
        return false;
      }
    }
    return true;
  }
}
