<div class="candidate-registration full-page-registration">
	<div fxLayout="row" fxLayoutAlign="center center">
		<div fxLayout="column" fxFlex="100">
			<div fxLayout="row" fxFlexOffset="30px" fxLayoutAlign="center center">
				<div fxLayout="column" fxFlex="80">
					<mat-progress-bar *ngIf="step!=1" mode="determinate" color="primary" [value]="step * 7.69"></mat-progress-bar>
					<mat-card class="content-part reg-card" [ngClass]="{'darker-right-side' : step==1}">
						<div fxLayout="row" fxFlex="grow" fxLayoutAlign="center stretch">
							<div fxLayout="column" fxFlex="10" *ngIf="!isProfileLoaded">
								<mat-spinner></mat-spinner>
							</div>
							<div fxLayout="column" fxFlex="100" *ngIf="isProfileLoaded">
								<app-candidate-resume fxFill [resume]="candidateProfile.resume" *ngIf="step == 1" (saveProfile)="next($event, 'resume')"></app-candidate-resume>
								<app-candidate-info [candidateInfo]="candidateProfile.candidateInfo" *ngIf="step == 2" (saveProfile)="next($event, 'candidateInfo')" (prevEvent)="prev($event)"></app-candidate-info>
								<app-candidate-location [location]="candidateProfile.location" *ngIf="step == 3" (saveProfile)="next($event, 'location')" (prevEvent)="prev($event)"></app-candidate-location>
								<app-candidate-availability-status [availabilityStatus]="candidateProfile.availabilityStatus" *ngIf="step == 4" (prevEvent)="prev($event)" (saveProfile)="next($event, 'availabilityStatus')"></app-candidate-availability-status>
								<app-candidate-sector [sectorTitle]="candidateProfile.sectorTitle" *ngIf="step == 5" (saveProfile)="next($event,'sectorTitle')" (prevEvent)="prev($event)"></app-candidate-sector>
								<app-candidate-availability-comp [availabilityComp]="candidateProfile.availabilityComp" *ngIf="step == 6" (saveProfile)="next($event, 'availabilityComp')" (prevEvent)="prev($event)"></app-candidate-availability-comp>
								<app-candidate-education [education]="candidateProfile.education" *ngIf="step == 7" (saveProfile)="next($event, 'education')" (prevEvent)="prev($event)"></app-candidate-education>
								<app-candidate-certifications [candidateCertifications]="candidateProfile.candidateCertifications" *ngIf="step == 8" (saveProfile)="next($event, 'candidateCertifications')" (prevEvent)="prev($event)"></app-candidate-certifications>
								<app-candidate-work-experience [workExperience]="candidateProfile.workExperience" *ngIf="step == 9" (saveProfile)="next($event,'workExperience')" (prevEvent)="prev($event)"></app-candidate-work-experience>
								<app-candidate-skills [candidateSkills]="candidateProfile.candidateSkills" *ngIf="step == 10" (saveProfile)="next($event, 'candidateSkills')" (prevEvent)="prev($event)"></app-candidate-skills>
								<app-candidate-work-environment [workEnvironment]="candidateProfile.workEnvironment" *ngIf="step == 11" (saveProfile)="next($event, 'workEnvironment')" (prevEvent)="prev($event)"></app-candidate-work-environment>
								<app-candidate-link-contacts [linkContacts]="candidateProfile.linkContacts" *ngIf="step == 12" (prevEvent)="prev($event)" (saveProfile)="next($event, 'linkContacts')"></app-candidate-link-contacts>
								<app-candidate-diversity-questions [diversityQuestion]="candidateProfile.diversityQuestion" *ngIf="step == 13" (prevEvent)="prev($event)" (saveProfile)="next($event, 'diversityQuestion')"></app-candidate-diversity-questions>
								<app-candidate-email-verification *ngIf="step == 14"></app-candidate-email-verification>
							</div>
						</div>
					</mat-card>
				</div>
			</div>
			<div fxLayout="row" fxFlexOffset="30px"></div>
		</div>
	</div>
</div>
