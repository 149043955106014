import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Themes } from '../Models/TenantsTheme';


@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private render: Renderer2;
  private theme: string = Themes.defaultTheme;
  constructor(private renderFactory: RendererFactory2) { 
    this.render = this.renderFactory.createRenderer(null,null);
    this.render.addClass(document.body, this.theme);
  }

  setTheme(themeToSet) {
    // remove the current theme
    this.render.removeClass(document.body, this.theme);
    // apply a new theme
    this.render.addClass(document.body, themeToSet);
  }

  setDefaultTheme(){
    //we'll want to use logic that removes any theme (regex matcher)
    this.render.removeClass(document.body, Themes.altTheme); 
    this.render.addClass(document.body, Themes.defaultTheme);
  }

  setAltTheme(){
    //we'll want to use logic that removes any theme
    this.render.removeClass(document.body, Themes.defaultTheme); 
    this.render.addClass(document.body, Themes.altTheme);
  }


}