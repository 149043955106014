import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from '../../../Pages/dialogs/common-confirm-popup/common-confirm-popup.component';
import { AdminApiService } from '../../../Services/admin-api.service';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
  selector: 'app-system-api-info',
  templateUrl: './system-api-info.component.html',
  styleUrls: ['./system-api-info.component.css']
})
export class SystemApiInfoComponent implements OnInit {

  isAddNew = false;
  editing: any;
  modalInEditMode = false;
  apiForm: UntypedFormGroup;
  isMobileDevice: Boolean = false;
  permissionList: any = [];

  constructor(public _dialog: MatDialog, private _adminApiService: AdminApiService,
    private dialogRef: MatDialogRef<SystemApiInfoComponent>,
    private _talentApiService: TalentApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public apiInfo: any,
    private _builder: UntypedFormBuilder) {

    if (apiInfo == null) {
      this.isAddNew = true;
    }
    else {
      this.editing = apiInfo;
    }
  }

  ngOnInit(): void {
    this.getPermissionList();
    this.buildForm();
    if (this.apiInfo != null) {
      this.editFillForm();
    }
  }

  getPermissionList() {
    this._adminApiService.getGlobalPermissionList()
      .subscribe(result => {
        if (result != null) {       
          this.getPermissionList = result;
        }
        else {
          this._talentApiService.snackbarMessage('Something went wrong!');
        }
      });
  }

  buildForm() {
    this.apiForm = this._builder.group({
      id: 0,
      apiname: ['', Validators.required],
      permissions: this._builder.array([])
    });
  }

  checkPermission(item) {
    if (!this.isAddNew) {
      if (this.editing.permissions != undefined) {
        var result = this.editing.permissions.filter(o => o === item.Id);
        if (result != null && result != undefined && result.length > 0) {
          return true;
        }
      }      
    }
    return false;
  }

  editFillForm() {
    this.apiForm.patchValue({
      id: parseInt(this.editing.Id),
      apiname: this.editing.name
    });
  }
  get frm() {
    return this.apiForm.controls;
  }
  closeDialog() {
    this.dialogRef.close();
  }

  permissionValue(data: any, isChecked: boolean) {    
    const permissionsFormArray = <UntypedFormArray>this.apiForm.controls.permissions;
    if (isChecked) {
      permissionsFormArray.push(new UntypedFormControl(data.Id));     
    } else {
      const index = permissionsFormArray.controls.findIndex(x => x.value === data.Id);
      permissionsFormArray.removeAt(index);      
    }

  }

  delete() {
    let dialog;
    if (!dialog == null) {
    } else {
      dialog = this._dialog.open(CommonConfirmPopupComponent, {
        height: '175',
        width: this.isMobileDevice ? '80%' : '36.66%',
        disableClose: false,
      });
      dialog.componentInstance.post_type = 'System-API';
      dialog.componentInstance.confirm_message = 'Are you sure you want to delete this record?';
      dialog.componentInstance.discardConfirm = true;
    }
    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this._adminApiService.deleteAPI(this.apiForm.value.id)
            .subscribe(
              (result: any) => {
                if (result) {
                  this._talentApiService.snackbarMessage('Selected API delete successfully!');
                  this._dialog.closeAll();
                } else {
                  this._talentApiService.snackbarMessage(result.msg);
                }
              },
              error => {
                this._talentApiService.snackbarMessage('Unable to delete this api!');
              }
            );
        } else {
        }
      });
  }
  submit() {
    if (this.isAddNew) {
      this._adminApiService.createAPI(this.apiForm.value)
        .subscribe((result: any) => {
          this._talentApiService.snackbarMessage('New API added successfully!');
          this.closeDialog();
        }, error => {
          this._talentApiService.snackbarMessage('Unable to save api!');
        });
    }
    else {
      this._adminApiService.editAPI(this.apiForm.value)
        .subscribe((result: any) => {
          this._talentApiService.snackbarMessage('API updated successfully!');
          this.closeDialog();
        }, error => {
          this._talentApiService.snackbarMessage('Unable to save api!');
        });
    }
  }
}
