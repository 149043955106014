import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AdminApiService } from '../../Services/admin-api.service';
import { ActivatedRoute,Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { ConfigEditComponent } from '../../Admin/dialogs/config-edit/config-edit.component';

@Component({
  selector: 'app-admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.css']
})
export class AdminConfigComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  configs:any;
  list={};

  constructor(private _adminApiService:AdminApiService,private _router: Router,public _dialog: MatDialog,private route: ActivatedRoute) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      order: [0, 'asc'],
      columnDefs: [
       {
        "targets": [ 5 ],
        "searchable": false,
        "orderable": false
       }
      ]
    };
    this.getConfigVariables();  
  }

  getConfigVariables(){

    $('#studentdatatables').DataTable().destroy();

   
    this._adminApiService.urlConfigVariableList()
      .subscribe(
        (result:any) => {
            this.configs = result;
          this.dtTrigger.next({});
            var self=this;
            this.configs.map((element, i) => {
               self.list[element.id] = element;
            });
        },
        error => {
            console.log('Could not load Config Variables');
        }
      );
  }

  openEditModal(id=''){
    let dialog = this.openDialog();
    let isReloadData = false;   

    let editData = Object.assign({}, this.list[id]);
    
    
    dialog.componentInstance.editing = editData;
    dialog.componentInstance.isReloadData = isReloadData;

    dialog.afterClosed()
    .subscribe((result) => {
      if(dialog.componentInstance.isReloadData){
        this.getConfigVariables();
      }        
    });
    
  }

  openDialog(){
    let dialog = this._dialog.open(ConfigEditComponent,{
		  height: 'auto',
    });
    return dialog;
  } 

}
