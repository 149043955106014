import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatAccordion } from "@angular/material/expansion";
import { Opportunity } from "../../Models/Opportunity";
import { OpportunityService } from "../../Services/opportunity.service";

@Component({
  selector: "app-public-postings",
  templateUrl: "./public-postings.component.html",
  styleUrls: ["./public-postings.component.css"],
})
export class PublicPostingsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public opportunityList: Opportunity[] = [];
  public customCollapsedHeight: string = "100px";
  viewAllPublicPostings = false;
  panelOpenState: {[key:string]: boolean} = {};
  companyName: string = "";
  hasPostings = undefined;

  constructor(
    private route: ActivatedRoute,
    private _opportunityService: OpportunityService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.companyName != null && params.companyName != "") {
        this.viewAllPublicPostings = !!(
          params.companyName.toString().toLowerCase() === "all"
        );
        this.companyName = params.companyName;
        this.getOpportunities(this.companyName);
      }
    });
  }

  getPostingDay(postingdate) {
    const diffInMs = Math.abs(<any>new Date() - <any>new Date(postingdate));
    const daysdiff = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    if (daysdiff == 0) {
      postingdate = "Today";
    }
    if (daysdiff == 1) {
      postingdate = "Yesterday";
    }
    if (daysdiff > 1) {
      postingdate = daysdiff + "d Ago";
    }
    return postingdate;
  }

  expandAll() {
    this.accordion.openAll();
  }

  collapseAll() {
    this.accordion.closeAll();
  }

  getOpportunities(companyName) {
    this._opportunityService
      .getPublicPostings(companyName)
      .subscribe((result: any) => {
        if (result["postings"].length) {
          this.hasPostings = true;
          this.opportunityList = result["postings"];
        } else {
          this.hasPostings = false;
        }
      });
  }
}
