import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../Pages/profile-edit/profile-edit.component';

import { CandidateLinkContacts } from '../../../Models/CandidateProfile'
import { TalentApiService } from '../../../Services/talent-api.service';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';

@Component({
  selector: 'app-additional-contacts-view',
  templateUrl: './additional-contacts-view.component.html',
  styleUrls: ['./additional-contacts-view.component.css']
})
export class AdditionalContactsViewComponent implements OnInit {
  @Input() ownProfile: boolean;
  @Input() additionalContacts:CandidateLinkContacts;

  public logos:any[] = [];

  constructor(private _talentApiService:TalentApiService,
    public _dialog: MatDialog,
    private sharedUtils: SharedUtilitiesService) { }

  ngOnInit(): void {}

  openEdit() {
    let dialog = this._dialog.open(ProfileEditComponent,{
      height: 'auto',
      width: '80%',
      disableClose: true,
      data: {
              subComponent: 'candidate-link-contacts',
              seedData: this.additionalContacts
            }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        if (result == null) {
        } else {
          console.log(this.additionalContacts);
          this.additionalContacts = result;
        }
      });   
  }

}
