import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../../Services/admin-api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
  selector: 'app-tenant-edit',
  templateUrl: './tenant-edit.component.html',
  styleUrls: ['./tenant-edit.component.css']
})
export class TenantEditComponent implements OnInit {
  editing: any;
  public isReloadData = false;

  constructor(private _adminApiService: AdminApiService,
	private dialogRef: MatDialogRef<TenantEditComponent>,
	private _talentApiService: TalentApiService) { }

  ngOnInit(): void {
  }

  closeDialog() {
	this.dialogRef.close();
  }

  modalAddNew() {
		if (this.editing && this.editing.name) {

		this._adminApiService.createTenant(this.editing)
			.subscribe(
			(result: any) => {
				this._talentApiService.snackbarMessage('Tenant \'' + this.editing.name + '\' has been created.');
				this.isReloadData = true;
				this.dialogRef.close();

			},
			error => {
				this._talentApiService.snackbarMessage(error.msg);
			}
			);
		} else {
			this._talentApiService.snackbarMessage('Invalid Tenant. Please check your parameters and try again.');
		}
  }

  modalDelete() {
	if (confirm('Are you sure you want to want to delete this Tenant?')) {
		this._adminApiService.deleteTenant(this.editing.id)
		.subscribe(
		(result: any) => {
			this._talentApiService.snackbarMessage('Tenant \'' + this.editing.name + '\' has been deleted.');
			this.isReloadData = true;
			this.dialogRef.close();
		},
		error => {
			this._talentApiService.snackbarMessage(error.msg);
		}
		);
	}
  }

  modalSave() {
	if (this.editing && this.editing.id && this.editing.name) {
		this._adminApiService.editTenant(this.editing)
		.subscribe(
			(result: any) => {
			this._talentApiService.snackbarMessage('Tenant \'' + this.editing.name + '\' has been saved.');
			this.isReloadData = true;
			this.dialogRef.close();
			},
			error => {
			this._talentApiService.snackbarMessage(error.msg);
			}
		);
		} else {
			this._talentApiService.snackbarMessage('Invalid Tenant. Please check your parameters and try again.');
		}
  }
}
