import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { AdminApiService } from '../../Services/admin-api.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailTemplateEditComponent } from '../dialogs/email-template-edit/email-template-edit.component';

@Component({
  selector: 'app-admin-email-template',
  templateUrl: './admin-email-template.component.html',
  styleUrls: ['./admin-email-template.component.css']
})
export class AdminEmailTemplateComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['id', 'template_name', 'sender', 'sender_password', 'subject', 'body', 'html', 'last_updated_by', 'last_updated_ts', 'Edit', 'Delete'];
  dataSource: MatTableDataSource<any> = null;


  constructor(private _adminApiService: AdminApiService,
    public _dialog: MatDialog) { }

  ngOnInit(): void {
    this.generateTable();
  }

  createTemplate() {
    this.openDialog({type: 'create'});
  }

  editTemplate(template:any) {
    this.openDialog({type: 'edit', template: template})
  }

  deleteTemplate(template:any) {
    this.openDialog({type: 'delete', id: template.id});
  }

  openDialog(data){
    let dialog = this._dialog.open(EmailTemplateEditComponent,{
      data: data
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  generateTable() {
		this._adminApiService.getEmailTemplateList().subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
		},
			error => {
				console.log('Could not load News Sources');
			}
		);
	}

}
