import { Component, Input, OnInit } from "@angular/core";
import { OpportunityService } from "../../Services/opportunity.service";
import { PostingService } from "../../Services/posting.service";
import { RouterModule } from "@angular/router";

@Component({
  selector: "app-public-posting-details",
  templateUrl: "./public-posting-details.component.html",
  styleUrls: ["./public-posting-details.component.css"],
})
export class PublicPostingDetailsComponent implements OnInit {
  isLoading: boolean = true;
  isMobileDevice: boolean = false;
  userType: number = -1;
  @Input() postingId: number;
  public detail: any = null;
  public resultDisplay: boolean = true;

  constructor(
    private _postingService: PostingService,
    private _opportunityService: OpportunityService
  ) {}

  ngOnInit(): void {
    this.getPostingDetail(this.postingId);
  }

  getPostingDetail(id) {
    this.isLoading = true;
    this.detail = null;
    this._opportunityService.getPublicPostingDetails(id).subscribe(
      (result: any) => {
        if (result.error == 2) {
          this.isLoading = false;
          this.resultDisplay = false;
          return false;
        }
        this.detail = result;
        const diffInMs = Math.abs(
          <any>new Date() - <any>new Date(result.postingdate)
        );
        this.detail.postingDay = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        if (
          this.detail.company.website != "" &&
          this.detail.company.website.substring(0, 3) != "http"
        ) {
          this.detail.company.website = "http://" + this.detail.company.website;
        }
        this.isLoading = false;
        this.resultDisplay = true;
      },
      () => {
        this.isLoading = false;
        this.resultDisplay = false;
      }
    );
  }

  getWorkSetup() {
    if (this.isMobileDevice && this.detail.work_setup != null) {
      return this.detail.work_setup.toString().replaceAll(",", "\n") + "\n\n";
    } else if (this.detail.work_setup != null) {
      return this.detail.work_setup.toString().replaceAll(",", ",  ");
    }
    return this.detail.work_setup;
  }
}
