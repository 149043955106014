import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DegreeType } from '../../../Models/degree-type';
import { OpportunityType } from '../../../Models/opportunity-type';
import { OpportunityService } from '../../../Services/opportunity.service';
import { TalentApiService } from '../../../Services/talent-api.service';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { OpportunityDetailComponent } from '../../opportunity-detail/opportunity-detail.component';
import * as _ from 'underscore';
import { CommonConfirmPopupComponent } from '../common-confirm-popup/common-confirm-popup.component';
import { UserInfoService } from '../../../Services/user-info.service';
import { PostingService } from '../../../Services/posting.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { SkillTreeControlComponent } from '../../controls/skill-tree-control/skill-tree-control.component';
import { _isNumberValue } from '@angular/cdk/coercion';

@Component({
  selector: 'app-create-posting-dialog',
  templateUrl: './create-posting-dialog.component.html',
  styleUrls: ['./create-posting-dialog.component.css']
})

export class CreatePostingDialogComponent implements OnInit {
  postingForm: UntypedFormGroup;
  skillsList = [];
  compnayResults: any;
  companiesinfo: any;
  opportunityTypes: OpportunityType[];
  locationSearchResult = [];
  degreeTypes: DegreeType[];
  isFormSubmitted = false;
  locations: string[] = [];
  isMobileDevice: any = false;
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  workSetup: any;
  companyControl = new UntypedFormControl();
  searchSkillResult: Observable<string[]>;
  selectable = true;
  removable = true;
  logoInfo: any;
  compensationPayHighValidationStatus = false;
  compensationPayLowValidationStatus = false;
  public user: any;
  userType: number;
  postingId = 0;
  skillCtrl = new UntypedFormControl('', [Validators.maxLength(80)]);
  minPostingDate: Date = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
  public hasDeletePostingPermission: boolean = false;

  @ViewChild('autoschool', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;
  @ViewChild('skillInput') skillInput: ElementRef<HTMLInputElement>;



  constructor(public breakpointObserver: BreakpointObserver, public _dialog: MatDialog,
    private dialogRef: MatDialogRef<CreatePostingDialogComponent>,
    private _router: Router, private _talentApiService: TalentApiService,
    private _opportunitService: OpportunityService,
    public _builder: UntypedFormBuilder,
    private _userInfoService: UserInfoService,
    private _postingService: PostingService,
    private sharedUtils: SharedUtilitiesService,
    @Optional() @Inject(MAT_DIALOG_DATA) public editData: any) {

  }

  ngOnInit(): void {
    this._userInfoService.checkMyPermission('Delete Opportunity').subscribe((result: any) => {
      this.hasDeletePostingPermission = result;
    });
    this.setMinDateforCal();
    this.user = this._userInfoService.getUser();
    this.userType = parseInt(this.user.type);
    this.buildPostingForm();
    this.getSkillsList();
    this.getCompaniesInfo();
    this.getOpportunityTypes();
    this.getDegreeTypes();
    this.getWorkSetup();
    this.editFillForm();
    this.skillCtrl.setValue(null);
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileDevice = true;
        }
      });
  }

  setMinDateforCal() {
    this.minPostingDate = new Date();
    this.minPostingDate.setHours(0);
    this.minPostingDate.setMinutes(0);
    this.minPostingDate.setSeconds(0);
    this.minPostingDate.setMilliseconds(0);
  }

  editFillForm() {
    if (this.editData != null) {
      // Location set Edit Mode    
      const locationsArrayFormArray = <UntypedFormArray>this.postingForm.controls.locations;
      this.editData.locations.forEach(element => {
        if (element.address1 != null) {
          const value = element.address1 +
            (element.address1 ? ', ' : '') +
            element.city + (element.city ? ', ' : '') +
            element.state + element.zip;//displays in location textbox
          this.locations.push(value.trim());
          const obj = { 'city': element.city.trim(), 'state': element.state.trim(), 'address1': element.address1.trim(), 'zip': element.zip.trim() };
          locationsArrayFormArray.push(new UntypedFormControl(obj));
        }
      });

      // Skill set Edit Mode
      if (this.editData && this.editData.skills.length > 0) {
        for (let exp of this.editData.skills) {
          this.t.push(this._builder.group({
            id: [exp.id],
            name: [exp.name, [Validators.required, Validators.maxLength(100)]],
            expYears: [exp.expYears, [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]]
          }));
        }
        this.skillCtrl.setValue(null);
      }

      // Compnay Name Set Edit Mode
      this.companyControl.setValue(this.editData.company.name);
      this.postingId = parseInt(this.editData.id);
      this.postingForm.patchValue({
        id: parseInt(this.editData.id),
        title: this.editData.title,
        quantity: this.editData.quantity,
        company: this.editData.company,
        startDate: this.editData.startdate != null ? new Date(this.editData.startdate) : new Date(),
        endDate: this.editData.enddate != null && this.editData.enddate != "" ? new Date(this.editData.enddate) : null,
        description: this.editData.description,
        benefits: this.editData.benefits,
        compensationlow: this.editData.compensationlow,
        compensationhigh: this.editData.compensationhigh,
        degree_type: this.editData.fk_degreetype,
        skills: this.editData.skills,
        postingDay: 0,
        postingdate: (this.editData.postingdate != null && this.editData.postingdate != "") ? new Date(this.editData.postingdate) : null,
        locations: this.editData.locations,
        remote: this.editData.remote,
        opportunity_type: this.editData.opportunity_type,
      });

      //in case of edit post, allow user to till select previous (start date)
      this.minPostingDate = this.editData.startdate != null ? new Date(this.editData.startdate) : new Date();

    }
  }

  buildPostingForm() {
    this.postingForm = this._builder.group({
      id: 0, // Used for Preview oppertunity Details
      title: ['', Validators.required],
      quantity: [1, Validators.required],
      company: ['', Validators.required],
      locationCtrl: '',
      skillCtrl: '',
      startDate: [this.minPostingDate, [Validators.required]], // date
      endDate: [null], // date
      description: ['', Validators.required], // string
      benefits: ['', Validators.required],
      compensationlow: ['', Validators.required], // float
      compensationhigh: ['', Validators.required],
      opportunity_types: this._builder.array([]),
      degree_type: '',
      skills: new UntypedFormArray([]),
      workSetup: this._builder.array([]),
      skillArray: this._builder.array([]),
      postingDay: 0,
      postingdate: new Date(),
      locations: this._builder.array([]),
      remote: this._builder.array([]), // Use for Preview oppertunity Details
      opportunity_type: this._builder.array([]) // Use for Preview oppertunity Details
    });
  }

  get f() { return this.postingForm.controls; }
  get t() { return this.f.skills as UntypedFormArray; }

  isEmptyOrNull(val): boolean {
    if (val == undefined || val == null || val == '') {
      return true;
    }
    return false;
  }

  compensationValidation(highLow) {
    if (this.isEmptyOrNull(this.postingForm.value.compensationlow)
      || this.isEmptyOrNull(this.postingForm.value.compensationhigh)) {
      return;
    }

    if (parseInt(this.postingForm.value.compensationlow) > parseInt(this.postingForm.value.compensationhigh)) {
      if (highLow == 'High') {
        this.compensationPayHighValidationStatus = true;
        this.postingForm.controls['compensationhigh'].setErrors({ 'incorrect': true });
      }
      else {
        this.postingForm.controls['compensationlow'].setErrors({ 'incorrect': true });
        this.compensationPayLowValidationStatus = true;
      }
    }
    else {
      this.compensationPayHighValidationStatus = false;
      this.compensationPayLowValidationStatus = false;
      this.postingForm.controls['compensationlow'].setErrors(null);
      this.postingForm.controls['compensationhigh'].setErrors(null);
    }
  }

  addSkill(event): void {
    const value = (typeof (event) == 'object') ? event.target.value : event;
    if (value === '') return;

    const inSelectedList = this.t.value.filter(skill => skill.name.toLowerCase().indexOf(value) === 0);
    if (inSelectedList.length == 0) {
      const checkInList = this.skillsList.filter(skill => skill.name.toLowerCase().indexOf(value) === 0);
      if (checkInList.length > 0) {
        if ((checkInList[0].name || '').trim()) {
          this.t.push(this._builder.group({
            id: [checkInList[0].id.trim()],
            name: [checkInList[0].name.trim(), [Validators.required, Validators.maxLength(100)]],
            expYears: ['', [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]]
          }));
        }
      } else {
        if ((value || '').trim()) {
          this.t.push(this._builder.group({
            id: 0,
            name: [value.trim(), [Validators.required, Validators.maxLength(100)]],
            expYears: ['', [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]]
          }));
        }
      }
    }
    this.skillCtrl.setValue(null);
  }


  removeLocation(location: string): void {
    const index = this.locations.indexOf(location);
    if (index >= 0) {
      this.locations.splice(index, 1);
      const locationsArrayFormArray = <UntypedFormArray>this.postingForm.controls.locations;
      const index1 = locationsArrayFormArray.controls.findIndex(x => x.value == location);
      locationsArrayFormArray.removeAt(index1);
    }
  }

  getLocation(val: string) {
    if (val != null) {
      this._talentApiService.getLocation(val).subscribe(response => {
        this.locationSearchResult = response;
      });
    }
  }

  selectedLocation(event: MatAutocompleteSelectedEvent): void {
    const state = this.extractAddress(event.option.value.address_components, 'administrative_area_level_1');
    const city = this.extractAddress(event.option.value.address_components, 'locality');
    const street = this.extractAddress(event.option.value.address_components, 'street_number') + ' ' + this.extractAddress(event.option.value.address_components, 'route');
    const zipCode = this.extractAddress(event.option.value.address_components, 'postal_code');

    const obj = { 'city': city.trim(), 'state': state.trim(), 'address1': street.trim(), 'zip': zipCode.trim() };

    let locString = '';
    let locStringFlag = 0;
    if (city != '' && state != '') {
      locString = city + ', ' + state;
      locStringFlag = 1;
    } else if (city == '' && state != '') {
      locString = state;
      locStringFlag = 1;
    } else if (city != '' && state == '') {
      locString = city;
      locStringFlag = 1;
    }

    // check for city and state value flag and then insert city,state values into locations
    if (locStringFlag == 1) {
      // check for distinct value (insert if no record in the list)
      const index = this.locations.indexOf(event.option.viewValue);
      if (index < 0) {
        this.locations.push(event.option.viewValue);
        const locationsArrayFormArray = <UntypedFormArray>this.postingForm.controls.locations;
        locationsArrayFormArray.push(new UntypedFormControl(obj));
      }
    }
    this.locationInput.nativeElement.value = '';
    this.postingForm.patchValue({ locationCtrl: null });
    // this.postingForm.value.locationCtrl.setValue(null);
  }

  Skillselected(event: MatAutocompleteSelectedEvent): void {
    const inSelectedList = this.t.value.filter(skill => skill.name.indexOf(event.option.viewValue) === 0);
    if (inSelectedList.length === 0) {
      const checkInList = this.skillsList.filter(skill => skill.name.indexOf(event.option.viewValue) === 0);
      if (checkInList.length > 0) {
        if ((checkInList[0].name || '').trim()) {
          this.t.push(this._builder.group({
            id: [checkInList[0].id.trim()],
            name: [event.option.viewValue, [Validators.required, Validators.maxLength(100)]],
            expYears: ['', [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]]
          }));
        }
      }
    }
    this.skillCtrl.setValue(null);
  }

  removeSkillWithYear(index) {
    if (index !== -1) {
      this.t.removeAt(index);
    }
  }


  FilterSkill() {
    this.searchSkillResult = this.skillCtrl.valueChanges.pipe(
      startWith(null),
      map((skill: string | null) => skill ? this._filter(skill) : this.skillsList.slice())
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.skillsList.filter(skill => skill.name.toLowerCase().indexOf(filterValue) === 0);
  }

  opportunityValue(data: any, isChecked: boolean) {
    const opportunitytypeFormArray = <UntypedFormArray>this.postingForm.controls.opportunity_types;
    const opportunityArray = <UntypedFormArray>this.postingForm.controls.opportunity_type;
    if (isChecked) {
      opportunitytypeFormArray.push(new UntypedFormControl(data.id));
      opportunityArray.push(new UntypedFormControl(data.type));
    } else {
      const index = opportunitytypeFormArray.controls.findIndex(x => x.value === data.id);
      opportunitytypeFormArray.removeAt(index);
      opportunityArray.removeAt(index);
    }
    this.postingForm.markAsDirty();
  }

  getWorkSetup() {
    this._opportunitService.GetWorkSetupData().subscribe((result: any) => {
      if (result.length > 0) {
        const that = this;
        this.workSetup = result;
        this.workSetup.forEach(function (obj) {
          if (that.editData != null) {
            obj.completed = false;
            that.editData.work_setup.forEach(function (name) {
              if (obj.name == name) {
                const workSetupFormArray = <UntypedFormArray>that.postingForm.controls.workSetup;
                const workSetupArray = <UntypedFormArray>that.postingForm.controls.remote;
                workSetupFormArray.push(new UntypedFormControl(obj.id));
                workSetupArray.push(new UntypedFormControl(obj.name));
                obj.completed = true;
              }
            });
          } else {
            obj.completed = false;
          }
        });
      }
    });
  }

  workSetupValue(data: any, isChecked: boolean) {
    const workSetupFormArray = <UntypedFormArray>this.postingForm.controls.workSetup;
    const workSetupArray = <UntypedFormArray>this.postingForm.controls.remote;

    if (isChecked) {
      workSetupFormArray.push(new UntypedFormControl(data.id));
      workSetupArray.push(new UntypedFormControl(data.name));
    } else {
      const index = workSetupFormArray.controls.findIndex(x => x.value === data.id);
      workSetupFormArray.removeAt(index);
      workSetupArray.removeAt(index);
    }
    this.postingForm.markAsDirty();
  }

  getSkillsList() {
    this._talentApiService.getSkills()
      .subscribe(
        (result: any) => {
          this.skillsList = result;
          this.FilterSkill();
        },
        error => {
          console.log('Unable to load skills list');
        }
      );
  }


  companyQuerySearch(query) {
    if (query !== null && query !== '' && this.companiesinfo != null) {
      if (typeof query === 'string' || query instanceof String) {
        this.compnayResults = query ? this.companiesinfo.filter(this.createFilterForCompany(query)) : [];
        return this.compnayResults;
      } else if (typeof query === 'object' || query instanceof Object) {
        this.compnayResults = query.name ? this.companiesinfo.filter(this.createFilterForCompany(query.name)) : [];
        return this.compnayResults;
      }
    }
  }

  createFilterForCompany(query) {
    const lowercaseQuery = query.toLowerCase();
    return function filterFn(companiesinfo) {
      return (companiesinfo.name.toLowerCase().indexOf(lowercaseQuery) === 0);
    };
  }

  getCompaniesInfo() {
    this._talentApiService.getCompaniesInfo()
      .subscribe(
        (result: any) => {
          this.companiesinfo = result;
          if (this.editData == null) { // Fetch company when adding new post
            this.fetchSector();
          }
        },
        error => {
          console.log('Could not load getCompaniesInfo');
        }
      );
  }

  CompanyObject(value) {
    this.postingForm.controls.company.setValue(value);
  }

  checkCompanyObject(event) {
    const newc = _.findWhere(this.companiesinfo, { name: event.value });
    if (newc != null) {
    } else {
      const obj = { 'id': 0, 'name': event.value, 'city': null, 'state': null, 'street': null, 'zip': null };
      this.postingForm.controls.company.setValue(obj);
    }
  }

  getOpportunityTypes() {
    this._opportunitService.GetCommitmentData().subscribe((result: OpportunityType[]) => {
      if (result.length > 0) {
        const that = this;
        this.opportunityTypes = result;
        this.opportunityTypes.forEach(function (obj) {
          if (that.editData != null) {
            obj.completed = false;
            that.editData.opportunity_type.forEach(function (type) {
              if (obj.type == type) {
                const opportunitytypeFormArray = <UntypedFormArray>that.postingForm.controls.opportunity_types;
                const opportunityArray = <UntypedFormArray>that.postingForm.controls.opportunity_type;
                opportunitytypeFormArray.push(new UntypedFormControl(obj.id));
                opportunityArray.push(new UntypedFormControl(obj.type));
                obj.completed = true;
              }
            });
          } else {
            obj.completed = false;
          }
        });
      }
    },
      error => {
        console.log(error);
      }
    );
  }

  extractAddress(array, attr) {
    if (array && array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].types.indexOf(attr) !== -1) {
          return array[i].short_name;
        }
      }
    }
    return '';
  }


  getDegreeTypes() {
    this._opportunitService.getDegreeTypes().subscribe((result: DegreeType[]) => {
      if (result.length > 0) {
        this.degreeTypes = result;
      }
    },
      error => {
        console.log(error);
      }
    );
  }

  confirmDiscard() {

    //close dialog without user confirmation, if no any edits
    if (!this.postingForm.dirty) {
      this.dialogRef.close();
      return;
    }

    let dialog;
    var confirmMessage = 'Are you sure you want to discard this Posting?'
    if (this.postingId > 0) {
      confirmMessage = 'Are you sure you want to discard your edits?'
    }

    if (!dialog == null) {
    } else {
      dialog = this._dialog.open(CommonConfirmPopupComponent, {
        height: '175px',
        width: this.isMobileDevice ? '85%' : '36.66%',
        disableClose: false,
      });
      dialog.componentInstance.post_type = 'Posting';
      dialog.componentInstance.confirm_message = confirmMessage;
      dialog.componentInstance.discardConfirm = true;
    }
    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this.dialogRef.close();
        } else {

        }
      });
  }

  deletePosting() {
    let dialog;

    var message = 'Are you sure you want to delete this Posting?';
    if (this.editData.hired_count != undefined && _isNumberValue(this.editData.hired_count) && parseInt(this.editData.hired_count)>0) {
      message = 'Warning: This posting has ' + this.editData.hired_count + ' candidate(s) hired. Do you still want to delete this posting?';
    }
    else if (this.editData.applicants_count != undefined && _isNumberValue(this.editData.applicants_count) && parseInt(this.editData.applicants_count)>0) {
      message = 'Warning: This posting has ' + this.editData.applicants_count + ' applicant(s). Do you still want to delete this posting?';
    }

    if (!dialog == null) {
    } else {
      dialog = this._dialog.open(CommonConfirmPopupComponent, {
        height: '175',
        width: this.isMobileDevice ? '80%' : '36.66%',
        disableClose: false,
      });
      dialog.componentInstance.post_type = 'Posting';
      dialog.componentInstance.confirm_message = message;
      dialog.componentInstance.discardConfirm = true;
    }
    dialog.afterClosed()
      .subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this._opportunitService.deletePosting(this.postingId)
            .subscribe(
              (result: any) => {
                if (result) {
                  this._talentApiService.snackbarMessage('Posting delete successfully!');
                  this.dialogRef.close();
                  this._postingService.refreshPostingData.next({ id: 0, value: true });//Refresh posting list
                } else {
                  this._talentApiService.snackbarMessage(result.msg);
                  this.isFormSubmitted = false;
                }
              },
              error => {
                // this._talentApiService.snackbarMessage('Unable to post postings!');
                this.isFormSubmitted = false;
              }
            );
        } else {

        }
      });
  }

  preview() {
    const dialog = this._dialog.open(OpportunityDetailComponent, {
      height: '100%',
      width: this.isMobileDevice ? '100%' : '64%',
      data: this.postingForm.value
    });

    dialog.afterClosed().subscribe((result) => { });
  }

  openSkillDialog() {
    let data = { "isMobileDevice": this.isMobileDevice, "skillData": this.f.skills as UntypedFormArray };
    const dialog = this._dialog.open(SkillTreeControlComponent, {
      height: this.isMobileDevice ? '75%' : '64%',
      width: this.isMobileDevice ? '100%' : '54%',
      data: data
    });

    dialog.afterClosed().subscribe((newSkills) => {
      if (newSkills != undefined && newSkills.length >= 0) {
        this.t.clear();
        for (var i = 0; i < newSkills.length; i++) {
          this.t.push(this._builder.group({
            id: newSkills[i].id,
            name: [newSkills[i].name, [Validators.required, Validators.maxLength(100)]],
            expYears: [newSkills[i].exp, [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]]
          }));
        }
      }
    });
    return false;
  }

  fetchSector() {
    this._talentApiService.getEmployeeCompanySector()
      .subscribe(
        (result: any) => {
          if (result[0]) {
            let emp_company = this.companyQuerySearch(result[0]['company_name']);
            this.CompanyObject(emp_company[0]);
            this.companyControl.setValue(emp_company[0].name);
          }
        },
        error => {
          console.log('Could not load company and sector');
        }
      );
  }
  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  submit() {
    this.isFormSubmitted = true;
    if (this.postingForm.invalid) {
      this.markFormGroupTouched(this.postingForm);
      return false
    }
    this._talentApiService.postPosting(this.postingForm.value)
      .subscribe(
        (result: any) => {
          if (result) {
            var isNewPosting = false;
            if (this.postingForm.value.id == 0) {
              isNewPosting = true;
              this.postingForm.value.id = parseInt(result);
            }
            this._postingService.refreshPostingData.next({ id: this.postingForm.value.id, value: true, isNewPosting: isNewPosting }); //Refresh posting list
            this._talentApiService.snackbarMessage('Posting submitted successfully!');
            this.dialogRef.close({ isNewPosting: isNewPosting });
            this.dialogRef.afterClosed().subscribe(closeResult => { this._dialog.closeAll() });      // Close multiple dialogs - opens when creating posting from candidate invite tab      
          } else {
            this._talentApiService.snackbarMessage(result.msg);
            this.isFormSubmitted = false;
          }
        },
        error => {
          // this._talentApiService.snackbarMessage('Unable to post postings!');
          this.isFormSubmitted = false;
        }
      );
  }
}
