<div fxLayout="row wrap " fxLayoutGap="10px grid" fxLayoutAlign="start center" class="application-feed">  
  <div fxLayout="row" fxFlex="100" *ngFor="let candidate of candidates">
    <mat-card class="mat-elevation-z4 student-card student-card-small" (click)="getCandidateDetails(candidate.candidateId,candidate.has_applied,candidate.has_invited)">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="auto" fxLayoutGap="5px 5px" [ngClass]="{'matcardcandidatelogo': breakpoint == 1, 'logo': breakpoint != 1}">
          <img *ngIf="candidate.profilepic == null || candidate.profilepic == ''"
               [ngClass]="{'matcardcandidateimage': breakpoint == 1, 'mat-card-image': breakpoint != 1}"
               class="card-round-image" src="assets/img/User-blue-icon.png">
          <img *ngIf="candidate.profilepic != null && candidate.profilepic != ''"
               [ngClass]="{'matcardcandidateimage': breakpoint == 1, 'mat-card-image': breakpoint != 1}"
               class="card-round-image" src="{{candidate.profilepic}}">
        </div>
        <div fxFlex="auto">
          <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="70" fxFlex.xs="100">
              <div fxLayout="row" fxLayout="row wrap"  fxLayoutGap="5px 5px">
                <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                  <span class="mat-display-3">
                    {{candidate.name}}
                  </span>
                  <div fxLayout="row start" *ngIf="candidate.state != null && candidate.city != null">
                    <mat-card-subtitle>
                      <div fxLayout="row" class="candidates-location">
                        <mat-icon>room</mat-icon><span class="mat-body-2">{{candidate.city | titlecase}}, {{candidate.state}}</span>
                      </div>
                    </mat-card-subtitle>
                  </div>
                </div>
                <div fxFlex="50" fxFlex.xs="100">
                  <mat-card-subtitle>
                    <div fxLayout="row"><span class="mat-small">JOB SEARCH STATUS</span></div>
                    <div fxLayout="row">
                      <span class="mat-display-2">{{candidate.candidate_status}}</span>
                    </div>
                  </mat-card-subtitle>
                </div>
              </div>
            </div>
            <div fxFlex="30" fxFlex.xs="100" fxLayout.xs="column"  fxLayoutAlign.xs="start">
              <div fxLayout="row" fxFlex.xs="100"><span class="mat-small">MATCH RATING</span></div>
              <div fxLayout="row" fxFlex.xs="100" class="match-rate-div" [ngClass]="{'good-match' : candidate.matchbucket==='Good',
                   'excellent-match' : candidate.matchbucket==='Excellent',
                   'need-training-match' : candidate.matchbucket==='Need Training',
                   'not-a-fit-match' : candidate.matchbucket==='Not a fit'}"></div>              
            </div>
            </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="candidates.length == 0">
  <h3>No candidate(s) found!</h3>
</div>
