import { Injectable } from '@angular/core';
import { Observable }           from 'rxjs';
import { CanDeactivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot }  from '@angular/router';
         
import { FeedbackComponent } from '../Pages/feedback/feedback.component';

@Injectable()
export class CanDeactivateGuardService implements CanDeactivate<FeedbackComponent> {

  canDeactivate(
    component: FeedbackComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    // Get the current URL
    // console.log(state.url);
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    let userType = component.userType;
    if(userType==3){
      var edited = JSON.stringify(component.form);
      var originalForm = JSON.stringify(component.originalForm);
  
      // console.log(originalForm)
      // console.log(edited)
  
      if (!component.form || originalForm==edited) {
        return true;
      }
      // Otherwise ask the user with the dialog service and return its
      // observable which resolves to true or false when the user decides
      return component._dialogService.confirm('You have unsaved changes. Are you sure you want to leave this page?');
    }
    return true;
  }
  constructor() { }

}
