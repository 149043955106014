import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../Services/login.service';
import { DashboardService } from '../../../Services/dashboard.service';
import { UserInfoService } from '../../../Services/user-info.service';
import { Article } from '../../../Models/Article';
import * as _ from 'underscore';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent implements OnInit {
  public userType:number;
  public ErrorMessage:string = "";
  public articlesList:Article[] = [];
  public numArticles:number = 0;
  public userId:string = "";
  public tenant:string = "";
  myEventSubscription:any;

  constructor(private _loginService: LoginService, private _dashboardService: DashboardService,
	   private _userInfoService: UserInfoService,
	   private sharedUtils:SharedUtilitiesService) { }

  ngOnInit(): void {
	  this.userType = parseInt(this._userInfoService.getUser().type);
	  this.getNewsSources();	
  }

  getNewsSources() {
    this._dashboardService.getNewsSources().subscribe((result: any) => {
      var that = this;
      result.forEach(function (item) {
        let date = new Date(item.pubDate);
        let dateParsed: string = date.toLocaleString('en-US', { month: 'long', day: 'numeric' });
        let logo_image = item.imageUrlLink.replace("https://", "").replace("http://", "");
        //remove www if present
        logo_image = logo_image.replace("www.","");
        //detect and remove / and everything after
        logo_image = logo_image.split("/")[0]; //if "/" not present split returns the original string as element 0
        let a: Article = {
          source: item.mainTitle,
          imageURL: item.imageUrlLink,
          company: logo_image,
          title: item.title,
          date: dateParsed,
          link: item.link,
          priority: 1,
        };
        that.articlesList.push(a);
      });

    });

  }

	open(article) {
		window.open(article.link);
	}

}
