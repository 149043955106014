import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { of } from 'rxjs';
import { CompanyInfoService } from '../../../../Services/company-info.service';

interface PermissionTab {
  tabName: string;
  permissions: string[];
  perAllowedFlag: boolean;
  data: any[][];
  disabled: boolean[][];
  rowIds: any[];
  dataSource: MatTableDataSource<any>;
};

@Component({
  selector: 'app-user-group-permissions-component',
  templateUrl: './user-group-permissions-component.component.html',
  styleUrls: ['./user-group-permissions-component.component.css']
})
export class UserGroupPermissionsComponentComponent implements OnInit {
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() viewOnly: boolean;
  @Input() groupId: string;
	public isLoadingResult:boolean = false;
  permissionsData: PermissionTab[] = [
    {
      tabName: "Posting",
      permissions: [' ', 'Create', 'View', 'Edit', 'Delete', 'Invite Candidate', 'Advance Candidate'],
      perAllowedFlag: true,
      data: [['All Postings'], ['Per Posting']],
      disabled: [[],[]],
      rowIds: [0,0],
      dataSource: null
    },
    {
      tabName: "Shortlist",
      permissions: [' ', 'Create', 'View', 'Edit', 'Delete', 'Manage Candidates'],
      perAllowedFlag: true,
      data: [['Company Shortlists'], ['User\'s Shortlists'], ['Per Shortlist']],
      disabled: [[],[],[]],
      rowIds: [0,-1,0],
      dataSource: null
    },
    {
      tabName: "Report",
      permissions: [' ', 'View'],
      perAllowedFlag: true,
      data: [['All Reports'], ['Per Report']],
      disabled: [[],[]],
      rowIds: [0,0],
      dataSource: null
    },
    {
      tabName: "Other",
      permissions: ['ADMIN', 'View Candidates'],
      perAllowedFlag: false,
      data: [[]],
      disabled: [[],[]],
      rowIds: [],
      dataSource: null
    },
  ];

  constructor(
    public _companyInfoService: CompanyInfoService
  ) { }

  ngOnInit(): void {
    // getGroupPermissions returns data to append into each tab's table
    this.isLoadingResult = true;
    this._companyInfoService.getGroupPermissions(this.groupId).subscribe((result:any) => {
      if (result) {
        this.isLoadingResult = false;
        this.populatePermissions(result['postings'], 0, result['globals'], result['userSpecific']);
        this.populatePermissions(result['shortlists'], 1, result['globals'], result['userSpecific']);
        this.populatePermissions(result['reports'], 2, result['globals'], result['userSpecific']);
        this.populatePermissions([], 3, result['globals'], result['userSpecific']);
        let checked = result['globals'].find((global) => global.name == 'ADMIN') !== undefined;
        this.valueChanged(checked, 0, 0, 3);
      }
    });
  }

  // --------------------------------------------------------------------- //
  // @params: 
  //          items: [ { id, name, permissions:[ {id, name} ] } ]
  //              ie. [ {220, 'Jr.Dev', [ {4, 'View Opportunity'}] } ]
  //              --> The company has one posting of type, queried group 
  //                  has one posting-specific permission for this posting 
  //          tabIndex: index of tab associated with items
  //          globals: all globals from getGroupPermissions
  // @effects: 
  //          this.permissionsData[tabIndex]: 
  //            rowIds --> row IDs appended to [0,0]
  //            data --> [[item name/'All X'/'Per X', list of 
  //                      true/false/' ' values for checkboxes]]
  //                      note: only global row if viewOnly
  //            disabled --> [[ true/false based on global entry ]]
  //            dataSource --> dataSource type to data
  // @emits: dataChange event on each table
  // @returns: none 
  // --------------------------------------------------------------------- //
  populatePermissions(items, tabIndex, globals, userSpecific) {
    let tab = this.permissionsData[tabIndex];
    this.populateRow(tab.data[0], globals, tab.permissions, tab.tabName, true);
    if(tabIndex === 1) { // Shortlist has a User's Shortlists Permission Row
      this.populateRow(tab.data[1], userSpecific, tab.permissions, tab.tabName, false);
    }
    tab.permissions.forEach(permission => {
      tab.disabled[0].push(false);
      if(tabIndex === 1) { // Shortlist has a User's Shortlists Permission Row
        this.setDisabledFlags(tab.disabled[1], tab.data[1], tab.data[0], tab.permissions);
        tab.disabled[2].push(false);
      }
      else {
        tab.disabled[1].push(false);
      }
    });
    if(this.viewOnly) { // Only show the global row
      tab.data = tab.data.slice(0,1);
    }
    else { // Append each item (posting, shortlist, etc.) as a row
      items.forEach(item => {
        tab.rowIds.push(item.id);
      });
      let rows = tab.data;
      items.forEach(item => {
        rows.push([item.name]);
        tab.disabled.push([]);
        tab.permissions.forEach(permission => {
          tab.disabled[tab.disabled.length - 1].push(false);
        });
        this.populateRow(rows[rows.length - 1], item['permissions'], tab.permissions, tab.tabName, false);
        this.setDisabledFlags(tab.disabled[tab.disabled.length - 1], rows[rows.length - 1], tab.data[0], tab.permissions);
      });
    }
    tab.dataSource = new MatTableDataSource(tab.data);
  }

  // helper for populatePermissions, inserts proper true/false/' '
  // value into the table (' ' represents no checkbox)
  populateRow(row, itemPermissions, tabPermissions, tabName, globalRowFlag) {
    let viewAccessRequired = false;
    tabPermissions.forEach(permission => {
      if(permission === 'Create' && !globalRowFlag) {
        row.push(' ');
      }
      else if(permission !== ' ') {
        if(this.itemHasPermission(itemPermissions, permission, tabName)) {
          row.push(true);
          viewAccessRequired = true;
        }
        else {
          row.push(false);
        }
      }
      if(tabPermissions[2] === 'View' && viewAccessRequired && tabName !== 'Shortlist') row[2] = true;
    });
  }

  // if a global permission is selected, then all checkboxes in that column
  // must be selected and disabled.
  setDisabledFlags(disabledFlags, row, checkedGlobals, permissions) {
    let startIndex = 1;
    if(permissions[1] == 'Create') startIndex = 2;
    for(let columnIndex=startIndex; columnIndex < row.length; columnIndex++) {
      if(checkedGlobals[columnIndex] === true) {
        row[columnIndex] = true;
        disabledFlags[columnIndex] = true;
      }
      else {
        disabledFlags[columnIndex] = false;
      }
    }
  }

  // @params:  itemPermissions: [{id, name}], permission (column name), tabName
  // @effects: none
  // @returns: returns true if item has the permission passed to function
  //           based on mapping
  itemHasPermission(itemPermissions, permission, tabName) {
    let hasPermission = false;
    itemPermissions.forEach(itemPermission => {
      if(tabName === 'Posting') {
        if(permission === 'Create' && itemPermission.name === 'Create Opportunity') hasPermission = true;
        else if(permission === 'View' && itemPermission.name === 'View Opportunity') hasPermission = true;
        else if(permission === 'Edit' && itemPermission.name === 'Edit Opportunity') hasPermission = true;
        else if(permission === 'Delete' && itemPermission.name === 'Delete Opportunity') hasPermission = true;
        else if(permission === 'Invite Candidate' && itemPermission.name === 'Invite Candidate') hasPermission = true;
        else if(permission === 'Advance Candidate' && itemPermission.name === 'Advance Candidate') hasPermission = true;
      }
      else if(tabName === 'Shortlist') {
        if(permission === 'Create' && itemPermission.name === 'Create Shortlist') hasPermission = true;
        else if(permission === 'View' && itemPermission.name === 'View Shortlist') hasPermission = true;
        else if(permission === 'Edit' && itemPermission.name === 'Edit Shortlist') hasPermission = true;
        else if(permission === 'Delete' && itemPermission.name === 'Delete Shortlist') hasPermission = true;
        else if(permission === 'Manage Candidates' && itemPermission.name === 'Manage Shortlist Candidates') hasPermission = true;
      }
      else if(tabName === 'Report') {
        if(permission === 'View' && itemPermission.name === 'View Report') hasPermission = true;
      }
      else if(tabName === 'Other') {
        if(permission === 'ADMIN' && itemPermission.name === 'ADMIN') hasPermission = true;
        else if(permission === 'View Candidates' && itemPermission.name === 'View Candidate Search') hasPermission = true;
      }
    });
    return hasPermission;
  }

  // sends data on each update to the parent component in format that can be easily read by the backend
  valueChanged(checked, columnIndex, rowIndex, tabIndex) {
    let tabs = this.permissionsData;
    let tab = tabs[tabIndex];
    let returnValue = []; // this is the return object sent to the parent dialog component
    tab.data[rowIndex][columnIndex] = checked;
    if(checked && tab.permissions[2] === 'View' && tab.permissions[columnIndex] === 'Create' && tabIndex === 1) {
      tab.data[1][2] = true; // For Shortlist Create, only default grant User's Shortlist View permission
    }
    else if(checked && tab.permissions[2] === 'View') {
      tab.data[rowIndex][2] = true; // View rights must be enabled for any other type of rights
    }
    if(tab.permissions[columnIndex] === 'View' && !checked && tabIndex !== 2) {
      this.valueChanged(false, 1, 0, tabIndex); // unset global create
      for(let column = 3; column < tab.permissions.length; column++) {
        this.valueChanged(false, column, rowIndex, tabIndex); // unset all other globals
      }
    }
    if(tab.permissions[5] === 'Manage Candidates') {  // View candidates permission must be given when manage candidates permission given
      if(checked) {
        tabs[3].data[0][1] = true;
      } else {
        tabs[3].data[0][1] = false;
      }
    }
    if(rowIndex == 0) { // set all Pers in associated column to false
      let tabData = tab.data;
      for(let rowIndex = 1; rowIndex < tabData.length; rowIndex++) {
        if(tabData[rowIndex][columnIndex] == true) tabData[rowIndex][columnIndex] = false;
        this.setDisabledFlags(tab.disabled[rowIndex], tabData[rowIndex], tab.data[0], tab.permissions);
      }
    }
    else if(tab.data[0][columnIndex]) { // set the indeterminate value of the associated global to false
      tab.data[0][columnIndex] = !checked;
    }
    this.permissionsData.forEach(tab => {           // all selected permissions appended to a return object, 
      for(let row=0; row<tab.data.length; row++) {  // ignoring disabled checkboxes
        let rowId = tab.rowIds[row];
        let globalRow = tab.data[0];
        if(tab.tabName == 'Posting') {
          if(tabs[0].data[row][1] == true && (row == 0 || !globalRow[1])) returnValue.push(this.addPermission(rowId, 'Create Opportunity'));
          if(tabs[0].data[row][2] && (row == 0 || !globalRow[2])) returnValue.push(this.addPermission(rowId,  'View Opportunity'));
          if(tabs[0].data[row][3] && (row == 0 || !globalRow[3])) returnValue.push(this.addPermission(rowId,  'Edit Opportunity'));
          if(tabs[0].data[row][4] && (row == 0 || !globalRow[4])) returnValue.push(this.addPermission(rowId,  'Delete Opportunity'));
          if(tabs[0].data[row][5] && (row == 0 || !globalRow[5])) returnValue.push(this.addPermission(rowId,  'Invite Candidate'));
          if(tabs[0].data[row][6] && (row == 0 || !globalRow[6])) returnValue.push(this.addPermission(rowId,  'Advance Candidate'));
        }
        if(tab.tabName === 'Shortlist') {
          if(tabs[1].data[row][1] == true && (row == 0 || !globalRow[1])) returnValue.push(this.addPermission(rowId,  'Create Shortlist'));
          if(tabs[1].data[row][2] && (row == 0 || !globalRow[2])) returnValue.push(this.addPermission(rowId,  'View Shortlist'));
          if(tabs[1].data[row][3] && (row == 0 || !globalRow[3])) returnValue.push(this.addPermission(rowId,  'Edit Shortlist'));
          if(tabs[1].data[row][4] && (row == 0 || !globalRow[4])) returnValue.push(this.addPermission(rowId,  'Delete Shortlist'));
          if(tabs[1].data[row][5] && (row == 0 || !globalRow[5])) returnValue.push(this.addPermission(rowId,  'Manage Shortlist Candidates'));
        }
        if(tab.tabName === 'Report') {
          if(tabs[2].data[row][1] && (row == 0 || !globalRow[1])) returnValue.push(this.addPermission(tabs[2].data[row][0],  'View Report'));
        }
        if(tab.tabName === 'Other') {
          if(tabs[3].data[row][0]) returnValue.push(this.addPermission(rowId,  'ADMIN'));
          if(tabs[3].data[row][1]) returnValue.push(this.addPermission(rowId,  'View Candidate Search'));
        }
      }
    });
    this.dataChange.emit(returnValue); // send object to parent component
  }

  addPermission(id, name) { // helper function for building return object
    if(id == undefined) id = 0;
    let returnItem = {
      itemId : id,
      name : name
    }
    return returnItem;
  }
   
  isString(tableValue) { // whether to treat table cell as a checkbox
    return typeof tableValue === 'string' || tableValue === undefined; 
  }

  // called on all cells for each change, sets global to indeterminate
  // if any Per's associated column checkboxes are true
  indeterminate(rowIndex,columnIndex,tabIndex) {
    if(columnIndex == 0) {
      let tab = this.permissionsData[tabIndex];
      for(let i=1; i < tab.data.length; i++) {
        if(tab.data[i][rowIndex] == true && !tab.disabled[i][rowIndex]) {
          return true;
        }
      }
    }
    return false;
  }
}
