<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="mat-typography">
  <!--color="primary" -->
  <a [routerLink]="'/dashboard'">
    <app-logo [tenant]="tenant" [type]="'header'" class="app-header-logo"></app-logo>
  </a>
  <!-- <div>App-logo</div> -->
  <div *ngIf="(userName==null || userName=='');else settingMenu"
       fxLayout="row" fxLayoutAlign="start end" class="toolbar-height" fxHide.lt-lg>
    <nav mat-tab-nav-bar color="primary">
      <a mat-tab-link [routerLink]="'/login'"><h2>Login/Register</h2></a>
      <a mat-tab-link [routerLink]="'/employer_register'"><h2>For Employers</h2></a>
    </nav>
  </div>
  <ng-template #settingMenu>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="toolbar-height" fxHide.lt-lg>
      <div fxLayout="row" fxLayoutAlign="start end" style="height:64px">
        <nav mat-tab-nav-bar color="primary">
          <!--backgroundColor="primary" -->
          <ng-container *ngFor="let link of navRoutes">
            <a mat-tab-link 
              routerLinkActive
              #RouterLinkActive="routerLinkActive"
              [active]="RouterLinkActive.isActive"
              [routerLink]="[ link.link ]"
              (click)="navigateTo(link.link)"
              [ngClass]="{focus: RouterLinkActive.isActive}" *ngIf="link.link == 'postings'">
              <h3>{{ link.name }}</h3>
            </a>
            <a mat-tab-link 
              routerLinkActive
              #RouterLinkActive="routerLinkActive"
              [active]="RouterLinkActive.isActive"
              [routerLink]="[ link.link ]"
              [ngClass]="{focus: RouterLinkActive.isActive}" *ngIf="link.link != 'postings'">
              <h3>{{ link.name }}</h3>
            </a>
          </ng-container>
          
        </nav>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="profileMenu">
        <img matListAvatar src="{{ profilePic === '' ? '../../../assets/img/User-blue-icon.png' : profilePic}}" onError="this.src='../../assets/img/User-blue-icon.png';" class="profile-image">
      </button>
      <mat-menu #profileMenu="matMenu">
        <mat-nav-list>
          <a mat-list-item id="settingsLink" [routerLink]="['/settings']">Settings</a>
          <a mat-list-item id="diversitySurveyLink" *ngIf="userType == 2" [routerLink]="['/diversitysurvey']">Diversity and Inclusion Survey</a>
          <a mat-list-item id="companyAdminLink" *ngIf="hasCompanyAdminPermission" [routerLink]="['/companyAdmin']">Company Admin</a>
          <a mat-list-item id="siteFeedbackLink" href="mailto:feedback@hipjobjuice.com?Subject=Feedback">Site Feedback</a>
          <a mat-list-item id="logOutLink" class="" (click)="logout()">Log Out</a>
        </mat-nav-list>
      </mat-menu>
    </div>
  </ng-template>
  <button mat-icon-button (click)="toggleSidenav()" fxHide.gt-md>
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
