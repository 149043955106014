import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../../Services/login.service';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { UserInfoService } from '../../../../Services/user-info.service';

@Component({
  selector: 'app-candidate-email-verification',
  templateUrl: './candidate-email-verification.component.html',
  styleUrls: ['./candidate-email-verification.component.css']
})
export class CandidateEmailVerificationComponent implements OnInit {
  user;
  isChangeEmailView: boolean = false;
  isEmailChanged:boolean = false;
  isLoading: boolean = false;
  public form;
  constructor(private fb: UntypedFormBuilder, private _router: Router, private _userInfoService: UserInfoService, private _talentApiService: TalentApiService,
    private _loginService: LoginService) { }
  
  ngOnInit(): void {
    this.user = this._userInfoService.getUser();
    if(this.user.email_verified) {
      this._router.navigateByUrl('/dashboard');
    }
    this.setChangeEmailForm();
  }

  setChangeEmailForm() {
    this.form = this.fb.group({
      currentEmail: [this.user.username, [Validators.required, Validators.email]],
      newEmail: ['', [Validators.required, Validators.email]]
    });
  }

  changeEmail() {
    this.isChangeEmailView = true;
    this.setChangeEmailForm();
  }

  back() {
    this.isChangeEmailView = false;
  }

  submitForm() {
    let obj = this.form.value;
    if(obj.currentEmail && obj.newEmail) {
      this.isLoading = true;
      this._talentApiService.changeEmail(obj).subscribe(
        (result:any) => {
          if (result) {
            if(result['result'] == false) {
              this.isLoading = false;
              this._talentApiService.snackbarMessage(result['msg']);
            }
            else {
              let changeEmailresult = result;
              this._loginService.getUserDetail().subscribe((result:any) => {
                this.user = result;
                this._talentApiService.snackbarMessage(changeEmailresult['msg']);
                this.isChangeEmailView = false;
                this.isLoading = false;
              });
            }
          }
          else {
            this._talentApiService.snackbarMessage('Something went wrong!');
            this.isLoading = false;
          }
        },
        error => {
          this.isLoading = false;
          this._talentApiService.snackbarMessage('Something went wrong!');
        });
    }
  }

  sendVerification() {
    this.isLoading = true;
    this._talentApiService.sendVerificationEmail(this.user.username).subscribe(
      (result:any) => {
        this.isLoading = false;
        if (result) {
          this._talentApiService.snackbarMessage('Verification Email sent');
          this._userInfoService.clearUser();
        }
        else {
          this._talentApiService.snackbarMessage('Verification Email could not be sent!');
        }
      },
      error => {
        this.isLoading = false;
        this._talentApiService.snackbarMessage('Something went wrong!');
      });
  }
}
