<mat-toolbar  class="modal-header">
	Logo Uploader
	<span class="flex-spacer"></span>
	<button mat-button mat-dialog-close>x</button>
</mat-toolbar>
<div mat-dialog-content>
	<form	name="uploadLogo" novalidate>
		<div class="modal-body">
			<div class="">
				<div class="form-group">
					<div>
						<label class="orgTitle">{{organization.type}} Name: {{organization.name}}</label>
					</div>
					<div *ngIf="isClearbitLoading">
						<div>
							<mat-progress-spinner style="position: absolute !important;
							top: 0;bottom: 0;margin: auto;right: 0;left: 0;" class="tjs-spinner skills" [color]="color" [mode]="mode" [value]="value"
								[diameter]="30">
							</mat-progress-spinner>
						</div>
						<div>Loading the image...</div>
					</div>
					<div *ngIf="!isClearbitLogoAvailable">
						<label for="#logo-upload">Logo</label>
						<!-- <ng-file-input *ngIf="!isClearbitLogoAvailable" id="logo-upload" mode="data-url" callback="callback(file)" tmpl="'templates/upload-button.html'"></ng-file-input> -->
						<button mat-button mat-raised-button class="btn background-primary" color="primary" (click)="callback.click()">
							Upload
							<input #callback type="file" (change)="fileChangeEvent($event)" mode="data-url" name="pic-upload-{{organization.id}}" id="pic-upload" style="display:none;"
								class="form-control" accept="image/x-png,image/gif,image/jpeg" />
						</button>
						<p class="help-block small animated fadeInDown mat-error ng-star-inserted" *ngIf="imageError">Only JPG, PNG or Gif images are allowed with file size of 2MB only.</p>
					</div>

					<div class="img-box padding-top-15" *ngIf="!isClearbitLoading">
						<img src="{{logoPreview}}" />
					</div>
				</div>
			</div>
		</div>
		<div class="" *ngIf="!isClearbitLoading">
			<button mat-button mat-raised-button class="btn background-primary" color="primary" [disabled]="!logoFile.content || imageError" *ngIf="!isClearbitLogoAvailable && !submitting" (click)="submit()">Submit</button>
			<div *ngIf="submitting">
				<mat-progress-spinner style="position: absolute !important;
					top: 0;bottom: 0;margin: auto;right: 0;left: 0;" class="tjs-spinner skills" [color]="color" [mode]="mode" [value]="value"
					[diameter]="30">
				</mat-progress-spinner>
			</div>
		</div>
	</form>
	</div>