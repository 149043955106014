import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';


@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.iconInit("banner", "../assets/img/icon/banner.svg");
    this.iconInit("eye", "../assets/img/icon/eye.svg");
    this.iconInit("meter", "../assets/img/icon/meter.svg");
    this.iconInit("page", "../assets/img/icon/page.svg");
    this.iconInit("pipe", "../assets/img/icon/pipe.svg");
    this.iconInit("sun", "../assets/img/icon/sun.svg");
    this.iconInit("aboutCompany", "../assets/img/icon/aboutCompany.svg");
    this.iconInit("card", "../assets/img/icon/card.svg");
    this.iconInit("companyMap", "../assets/img/icon/companymap.svg");
   }

  /*-----------------------------------------------
  |         **  -- IMPORTANT --                   |
  | Remember when specifying svg resource path    |
  | that it is relative to THIS FILE location     |
  | and not the component.ts location that it     |
  | is used in.                                   |
  -----------------------------------------------*/

iconInit(name, location) {
  this.iconRegistry.addSvgIcon(
    name,
    this.sanitizer.bypassSecurityTrustResourceUrl(location));
}

}


