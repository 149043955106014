<div fxLayout="row" fxLayoutAlign="center" *ngIf="isLoading" class="loader-margin">
  <mat-progress-bar [mode]="indeterminate">
  </mat-progress-bar>
</div>
<div class="popup-scroll" *ngIf="!isLoading && resultDisplay">
  <div data-element-identifier="member.postings.posting-detail-pane">
    <button *ngIf="showHeaderBack" mat-button class="header-button" (click)="backposting()">
      <i class="fa fa-arrow-left arrow_leftt" aria-hidden="true"></i> Back
    </button>
    <div class="top-bottom-margin tab-content  opprtunity-posting-content">
      <div fxFlex="100" fxShow.sm="{{showDetail}}" fxShow.xs="{{showDetail}}">

        <mat-card role="tabpanel"
                  id="list_tab1"
                  class="opportuity-detail-card"
                  [ngClass.lt-md]="'opportuity-detail-card-lt-md'"
                  [ngClass.md]="'opportuity-detail-card-md'"
                  [ngClass.xl]="['opportuity-detail-card-xl','padding-25']"
                  [ngClass.lg]="['opportuity-detail-card-lg','padding-25']">
          <div class="arrow_shadow_none">
            <div class="profile_section">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxFlex *ngIf="userType != 3 || showHeaderBack==true">
                  <app-candidate-posting-detail [detail]="detail"
                                                [user]="user"
                                                [userType]="userType"
                                                [applicants]="applicants"
                                                [showHeaderBack]="showHeaderBack"
                                                (unTag)="unTag($event)"
                                                (tag)="tag($event)"
                                                (apply)="apply($event)">
                  </app-candidate-posting-detail>
                </div>
                <div fxFlex *ngIf="userType == 3 && showHeaderBack==false">
                  <app-employer-posting-detail [detail]="detail"
                                               [breakpoint]="breakpoint"
                                               [user]="user"
                                               [userType]="userType"
                                               [applicants]="applicants"
                                               [showHeaderBack]="showHeaderBack"
                                               (unTag)="unTag($event)"
                                               (tag)="tag($event)"
                                               (apply)="apply($event)">
                  </app-employer-posting-detail>
                </div>
                <div fxShow.sm="{{showDetail}}"
                     fxShow.xs="{{showDetail}}"
                     class="posting-details-title"
                     [ngClass]="{'posting-back-button': isMobileDevice && !showHeaderBack}"
                     fxLayoutAlign.xs="start start">
                  <span>
                    <mat-icon (click)="toggleDetail()">close</mat-icon>
                  </span>
                </div>
              </div>
              <mat-divider [inset]="true"></mat-divider>
              <mat-tab-group class="opportunity-scroll opportuity-detail-card-tabs"
                             [ngClass.md]="'opportuity-detail-card-tabs-md'"
                             [ngClass.lg]="'opportuity-detail-card-tabs-lg'"
                             [(selectedIndex)]="currTabIndex"
                             color="primary"
                             mat-align-tabs="start"
                             (selectedTabChange)="changeTabs($event)">
                <mat-tab label="ABOUT JOB" (click)="showApplicants = false" data-target="#job_summary">
                  <div class="address-margin" id="job_summary" (swipe)="onSwipe($event)">
                    <div class="table_section" *ngIf="detail.skills.length > 0" style="padding-bottom: 15px;">
                      <div class="panel panel-default">
                        <div fxLayout="row wrap">
                          <div class="key-res-sec" fxFlex="50" fxLayoutAlign="start start">
                            <h4><span>SKILLS</span></h4>
                          </div>
                          <div fxFlex="50" *ngIf="(userType==3 || userType==4) && !showHeaderBack" fxLayoutAlign="end start">
                            <button class="" mat-stroked-button
                                    *ngIf="this.hasEditPostingPermission && user.company === detail.company.name"
                                    color="primary" (click)="OpenEditPopup()">
                              <span class="fa fa-1x fa-pencil fa-fw"></span>&nbsp;Edit&nbsp;
                            </button>
                          </div>
                        </div>
                        <mat-chip-list name="matcpSkill">
                          <mat-chip *ngFor="let skill of detail.skills" class="skill-chip">
                            <p class="skill-text"> {{ skill.name }} </p>
                            <p class="years-text"> {{ skill.expYears }} </p>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </div>
                    <div>
                      <h4 fxFlex="50"><span>DESCRIPTION</span></h4><!--If no any skill then set edit button here-->
                      <div fxFlex="50" *ngIf="(userType==3 || userType==4) && !showHeaderBack && detail.skills.length==0" fxLayoutAlign="end start">
                        <button class="" mat-stroked-button
                                *ngIf="this.hasEditPostingPermission && user.company === detail.company.name"
                                color="primary" (click)="OpenEditPopup()">
                          <span class="fa fa-1x fa-pencil fa-fw"></span>&nbsp;Edit&nbsp;
                        </button>
                      </div>
                    </div>
                    <p class="renderNewLines" id="jobdescription" [innerHtml]="detail.description">
                    </p>
                    <br />
                    <div *ngIf="detail.benefits != null">
                      <h4><span>BENEFITS</span></h4>
                      <p class="renderNewLines" id="jobBenefits">
                        {{detail.benefits}}
                      </p>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab class="tab-weight" label="LOGISTICS" (click)="showApplicants = false"
                         data-target="#logistics_detail">
                  <div class="address-margin" id="logistics_detail" (swipe)="onSwipe($event)" fxLayout.xs="row wrap">
                    <ng-container>
                      <mat-list fxFlex="80%" fxFlex.xs="100%" fxFlexOrder.xs="1"
                                fxLayout.xs="row wrap">
                        <mat-list-item [ngClass.xs]="'mat-list-item-word-wrap'"
                                       [ngClass]="{'mat-list-item-word-wrap' : (detail.locations.length >5)}"
                                       [ngClass.sm]="'mat-list-item-word-wrap'">
                          <div fxLayout="row wrap"
                               fxLayoutAlign.sm="start start"
                               fxLayoutAlign.xs="start start">
                            <h4>Address:</h4>
                            <div fxLayoutAlign="start start"
                                 fxLayoutAlign.xs="start start"
                                 fxLayout.xs="column"
                                 fxLayout="{{detail.locations.length >5 ?'column':'row'}}"
                                 fxLayoutAlign.sm="start start"
                                 fxLayout.sm="column">
                              <div *ngFor="let loc of detail.locations" class="mat-body-1">
                                <a class="address-padding "
                                   color="accent"
                                   *ngIf="loc.address1 || loc.city || loc.state"
                                   aria-label="map"
                                   href="http://maps.google.com/{{
                              '?q=' + (loc.address1 ? loc.address1+ ', ':'') + (loc.city ? loc.city+ ', ':'') + (loc.state ? loc.state:'') + (loc.zip ?  ', '+loc.zip:'') }}"
                                   target="_blank">
                                  <i *ngIf="loc.city || loc.state"
                                     class="fa fa-1x fa-map-marker color-primary">
                                  </i>
                                </a>{{ (loc.city ? loc.city+ ', ':'') + (loc.state ? loc.state:'') }}
                              </div>
                            </div>
                          </div>
                        </mat-list-item>
                        <mat-list-item>
                          <h4>Number of Positions: </h4>&nbsp; <span id="numOfPos" class="mat-body-1">{{ detail.quantity }}</span>
                        </mat-list-item>
                        <mat-list-item *ngIf="detail.workSetup" [ngClass.xs]="'mat-list-item-word-wrap'" [ngClass.sm]="'mat-list-item-word-wrap'">
                          <div fxLayout="row wrap" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start">
                            <h4>Work Setup:&nbsp;&nbsp;</h4>
                            <div fxLayoutAlign="start center"
                                 [ngClass.xs]="'linebreak'"
                                 [ngClass.sm]="'linebreak'"
                                 fxLayoutAlign.xs="start start"
                                 fxLayoutAlign.sm="start start"
                                 fxLayout.xs="column"
                                 fxLayout.sm="column">
                              {{getWorkSetup()}}
                            </div>
                          </div>
                        </mat-list-item>
                        <mat-list-item *ngIf="detail.opportunity_type.length > 0" [ngClass.xs]="'mat-list-item-word-wrap'" [ngClass.sm]="'mat-list-item-word-wrap'">
                          <div fxLayout="row wrap" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start">
                            <h4>Opportunity <br fxShow.xs fxShow.sm fxHide /> Type:&nbsp;&nbsp;</h4>
                            <div fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start">
                              <div id="optype" class="mat-body-1" *ngFor="let OpportunityType of detail.opportunity_type;let last = last;">
                                <span   fxShow.xs fxShow.sm fxHide>&nbsp;&nbsp;&nbsp;</span>{{OpportunityType}} <span fxShow fxHide.xs fxHide.sm *ngIf="!last">, &nbsp;</span>
                              </div>
                            </div>
                          </div>
                        </mat-list-item>
                      </mat-list>
                      <div fxFlex="20%" fxFlex.xs="100%" fxFlexOrder.xs="0" fxLayout.xs="row wrap" *ngIf="(userType==3 || userType==4) && !showHeaderBack" fxLayoutAlign="end start">
                        <button mat-stroked-button
                                *ngIf="this.hasEditPostingPermission && user.company === detail.company.name"
                                color="primary" (click)="OpenEditPopup()">
                          <span class="fa fa-1x fa-pencil fa-fw"></span>&nbsp;Edit&nbsp;
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </mat-tab>

                <mat-tab class="tab-weight" label="COMPANY INFO" (click)="showApplicants = false"
                         data-target="#compaby_detail">
                  <div class="address-margin" fxLayout="row" id="compaby_detail" (swipe)="onSwipe($event)">
                    <div fxFlex.gt-sm="100%" fxLayout="column" fxFlex.lt-md="100%"
                         class="company-margin company-info">
                      <span *ngIf="
                                    detail.streetaddress &&
                                    detail.city &&
                                    detail.state &&
                                    detail.zip
                                  ">
                        {{ detail.streetaddress }},
                        {{ detail.city }}, {{ detail.state }}
                        {{ detail.zip }}
                        <br />
                      </span>
                      <span *ngIf="detail.company.description" id="compdescription">
                        {{ detail.company.description }}
                        <br />
                      </span>
                      <a href="{{ detail.company.website }}" id="compwebsite" target="_blank">{{ detail.company.website }}</a>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab *ngIf="detail.isOwnerOrAdminRecruiter" class="tab-weight" label="APPLICATIONS" (click)="showApplicants = true"
                         data-target="#application">
                  <div class="feed-main" fxFlex (swipe)="onSwipe($event)">
                    <app-opportunity-applications *ngIf="showApplicants" [postingId]="postingId" [postingDetail]="detail" [breakpoint]="breakpoint"
                                                  fxFlex="100" fxFlex.gt-md="100"></app-opportunity-applications>
                  </div>
                </mat-tab>
                <mat-tab *ngIf="detail.isOwnerOrAdminRecruiter" class="tab-weight" label="ENGAGED" (click)="showApplicants = false" data-target="#engaged">
                  <div class="feed-main" fxFlex (swipe)="onSwipe($event)">
                    <app-candidate-engaged-card [postingId]="postingId" [postingDetail]="detail" [breakpoint]="breakpoint"
                                                fxFlex="100" fxFlex.gt-md="100"></app-candidate-engaged-card>
                  </div>
                </mat-tab>
                <mat-tab *ngIf="detail.isOwnerOrAdminRecruiter"
                         class="tab-weight" label="MATCHES" (click)="showApplicants = false" data-target="#matches">
                  <div class="feed-main" fxFlex (swipe)="onSwipe($event)">
                    <app-posting-match-candidate [postingId]="postingId"
                                                 [postingDetail]="detail"
                                                 [breakpoint]="breakpoint"
                                                 fxFlex="100"></app-posting-match-candidate>
                  </div>
                </mat-tab>
              </mat-tab-group>
              <div class="clearfix"></div>
            </div>
          </div>
        </mat-card>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!resultDisplay" class="top-bottom-margin" fxShow.sm="{{showDetail}}" fxShow.xs="{{showDetail}}">
  <mat-card class="padding-25 opportuity-detail-card" [ngClass.md]="'opportuity-detail-card-md'" [ngClass.lt-md]="'opportuity-detail-card-lt-md'" [ngClass.xl]="'opportuity-detail-card-xl'" [ngClass.lg]="'opportuity-detail-card-lg'">
    <div fxLayout="row" fxLayoutAlign="center center">
      <app-logo [tenant]="'TJS'" [type]="'no-posting'"></app-logo>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h2 fxLayout="row">The posting you're looking for does not exist!</h2>
    </div>
  </mat-card>
</div>
