<div class="container">
	<div class=" drop-shadow admin_user_page bg-light admin_logos">
		<div class="row">
			<div class="col-lg-12">
				<h4>Organizations Branding Logos</h4>
				<p>
					<em>Logos can be uploaded from here for schools or companies.</em>
				</p> 
				<div class="panel panel-default">
					<div class="panel-body">
						<div class="col-md-8">
							<strong>Instructions:</strong>
							<ol>
								<li>Normally logo is fetched from clearbit but if not found from there, it comes to be reviewed here.</li>
								<li>By Review or Edit button logo can be viewed or uploaded for school or company.</li>
							</ol>
						</div>
						<div class="col-md-4">
							<div>
								<strong>Update Default Logos:</strong>
							</div>
							<button class="btn add_new" (click)="openDefaultLogoModal(2)">For Company</button>
							<button class="btn add_new" (click)="openDefaultLogoModal(1)">For School</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Logo Datatables -->
		<div class="row">
			<div>
				<table datatable id="admincompanylogos" width="100%" class="table table-bordered table-striped table-hover display compact order-column" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
					<thead>
						<tr>
							<th [hidden]=false width="10%">Logo Id</th>
							<th>Name</th>
							<th width="20%">Organization Type</th>
							<th width="15%" class="text-center">Action</th>
							<!-- <th width="20%" class="text-center">Edit</th> -->

						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let logo of orgLogo; let i = index;">
							<td [hidden]=false>{{logo.id}}</td>
							<td>{{logo.name}}</td>
							<td>{{logo.organization_type}}</td>
							<td class="text-center">
								<button *ngIf="logo.isLogoUploadNeeded == 'N'" mat-button mat-raised-button (click)="openReviewModal(logo)">Edit</button>
								<button *ngIf="logo.isLogoUploadNeeded == 'Y'" mat-button mat-raised-button (click)="openReviewModal(logo)">Review</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<!-- Modal for logo update/ review -->
	<!-- <div data-element-identifier="admin.logo-display.modal.add-edit-logo">
		<div id="upload-modal" class="modal fade medium-modal" role="form">
			<form class="modal-dialog" name="uploadLogo" novalidate>
				<div class="modal-content">
					<div data-element-identifier="admin.logo-display.modal.add-edit-logo.header" class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 class="modal-title">Logo Uploader</h4>
					</div>
					<div data-element-identifier="admin.logo-display.modal.add-edit-logo.form" class="modal-body">
						<div class="">
							<div class="form-group">
								<div>
									<label class="orgTitle">{{organization.type}} Name: {{organization.name}}</label>
								</div>
								<div *ngIf="isClearbitLoading">
									<div>
										<mat-progress-spinner style="position: absolute !important;
										top: 0;bottom: 0;margin: auto;right: 0;left: 0;" class="tjs-spinner skills" [color]="color" [mode]="mode" [value]="value"
										 [diameter]="30">
										</mat-progress-spinner>
									</div>
									<div>Loading From Clearbit...</div>
								</div>
								<div *ngIf="!isClearbitLogoAvailable">
									<label for="#logo-upload">Logo</label>
									<button mat-button mat-raised-button class="btn background-primary" color="primary" (click)="callback.click()">
										Upload
										<input #callback type="file" (change)="fileChangeEvent($event)" mode="data-url" name="pic-upload-{{organization.id}}" id="pic-upload" style="display:none;"
										 class="form-control" accept="image/x-png,image/gif,image/jpeg" />
									</button>
									<p class="help-block small animated fadeInDown mat-error ng-star-inserted" *ngIf="imageError">Only JPG, PNG or Gif images are allowed with file size of 2MB only.</p>
								</div>

								<div class="img-box padding-top-15" *ngIf="!isClearbitLoading">
									<img src="{{logoPreview}}" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div data-element-identifier="admin.logo-display.modal.add-edit-logo.submit-button" class="" *ngIf="!isClearbitLoading">
							<button mat-button mat-raised-button class="btn background-primary" color="primary" [disabled]="!logoFile.content || imageError" *ngIf="!isClearbitLogoAvailable && !submitting" (click)="submit()">Submit</button>
							<div *ngIf="submitting">
								<mat-progress-spinner style="position: absolute !important;
									top: 0;bottom: 0;margin: auto;right: 0;left: 0;" class="tjs-spinner skills" [color]="color" [mode]="mode" [value]="value"
								 [diameter]="30">
								</mat-progress-spinner>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div> -->

	<!-- Modal for default logo update -->
	<!-- <div data-element-identifier="admin.logo-display.modal.change-default-logo">
		<div id="default-logo-modal" class="modal fade medium-modal" role="form">
			<form class="modal-dialog" name="defaultLogo" novalidate>
				<div class="modal-content">
					<div data-element-identifier="admin.logo-display.modal.change-default-logo.header" class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 class="modal-title">Update Default Logo for {{defaultLogoType}}</h4>
					</div>
					<div data-element-identifier="admin.logo-display.modal.change-default-logo.form" class="modal-body">
						<div class="">
							<div class="form-group">
								<div>
									<label for="#logo-upload">Logo</label>
									<button class="btn background-primary" mat-button mat-raised-button color="primary" (click)="callback.click()">
										Upload
										<input #callback type="file" (change)="fileChangeEvent($event)" mode="data-url" name="pic-upload-{{defaultLogoType}}" id="pic-upload-{{defaultLogoType}}" style="display:none;"
										 class="form-control" accept="image/x-png,image/gif,image/jpeg" />
									</button>
									<p class="help-block small animated fadeInDown mat-error ng-star-inserted" *ngIf="imageError">Only JPG, PNG or Gif images are allowed with file size of 2MB only.</p>
								</div>
								<div *ngIf="isLoadingDefaultLogo">
									<div>
										<mat-progress-spinner style="position: absolute !important;
										top: 0;bottom: 0;margin: auto;right: 0;left: 0;" class="tjs-spinner skills" [color]="color" [mode]="mode" [value]="value"
										 [diameter]="30">
										</mat-progress-spinner>
									</div>
									<div>Loading...</div>
								</div>
								<div [hidden]="isLoadingDefaultLogo" class="img-box padding-top-15">
									<img src="{{logoPreview}}" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div data-element-identifier="admin.logo-display.modal.change-default-logo.submit-button" class="">
							<button mat-button mat-raised-button class="btn background-primary" color="primary" [disabled]="!logoFile.content || imageError" *ngIf="!submitting" (click)="submitDefaultLogo()">Submit</button>
							<div *ngIf="submitting">
								<mat-progress-spinner style="position: absolute !important;
								top: 0;bottom: 0;margin: auto;right: 0;left: 0;" class="tjs-spinner skills" [color]="color" [mode]="mode" [value]="value"
									[diameter]="20">
								</mat-progress-spinner>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div> -->
</div>