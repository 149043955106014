import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Subject } from 'rxjs';
import { AdminApiService } from '../../Services/admin-api.service';

import { MatDialog } from '@angular/material/dialog';
import { TenantEditComponent } from '../../Admin/dialogs/tenant-edit/tenant-edit.component';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.css']
})
export class TenantComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'created_by', 'created_ts', 'last_updated_by', 'last_updated_ts', 'edit'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  tenantList = {};

  constructor(private _adminApiService: AdminApiService,
  public _dialog: MatDialog) {}

  ngOnInit(): void {
	this.getTenants();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTenants() {
		this._adminApiService.getTenantsList()
			.subscribe(
			(result: any) => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
				let self = this;
				result.map((element, i) => {
					self.tenantList[element.id] = element;
				});
			},
			error => {
				console.log('Could not load Tenants');
			}
		);
	}

  // role edit popup
  openEditModal(id= '') {
	const dialog = this._dialog.open(TenantEditComponent, {
    height: 'auto'
	});
	const editData = Object.assign({}, this.tenantList[id]);
	const isReloadData = false;

	dialog.componentInstance.isReloadData = isReloadData;

	dialog.componentInstance.editing = editData;
	dialog.afterClosed()
	.subscribe((result) => {
		if (dialog.componentInstance.isReloadData) {
		this.getTenants();
		}
	});
  }

}
