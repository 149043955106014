<h1 mat-dialog-title>You are sharing Post : {{postingName}}</h1>
<div mat-dialog-content>
  <form [formGroup]="emailForm">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="start start">
      <mat-form-field color="primary" class="form-item" appearance="fill" fxFlex="100">
        <mat-chip-list #chipList formArrayName="emails">
          <mat-chip [color]="(em.invalid)?'warn':''" selected *ngFor="let em of emailList; let i=index"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeEmail(em)"
                    required name="chips">
            {{em.value}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="enter multiple emails "
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="addEmails($event) " />
        </mat-chip-list>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button color="accent" (click)="closeDialog()">Close</button>
  <button mat-button mat-raised-button color="primary" (click)="sharePost()">
    Share
    <mat-icon *ngIf="sendingEmail">
      <mat-spinner color="accent" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
</div>
