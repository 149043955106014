import { Component, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { Validators, UntypedFormArray, FormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';

import { RegisterService } from '../../../../Services/register.service';
import { CandidateLinkContacts, CandidateProfile, CandidatePreferredContact, CandidateIdNameModel } from '../../../../Models/CandidateProfile';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { TenantService } from '../../../../Services/tenant.service';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-candidate-link-contacts',
  templateUrl: './candidate-link-contacts.component.html',
  styleUrls: ['./candidate-link-contacts.component.css']
})
export class CandidateLinkContactsComponent implements OnInit {
  @Input() linkContacts: CandidateLinkContacts;
  @Input() edit: boolean = false;
  @Input() candidateProfileLoaded: EventEmitter<CandidateProfile>;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();

  // list of contact types and the yet unused contact types
  // that should populate the mat-selection dropdowns
  public contactTypes: CandidateIdNameModel[];
  public unusedContactTypes: CandidatePreferredContact[];

  // after population, the formArray looks like:
  /*
  [ FORMARRAY -> PreferredContacts
    {},
    {},
    ...,
    { FORMGROUP -> PreferredContacts[i]
      { FORMGROUP -> contactType
        FORMCONTROL -> id,
        FORMCONTROL -> name
      },
      FORMCONTROL -> link
    },
    {}
  ] This is probably not the best form implementation
  */
  public contactForm: UntypedFormGroup;
  tenant: String;
  public breakpoint:number; // used to classify the screen size/display width

  constructor(private _registerService: RegisterService,
    private _talentAPIService: TalentApiService,
    public fb: UntypedFormBuilder,
    private _tenantService: TenantService,
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) {
      this.tenant = this._tenantService.tenant;
    }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    this.initForm();
    // contact types should load before populating the form
    this._registerService.getContactTypes().subscribe((result)=> {
      this.contactTypes = result;
      this.populate(this.linkContacts);
    });
  }

  initForm(){
    this.contactForm = this.fb.group({
      'contentLink': ['', Validators.maxLength(255)],
      'preferredContacts': this.fb.array([this.initPerfContact()])
    });
  }

  initPerfContact():UntypedFormGroup {
    return this.fb.group({
        contactType: ['', Validators.required],
        contact: ['', [Validators.required, Validators.maxLength(255)]]
    });
  }

  get Contacts():UntypedFormArray{
    return this.contactForm.get('preferredContacts') as UntypedFormArray;
  }

  getContactType(c: number){
    return this.contactTypes[c];
  }

  skip() {
    this.saveProfile.emit(this.contactForm.value);
  }

  prev() {
    this.prevEvent.emit();
  }

  // @requires: contentLink formControl and preferredContacts formArray
  // @effects: emits saveProfile, deletes old user contact information,
  //           adds contact information from forms
  next() {
    let candidateProfile = this.contactForm.value;
    let newLinks = []
    //make sure parent can read values for display by adding name etc.
    candidateProfile.preferredContacts.forEach(contact => {
      newLinks.push({
        contactType: this.contactTypes.find(e => e.id == contact.contactType),
        contact: contact.contact
      });
    });

    candidateProfile.preferredContacts = newLinks;

    console.log(candidateProfile);

    this._talentAPIService.updateProfile(candidateProfile)
      .subscribe((result:any) => {
        this.saveProfile.emit(candidateProfile);
        if(this.edit === true) {
          this.dialogRef.close(candidateProfile);
        }
      }, 
      error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
    	});
  }

  // Adds a FormGroup to the preferredContactsArray,
  // called on population and on ADD
  addContact() {
    this.Contacts.push(this.initPerfContact());
  }

  removeContact(index:number) {
    this.Contacts.removeAt(index);
  }

  // @params:   linkContacts of type CandidateLinkContacts
  // @effects:  if data is available, then the two forms will 
  //            populate with the CandidateLinkDara
  populate(linkContacts: CandidateLinkContacts) {
    if(linkContacts && (linkContacts.preferredContacts || linkContacts.contentLink)) {
      let populator = {
       'contentLink': linkContacts.contentLink,
       'preferredContacts': []
      };
      linkContacts.preferredContacts.forEach(l => {
        if(this.Contacts.length<linkContacts.preferredContacts.length){
          this.addContact();
        }
        populator.preferredContacts.push({
           contactType: l.contactType.id,
           contact: l.contact
        });
      });
      this.contactForm.patchValue(populator);
    }
  }
}
