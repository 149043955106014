import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CompanyInfoService } from '../../../Services/company-info.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-metric-detail',
  templateUrl: './metric-detail.component.html',
  styleUrls: ['./metric-detail.component.css'],
})
export class MetricDetailComponent implements OnInit {
  @Input() metricId;
  @Output() metricCounter: EventEmitter<any> = new EventEmitter();
  @ViewChild('outerSort') outerSort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  dataSource: MatTableDataSource<any> = null;
  expandedDataSource: MatTableDataSource<any> = null;
  expandedRow = null;
  advancedCandidates = 0;
  shortlistedCandidates = 0;

  tableData = [];
  displayedColumns = [];
  expandedDetailColumns = [];
  metricType: any; // flag used to manage metric-type specific dropdown controls

  constructor(
    public _companyInfoService: CompanyInfoService,
    public _router: Router
  ) { }

  ngOnInit(): void {
    // gets (mostly) formatted table data from the backend, some small changes are made
    this._companyInfoService.getSubscriptionMetricDetail(this.metricId).subscribe((result:any) => {
      this.displayedColumns = result['columnNames'];
      this.expandedDetailColumns = result['expandedColumnNames'];
      this.metricType = result['metricType'];
      console.log(this.metricType);
      if(this.metricType === 'Postings') {
        this.formatPostingsMetricData(result['data']);
      }
      else {
        this.formatData(result['data'], result['metricType']);
      }
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sort = this.outerSort;
      this.dataSource.sortData = this.sortData;
    });
  }
 
  formatPostingsMetricData(metricDetails) {
    metricDetails.forEach(user => {
      if(user['Account Status'] == 1) {
        user['Account Status'] = 'Active';
      }
      else {
        user['Account Status'] = 'Inactive';
      }
      user['Usage'] = user['postings'].length;
      user['selectable'] = false;
      user['expanded'] =  false;
      if(user['postings'].length > 0) {
        user['selectable'] = 'Usage';
        user['postings'].forEach(posting => {
          posting['Date Posted'] = this.formatDate(posting['Date Posted']);
        });
      }
      if (user['Usage'] > 0){
        this.advancedCandidates++;
      }
    });
    this.tableData = metricDetails;
    this.metricCounter.emit(this.advancedCandidates);

  }

  formatData(metricDetails, type) {
    console.log(metricDetails);
    metricDetails.forEach(entry => {
      if(entry['Account Status'] !== undefined) {
        if(entry['Account Status'] == 1) {
          entry['Account Status'] = 'Active';
        }
        else {
          entry['Account Status'] = 'Inactive';
        }

        if(entry['verified'] == 0) {
          entry['Account Status'] = 'Unverified';
        }
      }      

      if (type == "Shortlists"){
        this.shortlistedCandidates += parseInt(entry['Number of Candidates']);
      }
      entry['Date Added'] = this.formatDate(entry['Date Added']);
      entry['selectable'] = false;
      if(this.metricType === 'Shortlists') entry['selectable'] = true;
      entry['expanded'] =  false
    });
    this.tableData = metricDetails;
    if (type == "Shortlists"){
      this.metricCounter.emit(this.shortlistedCandidates);
    }
    
  }

  // Currently, only postings has an expandable row type
  // sets expanded flag and populates row data from data in the table
  clickRow(row, i) {
    if(this.metricType === 'Postings') {
      if(row['Usage'] > 0) {
        if(row.expanded) {
          row.expanded = false;
        }
        else {
          this.dataSource.data.forEach(element => {
            element.expanded = false;
          });
          row.expanded = true;
          this.expandedDataSource = new MatTableDataSource(row['postings']);
          let i = this.tableData.indexOf(row);
          this.expandedDataSource.sort = this.innerSort.toArray()[i];
          this.expandedDataSource.sortData = this.sortData;
        }
      }
    }
    if(this.metricType === 'Shortlists') {
      this.redirect(row);
    }
  }

  // Formats to MM/DD/YY
  formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		return [month, day, year.toString().substring(2)].join('/');
	}

  redirect(selection) {
    if(this.metricType === 'Postings') {
      this._router.navigate(['/postings/' + selection.id]);
    }
    if(this.metricType === "Shortlists") {
      this._router.navigate(['/shortlist/' + selection.id]);
    }
  } 
  
  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // requires a sorter to be able to sort our parsed dates as well as traditional strings
  sortData = function(data, sort) {
    const active = sort.active;
    const direction = sort.direction;
    let regexp = /^\d{1,2}\/\d{1,2}\/\d{2}$/;
    return data.sort((a, b) => {
      let aValue = this.sortingDataAccessor(a, active);
      let bValue = this.sortingDataAccessor(b, active);
      let comparatorResult = 0;
      if(regexp.test(aValue) && regexp.test(bValue)) { // MM/DD/YY comparator
        let aDate = aValue.split('/');
        let bDate = bValue.split('/');
        if(aDate[2] === bDate[2] && aDate[0] === bDate[0]) {
          comparatorResult = parseInt(aDate[1]) > parseInt(bDate[1]) ? 1 : -1;
        }
        else if(aDate[2] === bDate[2]) {
          comparatorResult = parseInt(aDate[0]) >  parseInt(bDate[0]) ? 1 : -1;
        }
        else {
          comparatorResult = parseInt(aDate[2]) >  parseInt(bDate[2]) ? 1 : -1;
        }
      }
      else { // classic string comparator
        comparatorResult = aValue > bValue ? 1 : -1;
      }
      if(direction === '') return 0;
      return comparatorResult * (direction === 'asc' ? 1 : -1); 
    });
  }
}
