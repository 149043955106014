import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../Services/login.service';
import { DashboardService } from '../../../Services/dashboard.service';
import { UserInfoService } from '../../../Services/user-info.service';
import { EmployerMetrics } from '../../../Models/EmployerMetrics';
import { NgxChartsModule } from '@swimlane/ngx-charts';



@Component({
  selector: 'app-employer-overview',
  templateUrl: './employer-overview.component.html',
  styleUrls: ['./employer-overview.component.css']
})
export class EmployerOverviewComponent implements OnInit {
  public userType:number;
  public ErrorMessage:string = "";
  public metrics:EmployerMetrics = {
    successRate: 0,
    activePostings: "",
    totalApplicants: "",
    shortlisted: "",
    inPipeline: "",
  };
  myEventSubscription:any;

  constructor(private _loginService: LoginService, private _dashboardService: DashboardService,
    private _userInfoService: UserInfoService ) { } 

  ngOnInit(): void {
    this.getEmployerOverview();
  }

  getEmployerOverview() {
    this.ErrorMessage = "";
    this._dashboardService.getEmployerOverview().subscribe((result: any) => {
      if (result != undefined && result.length > 0) {
        if (result[0].shortlist == null) {
          result[0].shortlist = 0;
        }
        if (result[0].applicant_count == null) {
          result[0].applicant_count = 0;
        }
        this.metrics = {
          successRate: Math.round((Number(result[0].hired_count) / Number(result[0].positions_count) * 100)),
          activePostings: result[0].active_count,
          totalApplicants: result[0].applicant_count,
          shortlisted: result[0].shortlist,
          inPipeline: result[0].pipeline
        }
      }
      else {
        this.metrics = {
          successRate: Math.round(0 * 100),
          activePostings: '0',
          totalApplicants: '0',
          shortlisted: '0',
          inPipeline: '0'
        }
      }
    },
      error => {
        this.ErrorMessage = "Unable to load News feed";
        console.log(this.ErrorMessage);
      }
      );
  }


}
