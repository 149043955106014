<form name="classSelector" id="classSelector" class="" #classSelector="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
            <h4 mat-dialog-title>Failed to register this email address</h4>
            <div mat-dialog-content class="mat-block">
                <label *ngIf="errorType == 'userExists'" class="termslink">This email is already registered. Please 
                    <a class="blue-underlined" (click)="goToResetPassword()">reset password</a> if needed.
                </label>
                <label *ngIf="errorType == 'userInvited'" class="termslink">This email has been invited to register as an employer. Please 
                    <a class="blue-underlined" (click)="goToEmployerRegister()">create an employer account.</a>
                </label>
                
            </div>
            <div mat-dialog-actions align="end">
                <button mat-button type="button" (click)="closeDialog()">
                    Return to Registration Form
                </button>
            </div>
        </div>
    </div>
</form>
