import { Component, Input, OnInit, Output, EventEmitter, Optional} from '@angular/core';
import { TalentApiService } from '../../../../Services/talent-api.service';
import { UntypedFormBuilder, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-candidate-availability-status',
  templateUrl: './candidate-availability-status.component.html',
  styleUrls: ['./candidate-availability-status.component.css']
})
export class CandidateAvailabilityStatusComponent implements OnInit {
  @Input() availabilityStatus;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  public candidateAvailabilityStatus;
  public breakpoint:number; // used to classify the screen size/display width

  public form;

  constructor(private _talentAPIService: TalentApiService,
    private fb: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    this.getCandidateAvailabilityStatus();
    this.form = this.fb.group({
      availabilityStatus: [this.availabilityStatus, [Validators.required]]
    });
  }
  getCandidateAvailabilityStatus() {
    this._talentAPIService.getCandidateAvailabilityStatus()
			.subscribe(
			(result:any) => {
          this.candidateAvailabilityStatus = result;
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }
  statusChange(event){
    this.availabilityStatus = event.value;
  }
  next() {
    const availabilityStatus = this.availabilityStatus;
    const candidateProfile = {
      availabilityStatus: availabilityStatus
    }
    this._talentAPIService.updateProfile(candidateProfile)
			.subscribe(
			(result:any) => {
        if (result) {
          this.saveProfile.emit(this.availabilityStatus);
          var data = availabilityStatus;
          if(this.edit === true) {
            this.dialogRef.close(data);
          }
        }
        else {

        }
			},
			error => {
        this._talentAPIService.snackbarMessage('Something went wrong!');
			});
  }
  prev() {
		this.prevEvent.emit();
  }
  skip() {
		this.saveProfile.emit(this.availabilityStatus);
	  }

}
