<div id="studentDetail" [ngClass]="{'col-xs-5': showChoices == 1, 'col-xs-8': showChoices > 0}" class="student-detail-header mat-dialog-container mat-dialog-fullscreen" >
      <form name="fbTemplateForm" class="popup_class_new feedback_popup_" #fbTemplateForm="ngForm"   novalidate ng-cloak>
      <mat-toolbar mat-toolbar class="mat-default-theme" [hidden]="showChoices==8">

        <!-- // ============= showChoices == 1 ===============================
        // ============== selection of template ======================== -->
          <div class="mat-toolbar-tools background-primary background-white" *ngIf="showChoices == 1">
            <h1 mat-dialog-title class="calendar-md-title">Select Feedback Template for {{studentName}}</h1>
            <div class="close_back_button">
                <button class="mat-icon-button close_bt" (click)="cancel()"  matTooltip=" Closing the Dialog will cancel the student's requst."> <i class="fa fa-times" aria-hidden="true"></i> </button>
          
            </div>
            </div>

        <!-- // ============= showChoices > 3 && showChoices < 7 ==============
        // ============== ======================== -->
          <div class="mat-toolbar-tools background-primary " *ngIf="showChoices > 3 && showChoices < 7 ">

            <h1 mat-dialog-title class="calendar-md-title">Create a custom form for {{studentName}}
               
            </h1>
            <div class="close_back_button">
                
                <button class="mat-icon-button close_bt" (click)="cancel()"  matTooltip=" Closing the Dialog will cancel the student's requst."> <i class="fa fa-times" aria-hidden="true"></i> </button>
                <button class="mat-icon-button close_bt" (click)="goBack()"  matTooltip=" Go back to previous Dialog.">  <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i></button>

            </div>
             
            
           </div>

         <!-- // ============= showChoices == 2 || 3  ==========================
        // ============== listed four tabs and select details ======================== -->
        <div class="mat-toolbar-tools background-primary " *ngIf="showChoices == 2 ||  showChoices == 3">

            <h1 mat-dialog-title class="calendar-md-title">Selected Template for {{studentName}}</h1>
                    
            <div class="close_back_button">
                <button class="mat-icon-button close_bt" (click)="cancel()"  matTooltip=" Closing the Dialog will cancel the student's requst."> <i class="fa fa-times" aria-hidden="true"></i> </button>
                <button class="mat-icon-button" (click)="goBack()"  matTooltip="Go back to previous Dialog.">  <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i></button>
                
           </div>
        </div>


        <div class="mat-toolbar-tools background-primary " *ngIf="showChoices == 7">

                <h1 mat-dialog-title class="calendar-md-title">Save the custom template</h1>
                        
                    <div class="close_back_button">
                        <button class="mat-icon-button" (click)="goBack()"  matTooltip="Go back to previous Dialog.">  <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i></button>
          
                        <button class="mat-icon-button close_bt" (click)="cancel()" > <i class="fa fa-times" aria-hidden="true"></i> </button></div>
    
                 </div>
    


      </mat-toolbar>




      <mat-dialog-content  class="bgpopup_in">
        <mat-card *ngIf = "showChoices == 1">  
            <div layout="column" layout-align="start start" ng-cloak ng-cloak>
                <div class="col-lg-6 text-left" >
                    <mat-form-field color="accent" appearance="outline">
                        <mat-select placeholder="Templates" [(ngModel)]="selectedTemplateName" #templateName="ngModel" name="templateName"
                            class="form-control" required>
                            <mat-option *ngFor="let template of templates;let last=last;" [value]="template.name">
                                    {{template.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-card>


        <mat-tab-group *ngIf="showChoices == 2 || showChoices == 3 || showChoices == 4"  (selectChange)="setShowChoices($event)" >
            <mat-tab class="tab-weight" label="Skills">
                <mat-card>
                        <em>Enter Skills that your student should be rated on, Separated by Comma.</em>
                    <tag-input [(ngModel)]="selectedTemplate[0].stars" name="templateSkills"  [allowDupes]="false"
                    [placeholder]="'Add More Skills'" [displayBy]="'name'"
                    [identifyBy]="'name'" [secondaryPlaceholder]="'Enter Skills Here'" class="mat-form-field" [separatorKeyCodes]="keys">
                    </tag-input>
                </mat-card>
            </mat-tab>
            <mat-tab class="tab-weight" label="Questions" >
                <mat-card>
                    <form>
                        <mat-form-field color="accent" appearance="outline" class="md-block" *ngFor="let question of selectedTemplate[0].questions;let i=index">
                            <input placeholder="Enter a Question"  matInput name="questionName{{i}}" i type="text" [(ngModel)]="question.name" #questionName="ngModel"  >
                        </mat-form-field>
                    </form>  
                    <div class="pull-right">
                        <i class="fa fa-plus fa-lg fa-border" aria-hidden="true" (click)="addOptions(showChoices)" matTooltip="Add another field.">
                        </i>
      
                        <i class="fa fa-minus fa-lg fa-border" aria-hidden="true" (click)="removeOption(showChoices)" matTooltip="Delete the last option.">
                        </i>
                    </div>
                </mat-card>
            </mat-tab>

            <mat-tab class="tab-weight" label="Check Boxes" >
                <mat-card>
                    <form>
                        <div class="col-lg-12" *ngFor="let checkBox of selectedTemplate[0].checkboxes;let i=index">
                            <mat-form-field color="accent" appearance="outline" class="md-block col-lg-8" flex-gt-xs>
                                <input placeholder="Type your Question"  matInput name="checkBoxQuestion{{i}}"  type="text" [(ngModel)]="checkBox.question"  spellcheck="true" >
                            </mat-form-field>
                            <div class="col-lg-5">
                                <div *ngFor="let option of checkBox.boxes;let inneri=index">
                                    <mat-checkbox [disabled]="true"  class="col-lg-1" style="margin-top: 24px" >
                                    </mat-checkbox>
                                    <mat-form-field color="accent" appearance="outline" class="md-block col-lg-11">
                                        <div class="col-lg-8 strip-margins no-padding">
                                            <input type="text" placeholder="Option" matInput spellcheck="true" [(ngModel)]="option.name" name="optionName{{inneri}}{{i}}" >
                                        </div>   
                                        <div class="col-lg-3 strip-margins" style="padding-top:5px;">
                                            <i class="fa fa-times-circle-o col-lg-2" aria-hidden="true" (click)="removeOption(showChoices+1, option, checkBox)" matTooltip="Delete The Option.">
                                              
                                            </i>
                                        </div>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-8 strip-margins" style="margin-left:17px;">
                                <i class="fa fa-plus-square-o fa-lg" aria-hidden="true" (click)="addOptions(showChoices+1,i)" matTooltip="Add more option.">
                                </i>
                            </div>

                        </div>
                    </form>
                    <div class="pull-right" style="margin-top:5px;">
                        <i class="fa fa-plus fa-lg fa-border" aria-hidden="true" (click)="addOptions(showChoices)" matTooltip="Add another field.">
                        </i>        
                        <i class="fa fa-minus fa-lg fa-border" aria-hidden="true" (click)="removeOption(showChoices)" matTooltip=">Delete the last option.">
                        </i>
                    </div>
                </mat-card>
            </mat-tab>


            <mat-tab class="tab-weight" label="Options" >
                <mat-card>
                    <form>
                        <div class="row" >
                            <div class="col-md-6" *ngIf= "showSaveTemplate">
                                <mat-slide-toggle  aria-label="Switch No Ink" [(ngModel)]="saveAsTemplate" name="saveAsTemplate" id="saveAsTemplate">
                                <span class="heading">Save this as a template?</span> 
                        </mat-slide-toggle>
                        <mat-form-field color="accent" appearance="outline" class="md-block" style="margin-top:10px;"  *ngIf = "saveAsTemplate">
                                <input type="text" placeholder="Template Name" matInput [(ngModel)]="formName" name="formName" >
                            </mat-form-field>
                    
                    </div>
                            <div class="col-md-6">
                                    <div layout-align="end start">
                                            <mat-form-field color="accent" appearance="outline" layout-align="end">
                                                <input matInput  [matDatepicker]= "startDatepicker"  name="dueDate"  [(ngModel)]="templatesDueDate" required placeholder="Due date"  [min]="minDate" >
                                                <mat-datepicker-toggle matSuffix [for]="startDatepicker"  style="margin-left:3vw;"> </mat-datepicker-toggle>
                                                <mat-datepicker #startDatepicker></mat-datepicker>                                
                                            </mat-form-field>
                                        </div>
                            </div>
                            
                            
                            
                        

                        </div>
                       
                    </form>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
     </mat-dialog-content>

     <mat-dialog-actions  layout="row" class="footer_popUp posting_pop">
        <div class="col-lg-12">
            <button mat-button *ngIf="showChoices == 1" class="pull-right md-raised md-accent" (click)="showSelectedTemplate()" [disabled]="selectedTemplateName==''">Select</button>

            <button  *ngIf = "showChoices != 1 && !approveAll" class="pull-right" [ngClass]="{ 'class_add_btn': fbTemplateForm.valid && templatesDueDate!= ''  }" mat-button mat-raised-button color="primary" (click)="approveStudent()" [disabled] = "!fbTemplateForm.valid || templatesDueDate== '' " > 
                Submit
            </button>


            <button  *ngIf = "showChoices != 1 && approveAll" class="pull-right" [ngClass]="{ 'class_add_btn': fbTemplateForm.valid && templatesDueDate!= ''  }" mat-button mat-raised-button color="primary" (click)="feedbackApproveAll()" [disabled] = "!fbTemplateForm.valid || templatesDueDate== '' " > 
                Approve All
            </button>


            
        </div>
    </mat-dialog-actions>

  </form>


  </div>