import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../Pages/profile-edit/profile-edit.component';

import { CandidateEducation } from '../../../Models/CandidateProfile'
import { TalentApiService } from '../../../Services/talent-api.service';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';


@Component({
  selector: 'app-education-view',
  templateUrl: './education-view.component.html',
  styleUrls: ['./education-view.component.css']
})
export class EducationViewComponent implements OnInit {
  @Input() ownProfile: boolean;
  @Input() education: any;

  public showingMore = false;
  public dateOptions = {
    month: 'long',
    year: 'numeric'
  }
  public candidateDegrees = [];
  public logos:any[] = []

  constructor(private _talentApiService:TalentApiService,
    public _dialog: MatDialog,
    private sharedUtils: SharedUtilitiesService) { }

  ngOnInit(): void {
    this.setCandidateDegrees();
  }

  toggleShow() {
    if(this.showingMore == true) {
      this.showingMore = false;
    }
    else {
      this.showingMore = true;
    }
  }

  openEdit() {
    let dialog = this._dialog.open(ProfileEditComponent,{
      height: 'auto',
      width: '80%',
      disableClose: true,
      data: {
              subComponent: 'candidate-education',
              seedData: this.education
            }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        if (result == null) {
        } else {
          console.log(result);
          this.education = result;
          this.setCandidateDegrees();
        }
      });   
  }

  setDateFormt(el) {
    el.startDate = el.startDate != null ? new Date(el.startDate) : null;
    el.endDate = el.endDate != null ? new Date(el.endDate) : null;
  }

  setCandidateDegrees() {
    this.candidateDegrees = [];
    this.education.candidateDegrees.tradeSchool.forEach(el => {
      this.candidateDegrees.push(el);
      this.setDateFormt(el);
    });
    this.education.candidateDegrees.associatesDegree.forEach(el => {
      this.candidateDegrees.push(el);
      this.setDateFormt(el);
    });
    this.education.candidateDegrees.mastersDegree.forEach(el => {
      this.candidateDegrees.push(el);
      this.setDateFormt(el);
    });
    this.education.candidateDegrees.bachelorsDegree.forEach(el => {
      this.candidateDegrees.push(el);
      this.setDateFormt(el);
    });
    this.education.candidateDegrees.doctorate.forEach(el => {
      this.candidateDegrees.push(el);
      this.setDateFormt(el);
    });

  }
}
