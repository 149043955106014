import {Component, Inject, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AdminApiService } from '../../../../app/Services/admin-api.service';

@Component({
  selector: 'app-email-template-edit',
  templateUrl: './email-template-edit.component.html',
  styleUrls: ['./email-template-edit.component.css']
})
export class EmailTemplateEditComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data, private _adminApiService:AdminApiService, private dialogRef:MatDialogRef<EmailTemplateEditComponent>) {}

  public form = new UntypedFormGroup({
		template_name: new UntypedFormControl('', [
			Validators.required
		]),
		sender: new UntypedFormControl('', [
			Validators.required,
      Validators.email
		]),
    sender_password: new UntypedFormControl('', [
			Validators.required
		]),
    subject: new UntypedFormControl('', [
			Validators.required
		]),
    body: new UntypedFormControl('', [
			Validators.required
		]),
    html: new UntypedFormControl('', [])
	});

  ngOnInit() {
    if(this.data.type == 'edit') {
      this.data.template.html == '0' ? this.data.template.html = false : this.data.template.html = true;
      this.form.patchValue(this.data.template);
    }
  }

  createEmailTemplate() {
    this._adminApiService.createEmailTemplate(this.form.value).subscribe((result:any) => { 
      location.reload();
    });
  }

  editEmailTemplate() {
    this._adminApiService.editEmailTemplate(this.data.template.id, this.form.value).subscribe((result:any) => { 
      location.reload();
    });
  }

  deleteEmailTemplate() {
    this._adminApiService.deleteEmailTemplate(this.data.id).subscribe((result:any) => { 
      location.reload();
    })
  }
}