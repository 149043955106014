<div fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="end start">
        <mat-form-field fxFlex="25">
            <input matInput (keyup)="applyFilter($event)" #input placeholder="Search">
        </mat-form-field>
    </div>
    <table mat-table class="outlined" [dataSource]="dataSource" fxFlex="100"  
           #outerSort="matSort" matSort multiTemplateDataRows>
        <ng-container *ngFor="let column of displayedColumns; let j = index" cdkColumnDef="{{ column }}">
            <mat-header-cell *matHeaderCellDef class="medium-16 no-padding" mat-sort-header> {{ column }} </mat-header-cell>
            <mat-cell *matCellDef="let row" fxLayoutAlign="space-between center" (click)="clickRow(row)"
                      [ngClass]="{ 'medium-16': j == 0, 'medium-14': j != 0 }" class="no-padding">
                {{ row[column] }} 
                <ng-container *ngIf="row.selectable === column">
                    <mat-icon *ngIf="!row.expanded">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="row.expanded">keyboard_arrow_up</mat-icon>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="expandedDetail">
            <mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" 
                      fxLayout="row" [ngClass]="{ 'collapsed': !row.expanded }" class="no-padding">
                <mat-table [dataSource]="expandedDataSource" fxFlex="99" #innerSort="matSort" matSort>
                    <ng-container *ngFor="let column of expandedDetailColumns" cdkColumnDef="{{ column }}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="medium-14 inset-cells"> {{ column }} </mat-header-cell>
                        <mat-cell *matCellDef="let insetRow;" class="medium-15 inset-cells">
                            {{ insetRow[column] }} 
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="expandedDetailColumns" class="inset-rows"></mat-header-row>
                    <mat-row *matRowDef="let insetRow; columns: expandedDetailColumns;"
                    class="inset-rows clickable" (click)="redirect(insetRow)"></mat-row>
                </mat-table>
            </mat-cell>
        </ng-container>
        <!-- Header Column Labels -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" 
                 [ngClass]="{ 'expanded-row': row.expanded, 'clickable': row.selectable }"></mat-row>
        <mat-row *matRowDef="let detail; columns: ['expandedDetail']"
                 [ngClass]="{ 'collapsed': !detail.expanded }"></mat-row>
    </table>
</div>