<mat-card class="mat-elevation-z4 student-card" fxFlex.xs="Fill" [ngClass]="{'student-card-small': breakpoint == 0, 'student-card-regular': breakpoint != 0}">
    <mat-card-header fxLayout="row">
      <div fxFlex></div>
      <mat-icon (click)="getShortlistDetails(data.id)" class="cursor-pointer">bookmark_border</mat-icon>
    </mat-card-header>
    <div fxLayout="row" fxLayoutAlign="center">
    <img mat-card-image class="card-round-image" src="{{data?.profilePic}}" onError="this.src='../../assets/img/User-blue-icon.png';">
    </div>
    <mat-card-title class="title">{{data.user}}</mat-card-title>
    <mat-card-subtitle class="margintop8px" >{{data.title}}</mat-card-subtitle>
    <mat-card-content class="content" [ngClass.gt-lg]="'card-content-height-gt-lg'" [ngClass.lg]="'card-content-height-lg'" [ngClass.md]="'card-content-height-md'" [ngClass.xs]="'card-content-height-xs'" [ngClass.sm]="'card-content-height-sm'" [ngClass.lt-md]="'card-content-height-lg'">
      <div fxLayout="row" fxLayoutAlign="center">
        <div fxLayout="column" fxFlex="95" fxLayoutGap="8px" fxLayoutGap.lg="12px">
          <div fxLayout="row" class="set-fixed-height" id="skillListContainer">
            <mat-chip-list cdkDropList cdkDropListOrientation="horizontal" id="skillList">
                <mat-chip color="accent" *ngFor="let skill of inputSkillChips" [removable]="true" (destroyed)="updateChipList()" cdkDrag>
                  {{skill}}
                </mat-chip>
            </mat-chip-list>
          </div>          
          <div fxLayout="row" class="mat-display-4">
            <div fxLayout="column" fxFlex="13" fxFlex.gt-lg="8">
              <mat-icon *ngIf="(data.location!=null && data.location !='' || data.state!=null && data.state !='')">room</mat-icon>
            </div>
            <div fxLayout="column" fxFlex="87" fxFlex.gt-lg="92">
             <mat-label>
              <span *ngIf="data.location!=null && data.location !=''">{{data.location | titlecase }}</span>
              <span *ngIf="(data.location!=null && data.location !='' && data.state!=null && data.state !='')">, </span>
              <span *ngIf="data.state!=null && data.state !=''">{{data.state | uppercase }}</span></mat-label>
            </div>
          </div><div class="margintop1px"></div>
          <div fxLayout="row" class="mat-display-4">
            <div fxLayout="column" fxFlex="13" fxFlex.gt-lg="8">
              <mat-icon *ngIf="data.degreetrack!='' || data.levelOfEducation !=''">school</mat-icon>
            </div>
            <div fxLayout="column" fxFlex="87" fxFlex.gt-lg="92">
              <mat-label>{{data.degreetrack}} <span *ngIf="data.levelOfEducation !='' && data.levelOfEducation !=null"> in {{data.levelOfEducation}}</span></mat-label>
            </div>
          </div><div class="margintop1px"></div>
          <div fxLayout="row" class="mat-display-4">
            <div fxLayout="column" fxFlex="13" fxFlex.gt-lg="8">
              <mat-icon *ngIf="data.availabilityType!=''">access_time</mat-icon>
            </div>
            <div fxLayout="column" fxFlex="87" fxFlex.gt-lg="92">
              <mat-label>{{data.availabilityType}}</mat-label>
            </div>
          </div>
        </div>
      </div>
      
    </mat-card-content>
    <mat-card-actions class="actions">
      <div fxLayout="row" fxLayoutAlign="center">
        <button mat-button color="primary" (click)="getStudentAllDetails(data.id)">VIEW PROFILE</button>
      </div>
    </mat-card-actions>
  </mat-card>
