import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyInfoService } from '../../../Services/company-info.service';
import { TalentApiService } from '../../../Services/talent-api.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from '../../dialogs/common-confirm-popup/common-confirm-popup.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-admin-my-users',
  templateUrl: './company-admin-my-users.component.html',
  styleUrls: ['./company-admin-my-users.component.css']
})
export class CompanyAdminMyUsersComponent implements OnInit, OnDestroy {
  public add = [];
  public remove = [];
  public selected = [];
  public user = null;
  public userDetailData: any = [];
  public userGroups: any = {};
  public columnHeaders: string[] = ['name', 'email', 'groups', 'status'];
  public statusOptions: string[] = ['Active', 'Inactive', 'Invite Pending'];
  public dataSource: MatTableDataSource<any> = null;
  public companyGroups: any = [];
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSort) sort: MatSort;
  largeScreen: boolean = false;

  constructor(
    private _companyInfoService: CompanyInfoService,
    private _talentAdminApiService: TalentApiService,
    public _dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    public _router: Router
  ) { }

  ngOnInit(): void {
    this._companyInfoService.getGroups().subscribe((result: any) => {
      this.companyGroups = result;
      this.updateDataSource();
    });
    this.breakpointObserver.observe(['(min-width: 959px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.largeScreen = true;
        } else {
          this.largeScreen = false;
        }
      });
  }

  updateDataSource() {
    this._companyInfoService.getCompanyUserData().subscribe((result: any) => {
      this.userDetailData = result;
      this.userGroups = {};
      this.userDetailData.forEach(element => {
        let groups = [];
        if (element.name && typeof element.name !== 'undefined') {
          switch (element.status) {
            case ("0" || "Inactive"):
              element.status = "Inactive";
              break;
            case ("1" || "Active"):
              element.status = "Active";
              break;
            default: "Unknown"
          }
          element.groups.forEach(group => {
            groups.push(group.name);
          });
          this.userGroups[element.id] = groups;
          element.groups = groups;
        }
        else {
          element.id = element.email;
          element.name = element.email;
          element.status = "Invite Pending";
          element.verified = 0;
          let parsedGroups = element.groups.split(',');
          parsedGroups = parsedGroups[0] == "" ? [] : parsedGroups;
          parsedGroups.forEach(groupId => {
            let groupFound = this.companyGroups.find(group => group.id == groupId);
            if (groupFound) {
              groups.push(groupFound.name);
            }
          });
          element.groups = groups;
          this.userGroups[element.id] = groups;
        }
      });
      this.dataSource = new MatTableDataSource(this.userDetailData);
      this.dataChange.emit(this.dataSource.data);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = (data, filter) => this.filterFunction(data, filter);
    });
  }

  ngOnDestroy() {
    if (this.add.length || this.remove.length) {
      //this.onClose(this.user, false);
    }
  }

  getGroupIdsFromNames(groupNames) {
    let ids = [];
    if (groupNames.length) {
      groupNames.forEach(group => {
        ids.push(this.companyGroups.find(cmpGroup => cmpGroup.name === group).id);
      });
    }
    return ids;
  }

  onClose(user, event) {
    if (event) {
      this.add = [];
      this.remove = [];
      this.selected = user.groups;
      this.user = user;
    }
    else {
      user.groups = this.selected;
      if (user.status === "Invite Pending") {
        console.log(this.selected);
        if (this.add.includes("Admin")) {
          this.confirmationDialog(user, null, null, this.getGroupIdsFromNames(this.selected), this.remove.includes("Admin"));
        }
        else if (this.remove.includes("Admin")) {
          this.confirmationDialog(user, null, null, this.getGroupIdsFromNames(this.selected), this.remove.includes("Admin"));
        }
        else if (this.add.length || this.remove.length) {
          this._companyInfoService.updateInvitedUserGroup(user.email, this.getGroupIdsFromNames(this.selected)).subscribe();;
        }
      }
      else {
        if (this.remove.includes("Admin") || this.add.includes("Admin")) {
          this.confirmationDialog(user, this.getGroupIdsFromNames(this.add), this.getGroupIdsFromNames(this.remove), null, this.remove.includes("Admin"));
        }
        else {
          for (let addIndex = 0; addIndex < this.add.length; addIndex++) {
            let groupId = this.companyGroups.find(group => group.name === this.add[addIndex]).id;
            this.addUserToGroup(user.id, groupId);
          }
          for (let removeIndex = 0; removeIndex < this.remove.length; removeIndex++) {
            let groupId = this.companyGroups.find(group => group.name === this.remove[removeIndex]).id;
            this.removeUserFromGroup(user.id, groupId);
          }
        }
      }
    }
  }

  calculateDifference(user, event) {
    this.add = event.value.filter(group => !user.groups.includes(group));
    this.remove = user.groups.filter(group => !event.value.includes(group));
    this.selected = event.value;
  }

  /*----------------------------------------------------------------|
  | This functions is called when the user selects Admin            |
  | as the group being toggled. The user will be presented          |
  | with a dialig in which they will need to confirm the action.    |
  |----------------------------------------------------------------*/
  confirmationDialog(user, add, remove, selected, removeAdmin) { // true when removing, false when adding to admin group
    let dialog;
    dialog = this._dialog.open(CommonConfirmPopupComponent, {
      height: '175',
      width: '36.66%',
      disableClose: false,
    });
    if (removeAdmin) {
      dialog.componentInstance.post_type = 'Remove Admin Privileges';
      dialog.componentInstance.confirm_message = 'Are you sure you want to remove Admin privileges from this user?';
    }
    else {
      dialog.componentInstance.post_type = 'Add Admin Privileges';
      dialog.componentInstance.confirm_message = 'Are you sure you want to grant this user Admin privileges?';
    }
    if (selected) {
      return dialog.afterClosed().subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          this._companyInfoService.updateInvitedUserGroup(user.email, selected).subscribe();
        }
        else {
          this.updateDataSource();
        }
      });
    }
    else {
      return dialog.afterClosed().subscribe((result) => {
        if (dialog.componentInstance.confirmDiscardPost) {
          if (remove && this.remove.length > 0) {
            for (let removeIndex = 0; removeIndex < this.remove.length; removeIndex++) {
              let groupId = this.companyGroups.find(group => group.name === this.remove[removeIndex]).id;
              this.removeUserFromGroup(user.id, groupId);
            }
          }
          if (add && this.add.length > 0) {
            for (let addIndex = 0; addIndex < this.add.length; addIndex++) {
              let groupId = this.companyGroups.find(group => group.name === this.add[addIndex]).id;
              this.addUserToGroup(user.id, groupId);
            }
          }
        }
        else {
          this.updateDataSource();
        }
      });
    }
  }

  /*------------------------------------------|
  | A custom filter function is needed when   |
  | filtering out users since the data source |
  | contains columns that are not displayed   |
  | to the user, therfore they should not be  |
  | be considered when applying a filter      |
  |------------------------------------------*/
  filterFunction(element, searchValue) {
    if (searchValue) {
      let key = searchValue.trim().toLowerCase();
      if (
        element.name.toLowerCase().includes(key) ||
        element.email.toLowerCase().includes(key) ||
        element.status.toLowerCase().includes(key) ||
        element.groups.join('').toLowerCase().includes(key)
      ) {
        return true;
      }
      return false;
    }
  }

  /*----------------------------------------------------|
  | This functions reads the status of the 2-way binded |
  | status property of the data row passed into it.     |
  | Conditionally, it will call the correspinding API   |
  | in the employer controller                          |
  |----------------------------------------------------*/
  updateAccountStatus(row) {
    if (row.status == "Active") {
      this._companyInfoService.setCompanyUserActive(row.id).subscribe((result: any) => {
        if (result) {
          this._talentAdminApiService.snackbarMessage("User successfully set to active");
        }
        else {
          this.updateDataSource();
          this._talentAdminApiService.snackbarMessage("Failed to update user status");
        }
      });
    }
    else if (row.status == "Inactive") {
      this._companyInfoService.setCompanyUserInactive(row.id).subscribe((result: any) => {
        if (result) {
          if (result.response.statusType == 'success' && result.response.msg != undefined) {
            this.updateDataSource();
            this._talentAdminApiService.snackbarMessage(result.response.msg);
          }
          else {
            this._talentAdminApiService.snackbarMessage("User successfully set to Inactive");
          }
        }
        else {
          this.updateDataSource();
          this._talentAdminApiService.snackbarMessage("Failed to update user status");
        }
      });
    }
  }

  /*------------------------------------------------------------|
  | This function takes a user Id of an active user             |
  | or an invited user and updates their group membership       |
  | by ADDING them to the specified group Id.                   |
  | the user type to update is interpreted from the post data.  |
  |------------------------------------------------------------*/
  addUserToGroup(userId, groupId) {
    this._companyInfoService.createUserGroup(userId, groupId).subscribe((result: any) => {
      if (result) {
        this._talentAdminApiService.snackbarMessage("User successfully added to group");
      }
      else {
        this.updateDataSource();
        this._talentAdminApiService.snackbarMessage("Failed to add user to group");
      }
    });
  }
  /*------------------------------------------------------------|
  | This function takes a user Id of an active user             |
  | or an invited user and updates their group membership       |
  | by REMOVING them to the specified group Id.                 |
  | the user type to update is interpreted from the post data.  |
  |------------------------------------------------------------*/
  removeUserFromGroup(userId, groupId) {
    this._companyInfoService.deleteUserGroup(userId, groupId).subscribe((result: any) => {
      if (result) {
        this._talentAdminApiService.snackbarMessage("User successfully removed from group");
      }
      else {
        this.updateDataSource();
        this._talentAdminApiService.snackbarMessage("Admin group must have one active user");
      }
    });
  }

  back() {
    this._router.navigate(['/companyAdmin']);
  }

  createNewUser() {
    this._router.navigate(['/companyAdmin/userGroups'], { queryParams: { create: true } });
  }
}
