
<div id="editing-modal" class="timesheet-popup admin_popup" role="form">
    <form name="editingForm" id="editingForm" #editingForm="ngForm" class="" novalidate ng-cloak>
        <div class="modal-content">
            <div class="modal-header">
        
            <div>
                <h1 *ngIf="!editing.id" mat-dialog-title>Add Tenant Logo</h1>
    
                <h1 *ngIf="editing.id" mat-dialog-title>Edit Tenant Logo</h1>            
                
                <button class="mat-icon-button close_bt" (click)="closeDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>
        
            </div>
     
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-12">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-label>Select Tenant </mat-label>
                            <mat-select [(ngModel)]="editing.tenant_id" name="editname" id="editname" required #editname="ngModel" [disabled]="editing.id">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let data of tenants; let i = index;" value="{{data.id}}">{{data.name}}</mat-option>
                            </mat-select>

                            <mat-error *ngIf="editname.hasError('required')">You did not select tenant name</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12">
                        <mat-form-field color="accent" appearance="outline">
                            <input placeholder="Type" matInput name="type" id="type"  [(ngModel)]="editing.type" #type="ngModel" minlength="3" maxlength="50" required (change)="editing.type=editing.type.trim()">
                        
                        <mat-error *ngIf="type.hasError('required')">You did not enter type</mat-error>
            
                        <mat-error *ngIf="!type.hasError('required') && type.hasError('maxlength')">Type must be between 3 and 15 characters long.</mat-error>
                        
                        </mat-form-field>
                    </div>

                    <div class="col-md-12">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-label>Size</mat-label>
                            <mat-select [(ngModel)]="editing.size" name="size" id="size" required #size="ngModel">
                                <mat-option>None</mat-option>
                                <mat-option value="Desktop">Desktop</mat-option>
                                <mat-option value="Mobile">Mobile</mat-option>
                            </mat-select>

                            <mat-error *ngIf="size.hasError('required')">You did not select size</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12">
                        <label for="#logo-upload">Logo</label>
                        <div>
                            <button mat-button mat-raised-button class="btn background-primary" color="primary" (click)="callback.click()">
                                Upload / Change
                                <input #callback type="file" (change)="fileChangeEvent($event)" mode="data-url" name="pic-upload" id="pic-upload" style="display:none;"
                                class="form-control" accept="image/x-png,image/gif,image/jpeg,image/svg+xml" />
                            </button>
                            <p class="help-block small animated fadeInDown mat-error ng-star-inserted" *ngIf="imageError">Only JPG, PNG or Gif images are allowed with file size of 2MB only.</p>
                        </div>
                        
                        <div class="img-box padding-top-15">
                            <img [src]="logoPreview|safeHtml" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" fxLayout="row" fxLayoutAlign="end start">
                <!-- // Edit  -->
                <div>
                    <button mat-button mat-raised-button color="warn" *ngIf="editing.id" (click)="modalDelete()" class="Cancel-btt  pull-left">Delete</button>
                </div>

                <div>
                    <button  mat-button mat-raised-button color="primary"  *ngIf="editing.id" (click)="modalSave()" class="save-bt popup_done_btn " [disabled]="!editingForm.valid" >Save</button>
                </div>
                
                <div>
                    <button  mat-button mat-raised-button *ngIf="!editing.id" [disabled]="!editingForm.valid" (click)="modalAddNew()" class="save-bt add_new ">Add New</button>
                </div>
            </div>
        </div>
    </form>
</div>