<div class="reports" fxLayout="row" fxLayoutAlign="center center">
  <mat-card fxFlex="85" class="full-height-card">
    <app-report-list [reportdetail]="reportData" [filterData]="filterData" [selectedReport]="selectedReport"  [reportList]="reportList"></app-report-list>
  <mat-divider></mat-divider>
    <app-report-details [reportdetail]="reportData"
                        (searchReport)="searchReportData($event)"
                        (cancelText)="cancelClickUpdateReport()"
                        [displayedColumns]="displayedColumns"></app-report-details>
  </mat-card>
</div>
