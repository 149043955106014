import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InviteToOpportunitiesComponent } from '../dialogs/invite-to-opportunities/invite-to-opportunities.component';
import { ProfileViewDialogComponent } from '../../Pages/dialogs/profile-view-dialog/profile-view-dialog.component';
import { UserInfoService } from '../../Services/user-info.service';
@Component({
  selector: 'app-student-small-card',
  templateUrl: './student-small-card.component.html',
  styleUrls: ['./student-small-card.component.css']
})
export class StudentSmallCardComponent implements OnInit {
  @Input() candidateData: any;
  @Input() breakpoint: number;
  @Input() isPostingView: boolean = false;
  @Input() isShortlistView: boolean = false;
  @Input() shortlistId: number;
  @Input() shortlistName: string;
  @Output() removeCandidate = new EventEmitter<number>();
  public skillWrapper;
  public listOfSkillWrappers;
  public inputSkillChips:  Array<any> = [];
  public numRemoved = 0;
  public hasManageCandidatePermission;
  constructor(private _userInfoService: UserInfoService,public _dialog: MatDialog) { }

  ngOnInit() {
    this._userInfoService.checkMyPermission('Manage Shortlist Candidates').subscribe((result: any) => {
      this.hasManageCandidatePermission = result;
    });
    if (this.candidateData.skill_list != null) {
      var sortByTextSkillList = this.sortByTextLength(this.candidateData.skill_list);
      for (var i = 0; i < sortByTextSkillList.length;i++) {
        if (sortByTextSkillList[i] != '') {
          this.inputSkillChips.push(sortByTextSkillList[i]);
        } 
      }
      var thisCandidateSkillList = document.getElementById('skillList');
      this.listOfSkillWrappers = document.getElementsByClassName('mat-chip-list-wrapper');
      
      for(var i=0; i<this.listOfSkillWrappers.length; i++) {
        if(this.listOfSkillWrappers[i].parentElement == thisCandidateSkillList) {
          this.skillWrapper = this.listOfSkillWrappers[i];
        }
      }
    }
  }
  sortByTextLength(skill_list: any) {
    return skill_list.sort(function (s1, s2) { return s1.length - s2.length; });//Sort by length of skill
  }

  ngAfterViewInit() {
    this.updateChipList();
  }

  updateChipList() {
    setTimeout(() => {      
      if(this.skillWrapper && this.skillWrapper.scrollHeight > 40) {
        if(this.numRemoved != 0) {
          this.inputSkillChips.pop();
        }
        this.inputSkillChips.pop();
        this.inputSkillChips.push("+" + ++this.numRemoved);
      }
    }, 10);
  }

  setAddress() {
    if (this.candidateData.candidate_state != null && this.candidateData.candidate_city != null) {
      this.candidateData.address = this.candidateData.candidate_city + ', ' + this.candidateData.candidate_state;
    }
    else {
      this.candidateData.address = null;
    }
  }

  openInviteToApply(candidateId) {
    let dialog = this._dialog.open(InviteToOpportunitiesComponent, {
      height: '95vh',
      width: '100%',
      disableClose: true,
      data: {
        candidateId: candidateId
      }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        console.log(result);
      });
  }

  public getCandidateDetails(): void {
    if(this.isShortlistView) {
      const dialog = this._dialog.open(ProfileViewDialogComponent, {
        height: 'auto',
        disableClose: false,
        //custom_popup  custom_popup_candidate
        data: { id: this.candidateData.candidateId, shortlistId: this.shortlistId, name: this.shortlistName, created_ts: this.candidateData?.shortliston },
      });
      const sub = dialog.afterClosed().subscribe((data) => {
        console.log(data);
      });
    }
  }

  callRemoveCandidate() {
    this.removeCandidate.emit(this.candidateData.candidateId);
  }
}
