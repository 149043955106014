
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class RegisterService {
	RedirectUrl: string;
	IsLoggedIn = new EventEmitter();
	constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }

	Register(registerForm) {
		let data: any;
		if (typeof (registerForm.Email) !== 'undefined' || typeof (registerForm.Password) !== 'undefined') {
			this._userInfoService.setType(2);
		}

		data = { username: registerForm.Email, password: CryptoJS.SHA256(registerForm.Password).toString(), type: 1 };

		let headers = new HttpHeaders();
		let loginResponse: any;
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/createUser", data, { headers: headers }).pipe(
			map(response => {
				{
					this._userInfoService.storeTempAccessToken(response['tokens'],registerForm.Email);
					return response['status'];
				};
			}),
			catchError(error => observableThrowError(
				error
			)),);
	}

	getSchoolList() {
		let data = {  };
		let headers = new HttpHeaders();
		let user: any;

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getSchoolList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['schoolList'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}

	getMetroList() {
		let data = {  };
		let headers = new HttpHeaders();
		let user: any;

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getMetroList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['metroList'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getContactTypes() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getContactTypes", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['contactTypes'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}

	getDegreeTypes() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getDegreeTypes", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['degreeTypes'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}

	getCandidateAvaibility() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateAvailabilityTypes", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['candidateAvailabilityTypes'];
					}
					else {
						console.log('Couldnt get degree');
					}
				  };
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
}
