<mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="title-padding medium-24">About Company</h2>
    <div>
        <button mat-stroked-button (click)="cancel()">CANCEL</button>
        <button mat-raised-button color="primary" fxFlexOffset="10px" [disabled]="!companyInfoForm.valid" (click)="submit()">SAVE</button>
    </div>  
</mat-card-title>
<mat-divider inset></mat-divider>
<form fxLayout="column" [formGroup]="companyInfoForm" autocomplete="off" novalidate>
    <div fxLayout-gt-xs="row" fxLayout.lt-sm="column" fxLayoutGap="30px" class="padding-above">
        <div fxLayout="column" fxLayoutGap="20px" fxFlex.gt-xs="25" fxFlex.lt-sm="100">
            <ng-container *ngIf="loaded">
                <app-company-logo [name]="companyInfoForm.get('name').value"></app-company-logo>
            </ng-container>
            <button mat-stroked-button color="primary" (click)="callback.click()">
                UPDATE IMAGE
                <input #callback type="file" (change)="fileChangeEvent($event)" mode="data-url" style="display:none;" accept="image/x-png,image/gif,image/jpeg" />
            </button>
        </div>
        <div fxLayout="column" fxFlex.gt-xs="75" fxFlex.lt-sm="100">
            <mat-label class="label-font">Company Name</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="name">
		        <mat-error *ngIf="companyInfoForm.get('name').hasError('required')">Company Name cannot be empty</mat-error>
		        <mat-error *ngIf="companyInfoForm.get('name').hasError('maxlength')">Company Name cannot exceed 255 characters</mat-error>
            </mat-form-field>
            <mat-label class="label-font">Website</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="website">
		        <mat-error *ngIf="companyInfoForm.get('website').hasError('maxlength')">Website cannot exceed 255 characters</mat-error>
            </mat-form-field>
            <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxFlex="100" fxLayoutGap.gt-xs="30px">
                <div fxFlex.gt-xs="calc(50%-15px)" fxFlex.lt-sm="100">
                    <mat-label class="label-font">Organization Size</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="size">
                            <mat-option *ngFor="let size of sizes" [value]="size.id">{{size.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex.gt-xs="calc(50%-15px)" fxFlex.lt-sm="100">
                    <mat-label class="label-font">Industry/Sector</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="sectors" multiple>
                            <mat-option *ngFor="let sec of sectors" [value]="sec.id">{{sec.name}}</mat-option>
                        </mat-select>
		            <mat-error *ngIf="companyInfoForm.get('sectors').hasError('required')">Industry/Sector cannot be empty</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <mat-label class="label-font">Headquarter Address</mat-label>
    <mat-form-field appearance="outline">
        <input matInput maxlength="255" formControlName="address">
        <mat-error *ngIf="companyInfoForm.get('address').hasError('maxlength')">Headquarter Address cannot exceed 255 characters</mat-error>
        <mat-error *ngIf="companyInfoForm.get('address').hasError('required')">Headquarter Address cannot be empty</mat-error>
    </mat-form-field>
    <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxFlex="100" fxLayoutGap.gt-xs="30px">
        <div fxFlex.gt-xs="calc(50%-15px)" fxFlex.lt-sm="100">
            <mat-label class="label-font">City</mat-label>
            <mat-form-field appearance="outline">
                <input pattern="[a-zA-Z ]*" maxlength="255" matInput formControlName="city">
                <mat-error *ngIf="companyInfoForm.get('city').hasError('pattern')"> Please enter valid city name </mat-error>
                <mat-error *ngIf="companyInfoForm.get('city').hasError('maxLength')">City cannot exceed 255 characters</mat-error>
                <mat-error *ngIf="companyInfoForm.get('city').hasError('required')">City cannot be empty</mat-error>
            </mat-form-field>
        </div>
        <div fxFlex.gt-xs="calc(50%-15px)" fxFlex.lt-sm="100">
            <mat-label class="label-font">Zip</mat-label>
            <mat-form-field appearance="outline">
                <input pattern="^\d{5}(?:[-\s]\d{4})?$" maxlength="5" minlength="5" matInput formControlName="zip">
                <mat-error *ngIf="companyInfoForm.get('zip').hasError('pattern')"> Please enter valid zip code </mat-error>
                <mat-error *ngIf="companyInfoForm.get('zip').hasError('required')">Zip code cannot be empty</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxFlex="100" fxLayoutGap.gt-xs="30px">
        <div fxFlex.gt-xs="calc(50%-15px)" fxFlex.lt-sm="100">
            <mat-label class="label-font">Country</mat-label>
            <mat-form-field appearance="outline">
                <input matInput type="text" placeholder="Country" required  formControlName="country" id="companycountry" pattern="[a-zA-Z ]*">
                <mat-error *ngIf="companyInfoForm.get('country').hasError('pattern')"> Please enter valid country </mat-error>
                <mat-error *ngIf="companyInfoForm.get('country').hasError('required')"> You did not enter country </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex.gt-xs="calc(50%-15px)" fxFlex.lt-sm="100">
            <mat-label class="label-font">State</mat-label>
            <mat-form-field appearance="outline">
                <mat-select formControlName="state" [disabled]="states == null">
                    <mat-option *ngFor="let state of states" [value]="state.abbrev">{{state.abbrev}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-label class="label-font">About Company</mat-label>
    <mat-form-field appearance="outline">
        <textarea matInput type="text" formControlName="description"></textarea>
        <mat-error *ngIf="companyInfoForm.get('description').hasError('required')">Company description cannot be empty</mat-error>
    </mat-form-field>
</form>
