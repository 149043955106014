export interface CandidateInfo {
    firstName: string;
    lastName: string;
    phone: string;
    profilePic: any;   
}

export interface CandidateDiversity {
  gender: any;
  ethnicity: any;
  military_status:any;
}

export interface CandidateLocation {
    streetaddress: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
}

export interface CandidateDegree {
    school: any;
    course: any;
    degreeType: CandidateIdNameModel;
    startDate: Date;
    endDate: Date;
}

export interface CandidateCertification {
    name: string;
    authority: string;
    dateAwarded: Date;
}

export interface CandidateWorkExperience {
    company: string;
    title: string;
    startDate: Date;
    present: any;
    endDate: Date;
    description: string;
}

export interface CandidateSkill {
    skill: any;
    expYears: number;
}

export interface CandidateIdNameModel {
    id: number;
    name: string;
}
export interface CandidatePreferredContact {
    contactType: CandidateIdNameModel;
    contact: string;
}

export interface CandidateInfo {
    firstName: string;
    lastName: string;
    phone: string;
    profilePic: any;
}

export interface CandidateAvailabilityComp {
    availabilities: CandidateIdNameModel[];
    compType: number;
    minComp: number;
    maxComp: number;
}

export interface CandidateSectorTitle {
    sector: CandidateIdNameModel;
    title: string;
}

export interface CandidateWorkEnvironment {
    location:any;
    currentWorkScheduleType: any;
    workStation: 0 | 1;
    workEnvironmentType: any;
    interestedMarkets: any;
}

export interface CandidateLinkContacts {
    contentLink: string;
    preferredContacts: CandidatePreferredContact[];
}

export interface CandidateEducation {
    highestDegree: CandidateIdNameModel;
    candidateDegrees: CandidateDegrees;
}

export interface CandidateDegrees {
    tradeSchool : CandidateDegree[]
    associatesDegree : CandidateDegree[]
    bachelorsDegree : CandidateDegree[]
    mastersDegree : CandidateDegree[]
    doctorate : CandidateDegree[]
}

export interface CandidateProfile {
    candidateInfo: CandidateInfo;
    resume: any;
    location: CandidateLocation;
    availabilityStatus: CandidateIdNameModel;
    sectorTitle : CandidateSectorTitle;
    availabilityComp: CandidateAvailabilityComp;
    education: CandidateEducation;
    candidateCertifications: CandidateCertification[];
    workExperience: CandidateWorkExperience[];
    candidateSkills: CandidateSkill[];
    workEnvironment: CandidateWorkEnvironment;
    linkContacts: CandidateLinkContacts;
    diversityQuestion: CandidateDiversity;
    activeApps: any; // just an object for compatibility with old ActiveApps fetching logic
    isActive: 0|1|"0"|"1";
};

export class CandidateProfileObj implements CandidateProfile {
    candidateInfo: CandidateInfo = {
        firstName: null,
        lastName: null,
        phone: null,
        profilePic: null,
    };
    resume = null;
    location: CandidateLocation = {
        streetaddress: null,
        address2: null,
        city: null,
        state: null,
        zip: null
    }
    availabilityStatus: CandidateIdNameModel = {
        id: null,
        name: null
    };
    sectorTitle: CandidateSectorTitle = {
        sector: null,
        title: null
    };
    availabilityComp: CandidateAvailabilityComp = {
        availabilities: [],
        compType: 1, // for hourly com-type as default
        minComp: null,
        maxComp: null,
    };
    education: CandidateEducation = {
        highestDegree: null,
        candidateDegrees: {
            tradeSchool : [],
            associatesDegree : [],
            bachelorsDegree : [],
            mastersDegree : [],
            doctorate : []
        },
    };
    candidateCertifications: CandidateCertification[] =  [];
    workExperience: CandidateWorkExperience[] =  [];
    candidateSkills: CandidateSkill[] = [];
    workEnvironment: CandidateWorkEnvironment = {
        location:null,
        currentWorkScheduleType: null,
        workStation: null,
        workEnvironmentType: null,
        interestedMarkets: null,
    };
    linkContacts: {
        contentLink: null,
        preferredContacts: []
    };
    isActive: 0;
    activeApps: null;
    diversityQuestion: CandidateDiversity = { gender:0, ethnicity:0, military_status:0 };
}
