<div fxLayout="row wrap" fxFlex="100">
  <h1 mat-dialog-title  fxFlex="50">
   Add Skills
  </h1>
  <div fxFlex="50" fxLayoutAlign="end start">     
    <button mat-raised-button (click)="done()" tabindex="-1" color="primary">
      DONE
    </button>
  </div>
  <hr fxFlex="100">
  <mat-dialog-content fxFlex="100" class="mat-typography scroll-content"
                      cdkScrollable>
    <div fxFlex="99" fxFlex.xs="100" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100" class="margin-bottom">
        <div fxFlex="90" fxFlex.md="87" fxFlex.sm="87" fxFlex.lt-sm="87" fxLayoutAlign="end start">     
          <button mat-stroked-button (click)="expandAll()" color="primary">
            Expand All
          </button>
        </div>
        <div fxFlex="16" fxFlex.md="23" fxFlex.sm="20" fxFlex.lt-sm="44" fxLayoutAlign="end start">     
          <button mat-stroked-button (click)="tree.treeControl.collapseAll()" color="primary">
            Collapse  All
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100" *ngIf="skillsList!=null && skillsList!= undefined && skillsList.length > 0">
        <div fxLayout="column" fxFlex="100" fxLayoutGap="5px">         
          <mat-chip-list name="skillChip">
            <mat-chip class="skill-chip" *ngFor="let skill of skillsList">
              {{skill.name}} <button matChipRemove (click)="removeSkill(skill)">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxFlex="100" fxLayout.xs="row"  fxLayout="row wrap">
        <div fxFlex="100" fxFlex.xs="100" fxLayout="column">
          <div fxFlex="100" fxLayout="row">
            <mat-form-field class="margin-field"
                            color="accent"
                            appearance="outline"
                            fxFlex="100">
              <input matInput
                     aria-label="search"
                     autocomplete="off"
                     [(ngModel)]="searchText"
                     (ngModelChange)="this.userEnteredSearchText.next($event)"
                     name="searchText"
                     id="searchText"
                     placeholder="Search by Name">
              <button mat-button type="button" *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                <mat-icon>cancel</mat-icon>
              </button>
              <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="tree-border" *ngIf="!isLoading && !noSkills" fxLayout="row" fxFlex="100">
            <mat-tree #tree [dataSource]="skillDataSource" id="matTree" fxFlex="100" class="candidate-skills-tree" [treeControl]="treeControl" color="primary"
                      [ngClass]="{'tree-container':!isMobileDevice, 'tree-container-xs':isMobileDevice}">
              <mat-tree-node *matTreeNodeDef="let node"
                             [style.background-color]="(node.name.toLowerCase().indexOf(searchText.toLowerCase())>=0 && searchText!='' ) ? '#FFFF00':null">
                <div id="nd{{node.id}}" style="width:98%;">
                  <mat-icon></mat-icon><div fxFlex class="checkbox-label">&nbsp;&nbsp;&nbsp;{{node.name}}&nbsp;&nbsp;</div>
                  <mat-checkbox class="skill-checkbox" style="float:right;" color="primary"
                                [checked]="node.isChecked"
                                [aria-label]="node.name"
                                [labelPosition]="'before'"
                                (change)="addSkill(node,$event.checked)">
                  </mat-checkbox>
                </div>
              </mat-tree-node>
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div id="nd{{node.id}}" style="width:98%" [style.background-color]="(node.name.toLowerCase().indexOf(searchText.toLowerCase())>=0 && searchText!='' ) ? '#FFFF00':null">
                  <mat-icon matTreeNodeToggle class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                  <div fxFlex class="checkbox-label">{{node.name}}&nbsp;&nbsp;</div>
                  <mat-checkbox class="skill-checkbox" style="float:right;" color="primary"
                                [checked]="node.isChecked"
                                [aria-label]="node.name"
                                [labelPosition]="'before'"
                                (change)="addSkill(node,$event.checked)">
                  </mat-checkbox>
                </div>
                <ul [class.node-tree-invisible]="!treeControl.isExpanded(node)">
                  <ng-container matTreeNodeOutlet></ng-container>
                </ul>
              </mat-nested-tree-node>
            </mat-tree>
          </div>
          <div class="tree-border" [ngClass]="{'tree-container':!isMobileDevice, 'tree-container-xs':isMobileDevice}" fxLayoutAlign="center center" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
          </div>
          <div class="tree-border tree-container" *ngIf="!isLoading && noSkills" fxLayoutAlign="center center" fxLayout="row" fxFlex="100">
            <span class="no-skill"><strong>No skills available.</strong></span>
          </div>
        </div>
        <div fxFlex="30" fxFlex.xs="0">
        </div>
      </div>
    </div>
    <div fxFlex="1">&nbsp;
    </div>
  </mat-dialog-content>
</div>
