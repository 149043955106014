export class EmployerRegistration {
	public FirstName: string = "";
	public LastName: string = "";
	public Phone: string = "";
	public Email: string = "";
	public Password: string = "";
	public VerifyPassword: string = "";
	// Company Details
	//public company = {name:'', id:'', website:''};
	//public newLocation = {city:'', state:'', zip:'', street:''};
	public CompanyName: string = "";
	public CompanyAddress: string = "";
	public CompanyCity: string = "";
	public CompanyState: string = "";
	public CompanyZip: string = "";
	public CompanyCountry: string = "USA";
	public CompanyDivGroupDept: string = "";
	public CompanyIndustrySector: string = "";
}
export interface CompanyEmployer {
  companyName: string;
  employers: Employer[];
}
export interface Employer {  
  email: string;
  name: string;
}
