import { Component, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CsvExportService } from '../../Services/csv-export.service';


@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {

  constructor(private _router: Router, private csv: CsvExportService) { }
  @Input() selectedReport: any;
  @Input() reportList: any;
  @Input() reportdetail: any;
  @Input() filterData: any;   

  ngOnInit(): void {   
    //this.selectedReport = this.reportList[0];
  }

  exportReport(): void {
    if (this.filterData != undefined && this.filterData != null) {
      this.csv.exportToCsv(this.selectedReport.name.concat('.csv'), this.filterData); //if any filter applied
    }
    else {
      this.csv.exportToCsv(this.selectedReport.name.concat('.csv'), this.reportdetail);
    }
  }

  updateReport() {   
    this._router.navigate(['reports', this.selectedReport.reportId]);
  }
}
