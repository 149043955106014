import { Component, OnInit,Output ,EventEmitter , Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { TimesheetService } from '../../../Services/timesheet.service';
import { FormControl } from '@angular/forms';
import { TalentApiService } from '../../../Services/talent-api.service';

@Component({
  selector: 'app-timesheet-action',
  templateUrl: './timesheet-action.component.html',
  styleUrls: ['./timesheet-action.component.css']
})
export class TimesheetActionComponent implements OnInit {

  
  public opportunityList:any;
  public currdate = new Date();
  public actionType:any;
  public deletingData:any;
  public editing:any;
  public statusupdate:any;
  public adding:any={date: new Date(),time_entered:'',selopportunity:''}; 
  


  constructor(private _timesheetService: TimesheetService,private dialogRef:MatDialogRef<TimesheetActionComponent>,private _talentApiService:TalentApiService) { }

  ngOnInit() {
    
  }


  closeDialog(){
    this.dialogRef.close();
	}


  addNew(){
    let timeentry_id = null;
    if (this.adding && this.adding.selopportunity && this.adding.time_entered && this.adding.date){
      this._timesheetService.updateStudentTimesheet(timeentry_id, this.adding.date, this.adding.selopportunity, this.adding.time_entered)
        .subscribe(
            (result:any) => {
              this.dialogRef.close();
              this._timesheetService.timesheetData.emit("close");
              this._talentApiService.snackbarMessage('TimeEntry Added successfully!');
            },
            error => {
                console.log('Could not add');
                this._talentApiService.snackbarMessage('Something Went Wrong!');
            }
        );
      }

  }

  removeTimeEntry(deleteType){
    if (this.deletingData && this.deletingData.id){

      this._timesheetService.deleteTimeEntry(this.deletingData.id)
        .subscribe(
            (result:any) => {
              if(deleteType=='supervisor'){
                  this.dialogRef.close();
                  this._timesheetService.timesheetData.emit("employee_action");
              }else if(deleteType=='student'){
                  this.dialogRef.close();
                  this._timesheetService.timesheetData.emit("delete");
              }
              this._talentApiService.snackbarMessage('TimeEntry Deleted successfully!');
            },
            error => {
                console.log('Could not add');
                this._talentApiService.snackbarMessage('Something Went Wrong!');
            }
        );
    }
  }

  modalSave(){
    if (this.editing && this.editing.id && this.editing.op_id && this.editing.time_entered && this.editing.date) {
      this._timesheetService.updateStudentTimesheet(this.editing.id, this.editing.date, this.editing.op_id, this.editing.time_entered)
      .subscribe(
          (result:any) => {
            this.dialogRef.close();
            this._timesheetService.timesheetData.emit("edit");
            this._talentApiService.snackbarMessage('TimeEntry Edited successfully!');
          },
          error => {
              console.log('Could not save');
              this._talentApiService.snackbarMessage('Something Went Wrong!');
          }
      );
    } else {
        console.log("Invalid update request, please check your parameters and try again.");
    }
  }


  updateStatus(){
    if (this.statusupdate && this.statusupdate.id && this.statusupdate.status){
      this._timesheetService.updateTimesheetStatus(this.statusupdate.id, this.statusupdate.status)
      .subscribe(
          (result:any) => {
            this.dialogRef.close();
            this._timesheetService.timesheetData.emit("employee_action");
            if(this.statusupdate.status==1){
              this._talentApiService.snackbarMessage('TimeEntry Approved successfully!');
            }else if(this.statusupdate.status==-1){
              this._talentApiService.snackbarMessage('TimeEntry Rejected successfully!');
            }
          },
          error => {
              console.log('Could not save');
              this._talentApiService.snackbarMessage('Something Went Wrong!');
          }
      );
    } else{
        console.log("Invalid status update request, please check your parameters and try again.");
        this._talentApiService.snackbarMessage('Something Went Wrong!');
    }
  }

 

}
