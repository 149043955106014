import { Component, OnInit, ViewChild} from '@angular/core';
import { Subject } from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute,Router } from '@angular/router';
import { UserInfoComponent } from '../../Admin/dialogs/user-info/user-info.component';
import { AdminApiService } from '../../Services/admin-api.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['id', 'fk_tenant', 'fk_userGroup', 
        'enabled', 'firstName', 'lastName', 'email', 'phone', 'last_updated_by', 
        'last_updated_ts', 'view'];

  dataSource: MatTableDataSource<any> = null;
  users:any;
  userList={};

  constructor(private _adminApiService:AdminApiService,private _router: Router,public _dialog: MatDialog,private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUsers();  
  }  

  // for seatching the mat-table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    
  }

  // calls API to get list of all users in user table
  getUsers(){
    this._adminApiService.urlUserList()
      .subscribe((result:any) => {
          this.dataSource = new MatTableDataSource(result);
        },
        error => {
            console.log('Could not load Config Variables');
        }
    );
  }

  // user information popup
  edit(user:any){
      let dialog = this._dialog.open(UserInfoComponent,{
        height: 'auto',
        minWidth: "50%"
      });
      let editData = Object.assign({}, user);
      let isReloadData = false;   
      dialog.componentInstance.isReloadData = isReloadData;
      dialog.componentInstance.editing = editData;
      dialog.afterClosed()
        .subscribe((result) => {
          if(dialog.componentInstance.isReloadData){
              this.getUsers();
          }        
        });
  }

}

