import { Component, Input, OnInit,  Output, EventEmitter, Optional, Inject, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileViewDialogComponent } from '../../Pages/dialogs/profile-view-dialog/profile-view-dialog.component';
import { TalentApiService } from '../../Services/talent-api.service';

@Component({
  selector: 'app-opportunity-applications',
  templateUrl: './opportunity-applications.component.html',
  styleUrls: ['./opportunity-applications.component.css']
})
export class OpportunityApplicationsComponent implements OnInit {
  @Input() postingId: any;  
  @Input() breakpoint: number;
  @Input() postingDetail: any;  
  applicants: any = [];
  public totalLength = 0; // Length of the candidate array
  public pageSize = 20; // Number of candidate fetched by API and displayed
  public pageIndex = 0; // Page number (starting candidate index = pageIndex * pageSize)
  public isLoadingResult: boolean = false; 
  public isPostingView: boolean = true; 
  
  

  constructor(@Optional() private dialogRef: MatDialogRef<ProfileViewDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public _dialog: MatDialog,
    private _talentApiService: TalentApiService, private _router: Router) {        

  }  

  ngOnInit(): void {    
    this.getApplicants(0, this.pageSize);
  }

  public getCandidateDetails(id: number): void {
    const dialog = this._dialog.open(ProfileViewDialogComponent, {
      height: 'auto',
      disableClose: false,      
      //custom_popup  custom_popup_candidate
      data: { id: id,  postingId: this.postingId, title: this.postingDetail.title, sector: this.postingDetail.company.sector, postedDate: this.postingDetail.postingdate },
    });
    const sub = dialog.afterClosed().subscribe((data) => {
      this.getApplicants(0, this.pageSize);      
    });  
  }

  getApplicants(pageNumber, pageSize) {
    this.isLoadingResult = true;
    this._talentApiService.getApplicants(this.postingId, pageNumber, pageSize).subscribe((result: any) => {            
      this.applicants = result['studentsForOpportunity'];
      this.totalLength = result['total_count'];
      this.isLoadingResult = false;
      
      for (var i = 0; i < this.applicants.length; i++) {
        var totalLen = 0;
        var totalAllowSkills = 0;
        if (this.applicants[i].skill_list != null && this.applicants[i].skill_list != '') {
          for (var j = 0; j < this.applicants[i].skill_list.length; j++) {
            if ((totalLen + this.applicants[i].skill_list[j].length) > 20) {
              break;
            }
            totalLen = totalLen + this.applicants[i].skill_list[j].length;
            totalAllowSkills++;
          }
          if (totalAllowSkills == 0) {
            totalAllowSkills = 1;
          }
          this.applicants[i].totalAllowSkills = totalAllowSkills;
        }          
        if (this.applicants[i].candidate_state != null && this.applicants[i].candidate_city != null) {
          this.applicants[i].address = this.applicants[i].candidate_city + ', ' + this.applicants[i].candidate_state;
        }
        else {
          this.applicants[i].address = null;
        }
      }
    });
  }

  // Gets the next group of candidates from the API
  onPageChange(event) {
    this.pageSize = event.pageSize;
    this.getApplicants(event.pageIndex, event.pageSize)
  }
}
