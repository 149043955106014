import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyInfoService } from '../../../Services/company-info.service';

@Component({
  selector: 'app-account-usage',
  templateUrl: './account-usage.component.html',
  styleUrls: ['./account-usage.component.css']
})
export class AccountUsageComponent implements OnInit {
  largeScreen: boolean = false;
  public postingDataReady : boolean = false;
  public shortlistDataReady : boolean = false;
  public usersDataReady : boolean = false;
  candidatesAdvanced = 0;
  candidatesShortlisted = 0;
  public metrics = {};
  public progress = {};

  constructor(
    public _companyInfoService: CompanyInfoService,
    public breakpointObserver: BreakpointObserver,
    public _router: Router
  ) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 959px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.largeScreen = true;
      } else {
        this.largeScreen = false;
      }
    });
    this._companyInfoService.getCompanyMetrics().subscribe((result : any) =>{
      this._companyInfoService.getSubscriptionInfo().subscribe((info : any) =>{
        if(result.length > 0) {
          const hasPosting = result.filter(f=> f.name == 'Postings');
          const hasShortlists = result.filter(f=> f.name == 'Shortlists');
          const hasUsers = result.filter(f=> f.name == 'Users');
          result.forEach(metric => {
            this.metrics[metric.name] = metric.id;
            if (hasPosting.length > 0 && info['active_postings'] > 0) {
              if(metric.name == "Postings"){
              this.progress['posting'] = info['active_postings'].concat('/', metric.threshold);
              this.progress['postingBar'] = parseInt(info['active_postings']) / parseInt(metric.threshold) * 100;
              this.postingDataReady = true;
              }
            } else {
              this.progress['posting'] = 0;
            }
            if (hasShortlists.length > 0 && info['shortlist'] > 0) {
              if (metric.name == "Shortlists"){
                this.progress['shortlist'] = info['shortlist'].concat('/', metric.threshold);
                this.progress['shortlistBar'] = parseInt(info['shortlist']) / parseInt(metric.threshold) * 100;
                this.shortlistDataReady = true;
              }
            } else {
              this.progress['shortlist'] = 0;
            }
            if (hasUsers.length > 0 && info['userlist'] > 0) {
              if (metric.name == "Users"){
                this.progress['userlist'] = info['userlist'].concat('/', metric.threshold);
                this.progress['userlistBar'] = parseInt(info['userlist']) / parseInt(metric.threshold) * 100;
                this.usersDataReady = true;
              } 
            } else {
              this.progress['userlist'] = 0;
            }
          });
        } else {
          this.progress['posting'] = 0;
          this.progress['shortlist'] = 0;
          this.progress['userlist'] = 0;
        }
      }); 
    });
  }

  back() {
    this._router.navigate(['/companyAdmin']);
  }

  onCountAdvancedChange(event){
    this.candidatesAdvanced = event;
  }
  
  onCountShortlistChange(event){
    this.candidatesShortlisted = event;
  }

}
