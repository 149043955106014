<div fxFlex="100" fxLayout="column" *ngIf="!isLoading" fxLayoutGap="10px" class="margin-top15px">
  <div fxLayout="row" fxFlex="100">
    <a mat-raised-button color="primary" [routerLink]="['/postings',detail.id]" target="_blank">APPLY</a>
  </div>
  <div fxLayout="row" fxFlex="100">
    <mat-divider fxLayout="row" fxFlex="100" [inset]="true"></mat-divider>
  </div>
  <div fxFlex="100" fxFlex.xs="100">
    <div fxLayout="row wrap" fxLayoutAlign.sm="start start" fxLayoutAlign.xs="start start">
      <b>Location:</b>&nbsp;&nbsp;
      <div fxLayoutAlign="start start" fxLayoutAlign.xs="start start" fxLayout.xs="column"
        fxLayout="{{detail.locations.length >5 ?'column':'row'}}" fxLayoutAlign.sm="start start" fxLayout.sm="column">
        <div *ngFor="let loc of detail.locations">
          <a class="address-padding" color="accent" *ngIf="loc.address1 || loc.city || loc.state" aria-label="map" href="http://maps.google.com/{{
                '?q=' + (loc.address1 ? loc.address1+ ', ':'')
                 + (loc.city ? loc.city+ ', ':'')
                 + (loc.state ? loc.state:'')
                 + (loc.zip ?  ', '+loc.zip:'') }}" target="_blank">
            &nbsp;&nbsp;<i *ngIf="loc.city || loc.state" class="fa fa-1x fa-map-marker color-primary">
            </i>
          </a>&nbsp;&nbsp;{{ (loc.city ? loc.city+ ', ':'') + (loc.state ? loc.state:'') }}
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxFlex="100">
    <b>Number of Positions: </b>&nbsp; {{ detail.quantity }}
  </div>
  <div fxLayout="row" fxFlex="100" *ngIf="detail.work_setup">
    <div fxLayout="row wrap" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start">
      <b>Work Setup:&nbsp;&nbsp;</b>
      <div fxLayoutAlign="start center" [ngClass.xs]="'linebreak'" [ngClass.sm]="'linebreak'"
        fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start" fxLayout.xs="column" fxLayout.sm="column">
        {{getWorkSetup()}}
      </div>
    </div>
  </div>
  <div fxLayout="row" fxFlex="100" *ngIf="detail.opportunity_type.length > 0">
    <div fxLayout="row wrap" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start">
      <b>Posting <br fxShow.xs fxShow.sm fxHide /> Type:&nbsp;&nbsp;</b>
      <div fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start"
        fxLayoutAlign.sm="start start">
        <div *ngFor="let OpportunityType of detail.opportunity_type;let last = last;">
          <span fxShow.xs fxShow.sm fxHide>&nbsp;&nbsp;&nbsp;</span>{{OpportunityType}} <span fxShow fxHide.xs fxHide.sm
            *ngIf="!last">, &nbsp;</span>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="100" fxLayout="row wrap">
    <div class="panel panel-default">
      <div fxLayout="row wrap">
        <div class="key-res-sec" fxFlex="100" fxLayoutAlign="start start">
          <strong><span>Requried Skill:</span></strong>
        </div>
      </div>
      <div class="margin-top15px">
        <mat-chip-list>
          <mat-chip *ngFor="let skill of detail.skills" class="skill-chip">
            <p class="skill-text"> {{ skill.name }} </p>
            <p class="years-text"> {{ skill.expYears }} </p>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
  <div fxFlex="100" fxLayout="row">
    <strong><span>Job Summary</span></strong>
  </div>
  <div fxFlex="100" fxLayout="row">
    <span [innerHTML]="detail.description"></span>
  </div>
  <div fxFlex="100" fxLayout="row">
    <strong><span>Benefits</span></strong>
  </div>
  <div fxFlex="100" fxLayout="row">
    {{detail.benefits}}
  </div>
  <div fxFlex="100" fxLayout="row">
    <strong><span>About {{detail.company.name}}</span></strong>
  </div>
  <div fxFlex="100" fxLayout="row">
    {{ detail.company.description }}
  </div>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>