<div fxFlex="100" fxLayout="row wrap" fxFill>
  <div fxFlex="100" fxLayout="row">
    <h1 fxFlex="90" *ngIf="isAddNew" mat-dialog-title>Add New API</h1>
    <h1 fxFlex="90" *ngIf="!modalInEditMode && !isAddNew" mat-dialog-title>API Name: {{editing.name}}</h1>
    <h1 fxFlex="90" *ngIf="modalInEditMode && !isAddNew" mat-dialog-title>Editing: #{{editing.id}} - {{editing.name}}</h1>
    <div fxFlex="10" fxLayoutAlign="end">
      <button *ngIf="!isAddNew" class="mat-icon-button close_bt" (click)="delete()">
        <mat-icon>delete</mat-icon>
      </button>
      <button class="mat-icon-button close_bt" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content fxFlex="100" class="mat-typography scroll-content">
    <form [formGroup]="apiForm" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxLayout="row" fxFlex="100">
        <mat-form-field color="accent" appearance="outline">
          <mat-label>API Name</mat-label>
          <input placeholder="API Name" matInput required type="text"
                 id="apiname"
                 name="apiname"
                 formControlName="apiname"
                 minlength="5"
                 maxlength="250" />
          <mat-error *ngIf="frm.apiname.hasError('required')" class="help-block small animated fadeInDown">
            You did not enter api name
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row wrap" fxFlex="100"><strong>Permission</strong></div>
      <div fxLayout="row wrap" fxFlex="100">
       <mat-checkbox *ngFor="let p of getPermissionList" [checked]="checkPermission(p)" (change)="permissionValue(p, $event.checked)" class="checkbox-width">
        {{ p.Name }}</mat-checkbox>
      </div>

      <div fxLayout="row" fxFlex="100" fxLayoutAlign="end start" fxLayoutGap="20px">
        <div *ngIf="!modalInEditMode">
          <button *ngIf="!modalInEditMode"
                  (click)="submit()"
                  [disabled]="apiForm.invalid"
                  mat-button mat-raised-button>
            Save
          </button>
        </div>
        <div *ngIf="!modalInEditMode">
          <button *ngIf="!modalInEditMode" (click)="closeDialog()" mat-button mat-raised-button>Cancel</button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
