<div fxLayout="column" ng-cloak>
    <div> <!--Instructions-->
        <h4>Tenant Permissions</h4>
        <p><em>Tenants' user-group specific route permissions can be managed from here. </em></p>
        <strong>Instructions:</strong>
        <ol>
            <li>Select the tenant from the dropdown.</li>
            <li>Select the user-group from the dropdown.</li>
            <li>The table will display that tenant user-group combination of routes.</li>
            <li>Press the edit button to change the route permission configuration.</li>
        </ol>  
    </div>
    <div> <!-- Searching Element -->
        <mat-form-field color="accent" appearance="outline">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </mat-form-field>
    </div>
    <div> <!--Select Tenant and User Group for table population-->
        <div fxFlex="column">
            <h3>Select a Tenant</h3>
            <mat-form-field color="accent" appearance="outline">
                <mat-label>Tenant</mat-label>
                <mat-select [(value)]="tenantSelected" (selectionChange)="generateRoutesTable()">
                    <mat-option *ngFor="let tenant of tenants" value="{{ tenant.id }}">{{tenant.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="column">
            <h3>Select a User Group</h3>
            <mat-form-field color="accent" appearance="outline">
                <mat-label>User Group</mat-label>
                <mat-select [(value)]="userGroupSelected" (selectionChange)="generateRoutesTable()">
                    <mat-option *ngFor="let group of userGroups" value="{{ group.id }}">{{ group.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="dataSource != null"> <!-- Table -->
        <table mat-table [dataSource]="dataSource" fxFlex="100%" matSort>
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.id }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="route">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Route </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.route }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="activated">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Activated? </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div *ngIf="row.editing == false">
                        <mat-icon *ngIf="row.activated == 0" class="red-icon">block</mat-icon>
                        <mat-icon *ngIf="row.activated == 1" class="green-icon">check_circle</mat-icon> 
                    </div>
                    <div *ngIf="row.editing == true">
                        <mat-checkbox 
                            [checked]="row.editData.activated" 
                            (change)="activatedEdited(row, $event)"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="is_nav_route">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Navigation Route? </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div *ngIf="row.editing == false">
                        <mat-icon *ngIf="row.is_nav_route == 0" class="red-icon">block</mat-icon>
                        <mat-icon *ngIf="row.is_nav_route == 1" class="green-icon">check_circle</mat-icon>
                    </div>
                    <div *ngIf="row.editing == true">
                        <mat-checkbox 
                            [checked]="row.editData.is_nav_route" 
                            (change)="isNavRouteEdited(row, $event)"
                            [disabled]="row.editData.activated == false"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="is_actions_route">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Actions Route? </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div *ngIf="row.editing == false">
                        <mat-icon *ngIf="row.is_actions_route == 0" class="red-icon">block</mat-icon>
                        <mat-icon *ngIf="row.is_actions_route == 1" class="green-icon">check_circle</mat-icon>
                    </div>
                    <div *ngIf="row.editing == true">
                        <mat-checkbox 
                            [checked]="row.editData.is_actions_route" 
                            (change)="isActionsRouteEdited(row, $event)"
                            [disabled]="row.editData.activated == false"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Action">
                <mat-header-cell *matHeaderCellDef mat-header-sort>Action</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-icon *ngIf="row.editing == false" (click)="editRoute(row)">edit</mat-icon>
                    <div *ngIf="row.editing == true">
                        <button mat-raised-button (click)="save(row)">Save</button>
                        <button mat-raised-button (click)="cancel(row)">Cancel</button>
                    </div>
                </mat-cell>
            </ng-container>
            <!-- Header Column Labels -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <!-- Row shown when there is no matching data. -->
            <mat-row *matNoDataRow>
                <mat-cell colspan="9">No data matching the filter "{{input.value}}"</mat-cell>
            </mat-row>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,50,100]"></mat-paginator>
    </div>
</div>
    
