
import {throwError as observableThrowError,  Observable, Subject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable,EventEmitter} from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';


@Injectable()
export class PostingService {
  backButtonShareData = new Subject();
  refreshPostingData = new Subject();// Refresh posting list if posting edited or deleted
  constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }
    
  sharePosting(opportunityId,emailList) {
    
    let data = { "opportunity-id": opportunityId, "emailList": emailList };
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/sharePosting", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['sharePostingResult'];
            }
            else {
              console.log('Couldn"t share data');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));

    }
  

  getPostingDetail(id){
	let data = { "opportunity-id": id};
	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');
	return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getPostingDetail", data, { headers: headers }).pipe(
		map(response => {
			{
				if (typeof (response !== 'undefined')) {
					return response['opportunityDetail'];
				}
				else {
					console.log('Couldnt get data');
				}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status }
		)),);
	  
  }

  editPosting(posting){
	let data = { posting: posting};
	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');
	return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/editOpportunity", data, { headers: headers }).pipe(
		map(response => {
			{
				if (typeof (response !== 'undefined')) {
					return response['status'];
				}
				else {
					console.log('Couldnt get data');
				}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status }
		)),);
  }
  deletePosting(id){
	let data = { "opportunity-id": id};
	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');
	return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/deleteOpportunity", data, { headers: headers }).pipe(
		map(response => {
			{
				if (typeof (response !== 'undefined')) {
					return response['status'];
				}
				else {
					console.log('Couldnt get data');
				}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status }
		)),);
  }

  tagPosting(id){
	let data = { "opportunity-id": id};
	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');
	return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/createOpportunityTag", data, { headers: headers }).pipe(
	map(response => {
		{
			if (typeof (response !== 'undefined')) {
				return response['status'];
			}
			else {
				console.log('Couldnt get data');
			}
		};
	}),
	catchError(error => observableThrowError(
		{ status: error.status }
	)),);
  }

  // new service for untag posting

  unTagPosting(tagId,id){
	let data = { "tag_id":tagId, "opportunity-id": id};
	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');
	return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/removeOpportunityTag", data, { headers: headers }).pipe(
	map(response => {
		{
			if (typeof (response !== 'undefined')) {
				return response['status'];
			}
			else {
				console.log('Couldnt get data');
			}
		};
	}),
	catchError(error => observableThrowError(
		{ status: error.status }
	)),);
  }

	viewCandidateOpportunityCount(opportunity_id: string, candidate_id: string){
		let headers = new HttpHeaders();
		let data = {
			'opportunity-id' : opportunity_id,			
			'student-id': candidate_id
			
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
			"opportunity/viewCandidateOpportunityCount", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response;
						}
						else {
							console.log('Could not change candidate status.');
						}

					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)));

	}

	updateOpportunityStatus(opportunity_id: string, status_id: string){
		let headers = new HttpHeaders();
		let data = {
			'opportunity-id' : opportunity_id,			
			'status-id': status_id
			
		};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
			"opportunity/updateOpportunityStatus", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response;
						}
						else {
							console.log('Could not change opportunity status.');
						}

					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)));

	}

	getOpportunityStatus(){
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getOpportunityStatus", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt get data');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
		  
	  }



}
