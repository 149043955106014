export class User {
	id: string;
	fk_tenant:string;
	first_name:string;
	last_name:string;
	name:string;
	username: string;
	type: string;
	enabled:string;
	email_verified:boolean;
	school:number;
	telephone:number; 
	company:string;
}