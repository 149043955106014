<div fxLayout="row">
    <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
        <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
    </div>
    <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
        <h1 class="title title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">What is your current job title?</h1>
        <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
    </div>
</div>
<form name="candidateSectorForm" id="candidateSectorForm" [formGroup]="form" autocomplete="off">
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex="92" fxFlexOffset="5">
            <div fxLayout="row">
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>Title</mat-label>
                    <input matInput type="text" name="title" id="title" formControlName="title">
                    <mat-error *ngIf="form.get('title').hasError('required') || form.get('title').hasError('pattern')">
                        Please enter a valid title
                    </mat-error>
                    <mat-error *ngIf="form.get('title').hasError('maxlength')">
                        Title must be under 100 characters
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxFlexOffset="20px">
                <div fxLayout="column" fxFlex="100">
                    <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Which industry do you currently work in?</h1>
                    <div fxLayout="row">
                        <mat-radio-group
                        fxLayout="column"
                        aria-labelledby="example-radio-group-label"
                        class="example-radio-group" formControlName="sector">
                            <mat-radio-button [fxFlexOffset]="i>0 ? '20px' : ''" fxLayoutAlign="center center" (change)="sectorChange($event)" *ngFor="let sec of Candidatesector;let i=index;" [value]="sec" class="example-radio-button" [checked]="sectorTitle && sectorTitle.sector && sectorTitle.sector.id == sec.id">
                                {{sec.name}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxFlexOffset="20px">
                <div fxLayout="column" fxLayoutAlign="start">
                    <button [disabled]="!form.valid" mat-raised-button color="primary" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                </div>
            </div>
        </div>
    </div>
</form>
