<mat-card fxLayout="row wrap" fxFlex="100" ng-cloak>
  <div fxLayout="row wrap" fxFlex="100">
    <h4 fxFlex="100">Content Reported</h4>
    <p fxFlex="100"><em>User's content reporting list displayed here. </em></p>
    <div class="instructions_cl" fxFlex="100">

      <strong>Instructions:</strong>
      <ol>
        <li>A candidate or an opportunity can be reviewed by clicking on the name or the title.</li>
        <li>
          'Action taken' drop down has two values 'None' or 'Removed'.<br />          
          if 'None' is selected, the 'Reviewed' chechbox will be checked.No action will be taken by system.<br />
          if 'Removed' is selected,the corresponding candidate/opportunity will be removed and the 'Reviewed' chechbox will be checked. This will be a non-reversible final action.
        </li>
        <li>If 'Remove this User' or 'Remove this Posting' button is pressed, the corresponding candidate/opportunity will be removed and the 'Reviewed' chechbox will be checked. This will be a non-reversible final action.</li>
      </ol>
    </div>
  </div>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <div fxLayout="row" fxFlex="100">
    <div fxLayout="column" fxFlex>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="reportedby">
          <th mat-header-cell *matHeaderCellDef> Reported By </th>
          <td mat-cell *matCellDef="let element"> {{element.reportedby}} </td>
        </ng-container>
        <ng-container matColumnDef="reportedDate">
          <th mat-header-cell *matHeaderCellDef> Reported Date </th>
          <td mat-cell *matCellDef="let element"> {{element.reportedDate | date: 'dd-MMM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="mainReason">
          <th mat-header-cell *matHeaderCellDef>Reason</th>
          <td mat-cell *matCellDef="let element"> {{element.mainReason}} -> {{element.subReason}} </td>
        </ng-container>
        <ng-container matColumnDef="candidateId">
          <th mat-header-cell *matHeaderCellDef> Candidate</th>
          <td mat-cell *matCellDef="let element"><a mat-flat-button (click)="previewCandidateProfile(element.candidateId)">{{element.candidateName}}</a> </td>
        </ng-container>
        <ng-container matColumnDef="opportunityId">
          <th mat-header-cell *matHeaderCellDef> Opportunity</th>
          <td mat-cell *matCellDef="let element"> <a mat-flat-button (click)="previewPosting(element.opportunityId)">{{element.opportunityTitle}}</a> </td>
        </ng-container>
        <ng-container matColumnDef="reviewed">
          <th mat-header-cell *matHeaderCellDef>  Reviewed </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox disabled [checked]="element.actionTaken != null"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionTaken">
          <th mat-header-cell *matHeaderCellDef> Action Taken </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="fill" class="selectoption">
              <mat-label>Choose an option</mat-label>
              <mat-select [(value)]="element.actionTaken" (selectionChange)="takeAction(element)" [disabled]="element.opportunityDisabled == 1 || element.userDisabled == 1">
                <mat-option disabled></mat-option>
                <mat-option *ngFor="let option of actionOptions" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="disablebutton">
          <th mat-header-cell *matHeaderCellDef>  Disable</th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button color="primary" type="button" (click)="disable(element)" [disabled]="element.opportunityDisabled == 1 || element.userDisabled == 1">{{element.candidateId!=null?'Disable this User':'Disable this Posting'}} </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"
                     showFirstLastButtons
                     aria-label="Select page">
      </mat-paginator>
    </div>

  </div>
</mat-card>
