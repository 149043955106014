<div class="candidate-info-containter">
  <div fxLayout="row">
    <div fxLayout="column" fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
      <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
    </div>
    <div fxLayout="column" fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex="90" fxFlex.lt-md="80">
          <h1 [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Add Additional Information</h1>
        </div>
        <div fxLayout="column" fxFlex="5" fxFlex.lt-md="20">
          <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="contactForm" fxLayout="row">
    <div fxLayout="row wrap" fxFlex="42" fxFlexOffset="5" fxFlex.lt-md="95">
      <div formArrayName="preferredContacts" fxLayout="column">
        <div fxLayout="row">
          <h4 class="remove-margin pad-bottom-15">Add a link to your online portfolio or any other online sources that showcases your telent.</h4>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center none" *ngFor="let newContact of Contacts.controls; let i = index;" [formGroupName]="i">
          <mat-divider [inset]="true" fxLayoutAlign="start" fxFlex="100" class="pad-bottom-15" *ngIf="i > 0"></mat-divider>
          <div fxFlex="90">
            <mat-form-field color="accent" appearance="outline" style="margin-bottom: -1.25em">
              <mat-label>Portfolio</mat-label>
              <mat-select formControlName="contactType">
                <mat-option *ngFor="let type of contactTypes" [value]="type.id">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field color="accent" appearance="outline">
              <mat-label>Copy Link Here</mat-label>
              <input matInput class="reg-input" formControlName="contact" placeholder="Copy Link Here">
            </mat-form-field>
          </div>
          <div fxFlex="10" fxLayoutAlign="center start">
            <button mat-icon-button color="accent" (click)="removeContact(i)"><mat-icon>delete</mat-icon></button>
          </div>
        </div>
        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="15px">
          <button fxFlex="no-grow" mat-stroked-button color="primary" [disabled]="!contactForm.valid" (click)="addContact()">ADD NEW</button>
          <button fxFlex="no-grow" type="button" mat-raised-button color="primary" [disabled]="!contactForm.valid" (click)="next()">{{edit === false ? 'NEXT' : 'SAVE' }}</button>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="40" fxFlexOffset="60px" fxHide.lt-md="true" fxLayoutAlign="start stretch">
      <app-logo [tenant]="tenant" [type]="'candidate-reg-contacts'"></app-logo>
    </div>
  </form>
</div>
