<mat-card fxLayout="column" [ngClass.gt-xs]="'medium-card'" *ngIf="!showAllNotification">
  <div fxFlex="10" fxLayoutAlign="stretch stretch" *ngIf="!showAllNotification">
    <div fxFlex="80" fxLayou="row">
      <div fxLayout="row" fxFlex="100">
        <mat-card-title>
          <h2 matBadge="{{totUnread}}">
            New Notifications&nbsp;&nbsp;
          </h2>
        </mat-card-title>
      </div>
    </div>
    <button mat-button fxFlex="20" (click)="viewAllNotifications()" fxLayoutAlign="end center" color="accent">VIEW ALL</button>
  </div>
  <mat-divider fxFlex="0" [inset]="true"></mat-divider>
  <mat-card-content fxFlex="90">
    <div *ngIf="this.totUnread == 0" fxLayout="column" fxLayoutAlign="space-around center" fxFlex>
      <mat-divider fxFlex="20%" [inset]="true"></mat-divider>
      <div fxFlex="80%">
        <h2 style="color:gray">No Notifications</h2>
      </div>
    </div>
    <div *ngIf="this.totUnread >= 1" fxFill [style.overflow]="'auto'">
      <mat-selection-list fxFlex="auto" style="padding-top:0px;" [multiple]="false">
        <div *ngFor="let n of notificationsList;">
          <div *ngIf="n.read == 'unread'">
            <mat-list-item [routerLink]="n.link" (click)="updateStatus(n)" class="mat-list-item-word-wrap" style="cursor: pointer;">
              <img matListAvatar *ngIf="n.imageURL" [src]="n.imageURL|safeHtml" />
              <div mat-line><span class="mat-display-3">{{ n.title }}</span></div>
              <div mat-line><span class="mat-body-2">{{ n.detail }}</span></div>
              <div mat-line><span class="mat-body-2">{{getFormattedDate(n.created_ts)}}</span></div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
          </div>
        </div>
      </mat-selection-list>
    </div>
  </mat-card-content>
</mat-card>
<div fxLayout="column"
     fxFlex="100"
     *ngIf="showAllNotification"
     [ngClass]="{'overflow-desktop':!isMobileDevice}" >
  <div fxFlex="80" fxLayout="row" fxFlex.xs="80"
       [ngClass.xs]="'overflow-mobile'"
       [ngClass.sm]="'overflow-mobile'" 
       *ngIf="totalCount >= 1"
       fxFill
       [style.overflow]="'auto'">
    <mat-selection-list fxFlex="auto" style="padding-top:0px;" [multiple]="false" cdkScrollable>
      <div *ngFor="let n of notificationsList;">        
        <mat-list-item [routerLink]="n.link"
                       (click)="updateStatus(n)"
                       class="mat-list-item-word-wrap"
                       style="cursor: pointer;">
          <img matListAvatar *ngIf="n.imageURL" [src]="n.imageURL|safeHtml" />
          <div mat-line><span class="mat-display-3">{{ n.title }}</span></div>
          <div mat-line><span class="mat-body-2">{{ n.detail }}</span></div>
          <div mat-line><span class="mat-body-2">{{getFormattedDate(n.created_ts)}}</span></div>
          <mat-divider mat-line [inset]="true"></mat-divider>
        </mat-list-item>       
      </div>
    </mat-selection-list>
  </div>
  <div fxFlex="10" fxFlex.xs="20" fxLayout="row" *ngIf="totalCount > 0"  fxLayoutAlign="end center">
    <mat-paginator [length]="totalCount"
                   [hidePageSize]="isMobileDevice"
                   (page)="managePagination($event)"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[25, 50, 100, 500]">
    </mat-paginator>
  </div>
  <div *ngIf="this.totalCount == 0" fxFlex="100" >    
    <div fxFlex="100%" fxLayoutAlign="center center">
      <h2 style="color:gray">No Notifications</h2>
    </div>
  </div>
</div>


