<div fxLayout="row wrap " fxLayoutGap="10px grid" fxLayoutAlign="start center" class="application-feed">
  <div fxLayout="row" fxFlex="100" fxLaoutAlign="end">
    <div fxLayout="column" fxFlex="70" fxFlex.sm="60" fxFlex.xs="50"></div>
    <div fxLayout="column" fxFlex="30" fxFlex.sm="40" fxFlex.xs="50">
      <mat-select class="engaged-sort-select" mat-button color="accent" *ngIf="candidates.length > 0" [(value)]="selectedSort" (selectionChange)="onChangeSort($event.value)">
        <mat-option value="0">Sort By Date - DESC</mat-option>
        <mat-option value="1">Sort By Date - ASC</mat-option>
        <mat-option value="2">Sort By Status - DESC</mat-option>
        <mat-option value="3">Sort By Status - ASC</mat-option>
      </mat-select>
    </div>
  </div>
  <div fxLayout="row" fxFlex="100" *ngFor="let candidate of candidates">
    <mat-card class="mat-elevation-z4 student-card student-card-small" (click)="getCandidateDetails(candidate.id)">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="auto" fxLayoutGap="5px 5px" [ngClass]="{'matcardcandidatelogo': breakpoint == 1, 'logo': breakpoint != 1}">
          <img *ngIf="candidate.profile_pic == null || candidate.profile_pic == ''"
            [ngClass]="{'matcardcandidateimage': breakpoint == 1, 'mat-card-image': breakpoint != 1}"
            class="card-round-image" src="assets/img/User-blue-icon.png">
          <img *ngIf="candidate.profile_pic != null && candidate.profile_pic != ''"
            [ngClass]="{'matcardcandidateimage': breakpoint == 1, 'mat-card-image': breakpoint != 1}"
            class="card-round-image" src="{{candidate.profile_pic}}">
        </div>
        <div fxFlex="auto">
          <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="70" fxFlex.xs="100">
              <div fxLayout="row" fxLayout="row wrap" fxLayoutGap="5px 5px">
                <div fxLayout="column" fxFlex="32" fxFlex.xs="100">                
                    <span class="mat-display-4">
                      {{candidate.name}}
                    </span>                                   
                  <div fxLayout="row start" *ngIf="candidate.candidate_state != null && candidate.candidate_city != null">
                    <mat-card-subtitle>
                      <div fxLayout="row" class="engaged-candidates-location">
                        <mat-icon>room</mat-icon><span class="mat-body-2">{{candidate.candidate_city | titlecase}}, {{candidate.candidate_state}}</span>
                      </div>
                    </mat-card-subtitle>
                  </div>
                </div>
                <div fxFlex="33" fxFlex.xs="100">
                  <mat-card-subtitle>
                    <div fxLayout="row"><span class="mat-small">APPLIED ON</span></div>
                    <div fxLayout="row"><span class="mat-display-2">{{ candidate.opportunity_status != 1 ? candidate.created_ts : 'N/A' }}</span></div>
                  </mat-card-subtitle>
                </div>
                <div fxFlex="32" fxFlex.xs="100" class="margintopcontent3">
                  <mat-card-subtitle>
                    <div fxLayout="row"><span class="mat-small">STATUS CHANGED ON</span></div>
                    <div fxLayout="row"><span class="mat-display-2">{{candidate.last_updated_ts}}</span></div>
                  </mat-card-subtitle>
                </div>
              </div>
            </div>
            <div fxFlex="30"  fxFlex.xs="100" fxLayoutAlign="" fxLayoutAlign.xs="start" [ngClass]="{'statushired-div' : candidate.opportunity_status == 4, 'auto-height': breakpoint == 0} ">
              <span>
                <span fxFlex class="status" [ngClass]="{'opportunitystatusinvited' : candidate.opportunity_status == 1, 'opportunitystatusappliedadvanced' : candidate.opportunity_status == 2 || candidate.opportunity_status == 3, 'opportunitystatushired' : candidate.opportunity_status == 4}">
                  <span>{{candidate.current_status}}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<!-- Paginator -->
<div class="padded top-margin" *ngIf="candidates.length > 0">
  <mat-paginator [pageSizeOptions]="[20,40,60,80,100]" [length]="totalLength" [pageSize]="20"
    (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
</div>
<div *ngIf="candidates.length == 0">
  <h3>No candidate(s) found!</h3>
</div>
