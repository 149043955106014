import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TalentApiService } from '../../../Services/talent-api.service';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-feedback-template',
  templateUrl: './feedback-template.component.html',
  styleUrls: ['./feedback-template.component.css']
})
export class FeedbackTemplateComponent implements OnInit {

  public studentId:any;
  public opportunity:any;
  public status: any;
  public  internship: any;
  public studentName: any;
  public classId: any;  

  // approve all

  public activeApps:any;
  public approveAll=false;

  public showChoices = 1;
  public readonly = false;
  public saveAsTemplate = false;
  public minDate = new Date();
  public createdFormsDueDate = "";
  public templatesDueDate = '';
  public formName = "";
  public templates = [];
  public showSaveTemplate = false;
  public selectedTemplate = [];
  public selectedTempIndex = 0;
  //public keys= [ENTER, COMMA, SPACE];
  public keys= [188];

  public selectedTemplateName = '';
  public chips = ["Unknown", "OhYa"];

  public createdForm = {
      stars:[],
      questions:[{
          name:"",
          content:""
      }],
      checkboxes:[{
          question:"",
          boxes:[{
              name:"",
              content:0
          }]
      }]
  };
  feedbackTemplates:any;
  tmp = {};
  option:any;
  index:any;
  item:any;
  cBox:any;

  constructor(private dialogRef:MatDialogRef<FeedbackTemplateComponent>,private _talentApiService:TalentApiService) { }

  ngOnInit() {
      this.getTemplates();
  }

  getTemplates(){
    this.selectedTemplate = [];

    this._talentApiService.getMyFeedbackTemplate().subscribe((result:any) => {
        this.showChoices = 1;
        this.feedbackTemplates = result;
        var self = this;
        this.feedbackTemplates.forEach(function(form, index, array){
            
            self.templates.push(JSON.parse(form['form']));
            self.templates[index].name = form.name;
            self.templates[index].id = form.id;

        });
        this.templates.push({name:"Custom Template", id:0});
    },error => {
        console.log("Error Loading getMyStudentInternships");
    });
  }
  
  showSelectedTemplate(){
    this.selectedTemplate = [];
    if(this.selectedTemplateName == "Custom Template"){
            //vm.selectedTemplate = [];
            this.selectedTemplate.push(this.createdForm);
            this.showSaveTemplate = true;
        }else{
            var self = this;
            this.templates.forEach(function(item, index, array){
                if(item.name == self.selectedTemplateName){
                    //self.tmp = Object.assign({},item);
                    self.tmp = JSON.parse(JSON.stringify(item));
                    //angular.copy(item, this.tmp);
                    self.selectedTemplate.push(item);
                }
            });

        };
        this.showChoices = 2;
  }

  cancel(){
    this.dialogRef.close();
  }

  goBack(){
    if(this.showChoices>=4 && this.showChoices<=6){
        this.showChoices = 1;
    }else{
        this.showChoices--;
    }
  }

  setShowChoices(event){
      console.log(event.index);
      console.log(this.showChoices);
        if(event.index== 1){ // QUESTIONS
            this.showChoices=2;
        } else if(event.index== 2){ //CHECK BOXES
            this.showChoices=3;
        } else if(event.index== 3){//OPTIONS
            this.checkTemplates();
        }
  }

  checkTemplates(){
        var tempSelectedTemplate = JSON.stringify(this.selectedTemplate[0]);
        var tempAssignedTemplate = JSON.stringify(this.tmp);
        if(tempSelectedTemplate!=tempAssignedTemplate){
            this.showSaveTemplate = true;
        }else{
            this.showSaveTemplate = false;
        }
        this.showChoices = 4;
  }

  addOptions(option, indx){
    this.option = option;
    this.index = indx;
    switch(this.option) {
        // this has to start from case 2 as our option 1 is to just show the template selector dialog
        case 2:
            this.selectedTemplate[0].questions.push({name: "", content: ""});
            break;
        case 3:
            this.selectedTemplate[0].checkboxes.push({question: "",boxes:[{name:"",content:0}]});
            break;
        case 4:
            this.selectedTemplate[0].checkboxes[this.index].boxes.push({name:"", content:0});
            break;
        default:
            console.log("wrong input");
    }
  }

  removeOption(num, itm, chkBox) {
    this.option = num;
    this.item = itm;
    this.cBox = chkBox;
    switch(this.option) {
        case 2:
            var lastItem = this.selectedTemplate[0].questions.length-1;
            this.selectedTemplate[0].questions.splice(lastItem);
            break;
        case 3:
            var lastItem = this.selectedTemplate[0].checkboxes.length-1;
            this.selectedTemplate[0].checkboxes.splice(lastItem);
            break;
        case 4:
            var lastIndex = this.cBox.boxes.indexOf(this.item);
            this.cBox.boxes.splice(lastIndex, 1);
            break;
        default:
            console.log("something wrong");
    }

};

approveStudent(){
    this._talentApiService.editCourseRegistration(this.studentId, this.status, this.opportunity,this.classId).subscribe((result:any) => {
        this._talentApiService.createFeedback(this.selectedTemplate[0], this.internship, this.templatesDueDate).subscribe((result:any) => {
            if(this.saveAsTemplate){
                this._talentApiService.createFeedbackTemplate(this.selectedTemplate[0], this.formName).subscribe((result:any) => {  
                    this.dialogRef.close();
                    this._talentApiService.snackbarMessage('Template Saved and Request Accepted!');
                } 
                ,function errorCallback(){
                        //nope
                });
            }else{
                this.dialogRef.close();
                this._talentApiService.snackbarMessage('Request Accepted!');
            }
        },error => {
            this._talentApiService.snackbarMessage("Something went wrong. Please check form and try again.");
        });
    },error => {
        this._talentApiService.snackbarMessage("Something went wrong in course registration. Please check form and try again.");
    });
}


feedbackApproveAll(){
    var self = this;
    
    this._talentApiService.approveAllFeedback(this.activeApps,this.studentId,this.selectedTemplate[0],this.templatesDueDate,this.formName,this.saveAsTemplate).subscribe((result:any) => {
        this._talentApiService.snackbarMessage('All Feedback Request Accepted!');
        this.dialogRef.close();

    },error => {
        this._talentApiService.snackbarMessage("Something went wrong in approve feedback. Please check form and try again.");
    });
    
}



}
