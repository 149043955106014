import { environment } from '../../environments/environment';
export class TjsConfigurationHeler{
	TjsConfigurationHeler(){
	}
	LoadConfigurations(configObj) {
        ConfigurationModel.Configuration = configObj;
    }
}

export class ConfigurationModel{
	static Configuration = {
		APP_NAME: 'Talent Jumpstart',
		BASE_API_URL: environment.BASE_API_URL
	}
}