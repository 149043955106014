import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {
  public errorTitle: string = "Error"
  public errorMessage: string = "";
  public errorClose: string = "Close";

  constructor(public _dialogRef: MatDialogRef<ErrorMessageComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this._dialogRef.close();
  }

}
