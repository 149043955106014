import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EmployerRegisterService } from '../../../Services/employer-register.service';
import { CompanyInfoService } from '../../../Services/company-info.service';
import { TalentApiService } from '../../../Services/talent-api.service';
import { Router } from '@angular/router';
import { Country, State, City }  from 'country-state-city';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-company-admin-about',
  templateUrl: './company-admin-about.component.html',
  styleUrls: ['./company-admin-about.component.css']
})
export class CompanyAdminAboutComponent implements OnInit {
  sectors:string[] = [];
  sizes:string[] = [];
  countries:any;
  public states = ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
    'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
    'WY').split(' ').map(function (state) {
      return { abbrev: state };
    });
  sectorIds = [];
  loaded:boolean = false;
  resetData:any;
  companyInfoForm = new UntypedFormGroup({
		name: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
		website: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
		size: new UntypedFormControl('', ),
    sectors: new UntypedFormControl('', [Validators.required]),
    address: new UntypedFormControl('', [Validators.required]) ,
    city: new UntypedFormControl('', [Validators.required]),
    zip: new UntypedFormControl('', [Validators.required]),
    state: new UntypedFormControl(''),
    country: new UntypedFormControl(''),
    description: new UntypedFormControl('', [Validators.required])
	});
  logoFile: any = {
    mimetype: '',
    encoding: '',
    content: '',
    name: '',
    size: 0
  };
  imageError: boolean;
  logoPreview: any;
  noLogo: any;
	

  constructor(
    public _companyInfoService: CompanyInfoService,
    public _employerRegisterService: EmployerRegisterService,
    public _router: Router,
    public _talentApiService: TalentApiService,
    public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.countries = Country.getAllCountries();
    this._companyInfoService.getCompanyInfo().subscribe((result: any) =>{
        this.loaded = true;
        this.companyInfoForm.patchValue(result);
        result.sectors.forEach(element => {
          this.sectorIds.push(element.id);
        });
        this.companyInfoForm.get('sectors').patchValue(this.sectorIds);
        this.resetData = this.companyInfoForm.value;
    });
    this._employerRegisterService.getSectors().subscribe((result: any) =>{
      this.sectors = result;
    });
    this._employerRegisterService.getCompanySizeList().subscribe((result: any) =>{
      this.sizes = result;
    });
  }

  cancel() {
    if (window.innerWidth > 1100) {
      this.companyInfoForm.patchValue(this.resetData);
    } else {
      this._router.navigate(['/companyAdmin']);
    }
  }


  submit() {
    this._companyInfoService.updateCompanyInfo(this.companyInfoForm.value).subscribe((result:any) => {
      this._talentApiService.snackbarMessage("Company information has been updated");
    },
    error => {
      this._talentApiService.snackbarMessage("Failed to update Company Info");
    })
  }

  fileChangeEvent(event: any): void {
		let fileExtension = event.target.value.replace(/^.*\./, '');
		switch (fileExtension) {
			case 'png': case 'jpeg': case 'jpg':
        var reader = new FileReader();
				if (event.target.files && event.target.files[0]) {
					this.logoFile.size = event.target.files[0].size / 1024;
					if (this.logoFile.size > 2048) {
						this._talentApiService.snackbarMessage("Only JPG, PNG or Gif images are allowed with file size of 2MB only.");
						break;
					}
					this.logoFile.mimetype = event.target.files[0].type;
					this.logoFile.name = event.target.files[0].name;
					this.logoFile.size = event.target.files[0].size / 1024;
					this.logoFile.encoding = 'base64';
          reader.onload = (event: ProgressEvent) => {
						this.logoFile.content = reader.result;
						this.logoPreview = (<FileReader>event.target).result;
            this.submitLogoChange();
					}
					reader.readAsDataURL(event.target.files[0]);
					event.target.value = null;
				}
			default:
				break;
		}
	}

  submitLogoChange() {
    this.loaded = false;
    this._companyInfoService.uploadLogo(this.logoFile)
      .subscribe(
        (result:any) => {
          this.loaded = true;
          this._talentApiService.snackbarMessage("Logo has been updated");
        },
        error => {
          this._talentApiService.snackbarMessage("Error occurred while submitting logo.");
        }
      );
  }


}
