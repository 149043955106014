import { Injectable } from '@angular/core';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedUtilitiesService {

  constructor(private _http: HttpClient) { }


	getClearbitCompanyinfoWithFallback(companyName){
		let name = companyName.replaceAll(" ","+");
		let data = { 'name' : companyName };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getOpportunityCompany", data, {}).pipe(
		 map(response => {
				{
					if (typeof (response) !== undefined) {
						return response['result'];
					}
					console.log("could not get company info")
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)));
	}

	getOrganizationLogo(orgName, orgType) {
		let data = { 'organization_name': orgName };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "organization/getOrganizationLogo", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['file'];
					}
					else {
						console.log('Couldnt get Organization Logo');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getMyProfile(candidateId, id) {
		let data = { "id": candidateId, "user-id": id };

		let headers = new HttpHeaders();
		let user: any;

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateProfile", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['candidateProfile'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				error
			)),);
	}


	getFile(id, table, col) {
		let data = {	
			"id": id, "table": table, "col": col
		};
		//console.log(data);

		let headers = new HttpHeaders();
		let user: any;

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getFile", data, { headers: headers }).pipe(
			map(response => {
				{
					//console.log(response);
					if (typeof (response) !== 'undefined') {
						return response['file'];
					}

					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
		//}
	}
}
