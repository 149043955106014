import { Component, Input, OnInit, Output, EventEmitter, Optional, ViewChild} from '@angular/core';
import { CandidateSkill } from '../../../../Models/CandidateProfile';
import { UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { TalentApiService } from '../../../../Services/talent-api.service';
import {Observable} from 'rxjs';
import { EmployerStudentService } from '../../../../Services/employer-student.service';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import { ProfileEditComponent } from '../../../../Pages/profile-edit/profile-edit.component';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SkillTreeControlComponent } from '../../../../Pages/controls/skill-tree-control/skill-tree-control.component';
@Component({
  selector: 'app-candidate-skills',
  templateUrl: './candidate-skills.component.html',
  styleUrls: ['./candidate-skills.component.css']
})
export class CandidateSkillsComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Input() candidateSkills: any;
  @Input() edit: boolean;
  @Output() saveProfile = new EventEmitter<any>();
  @Output() prevEvent = new EventEmitter<any>();
  skillCtrl = new UntypedFormControl();
  public form = this.fb.group({
    skills: new UntypedFormArray([])
  });
  filteredSkill: Observable<string[]>;
  public isNextClicked = false;
  public allSkills;
  public breakpoint:number; // used to classify the screen size/display width
  constructor(public fb: UntypedFormBuilder, 
    public _dialog: MatDialog,
    public _builder: UntypedFormBuilder,
    private _talentAPIService: TalentApiService, 
    private _employerStudentService: EmployerStudentService, 
    private breakpointObserver: BreakpointObserver,
    @Optional() private dialogRef:MatDialogRef<ProfileEditComponent>) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 425px)', 
			'(max-width: 800px)', '(max-width: 1176px)', 
			'(max-width: 1550px)']).subscribe(result => {
				if(result.breakpoints['(max-width: 425px)']) {
					this.breakpoint = 0;
				}	
				else if(result.breakpoints['(max-width: 800px)']) {
					this.breakpoint = 1;
				}	
				else if(result.breakpoints['(max-width: 1176px)']) {
					this.breakpoint = 2;
				}
				else if(result.breakpoints['(max-width: 1550px)']) {
					this.breakpoint = 3;
				}
				else {
					this.breakpoint = 4
				}
			});
    this.form.patchValue(this.candidateSkills);
    this.getSkillList();
    if(this.candidateSkills && this.candidateSkills.length > 0){
      for(let exp of this.candidateSkills) {
        this.t.push(this.fb.group({
          name: [exp.name, [Validators.required, Validators.maxLength(80)]],
          expYears: [exp.expYears, [Validators.required,Validators.pattern(/^-?([0-9]\d*)?$/)]]
        }));
      }
      this.skillCtrl.setValue(null);
    }
    if(!this.edit){
			if(this._talentAPIService.isResumeUpload) {
        this.getskillsFromResume();
      }
    }
  }

  get f() { return this.form.controls; }
  get t() { return this.f.skills as UntypedFormArray; }

  filterSkills() {
    this.filteredSkill = this.skillCtrl.valueChanges.pipe(
      startWith(null),
      map((skill: string | null) => skill ? this._filter(skill) : this.allSkills.slice())
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allSkills.filter(skill => skill.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getSkillList(){
    this._employerStudentService.getSkillList().subscribe((result:any) => {
			if (result) {
        this.allSkills = result;
        this.filterSkills(); 
			}
			else {
				console.log('employer students not geeting');
			}
		});
  }

  openSkillDialog() {
    let data = { "isMobileDevice": this.breakpoint < 2, "skillData": this.f.skills as UntypedFormArray };
    const dialog = this._dialog.open(SkillTreeControlComponent, {
      height: this.breakpoint < 2 ? '75%' : '64%',
      width: this.breakpoint < 2 ? '100%' : '54%',      
      data: data
    });

    dialog.afterClosed().subscribe((newSkills) => {
      if (newSkills != undefined && newSkills.length >= 0) {
        this.t.clear();
        for (var i = 0; i < newSkills.length; i++) {          
          this.t.push(this._builder.group({
            id: newSkills[i].id,
            name: [newSkills[i].name, [Validators.required, Validators.maxLength(100)]],
            expYears: [newSkills[i].exp, [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]]
          }));
        }
      }
    });
    return false;
  }

  removeSkillWithYear(index) {
    if (index !== -1) {
      this.t.removeAt(index);
    }
  }

  next() {
    this.isNextClicked = true;
    this.markFormGroupTouched(this.form);
    if(this.t.controls.length == 0) {
      this._talentAPIService.snackbarMessage('Please enter skill(s)');

    } else if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return;
    } else {
      const candidateProfile = this.form.value;
      this._talentAPIService.updateProfile(candidateProfile)
        .subscribe(
        (result:any) => {
            this.saveProfile.emit(candidateProfile.skills);
            var data = this.form.value;
            if(this.edit === true) {
              this.dialogRef.close(data);
            }
  
        },
        error => {
          this._talentAPIService.snackbarMessage('Something went wrong!');
        });
    }
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  
  prev() {
		this.prevEvent.emit();
	}

  getskillsFromResume() {
    this._talentAPIService.getskillsFromResume()
		.subscribe(
		(result:any) => {
      let newSkills = result
			if (newSkills != undefined && newSkills.length >= 0) {
        this.t.clear();
        for (var i = 0; i < newSkills.length; i++) {          
          this.t.push(this._builder.group({
            id: newSkills[i].id,
            name: [newSkills[i].name, [Validators.required, Validators.maxLength(100)]],
            expYears: [newSkills[i].exp, [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]]
          }));
        }
      }
			else {

			}
		},
		error => {
			this._talentAPIService.snackbarMessage('Something went wrong!');
		});
  }
}
