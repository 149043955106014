<div class="candidate-info-containter">
    <div fxLayout="row">
        <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
            <!-- <button mat-mini-fab (click)="prev()"> -->
                <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
            <!-- </button> -->
        </div>
        <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
            <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Type of position you are looking for?</h1>
            <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="94" fxFlexOffset="5">
            <form [formGroup]="form" autocomplete="off" novalidate>
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="100">
                        <div fxLayout="row">
                            <div fxLayout="column" fxFlex="100">
                                <div fxLayout="row" fxLayoutAlign="space-between">
                                    <div fxLayout="column" fxFlex="100">
                                        <mat-selection-list formControlName="availabilities" [compareWith]="compareFunction">
                                            <mat-list-option checkboxPosition="before" [value]="avail" *ngFor="let avail of candidateAvailabilityTypes">
                                                {{avail.name}}
                                            </mat-list-option>
                                        </mat-selection-list>
                                    </div>
                                    
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between">
                                    <div fxLayout="column" fxFlex="100">
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="45px">
                                    <div fxLayout="column" fxFlex="100">
                                        <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">What is your desired Rate?</h1>
                                        <div fxLayout="row" fxLayoutGap="15px">
                                            <mat-form-field color="accent" appearance="outline" fxFlex="25" fxFlex.xs="40">
                                                <mat-label>Min hourly rate:</mat-label>
                                                <input matInput type="number" formControlName="minComp" (change)="minValueInput($event)">
                                            </mat-form-field>
                                            <mat-form-field color="accent" appearance="outline" fxFlex="25" fxFlex.xs="40">
                                                <mat-label>Max hourly rate:</mat-label>
                                                <input matInput type="number" formControlName="maxComp" (change)="maxValueInput($event)">
                                            </mat-form-field>
                                        </div>
                                        <div [ngClass]="!edit ?'slider-margin':'slider-margin_edit'" *ngIf="false">
                                            <mat-slider class="slider-over min-slider" [ngClass]="{'mat-slider-horizontal_eidt': edit}" (change)="minValueInput($event)" formControlName="minComp" [color]="minColor"
                                               step="1" [value]="form.value.minComp">
                                            </mat-slider>
                                            <mat-slider class="slider-over" [ngClass]="{'mat-slider-horizontal_eidt': edit}" (change)="maxValueInput($event)" formControlName="maxComp" [color]="maxColor"
                                              step="1" [value]="form.value.maxComp">
                                            </mat-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <button fxFlexOffset="30px" mat-raised-button color="primary" [disabled]="!form.valid" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    
</div>
