import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportService } from '../../../Services/report.service';
import { TalentApiService } from '../../../Services/talent-api.service';

export interface DialogData {
	opportunityId: number;
  candidateId: number;
}

@Component({
  selector: 'app-content-reporting',
  templateUrl: './content-reporting.component.html',
  styleUrls: ['./content-reporting.component.css']
})
export class ContentReportingComponent implements OnInit {

  reportingMainReasons: any[];
  subReasons: any[];
  title: any = "Why are you reporting this?";
  showDetails=false;
  isLoading = false;
  isSubmitClicked = false;

  constructor(private fb: UntypedFormBuilder,
    public _dialogRef: MatDialogRef<ContentReportingComponent>,
    public reportService: ReportService,
    public _talentApiService: TalentApiService,
    @Inject(MAT_DIALOG_DATA) public input: DialogData) { }

  public form = this.fb.group({
    reason: [null, [Validators.required]]
  });

  ngOnInit(): void {
    this.getReasons(null);
  }

  closeReportingDialog() {
    this._dialogRef.close();
  }

  getReasons(reasonId) {
    this.isLoading = true;
    this.reportService.getContentReportReasons(reasonId).subscribe((result: any) => {
      this.isLoading = false;
      if(result && result.length > 0) {
        if(!reasonId) {
          this.reportingMainReasons = result;
        } else {
          this.subReasons = result;
        }
      }
    });
  }

  getDetails(data) {
    this.subReasons = [];
    this.getReasons(data.id);
    this.title = data.reason;
    this.showDetails = true;
  }
  resetList() {
    this.showDetails = false;
    this.title = "Why are you reporting this?";
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  submitReport() {
    this.isSubmitClicked = true;
    if(this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return false;
    }
    this.reportService.createContentReport(this.form.value.reason, this.input.opportunityId, this.input.candidateId).subscribe((result: any) => {
      this._talentApiService.snackbarMessage("Content report submitted successfully");
      this.closeReportingDialog();
    },error => {
      this._talentApiService.snackbarMessage("Failed to submit content report!");
    });
  }

}

