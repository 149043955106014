import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyInfoService } from '../../../../Services/company-info.service';
import { TalentApiService } from '../../../../Services/talent-api.service';

@Component({
  selector: 'app-create-user-group',
  templateUrl: './create-user-group.component.html',
  styleUrls: ['./create-user-group.component.css']
})
export class CreateUserGroupComponent implements OnInit {
  newGroupForm = new UntypedFormGroup({
		'group-name': new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
    'posting-permissions': new UntypedFormArray([]),
    'shortlist-permissions': new UntypedFormArray([])
	});
  groupPermissions = {};


  constructor(
    public _companyInfoService: CompanyInfoService,
    public _talentApiService: TalentApiService,
    private dialogRef: MatDialogRef<CreateUserGroupComponent>,
  ) { }

  ngOnInit(): void {
  }

  save() {
    this._companyInfoService.createGroup(this.newGroupForm.get('group-name').value, this.groupPermissions).subscribe((result:any) => {
      this._talentApiService.snackbarMessage('New employer group: \'' + this.newGroupForm.get('group-name').value + '\'has been created');
      this.dialogRef.close();
    },
    error => {
      this._talentApiService.snackbarMessage("Failed to create group");
    })
  }

  onDataChange(event) {
    this.groupPermissions = event;
  }

}
