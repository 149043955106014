
<div id="editing-modal" class="timesheet-popup admin_popup" role="form">
    <form name="editingForm" id="editingForm" #editingForm="ngForm" class="" novalidate ng-cloak>
      <div class="modal-content">
            <div class="modal-header">
       
          <div>
            <h1 *ngIf="!editing.id" mat-dialog-title>Add Tenant</h1>
  
            <h1 *ngIf="editing.id" mat-dialog-title>Edit Tenant</h1>            
            
            <button class="mat-icon-button close_bt" (click)="closeDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
       
        </div>
     
      <div class="modal-body">
        <div class="row">            
            <div class="col-md-12">
              <mat-form-field color="accent" appearance="outline">
                  <input placeholder="Tenant Name" matInput name="editname" id="editname"  [(ngModel)]="editing.name" #editname="ngModel" minlength="2" maxlength="50" required (change)="editing.name=editing.name.trim()">
              
                 <mat-error *ngIf="editname.hasError('required')">You did not enter tenant name</mat-error>
  
                 <mat-error *ngIf="!editname.hasError('required') && editname.hasError('maxlength')">Tenant Name must be between 2 and 50 characters long.</mat-error>
                
              </mat-form-field>
  
              <small>* 50 characters maximum and must be a unique name.</small>
            </div>
        </div>
       
      </div>
      <div class="modal-footer">
          <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
                  <!-- // Edit  -->
                  <div>
                      <button mat-button mat-raised-button color="warn" *ngIf="editing.id" (click)="modalDelete()" class="Cancel-btt  pull-left">Delete</button>
                  </div>
  
                  <div>
                      <button  mat-button mat-raised-button color="primary"  *ngIf="editing.id" (click)="modalSave()" class="save-bt popup_done_btn " [disabled]="!editingForm.valid" >Save</button>
                  </div>
                 
                  <div>
                      <button  mat-button mat-raised-button *ngIf="!editing.id" [disabled]="!editingForm.valid" (click)="modalAddNew()" class="save-bt add_new ">Add New</button>
                  </div>
              </div>
          </div>
      </div>
  </form>
  </div>
  
  
  
  
  