<mat-toolbar>
    <div fxLayout="row" fxFlex="100">
        <div fxLayout="row" fxFlex="50" >
            Advanced Filter
        </div>
        <div fxLayout="row" fxFlex="50" fxLayoutAlign="end">
            <button mat-raised-button fxFlex="25" class="save" color="primary" (click)="submit(searchStudent)">SEARCH</button>
            <button fxFlexOffset="10px" mat-stroked-button fxFlex="25" class="clear" color="primary" (click)="cancelFilter()">CANCEL</button> 
        </div>
    </div>
</mat-toolbar>

<div class="student-detail-header background_none_cl  mat-dialog-fullscreen">
<form novalidate ng-cloak name="searchStudent" id="searchStudent" #searchStudent="ngForm">
    <mat-dialog-content class="bgpopup_in" cdkScrollable>
        <div class="common-padding">
            <div fxLayout="row" class="saved-search-div" fxLayoutAlign="space-around center">
                <div fxFlex="80">
                    <h5 class="mat-small">Save this search for easy search experience next time.</h5>
                  </div>
                  <div fxFlex="17" fxLayoutAlign="end center">
                    <button (click)="saveSearch()" mat-button color="primary">
                      <i class="fa fa-floppy-o"></i> SAVE SEARCH
                    </button>
                  </div>
            </div>
        </div>
        <div fxLayout="row" class="common-padding">
            <div fxLayout="column" fxFlex="100">
                <div fxLayout="row">
                    <div fxFlex="100%">
                        <mat-form-field color="accent" appearance="outline" class="example-chip-list">
                        <mat-chip-list #skillList aria-label="Skill selection">
                            <mat-chip *ngFor="let item of TempFilter.skills" [selectable]="selectable"
                                    [removable]="removable">
                            {{item}}
                            <mat-icon (click)="removeSkill(item)" matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="Search For Skills..."
                                    [matChipInputFor]="skillList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="false"
                                    [matAutocomplete]="auto"
                                    (matChipInputTokenEnd)="addSkill($event)"
                                    name="skillSearch"
                                    id="skillSearch"
                                    (blur)="addSkillOnBlur($event, skillList)"
                                    #skillName
                                    [formControl]="skillCtrl"
                                    #trigger="matAutocompleteTrigger"
                                    (keydown.enter)="trigger.closePanel()">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkill($event)">
                            <ng-container *ngFor="let skills of filteredSkill | async">
                                <mat-option [value]="skills.name" *ngIf="skills.name !=''">
                                  {{skills.name}}
                                </mat-option>
                            </ng-container>
                          </mat-autocomplete>
                        </mat-form-field>
                        <mat-error *ngIf="skillCtrl.errors && (skillCtrl.dirty || skillCtrl.touched) && skillCtrl.errors.maxlength">
                            Skill Name must be upto 80 characters
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>      
        <div fxLayout="row" class="common-padding">
            <div fxLayout="column" fxFlex="100">
                <div fxLayout="row">
                    <div fxFlex="100%">
                        <mat-form-field color="accent" appearance="outline" class="example-chip-list">
                            <mat-label class="mat-h4">Location</mat-label>
                            <mat-chip-list #locationList aria-label="Location selection">
                                <mat-chip *ngFor="let loc of TempFilter.searchParams.location"
                                        [selectable]="selectable"
                                        [removable]="removable"
                                        (removed)="removeLocation(loc)">
                                {{loc}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Add multiple prefered Locations"
                                    #locationInput
                                    [formControl]="locationCtrl"
                                    [matAutocomplete]="autoAddress"
                                    [matChipInputFor]="locationList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addLocation($event)"
                                    (ngModelChange)="getLocation($event)">
                            </mat-chip-list>
                            <mat-autocomplete #autoAddress="matAutocomplete" (optionSelected)="selectedLocation($event)">
                            <mat-option *ngFor="let item of searchResult" [value]="item">
                                {{ item.formatted_address }}
                            </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="100%">
                        <mat-label class="mat-h4">Education</mat-label>
                        <div fxLayout="row wrap" fxLayoutGap="20px" class="common-padding">
                            <div fxLayout="column" fxLayoutAlign="space-around center" *ngFor="let deg of TempFilter.searchParams.educationLevel; let i = index;">
                            <mat-checkbox color="primary" name="deg_{{i}}" matInput [(ngModel)]="deg.checked" (change)="searchByEducationLevel(deg.id,$event)">{{deg.name}}</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLatout="row">
                    <div fxFlex="100%">
                        <mat-label class="mat-h4">Availability</mat-label>
                        <div fxLayout="row wrap" fxLayoutGap="20px" class="common-padding">
                            <div fxLayout="column" fxLayoutAlign="space-around center"  fxFlexOffset="15px" *ngFor="let avlblType of TempFilter.searchParams.availability; let i = index;">
                            <mat-checkbox color="primary" name="availability_{{i}}" matInput [(ngModel)]="avlblType.checked" (change)="searchByAvaibility(avlblType.id, $event)">{{avlblType.availability_type}}</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</form>
</div>