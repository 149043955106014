<mat-card fxLayout="column" fxFlex="grow" [ngClass.gt-sm]="'small-card'">
    <mat-card-title>
        <h1><span  fxFlex="80"  fxFlex.xs="60" >Overview</span>
            <div fxFlex="20" fxFlex.xs="40"  fxLayoutAlign="end">
              <button mat-raised-button color="primary"
                      fxlayoutalign="end center"
                      [routerLink]="'/postings/matched'">
                FIND MY MATCH 
              </button>
            </div></h1>
    </mat-card-title>
    
    <mat-card-content fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutAlign.lt-sm="start stretch" fxLayoutGap="20px">
        <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-evenly center" fxLayoutGap="20px">
            <mat-card fxFlex [ngClass]="'overview-sub-card'" [routerLink]="'/profile'" class="clickable">
                <mat-card-title><h2> Profile Score </h2></mat-card-title>
                <mat-card-content >
                    <div fxLayout="row" class="marTop16px" fxLayoutAlign="space-between none">
                        <h1> {{ metrics.profileScore }}/10 </h1>
                        <mat-icon svgIcon="meter" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex [ngClass]="'overview-sub-card'" [routerLink]="'/profile'" class="clickable">
                <mat-card-title><h2> Views on Profile </h2></mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px">
                        <h1>{{ metrics.profileViews }}</h1>
                        <mat-icon svgIcon="eye" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-evenly center" fxLayoutGap="20px">
            <mat-card fxFlex [ngClass]="'overview-sub-card'" [routerLink]="'/postings/applied'" *ngIf="!(metrics.jobsApplied == '0')" class="clickable">
                <mat-card-title><h2> Active Applications </h2></mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px">
                        <h1>{{ metrics.jobsApplied }}</h1>
                        <mat-icon svgIcon="page" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex [ngClass]="'overview-sub-card'" [routerLink]="'/postings'" *ngIf="(metrics.jobsApplied == '0')" class="clickable">
                <mat-card-title><h2> Applications </h2></mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px">
                        <strong style="font-weight: 500;">Apply Now</strong>
                        <mat-icon svgIcon="page" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex [ngClass]="'overview-sub-card'" [routerLink]="'/postings/tagged'" class="clickable">
                <mat-card-title><h2> Tagged </h2></mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px">
                        <h1>{{ metrics.inPipeline }}</h1>
                        <mat-icon class="svg-icon" color="primary" style="padding-top: 5px;">star_border</mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>
</mat-card>
