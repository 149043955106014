<div fxLayout="row" fxLayoutAlign="center" *ngIf="isLoading" class="loader-margin">
  <mat-progress-bar [mode]="indeterminate">
  </mat-progress-bar>
</div>
<div  *ngIf="!isLoading  && resultDisplay">
  <div data-element-identifier="member.postings.posting-detail-pane">
    <button *ngIf="showHeaderBack" mat-button class="header-button" (click)="backshortlist()">
      <i class="fa fa-arrow-left arrow_leftt" aria-hidden="true"></i> Back
    </button>
    <div class="top-margin tab-content  opprtunity-posting-content">
      <div fxLayout="column" fxFlex="100" fxShow.sm="{{showDetail}}" fxShow.xs="{{showDetail}}">        
        <mat-card role="tabpanel" 
                  id="list_tab1"
                  class="shortlist-detail-card"
                  [ngClass.xl]="['shortlist-detail-card-xl','padding-25']"
                  [ngClass.lg]="['shortlist-detail-card-lg','padding-25']"
                  [ngClass.md]="'shortlist-detail-card-md'"
                  [ngClass.lt-md]="'shortlist-detail-card-lt-md'">
          <div class="arrow_shadow_none">
            <div class="profile_section">
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.xs="start start">
                <div fxFlex="20" fxFlex.xs="35" fxLayout="row" fxLayout.xs="column">
                  <div fxFlex="100" fxLayout="row" fxFlex.xs="70">
                    <app-company-logo fxFlex *ngIf="detail.fk_display_image == '0'"
                                      class="shortlist-logo"
                                      [name]="detail.company_name"
                                      [type]="'company'"></app-company-logo>
                    <app-company-logo fxFlex
                                      *ngIf="detail.fk_display_image != '0'"
                                      class="shortlist-logo"
                                      [imageURL]="detail.image"></app-company-logo>
                  </div>
                  <div *ngIf="!showHeaderBack && hasEditShortlistPermission" fxFlex.xs="30" fxHide fxShow.xs>
                    <button mat-stroked-button
                            color="primary"
                            (click)="OpenEditPopup()">
                      <span class="fa fa-1x fa-pencil fa-fw"></span>&nbsp;Edit
                    </button>
                  </div>
                </div>
                <div fxFlex="100">
                  <div fxLayout="row">
                    <div fxFlex="100" fxFlex.xs="95"                        
                         fxLayout.xs="column"
                         fxLayout="column"
                         fxLayoutAlign="space-between stretch">
                      <div>
                        <strong>
                          <mat-card-title>
                            <div fxFlex="86">
                              {{detail.name}}
                            </div>
                            <div *ngIf="!showHeaderBack && hasEditShortlistPermission" fxFlex="14" fxHide.xs fxLayout="row-reverse">
                              <button mat-stroked-button color="primary" (click)="OpenEditPopup()">
                                <span class="fa fa-1x fa-pencil fa-fw"></span>&nbsp;Edit
                              </button>
                            </div>
                          </mat-card-title>
                        </strong>
                        <strong *ngIf="totalCount > 1">
                          <mat-card-subtitle>
                            {{totalCount}} candidates
                          </mat-card-subtitle>
                        </strong>
                        <strong *ngIf="totalCount == 1">
                          <mat-card-subtitle>
                            {{totalCount}} candidate
                          </mat-card-subtitle>
                        </strong>
                        <strong *ngIf="totalCount == 0">
                          <mat-card-subtitle>
                            No candidates
                          </mat-card-subtitle>
                        </strong>
                      </div>
                      <div>
                        <mat-card-subtitle class="description-top"
                                           fxLayout="row"
                                           fxLayout.xs="column"
                                           fxLayoutAlign="space-between">
                          <span>
                            {{detail.description}}
                          </span>
                        </mat-card-subtitle>
                        <mat-card-subtitle fxLayout.xs="row"  fxLayout="row-reverse">
                          CREATED ON : {{detail.created_ts.toString() | date:'MM/dd/yyyy'}}
                        </mat-card-subtitle>
                      </div>                     
                    </div>
                    <div fxFlex.xs="5" fxHide fxShow.xs fxShow.sm fxLayoutAlign="end start">
                      <span>
                        <mat-icon (click)="toggleDetail()">close</mat-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column-reverse" class="btn-container mat-small">
                <div fxLayoutAlign="start center" fxFlex="100" fxFlex.xs="100">
                  <mat-chip-list cdkDropListOrientation="horizontal">
                    <ng-container *ngFor="let skill of detail.skills;let index=index;">
                      <mat-chip color="accent" *ngIf="skill.name !='' && index < 3" cdkDrag>
                        <span title="{{skill.name}}" *ngIf="skill.name.length <= 25">{{skill.name}} </span>
                        <span title="{{skill.name}}" *ngIf="skill.name.length > 25">{{skill.name.substring(0,24)}}.. </span>
                      </mat-chip>
                      <mat-chip color="accent" *ngIf="index == 3" cdkDrag>
                        +{{detail.skills.length - (index)}}
                      </mat-chip>
                    </ng-container>
                  </mat-chip-list>
                </div>                
              </div>
              <div fxLayout="row">
                <app-shortlist-candidates
                                          [shortlistId]="shortlistId"
                                          [shortlistDetail]="detail"
                                          [breakpoint]="breakpoint"
                                          fxFlex="100"
                                          fxFlex.gt-md="100"
                                          (onShortlistCandidateCount)="changeCandidateCount($event)"></app-shortlist-candidates>
                           
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </mat-card>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!resultDisplay" fxHide.xs fxShow>
  <mat-card class="top-margin padding-25 shortlist-detail-card" [ngClass.xl]="'shortlist-detail-card-xl'" [ngClass.lg]="'shortlist-detail-card-lg'" [ngClass.md]="'shortlist-detail-card-md'" [ngClass.lt-md]="'shortlist-detail-card-lt-md'">
    <div fxLayout="row" fxLayoutAlign="center center">
      <app-logo [tenant]="'TJS'" [type]="'no-posting'"></app-logo>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h2 fxLayout="row">The requested Shortlist Could Not Be Found.</h2>
    </div>
  </mat-card>
</div>

