<div class="bg-dark std-reg-form">
	<div class="">
		<section>
			<mat-toolbar-row>
				<!-- //Cheks -->
				<!-- <div class=""> -->
				<div class="col-md-12">
					<div class="steps-check">
						<p style="margin-bottom:0px;" class="text-center">
							<i class="fa fa-2x fa-check progress-pill" (click)="setTab(0)" [ngClass]="pg0.valid && visited[0] && !checkActiveTab(0) ? 'active' : 'notact'" *ngIf="(pg0.valid || (pg0.pristine && !visited[0]) || checkActiveTab(0))"></i>

							<i class="fa fa-2x fa-times" style="color: #D50000;" (click)="setTab(0)" *ngIf="pg0.invalid && visited[0] && !checkActiveTab(0)"></i>

							<i class="fa fa-2x fa-check progress-pill" (click)="setTab(1)" [ngClass]="pg1.valid && visited[1] && !checkActiveTab(1) ? 'active' : 'notact'" *ngIf="pg1.valid || (pg1.pristine && !visited[1] || checkActiveTab(1))"></i>

							<i class="fa fa-2x fa-times" style="color: #D50000;" (click)="setTab(1)" *ngIf="pg1.invalid && visited[1] && !checkActiveTab(1)"></i>

							<i class="fa fa-2x fa-check progress-pill" (click)="setTab(2)" [ngClass]="pg2.valid && visited[2] && !checkActiveTab(2) ? 'active' : 'notact'" *ngIf="pg2.valid || (pg2.pristine && !visited[2] || checkActiveTab(2))"></i>

							<i class="fa fa-2x fa-times" style="color: #D50000;" (click)="setTab(2)" *ngIf="pg2.invalid && visited[2] && !checkActiveTab(2)"></i>

							<i class="fa fa-2x fa-check progress-pill" (click)="setTab(3)" [ngClass]="pg3.valid && visited[3] && !checkActiveTab(3) ? 'active' : 'notact'" *ngIf="pg3.valid || (pg3.pristine && !visited[3] || checkActiveTab(3))"></i>

							<i class="fa fa-2x fa-times" style="color: #D50000;" (click)="setTab(3)" *ngIf="pg3.invalid && visited[3] && !checkActiveTab(3)"></i>
						</p>
					</div>
				</div>
				<!-- </div> -->
			</mat-toolbar-row>
			<div class="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3">
				<div class="internship-modal panel panel-text user_create mat-stepper-horizontal text-primary">
					<form name="regForm" id="regForm" #regForm="ngForm" autocomplete="off">
						<mat-toolbar class="background_ch2 internship-modal mat-default-theme">
							<mat-toolbar-row>
								<div class="col-md-12">
									<div>
										<h2 class="panel-title text-primary text-center" [hidden]="!checkActiveTab(0)">Tell us About Yourself.</h2>

										<h2 class="panel-title text-primary text-center" [hidden]="!checkActiveTab(1)">Company Information.</h2>

										<h2 class="panel-title text-primary text-center" [hidden]="!checkActiveTab(2)">Company Information.</h2>

										<h2 class="panel-title text-primary text-center" [hidden]="!checkActiveTab(3)">Almost Done!</h2>
									</div>
								</div>
							</mat-toolbar-row>
						</mat-toolbar>
						<div>
							<!-- Buttons next previous -->
							<div class="col-md-12">
								<div class="nav-buttons">
									<ul class="nav nav-pills student-reg-form-header">
										<li class="pull-left">
											<div>
												<button md-no-ink mat-raised-button (click)="setTab(tab-1)" [hidden]="checkActiveTab(0)">
													<span aria-hidden="true">&larr;</span> Previous</button>
											</div>
										</li>
										<li class="pull-right">
											<div>
												<button mat-raised-button (click)="setTab(tab+1)" [hidden]="checkActiveTab(3)">Next
													<span aria-hidden="true">&rarr;</span>
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<mat-dialog-content cdkScrollable>
							<div class="md-padding panel-body">
								<form name="pg0" id="pg0" #pg0="ngForm" novalidate [hidden]="!checkActiveTab(0)" autocomplete="off">
									<div class="row">
										<div class="col-lg-6">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<!-- <label>First Name</label> -->
												<input matInput name="firstName" placeholder="First Name" id="firstName" type="text" [(ngModel)]="empUser.firstName" #firstName="ngModel"
												 class="form-control" required autofocus>
												<mat-error *ngIf="firstName.hasError('required')" class="help-block small animated fadeInDown">
													You did not enter your first name
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-lg-6">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<!-- <label>First Name</label> -->
												<input matInput name="lastName" placeholder="Last Name" id="lastName" type="text" [(ngModel)]="empUser.lastName" #lastName="ngModel"
												 class="form-control" required>
												<mat-error *ngIf="lastName.hasError('required')" class="help-block small animated fadeInDown">
													You did not enter your last name
												</mat-error>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error':!empphone.valid}">
												<!-- <input matInput type="text" placeholder="Phone #" [textMask]="{mask: mask}" [(ngModel)]="empUser.phone" #empphone="ngModel"
												 class="form-control" name="empphone" id="empphone" pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"> -->
												<input matInput type="text" placeholder="Phone #" [(ngModel)]="empUser.phone" #empphone="ngModel"
												 class="form-control" name="empphone" id="empphone" mask="(000) 000-0000">
												<mat-error *ngIf="!empphone.pattern && !empphone.hasError('required')" class="help-block small animated fadeInDown">
													Please enter valid phone number </mat-error>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<!-- <label class="mytitle">Alternate Contact</label> -->
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error':!empskype.valid}">
												<input matInput type="text" placeholder="e.g. Skype ID" [(ngModel)]="empUser.skype" #empskype="ngModel" name="empskype" id="empskype" class="form-control">
											</mat-form-field>
										</div>
									</div>
									<!-- <div class="row">
										<div class="col-md-12">
											<mat-form-field class="full-width test" [ngClass]="{'has-error': !empemail.valid}">
												<input matInput type="email" placeholder="Email/ Username" required [(ngModel)]="empUser.email" #empemail="ngModel" name="empemail"
												 id="empemail" class="form-control" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" minlength="10" maxlength="100"
												 [disabled]="true">
												<mat-error *ngIf="!empemail.pattern && !empemail.valid && !empemail.hasError('required')" class="help-block small animated fadeInDown">
													Your email must be between 10 and 100 characters long and look like an e-mail address. </mat-error>
												<mat-error *ngIf="empemail.hasError('required')" class="help-block small animated fadeInDown"> You did not enter your email address </mat-error>
											</mat-form-field>
										</div>
									</div> -->
									<div class="row">
										<div class="col-md-12">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<label>Linked
													<i class="fa fa-1x fa-linkedin-square"></i>
												</label>
												<input matInput name="linkedin" id="linkedin" type="text" [(ngModel)]="empUser.linkedin" #linkedinurl="ngModel" pattern="https?://.+" (focus)="checkLinUrl(empUser.linkedin)">
												<mat-error *ngIf="!linkedinurl.pattern" class="help-block small animated fadeInDown">
													Please enter valid url
												</mat-error>
											</mat-form-field>
										</div>
									</div>
								</form>
								<!-- Employer's Company -->
								<form name="pg1" class="input_width_74" id="pg1" #pg1="ngForm" novalidate [hidden]="!checkActiveTab(1)" autocomplete="off">
									<div class="row">
										<div class="col-md-12">
											<mat-form-field color="accent" appearance="outline" class="full-width">
												<input matInput type="text" placeholder="Company Name" required name="autocompleteFieldcompany" id="autocompleteFieldcompany"
												 [(ngModel)]="empUser.company" #companyname="ngModel" class="form-control" [matAutocomplete]="autocompany" minlength="3"
												 maxlength="65" (blur)="checkCompanyObject(empUser.company)">

												<mat-autocomplete #autocompany="matAutocomplete" [displayWith]="addCompany">
													<mat-option *ngFor="let company of companyQuerySearch(empUser.company)" [value]="company">
														<!-- (onSelectionChange)="addNewOption()" -->
														{{ company.name }}
													</mat-option>
												</mat-autocomplete>
												<mat-error *ngIf="companyname.hasError('required'); else companynameSecond;" class="help-block small animated fadeInDown">
													You must have a Company Name.
												</mat-error>
												<ng-template #companynameSecond>
													<mat-error *ngIf="companyname.hasError('minlength') || companyname.hasError('maxlength')" class="help-block small animated fadeInDown">
														Company name must be between 3 and 100 characters long.
													</mat-error>
												</ng-template>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error':!autocompleteFieldAddress.valid}">
												<input matInput type="text" placeholder="Address 1" class="input-cust-width form-control" name="address" id="address" [(ngModel)]="empUser.location.address"
												 [matAutocomplete]="autoAddress" #autocompleteFieldAddress="ngModel" required>
												<mat-autocomplete #autoAddress="matAutocomplete">
													<mat-option *ngFor="let item of querySearch(autocompleteFieldAddress, approximateLocation.street)" [value]="item" (onSelectionChange)="setCompanyStreet(autocompleteFieldAddress)">
														{{ item }}
													</mat-option>
												</mat-autocomplete>
												<mat-error *ngIf="autocompleteFieldAddress.hasError('required')" class="help-block small animated fadeInDown">
													You must enter an address.
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<!-- <label class="mytitle">Address Line 2</label> -->
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error':!empaddress2.valid}">
												<input matInput type="text" placeholder="Address 2" [(ngModel)]="empUser.location.address2" #empaddress2="ngModel" name="empaddress2"
												 id="empaddress2" class="form-control">
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<!-- <label>City*</label> -->
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error':!autocompleteFieldcity.valid}">
												<input matInput placeholder="City" type="text" class="input-cust-width form-control" name="city" id="city" [(ngModel)]="user.location.city"
												 [matAutocomplete]="autoCity" #autocompleteFieldcity="ngModel" required>
												<mat-autocomplete #autoCity="matAutocomplete">
													<mat-option *ngFor="let city of querySearch(autocompleteFieldcity, approximateLocation.city)" [value]="city">
														{{ city }}
													</mat-option>
												</mat-autocomplete>
												<mat-error *ngIf="autocompleteFieldcity.hasError('required');" class="help-block small animated fadeInDown">
													You must have a City Name.
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-lg-6">
											<!-- <label>State*</label> -->
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error':!statelocation.valid}">
												<mat-select placeholder="State" [(ngModel)]="empUser.location.state" #statelocation="ngModel" name="state" id="state" class="form-control"
												 required>
													<mat-option *ngFor="let state of states" [value]="state.abbrev">{{state.abbrev}}</mat-option>
												</mat-select>
												<mat-error *ngIf="statelocation.hasError('required');" class="help-block small animated fadeInDown">
													You must choose a state
												</mat-error>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<!-- <label>Zip*</label> -->
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error':!autocompleteFieldZip.valid}">
												<input matInput placeholder="Zip" type="text" class="form-control input-cust-width" name="zip" id="zip" [(ngModel)]="user.location.zip"
												 [matAutocomplete]="autozip" #autocompleteFieldZip="ngModel" required>
												<mat-autocomplete #autozip="matAutocomplete">
													<mat-option *ngFor="let zip of querySearch(autocompleteFieldZip, approximateLocation.zip)" [value]="zip">
														{{ zip }}
													</mat-option>
												</mat-autocomplete>
												<mat-error *ngIf="autocompleteFieldZip.hasError('required');" class="help-block small animated fadeInDown">
													You must enter zip.
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-lg-6">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<!-- <label>Country*</label> -->
												<input matInput placeholder="Country" name="Country" id="Country" type="text" [(ngModel)]="user.location.metro" #countryname="ngModel"
												 minlength="3" maxlength="65" required class="form-control">
												<mat-error *ngIf="countryname.hasError('required'); else countrySecond;" class="help-block small animated fadeInDown">
													You did not enter your country name
												</mat-error>
												<ng-template #countrySecond>
													<mat-error *ngIf="countryname.hasError('minlength') || countryname.hasError('maxlength')" class="help-block small animated fadeInDown">
														Country name must be between 3 and 65 characters long.
													</mat-error>
												</ng-template>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<!-- <label>Company Website</label> -->
												<input matInput placeholder="Company Website" name="website" id="website" type="text" [(ngModel)]="user.company.website" #companywebsite="ngModel"
												 class="form-control" pattern="https?://.+" (focus)="checkCompanyUrl(companyWebsite)">
												<mat-error *ngIf="!companywebsite.pattern" class="help-block small animated fadeInDown">
													Please enter valid website url
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-lg-6">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<label>Linked
													<i class="fa fa-1x fa-linkedin-square"></i>
												</label>
												<input matInput name="linkedin" id="linkedin" type="text" [(ngModel)]="user.compSocialMedia.linkedIn" #linkedinurl="ngModel"
												 pattern="https?://.+" (focus)="checkLinUrlCompany(user.compSocialMedia.linkedIn)" class="form-control">
												<mat-error *ngIf="!linkedinurl.pattern" class="help-block small animated fadeInDown">
													Please enter valid url
												</mat-error>
											</mat-form-field>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<label>
													<i class="fa fa-1x fa-facebook-square"></i>Facebook</label>
												<input matInput name="facebook" id="facebook" type="text" [(ngModel)]="user.compSocialMedia.facebook" #companyfacebook="ngModel"
												 pattern="https?://.+" (focus)="checkfbUrl(user.compSocialMedia.facebook)" class="form-control">
												<mat-error *ngIf="!companyfacebook.pattern" class="help-block small animated fadeInDown">
													Please enter valid url
												</mat-error>
											</mat-form-field>
										</div>
										<div class="col-lg-6">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<label>
													<i class="fa fa-1x fa-twitter-square"></i>Twitter</label>
												<input matInput name="twitter" id="twitter" type="text" [(ngModel)]="user.compSocialMedia.twitter" #companytwitter="ngModel"
												 pattern="https?://.+" (focus)="checktwitterUrl(user.compSocialMedia.twitter)" class="form-control">
												<mat-error *ngIf="!companytwitter.pattern" class="help-block small animated fadeInDown">
													Please enter valid url
												</mat-error>
											</mat-form-field>
										</div>
									</div>
								</form>
								<form name="pg2" id="pg2" #pg2="ngForm" novalidate [hidden]="!checkActiveTab(2)" autocomplete="off">
									<div class="row">
										<div class="col-lg-12">
											<div class="col-md-6">
												<label style="display:block;">Is this a Remote Position?</label>
												<mat-slide-toggle class="example-margin" [(ngModel)]="user.location.remote" name="remote" id="remote">
													<span class="heading" *ngIf="user.remote">YES</span>
													<span class="heading" *ngIf="!user.remote">NO</span>
												</mat-slide-toggle>
											</div>
											<div class="col-md-6">
												<label style="display:block;">Is there transportation availabe for employees?</label>
												<mat-slide-toggle class="example-margin" [(ngModel)]="user.location.pubTransit" name="pubtrans" id="pubtrans">
													<span class="heading" *ngIf="user.location.pubTransit">YES</span>
													<span class="heading" *ngIf="!user.location.pubTransit">NO</span>
												</mat-slide-toggle>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<mat-form-field color="accent" appearance="outline" class="full-width" [ngClass]="{'has-error': !companydivision.valid}">
												<input matInput type="text" placeholder="Division / Group / Department" required [(ngModel)]="user.division" #companydivision="ngModel"
												 name="companydivision" id="companydivision" class="form-control">
												<mat-error *ngIf="companydivision.hasError('required')" class="help-block small animated fadeInDown"> You did not enter a Division </mat-error>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field color="accent" appearance="outline" class="full-width">
												<mat-select placeholder="Industry/ Sector" [(ngModel)]="user.sector" #companysector="ngModel" name="companysector" id="companysector"
												 class="form-control" required>
													<mat-option *ngFor="let sec of sectors" [value]="sec">{{sec.name}}</mat-option>
												</mat-select>
												<mat-error *ngIf="companysector.hasError('required')">You must make a selection</mat-error>
											</mat-form-field>
										</div>
									</div>
								</form>
								<form name="pg3" id="pg3" #pg3="ngForm" novalidate [hidden]="!checkActiveTab(3)" autocomplete="off">
									<div class="row">
										<div class="col-md-6">
											<div class="mat-form-field">
												<label class="mytitle">Profile Picture(Optional)</label>
												<button mat-button mat-raised-button color="primary" (click)="profilepic.click()">
													Upload
													<input #profilepic type="file" (change)="fileChangeEvent($event)" mode="data-url" name="pic-upload" id="pic-upload" style="display:none;"
													 class="form-control" accept="image/x-png,image/gif,image/jpeg" />
												</button>
												<p class="help-block small animated fadeInDown mat-error ng-star-inserted" *ngIf="imageError">Please upload valid image, (i.e png, jpeg, jpg)</p>
											</div>
										</div>
										<div class="col-md-6">
											<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [resizeToWidth]="128"
											 format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<div class="form-group re-animate" [hidden]="!user.photoUpload">
												<label for="#photo">Preview</label>
												<img class="round" id="photo" [src]="user.photoUpload">
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12">
											<mat-checkbox matInput class="form-control check-b example-margin" [(ngModel)]="agree" name="terms" id="terms" required>
												<span style="color:#3f51b5; font-weight: 400;">
													<br />I am voluntarily providing information from my employer record and agree that TalentJumpstart LLC may share
													this information with selected businesses for internship recruitment purposes.</span>
											</mat-checkbox>
										</div>
										<div class="clearfix"></div>
									</div>
								</form>
							</div>
						</mat-dialog-content>
						<!-- <mat-dialog-footer> -->
						<div class="row">
							<div class="col-md-12">
								<div class="mat-form-field modal-footer">
									<button mat-button mat-raised-button color="primary" class="md-raised print_btn" data-dismiss="modal" *ngIf="checkActiveTab(3) && !progress"
									 (click)="submit()" [disabled]="!pg0.valid || !pg1.valid || !pg2.valid || !pg3.valid || isFormSubmitted">Submit
										<mat-progress-spinner style="position: absolute !important;
							top: 0;bottom: 0;margin: auto;right: 0;left: 0;" *ngIf="progress" class="tjs-spinner emp_setup" [color]="color" [mode]="mode"
										 [value]="value" [diameter]="20">
										</mat-progress-spinner>
									</button>
								</div>
							</div>
							<div class="clearfix"></div>
						</div>
						<!-- </mat-dialog-footer> -->
					</form>
				</div>
			</div>
			<div class="clearfix"></div>
		</section>
	</div>
	<div class="clearfix"></div>
</div>