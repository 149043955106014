import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateToShortlistComponent } from '../candidate-to-shortlist/candidate-to-shortlist.component';
import { CreatePostingDialogComponent } from '../create-posting-dialog/create-posting-dialog.component';
import { CreateShortlistDialogComponent } from '../create-shortlist-dialog/create-shortlist-dialog.component';

@Component({
  selector: 'app-posting-shortlist-notexists',
  templateUrl: './posting-shortlist-notexists.component.html',
  styleUrls: ['./posting-shortlist-notexists.component.css']
})
export class PostingShortlistNotexistsComponent implements OnInit {
  public IsShortListNotExists = false;
  public IsPostingNotExists = false;


  constructor(
    public _dialog: MatDialog,
    private dialogRef: MatDialogRef<CandidateToShortlistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.backdropClick().subscribe(result => {
      dialogRef.disableClose = true; //disable default close operation
      dialogRef.close();
    });
    this.IsShortListNotExists = data.IsShortListNotExists;
    this.IsPostingNotExists = data.IsPostingNotExists;
  }

  ngOnInit(): void {

  }
  close(): void {
    this.dialogRef.close();
  }

  createPosting() {
    let dialog = this._dialog.open(CreatePostingDialogComponent, {
      height: 'auto',
      width: '100%',
      disableClose: true
    });
    dialog.afterClosed().subscribe((result) => { });
    this.close();
  }

  createShortList() {
    let dialog = this._dialog.open(CreateShortlistDialogComponent, {
      height: 'auto',
      width: '100%',
      disableClose: true
    });
    dialog.afterClosed().subscribe((result) => { });
    this.close();
  }
}
