<mat-card fxLayout="column" fxFlex="grow" [ngClass.gt-sm]="'small-card'">
    <mat-card-title fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-sm="start start">
        <h2>Overview</h2>
        <div fxLayout="row" *ngIf="metrics.successRate>=50">
            <h2 style="color:'secondary'">Overall Success Rate:</h2>
            <mat-progress-spinner
                [diameter]="'35'"
                [mode]="'determinate'"
                [value]="metrics.successRate"
                [color]="'secondary'">
            </mat-progress-spinner>
        </div>
    </mat-card-title>
    <mat-card-content fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="start stretch" fxLayoutGap="20px">
        <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-evenly center" fxLayoutGap="20px">
            <mat-card fxFlex fxLayout="column" fxLayoutGap="10px" [ngClass]="'overview-sub-card'" [routerLink]="'/postings'" class="clickable">
                <mat-card-title><h2> My Active Postings </h2></mat-card-title>
                <mat-card-content class="mat-display-1">
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px"> 
                        <h1> {{ metrics.activePostings }} </h1>
                        <mat-icon svgIcon="sun" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex fxLayout="column" fxLayoutGap="10px" [ngClass]="'overview-sub-card'" [routerLink]="'/reports'" class="clickable">
                <mat-card-title><h2> Total Applicants </h2></mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px">
                        <h1> {{ metrics.totalApplicants }} </h1>
                        <mat-icon svgIcon="page" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>  
            </mat-card>
        </div>
        <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-evenly center" [routerLink]="'/shortlist'" class="clickable" fxLayoutGap="20px">
            <mat-card fxFlex fxLayout="column" fxLayoutGap="10px" [ngClass]="'overview-sub-card'">
                <mat-card-title><h2> Shortlisted </h2></mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px">
                        <h1> {{ metrics.shortlisted }} </h1>
                        <mat-icon svgIcon="banner" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex fxLayout="column" fxLayoutGap="10px" [ngClass]="'overview-sub-card'" [routerLink]="'/reports/Advanced'" class="clickable">
                <mat-card-title><h2> Candidates Advanced </h2></mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between none" class="marTop16px">
                        <h1> {{ metrics.inPipeline }} </h1>
                        <mat-icon svgIcon="pipe" class="svg-icon" color="accent"></mat-icon>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>
</mat-card>
