
import {throwError as observableThrowError,  Observable, EMPTY } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from '../Services/user-info.service';
import { SharedUtilitiesService } from '../Services/shared-utilities.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class TalentApiService {

	public authString2;
	public headers2 = new HttpHeaders();
	public userLoggedOut: boolean = false;
	public isProfPicChanged = new EventEmitter();
	public snackbarNotifiation = new EventEmitter();
	public isResumeUpload: boolean = false;

	//timesheetData = new EventEmitter<any>();

	constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService, private sharedUtils: SharedUtilitiesService) {
		this.headers2.append('Content-Type', 'application/x-www-form-urlencoded');
	}

	snackbarMessage(message: string) {
		this.snackbarNotifiation.next(message);
	}

	getClasses() {
		//var data = "username=" + loginForm.Email + "&password=" + loginForm.Password;
		let userSchool = this._userInfoService.getUser().school;
		let data = { 'school': userSchool };

		let headers = new HttpHeaders();
		let user: any;

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getClassList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['classList'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getStudentInternshipClassStatus(studentId, class_id, opportunityId) {
		let userSchool = this._userInfoService.getUser().school;
		let data = { "class_id": class_id, "student_id": studentId, "opportunity_id": opportunityId };

		let headers = new HttpHeaders();
		let user: any;

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "getStudentInternshipClassStatus.php", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response;
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	
	getSchoolList() {
		let userSchool = this._userInfoService.getUser().school;
		let data = {  };

		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getSchoolList", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['schoolList'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	updateProfile(candidate) {
		let userSchool = this._userInfoService.getUser().school;
		let data = { candidate: candidate };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/editCandidateProfile", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						this.isProfPicChanged.emit(true);
						return response['editCandidateProfile'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	uploadFile(table, col, file) {
		let userSchool = this._userInfoService.getUser().school;
		let data = { "table": table, "col": col, "file": file };

		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/uploadFile", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						if(col == 'profile-pic'){
							this.isProfPicChanged.emit(true);
						}
						return response['uploadFile'];
					}
					console.log('failed to upload file')
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	logoutEvent(logout) {
		if (logout) {
			this.userLoggedOut = true;
		}
	}

	getCompaniesInfo() {
		let data = {  };

		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getCompaniesInfo", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['companiesInfo'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getSectors() {
		let data = {  };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getSectorList", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if (typeof (response) !== 'undefined') {
						return response['sectorList'];
					}
					return response;
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status}
			)),);
	}

	getSkills() {
		let data = {  };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getSkillList", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['skillList'];
					}
					return response;
				}
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}


	getLocation(term) {
		let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + term + '&key=AIzaSyB25j2yV-_f27Q9yI_OFcVj6M26Jjbuzfo&sensor:false';
		return this._http.get(url).pipe(map(res => {
			return res['results'];
		}))
	}




	getLocation1(val) {
		return this._http.get('https://maps.googleapis.com/maps/api/geocode/json', {
			params: {
				address: val,
				key: 'AIzaSyB25j2yV-_f27Q9yI_OFcVj6M26Jjbuzfo'
			}
		}).pipe(
			catchError(error => observableThrowError(error)));

		// return this._http.get('//maps.googleapis.com/maps/api/geocode/json', {
		//  		params: {
		//  			address: val,
		//  			sensor: false
		//  		}
		//  	});
		/*return this._http.get("//maps.googleapis.com/maps/api/geocode/json?address="+val+"&sensor=false")
		.map(response => {
			{
				if (typeof (response !== 'undefined')) {
					return response;
				}
			};
		})*/

	}

	newBlankPosting() {
		var blankPosting = {
			quantity: 1,
			published: true, //boolean flag
			postingDate: new Date(),
			title: '', //string
			company: '',
			startDate: {}, //date
			endDate: {}, //date
			description: '', //string
			responsibilities: [], //list of strings
			credit: [ //list of school credit objects (see below)
				{
					id: '', //remote key for school
					name: '', //string
					value: '', //-1 = no credit 0= in progress >0 = #of awarded credits
					term: {}, //date
				}
			],
			compensationLow: 0, //float
			compensationHigh: 0,
			compScheme: '',
			o_type:'',
			degree_type:'',
			estimatedHours: 0,
			hoursPer: '',
			creditRequested: false, //boolean
			location: { //internship location object
				address: '', //string
				city: '', //string
				state: '', //string
				zip: '',
				metro: '', //string
				pubTransit: false, //boolean
				remote: '0'
			},
			supervisor: { //primary and secondary person objects
				primary: {
					id: '', //remote key
					name: '', //string
					tele: '', //string
					email: '' //string
				},
				secondary: {
					id: '', //remote key
					name: '', //string
					tele: '', //string
					email: '' //string
				}
			},
			reqSkills: [], //list of skills
			targetSchools: []
		}; //contains generic posting data

		return blankPosting;
	}

	postPosting(posting) {
    let data = { posting: posting };
    let apiName = '';
    if (posting.id > 0) {
      apiName = 'opportunity/editOpportunity';
    } else {
      apiName = 'opportunity/createOpportunity';
    }
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + apiName, data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if(typeof(response) !== undefined) {
						return response['status'];
					}
					console.log("could not create opportunity");
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	apply(posting) {
		let data = { "opportunity-id": posting, "status": 2 };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/createOpportunityStudent", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if(typeof(response) !== undefined) {
						return response['status'];
					}
					console.log("could not create opportunity student");				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	updateApplicantStatus(applicant, opportunity, status) {
		let data = { "student-id": applicant.id, "opportunity-id": opportunity, status: status };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/editOpportunityStudent", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if(typeof(response) !== undefined) {
						return response['status'];
					}
					console.log("could not edit opportunity student");				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getStudents() {
		let data = {  };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateList", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					return response['candidateList'];
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getClearbitCompanyinfo(companyName) {
		return this._http.get(
			'https://autocomplete.clearbit.com/v1/companies/suggest?query=:' + 
			companyName, {responseType: 'text'});
	}
		
	errorHandler(error: HttpErrorResponse) {
		let url=error.url
		let company_name=url.split("=:");
		return this.sharedUtils.getOrganizationLogo(company_name[1], 'company');
	}

	getApplicants(posting, pageNumber, pageSize) {	
		
		let data = { "opportunity-id": posting, "pagenumber" : pageNumber, "pagesize": pageSize };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/getStudentsForOpportunity", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt get Applicants');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);

	}

	getMyClasses(archived, status) {
		let data = { "archived": archived, "status-id": status };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "getMyClasses.php", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt get own Classes');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	editCourseRegistration(id, status, opportunity,classId='') {
		let data = { "id": id, "status": status, "opportunity-id": opportunity,"classId":classId };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "editInstructorInternship.php", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt edit Course Registration');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}


	getCandidateProfile(id) {
		let data = { "id": id };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateProfile", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						this.isProfPicChanged.emit(true);
						return response['candidateProfile'];
					}
					else {
						console.log('Couldnt get Student Detail');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	// feedback js
	getFeedbackList(status) {
		
		
		let data = { 'status-id': status };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getFeedbackList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt get Feedback List');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	getMyClassList(archived) {
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { "instructor": userId, "archived": archived };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getClassList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['classList'];
					}
					else {
						console.log('Couldnt load class list');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getFeedback(student_or_feedbackid, posting) {
		
		let userId = this._userInfoService.getUser().id;
		
		//if posting id is blank, new feedback flow
		if(posting)
		{
			let data = { 'posting': posting, 'student': student_or_feedbackid };
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'application/x-www-form-urlencoded');
			return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getFeedback", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response['getFeedback'];
						}
						else {
							console.log('Couldnt load feedback');
						}
					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)),);
		}
		else
		{
			let data = { 'feedback_id': student_or_feedbackid };
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'application/x-www-form-urlencoded');
			return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getFeedbackById", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response['getFeedbackById'];
						}
						else {
							console.log('Couldnt load feedback');
						}
					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)),);
		}


	}

	searchStudents(searchText) {
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { SEARCH: searchText };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateList", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['candidateList'];
					}
					else {
						console.log('Couldnt search Students');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getFeedbackEmailTemplate(student, job, from, feedbackCoverOption:any = '') {
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { 'id': student, 'opportunity-id': job, 'from': from, 'feedback_cover_option':feedbackCoverOption };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getFeedbackEmailTemplate", data, { headers: headers })
	}

	getFeedbackEmailTemplateByFeedback(feedbackId) {
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { 'feedback_id': feedbackId };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getFeedbackEmailTemplateByFeedback", data, { headers: headers })
	}

	sendFeedback(subject, message, mailings, from) {
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { "subject": subject, "message": message, "mailings": mailings, "from": from };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/sendFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['sendFeedback'];
					}
					else {
						console.log('Couldnt send feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	sendNewFeedback(feedbackId, feedbackEmailData) {
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { "feedback_id": feedbackId, "feedback_email_data": feedbackEmailData };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/sendNewFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['sendNewFeedback'];
					}
					else {
						console.log('Couldnt send feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getMyFeedbackTemplate(){
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { id: userId};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getFeedbackTemplate", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['getFeedbackTemplate'];
					}
					else {
						console.log('Couldnt send feedback Template');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	createFeedback(form, internship, dueDate){
		
		let userId = this._userInfoService.getUser().id;
		
		let data = { "form": form, id: internship, due_date: dueDate };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/createFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['createFeedback'];
					}
					else {
						console.log('Couldnt create feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	createNewFeedback(feedbackDataObj){
		let data = feedbackDataObj;
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/createNewFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['createNewFeedback'];
					}
					else {
						console.log('Couldnt create feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	createFeedbackTemplate(form, name){
		let userId = this._userInfoService.getUser().id;
		let data = { "form": form, id: userId, name: name };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/createFeedbackTemplate", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['createFeedbackRequest'];
					}
					else {
						console.log('Couldnt create Feedback Template');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	private handleError(error: any) { 
		//let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
		let errMsg = (error.error) ? error.error : 'Internal server error';
		return observableThrowError(errMsg);
	}

	newBlankEmployerUser(){
		var empUser = {
			TJS_USERNAME: '',
			JWTTOKEN: '',
			firstName: '',
			lastName: '',
			phone: '',
			// email: vm.userInfo.email,
			email: '',
			// pass: vm.userInfo.pass,
			pass:'',
			// verifiedpass: vm.userInfo.pass,
			verifiedpass: '',
			division: '',
			photoUpload: '',
			skype:'',
			linkedin:'',
			company:'',
			sector:'',
			location:{
				// address: vm.address1+' '+ vm.address2,
				address: '',
				city: '',
				state: '',
				zip: '',
				metro: 'USA',
				pubTransit: false,
				remote: false
			},
			compSocialMedia:{
				linkedIn:'',
				facebook:'',
				twitter:''
            }
		};
		return empUser;
	}

	createEmployer(user){
		let data = user;
		data.sha_pass = user.pass;
		data.sha_verifiedpass = user.pass;
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/createEmployer", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['status'];
					}
					else {
						console.log('Couldnt create Employer');
					}
				};
			}),
			// .catch(error => Observable.throw(
			// 	{ status: error.status }
			// )
			catchError(this.handleError),);
	}
	editFeedback(form, id, status){
		let userId = this._userInfoService.getUser().id;
		let data = { "form":form, feedback: id, status: status };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/editFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['editFeedback'];
					}
					else {
						console.log('Couldnt edit feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	isOpportunityValid(id){
		let data = { "opportunity-id": id};
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "opportunity/checkOpportunity", data, { headers: this.headers2 }).pipe(
			map(response => {
				{
					if(typeof(response) !== undefined) {
						return response['status'];
					}
					console.log("failed to check opportunity");				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	createClass(clss){
		let usr = this._userInfoService.getUser();
		let data = { cls:clss, id:usr.id };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "organization/createClass", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['createdClass'];
					}
					else {
						console.log('Couldnt create class');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getClassRegistrationReport(classid) {
		let data = { class_id : classid };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "getClassRegistrationReport.php", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt get class registration report');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	requestReset(user) {
		let data = { TJS_USERNAME: user.Email };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "auth/resetPassword", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response;
					}
					else {
						console.log('Couldnt request the reset password');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	approveAllFeedback(activeApps,studentId,selectedTemplate,templatesDueDate,formName,saveAsTemplate) {
		let userId = this._userInfoService.getUser().id;
		let data = { "activeApps":activeApps, "studentId":studentId, "selectedTemplate":selectedTemplate, "templatesDueDate":templatesDueDate, "formName":formName,"userId":userId ,"saveAsTemplate":saveAsTemplate};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/approveAllFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['approveAllFeedback'];
					}
					else {
						console.log('Couldnt approve All Feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	
	getEmployeeCompanySector() {
		let userId = this._userInfoService.getUser().id;
		let data = { "userId":userId };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "employer/getEmployeeCompanySector", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['getEmployeeCompanySector'];
					}
					else {
						console.log('Couldnt get Employee Company Sector');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getListOfFeedbackCreateOptions(fbCoverOption){
		let data = { "feedbackCoverOption": fbCoverOption};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getListOfFeedbackCreateOptions", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['getListOfFeedbackCreateOptions'];
					}
					else {
						console.log('Couldnt get list of Feedback create options');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getStudentListForFeedback(opportunityId,classId) {
		let data = { "opportunityId": opportunityId, "classId" : classId};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getStudentListForFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['getStudentListForFeedback'];
					}
					else {
						console.log('Couldnt get list of students list for feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getOpportunityListForFeedback(classId, studentId) {
		let data = { "classId": classId, "studentId" : studentId};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getOpportunityListForFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['getOpportunityListForFeedback'];
					}
					else {
						console.log('Couldnt get list of opportunities for feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getClassInternship(classId, studentId, opportunityId) {
		let data = { "classId": classId, "studentId" : studentId, 'opportunityId' : opportunityId};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getClassInternshipForFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['getClassInternshipForFeedback'];
					}
					else {
						console.log('Couldnt get class internship for Feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getOpportunityStudent(opportunityId, studentId) {
		let data = { "opportunityId": opportunityId, "studentId" : studentId};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "feedback/getOpportunityStudentForFeedback", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['getOpportunityStudentForFeedback'];
					}
					else {
						console.log('Couldnt get Opportunity Student for Feedback');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	getLogo(tenant, type, size) {
		let data = {
			'tenant': tenant, 
			"type" : type, 
			"size": size
		};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/getTenantLogo", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response["tenantLogo"];
					}
					else {
						console.log('Couldnt get tenant logo image');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}
	
	getCandidateAvailabilityStatus() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateAvailabilityStatus", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['candidateAvailabilityStatusList'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
	getCandidateSector() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateSector", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['candidateSectorList'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
	getCandidateWorkScheduleType() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateWorkScheduleType", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['candidateWorkScheduleList'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
	getCandidateWorkEnvironmentType() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateWorkEnvironmentType", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['candidateWorkEnvironmentTypeList'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
	getCandidateLocation() {
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getCandidateLocation", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['candidateLocationList'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}

	changeEmail(params) {
		let data = { currentEmail: params.currentEmail, newEmail: params.newEmail };
		let headers = new HttpHeaders();
    	headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/changeEmail", data, { headers: headers }).pipe(
			map(response => {
				if (typeof (response !== 'undefined')) {
					return response;
				}
				else {
					this.snackbarMessage("Something went wrong while changing email!")
				}}),
				catchError(error => observableThrowError (
					{ status: error.status }
				))
			);	
	}

	verifyEmail(token) {
		let data = { 'token': token };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/verifyEmail", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['result'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}

	sendVerificationEmail(email) {
		let data = { 'email': email };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "core/sendVerificationEmail", data, { headers: headers }).pipe(
			map(response => {
				{
					if(response != 'undefined') {
						return response['result'];
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status, Errors: error }
			)),);
	}
  
	getGenderList() {
		let data = {};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getGenderList", data, { headers: headers }).pipe(
		map(response => {
			{
			if (response != 'undefined') {
				return response['candidateGenderList'];
			}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status, Errors: error }
		)));
	}
	getEthnicityList() {
		let data = {};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getEthnicityList", data, { headers: headers }).pipe(
		map(response => {
			{
			if (response != 'undefined') {
				return response['candidateEthnicityList'];
			}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status, Errors: error }
		)));
	}

	getMilitaryStatusList() {
		let data = {};
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "candidate/getMilitaryStatusList", data, { headers: headers }).pipe(
		map(response => {
			{
			if (response != 'undefined') {
				return response['candidateMilitaryStatusList'];
			}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status, Errors: error }
		)));
	}

	saveSearch(data) {
		let headers = new HttpHeaders();
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
			"core/saveSearch", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response;
						}
						else {
							console.log('Could not save search data .');
						}

					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)
			)
		);
	}

	getMySearches(data) {
		let headers = new HttpHeaders();
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
			"core/getMySearches", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response;
						}
						else {
							console.log('Could not save search data .');
						}

					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)
			)
		);
	}

	deleteSearch(data) {
		let headers = new HttpHeaders();
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
			"core/deleteSearch", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response;
						}
						else {
							console.log('Could not save search data .');
						}

					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)
			)
		);
	}
	UploadResume(file) {
		console.log(file);
		var fileData = {
			'fileName': file.name,
			'fileType': file.type
		}
		let user = {
			userId : this._userInfoService.getUser().id,
			userName : this._userInfoService.getUser().username
		}
		var formData: any = new FormData();
		formData.append("file", file);
		formData.append("fileData", JSON.stringify(fileData));
		formData.append("user", JSON.stringify(user));
		let headers = new HttpHeaders();
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
			"candidate/UploadResume", formData, { headers: headers }).pipe(
				map(response => {
					{
						console.log(response);
						return response;
					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)
			)
		);
	}

	getskillsFromResume() {
	let data = {
		userId : this._userInfoService.getUser().id
	}
	let headers = new HttpHeaders();
	return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
		"candidate/GetSkills", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['candidateSkills'];
					}
					else {
						console.log('Could not save search data .');
					}

				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)
		)
	);
	}

	getBasicInfoFromResume() {
		let data = {
			userId : this._userInfoService.getUser().id
		}
		let headers = new HttpHeaders();
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
			"candidate/getBasicInfo", data, { headers: headers }).pipe(
				map(response => {
					{
						if (typeof (response !== 'undefined')) {
							return response['candidateInfo'];
						}
						else {
							console.log('Could not save search data .');
						}
	
					};
				}),
				catchError(error => observableThrowError(
					{ status: error.status }
				)
			)
		);
		}

}
