<div class="header">
    <h4>My Report</h4>
    <div fxLayout="row">
      <div fxLayout.xs="column" fxFlex="100" fxLayoutGap.gt-sm="15px" fxLayoutAlign="space-between start">
        <div  fxFlex.md="66" fxFlex.lg="33">
          <mat-form-field>
            <mat-select tabindex="14" placeholder="Select an option" [(ngModel)]="selectedReport"
                        (selectionChange)="updateReport()"  required>
              <mat-option *ngFor="let report of reportList" [value]="report"> {{report.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div  fxFlex.md="66" fxFlex.lg="33" fxLayout="row-reverse" >
          <button mat-raised-button color="primary" *ngIf="reportdetail != null && reportdetail.length!=0" (click)="exportReport()">
            <mat-icon>save_alt</mat-icon> Download
          </button>
        </div>
      </div>
    </div>
  </div>
