<div fxLayout="row" fxLayoutAlign="center center" class="shortlist_p">
  <div fxFlex="86" fxFlex.xs="96">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="shortlist-list-card">
      <div fxFlex.gt-sm="35" fxFlex="100" [ngClass]="{'shortlist-details-card': cardWidth}">
        <app-shortlist-list-card fxFlex="100" [shortListLoaded]="shortlistListLoaded"  (searchedOutput)="searchedResult($event)"></app-shortlist-list-card>
      </div>
      <div fxFlex.gt-sm="64" fxFlex="100" [ngClass]="{'shortlist-details-card': cardWidth}">
        <router-outlet class="no-margin"></router-outlet>
      </div>
    </div>
  </div>
</div>
