<mat-card fxFlex="55"  fxLayout="column" fxLayoutAlign="center start">
    <mat-card-title>Admin Marketing Area</mat-card-title>
    <mat-card-content>
        
        
        <strong>Instructions:</strong>
        <ol>
            <li>Send a new Email by clicking the "<strong>Send New Email</strong>" button below.</li>
            <li>Enter in recipients, an optional body message, and opportunities when prompted to do so</li>
            <li>Opportunities that are checked will be delivered via email to the recipients.</li>
            <li>Once finished, click the "<strong>Send</strong>" button at the bottom of the prompt</li>
        </ol>
    </mat-card-content>
    <mat-card-footer>
        <div fxLayout="row">
            <div fxFlex fxLayoutAlign="center center">
                <button mat-raised-button (click)="sendNew()" color="accent">Send New Email</button>
            </div>
        </div>
    </mat-card-footer>
</mat-card>
