import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsComponent } from '../../dashboard/notifications/notifications.component';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  viewAllNotification: any = true;
  newCount: number = 0;
  

  constructor(private dialogRef: MatDialogRef<NotificationsComponent>,   
    @Optional() @Inject(MAT_DIALOG_DATA) public totalCount: any) {   
    this.newCount = totalCount;
  }

  ngOnInit(): void {
  }
 
  cancel() {
    this.dialogRef.close();
  }

}
