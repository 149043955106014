import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyInfoService } from '../../../Services/company-info.service';
import { CreateUserGroupComponent } from '../dialogs/create-user-group/create-user-group.component';
import { ViewGroupRightsComponent } from '../dialogs/view-group-rights/view-group-rights.component';

@Component({
  selector: 'app-company-user-groups',
  templateUrl: './company-user-groups.component.html',
  styleUrls: ['./company-user-groups.component.css']
})
export class CompanyUserGroupsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'numUsers', 'dateAdded', 'accessRights'];
  dataSource: MatTableDataSource<any> = null;
  @ViewChild(MatSort) sort: MatSort;
  largeScreen: boolean = false;
  public breakpoint;
  public panelClass;


  constructor(
    public _companyInfoService: CompanyInfoService,
    public _dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    public _router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
			if(params['create']) {
				this.createGroup();
			}
		});
    this.generateTable();
    this.breakpointObserver.observe(['(min-width: 959px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.largeScreen = true;
      } else {
        this.largeScreen = false;
      }
    });
    this.breakpointObserver.observe(['(max-width: 375px)','(max-width: 425px)','(max-width: 768px)','(max-width: 1024px)','(max-width: 1440px)']).subscribe(result => {
      if (result.breakpoints['(max-width: 375px)']) {
        this.breakpoint = 0;
      }  
      else if (result.breakpoints['(max-width: 425px)']) {
        this.breakpoint = 1;
      }
      else if (result.breakpoints['(max-width: 768px)']) {
        this.breakpoint = 2;
      }
      else if (result.breakpoints['(max-width: 1024px)']) {
        this.breakpoint = 3;
      }
      else if (result.breakpoints['(max-width: 1440px)']) {
        this.breakpoint = 4;
      }
      else {
        this.breakpoint = 5;
      }
    });
  }

  generateTable() {
		this._companyInfoService.getUserGroups().subscribe((result: any) => {
      let formattedResult = [];
      result.forEach(element => {
        let dateAdded = 'Default';
        let accessRights = 'View Rights'
        if(element[0].name !== 'Admin' && element[0].name !== 'Recruiter' && element[0].name !== 'View Only') {
          accessRights = 'View/Edit Rights'
          dateAdded = this.formatDate(element[0].created_ts);
        }
        formattedResult.push({
          id: element[0].id,
          name: element[0].name,
          numUsers: element[1].length,
          dateAdded: dateAdded,
          accessRights: accessRights
        })
      });
      this.dataSource = new MatTableDataSource(formattedResult);
      // below is used to sort the table so default groups come first
      // followed by most recently added
      this.dataSource.sort = this.sort;
      const sortState: Sort = {active: 'dateAdded', direction: 'desc'};
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.dataSource.sortData = this.sortData;
      this.sort.sortChange.emit(sortState);
		});
	}

  formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		return [month, day, year.toString().substring(2)].join('/');
	}

  viewEditRights(selectedGroup) {
    switch (this.breakpoint) {
      case 0:
        this.panelClass = 'viewEditCreateUserGroup_dialogXs';
        break;
        case 1:
        this.panelClass = 'viewEditCreateUserGroup_dialogSm';
        break;
        case 2:
        this.panelClass = 'viewEditCreateUserGroup_dialogGtSm';
        break;
        case 3:
        this.panelClass = 'viewEditCreateUserGroup_dialogGtSm';
        break;
        case 4:
        this.panelClass = 'viewEditCreateUserGroup_dialogGtSm';
        break;
        case 5:
        this.panelClass = 'viewEditCreateUserGroup_dialog';
        break;
      default:
        break;
    }
    const dialog = this._dialog.open(ViewGroupRightsComponent, {
      data: selectedGroup,
      maxHeight: '90vh',
      minHeight: '90vh',
      minWidth: '44vw',
      panelClass:this.panelClass
    });
    dialog.afterClosed().subscribe((result:any) => {
      this.generateTable();
    });
  }

  createGroup() {
    switch (this.breakpoint) {
      case 0:
        this.panelClass = 'CreateUserGroup_dialogXs';
        break;
        case 1:
        this.panelClass = 'CreateUserGroup_dialogSm';
        break;
        case 2:
        this.panelClass = 'CreateUserGroup_dialogGtSm';
        break;
        case 3:
        this.panelClass = 'CreateUserGroup_dialogGtSm';
        break;
        case 4:
        this.panelClass = 'CreateUserGroup_dialogGtSm';
        break;
        case 5:
        this.panelClass = 'CreateUserGroup_dialog';
        break;
      default:
        break;
    }
    const dialog = this._dialog.open(CreateUserGroupComponent, {
      maxHeight: '90vh',
      minHeight: '90vh',
      minWidth: '44vw',
      panelClass:this.panelClass
    });
    dialog.afterClosed().subscribe((result:any) => {
      this.generateTable();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // requires a sorter to be able to sort our parsed dates as well as traditional strings
  sortData = function(data, sort) {
    const active = sort.active;
    const direction = sort.direction;
    let regexp = /^\d{1,2}\/\d{1,2}\/\d{2}$/;
    return data.sort((a, b) => {
      let aValue = this.sortingDataAccessor(a, active);
      let bValue = this.sortingDataAccessor(b, active);
      let comparatorResult = 0;
      if(regexp.test(aValue) && regexp.test(bValue)) { // MM/DD/YY comparator
        let aDate = aValue.split('/');
        let bDate = bValue.split('/');
        if(aDate[2] === bDate[2] && aDate[0] === bDate[0]) {
          comparatorResult = parseInt(aDate[1]) > parseInt(bDate[1]) ? 1 : -1;
        }
        else if(aDate[2] === bDate[2]) {
          comparatorResult = parseInt(aDate[0]) >  parseInt(bDate[0]) ? 1 : -1;
        }
        else {
          comparatorResult = parseInt(aDate[2]) >  parseInt(bDate[2]) ? 1 : -1;
        }
      }
      else { // classic string comparator
        comparatorResult = aValue > bValue ? 1 : -1;
      }
      if(direction === '') return 0;
      return comparatorResult * (direction === 'asc' ? 1 : -1); 
    });
  }
  
  back() {
    this._router.navigate(['/companyAdmin']);
  }


}
