import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from '../../../Services/login.service';
import { UserInfoService } from '../../../Services/user-info.service';
import { DashboardService } from '../../../Services/dashboard.service';
import { PostingService } from '../../../Services/posting.service';
import { TalentApiService } from '../../../Services/talent-api.service';
import { Notification } from '../../../Models/Notification';
import * as _ from 'underscore';
import { SharedUtilitiesService } from '../../../Services/shared-utilities.service';
import { TenantService } from '../../../Services/tenant.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { NotificationListComponent } from '../../dialogs/notification-list/notification-list.component';
import { from } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  public ErrorMessage: string = "";
  public notificationsList: Notification[] = [];
  public totUnread: number = 0;

  myEventSubscription: any;
  tenant: String;
  isMobileDevice: boolean = false;
  @Input() showAllNotification: any = false;
  // MatPaginator Output
  pageNum: number = 1;
  pageSize: number = 25;
  totalCount: number = 0;

  constructor(private _loginService: LoginService,
    private _userInfoService: UserInfoService,
    private _dashboardService: DashboardService,
    private _postingService: PostingService,
    private _talentApiService: TalentApiService,
    private sharedUtils: SharedUtilitiesService,
    public breakpointObserver: BreakpointObserver,
    private _tenantService: TenantService,
    public _dialog: MatDialog, private _router: Router,) {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, Breakpoints.Tablet
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileDevice = true;
        }
      });
  }

  ngOnInit(): void {
    this.tenant = this._tenantService.tenant;
    this.getNotificationsList();
  }

  getNotificationsList() {
    this.notificationsList = [];
    this.totUnread = 0;
    this._dashboardService.getNotifications(this.showAllNotification, this.pageNum, this.pageSize, this.totalCount).subscribe((result: any) => {
      if (result.length > 0) {
        var that = this;
        result.forEach(function (item) {
          let n: Notification = {
            id: item.id,
            company: item.company_name,
            imageURL: item.imageURL,
            title: item.title,
            detail: item.detail,
            link: item.link,
            read: item.status,
            dueDate: item.due_date,
            type: item.notificationType,
            fk_posting: item.fk_posting,
            type_name: item.notification_type_name,
            created_ts: item.created_ts
          };

          if (item.status == "unread") {
            that.totUnread += 1;
          }

          that.notificationsList.push(n);
        });

        if (this.showAllNotification) {
          if (this.totalCount === 0) {
            this.totalCount = result[0]["RecordCount"];
          }
        }
      }
    },
      error => {
        this.ErrorMessage = "Unable to load Notifications";
        console.log(this.ErrorMessage);
      }
    );
  }

  // when notification is opened
  updateStatus(n) {
    this._dashboardService.updateNotificationStatus(n.id, 2).subscribe(
      (result: any) => {
        if (this.showAllNotification) {
          this._dialog.closeAll();
        }
        this.getNotificationsList();
      },
      error => {
        this.ErrorMessage = "Unable to update notification";
        console.log(this.ErrorMessage);
      }
    );
  }

  getFormattedDate(dt) {
    return moment(dt).format("Do MMMM h:mm a");
  }

  managePagination(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNum = event.pageIndex + 1;
    this.getNotificationsList();
  }

  viewAllNotifications() {
    let dialog = this._dialog.open(NotificationListComponent, {
      height: this.isMobileDevice ? '96%' : '78%',
      width: this.isMobileDevice ? '100%' : '48%',
      disableClose: true,
      data: this.totUnread,
      panelClass: 'posting_popup'
    });
    dialog.afterClosed().subscribe((result) => {
    });
  }

}
