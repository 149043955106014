export class SkillNode {
  id: number;
  name: string;
  description: string;
  parent: number;
  reviewed: string;
  isChecked?: boolean;
  children: SkillNode[];
  level?: string;
  exp?: any;
  constructor() { }

}
