import { Component, Input, OnInit } from '@angular/core';

import { CandidateWorkEnvironment } from '../../../Models/CandidateProfile'
import { TalentApiService } from '../../../Services/talent-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../Pages/profile-edit/profile-edit.component';




@Component({
  selector: 'app-work-setup-view',
  templateUrl: './work-setup-view.component.html',
  styleUrls: ['./work-setup-view.component.css']
})
export class WorkSetupViewComponent implements OnInit {
  @Input() ownProfile: boolean;
  @Input() workEnvironment:CandidateWorkEnvironment;


  public markets: String;
  public nextJob: String;

  constructor(public _dialog: MatDialog) { }

  ngOnInit(): void {
    // this.squashArrays();
  }

  // squashArrays(){
  //   this.markets = this.workEnvironment.interestedMarkets.join().replaceAll(",", ", ");
  //   this.nextJob = this.workEnvironment.importantInNextJob.join().replaceAll(".,", ", ");
  // }

  openEdit() {
    let dialog = this._dialog.open(ProfileEditComponent,{
      height: 'auto',
      width: '80%',
      disableClose: true,
      data: {
              subComponent: 'candidate-work-environment',
              seedData: this.workEnvironment
            }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        if (result == null) {
        } else {
          console.log(result);
          this.workEnvironment = result;
          // this.squashArrays();
        }
      });
  }

}
