import { Component, OnInit, Input } from '@angular/core';
import { SharedUtilitiesService } from '../../Services/shared-utilities.service';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.css']
})
export class CompanyLogoComponent {
  @Input() name: string;
  @Input() type: string;
  @Input() imageURL: string;
  logoImage: string;
  constructor(private sharedUtils: SharedUtilitiesService) { }

  ngOnInit(): void {
    if(this.imageURL) {
      this.logoImage = this.imageURL;
    }
    else {
      this.sharedUtils.getOrganizationLogo(this.name, this.type).subscribe((result: any) => {
        if (result) {
          this.logoImage = result;
        } else {
          this.logoImage = '../../../assets/img/nologo.png';
        }
      });
    }
  }

  
}
