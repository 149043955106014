<div fxLayout="column" ng-cloak>
    <div> <!--Instructions-->
        <h4>System Users</h4>
        <p><em>Users are added through the front end registration screens.</em></p>
        <div class="instructions_cl">
            <strong>Instructions:</strong>
            <ol>
                <li>Users may be edited by clicking on "View" and then the "Edit" button on the resulting pop up.</li>
                <li>From the edit user form you may "Disable" the user. This will prevent the user from logging in.</li>
                <li>A disabled user may then be "Enabled" to restore access.</li>
                <li>Users belong to a user-group, which provides access to pages and data pertaining to that type of user.</li>
            </ol>
        </div>
    </div>
    <div> <!--Search box-->
        <mat-form-field color="accent" appearance="outline">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </mat-form-field>
    </div>
    <div> <!--Table-->
        <table mat-table [dataSource]="dataSource" fxFlex="100%" matSort>
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.id }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fk_tenant">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Tenant </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.fk_tenant }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fk_userGroup">
                <mat-header-cell *matHeaderCellDef mat-sort-header> User Group </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.fk_userGroup }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="enabled">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-icon *ngIf="row.enabled == 0" class="red-icon">block</mat-icon>
                    <mat-icon *ngIf="row.enabled == 1" class="green-icon">check_circle</mat-icon> 
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.firstName }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.lastName }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.email }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.phone }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_updated_by">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.last_updated_by }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_updated_ts">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.last_updated_ts | date }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="view">
                <mat-header-cell *matHeaderCellDef mat-sort-header> View </mat-header-cell>
                <mat-cell *matCellDef="let row"> 
                    <button mat-raised-button (click)="edit(row)">View</button>
                </mat-cell>
            </ng-container>
            <!-- Header Column Labels -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <!-- Row shown when there is no matching data. -->
            <mat-row *matNoDataRow>
                <mat-cell colspan="9">No data matching the filter "{{input.value}}"</mat-cell>
            </mat-row>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,50,100]"></mat-paginator>
    </div> 
</div>
  