<div fxLayout="row wrap" fxFlex="100" fxFlex.xs="100" [ngClass]="{'':!isMobileDevice, 'small-device-padding':isMobileDevice}">
    <div fxFlex="100" fxFlex.xs="100" fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
        <h1 mat-dialog-title fxFlex="50" [ngClass.xs]="'title-margin-clear'" fxFlex.xs="70">
          {{isEdit?'Edit Filter Criteria':'Save Filter Criteria'}}
        </h1>
        <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="end center">
            <div fxFlex.xs="25">
                <button *ngIf="isEdit" (click)="delete()" class="content-margin" mat-icon-button matTooltip="Delete Filters"> <mat-icon>delete</mat-icon></button>
            </div>
            <div fxFlex.xs="30">
                <button mat-icon-button fxLayoutAlign="end center" tabindex="-1" (click)="close()"><mat-icon>close</mat-icon></button>
            </div>   
        </div>
    </div>
  <hr class="margin-24" fxFlex="100">
  <mat-dialog-content fxFlex="100" fxFlex.xs="100" cdkScrollable fxLayoutAlign.xs="center start">
    <div fxLayout="row wrap" fxFlex.xs="100" fxLayoutAlign.xs="center center" fxLayoutAlign="start start" class="">
        <h4 class="remove-margin"
          fxFlex="100">
        Save Search As
        </h4>
        <div fxLayout="row" fxFlex="100">
            <mat-form-field color="accent"
                        appearance="outline"
                        [ngClass]="{'form-item':!isMobileDevice, 'form-item-xs':isMobileDevice}">
            <mat-label>Search Criteria Name</mat-label>
            <input matInput [formControl]="name">
            <mat-error *ngIf="name.hasError('required')">
                Please enter Search Criteria Name
            </mat-error>
            </mat-form-field>
        </div>
        <h4 class="remove-margin"
          fxFlex="100">
        Search Parameters
        </h4>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search Candidate Text">
                <mat-chip *ngIf="candidateSearchParams.text" (removed)="removeCandidateSearchText()">
                    {{candidateSearchParams.text}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search Text">
                <mat-chip *ngIf="searchText" (removed)="removePostingSearchText()">
                    {{searchText}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search Location">
                <mat-chip *ngFor="let loc of candidateSearchParams.location;index as i"
                [selectable]="selectable"
                removable="true"
                (removed)="removeCandidateLocation(i)">
                    {{loc}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search Parameters">
                <mat-chip *ngFor="let loc of location;index as i"
                      [selectable]="selectable"
                      removable="true"
                      (removed)="removeLocation(i)">
                    {{loc}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search Salary">
                <mat-chip *ngIf="salary != null && salary != 0" (removed)="removeSalary()">
                    {{salary}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search education">
                <mat-chip *ngFor="let edu of educationLevelData"
                      [selectable]="selectable"
                      removable="true"
                      (removed)="removeEducation(edu)">
                    {{edu}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search commitment">
                <mat-chip *ngFor="let commit of commitmentData"
                      [selectable]="selectable"
                      removable="true"
                      (removed)="removeCommitment(commit)">
                    {{commit}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search title">
                <mat-chip *ngFor="let jobTitle of title;index as i"
                      [selectable]="selectable"
                      removable="true"
                      (removed)="removeTitle(i)">
                    {{jobTitle}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search title">
                <mat-chip *ngFor="let skill of candidateSearchParams.skills;index as i"
                      [selectable]="selectable"
                      removable="true"
                      (removed)="removeSkill(i)">
                    {{skill}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search workSetup">
                <mat-chip *ngFor="let wSetup of workSetupData"
                      [selectable]="selectable"
                      removable="true"
                      (removed)="removeWorkSetup(wSetup)">
                    {{wSetup}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div fxLayout="row" fxFlex="100">
            <mat-chip-list aria-label="Search matchBucket">
                <mat-chip *ngFor="let mBucket of matchBucketData"
                      [selectable]="selectable"
                      removable="true"
                      (removed)="removeMatchBucket(mBucket)">
                    {{mBucket}}
                    <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
      <div fxLayoutGap="15px" fxLayout="row" fxFlex="100" fxLayoutAlign="start" class="margin-top-3">
        <button type="button" (click)="save()" mat-raised-button color="primary">SAVE</button>
      </div>
    </div>
  </mat-dialog-content>
</div>