// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  BASE_API_URL:'https://api2-dot-tjs-qa.appspot.com/',// 'https://api2-dot-tjs-qa.appspot.com/',
  // |||								   |||
  // vvv uncomment for local development vvv
  // BASE_API_URL: 'http://localhost:80/'

  // For use with Docker Container Development Environment
  //BASE_API_URL: 'http://localhost:8080/',
  SNACKBAR_TIMEOUT: 2000,
  CV_UPLOAD_MAXSIZE_KB: 2048,
  POSTING_CSV_MAXSIZE_KB: 1024
};
