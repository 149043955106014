<div *ngIf="!isAddtionalCourse">
    <div fxLayout="row">
        <div fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
            <mat-icon (click)="prev()" *ngIf="!edit">keyboard_backspace</mat-icon>
        </div>
        <div fxFlex.gt-md="95" fxFlex.md="95" fxFlex.lt-md="90" fxLayoutAlign="space-between start">
            <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Do you have any additional courses or certifications you would like to add?</h1> 
            <button mat-button color="primary" (click)="skip()" *ngIf="!edit">SKIP</button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex="92" fxFlexOffset="5">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <form [formGroup]="addCourses" fxLayout="column" fxFlex="92" fxLayoutGap="20px" fxLayoutAlign="start start">
                    <mat-radio-group formControlName="coursesToAdd" fxLayout="column" fxLayoutGap="20px">
                        <mat-radio-button [value]="true" [checked]="t.controls.length > 0">
                            Yes
                        </mat-radio-button>
                        <mat-radio-button [value]="false" [checked]="t.controls.length == 0">
                            No
                        </mat-radio-button> 
                    </mat-radio-group>
                    <button type="button" mat-raised-button color="primary" (click)="courseChange()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isAddtionalCourse">
    <div fxLayout="row">
        <div fxLayout="column" fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="10" style="cursor:pointer">
            <mat-icon (click)="backToIsAdditional()" *ngIf="!edit">keyboard_backspace</mat-icon>
        </div>
        <div fxLayout="column" fxFlex.gt-md="90" fxFlex.md="90" fxFlex.lt-md="70">
            <h1 class="title-bold" [ngClass]="edit && breakpoint<=1 ? 'font-size-1':''">Tell us more about your Courses and Certifications</h1> 
        </div>
        <div fxLayout="column" fxFlex.gt-md="5" fxFlex.md="5" fxFlex.lt-md="20">
            <button mat-stroked-button color="primary" (click)="addCertification()">ADD</button>
        </div>
    </div>
    <form name="candidateCertificationForm" id="candidateCertificationForm" [formGroup]="form" autocomplete="off">
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="92" fxFlexAlign="start start" fxFlexOffset="4">
                <div fxFlex *ngFor="let cs of t.controls; let i = index; let last = last; let first = first" [formGroup]="cs">
                    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxFlex="95">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="95">
                            <mat-form-field color="accent" appearance="outline" fxFlex="100%">
                                <mat-label>Course/Certification Name</mat-label>
                                <input formControlName="name" matInput>
                                <mat-error *ngIf="cs.controls.name.errors?.required" class="help-block small animated fadeInDown">
                                    This field is required!
                                </mat-error>
                                <mat-error *ngIf="cs.controls.name.errors?.maxlength" class="help-block small animated fadeInDown">
                                    This certification is too long! (100 characters maximum)
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent" appearance="outline" fxFlex="100%">
                                <mat-label>Certified By</mat-label>
                                <input formControlName="authority" matInput>
                                <mat-error *ngIf="cs.controls.authority.errors?.required" class="help-block small animated fadeInDown">
                                    This field is required!
                                </mat-error>
                                <mat-error *ngIf="cs.controls.authority.errors?.maxlength" class="help-block small animated fadeInDown">
                                    This authority is too long! (100 characters maximum)
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent" appearance="outline" fxFlex="100%">
                                <mat-label>Completion Date</mat-label>
                                <input name="dateAwarded" matInput [max]="maxDate"                                       
                                       [matDatepicker]="dateAwarded"
                                       type="text"
                                       formControlName="dateAwarded">
                                <mat-datepicker-toggle matSuffix [for]="dateAwarded"></mat-datepicker-toggle>
                                <mat-datepicker #dateAwarded startView="multi-year"  (monthSelected)="chosenMonthHandler($event,dateAwarded,i)"></mat-datepicker>
                                <mat-error *ngIf="cs.controls.dateAwarded.errors?.required && isNextClicked; else dateAwardedsecond;" class="help-block small animated fadeInDown">
                                    This field is required!
                                </mat-error>
                                <ng-template #dateAwardedsecond>
                                    <mat-error *ngIf="cs.controls.dateAwarded.errors?.invalid && isNextClicked" class="help-block small animated fadeInDown">
                                        The entered value is not a date!
                                    </mat-error>
                                </ng-template>
                            </mat-form-field>
                        </div>
                        <div fxFlex="3" fxFlex.lt-md="100" fxLayoutAlign="end">
                          <!--*ngIf="!(first && last)"-->
                          <button type="button" matSuffix mat-icon-button color="accent" aria-label="Clear" (click)="removeCertification(i)"><mat-icon>delete</mat-icon></button>
                        </div>
                        <div fxFlex="100" *ngIf="!last">
                            <hr class="divider">
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxFlexOffset="20px">
                    <div fxLayout="column" fxLayoutAlign="start">
                        <button type="button" mat-raised-button color="primary" (click)="next()">{{ !edit ? 'NEXT' : 'SAVE'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
