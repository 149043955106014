import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import { AdminApiService } from '../../Services/admin-api.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-tenant-permissions',
  templateUrl: './admin-tenant-permissions.component.html',
  styleUrls: ['./admin-tenant-permissions.component.css']
})
export class AdminTenantPermissionsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['id', 'name', 'route', 'activated', 'is_nav_route', 'is_actions_route', 'Action'];

  dataSource: MatTableDataSource<any> = null;
  tenants:any = null;
  userGroups:any = null;
  userGroupSelected = null;
  tenantSelected = null;
  editRow = null;


  constructor(private _adminApiService: AdminApiService,
    public _dialog: MatDialog) { }

  // gets list of tenants and user types for dropdowns
  ngOnInit(): void {
    this.getTenants();
    this.getUserTypes();
  }

  // Sets the routes edit flag, 
  // which displays the editing commands
  editRoute(route:any) {
    route.editing = true;
  }

  // This updates the current route table displayed
  // and passes the edit to the backend for processing,
  // the display will be updated again when editPermission
  // returns.
  save(route:any) {
    route.editing = false;
    route.activated = route.editData.activated;
    route.is_nav_route = route.editData.is_nav_route;
    route.is_actions_route = route.editData.is_actions_route;
    this._adminApiService.editPermission(route.id, this.tenantSelected, 
          this.userGroupSelected, route.editData.activated, route.editData.is_nav_route, route.editData.is_actions_route).subscribe();
  }

  // cancels the edits made to the route
  // reverts the edit data back
  cancel(route:any) {
    route.editing = false;
    route.editData = {activated: route.activated, is_nav_route: route.is_nav_route, is_actions_route: route.is_actions_route};
  }

  // nav route and actions route
  // can only be selected if the
  // route is activated
  activatedEdited(route:any, event) {
    if(event.checked) {
      route.editData.activated = 1;
    }
    else {
      route.editData.activated = 0;
      route.editData.is_nav_route = 0;
      route.editData.is_actions_route = 0;
    }
  }

  // only nav or actions can be selected,
  // not both
  isNavRouteEdited(route:any, event) {
    if(event.checked) {
      route.editData.is_nav_route = 1;
      route.editData.is_actions_route = 0;
    }
    else {
      route.editData.is_nav_route = 0;
    }
  }

  isActionsRouteEdited(route:any, event) {
    if(event.checked) {
      route.editData.is_actions_route = 1;
      route.editData.is_nav_route = 0;
    }
    else {
      route.editData.is_actions_route = 0;
    }
  }

  // for searching the table, mat-table functionality
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTenants() {
		this._adminApiService.getTenantsList()
			.subscribe(
			(result: any) => {
        this.tenants = result;
			},
			error => {
				console.log('Could not load Tenants');
			}
		);
  }
  
  getUserTypes() {
		this._adminApiService.getUserTypes()
			.subscribe(
			(result: any) => {
        this.userGroups = result;
			},
			error => {
				console.log('Could not load User Types');
			}
		);
  }

  // gets the permissions data from API, 
  // formats for the table, and 
  // generates initial 'editing' data,
  // which is a copy of the actions, nav, and activated
  generateRoutesTable() {
    if(this.tenantSelected == null || this.userGroupSelected == null) {
      return;
    }
		this._adminApiService.getTenantPermissionsTable(this.tenantSelected,this.userGroupSelected).subscribe((result: any) => {
      for(let route of result) {
        if(!route.activated) {
          route.activated = 0;
        }
        if(!route.is_nav_route) {
          route.is_nav_route = 0;
        }
        if(!route.is_actions_route) {
          route.is_actions_route = 0;
        }
        route.editData = {activated: route.activated, is_nav_route: route.is_nav_route, is_actions_route: route.is_actions_route };
        route.editing = false;
      }
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
		},
			error => {
				console.log('Could not load News Sources');
			}
		);
	}

}
