<mat-sidenav-container class="mat-typography bg-pattern">
    <mat-sidenav #sidenav position="end" class="sidenav">
        <!-- Not Logged In -->
        <div *ngIf="(userName==null || userName=='');else settingMenu">
          <mat-nav-list>
            <a mat-list-item [routerLink]="'/login'" (click)="sidenav.toggle()"> <mat-icon>lock</mat-icon><h2>Login/Register</h2></a>
            <a mat-list-item [routerLink]="'/employer_register'" (click)="sidenav.toggle()"><mat-icon>supervisor_account</mat-icon><h2>For Employers</h2></a>
          </mat-nav-list>
        </div>

        <!-- User Dropdown For All Users  -->
        <ng-template #settingMenu>
          <div >
            <mat-expansion-panel class="mat-elevation-z0">
               <mat-expansion-panel-header collapsedHeight="64px">
                    <mat-panel-title>
                        <mat-list>
                            <mat-list-item>
                                <img matListAvatar class="profile-image" src="{{ profilePic === '' ? '../../../assets/img/User-blue-icon.png' : profilePic}}"  onError="this.src='../../../assets/img/User-blue-icon.png';">
                                <div *ngIf="userName"><h2 class="SideNavUserName">{{ userName }}</h2></div>
                                <div *ngIf="!userName"><h2>Account</h2></div>
                            </mat-list-item>
                        </mat-list>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list (click)="sidenav.toggle()">
                        <a mat-list-item id="settingsLink" [routerLink]="['/settings']">Settings</a>
                        <a mat-list-item id="diversitySurveyLink" *ngIf="userType == 2" [routerLink]="['/diversitysurvey']">Diversity and Inclusion Survey</a>
                        <a mat-list-item id="companyAdminLink" *ngIf="hasCompanyAdminPermission" [routerLink]="['/companyAdmin']">Company Admin</a>
                        <a mat-list-item id="siteFeedbackLink" href="mailto:feedback@hipjobjuice.com?Subject=Feedback">Site Feedback</a>
                        <a mat-list-item id="logOutLink" class="" (click)="logout()">Log Out</a>
                </mat-nav-list>
                <mat-divider></mat-divider>
            </mat-expansion-panel>
          </div>
        </ng-template>

        <!-- Links For Each User Type -->
        <div *ngIf="_router.url != '/' && _router.url != '/login'">
            <mat-nav-list (click)="sidenav.toggle()">
                <a mat-list-item  (click)="navigateTo(link.link)" *ngFor="let link of navRoutes"><h2>{{ link.name }}</h2></a>
            </mat-nav-list>
        </div>

        <!-- Additional Dropdowns For Each User Type -->
        <div *ngIf="_router.url != '/' && _router.url != '/login'">
            <!--<div *ngIf="userType==1">
                <mat-expansion-panel #schoolActionPanel class="mat-elevation-z0" hideToggle>
                    <mat-expansion-panel-header class="nav-expansion">
                        <mat-panel-title><h2>Actions</h2></mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list (click)="schoolActionPanel.toggle(); sidenav.toggle()">
                        <a mat-list-item (click)="openCreateClassModal()"><h4>Create Class</h4></a>
                        <a mat-list-item (click)="openCreateFeedbackModal(userType)"><h4>New Feedback</h4></a>
                        <a mat-list-item *ngFor="let link of actionsRoutes"
                            [routerLink]="[ link.link ]"><h4>{{ link.name }}</h4></a>
                    </mat-nav-list>
                    <mat-divider></mat-divider>
                </mat-expansion-panel>
            </div>
            <div *ngIf="userType==2">
                <mat-expansion-panel #studentActionPanel class="mat-elevation-z0" hideToggle>
                   <mat-expansion-panel-header class="nav-expansion">
                        <mat-panel-title><h2>Actions</h2></mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list (click)="studentActionPanel.toggle(); sidenav.toggle()">
                        <a mat-list-item *ngFor="let link of actionsRoutes"
                            [routerLink]="[ link.link ]"><h4>{{ link.name }}</h4></a>
                        <a mat-list-item (click)="openCreateFeedbackModal(userType)"><h4>New Feedback</h4></a>
                    </mat-nav-list>
                    <mat-divider></mat-divider>
                </mat-expansion-panel>
            </div>-->
        </div>
        
        <div #toggleSidenav (click)="sidenav.toggle()"></div>
    </mat-sidenav>
    <mat-sidenav-content class="page-content">
        <!-- <toaster-container [toasterconfig]="toastConfig" toaster-options="{'time-out': 3000, 'close-button':true, 'position-class':'toast-top-auto-width'}"></toaster-container> -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
