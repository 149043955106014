import { Component, Input, OnInit } from '@angular/core';
import { CandidateSkill } from '../../../Models/CandidateProfile';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEditComponent } from '../../../Pages/profile-edit/profile-edit.component';

@Component({
  selector: 'app-skills-view',
  templateUrl: './skills-view.component.html',
  styleUrls: ['./skills-view.component.css']
})
export class SkillsViewComponent implements OnInit {

  @Input() ownProfile:boolean;
  @Input() skills:any;
  
  constructor(public _dialog: MatDialog) { }

  ngOnInit(): void {
    
  }

  openEdit() {
    let dialog = this._dialog.open(ProfileEditComponent,{
      height: 'auto',
      width: '80%',
      disableClose: true,
      data: {
              subComponent: 'candidate-skills',
              seedData: this.skills
            }
    });
    dialog.afterClosed()
      .subscribe((result) => {
        if (result == null) {
        } else {
          console.log(result.skills);
          this.skills = result.skills;
        }
      });   
  }

}
