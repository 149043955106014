<div class="container profile-padding proflie-page" ng-cloak>
    <div *ngIf="userType==2" class="timesheet-view timesheet-page" id="timesheet-page">
        <div id="we_aboutme ">
            <div class="row">
                <div class="col-md-12">
                    <mat-toolbar mat-toolbar color="primary" class=" top_tl background-primary mat-default-theme">
                        <div class="mat-toolbar-tools  pan-title">
                            <h1 mat-dialog-title class="calendar-md-title">Timesheet</h1>
                            <span mat-flex></span>
                        </div>
                    </mat-toolbar>
                </div>
            </div>

            <div class="padding_color">

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6 addbutton">
                            <button mat-raised-button color="primary" (click)="getStudentTimesheets()" class="pull-left view_all">View All</button>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 addbutton">
                            <button mat-raised-button color="primary" (click)="addNewModal()" class="pull-right view_all">
                                <i class="fa fa-plus-circle"></i> Add New</button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="timesheet-table timesheet_search admin_user_page">
                        <form name="prof">

                            <div class="table-responsive">

                                <table datatable id="studentdatatables" width="100%" class="table table-bordered table-striped table-hover order-column" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">


                                    <thead>
                                        <tr>
                                            <th>Posting Title</th>
                                            <th width="20%">Date</th>
                                            <th width="20%">Time Entered</th>
                                            <th width="15%" class="text-center">Status</th>
                                            <th width="20%" class="text-center">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let list of opportunityTimesheetList; let i = index;">
                                            <td class="{{i + 1}}">{{list.title}}</td>
                                            <td class="date">
                                                <span hidden>{{list.datetime}}</span>{{list.date | date}}
                                            </td>
                                            <td>{{list.time_entered}}</td>
                                            <td class="text-center">
                                                <div *ngIf="list.approval_supervisor == 1">
                                                    <i class="glyphicon glyphicon-ok-circle"></i> Approved</div>
                                                <div *ngIf="list.approval_supervisor == -1">
                                                    <i class="glyphicon glyphicon-ban-circle"></i> Disapproved</div>
                                                <div *ngIf="list.approval_supervisor == 0">
                                                    <i class="glyphicon glyphicon-hourglass"></i> Pending</div>
                                            </td>
                                            <td class="text-center">
                                                <button matTooltip="Change" mat-raised-button color="primary" type="button" class="btn btn-info btn" (click)="edit(i);">
                                                    <span >
                                                        <i class="glyphicon glyphicon-pencil"></i>
                                                    </span>
                                                </button>
                                                <button matTooltip="Remove" mat-raised-button color="primary" type="button" class="btn btn-danger btn" (click)="delete(i);">
                                                    <span >
                                                        <i class="glyphicon glyphicon-trash"></i>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                        </form>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>



        </div>




    </div>
    <!-- End user type==3 div -->
    <!-- Start user type==5 div -->

    <div *ngIf="userType==3" class="timesheet-view timesheet-page" id="timesheet-page">
        <div id="we_aboutme">
            <div class="row">
                <div class="col-md-12">
                    <mat-toolbar mat-toolbar color="primary" class=" top_tl background-primary mat-default-theme">
                        <div class="mat-toolbar-tools pan-title">
                            <h1 mat-dialog-title class="calendar-md-title">Timesheet</h1>
                            <span mat-flex></span>
                        </div>
                    </mat-toolbar>
                </div>
            </div>
            <div class="padding_color">
                <form id="searchtimesheet" #searchtimesheet="ngForm" name="searchtimesheet" class="searchtimesheet" novalidate>
                    <div class="col-md-3">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-select placeholder="Select Posting Type" [(ngModel)]="view.selopportunityType" #selopportunityType="ngModel" name="selopportunityType"
                                id="selopportunityType" class="form-control" required (ngModelChange)="getOpportunityList(view.selopportunityType)">
                                <mat-option *ngFor="let opportunity of opportunitiesListType" [value]="opportunity.value">
                                    {{ opportunity.display }}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="view.selopportunityType">
                                <mat-error *ngIf="searchopportunity.hasError('required')" class="help-block small animated fadeInDown">
                                    Please select Timesheet Type!
                                </mat-error>
                            </div>

                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-select placeholder="Select Posting" [(ngModel)]="view.selopportunity" #searchopportunity="ngModel" name="searchopportunity"
                                id="searchopportunity" class="form-control" (ngModelChange)="opportunityStudentList(view.selopportunity)">
                                <mat-option *ngFor="let opportunity of opportunitiesList" [value]="opportunity.id">
                                    {{ opportunity.title }}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="view.selopportunity">
                                <mat-error *ngIf="searchopportunity.hasError('required')" class="help-block small animated fadeInDown">
                                    Please select opportunity!
                                </mat-error>
                            </div>

                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-select placeholder="Select Student" [(ngModel)]="view.selstudent" #searchstudent="ngModel" name="searchstudent" id="searchstudent"
                                class="form-control" (ngModelChange)="changeStudent()">
                                <mat-option *ngFor="let student of studentList" [value]="student.id">
                                    {{ student.name }}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="view.selstudent">
                                <mat-error *ngIf="searchstudent && searchstudent.hasError('required')" class="help-block small animated fadeInDown">
                                    Please select student name!
                                </mat-error>
                            </div>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3 search-timesheet-btn apply_clear pull-right">
                        <button (click)="searchStudentOpportunityTimesheet(view.selopportunity,view.selstudent)" mat-raised-button color="primary"
                            [disabled]="!searchtimesheet.valid">Apply</button>
                        <button type="button" (click)="searchStudentOpportunityTimesheet(view.selopportunity = '',view.selstudent = '',1)" mat-raised-button
                            color="warn" [disabled]="disableStatus" class="ml10">Clear</button>
                    </div>
                </form>

                <div class="col-md-12">
                    <div class="timesheet-table timesheet_search admin_user_page">
                        <form name="prof">

                            <table datatable id="supdatatables" width="100%" class="table table-bordered table-striped table-hover order-column" [dtOptions]="dtOptionsEmp" [dtTrigger]="dtTriggerEmp">

                                <thead>
                                    <tr>
                                        <th [hidden]=true></th>
                                        <th width="30%">Student Name</th>
                                        <th width="25%">Posting Title</th>
                                        <th width="15%">Date</th>
                                        <th width="10%">Time Entered</th>
                                        <th width="15%">Approve / Reject</th>
                                        <th width="5%">Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of opportunityTimesheetList; let i = index;">
                                        <td [hidden]=true>{{i + 1}}</td>
                                        <td class="{{i + 1}}">{{list.name}}</td>
                                        <td>{{list.title}}</td>
										<td class="date"><span class="hidden">{{list.datetime}}</span><time>{{list.date | date: 'MMM dd, yyyy'}}</time></td>
										<!-- <td>{{list.date | date: 'yyyy/MM/dd'}}</td> -->
                                            <td>{{list.time_entered}}</td>

                                            <td *ngIf="list.approval_supervisor == 0">
                                                <div class="text-center">
                                                    <button matTooltip="Approve" type="button" class="btn btn-primary" (click)="confirmUpdateStatus(i, 1);">
                                                        <span >
                                                            <i class="glyphicon glyphicon-ok"></i>
                                                        </span>
                                                    </button>
                                                    <button matTooltip="Disapprove" type="button" class="btn btn-warning" (click)="confirmUpdateStatus(i, -1);">
                                                        <span >
                                                            <i class="glyphicon glyphicon-remove"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>

                                            <td *ngIf="list.approval_supervisor != 0">
                                                <div class="text-center" *ngIf="list.approval_supervisor == 1">
                                                    <i class="glyphicon glyphicon-ok-circle"></i> Approved
                                                </div>
                                                <div class="text-center" *ngIf="list.approval_supervisor == -1">
                                                    <i class="glyphicon glyphicon-remove-sign"></i> Disapproved
                                                </div>
                                            </td>

                                            <td class="text-center">
                                                <button matTooltip="Remove" type="button" class="btn btn-danger" (click)="deleteSupervisor(i);">

                                                    <span >
                                                        <i class="glyphicon glyphicon-trash"></i>
                                                    </span>

                                                </button>
                                            </td>

                                    </tr>
                                </tbody>
                            </table>

                        </form>
                    </div>

                </div>
                <div class="clearfix"></div>
            </div>

        </div>
    </div>
    <!-- End user type==5 div -->


</div>