import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';



@Component({
  selector: 'app-logout-confirm',
  templateUrl: './logout-confirm.component.html',
  styleUrls: ['./logout-confirm.component.css']
})
export class LogoutConfirmComponent implements OnInit {
  public logoutConfirm: boolean = false;

  constructor(public _dialogRef: MatDialogRef<LogoutConfirmComponent>,
    private _router: Router) { }

  ngOnInit(): void {
  }

  confirmLogout(){
    this.logoutConfirm = true;
		this._dialogRef.close();
    //this._router.navigate(['/']);     
	}

	cancelLogout(){
		this._dialogRef.close();
	}
}
