<mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
  <h2 class="title-padding medium-24">Invite New User</h2>
  <div fxLayout="row" fxLayoutGap="20px">
    <button *ngIf="showSuccess == 1" mat-button mat-raised-button (click)="backToInvite()">BACK</button>
    <button *ngIf="!largeScreen && showSuccess !== 1" mat-stroked-button (click)="back()">BACK</button>
  </div>
</mat-card-title>
<mat-divider inset></mat-divider>
<form fxLayout="column" [formGroup]="emailForm">
  <div fxLayout="row wrap" *ngIf="showSuccess==0" fxLayoutGap="0px">
    <div fxLayout="row" fxFlex="100">
      <mat-form-field appearance="outline" class="form-field" fxFlex="100">
        <mat-label>Email Address</mat-label>
        <mat-chip-list #emailChipList formArrayName="emails">
          <mat-chip [color]="(em.invalid)?'warn':''" selected *ngFor="let em of emailList; let i=index"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="removeEmail(em)"
                    required name="chips">
            {{em.value}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="Enter Multiple Emails "
                 name="txtEmail"
                 [matChipInputFor]="emailChipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="addEmails($event) " />
        </mat-chip-list>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxFlex="100">
      <mat-form-field appearance="outline" class="no-padding">
        <mat-label class="label-font">Select User Group</mat-label>
        <mat-select formControlName="usergroup" id="grp" (selectionChange)="onChangeUserGroup($event.value)" multiple>
          <mat-option *ngFor="let cg of companyGroups" [value]="cg.id">{{cg.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxFlex="100">
      <button color="primary" [disabled]="emailForm.invalid" id="btnInvite" mat-button mat-raised-button (click)="Invite()">INVITE
        <mat-icon *ngIf="sendingEmail">
          <mat-spinner color="accent" diameter="20">
          </mat-spinner>
        </mat-icon></button>      
    </div>
  </div>
  <div fxLayout="row wrap" *ngIf="showSuccess==1" class="success" fxLayoutGap="0px">
    <div fxFlex="100" fxLayout="column">
      <div class="success-tick" fxLayout="row" fxFlex="60" fxLayoutAlign="center end">
        <mat-icon [inline]="true">check_circle</mat-icon>
      </div>
      <div fxLayout="row" fxFlex="20" fxLayoutAlign="center end">
        <b>Invitation sent to {{getEmails(true)}}.</b>
      </div>
      <div fxLayout="row" *ngIf="previouslyInvitedEmail.length>0" fxLayoutAlign="center start">
        <b color="warn">Invitation already sent to {{previouslyInvitedEmail}}.</b>
      </div>
      <div fxLayout="row" *ngIf="userExistsEmail.length>0" fxFlex="20" fxLayoutAlign="center start">
        <b color="warn">{{userExistsEmail}} is already associated with an account.</b>
      </div>
    </div>
  </div>  
</form>
