
import {catchError, map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoService } from '../../../Services/user-info.service'
import { TalentApiService } from '../../../Services/talent-api.service';
import { EmployerRegisterService } from '../../../Services/employer-register.service';
import { LoginService } from '../../../Services/login.service';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';

@Component({
	selector: 'app-employer-setup-profile',
	templateUrl: './employer-setup-profile.component.html',
	styleUrls: ['./employer-setup-profile.component.css']
})
export class EmployerSetupProfileComponent implements OnInit {

	public tab = 0; //Home
	public visited = [
		false, false, false, false, false
	];
	// public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	public userInfo = this._userInfoService.getUser();
	public address1 = '';
	public address2 = '';
	public results: any;
	public companiesinfo: any;
	public user: any;
	public empUser = {
		firstName: '',
		lastName: '',
		phone: '',
		division: '',
		photoUpload: '',
		skype:'',
		linkedin:'',
		company:'',
		sector:'',
		location:{
		   address: this.address1+' '+ this.address2,
		   address2:'',
		   city: '',
		   state: '',
		   zip: '',
		   metro: 'USA',
		   pubTransit: false,
		   remote: false
		},
		compSocialMedia:{
		   linkedIn:'',
		   facebook:'',
		   twitter:''
	  }

  };
  	public companyWebsite: any;
	// public user = {
	// 	id: '',
	// 	email: '',
	// 	pass: '',
	// 	verifiedpass: '',
	// 	phone: '',
	// 	reset: '',
	// 	passhash: '',
	// 	resethash: '',
	// 	firstName: '',
	// 	lastname: '',
	// 	linkedin: '',
	// 	company: ''
	// };
	public activeStep: any;
	public newAddress: boolean = false;
	public newCompany = { name: '', id: '', website: '' };
	public newLocation = { city: '', state: '', zip: '', street: '' };
	public states = ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
		'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
		'WY').split(' ').map(function (state) {
			return { abbrev: state };
		});
	public imageChangedEvent: any;
	public imageError: boolean = false;
	public agree: boolean = false;

	searchResult = [];
	//For Company details in employer registration
	public approximateLocation = {
		city: [],
		state: [],
		street: [],
		zip: []
	};
	public sectors = [];
	public progress: boolean = false;
	public pic:any;
	public errors:any;

	constructor(public _dialog: MatDialog, private _userInfoService: UserInfoService, private _talentApiService: TalentApiService, public _employerRegistrationService: EmployerRegisterService, private _loginService: LoginService, private _router: Router) { }

	ngOnInit() {
		//this.openEmployerProfileModal();
		$("body").removeClass("with-toolbar");
		$("body").removeClass("modal-open");
		this.user = this._talentApiService.newBlankEmployerUser();
		this.getCompaniesInfo();
		this.getSectorsList().subscribe(response => {
			//console.log('ngOnit after getSectors() ' + this.sectors);
		});
	}

	getCompaniesInfo() {
		this._talentApiService.getCompaniesInfo()
			.subscribe(
			(result:any) => {
				this.companiesinfo = result;
			},
			error => {
				//this.ErrorMessage = "Something went wrongs!";
				console.log('Could not load getCompaniesInfo');
			}
			);
	}

	openEmployerProfileModal() {
		let dialog = this._dialog.open(EmployerSetupProfileComponent,{
			height: 'auto',
			width: '66.66%',
			panelClass:"employer_profile_setup"
		});

		dialog.afterClosed()
		.subscribe((result) => {
			
		})
	}

	setTab(tabToSet) {
		this.visited[this.tab] = true;
		this.tab = tabToSet;
	}
	checkActiveTab(active) {
		return this.tab === active;
	}
	checkActiveStep(check) {
		return this.activeStep === check;
	}

	checkLinUrl(url){
		var string = url;
        if(!(/^http:\/\//.test(string))){
			//string = "http://" + string;
			string = "http://";
        }
        this.user.linkedin = string;
        this.empUser.linkedin = string;
	}

  addNewOption() {    
		//console.log('user: ', this.user);
		//if (this.user.company != null && this.user.company != "") {
		//if(typeof this.user.company === 'object' || this.user.company instanceof Object){
		if (this.newCompany.name != null && this.newCompany.name != "") {
			this.newAddress = false;
			this.user.company = this.newCompany.name;
			//console.log(this.user.company);
			//angular.copy(this.user.company.state, this.approximateLocation.state);
			this.approximateLocation.state = Object.assign({}, this.approximateLocation.state, this.user.company.state);

			// this.approximateLocation.state = this.user.company.state;
			// this.approximateLocation.zip = this.user.company.zip;
			// this.approximateLocation.street = this.user.company.street;
			// this.approximateLocation.city = this.user.company.city;

			//angular.copy(this.user.company.zip, this.approximateLocation.zip);
			this.approximateLocation.zip = Object.assign({}, this.approximateLocation.zip, this.user.company.zip);

			//angular.copy(this.user.company.street, this.approximateLocation.street);
			this.approximateLocation.street = Object.assign({}, this.approximateLocation.street, this.user.company.street);

			//angular.copy(this.user.company.city, this.approximateLocation.city);
			this.approximateLocation.city = Object.assign({}, this.approximateLocation.city, this.user.company.city);

			this.user.location.metro = 'USA';
		} else {
			this.newAddress = true;
			this.user.location.metro = 'USA';

			this.approximateLocation.street.push('90 kent St', '26 Lake Shore Dr');
			this.approximateLocation.state.push('NY', 'MA', 'MT');
			this.approximateLocation.city.push('Albany', 'Troy');
			this.approximateLocation.zip.push('12206', '12189');
		}
		return this.approximateLocation;
		//}
	}
	checkCompanyObject(event){
		if (typeof event === 'string' || event instanceof String) {
			this.newAddress = true;
		}
		else if(typeof event === 'object' || event instanceof Object){
			this.newAddress = false;
		}
	}
	companyQuerySearch(query) {
		if (query !== null && query !== "") {
			if (typeof query === 'string' || query instanceof String) {
				this.results = query ? this.companiesinfo.filter(this.createFilterForCompany(query)) : [];
				return this.results;
			}
			else if (typeof query === 'object' || query instanceof Object) {
				this.results = query.name ? this.companiesinfo.filter(this.createFilterForCompany(query.name)) : [];
				return this.results;
			}

		}
	}
	//For Company QuerySearch
  createFilterForCompany(query) {
    
		let lowercaseQuery = query.toLowerCase();
		return function filterFn(companiesinfo) {
			return (companiesinfo.name.toLowerCase().indexOf(lowercaseQuery) === 0);
		};
	}
	addCompany = (companyObject): string => {

		if (companyObject !== null) {
			//this.newCompany.name = this.user.company;
			this.newCompany.name = companyObject;
			//console.log(this.newCompany);
			this.addNewOption();
			//this.addNewOption().subscribe(response => { });
			return companyObject.name ? companyObject.name : companyObject.name;
		}
	}
	querySearch(query, sourcelist) {
		if(typeof query === 'string' || this.user.company instanceof String){
			if (query != undefined && query != '' && query != null) {
				var results: any;
				results = query ? sourcelist.filter(this.createFilterFor(query)) : [];
				return results;
			}
		}
		else{
			if (query != undefined && query.value != '' && query.value != null) {
				var array = $.map(sourcelist, function (value, index) {
					return [value];
				});
				var results: any;
				results = query.value ? array.filter(this.createFilterFor(query.value)) : [];
				return results;
			}
		}
	}
	createFilterFor(query) {
		var lowercaseQuery = query.toLowerCase();
		return function filterFn(item) {
			var lowercaseitem = item.toLowerCase();
			return (lowercaseitem.indexOf(lowercaseQuery) != -1);
		};
	}
	setCompanyStreet(value) {
		this.newLocation.street = value;
		//console.log('new location: ',this.newLocation);
	}
	checkCompanyUrl(url){
		var string = url;
        if(!(/^http:\/\//.test(string))){
			//string = "http://" + string;
			string = "http://";
		}
        //this.user.company.website = string;
        this.companyWebsite = string;
	}
	checkLinUrlCompany(url){
		var string = url;
        if(!(/^http:\/\//.test(string))){
			//string = "http://" + string;
			string = "http://";
        }
        this.user.compSocialMedia.linkedIn = string;
	}
	checkfbUrl(url){
		var string = url;
        if(!(/^http:\/\//.test(string))){
			//string = "http://" + string;
			string = "http://";
        }
        this.user.compSocialMedia.facebook = string;
	}
	checktwitterUrl(url){
		var string = url;
        if(!(/^http:\/\//.test(string))){
			//string = "http://" + string;
			string = "http://";
        }
        this.user.compSocialMedia.twitter = string;
	}
	ngOnDestroy() {
		$("body").addClass("with-toolbar");
	}
	
	updateCompanyLocation() {
		//console.log('Posting: ',this.posting);


		// if(this.user.company == null){
		// 	this.user.company = {};
		// 	this.user.company.name = this.newCompany.name;
		// };
		if (typeof this.user.company === 'string' || this.user.company instanceof String) {
			this.newCompany.name = this.user.company;
			this.user.company = {};
			this.user.company.name = this.newCompany.name;
		};

		this.user.company.website = this.companyWebsite ? this.companyWebsite : '';
		// this.user.company.socialMedia = {
		// 	linkedIn: this.companyLinkedIn ? this.companyLinkedIn : '', //string
		// 	facebook: this.companyFacebook ? this.companyFacebook : '', //string
		// 	other: ''
		// };

		// this.user.company.sector = {
		// 	id: this.companySector.id,
		// 	name: this.companySector.name
		// };

		if(this.user.location.city == null){
			this.user.location.city = this.newLocation.city;
		};

		if(this.user.location.address == null){
			this.user.location.address = this.newLocation.street;
		};

		if(this.user.location.state == null){
			this.user.location.state = this.newLocation.state;
		};

		if(this.user.location.zip == null){
			this.user.location.zip = this.newLocation.zip;
		};
		//deleting unneccesary information from company
		//we don't need it as we already have an location object

		delete this.user.company.city;
		delete this.user.company.state;
		delete this.user.company.zip;
		delete this.user.company.street;
	}
	getSectorsList() {
		let sec = [];
		return this._employerRegistrationService
			.getSectors().pipe(
			map(
			(result) => {
				let resultStr = JSON.stringify(result);
				let resultArray = JSON.parse(resultStr);
				resultArray.forEach(function (sect) {
					sec.push(sect);
				});
				this.sectors = sec;
				this.user.sector = this.sectors[0];
			}),
			catchError((error) => {
				console.log('error ' + error);
				throw error;
			}),);
	}
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
		console.log(this.imageChangedEvent);
		let fileExtension = this.imageChangedEvent.target.value.replace(/^.*\./, '');
		switch (fileExtension) {
			case 'png': case 'jpeg': case 'jpg':
				this.imageError = false;
				break;
            default:
				this.imageError = true;
				break;
        }
	}
	imageCropped(event: any) {
		//this.croppedImage = image;
		this.user.photoUpload = event.base64;
	}
	imageLoaded(event) {
		//this.user.photoUpload = event;
		// show cropper
	}
	loadImageFailed() {
		// show message
	}

	submit(){
		this.progress = true;
		this.pic = this.user.photoUpload;
		this.user.photoUpload = null;

		if(this.user.company == null) {
			this.user.company = {};
			this.newCompany.website = this.user.company.website;
			this.newCompany = Object.assign({}, this.newCompany, this.user.company);
			//angular.copy(this.newCompany, this.user.company);
		}

		if(this.user.location.city == null){
			this.user.location.city = this.newLocation.city;
		};

		if(this.user.location.address == null){
			this.user.location.address = this.newLocation.street;
		};

		if(this.user.location.state == null){
			this.user.location.state = this.newLocation.state;
		};

		if(this.user.location.zip == null){
			this.user.location.zip = this.newLocation.zip;
		};

		delete this.user.company.city;
		delete this.user.company.state;
		delete this.user.company.zip;
		delete this.user.company.street;
		//console.log('USER Info: ',this.user);
		//setting up the User object for creating employer user
		this.user.firstName = this.empUser.firstName;
		this.user.lastName = this.empUser.lastName;
		this.user.linkedin = this.empUser.linkedin;
		this.user.location.address = this.empUser.location.address;
		this.user.location.state = this.empUser.location.state;
		this.user.phone = this.empUser.phone.replace(/\D+/g, '');
		this.user.skype = this.empUser.skype;
		console.log('this.user');
		console.log(this.user);
		console.log(this.user.location.remote);
		this._talentApiService.createEmployer(this.user)
		.subscribe(
			(result:any) => {
				console.log("Employer created successfully");
				this._talentApiService.uploadFile('employer', 'profile-pic', this.pic)
				.subscribe(
					(result:any) => {
						console.log("Employer pic_uploaded successfully");
					}
				);
				// let param:Login = {
				// 	Email: this.empUser.TJS_USERNAME,
				// 	Password: this.empUser.TJS_PASSWORD,
				// 	RememberMe: false
				// };
				// this._loginService.Login(param)
				// .subscribe(
				// 	(result:any) => {
				// 		this.progress = true;
				// 		this.errors = false;
				// 		this._router.navigate(['/dashboard']);
						
				// 	},
				// 	error => {
				// 		this.progress = false;
				// 		this.errors = true;
				// 		console.log("bad user");
				// 		//do something
				// 	}
				// );

			},
			error => {
				this.progress = false;
				console.log("submission error check logs");
				this.errors = true;
			}
		);
	}
}
