import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurationModel } from '../Helpers/tjsConfigurationHelper';
import 'rxjs';
import { UserInfoService } from './user-info.service';


@Injectable()
export class AdminApiService {
    
  constructor(private _http: HttpClient, private _router: Router, private _userInfoService: UserInfoService) { }


  urlConfigVariableList() {
    let data = {};
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getConfigVariableList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['configVariableList'];
          }
          else {
            console.log('Couldnt get posting data');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));

  }

  createConfigVariable(configVar) {
    let data = { 'configVariable': configVar };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/createConfigVariable", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt get posting data');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }

  createEmailTemplate(emailTemplate: any) {
    let data = { 'emailTemplate': emailTemplate };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    console.log(data);
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/createEmailTemplate", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Failed to create email template');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }

  deleteConfigVariable(id) {
    let data = { 'configVariableId': id };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/deleteConfigVariable", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt get posting data');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }

  deleteEmailTemplate(id) {
    let data = { 'emailTemplateId': id };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    console.log(data);
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/deleteEmailTemplate", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Failed to delete email template');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }

  editConfigVariable(configVar) {
    let data = { 'configVariable': configVar };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/editConfigVariable", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          } else {
            console.log('Couldnt get posting data');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }
  urlUserList() {
    let data = {};
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getAdminUserList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['adminUserList'];
          } else {
            console.log('Couldnt get posting data');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }


  modalEnableDisable(id, status) {
    let data = { 'user': {} };
    data.user = {
      id: id,
      enable: status
  };
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/editUserStatus", data, { headers: headers }).pipe(
    map(response => {
      {
        if (typeof (response !== 'undefined')) {
          return response['status'];
        }else {
          console.log('Couldnt get posting data');
        }
      };
    }),
    catchError(error => observableThrowError(
       { status: error.status,msg:error.error }
    )),);
}

editUser(user) {
let data = { 'user':user};
let headers = new HttpHeaders();
headers.append('Content-Type', 'application/x-www-form-urlencoded');
return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/editUser", data, { headers: headers }).pipe(
	map(response => {
	{
		if (typeof (response !== 'undefined')) {
		return response['status'];
		}else {
		console.log('Couldnt get posting data');
		}
	};
	}),
	catchError(error => observableThrowError(
	{ status: error.status,msg:error.error }
	)),);
}

editEmailTemplate(id, template) {
  let data = { 'emailTemplateId': id, 'emailTemplate': template };
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  console.log(data);
  return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/editEmailTemplate", data, { headers: headers }).pipe(
    map(response => {
    {
      if (typeof (response !== 'undefined')) {
        return response['status'];
      }
      else {
        console.log("Failed to edit email template");
      }
    };
    }),
    catchError(error => observableThrowError(
    { status: error.status,msg:error.error }
    )),);
  }


	getSkillsTree(){
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getSkillsTreeData", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['skillsTreeData'];
					}
					else {
						console.log('Couldnt get Skills tree');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	editSkill(skill){
		let data = { 'skill': skill };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/editSkill", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['status'];
					}
					else {
						console.log('Couldnt get Reviewed Skills tree');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	createSkill(skill){
		let data = { 'skill': skill };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/createSkill", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['status'];
					}
					else {
						console.log('Couldnt add Skill');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	deleteSkill(skill_id){
		let data = { 'id': skill_id };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/deleteSkill", data, { headers: headers }).pipe(
			map(response => {
				{
					if (typeof (response !== 'undefined')) {
						return response['status'];
					}
					else {
						console.log('Couldnt delete Skill');
					}
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);
	}

	urlOrganizationsList(){
		let data = {  };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getOrganizationsList", data, { headers: headers }).pipe(
		map(response => {
			{
			if (typeof (response !== 'undefined')) {
				return response;
			}
			else {
				console.log('Couldnt get organizations logo');
			}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status,msg:error.error }
		)),);
	}

	getLogo(fkLogo){
		let data = { fkLogo: fkLogo };
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getLogo", data, { headers: headers }).pipe(
		map(response => {
			{
			if (typeof (response !== 'undefined')) {
				return response['logo'];
			}
			else {
				console.log('Couldnt get organizations logo');
			}
			};
		}),
		catchError(error => observableThrowError(
			{ status: error.status,msg:error.error }
		)),);
   }


  uploadLogo(file, size, type, fileId, orgType, altText, fkLogo) {
    let data = { 'file': file, 'altText': altText, 'table': orgType, 'organizationId': fileId, 'fkLogo': fkLogo };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/uploadLogo", data, { headers: headers }).pipe(
    map(response => {
     {
     if (typeof (response !== 'undefined')) {
      return response['status'];
     }
     else {
      console.log('Couldnt get organizations logo');
     }
     };
    }),
    catchError(error => observableThrowError(
     { status: error.status,msg:error.error }
    )),);
   }
  
   setDefaultLogo(file, logoId) {
    let data = { 'file': file, 'logoId': logoId };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/uploadDefaultLogo", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt get organizations logo');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }



  getTenantsList() {


    let data = {};

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getTenantsList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['tenantsList'];
          }
          else {
            console.log('Couldnt get Tenants list');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  getEmailTemplateList() {
    let data = {};
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getEmailTemplateList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['emailTemplateList'];
          }
          else {
            console.log('Couldnt get Tenants list');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  createTenant(tenant) {


    let data = { 'tenant': tenant };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/createTenant", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt add Tenant');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  editTenant(tenant) {


    let data = { 'tenant': tenant };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/editTenant", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt update Tenant');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  deleteTenant(id) {


    let data = { 'id': id };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/deleteTenant", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt delete Tenant');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  getTenantLogosList() {


    let data = {};

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getTenantLogosList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['tenantLogosList'];
          }
          else {
            console.log('Couldnt get Tenant Logos list');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  createTenantLogo(tenantLogo) {


    let data = { 'tenantLogo': tenantLogo };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/createTenantLogo", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt add Tenant Logo');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  editTenantLogo(tenantLogo) {


    let data = { 'tenantLogo': tenantLogo };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/editTenantLogo", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt update Tenant Logo');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  deleteTenantLogo(id) {


    let data = { 'id': id };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/deleteTenantLogo", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log('Couldnt delete Tenant Logo');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  getNewsSources() {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let data = {

      'user': 'admin'
    };
		return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + 
			"admin/getNewsSources", data, {headers: headers }).pipe(
			map(response => {
				{
          if (typeof (response !== 'undefined')) {
            return response['newsSources'];
          }
          else {
            console.log('Couldnt get news sources');
          }
				};
			}),
			catchError(error => observableThrowError(
				{ status: error.status }
			)),);

  }

  createNewsSource(user_type:string, url:string, priority:string, expiration:Date, tenant:string, active:string, expires:string) {
		
		let headers = new HttpHeaders();
		let user = this._userInfoService.getUser();
		headers.append('Content-Type', 'application/json');
		let data = { 
			
			'user': user,
			'data': {
				'update_id': "NULL",
				'user_type': user_type,
				'new_url': url,
				'source_priority': priority,
        'new_exp': expiration,
        'tenant': tenant,
        'active': active,
        'expires': expires
      }
    };
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "admin/updateNewsSource", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['status'];
            }
            else {
              console.log('Couldnt create news source');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }

  updateNewsSource(update_id: string, user_type: string, url: string, priority: string, expiration: Date, tenant: string, active: string, expires: string) {

    let headers = new HttpHeaders();
    let user = this._userInfoService.getUser();
    headers.append('Content-Type', 'application/json');
    let data = {

      'user': user,
      'data': {
        'update_id': update_id,
        'user_type': user_type,
        'new_url': url,
        'source_priority': priority,
        'new_exp': expiration,
        'tenant': tenant,
        'active': active,
        'expires': expires
      }
    };
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "admin/updateNewsSource", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['status'];
            }
            else {
              console.log('Couldnt update news source');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));

  }

  deleteNewsSource(id: string) {

    let headers = new HttpHeaders();
    let user = this._userInfoService.getUser();
    headers.append('Content-Type', 'application/json');
    let data = {

      'user': user,
      'data': {
        'update_id': id
      }
    };
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "admin/deleteNewsSource", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['status'];
            }
            else {
              console.log('Couldnt delete News source');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }

  getUserTypes() {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = {

    };

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "admin/getUserTypes", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['userTypes'];
            }
            else {
              console.log('Could not get user types');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }

  getTenantPermissionsTable(tenant: number, userGroup: number) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = { tenant: tenant, userGroup: userGroup };
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "admin/getTenantPermissionsTable", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['tenantPermissionsTable'];
            }
            else {
              console.log('Could not get tenant permission table');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }

  editPermission(routeId: number, tenant: number, userGroup: number, activated: number, is_nav_route: number, is_actions_route: number) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = { routeId: routeId, tenant: tenant, userGroup: userGroup, activated: activated, is_nav_route: is_nav_route, is_actions_route: is_actions_route };
    console.log(data);
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "admin/editPermission", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['success'];
            }
            else {
              console.log('Could not edit the route permission');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }

  sendPostingListEmail(emails: Array<String>, message: string, postings: Array<String>) {
    let headers = new HttpHeaders();
    let data = { 'emails': emails, 'message': message, 'postings': postings };
    console.log(data);
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL +
      "admin/sendPostingListEmail", data, { headers: headers }).pipe(
        map(response => {
          {
            if (typeof (response !== 'undefined')) {
              return response['success'];
            }
            else {
              console.log('Could not send email');
            }
          };
        }),
        catchError(error => observableThrowError(
          { status: error.status }
        )));
  }

  getAPIList() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = {};

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getAPIList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['apiList'];
          }
          else {
            console.log('Could not retrive api list');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  editAPI(apiForm) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = { id: apiForm.id, name: apiForm.apiname, permissions: apiForm.permissions };
    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/updateAPI", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['api'];
          }
          else {
            console.log('Could not edit api');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  createAPI(apiForm) {     
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = { id: apiForm.id, name: apiForm.apiname, permissions: apiForm.permissions};

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/createAPI", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['api'];
          }
          else {
            console.log('Could not create the api');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  deleteAPI(id: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = { id: id };

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/deleteAPI", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['api'];
          }
          else {
            console.log('Could not delete api permission');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }
  getGlobalPermissionList() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = {   };

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getGlobalPermissionsList", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['globalPermissionsList'];
          }
          else {
            console.log('Could not retrieve api global permissions list');
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status }
      )));
  }

  getEmployerList() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = {};

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/getCompanyEmployers", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['companyEmployers'];
          }
          else {
            console.log('Could not retrieve company employer list');
          }
        };
      }),
      catchError(error =>
        observableThrowError(
          {
            status: error.status
          })
      ));
  }

  importBulkOpportunities(datum) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let data = datum;

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/importBulkOpportunities", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response) !== 'undefined') {
            return response;
          }
          else {
            console.log('Could not retrieve company employer list');
          }
        };
      }),
      catchError(error =>
        observableThrowError(
          {
            status: error.status
          })
      ));
  }
  mergeSkill(skillSource: number, skillTarget: number) {
    let data = { skillSource: skillSource, skillTarget: skillTarget };
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/mergeSkill", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response !== 'undefined')) {
            return response['status'];
          }
          else {
            console.log("'Couldn't merge this Skill");
          }
        };
      }),
      catchError(error => observableThrowError(
        { status: error.status, msg: error.error }
      )));
  }

  getContentReports() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.get(ConfigurationModel.Configuration.BASE_API_URL + "admin/getContentReports", { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response) !== 'undefined') {
            return response['contentReports'];
          }
          else {
            console.log('Could not retrieve content data');
          }
        };
      }),
      catchError(error =>
        observableThrowError(
          {
            status: error.status
          })
      ));
  }

  contentReportAction(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(ConfigurationModel.Configuration.BASE_API_URL + "admin/contentReportAction", data, { headers: headers }).pipe(
      map(response => {
        {
          if (typeof (response) !== 'undefined') {
            return response;
          }
          else {
            console.log('Could not retrieve content data');
          }
        };
      }),
      catchError(error =>
        observableThrowError(
          {
            status: error.status
          })
      ));
  }
}
