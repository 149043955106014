import { Component, OnInit ,Inject,ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//import { LoginComponent } from '../login/login.component';

declare var $:any;
@Component({
	selector: 'talent-registration-terms',
	templateUrl: './terms-condition-reg.component.html',
	// styleUrls: ['./terms-condition-reg.component.css']
  })
  export class TermsConditionRegComponent implements OnInit {
  
	constructor( 
	  private dialogRef: MatDialogRef<TermsConditionRegComponent>, public _dialog: MatDialog
	  ) {}
	ngOnInit() {
	}
	onNoClick(): void {
	 // this.openDialog()
	  this.dialogRef.close();
	}	
	close(): void {
		this.dialogRef.close(true);
	}
  }