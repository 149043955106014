<div>
	<form name="classSelector" id="classSelector" class="" #classSelector="ngForm" novalidate>
		<div class="row">
			<div class="col-lg-12">
				<h4 mat-dialog-title>Have you completed Page 1, Page 2, and Page 3?</h4>
				<div mat-dialog-content class="mat-block">
					<label>Check For Incomplete Forms</label>
				</div>
				<div mat-dialog-actions>
					<button mat-button color="primary">
						Submit Now
					</button>
					<button mat-button type="button">
						Return to Form
					</button>
				</div>
			</div>
		</div>
	</form>
</div>