import { Component, OnInit, Input } from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { TalentApiService } from '../../Services/talent-api.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {
  @Input() tenant: string;
  @Input() type: string;
  deviceSize: string;
  logoImage: string;
  constructor(private _talentApiService: TalentApiService, breakpointObserver: BreakpointObserver) { 
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait,
      '(max-width: 1024px) and (orientation: portrait)'
    ]).subscribe(result => {
      if (result.matches) {
        this.deviceSize = 'Mobile';
      }
      else {
        this.deviceSize = 'Desktop';
      }
    });
  }

  ngOnInit(): void {
    this.logoImage = '';
    this.getLogo();
  }

  getLogo() {
		this._talentApiService.getLogo(this.tenant, this.type, this.deviceSize).subscribe(
			(result: any) => {
        if(result && result.length > 0) {
          this.logoImage = result[0].image; //need to update "data:image/jpeg;base64," +

        }
        else {
          console.log('Error retrieving logo image');
        }
			},
			error => {
				console.log('Could not get logo');
			}
		);
	}

}
