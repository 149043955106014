import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { Subject } from 'rxjs';
import { AdminApiService } from '../../Services/admin-api.service';
import { MatDialog } from '@angular/material/dialog';
import { TenantLogoEditComponent } from '../../Admin/dialogs/tenant-logo-edit/tenant-logo-edit.component';

@Component({
  selector: 'app-tenant-logo',
  templateUrl: './tenant-logo.component.html',
  styleUrls: ['./tenant-logo.component.css']
})
export class TenantLogoComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'type', 'size', 'created_by', 'created_ts', 'last_updated_by', 'last_updated_ts', 'edit'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  tenants: any;
  tenantLogosList = {};

  constructor(private _adminApiService: AdminApiService,
  public _dialog: MatDialog) {}

  ngOnInit(): void {
    this.getTenants();
	this.getTenantLogos();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTenants() {
		this._adminApiService.getTenantsList()
			.subscribe(
			(result: any) => {
        this.tenants = result;
			},
			error => {
				console.log('Could not load Tenants');
			}
		);
	}

  getTenantLogos() {

		this._adminApiService.getTenantLogosList()
			.subscribe(
			(result: any) => {
				this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
				const self = this;
				result.map((element, i) => {
					self.tenantLogosList[element.id] = element;
				});
			},
			error => {
				console.log('Could not load Tenant Logos');
			}
		);
	}

  // role edit popup
  openEditModal(id= '') {
	const dialog = this._dialog.open(TenantLogoEditComponent, {
    height: 'auto',
    minWidth: '40%'
	});
	const editData = Object.assign({}, this.tenantLogosList[id]);
	const isReloadData = false;

	dialog.componentInstance.isReloadData = isReloadData;

  dialog.componentInstance.editing = editData;
  dialog.componentInstance.tenants = this.tenants;
	dialog.afterClosed()
	.subscribe((result) => {
		if (dialog.componentInstance.isReloadData) {
		this.getTenantLogos();
		}
	});
  }

}
