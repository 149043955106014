<div class="container">
	<div class="row  proflie-page posting_p posting_page">
		<div class="tab-custom">
			<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 ">
				<div class="background-white profile-padding" [ngClass]="{'visible-lg': showDetail}">
					<div class="left_list_panel">
						<ul class="settings_list list-unstyled">
							<li class="search_bar">
								<div class="input-group">
									<input matInput aria-label="search" class="form-control" [(ngModel)]="searchText" id="searchText" name="searchText" #searchPosting="ngModel"
									 placeholder="   Search Classes, Postings, Students">
									<span class="input-group-btn">
										<button class="btn btn-success" type="button">
											<span class="fa fa-search"> </span>
										</button>
									</span>
								</div>
							</li>
							<li class="filters">
								<div class="row">
									<div class="col-xs-6 col-sm-6">
										<div id="sort" class="sort_cl">
											<button mat-button [matMenuTriggerFor]="menu">
												<i class="fa fa-1x fa-caret-down" *ngIf="!reverseSort"></i>
												<i class="fa fa-1x fa-caret-up" *ngIf="reverseSort"></i>
											</button>
											<mat-menu #menu="matMenu" width="2">
												<button mat-menu-item (click)="setSort('company')"> Company</button>
												<button mat-menu-item (click)="setSort('title')">Title</button>
												<button mat-menu-item (click)="setSort('lastName')">Name</button>
											</mat-menu>
										</div>
										<label for="sort" class="profile-exp-title no-padding" *ngIf="!sortText">Sort </label>
										<label for="sort" class="profile-exp-title no-padding">{{sortText}}</label>
									</div>
									<div class="col-xs-6 col-sm-6">
										<div id="filter" class="sort_cl">
											<button mat-button [matMenuTriggerFor]="menuFilter">
												<i class="fa fa-1x fa-filter"></i>
											</button>
											<mat-menu #menuFilter="matMenu" width="2">
												<button mat-menu-item (click)="setFilter(0, null)" *ngIf="isStudent">All Classes / Opportunites</button>
												<button mat-menu-item (click)="setFilter(0, null)" *ngIf="isInstructor">All Classes</button>
												<button mat-menu-item (click)="setFilter(0, null)" *ngIf="isEmployer">All Postings</button>
												<button mat-menu-item (click)="setFilter(1, null)"> Completed Feedback</button>
												<button mat-menu-item (click)="setFilter(2, null)"> Pending Feedback</button>
												<button *ngFor="let cls of classList" mat-menu-item (click)="setFilter(3, cls)"> {{cls.courseid}} - {{cls.name}}</button>
											</mat-menu>
										</div>
										<label for="sort" class="profile-exp-title">{{filterText}}</label>
									</div>
								</div>
							</li>
						</ul>
						<div class="wrapper_list">
							<ul role="tablist" class="nav nav-tabs list  responsive-tabs">
								
								<!-- <li role="presentation" *ngFor="let single_posting of postings | postingSearch:'city,company,state,status,streetaddress,title,zip':searchText | postingFilter:filter | postingOrder:sortField: this.reverseSort;"
														 (click)="showDetailFor(single_posting.id,1)"> -->

								<li *ngFor="let student of students | feedbackSearch:'title,name,company,className':searchText | feedbackFilter:filter | feedbackOrder:sortField: this.reverseSort;"
								 [ngClass]="{'active': student.feedback_id===detail_id && showDetail}" (click)="select(student.feedback_id)">


									<!-- show only fb requested to employer, show all accepted interns to school -->
									<!-- *ngIf="(student.fb_status > 0 && !isInstructor) || (student.fb_status != '' && isInstructor)" -->
								 	<a *ngIf="(student.fb_status > 0 && !isInstructor) || (student.fb_status != '' && isInstructor)">
										<div class="media">
											<div class="media-left feedback_img_cl">
												<img src="{{student.profilePic}}" class="media-object" />
											</div>
											<div class="media-body">
												<div class="list_text">
													<h4 *ngIf="isStudent && student.className">{{student.className | titlecase}}</h4>
													<h4 *ngIf="isStudent && !student.className">{{student.title | titlecase}}</h4>
													<h4 *ngIf="!isStudent">{{student.title | titlecase}}</h4>

													<!-- <span class="feedback-list-info angular-with-newlines">{{student.company.substr(0, 12)}} <span *ngIf="12 < student.company.length">...</span> <br/>
												{{student.title.substr(0,12)}} <span *ngIf="12 < student.title.length">...</span> </span> -->
													<h5>{{student.company}}</h5>
													<h5 *ngIf="!isStudent">{{student.name}}</h5>
													<div *ngIf="student.fb_status<3">
														<span class="feedback-list-info angular-with-newlines" style="display:inline-block">Due: {{student.fb_due | date}}</span>
													</div>
													<div *ngIf="student.fb_status>2">
														<span class="feedback-list-info color-accent angular-with-newlines" style="display:inline-block">Complete: {{student.last_updated_ts | date}}</span>
													</div>
												</div>
											</div>
										</div>
									</a>
								</li>
							</ul>
							<div class="clearfix"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="panel-detail">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8  profile-padding">
				<div class="tab-content posting-content background-white" *ngIf="getParam">
					<div role="tabpanel" id="list_tab1" class="tab-pane in active">
						<div class="posting-detail">
							<div class="title_bar">
								<h2>Feedback</h2>
								<!-- <div class="back_btn visible-xs visible-sm">
									<button mat-button class="btn btn-default back-button" (click)="toggleDetail()">
										<span class="fa fa-arrow-left arrow_leftt"></span> back </button>
								</div> -->
							</div>
							<div class="main_content_detail arrow_shadow_none wrapper_list_detail" *ngIf="!noRecords">


								<div class="col-md-4 col-lg-4 col-sm-4">
									<div class="feedback_profile_img">
										<img class="img-responsive" src="{{student?.profilePic}}" *ngIf="student?.profilePic" />
										<img class="img-responsive" src="/assets/img/Logo_no_backgroundv3.png" />
									</div>

								</div>

								<div class="col-md-8 col-sm-8 col-lg-8">
									<span class="profile-name">{{student?.name}} at {{student?.company}}</span>
									<div class="row">
										<div class="col-md-6">
											<span class="profile-exp-title">
												<a href="/postings/{{student?.opportunity_id}}">{{student?.title}} </a>
											</span>
											<div *ngIf="readOnly">
												<span class="profile-exp-title">Reviewer: </span>
												<a class="color-primary ng-binding" href="#" data-toggle="modal" data-target="#reviewerInfo">{{form?.reviewer.name}}</a>
											</div>

										</div>
										<div class="col-md-6">
											<button mat-button mat-raised-button color="primary" class="print_btn md-raised pull-right md-primary" *ngIf="status > 2" (click)="printPage()">
												<i class="fa fa-print"></i> Print</button>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12" *ngIf="!readOnly">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<label>Reviewer</label>
												<input matInput [(ngModel)]="form?.reviewer.name" type="text" aria-label="Supervisor Name" minlength="1" maxlength="75">
											</mat-form-field>
										</div>
										<div class="col-md-12" *ngIf="!readOnly">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<label>Telephone Number</label>
												<input matInput id="tele" name="tele" [(ngModel)]="form?.reviewer.tele" type="text" phone-input minlength="10" maxlength="15">
											</mat-form-field>
										</div>
										<div class="col-md-12" *ngIf="!readOnly">
											<mat-form-field color="accent" appearance="outline" class="md-block">
												<label>Email address</label>
												<input matInput required type="email" id="Email" name="Email" [(ngModel)]="form?.reviewer.email" minlength="10" maxlength="100"
												 #email="ngModel" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
												<mat-error *ngIf="!email.pattern && !email.hasError('required')" class="help-block small animated fadeInDown"> Invalid e-mail address </mat-error>
											</mat-form-field>
										</div>
									</div>
									<div class="row new_add_bx_cl">
										<div class="col-md-4" *ngIf="status > 2">
											<div class="text-center md-whiteframe-2dp  profile-box-margin">
												<span class="mini-profile-font">
													<i class="fa fa-1x fa-fw fa-calendar"></i> Completed</span>
												<h4 class="color-accent mini-profile-icon">{{form?.completed | date}}</h4>
											</div>
										</div>
										<div class="col-md-4">
											<div class=" text-center md-whiteframe-2dp  profile-box-margin  ">
												<span class="mini-profile-font">
													<i class="fa fa-1x fa-fw fa-star"></i> Skill</span>
												<h4 class="color-accent mini-profile-icon">{{average(form?.stars) | number:'1.1-2'}}</h4>
												<!-- <md-tooltip md-direction="top">
																				Based on the Star Ratings
																			</md-tooltip> -->
											</div>
										</div>
										<div class="col-md-4">
											<div class="text-center md-whiteframe-2dp  profile-box-margin   ">
												<span class="mini-profile-font">
													<i class="fa fa-1x fa-fw fa-star"></i> Overall</span>
												<h4 class="color-accent mini-profile-icon">{{form?.rating| number: '1.1'}}</h4>
											</div>

										</div>


									</div>
								</div>
								<div class="col-xs-12 no-padding strip-margins" *ngIf="form" style="margin-top: 20px; margin-bottom: 20px;">
									<mat-tab-group [(selectedIndex)]="activeStep"  *ngIf="!pending" (selectedTabChange)="tabChanged($event)">
										<mat-tab class="tab-weight" label="PAGE 1">
											<!-- <div class="savebtn pull-right">
												<button mat-stroked-button class="pull-left print_btn md-raised" (click)="save();" *ngIf="!readOnly" matTooltip="Save Feedback - DOES NOT SUBMIT" [matTooltipPosition]="'above'">Save</button>
											</div> -->
											<div class="col-xs-12 no-padding strip-margins border">
												<div class="col-xs-3 text-center">
													<span class="profile-main-header">Skill</span>
												</div>
												<div class="col-xs-3 text-center">
													<span class="profile-main-header">Rating</span>
												</div>
												<div class="col-xs-6">
													<span class="profile-main-header">Comments</span>
												</div>
												<div *ngFor="let item of form?.stars; let i = index;">
													<div class="row">
														<div class="col-xs-12 no-padding strip-margins" style="margin-bottom: 10px;">
															<div class="col-lg-3">
																<div class="col-xs-2 no-padding strip-margins">
																	<span class="profile-exp-title">{{i + 1}}</span>
																</div>
																<div class="col-xs-10">
																	<span class="postings-basic-info">{{item.name}}</span>
																</div>
															</div>
															<div class="col-lg-3 text-center">
																<!-- latest line below -->
																<rating [(ngModel)]="item.rating" [readonly]="readOnly"></rating>
																<!-- <star-rating [(ngModel)]="item.rating" readonly="readOnly"></star-rating> -->
															</div>
															<div class="col-lg-6">
																<mat-form-field color="accent" appearance="outline" class="md-block" style="margin-top: 0px;" *ngIf="!readOnly">
																	<textarea matInput [(ngModel)]="item.comment" maxlength="500" rows="3" md-select-on-focus aria-label="Comment" class="text_areat"
																		matAutosizeMinRows="0" matAutosizeMaxRows="6" matTextareaAutosize></textarea>
																</mat-form-field>
																<small *ngIf="!readOnly" class="form-text text-muted pull-right">{{item.comment?.length}}/{{fbCommentLength}}</small>
																<span class="postings-basic-info" *ngIf="readOnly">{{item.comment}}</span>
															</div>
															<div class="divider col-xs-12" style="margin-top: 20px;"></div>
														</div>
													</div>
												</div>
											</div>
										</mat-tab>
										<mat-tab class="tab-weight" label="PAGE 2">
											<div class="col-xs-12 no-padding strip-margins">
												<!-- <div class="savebtn pull-right">
													<button mat-stroked-button class="pull-left print_btn md-raised" (click)="save();" *ngIf="!readOnly" matTooltip="Save Feedback - DOES NOT SUBMIT" [matTooltipPosition]="'above'">Save</button>
												</div> -->
												<div *ngFor="let item of form?.questions">
													<div class="col-xs-12 no-padding strip-margins">
														<div class="col-lg-12">
															<span class="profile-main-header">{{item?.name}}</span>
														</div>
														<div class="col-lg-12">
															<mat-form-field color="accent" appearance="outline" class="md-block" *ngIf="!readOnly">
																<!-- <label>Response</label> -->
																<textarea matInput [(ngModel)]="item.content" maxlength="1000" rows="3" md-select-on-focus class="text_areat" matAutosizeMinRows="0"
																 matAutosizeMaxRows="6" matTextareaAutosize md-select-on-focus placeholder="Response">
									  							</textarea>
															</mat-form-field>
															<small *ngIf="!readOnly" class="form-text text-muted pull-right">{{item.content?.length}}/{{fbResponseLength}}</small>
															<span class="postings-basic-info" *ngIf="readOnly">{{item?.content}}</span>
														</div>
													</div>
													<div class="divider col-xs-12" style="margin-top: 20px;" *ngIf="readOnly"></div>
												</div>
												<div *ngFor="let item of form?.checkboxes">
													<div class="col-xs-12 no-padding strip-margins">
														<div class="col-lg-12">
															<span class="profile-main-header">{{item?.question}}</span>
														</div>
														<div class="col-lg-12" *ngFor="let box of item?.boxes">
															<mat-checkbox matInput [(ngModel)]="box.content" [disabled]="readOnly"> {{box?.name || "Yes"}} </mat-checkbox>
														</div>
													</div>
													<div class="divider col-xs-12" style="margin-top: 20px;" *ngIf="readOnly"></div>
												</div>
											</div>
										</mat-tab>
										<mat-tab class="tab-weight" label="PAGE 3">
											<div class="col-xs-12 no-padding strip-margins">
												<div class="col-xs-12">
													<span class="profile-main-header">{{reviewerCommentsLabel}}</span>
												</div>
												<div class="col-xs-12">
													<mat-form-field color="accent" appearance="outline" class="md-block" *ngIf="!readOnly">
														<!-- <label>{{reviewerCommentsLabel}}</label> -->
														<textarea matInput [(ngModel)]="form.comments" maxlength="1000" rows="3" md-select-on-focus class="text_areat" matAutosizeMinRows="0"
														 placeholder="Overall Comments" matAutosizeMaxRows="6" matTextareaAutosize md-select-on-focus>
								  						</textarea>
													</mat-form-field>
													<small *ngIf="!readOnly" class="form-text text-muted pull-right">{{form.comments?.length}}/{{fbResponseLength}}</small>
													<span class="postings-basic-info" *ngIf="readOnly">{{form?.comments}}</span>
												</div>
												<div class="divider col-xs-12" style="margin-top: 20px;" *ngIf="readOnly"></div>

												<div class="col-xs-3 new_add_bx_cl" matTooltip="Overall Rating (5 = Best Rating)" [matTooltipPosition]="'above'">
													<div class="text-center md-whiteframe-2dp  profile-box-margin">
														<h4 class="">
															<i class="fa fa-1x fa-fw fa-star"></i> Overall</h4>
														<h4 class="color-accent ng-binding" style="margin-top: 20px;" *ngIf="readOnly">{{form?.rating| number: '2.2'}}</h4>



														<div class="reanimate" style="height: 40px;" *ngIf="!readOnly">
															<input matInput type="number" class="text-center" [(ngModel)]="form.rating" step=".05" min="0" max="5" style="font-weight:bold;">
														</div>
														<!-- <md-tooltip md-direction="top">
																Overall Rating (5 = Best Rating)
															</md-tooltip> -->
													</div>
												</div>
												<div class="clearfix"></div>

												<div class="col-xs-12" *ngIf="!readOnly && isInstructor">
													<span class="profile-main-header">{{recipientCommentsLabel}}</span>
												</div>
												<div class="col-xs-12" style="margin:15px 0px;">
													<mat-form-field color="accent" appearance="outline" class="md-block" *ngIf="readOnly">
														<label>{{recipientCommentsLabel}}</label>
														<textarea matInput [(ngModel)]="form.instructorcomments" maxlength="1000" matAutosizeMinRows="0" matAutosizeMaxRows="5" matTextareaAutosize
														 md-select-on-focus aria-label="Overall Comment"></textarea>
													</mat-form-field>

													<small *ngIf="readOnly" class="form-text text-muted pull-right">{{form.instructorcomments?.length}}/{{commentsLength}}</small>
													<label *ngIf="!readOnly && form?.instructorcomments?.length > 0">{{recipientCommentsLabel}}</label>
													<span class="postings-basic-info" style="display: block;" *ngIf="!readOnly && form?.instructorcomments?.length > 0">{{form?.instructorcomments}}</span>
												</div>
											</div>
											<!-- <div class="col-xs-12 fb-sub-btn no-padding strip-margins pull-left" *ngIf="!readOnly">
												<button mat-button mat-raised-button color="primary" class="pull-right print_btn md-primary md-raised" (click)="clean();" *ngIf="!readOnly || status > 2"
												 matTooltip="Submit Feedback To School" [matTooltipPosition]="'above'">Submit
												
												</button>
												<button mat-stroked-button class="pull-right  md-raised" (click)="save();" *ngIf="!readOnly" matTooltip="Save Feedback - DOES NOT SUBMIT"
												 [matTooltipPosition]="'above'">Save
													
												</button>
											</div> -->
											
											<!-- <div class="col-xs-12 no-padding strip-margins pull-left" *ngIf="readOnly">
												<button mat-button mat-raised-button color="primary" class="pull-left print_btn md-primary md-raised" (click)="clean();" *ngIf="!readOnly || status > 2"
												 matTooltip="Submit Your Comments" [matTooltipPosition]="'above'">Submit
													
												</button>
												<button mat-stroked-button class="pull-left print_btn md-raised" (click)="save();" *ngIf="!readOnly" matTooltip="Save Feedback - DOES NOT SUBMIT"
												 [matTooltipPosition]="'above'">Save
													
												</button>
											</div> -->
											<div class="clearfix"></div>
										</mat-tab>
									</mat-tab-group>
									<mat-toolbar mat-toolbar >
									<mat-toolbar-row *ngIf="!pending">
										<!-- Buttons next previous -->
										
										<ul class="nav nav-pills" style="width: 100%;">
											<li class="pull-left">	
												<button md-no-ink mat-button mat-raised-button class="pwChange" (click)="decrementStep()" *ngIf="activeStep > 0">
													<span aria-hidden="true">&larr;</span> Previous</button>
											</li>
											<li class="pull-right">
												<button md-no-ink mat-button mat-raised-button class="pwChange" (click)="incrementStep()" *ngIf="activeStep < 2">Next
													<span aria-hidden="true">&rarr;</span>
												</button>									
											</li>
											<!-- // added buttons here to make buttons inline with Previous button -->
											<li class="pull-right" *ngIf="activeStep==2">
												<div class="col-xs-12 fb-sub-btn no-padding strip-margins pull-left" *ngIf="!readOnly">
													<button mat-button mat-raised-button color="primary" class="pull-right print_btn md-primary md-raised" (click)="clean();" *ngIf="!readOnly || status > 2"
													matTooltip="{{submitFeedbackTooltip}}" [matTooltipPosition]="'above'">Submit
														<!-- <md-tooltip md-direction="top">
																Submit Feedback To School
															</md-tooltip> -->
													</button>
													<button mat-stroked-button class="pull-right  md-raised" (click)="save();" *ngIf="!readOnly" matTooltip="Save Feedback - DOES NOT SUBMIT"
													[matTooltipPosition]="'above'">Save
														<!-- <md-tooltip md-direction="top">
																Save Feedback - DOES NOT SUBMIT
															</md-tooltip> -->
													</button>
												</div>
												
												<div class="col-xs-12 no-padding strip-margins pull-left" *ngIf="readOnly">
													<button mat-button mat-raised-button color="primary" class="pull-left print_btn md-primary md-raised" (click)="clean();" *ngIf="!readOnly || status > 2"
													matTooltip="Submit Your Comments" [matTooltipPosition]="'above'">Submit
														<!-- <md-tooltip md-direction="top">
																Submit Feedback To School
															</md-tooltip> -->
													</button>
													<button mat-stroked-button class="pull-left print_btn md-raised" (click)="save();" *ngIf="!readOnly" matTooltip="Save Feedback - DOES NOT SUBMIT"
													[matTooltipPosition]="'above'">Save
														<!-- <md-tooltip md-direction="top">
																Save Feedback - DOES NOT SUBMIT
															</md-tooltip> -->
													</button>
												</div>
												<div class="clearfix"></div>
											</li>
										</ul>
										
									</mat-toolbar-row>
								</mat-toolbar>
									<!-- <div *ngIf="!pending && status < 1 && isEmployer">
										<h2>Feedback has not been requested</h2>
										<p>Once instructor request for the feedback it will be available here.</p>
									</div> -->
									<!--Pending feedback message for instructor user - START -->
									<div class="col-xs-12 text-center" *ngIf="pending && isInstructor">
										<div *ngIf="status < 1">
											<h2>Feedback has not been requested</h2>
											<p>Send an invitaion to the supervisor by selecting an option from the fab button below.</p>
										</div>
										<div *ngIf="status == 1">
											<h2>Feedback requested</h2>
											<p>The employer didn't response to your invitation yet. Check back later. Feedback will become available once the
												employer submits it for review.</p>
										</div>
										<div *ngIf="status == 2">
											<h2>Feedback Pending</h2>
											<p>The employer is in the process of completing the feedback. Check back later. Feedback will become available once the employer
												submits it for review.</p>
										</div>
										<!-- <talent-request-feedback *ngIf="false"></talent-request-feedback> -->
									</div>
									<!--Pending feedback message for instructor user - END -->

									<!--Pending feedback message for employer user - START -->
									<!--<div class="col-xs-12 text-center" *ngIf="!readOnly && pending && isEmployer">-->
									<div class="col-xs-12 text-center" *ngIf="pending && isEmployer">
										<div *ngIf="status < 1">
											<h2>Feedback has not been requested</h2>
											<p>Send an invitaion to the student by selecting an option from the fab button below.</p>
										</div>
										<div *ngIf="status == 1">
											<h2>Feedback requested</h2>
											<p>The student didn't response to your invitation yet. Check back later. Feedback will become available once the
												student submits it for review.</p>
										</div>
										<div *ngIf="status == 2">
											<h2>Feedback Pending</h2>
											<p>The student is in the process of completing the feedback. Check back later. Feedback will become available once the student
												submits it for review.</p>
										</div>
									</div>
									<!--Pending feedback message for employer user - END -->

									<!--Pending feedback message for STUDENT user - START -->
									<div class="col-xs-12 text-center" *ngIf="pending && isStudent">
										<div *ngIf="status < 1">
											<h2>Feedback has not been requested</h2>
											<p>Send an invitaion to the supervisor or posting owner by selecting an option from the fab button below.</p>
										</div>
										<div *ngIf="status == 1">
											<h2>Feedback requested</h2>
											<p>The supervisor or posting owner didn't response to your invitation yet. Check back later. Feedback will become available once the
												supervisor or posting owner submits it for review.</p>
										</div>
										<div *ngIf="status == 2">
											<h2>Feedback Pending</h2>
											<p>The supervisor or posting owner is in the process of completing the feedback. Check back later. Feedback will become available once the supervisor or posting owner
												submits it for review.</p>
										</div>
									</div>
									<!--Pending feedback message for STUDENT user - END -->

								</div>
								<div class="clearfix"></div>
								<div class="element-identifier-visible" *ngIf="isInstructor && status <= 1">
									<fab-speed-dial direction="up" class=" pipe_fab side_fab" *ngIf="readOnly">
										<button type="button" mat-fab fab-toggle>
											<i class="fa fa-1x fa-bars fa-fw"></i>
										</button>
										<button type="button" mat-mini-fab fab-button matTooltip="Request Feedback For Individual" [matTooltipPosition]="'left'"
										 (click)="feedbackRequest(0)">
											<i class="fa fa-envelope"></i>
										</button>
										<button type="button" mat-mini-fab fab-button color="warn" matTooltip="Request Feedback as Group" [matTooltipPosition]="'left'"
										 (click)="feedbackRequest(1)">
											<i class="fa fa-envelope-o"></i>
										</button>
									</fab-speed-dial>
								</div>
								<div class="element-identifier-visible" *ngIf="!isInstructor && status <= 1">
									<fab-speed-dial direction="up" class=" pipe_fab side_fab" *ngIf="readOnly">
										<button type="button" mat-fab fab-toggle>
											<i class="fa fa-1x fa-bars fa-fw"></i>
										</button>
										<button type="button" mat-mini-fab fab-button matTooltip="Request Feedback For Individual" [matTooltipPosition]="'left'"
										 (click)="feedbackRequest(0)">
											<i class="fa fa-envelope"></i>
										</button>
									</fab-speed-dial>
								</div>
							</div>
							<div class="main_content_detail arrow_shadow_none wrapper_list_detail" *ngIf="noRecords">
								<h3>No Feedback has been requested yet! Please check back later!</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- Right Panel -->
<div *ngIf="getParam">
	<!--Printing Report-->
	<div id="printThisElement" style="display:none; width:100%; background-color:#fff; font-family: Georgia, serif; line-height: 1.2;">
		<div id="basicInfo" style="width:100%; display:flex; margin-top:10px;">
			<div style="width:28%; float:left; ">
				<img [src]="student?.profilePic" *ngIf="student?.profilePic"> </div>
			<div style="width:62%; float:right; margin-left:25px;">
				<h2 style="margin:0px; width:100%">{{student?.name}} at {{student?.company}}</h2>
				<div style="display:inline-flex; float:left; clear:both; width:100%; margin-top: 7px;">
					<div style="width:17%; text-align:left; display:block">
						<h4 style="margin:0px">Title: </h4>
						<h4 style="margin:0px">Reviewer: </h4>
					</div>
					<div style="width:75%; text-align:left; display:inline-block; margin-left:2px">
						<h4 style="font-weight:normal; font-size:inherit; margin:0px">{{student?.title}}</h4>
						<h4 style="font-weight:normal; font-size:inherit; margin:0px; ">{{form?.reviewer.name}}</h4>
					</div>
				</div>
				<div style="display:inline-flex; float:left; clear:both; width:100%; text-align:center; margin-top:25px">
					<div style="border:1px solid #ccc; width:25%; margin-left:0px; margin-top:5px; margin-right:5px; margin-bottom:5px">
						<h4 style="margin:0px; border-bottom:1px solid #ccc; padding:5px 0px">
							<span style="color:#616161">&#128197;</span> Completed</h4>
						<h4 class="color-accent ng-binding" style="margin-top: 20px; color:limegreen">{{form?.completed | date}}</h4>
					</div>
					<div style="border:1px solid #ccc; width:25%; margin:5px">
						<h4 style="margin:0px; border-bottom:1px solid #ccc; padding:5px 0px">&#9733; Skill</h4>
						<h4 class="color-accent ng-binding" style="margin-top: 20px; color:limegreen">{{average(form?.stars) | number:'2.2'}}</h4>
					</div>
					<div style="border:1px solid #ccc; width:25%; margin:5px">
						<h4 style="margin:0px; border-bottom:1px solid #ccc; padding:5px 0px">&#9733; Overall</h4>
						<h4 id="color-accent" style="margin-top: 20px; color:limegreen">{{form?.rating| number: '2.2'}}</h4>
					</div>
				</div>
			</div>
		</div>
		<div id="reportPage1" style="width:100%; display:block">
			<div style="display:inline-flex; float:left; clear:both; width:100%; text-align:center;">
				<div style="border-bottom:1px solid black; width:28%; margin:4px">
					<h3>Skill</h3>
				</div>
				<div style="border-bottom:1px solid black; width:22%; margin:4px">
					<h3>Rating</h3>
				</div>
				<div style="border-bottom:1px solid black; width:47%; margin:4px; margin-right:0px">
					<h3 class="">Comments</h3>
				</div>
			</div>
			<div *ngFor="let item of form?.stars; let i = index;">
				<div style="display:inline-flex; float:left; clear:both; width:100%; text-align:left;">
					<div style="width:2%; margin:4px; page-break-inside: avoid;">
						<span>{{i + 1}}.</span>
					</div>
					<div style="width:25%; margin:4px; margin-left:1px; page-break-inside: avoid;">
						<span>{{item.name}}</span>
					</div>
					<div style="margin:4px; text-align: center; page-break-inside: avoid; display:inline;">
						<div *ngIf="item.rating==1">
							<span style="font-size:26px; color:#FFD700; text-shadow: 3px 2px #ccc;">&#9733;</span>
							<span style="font-size:25px;">&#9734;&#9734;&#9734;&#9734;</span>
						</div>
						<div *ngIf="item.rating==2">
							<span style="font-size:26px; color:#FFD700; text-shadow: 3px 2px #ccc;">&#9733;&#9733;</span>
							<span style="font-size:25px;">&#9734;&#9734;&#9734;</span>
						</div>
						<div *ngIf="item.rating==3">
							<span style="font-size:26px; color:#FFD700; text-shadow: 3px 2px #ccc;">&#9733;&#9733;&#9733;</span>
							<span style="font-size:25px;">&#9734;&#9734;</span>
						</div>
						<div *ngIf="item.rating==4">
							<span style="font-size:26px; color:#FFD700; text-shadow: 3px 2px #ccc;">&#9733;&#9733;&#9733;&#9733;</span>
							<span style="font-size:25px;">&#9734;</span>
						</div>
						<div *ngIf="item.rating==5">
							<span style="font-size:26px; color:#FFD700; text-shadow: 3px 2px #ccc;">&#9733;&#9733;&#9733;&#9733;&#9733;</span>
						</div>
					</div>
					<div style="width:46%; margin:4px; margin-right:0px; padding-left:10px">
						<span>{{item.comment}}</span>
					</div>
				</div>
			</div>
			<h2 style="border-bottom:1px solid black; width:100%; page-break-before: always;">Key Questions</h2>
			<div *ngFor="let item of form?.questions; let i = index;" style="display:block;  width:100%; text-align:left;">
				<div style="padding-top:15px; display:inline-flex; width:100%">
					<span style="margin:4px; width:2%;"> {{i + 1}}. </span>
					<span style="margin:4px; margin-left:1px; width:96%; font-size:18px; font-weight:600; page-break-inside: avoid;">
						{{item.name}}</span>
				</div>
				<div style="padding-left:18px; margin:10px; width:95%; page-break-inside: avoid; display:block">
					<span>{{item.content}}</span>
				</div>
			</div>

			<!--<h2 style="border-bottom:1px solid black; width:100%; margin-top:20px; page-break-inside: avoid;">Check box:</h2>-->
			<div *ngFor="let item of form?.checkboxes; let i = index;" style="display:block;  width:100%; text-align:left; ">
				<div style="padding-top:15px; display:inline-flex; width:100%">
					<span style="margin:4px; margin-right:0px; width:2%;">{{questionsLength+i + 1}}.</span>
					<span style="margin:4px; width:96%; font-size:18px; font-weight:600; page-break-inside: avoid;">
						{{item?.question}}</span>
				</div>
				<div *ngFor="let box of item?.boxes" style="display:block;  width:100%; text-align:left;">
					<div style="margin-top:5px; margin-left:28px">
						<span *ngIf="box.content==1" style="width:100%; page-break-inside: avoid;">&#9745; {{box?.name || "Yes"}}</span>
						<span style="width:100%; page-break-inside: avoid;" *ngIf="box?.content==0">
							&#9744; {{box?.name || "Yes"}}</span>
					</div>
				</div>
			</div>
			<h2 style="border-bottom:1px solid black; width:100%; page-break-inside: avoid;">{{reviewerCommentsLabel}}</h2>
			<span style="page-break-inside: avoid; padding-left:18px;">{{form?.comments}}</span>
		</div>
	</div>
</div>

<!-- Confirm on submit feedback -->
<div id="submit-feedback-confirm" class="modal feedback_p fade" role="form">
	<div class="modal-dialog">
		<div class="modal-content">
			<form name="subfeedback" id="subfeedback" #subfeedback="ngForm" novalidate>
				<div class="modal-header">
					<h4 class="modal-title" mat-dialog-title>Are you sure you want to submit?
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button></h4>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-lg-12">
							<div mat-dialog-content class="mat-block">
								<label>Check For Incomplete Forms</label>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<div mat-dialog-actions>
						<button type="button" mat-button (click)="closeDialog()"> Return to Form </button>
						<button mat-button mat-raised-button color="primary" (click)="submit()" class="md-primary md-raised print_btn"> Submit Now</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
